import Home from "./Home";
import Login from "./Auth/Login";
import ForgotPassword from "./Auth/ForgotPassword";
import AdminForgotPassword from "./Auth/AdminForgotPassword";
import AdminLogin from "./Auth/AdminLogin";
import Signup from "./Auth/Signup";
import Confirm from "./Auth/Confirm";
import User from "./User";
import { JobsAndIntership } from "./JobsAndIntership";
import Xyfund from './StaticPages/Xyfund'
import Texteditor from "./Admin/UpdateStaticPages";
import StaticPages from "./Admin/StaticPages";
import Awards from "./StaticPages/Awards";
import ContactUs from "./StaticPages/ContactUs";
import Event from "./StaticPages/Event";
import Support from "./StaticPages/faq";
import AboutUs from "./StaticPages/AboutUs";
import Terms from "./StaticPages/terms-conditions";
import Skillset from './Admin/Skillset'
import SkillSetList from './Admin/SkillSetList'

import Banner from "./Admin/banner";
import BannerList from "./Admin/BannerList";
import Attribute from "./Admin/Attribute";
import AttributeList from "./Admin/AttributeList";
import Advertisement from "./Admin/Advertisement";
import AdvertisementList from "./Admin/AdvertisementList";
import Course from "./Admin/Course";
import CourseList from "./Admin/CourseList";
import Industry from "./Admin/Industry";
import IndustryList from "./Admin/IndustryList";
import Qualification from "./Admin/Qualification";
import QualificationList from "./Admin/QualificationList";
import Toolkit from "./Admin/Toolkit";
import ToolkitList from "./Admin/ToolkitList";
import Users from "./Admin/Users";
import InvitationMail from "./Admin/InvitationMail"
import UpdateProfile from "./Admin/UpdateProfile";


import CollegesProfile from "./College/CollegeProfile";
import StudentsProfile from "./Student/StudentsProfile";
import CompanysProfile from "./Company/CompanyProfile";
import MyNetwork from "./Company/MyNetwork";
import Messaging from "./Company/Messaging";


import CollegeList from "./College/CollegeList";
import StudentList from "./Student/StudentList";
import CompanyList from "./Company/CompanyList";

import StaticPagesList from "./Admin/StaticPagesList";
import AdminChangePassword from "./Admin/ChangePassword";
import CollegeChangePassword from "./College/Admin/ChangePassword";
import CompanyChangePassword from "./Company/Admin/ChangePassword";
import StudentChangePassword from "./Student/Admin/ChangePassword";

import Protected from "./Protected";
import Unprotected from "./Unprotected";
import ProtectedAdmin from "./ProtectedAdmin";


import CollegeBannerList from "./College/Admin/CollegeBannerList";
import CollegeBanner from "./College/Admin/CollegeBanner";
import CollegeProfile from "./College/Admin/CollegeProfile";
import CollegeAboutUs from "./College/Admin/CollegeAboutUs";

import CollegeCourse from "./College/Admin/CollegeCourse";
import CollegeCourseList from "./College/Admin/CollegeCourseList";
import CollegeEventList from "./College/Admin/CollegeEventList";
import CollegeEvent from "./College/Admin/CollegeEvent";
import CollegeNewsList from "./College/Admin/CollegeNewsList";
import CollegeNews from "./College/Admin/CollegeNews";
import CollegeShortList from "./College/CollegeShortList";
import ShortListCategory from "./College/Admin/ShortListCategory";
import CollegeAppliedEvent from './College/Admin/CollegeAppliedEvents'

import CompanyAppliedJobsList from "./Company/Admin/CompanyAppliedJobsList";
import CompanyAppliedEvents from "./Company/Admin/CompanyAppliedEvents";
import CompanyProfile from "./Company/Admin/CompanyProfile";
import CompanyBannerList from "./Company/Admin/CompanyBannerList";
import CompanyBanner from "./Company/Admin/CompanyBanner";
import CompanyEventList from "./Company/Admin/CompanyEventList";
import CompanyEvent from "./Company/Admin/CompanyEvent";
import CompanyMediaList from "./Company/Admin/CompanyMediaList";
import CompanyMedia from "./Company/Admin/CompanyMedia";
import CompanyLocationList from "./Company/Admin/CompanyLocationList";
import CompanyLocation from "./Company/Admin/CompanyLocation";
import CompanyJobList from "./Company/Admin/CompanyJobList";
import CompanyJob from "./Company/Admin/CompanyJob";
import CompanyAboutUs from "./Company/Admin/CompanyAboutUs";
import CompanyShortList from './Company/CompanyShortList';
import CompanyCheckfitList from "./Company/Admin/CompanyCheckfitList";

import StudentAppliedJobsList from "./Student/Admin/StudentAppliedJobList";
import StudentBannerList from "./Student/Admin/StudentBannerList";
import StudentBanner from "./Student/Admin/StudentBanner";
import StudentProfile from "./Student/Admin/StudentProfile";
import StudentDetails from "./Student/Admin/StudentDetails";
import StudentEducationList from "./Student/Admin/StudentEducationList";
import StudentEducation from "./Student/Admin/StudentEducation";
import StudentJobHistoryList from "./Student/Admin/StudentJobHistoryList";
import StudentJobHistory from "./Student/Admin/StudentJobHistory";
import StudentPortFolioList from "./Student/Admin/StudentPortFolioList";
import StudentPortFolio from "./Student/Admin/StudentPortFolio";
import StudentAchievementList from "./Student/Admin/StudentAchievementList";
import StudentAchievement from "./Student/Admin/StudentAchievement";
import StudentShortList from './Student/StudentShortList'
import StudentDashboard from "./Student/Admin/StudentDashboard";
import CollegeDashboard from "./College/Admin/CollegeDashboard";
import CompanyDashboard from "./Company/Admin/CompanyDashboard";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import AdminDashBoard from "./Admin/AdminDashBoard";
import CollegeLocation from "./College/Admin/CollegeLocation";
import CollegeLocationList from "./College/Admin/CollegeLocationList";
import Invitation from "./Invitation";
const NavRouth = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="/home">
        <Home />
      </Route>
      <Route path="/Xyfund" component={Xyfund}></Route>
      <Route path="/login">
        <Unprotected cmp={Login}></Unprotected>
      </Route>
      <Route path="/forgotpassword">
        <Unprotected cmp={ForgotPassword}></Unprotected>
      </Route>
      <Route path="/AdminForgotPassword">
        <Unprotected cmp={AdminForgotPassword}></Unprotected>
      </Route>
      <Route path="/adminlogin">
        <Unprotected cmp={AdminLogin}></Unprotected>
      </Route>
      {/* <Route path="/mynetwork" exact component={MyNetwork}>
      </Route> */}
      <Route path="/message" exact component={Messaging}>
      </Route>
      <Route path="/SignUp">
        <Unprotected cmp={Signup}></Unprotected>
      </Route>
      <Route path="/User">
        <Protected cmp={User}></Protected>
      </Route>
      <Route path="/invitation">
        <Invitation />
      </Route>

      {/* <Route path="/awards">
        <Awards />
      </Route> */}

      <Route path="/getintouch">
        <ContactUs />
      </Route>

      {/* <Route path="/event">
        <Event />
      </Route> */}

      <Route path="/aboutus">
        <AboutUs />
      </Route>

      {/* <Route path="/support">
        <Support />
      </Route> */}

      <Route path="/terms">
        <Terms />
      </Route>

      <Route path="/privacy">
        <Terms />
      </Route>
      <Route path="/texteditor">
        <ProtectedAdmin cmp={Texteditor}></ProtectedAdmin>
      </Route>
      <Route path="/invitationmail">
        <ProtectedAdmin cmp={InvitationMail}></ProtectedAdmin>
      </Route>
      <Route path="/admindashboard">
        <ProtectedAdmin cmp={AdminDashBoard}></ProtectedAdmin>
      </Route>
      <Route path="/staticpages/:id?" component={StaticPages}>

      </Route>
      <Route path="/staticpageslist">
        <ProtectedAdmin cmp={StaticPagesList}></ProtectedAdmin>
      </Route>
      <Route path="/attribute/:id?" component={Attribute}></Route>
      <Route path="/attributelist">
        <ProtectedAdmin cmp={AttributeList}></ProtectedAdmin>
      </Route>
      <Route path="/advertisement/:id?" component={Advertisement}></Route>
      <Route path="/advertisementlist">
        <ProtectedAdmin cmp={AdvertisementList}></ProtectedAdmin>
      </Route>
      <Route path="/toolkit/:id?" component={Toolkit}></Route>
      <Route path="/toolkitlist">
        <ProtectedAdmin cmp={ToolkitList}></ProtectedAdmin>
      </Route>
      <Route path="/skillset/:id?" component={Skillset}></Route>
      <Route path="/skillsetlist">
        <ProtectedAdmin cmp={SkillSetList}></ProtectedAdmin>
      </Route>
      <Route path="/industry/:id?" component={Industry}></Route>
      <Route path="/industrylist">
        <ProtectedAdmin cmp={IndustryList}></ProtectedAdmin>
      </Route>
      <Route path="/qualification/:id?" component={Qualification}></Route>
      <Route path="/qualificationlist">
        <ProtectedAdmin cmp={QualificationList}></ProtectedAdmin>
      </Route>
      <Route path="/course/:id?" component={Course}></Route>
      <Route path="/courselist">
        <ProtectedAdmin cmp={CourseList}></ProtectedAdmin>
      </Route>
      <Route path="/banner/:id?" component={Banner}></Route>
      <Route path="/BannerList">
        <ProtectedAdmin cmp={BannerList}></ProtectedAdmin>
      </Route>
      <Route path="/users">
        <ProtectedAdmin cmp={Users}></ProtectedAdmin>
      </Route>
      <Route path="/updateprofile/:id?" component={UpdateProfile}>
      </Route>
      <Route path="/adminchangepassword">
        <ProtectedAdmin cmp={AdminChangePassword}></ProtectedAdmin>
      </Route>

      {/* --------------------Static Page---------------------- */}


      <Route path="/JobAndInternship/:type?" component={JobsAndIntership}></Route>

      {/* ------------------------College----------------------- */}
      <Route path="/GetAllEventRegistration/:type?" component={CollegeAppliedEvent}></Route>
      <Route path="/CollegeList/:type?" component={CollegeList}></Route>


      <Route path="/collegechangepassword">
        <Protected cmp={CollegeChangePassword}></Protected>
      </Route>

      <Route path="/collegeaboutus/:id?">
        <Protected cmp={CollegeAboutUs}></Protected>
      </Route>

      <Route path="/collegebanner/:id?" component={CollegeBanner}></Route>
      <Route path="/collegebannerlist">
        <Protected cmp={CollegeBannerList}></Protected>
      </Route>

      <Route path="/collegeevent/:id?" component={CollegeEvent}></Route>
      <Route path="/collegeeventlist">
        <Protected cmp={CollegeEventList}></Protected>
      </Route>

      <Route path="/collegenews/:id?" component={CollegeNews}></Route>
      <Route path="/collegenewslist">
        <Protected cmp={CollegeNewsList}></Protected>
      </Route>

      <Route path="/collegelocation/:id?" component={CollegeLocation}></Route>
      <Route path="/collegelocationlist">
        <Protected cmp={CollegeLocationList}></Protected>
      </Route>

      <Route path="/collegecourse/:id?" component={CollegeCourse}></Route>
      <Route path="/collegecourselist">
        <Protected cmp={CollegeCourseList}></Protected>
      </Route>
      <Route path="/collegeshortlist">
        <Protected cmp={CollegeShortList}></Protected>
      </Route>
      <Route path="/collegesprofile/:id?" component={CollegesProfile}></Route>
      <Route path="/collegeprofile/:id?" component={CollegeProfile}></Route>
      <Route path="/collegedashboard">
        <Protected cmp={CollegeDashboard}></Protected>
      </Route>
      <Route path="/collegeshortlistcategory">
        <Protected cmp={ShortListCategory}></Protected>
      </Route>

      {/* ------------------------Company----------------------- */}
      <Route path="/CompanyShortList">
        <Protected cmp={CompanyShortList}></Protected>
      </Route>
      <Route path="/companychangepassword">
        <Protected cmp={CompanyChangePassword}></Protected>
      </Route>

      <Route path="/companyaboutus/:id?">
        <Protected cmp={CompanyAboutUs}></Protected>
      </Route>
      <Route path="/GetAllStudentJobApply/:type?" component={CompanyAppliedJobsList}></Route>
      <Route path="/GetAllCompanyRegistration/:type?" component={CompanyAppliedEvents}></Route>
      <Route path="/CompanyList/:type?" component={CompanyList}></Route>
      <Route path="/companybanner/:id?" component={CompanyBanner}></Route>
      <Route path="/companybannerlist">
        <Protected cmp={CompanyBannerList}></Protected>
      </Route>

      <Route path="/companyevent/:id?" component={CompanyEvent}></Route>
      <Route path="/companyeventlist">
        <Protected cmp={CompanyEventList}></Protected>
      </Route>

      <Route path="/companymedia/:id?" component={CompanyMedia}></Route>
      <Route path="/companymedialist">
        <Protected cmp={CompanyMediaList}></Protected>
      </Route>
      <Route path="/companylocation/:id?" component={CompanyLocation}></Route>
      <Route path="/companylocationlist">
        <Protected cmp={CompanyLocationList}></Protected>
      </Route>

      <Route path="/companyjob/:id?" component={CompanyJob}></Route>
      <Route path="/companyjoblist">
        <Protected cmp={CompanyJobList}></Protected>
      </Route>

      <Route path="/companysprofile/:id?" component={CompanysProfile}></Route>

      <Route path="/companyprofile/:id?" component={CompanyProfile}></Route>


      <Route path="/companydashboard">
        <Protected cmp={CompanyDashboard}></Protected>
      </Route>

      <Route path="/companycheckfitlist">
        <Protected cmp={CompanyCheckfitList}></Protected>
      </Route>


      {/* ------------------------Student----------------------- */}
      <Route path="/AppliedjobList/:type?" component={StudentAppliedJobsList}></Route>
      <Route path="/studentshortlist">
        <Protected cmp={StudentShortList}></Protected>
      </Route>
      <Route path="/studentchangepassword">
        <Protected cmp={StudentChangePassword}></Protected>
      </Route>

      <Route path="/studentprofile/:id?" component={StudentProfile}></Route>
      <Route path="/StudentDetails/:id?" component={StudentDetails}></Route>


      <Route path="/studentjobhistory/:id?" component={StudentJobHistory}></Route>
      <Route path="/studentjobhistorylist" >
        <Protected cmp={StudentJobHistoryList}></Protected>
      </Route>


      <Route path="/studenteducation/:id?" component={StudentEducation}></Route>
      <Route path="/studenteducationlist">
        <Protected cmp={StudentEducationList}></Protected>
      </Route>

      <Route path="/studentportfolio/:id?" component={StudentPortFolio}></Route>
      <Route path="/studentportfoliolist" >
        <Protected cmp={StudentPortFolioList}></Protected>
      </Route>

      <Route path="/StudentAchievement/:id?" component={StudentAchievement}></Route>
      <Route path="/StudentAchievementList">
        <Protected cmp={StudentAchievementList}></Protected>
      </Route>


      <Route path="/StudentList/:type?" component={StudentList}></Route>

      <Route path="/studentbanner/:id?" component={StudentBanner}></Route>
      <Route path="/studentbannerlist">
        <Protected cmp={StudentBannerList}></Protected>
      </Route>

      <Route path="/studentsprofile/:id?" component={StudentsProfile}></Route>

      <Route path="/studentdashboard">
        <Protected cmp={StudentDashboard}></Protected>
      </Route>

      <Route path="/confim-account/:userid?/:code?/:action?" component={Confirm}></Route>
    </Switch>
  );
};
export default NavRouth;
