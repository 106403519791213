import React, { useState, useEffect } from "react";
import Common from "../../../Utility/Common";
import DefaultModel from "../../../Model/College/CollegeBannerModel";
import service from "../../../Services/UtilityService";
import ServiceAPI from "../../../Utility/ServiceList";
import FormHeader from "../../Common/FormHeader";
import CollegeNav from "./CollegeNav";
import environment from '../../../environment';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useLoader from "../../Auth/Loader"
import AdminHeader from '../../Common/AdminHeader'
import $ from "jquery";
import { useHistory } from "react-router-dom";
toast.configure()

const CollegeBanner = (props) => {
  Common.ValidLogin();
  const formData = new FormData();
  localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
  const id1 = props.id;
  const [loader, showLoader, hideLoader] = useLoader();
  const [model, setModel] = useState(DefaultModel);
  const [imgurl, setImgurl] = useState();
  const [border, setBorder] = useState("");
  const [showimage, setShowImage] = useState(false);
  const services = new service();
  const history = useHistory()

  useEffect(() => {
    if (id1) {
      GetById(id1);
    }
    else if (props.match || !props) {

      // const id = props.match.params.id;
      const id = props.location.search.split("=")[1]
      if (id) { GetById(id); }
    }
  }, [id1]);
  const ChangeHandler = (e) => {
    let value = {};
    if (e.target.type === "checkbox") {
      value[e.target.id] = e.target.checked;
    } else {
      value[e.target.id] = e.target.value;
    }
    setModel({ ...model, ...value });
    setBorder({ ...border, [e.target.id]: "" })
  };

  const UploadImage = async (e) => {
    let file = e.target.files[0];
    if (validImage(file)) {
      setImgurl()
      setShowImage(true)
      formData.append('file', e.target.files[0]);
      formData.append('imageType', '1');
      var data = await services.UploadImages(ServiceAPI.ImageUplaodApi, "banner", formData);
      var url = environment.imageUrl + data.result;
      setShowImage(false)
      setImgurl(url);

      let value = { imageUrl: url };
      await setModel({ ...model, ...value });
      setBorder({ ...border, [e.target.id]: "" })
    }
  }
  const validImage = (file) => {
    const fileExtension = file.name.split(".").at(-1);
    const allowedFileTypes = ["jpg", "png", "jpeg"];
    if (!allowedFileTypes.includes(fileExtension)) {
      toast.error("File does not support Upload jpg,png,jpeg files", { theme: "colored", toastId: 'error5', autoClose: 3000 });
      return false;
    }
    if (file.size > 10e6) {
      toast.error("Please upload a file smaller than 10 MB", { theme: "colored", toastId: 'error6', autoClose: 3000 });
      return false;
    }
    return true;
  }

  const Create = async (e) => {
    e.preventDefault();
    var response = null;
    if (valid()) {

      let value = { userId: Common.UserId() };
      setModel({ ...model, ...value });
      showLoader();
      if (model.id === null) {
        response = await services.Create(ServiceAPI.CollegeBannerCreateApi, model);
      } else {
        response = await services.Update(ServiceAPI.CollegeBannerUpdateApi, model);
      }
      if (response && response.message === "Success") {
        if (props.id) {
          props.GetcollegeBanner(Common.LoginId());
          toast.success('Successful', { theme: "colored", autoClose: 2000, toastId: 'success1' })
          setTimeout(() => {
            hideLoader();
            $('.modal-backdrop').removeClass('show ');
            $('.modal').removeClass('show');
            $('body').toggleClass('p-0');
            $('body').removeClass('modal-open');
            $('nav.navbar.navbar-expand-sm').addClass('p-0');

          }, 2000)
        }
        else if (props.id2) {
          hideLoader();
          props.GetcollegeBanner(Common.LoginId());
          toast.success('Successful', { theme: "colored", autoClose: 2000, toastId: 'success2' })
        }
        else {
          toast.success('Successful', { theme: "colored", autoClose: 2000, toastId: 'success3' })
          setTimeout(() => {
            history.push("/collegebannerlist");
            setModel(DefaultModel);
          }, 1000);

        }
      }
      else {
        hideLoader();
      }
    }
  };

  const GetById = async (id) => {
    $('.modal-backdrop').removeClass('show ');
    $('.modal').removeClass('show');
    $('body').toggleClass('p-0');
    $('body').removeClass('modal-open');
    showLoader()
    setModel(DefaultModel);
    var res = await services.GetById(ServiceAPI.CollegeBannerGetApi, id);
    hideLoader()
    const banner = await res.result;
    setModel(banner);
    setImgurl(banner ? banner.imageUrl : "");
  };
  const valid = () => {
    let Error = [];
    let name = "";
    let title = "";
    let imageUrl = "";
    let titleLink = "";
    function isValidWebUrl(url) {
      let regEx = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gm;
      return regEx.test(url);
    }

    if (!Boolean(model.title.trim())) {
      Error.push("Valid Title required");
      title = "borderred"
    }
    if (!Boolean(model.imageUrl.trim())) {
      Error.push("Valid Image required");
      imageUrl = "borderred"
    }
    if (name || title || imageUrl || titleLink) {
      setBorder({ name, title, imageUrl, titleLink });
    }
    if (Error.length === 0) {
      Error = [];
      return true;
    } else if (Error.length > 2) {
      toast.error('Please fill the required fields', { theme: "colored", toastId: 'error1' });
      return false;
    } else if (Error.length <= 2) {
      Error.forEach((element, index) => {
        toast.error(element, { theme: "colored", toastId: `${[index]}` });
      });
      return false;
    }
  };
  const back = () => {
    history.push("/collegebannerlist");
  }
  const DeleteBanner = async (id) => {
    showLoader()
    const res = await services.Delete(ServiceAPI.CollegeBannerDeleteApi, id)
    hideLoader()
    if (res.message == "Success") {
      if (props.id) {
        props.GetcollegeBanner("delete");
        toast.success('Successful Deleted...', { theme: "colored", autoClose: 2000, toastId: 'success1' })
        setTimeout(() => {
          hideLoader();
          $('.modal-backdrop').removeClass('show ');
          $('.modal').removeClass('show');
          $('body').toggleClass('p-0');
          $('body').removeClass('modal-open');
          $('nav.navbar.navbar-expand-sm').addClass('p-0');

        }, 2000)
      }
    }
  }
  return (
    <>
      {props.id || props.id2 ? "" : <AdminHeader heading="" subheading="Manage Banner" />}
      <form onSubmit={Create} autoComplete="off">
        <div className="theme-container-admin container-fluid">
          {props.id || props.id2 ? "" : <CollegeNav banner="active"></CollegeNav>}
          <div className="row">
            <div className="mx-auto detail-box form-sec">
              {/* <div className="col-md-6 mx-auto detail-box form-sec"> */}
              <div className="row m-0 align-items-center  ">

                <div className="col-md-12">
                  <div className="label xy-label-float">Title<span className="required">*</span></div>
                  <input
                    type="text"
                    id="title"
                    className={border.title ? border.title : "xu-input"}
                    placeholder="Title"
                    value={model ? model.title : ""}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                </div>
                <div className="col-md-12">
                  <div className="label xy-label-float">Title Link<span className="required"></span></div>
                  <input
                    type="text"
                    id="titleLink"
                    className={border.titleLink ? border.titleLink : "xu-input"}
                    placeholder="Link"
                    value={model ? model.titleLink : ""}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                </div>
                <div className="col-md-12">
                  <div className="row m-0">
                    <div className="col-md-10 pl-0">
                      <div className="label xy-label-float">Choose Image<span className="required">*</span> <span className="">(JPG or PNG)</span></div>
                      <input
                        type="file"
                        id="imageUrl"
                        className={border.imageUrl ? `${border.imageUrl} xpading` : "xu-input xpading"}
                        placeholder="Image"
                        accept="image/jpeg, image/png,  image/jpg"
                        onChange={(e) => UploadImage(e)}
                      ></input>
                    </div>
                    <div className="col-md-2 pr-0">
                      {imgurl ? <img src={imgurl} className="ad-img img-fluid" />
                        : showimage ? <span className="img">
                          {/* <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/loader.gif`} className="ad-img img-fluid" /> */}
                          <div className="admin-img-preview ">
                            <div className="lds-roller sm"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                          </div>
                        </span> : <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/default-img.jpg`} className="ad-img img-fluid" />}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="label">Active</div>
                  <label className="switch">
                    <input
                      type="checkbox"
                      id="active"
                      checked={model ? model.active : ""}
                      onChange={(e) => ChangeHandler(e)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                {props.id ? <div className=" delete">
                  <a
                    className="fa fa-trash delete"
                    onClick={() => DeleteBanner(model.id)}
                  >
                  </a>
                </div> : ""}
                <div className="col-md-12 spacing">
                  <button
                    className="btn btn-outline a-btn mt-0 float-right auto"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>{" "}
            </div>{" "}
          </div>
          {props.id || props.id2 ? "" : <div className='back-icon' onClick={back}></div>}
        </div>
      </form>
      {loader}
    </>
  );
};

export default CollegeBanner;
