import React, { useState, useEffect } from "react";
import service from "../../../Services/UtilityService";
import ServiceAPI from "../../../Utility/ServiceList";
import AdminNav from "./CompanyNav";
import Select from "react-select";
import Common from "../../../Utility/Common";
import Multiselect from 'multiselect-react-dropdown';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Checkfit from "./Checkfit";
import AdminHeader from '../../Common/AdminHeader';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { FaRegTimesCircle } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import useLoader from '../../Auth/Loader'
import $ from 'jquery'

toast.configure()

function CompanyCheckfitList() {
    const history = useHistory();
    localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
    const [model, setModel] = useState([]);
    const [open, setOpen] = useState(false);
    const [progress, setProgress] = useState(20);
    const [newtoolkit, setNewtoolkit] = useState([]);
    const [index, setIndex] = useState(0);
    const [newindex, setNewIndex] = useState()
    const [attribute, setAttribute] = useState([]);
    const [loader, showLoader, hideLoader] = useLoader();
    const [newtoolkitlist, setNewtoolkitlist] = useState([]);
    const [activetoolkit, setActivetoolkit] = useState(false);
    const [name, setName] = useState('');
    const [toolkit, setToolkit] = useState([]);
    const [course, setCourse] = useState([]);
    const [companyCheckfitId, setCompanyCheckfitId] = useState('')
    const [newupdatelist, setNewUpdateList] = useState([])
    const [border, setBorder] = useState("");
    const [page, setPage] = useState({
        "pageNum": 1,
        "pageSize": 100
    })
    var services = new service();


    useEffect(() => {
        GetAll();
        BindAttribute();
        BindToolKit();
        GetQualification();
    }, []);

    const GetQualification = async () => {
        var res = await services.PostAnonymous(ServiceAPI.QualificationGetAllApi, {
            "pageNum": 1,
            "pageSize": 100,
            "isActive": true,
            "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
        });
        const qualificationData = await res.result
        qualificationData.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
        setCourse(qualificationData)

    };

    async function GetAll() {
        $('.modal-backdrop').removeClass('show ');
        $('.modal').removeClass('show');
        $('body').toggleClass('p-0');
        $('body').removeClass('modal-open');
        showLoader()
        var res = await services.GetDataById(ServiceAPI.CompanyCheckfitGetAllApi, Common.LoginId(), {
            "pageNum": 1,
            "pageSize": 6
        });
        hideLoader()
        const data = await res.result;
        await setModel(data);
        const checkfitId = data.map((item, index) => item.id)
        setCompanyCheckfitId(checkfitId)
        setNewtoolkit(data)
        if (data.toolKits && data.toolKits.length > 0) {
            setProgress(data.toolKits[data.toolKits.length - 1].percentage)
        }
    }
    function CircularProgressWithLabel(props) {
        return (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress variant="determinate" {...props} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="caption" component="div" color="text.secondary">
                        {`${Math.round(props.value)}%`}
                    </Typography>
                </Box>
            </Box>
        );
    }

    const marks = [

        {
            value: 25,
            label: '25%',
        },
        {
            value: 50,
            label: '50%',
        },
        {
            value: 75,
            label: '75%',
        },
        {
            value: 99,
            label: '99%',
        },
    ];

    const handleClose = () => {
        setNewtoolkit(model)
        setOpen(false);
    };
    const handleRemove = (id, index, value) => {
        var val1 = [...model]
        const filteredtoolkit = model[newindex ? newindex : value].toolKits.filter((item) => item.toolKitId !== id);
        setNewtoolkit(filteredtoolkit);
        let values = { ...model[newindex ? newindex : value], toolKits: filteredtoolkit ? filteredtoolkit : '' };
        val1[newindex ? newindex : value].toolKits = [...filteredtoolkit]
        setModel([...val1]);
    };
    const BindAttribute = async () => {
        var res = await services.PostGetAll(ServiceAPI.AttributeGetAllApi, page);
        const data = await res.result;
        data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
        await setAttribute(data);
    }

    const BindToolKit = async () => {
        var res = await services.PostGetAll(ServiceAPI.ToolKitGetAllApi, page);
        const data = await res.result.filter((data) => data.active !== false);
        data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
        await setToolkit(data);

    }

    const AddValue = async (e, field, index) => {

        if (field === "attributes") {
            let value = { attributes: [...model[index].attributes, { companyCheckfitId: companyCheckfitId ? companyCheckfitId[index] : 0, attributeId: e.slice(-1)[0].id, name: e.slice(-1)[0].name, attribute: null, id: null, userId: Common.UserId(), active: true }] };
            let newValue = { ...model[index], ...value };
            let newArr = [...model];
            newArr[index] = newValue;
            await setModel(newArr);
        }
        else if (field === "toolKits") {
            let value = { toolKits: [...model[e.id].toolKits, { companyCheckfitId: companyCheckfitId ? companyCheckfitId[e.id] : 0, toolKitId: e.value, name: e.label, course: null, id: null, userId: Common.UserId(), active: true }] };
            let newValue = { ...model[e.id], ...value };
            let newArr = [...model];
            newArr[e.id] = newValue;
            await setNewUpdateList(newArr);
            setNewtoolkit(newArr[e.id].toolKits)

        } else if (field === "courses") {
            let value = { courses: [...model[index].courses, { companyCheckfitId: companyCheckfitId ? companyCheckfitId[index] : 0, courseId: e.slice(-1)[0].id, name: e.slice(-1)[0].name, course: null, id: null, userId: Common.UserId(), active: true }] };
            let newValue = { ...model[index], ...value };
            let newArr = [...model];
            newArr[index] = newValue;
            await setModel(newArr);
        }
    }
    const handleClickOpen = (e, index) => {
        setActivetoolkit(false)
        setOpen(true);
        setNewIndex(index)
        var results = [
            ...toolkit.filter(el => {
                return !model[index].toolKits.find(element => {
                    return `${element.name}` === `${el.name}`;
                });
            }),
        ]
        results.forEach(function (element) {
            element.verify = index;
        });
        setNewtoolkitlist(results)


    };
    function valuetext(value) {
        setProgress(value)
        return `${value}%`;
    }
    const handleAdd = (indexs) => {
        var val1 = [...model]
        if (newtoolkit.length > 0 && activetoolkit == true) {
            val1[newindex].toolKits[index].percentage = progress
            setOpen(false);
        } if (newtoolkit.length > model[newindex].toolKits.length) {
            newupdatelist[newindex].toolKits[newupdatelist[newindex].toolKits.length - 1].percentage = progress
            setOpen(false);
        } if (newtoolkit.length == model[newindex].toolKits.length && activetoolkit == false) {
            toast.error('Please Select Valid Toolkit', { theme: "colored", autoClose: 4000 });
            setOpen(true);
        }
        val1.forEach(function (element, index) {
            if (index == newindex) {
                element.toolKits = newupdatelist.length > 0 ? newupdatelist[newindex].toolKits : model[newindex].toolKits
            }
        });
        setModel(val1);
    };
    const handleClickUpdate = (e, percentage, index, newindex) => {
        setProgress(percentage)
        setName(e)
        setIndex(index)
        setNewIndex(newindex)
        setActivetoolkit(true)
        if (newtoolkit.map((item) => (item.name)).filter((val) => val == e) == e) {
            newtoolkit[index].percentage = progress
        }
        setOpen(true);
    };

    const RemoveValue = async (e, field, index) => {
        if (field === "attributes") {
            let value = { attributes: e }
            let newValue = { ...model[index], ...value };
            let newArr = [...model];
            newArr[index] = newValue;
            await setModel(newArr);

        } else if (field === "toolKits") {
            let value = { toolKits: e }
            let newValue = { ...model[index], ...value };
            let newArr = [...model];
            newArr[index] = newValue;
            await setModel(newArr);
        } else if (field === "courses") {
            let value = { courses: e }
            let newValue = { ...model[index], ...value };
            let newArr = [...model];
            newArr[index] = newValue;
            await setModel(newArr);
        }
    }

    const ChangeHandler = (e, index) => {
        let value = {};
        if (e.target.type === "checkbox") {
            value[e.target.id] = e.target.checked;
        } else {
            value[e.target.id] = e.target.value;
        }
        let newValue = { ...model[index], ...value };
        let newArr = [...model];
        newArr[index] = newValue;
        setModel(newArr);
    }
    const Create = async (e, index) => {

        e.preventDefault();
        var response = null;
        if (valid(index)) {
            showLoader()
            if (model[index].id === null) {
                let value = { userId: Common.UserId() };
                setModel({ ...model[index], ...value });
                response = await services.Create(ServiceAPI.CompanyCheckfitCreateApi, model[index]);
                hideLoader()
            } else {
                response = await services.Update(ServiceAPI.CompanyCheckfitUpdateApi, model[index]);
                hideLoader()
            }
            if (response && response.message === "Success") {
                hideLoader()
                setTimeout(() => {
                    history.push("/companycheckfitlist");
                }, 2000);
                toast.success('Successful', { theme: "colored", autoClose: 2000, toastId: 'Success' })
            }
        }
    };
    const Delete = async (id) => {

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="xy-form">
                        <div className="mdl-heading">Delete Record</div>
                        <div className="desc">Are you sure to delete it?</div>
                        <button className="btn btn-outline s-btn pr" onClick={onClose}>No</button>
                        <button className="btn btn-outline s-btn pr"
                            onClick={async () => {
                                showLoader()
                                await services.Delete(ServiceAPI.CompanyCheckfitDeleteApi, id);
                                hideLoader()
                                await GetAll();
                                onClose();
                            }}
                        >
                            Yes
                        </button>
                    </div>
                );
            }
        });
    };

    const valid = (index) => {
        let Error = [];
        let attributes = "";
        let toolkits = "";
        let courses = "";
        if (!Boolean(model[index].attributes.length)) {
            Error.push("Valid Attributes required");
            attributes = "borderred";
        }
        if (!Boolean(model[index].toolKits.length)) {
            Error.push("Valid Toolkits required");
            toolkits = "borderred";
        }
        if (!Boolean(model[index].courses.length)) {
            Error.push("Valid Qualification Types required");
            courses = "borderred";
        }

        if (attributes || toolkits || courses) {
            setBorder({ attributes, toolkits, courses });
        }
        if (Error.length === 0) {
            Error = [];
            return true;
        } else if (Error.length > 2) {
            toast.error('Please fill the required fields', { theme: "colored", toastId: 'error1' });
            return false;
        } else if (Error.length <= 2) {
            Error.forEach((element, index) => {
                toast.error(element, { theme: "colored", toastId: `${[index]}` });
            });
            return false;
        }
    };

    return (
        <>
            <AdminHeader heading="" subheading="Manage Checkfit" />
            <form >
                <div className="theme-container-admin check-fit min-h">
                    <AdminNav Checkfit="active"></AdminNav>
                    <div className="add-option">
                        <div className="title"></div>
                        <div className="icon mr-1 mr-sm-2" data-toggle="modal" data-target='#exampleModal'>
                            <i className="fa fa-plus" aria-hidden="true"></i>
                        </div>
                        <div className="modal fade xy-form"
                            id="exampleModal"
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true">

                            <div className="modal-dialog  modal-dialog-centered " >

                                <div className="modal-content"  >
                                    <div className="modal-header form-mdlheadr">
                                        <h4 class="modal-title mdl-heading">
                                            Checkfit Preferences
                                        </h4>
                                        <button type="button"
                                            className="close modalclose-btn"
                                            data-dismiss="modal">
                                            <span aria-hidden="true"> x </span>
                                        </button>
                                    </div>

                                    <Checkfit id={null} GetAll={GetAll} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid overflow-cards">
                        <div className="row m-0 justify-content-center">
                            {model ? model.map((data, index) => {
                                return (
                                    <div className="col-lg-4 col-md-6 detail-box bordered form-sec" key={index}>
                                        <div className="line">
                                            <div className="display-inline w-100 line">
                                                <div className=" sub-title ">
                                                    {data.categoryName}
                                                </div>

                                                <div className="display-inline att-icons">

                                                    <div className="add-option icon mb-0 mt-0" >
                                                        <div data-toggle="modal" data-target={`${"#exampleModal11"}${index}`}>
                                                            <a className="fa fa-pencil edit" aria-hidden="true" />
                                                        </div>
                                                        <div className="modal fade xy-form"
                                                            id={`${"exampleModal11"}${index}`}
                                                            tabIndex="-1"
                                                            role="dialog"
                                                            aria-labelledby="exampleModalLabel"
                                                            aria-hidden="true">

                                                            <div className="modal-dialog  modal-dialog-centered ">

                                                                <div className="modal-content "  >
                                                                    <div className="modal-header form-mdlheadr">
                                                                        <h4 class="modal-title mdl-heading text-capitalize">
                                                                            edit
                                                                        </h4>
                                                                        <button type="button"
                                                                            className="close modalclose-btn"
                                                                            data-dismiss="modal">
                                                                            x
                                                                        </button>
                                                                    </div>
                                                                    <Checkfit id={data.id} GetAll={GetAll} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="icon">
                                                        <a className="fa fa-trash delete" aria-hidden="true" onClick={() => Delete(data.id)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="label">Attributes {`(select max 6)`}<span className="required">*</span></div>
                                                <Multiselect className="xu-input"
                                                    id="attributes"
                                                    onSelect={(e) => AddValue(e, "attributes", index)}
                                                    selectedValues={data.attributes}
                                                    options={attribute}
                                                    displayValue="name"
                                                    onRemove={(e) => RemoveValue(e, "attributes", index)}
                                                    selectionLimit={6}
                                                />
                                            </div>
                                            <div className="col-md-12">
                                                <div className="display-inline ">
                                                    <div className="label">
                                                        Toolkit {`(select max 6)`}<span className="required">*</span>
                                                    </div>
                                                    <div>
                                                        <Button variant="contained" className="btn btn-outline tk-btn " onClick={(e) => handleClickOpen(e, index)}>
                                                            Add
                                                        </Button>
                                                        <Dialog
                                                            open={open}
                                                            onClose={handleClose}
                                                            aria-labelledby="alert-dialog-title"
                                                            aria-describedby="alert-dialog-description"
                                                        >
                                                            {activetoolkit == true ? <DialogTitle id="alert-dialog-title">{"Update Your Toolkit"}</DialogTitle> : <DialogTitle id="alert-dialog-title">{"Add Your Toolkit"}</DialogTitle>}
                                                            <DialogContent>
                                                                <DialogContentText id="alert-dialog-description">
                                                                    <div className="col-md-12 p-0">
                                                                        {activetoolkit == true ? < div>{name.charAt(0).toUpperCase() + name.slice(1)}</div> : <><div className="label mb-2">Toolkit<span className="required">*</span></div>
                                                                            <Select
                                                                                id='toolKits'
                                                                                onChange={(e) => AddValue(e, `toolKits`, index)}
                                                                                options={newtoolkitlist ? newtoolkitlist.map((data1) => ({ label: data1.name, value: data1.id, id: data1.verify })) : []}
                                                                            />
                                                                        </>
                                                                        }
                                                                    </div>

                                                                    <Box className="mt-4 slider-w">
                                                                        <Slider
                                                                            aria-label="Custom marks"
                                                                            defaultValue={activetoolkit === true ? progress : 0}
                                                                            getAriaValueText={valuetext}
                                                                            step={25}
                                                                            valueLabelDisplay="auto"
                                                                            marks={marks}
                                                                            min={25}
                                                                            max={99}
                                                                        />
                                                                    </Box>
                                                                </DialogContentText>
                                                            </DialogContent>
                                                            <DialogActions className="tk-row">
                                                                <Button onClick={handleClose} className="btn btn-outline tk-btn2">Cancel</Button>
                                                                {activetoolkit == true ? <Button onClick={() => handleAdd(index)} className="btn btn-outline tk-btn2">
                                                                    Update
                                                                </Button> : <Button onClick={() => handleAdd(index)} className="btn btn-outline tk-btn2">
                                                                    Add
                                                                </Button>}
                                                            </DialogActions>
                                                        </Dialog>
                                                    </div>
                                                </div>
                                                <div style={{ marginTop: "5px" }}>
                                                    <ul width={100} className="list-group list-group-horizontal" >
                                                        {model[index].toolKits ? model[index].toolKits.map((item, indexs) => <div key={index} className="tool" >
                                                            <a onClick={(e) => handleClickUpdate(item.name, item.percentage, indexs, index)}  >
                                                                <li ><CircularProgressWithLabel style={{ color: 'orangered' }} value={item.percentage} /></li>
                                                                <li >{item.name.charAt(0).toUpperCase() + item.name.slice(1)}</li>
                                                            </a>
                                                            <a className="add-option" onClick={(e) => handleRemove(item.toolKitId, indexs, index)}><FaRegTimesCircle style={{ color: '#FF5A00', height: "20px", width: "20px" }} /></a>
                                                        </div>
                                                        ) : ''}

                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="label">Qualification Types</div>
                                                <Multiselect className="xu-input"
                                                    id="courses"
                                                    onSelect={(e) => AddValue(e, "courses", index)}
                                                    selectedValues={data.courses}
                                                    options={course}
                                                    displayValue="name"
                                                    onRemove={(e) => RemoveValue(e, "courses", index)}
                                                />
                                            </div>
                                            <div className="col-md-12">
                                                <label className="switch icon">
                                                    <input
                                                        className=""
                                                        type="checkbox"
                                                        id="active"
                                                        checked={data.active}
                                                        onChange={(e) => ChangeHandler(e, index)}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>

                                            <div className="col-md-12 spacing">
                                                <button
                                                    className="btn btn-outline a-btn mt-0 float-right"
                                                    type="submit"
                                                    onClick={(e) => Create(e, index)}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : ""}

                        </div>
                    </div>
                </div>
            </form>
            {loader}
        </>
    )
}

export default CompanyCheckfitList
