import React, { useState, useEffect } from "react";
import Common from "../../Utility/Common";
import DefaultModel from "../../Model/Admin/QualificationModel";
import service from "../../Services/AdminUtilityService";
import ServiceAPI from "../../Utility/ServiceList";
import FormHeader from "./FormHeader";
import AdminNav from "./AdminNav";
import AdminHeader from './AdminHeader'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useLoader from '../Auth/Loader';
import { useHistory } from "react-router-dom";
toast.configure()

function Qualification(props) {
    Common.ValidAdminLogin();
    const history = useHistory();
    // const id = props.match.params.id;
    const id = props.location.search.split("=")[1]
    const [model, setModel] = useState(DefaultModel);
    const [error, setError] = useState("");
    const [loader, showLoader, hideLoader] = useLoader();
    const [border, setBorder] = useState("");
    const services = new service();

    useEffect(() => {
        if (id) {
            GetById(id);
        }
    }, []);

    const ChangeHandler = (e) => {
        let value = {};
        if (e.target.type === "checkbox") {
            value[e.target.id] = e.target.checked;
        } else {
            value[e.target.id] = e.target.value;
        }
        setModel({ ...model, ...value });
        setBorder({ ...border, [e.target.id]: "" })
    };
    const Create = async (e) => {
        e.preventDefault();
        var response = null;
        if (valid()) {
            let user = { userId: Common.AdminUserId() };
            setModel({ ...model, ...user });
            showLoader();
            if (model.id === null) {
                response = await services.Create(ServiceAPI.QualificationCreateApi, model);
            } else {
                response = await services.Update(ServiceAPI.QualificationUpdateApi, model);
            }
            if (response && response.message === "Success") {
                if (model.id === null) {
                    toast.success('Qualification added Successfully', { theme: "colored", autoClose: 2000 })
                } else {
                    toast.success('Qualification  Updated Successfully', { theme: "colored", autoClose: 2000 })
                }
                setTimeout(() => {
                    history.push("/qualificationlist");
                    setModel(DefaultModel);
                }, 1000);

            } else {
                hideLoader();
            }
        }
    };

    const GetById = async (id) => {
        showLoader()
        var res = await services.GetById(ServiceAPI.QualificationGetApi, id);
        hideLoader()
        const banner = await res.result;
        setModel(banner);
    };

    const valid = () => {
        let name = '';
        let nameError = "";
        function isValidName(url) {
            let regEx = /^[A-Za-z]/
            return regEx.test(url);
        }
        if (!isValidName(model.name)) {
            toast.error("Valid Qualification required", { theme: "colored", toastId: 'error1', autoClose: 2000 });
            nameError = "Enter Valid Qualification";
            name = "borderred"
        }
        if (nameError) {
            setError({ nameError });
            setBorder({ name })
            return false;
        }
        return true;
    };
    const back = () => {
        history.push("/qualificationlist");
    }
    return (
        <>
            <AdminHeader heading="" subheading="Manage Qualification" />
            <form onSubmit={Create} autoComplete="off">
                <div className="theme-container-admin master-admin">
                    <AdminNav qualification="active"></AdminNav>
                    <div className="row">
                        <div className="col-md-6 mx-auto detail-box form-sec">
                            <div className="row m-0 align-items-center">
                                <div className="col-md-12">
                                    <div className="label">Qualification<span className="required">*</span></div>
                                    <input
                                        type="text"
                                        id="name"
                                        className={border.name ? border.name : "xu-input"}
                                        placeholder="name"
                                        value={model.name}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>

                                <div className="col-md-12">
                                    <div className="label">Active</div>
                                    <label className="switch">
                                        <input
                                            className=""
                                            type="checkbox"
                                            id="active"
                                            checked={model.active}
                                            onChange={(e) => ChangeHandler(e)}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="col-md-12">
                                    <button
                                        className="btn  btn-outline s-btn pr"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='back-icon' onClick={back}></div>
                </div>
                <div></div>
            </form>
            {loader}
        </>
    );
};

export default Qualification