import React, { useState, useEffect } from 'react';
import service from "../../Services/UtilityService";
import Common from '../../Utility/Common';
import ServiceAPI from "../../Utility/ServiceList";
import { FaPencilAlt } from "react-icons/fa"
import Checkfit from './Admin/Checkfit';
import { toast } from "react-toastify";
import $ from "jquery"
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useHistory } from "react-router-dom";
import useLoader from '../Auth/Loader'
var checkFitArr = [];
var firstUpdateAttemd = true;

const Draganddrop = () => {
    const history = useHistory();
    var services = new service();
    const [ShortListData, setShortListData] = useState([])
    const [loader, showLoader, hideLoader] = useLoader();
    const [choiceData, setChoiceData] = useState([])
    const [checkFit, setCheckFit] = useState({ check: false, result: [], })
    const [pageModel, setPageModel] = useState({
        "pageNum": 1,
        "pageSize": 106
    })

    const [show, setShow] = useState(true)
    localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
    useEffect(() => {
        GetShortListData();
    }, [])

    const GetShortListData = async (type) => {

        $('.modal-backdrop').removeClass('show ');
        $('.modal').removeClass('show');
        $('body').toggleClass('p-0');
        $('body').removeClass('modal-open');
        showLoader()
        var res = await services.GetById(ServiceAPI.CompanyShortListGetAllApi, Common.LoginId());
        var res1 = await services.GetDataById(ServiceAPI.CompanyCheckfitGetAllApi, Common.LoginId(), pageModel);
        hideLoader()
        if (res.result && res1.result) {
            const data1 = res1.result.filter((items) => items.active !== false);
            setChoiceData(res.result.filter((value) => value.companyCheckfitId == null))
            setShortListData(data1)
            ////////debugger;;
            if (type && data1) {
                //setCheckFit({ check: true, result: [] })
                //setCheckFit({ check: $(`${type}`).is(":checked"), result: [...data1.filter((data) => data.id == type)] })
            }
            setCheckfitData(data1);
            //updateCheckFitAsync(data1);

            // console.log(choiceData, "choicedata");
        }
    };

    const setCheckfitData = (responsed) => {
        if (firstUpdateAttemd) {
            responsed.forEach(element => {
                var obj = {
                    id: element.id + "_r",
                    isChecked: false,
                    data: element
                }
                checkFitArr.push(obj);
            });
            firstUpdateAttemd = false;
        } else {
            responsed.forEach(element => {
                var indexPoint = checkFitArr.findIndex(x => x.id === element.id + "_r");
                var isChecked = checkFitArr.find(x => x.id === (element.id + "_r")).isChecked;
                var obj = {
                    id: element.id + "_r",
                    isChecked: isChecked,
                    data: element
                }
                checkFitArr[indexPoint] = obj;
            });
        }
    }

    const deletestudent = async (id) => {
        var res = await services.Delete(ServiceAPI.CompanyShortListDeleteApi, id);
        // console.log("resshortlist", res)
        if (res.message == "Success") {
            GetShortListData()
            toast.success("Successfully Removed", { theme: "colored", autoClose: 2000 })
            // window.location ="/companyShortList"
        }

    };

    const onDragStart = (ev, id) => {
        // ////debugger;;
        ev.dataTransfer.setData("id", JSON.stringify(id));
        console.log(checkFitArr);
        // ////debugger;

        console.log('dragstart:', id);

        // console.log("Start Drop event");
    }

    const onDrop = async (ev, cat, nw) => {
        var s = $(`${cat}`).is(":checked")
        // console.log("On Drop event", s);
        let id = JSON.parse(ev.dataTransfer.getData("id"));
        console.log("On Drop event", id, cat);
        // debugger
        // if(cat==id.companyCheckfitId){
        if (cat == "shortList") {
            let new_obj = { ...id, companyCheckfitId: null, students: null }
            var res1 = await services.Update(ServiceAPI.CompanyShortListPutApi, new_obj);
            GetShortListData()
        }
        else {
            let new_obj = { ...id, companyCheckfitId: cat, students: null }
            var res1 = await services.Update(ServiceAPI.CompanyShortListPutApi, new_obj);
            // console.log("res1", cat)
            GetShortListData(cat)
            // checkBox(res1)
        }
        updateData(id)
        GetCheckFit();

    }

    const updateData = (id) => {
        checkFitArr.forEach(element => {
            // debugger
            if (element.data.id === id.companyCheckfitId && (element.data.shortLists && element.data.shortLists.length <= 1)) {
                // $(`#${id}_r`).prop("checked", false);
                var indexPoint = checkFitArr.findIndex(x => x.id === element.data.id + "_r");
                var obj = {
                    id: element.data.id + "_r",
                    isChecked: false,
                    data: element
                }
                checkFitArr[indexPoint] = obj;
                $(`#${id.companyCheckfitId}_r`).prop({ checked: false });
                console.log()
            }

        });

    }

    const updateCheckFitAsync = (obj) => {
        checkFitArr.forEach(element => {
            if (element.isChecked) {
                for (let index = 0; index < element.data.shortLists.length; index++) {
                    const innerElement = element.data.shortLists[index];
                    let pId = innerElement.studentId + '_prog';
                    let pIds = innerElement.studentId + '_progs';
                    let iId = innerElement.studentId + '_img';
                    $(`#${iId}`).hide(100);
                    $(`#${pIds}`).show(100);
                    $(`#${pId}`).show(100);
                }
            } else {
                for (let index = 0; index < element.data.shortLists.length; index++) {
                    const innerElement = element.data.shortLists[index];
                    let pId = innerElement.studentId + '_prog';
                    let pIds = innerElement.studentId + '_progs';
                    let iId = innerElement.studentId + '_img';
                    $(`#${pId}`).hide(100);
                    $(`#${pIds}`).hide(100);
                    $(`#${iId}`).show(100);
                }
            }
        });
    }

    const onDragOver = (ev) => {
        console.log("Over Drop event");
        ev.preventDefault();
    }

    const onChangedCheckFitBox = (e, data, indec, id) => {
        //debugger;
        console.log(checkFitArr);
        checkFitArr.forEach(element => {
            if (element.id === id && e.target.checked) {
                element.isChecked = true;
            }
            if (element.id === id && e.target.checked == false) {
                element.isChecked = false;
                GetCheckFit(false, id)
            }
        });
    }

    const GetCheckFit = (cat, id) => {
        // //debugger;
        checkFitArr.forEach(element => {
            if (element.isChecked && cat) {
                for (let index = 0; index < element.data.shortLists.length; index++) {
                    const innerElement = element.data.shortLists[index];
                    let pId = innerElement.studentId + '_prog';
                    let pIds = innerElement.studentId + '_progs';
                    let iId = innerElement.studentId + '_img';
                    $(`#${iId}`).hide();
                    $(`#${pIds}`).show();
                    $(`#${pId}`).show();
                }
            } else {
                // debugger
                for (let index = 0; index < (element.data.shortLists && element.data.shortLists.length); index++) {

                    const innerElement = element.data.shortLists[index];
                    if (id == `${element.data.id}_r` && cat == false) {
                        let pId = innerElement.studentId + '_prog';
                        let pIds = innerElement.studentId + '_progs';
                        let iId = innerElement.studentId + '_img';
                        $(`#${pId}`).hide();
                        $(`#${pIds}`).hide();
                        $(`#${iId}`).show();
                    }
                    else if (element.isChecked == false && id == `${element.data.id}_r`) {
                        let pId = innerElement.studentId + '_prog';
                        let pIds = innerElement.studentId + '_progs';
                        let iId = innerElement.studentId + '_img';
                        $(`#${pId}`).show();
                        $(`#${pIds}`).show();
                        $(`#${iId}`).hide();
                    }
                }
            }
        });
    }

    const profile = (id, userId) => {
        localStorage.setItem("suserid", userId)
        setTimeout(() => { history.push('studentsprofile?id=' + id) }, 100)
    }
    const redirect = () => {
        history.push("/companycheckfitlist")
    }
    const Resetshortlist = async () => {
        // CompanyshortlistResetApi
        showLoader();
        var res1 = await services.Deleteshortlist(ServiceAPI.CompanyshortlistResetApi, Common.LoginId());
        GetShortListData()
        checkFitArr.forEach(element => {
            if ((element.data.shortLists && element.data.shortLists.length >= 1)) {
                // $(`#${id}_r`).prop("checked", false);
                var indexPoint = checkFitArr.findIndex(x => x.id === element.data.id + "_r");
                var obj = {
                    id: element.data.id + "_r",
                    isChecked: false,
                    data: element
                }
                checkFitArr[indexPoint] = obj;
                $(`#${element.data.id}_r`).prop({ checked: false });
                console.log()
            }

        });
        setTimeout(() => {
            toast.success("Reset Successfully", { theme: "colored" })
            hideLoader()
        }, 100);

    }

    return (
        <div className="checkfit">
            <div className='btn-row '>
                <div className="title ">ShortList</div>
                <div className='visit'> <div className="btn btn-outline s-btn " onClick={() => GetCheckFit(true)}> <img src="images/checkfit.png" /> checkfit</div>
                    <div className="btn btn-outline s-btn sl" onClick={() => Resetshortlist()}><img src="images/reset.png" />  Reset</div>

                </div>
            </div>
            <div className='shortlist-pannel scrollbar bg-light' id='scrollbar'>
                {/* <div className="add-option float-right" style={{ padding: "0.68359vw", fontSize: "1.5625vw" }} data-toggle="modal" data-target='#exampleaddModal'>
                    <div className="icon">
                        <i className="fa fa-plus" aria-hidden="true"></i></div>
                </div> */}

                <div className="modal fade xy-form"
                    id={`exampleaddModal`}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog form-w modal-dialog-centered " >
                        <div className="modal-content">
                            <div className="modal-header form-mdlheadr">
                                <h4 className="modal-title mdl-heading"> Checkfit Preferences</h4>
                                <button type="button" className="close modalclose-btn" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <Checkfit id2='0' GetShortListData={GetShortListData} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-light mt-1 container-fluid ">
                    <div className='display-inline pb-0'>
                        <div className='sub-title text-capitalize'>shortList</div>
                    </div>
                    <hr />
                    <div className="row m-h"
                        onDragOver={(e) => onDragOver(e)}
                        onDrop={(e) => onDrop(e, "shortList")}
                    >
                        <div className="light-gry " style={{ minHeight: "30px" }}>    </div>
                        {choiceData ? choiceData.map((value) => {
                            return (<div key={value.studentId}
                                onDragStart={(e) => onDragStart(e, value)}
                                draggable
                                className="col-xl-2 col-md-4 col-sm-6 mb-3"
                            >
                                <div className="user-profile shortlist-card">
                                    <label>
                                        <a
                                            onClick={() => deletestudent(value.id)}
                                            className="fa fa-trash delete"
                                            aria-hidden="true"
                                        />
                                    </label>
                                    <a onClick={() => profile(value.studentId, value.students.userId)}>
                                        <div className="bor-pad">
                                            <img src={value ? value.students.profileImage : "./images/news.png"} alt="" className="profile-img" />

                                            <div className="text-center">
                                                <h4 className='title mt-20'>{`${value.students.firstName} ${value.students.lastName}`}</h4>
                                                <p>{value.students && value.students.educations.length !== 0 ? value.students.educations[0].industry.name : ""}</p>
                                                <p className='user-location'>{value.students.country ? <img src="images/location-icon.png" /> : ""}{value.students.country}</p>
                                            </div>
                                        </div>
                                        <div className="education">
                                            <div className="study">
                                                {value.students.educations.length !== 0 ? value.students.educations[0].studyProgram ? <img src="images/education-icon.png" /> : "" : ""}
                                                {value.students && value.students.educations.length !== 0 ? value.students.educations[0].studyProgram ? value.students.educations[0].studyProgram : "" : ""}
                                            </div>
                                            <div className="college">
                                                {value.students.collegeNavigation ? value.students.collegeNavigation.logo ? <img className="" src={value.students.collegeNavigation ? value.students.collegeNavigation.logo : "images/small-bg-logo.png"} /> : "" : ""}
                                                {value.students.collegeNavigation ? value.students.collegeNavigation.name ? <span className='ml-1'>{value.students.collegeNavigation ? value.students.collegeNavigation.name : "Bhc School Of Design"}</span> : "" : ""}
                                            </div>
                                        </div>
                                        <div className="display-inline center">
                                            {/* ai */}
                                            {/* <div className="progress ">
                                                    <img src="./images/bg-img1.png " alt=" " />
                                                    <div className="overlay "></div>
                                                    <div className="left "></div>
                                                    <div className="right "></div>
                                                </div> */}
                                            {/* revit  */}
                                            {value.students ? value.students.toolKits.map((values, index) => {
                                                return (
                                                    <div key={index} className="progress-circle" data-toggle="tooltip" data-placement="right" >
                                                        <span className="percent-value">{values.percentage ? `${values.percentage}%` : ""}</span>
                                                        <CircularProgressbarWithChildren value={values.percentage} strokeWidth={7} styles={buildStyles({
                                                            pathColor: "#FF5A00",
                                                        })}>
                                                            <img src={values.toolKit ? values.toolKit.image : "./images/revit.svg"} alt=" " className="rounded-circle img" />
                                                        </CircularProgressbarWithChildren>

                                                    </div>
                                                )
                                            }) : ""}
                                        </div>
                                    </a>
                                </div>
                            </div>)
                        }) : ""}
                        <div className='col-xl-2 col-md-4 col-sm-6 mb-3'>
                            <div className='drag-box'>
                                {/* <div className='plus-btn'>+</div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {ShortListData ? ShortListData.map((category, index) => {
                        return (<section key={index}>
                            <div key={index} className="bg-light mb-2 container-fluid">
                                <div className='display-inline pl-0 pb-2'>
                                    {/* <input type="checkbox" className='mr-2' onChange={(e) => checkBox(e, category)} /> */}
                                    <div className='display-inline pb-0 pt-2'>
                                        {/* <input class='class2' name='checkbox[]' value='check' type="checkbox" id={index} onChange={(e) => checkBox(e, category, index)} /> */}
                                        <label className="check-container">
                                            <input class='class2' name='checkbox[]' value='check' type="checkbox" id={`${category.id}_r`} onChange={(e) => onChangedCheckFitBox(e, category, index, `${category.id}_r`)} />
                                            <span className="ccheckmark"></span>
                                        </label>
                                        <div className='sub-title text-capitalize' key={index + category}>
                                            {category.categoryName}
                                        </div>
                                    </div>
                                    <div className=" add-option float-right" >  <FaPencilAlt className="icon" data-toggle="modal" data-target={`${"#checkFittModal"}${index}`} onClick={() => redirect()} /> </div>
                                </div>
                                <div className='divider'></div>
                                <div className="row m-h"
                                    key={index}
                                    onDragOver={(e) => onDragOver(e)}
                                    onDrop={(e) => onDrop(e, category.id)}>
                                    {/* <div className="h-25 text-white"> hii  </div> */}
                                    {category ? category.shortLists.map((value, index) => {
                                        return (<div key={value.studentId}
                                            onDragStart={(e) => onDragStart(e, value)}
                                            draggable
                                            className="col-xl-2 col-md-4 col-sm-6 mb-3">
                                            <div className="user-profile shortlist-card">
                                                <label>
                                                    <a
                                                        onClick={() => deletestudent(value.id)}
                                                        className="fa fa-trash delete"
                                                        aria-hidden="true"
                                                    />
                                                </label>
                                                <a onClick={() => profile(value.studentId, value.students.userId)}>
                                                    <div className="bor-pad ">
                                                        {/* {show == true && (checkFit.result.map((data) => data.categoryName === category.categoryName ? data.categoryName : '') == category.categoryName ? category.categoryName : '')} */}
                                                        <div className='shortlist-progress'>
                                                            <div id={`${value.studentId}_prog`} className="hide">
                                                                <CircularProgressbarWithChildren value={value.matchTotal} strokeWidth={9} styles={buildStyles({
                                                                    pathColor: "#ff4000",
                                                                })}>
                                                                    <img className="profile-img" src={value ? value.students.profileImage : "./images/news.png"} alt="doge" />
                                                                </CircularProgressbarWithChildren>
                                                            </div>
                                                            <img id={`${value.studentId}_img`} src={value ? value.students.profileImage : "./images/news.png"} alt="" className="profile-img show" />
                                                        </div>

                                                        <h4 className='title mt-20'>{`${value.students.firstName} ${value.students.lastName}`}</h4>
                                                        <p className='user-profession'>{value.students && value.students.educations.length !== 0 ? value.students.educations[0].industry.name : ""}</p>

                                                        <p className='user-location'>{value.students.country ? <img src="images/location-icon.png"></img> : ""}{value.students.country}</p>

                                                        <div className="sub-head hide" id={`${value.studentId}_progs`} >{value.matchTotal}% Compatible</div>
                                                    </div>
                                                    <div className="education">
                                                        <div className="study">
                                                            {value.students && value.students.educations.length !== 0 ? value.students.educations[0].studyProgram ? <img src="images/education-icon.png" /> : "" : ""}
                                                            {value.students && value.students.educations.length !== 0 ? value.students.educations[0].studyProgram : ""}
                                                        </div>
                                                        <div className="college">
                                                            {value.students.collegeNavigation ? value.students.collegeNavigation.logo ? <img className="" src={value.students.collegeNavigation ? value.students.collegeNavigation.logo : "images/small-bg-logo.png"} /> : "" : ""}
                                                            {value.students.collegeNavigation ? value.students.collegeNavigation.name ? <span className='ml-1'>{value.students.collegeNavigation ? value.students.collegeNavigation.name : "Bhc School Of Design"}</span> : "" : ""}
                                                        </div>
                                                    </div>
                                                    <div className="display-inline center">
                                                        {/* ai */}
                                                        {/* <div className="progress ">
                                                    <img src="./images/bg-img1.png " alt=" " />
                                                    <div className="overlay "></div>
                                                    <div className="left "></div>
                                                    <div className="right "></div>
                                                </div> */}
                                                        {/* revit  */}
                                                        {value.students ? value.students.toolKits.map((values, index) => {
                                                            return (
                                                                <div key={index} className="progress-circle" data-toggle="tooltip" data-placement="right" >
                                                                    <span className="percent-value">{values.percentage ? `${values.percentage}%` : ""}</span>
                                                                    <CircularProgressbarWithChildren value={values.percentage} strokeWidth={7} styles={buildStyles({
                                                                        pathColor: "#FF5A00",
                                                                    })}>
                                                                        <img src={values.toolKit ? values.toolKit.image : "./images/revit.svg"} alt=" " className="rounded-circle img" />
                                                                    </CircularProgressbarWithChildren>

                                                                </div>
                                                            )
                                                        }) : ""}
                                                    </div>
                                                </a>
                                            </div>

                                        </div>)
                                    }) : ""}
                                    <div className='col-xl-2 col-md-4 col-sm-6 mb-3'>
                                        <div className='drag-box'>
                                            {/* <div className='plus-btn'>+</div> */}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="modal fade xy-form"
                                id={`${"checkFittModal"}${index}`}
                                tabIndex="-1"
                                role="dialog"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true">
                                <div className="modal-dialog form-w modal-dialog-centered " >
                                    <div className="modal-content">
                                        <div className="modal-header form-mdlheadr">
                                            <h4 className="modal-title mdl-heading"> Edit</h4>
                                            <button type="button" className="close modalclose-btn" data-dismiss="modal">&times;</button>
                                        </div>
                                        <div className="modal-body">
                                            <Checkfit id1={category.id} GetShortListData={GetShortListData} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>)
                    }) : ""}
                </div>


            </div>
            {loader}
        </div>

        // <div className="">
        //     <div className="title">ShortList<a className="btn btn-outline s-btn float-right" href="#" onClick={() => GetCheckFit()}> <i className="fa fa-check mr-1"></i> checkfit</a></div>
        //     <div>
        //         {ShortListData ? ShortListData.map((category, index) => {
        //             return (<>
        //                 <div className="bg-light mb-2">
        //                     <input type="checkbox" onChange={(e) => checkBox(e, category)} />
        //                     <span key={index + category}>{category.categoryName}</span>
        //                     <hr />
        //                     <div className="row "
        //                         key={index}
        //                         onDragOver={(e) => onDragOver(e)}
        //                         onDrop={(e) => onDrop(e, category.id)}>

        //                         <div className="h-25 text-white"> hii  </div>
        //                         {category ? category.shortLists.map((value) => {
        //                             return (<div key={value.studentId}
        //                                 onDragStart={(e) => onDragStart(e, value)}
        //                                 draggable
        //                                 className="col-lg-2 card big p-2"
        //                                 style={{
        //                                     width: 200,
        //                                     height: 300,
        //                                     padding: "20px",
        //                                     margin: 30
        //                                 }}>
        //                                 {(show == true && checkFit.result.categoryName == category.categoryName) ? <div className="text-right">{value.matchTotal} %</div> : ""}
        //                                 <div className="text-center">
        //                                     {(show == true && checkFit.result.categoryName == category.categoryName) ? <div style={{ width: "35%", marginLeft: "80px" }} className="mt-2 h-5">

        //                                         <CircularProgressbarWithChildren value={value.matchTotal} strokeWidth={9} styles={buildStyles({
        //                                             pathColor: "#ff4000",
        //                                         })}>
        //                                             {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
        //                                             <img style={{ width: 60, height: 60 }} className="rounded-circle" src={value ? value.students.profileImage : "./images/news.png"} alt="doge" />
        //                                         </CircularProgressbarWithChildren>
        //                                     </div> : <img src={value ? value.students.profileImage : "./images/news.png"} alt="" className="rounded-circle mt-2 " style={{ width: 72, height: 72 }} />}
        //                                 </div>
        //                                 <div className="text-center">
        //                                     <h4>{`${value.students.firstName} ${value.students.lastName}`}</h4>
        //                                     <p>{category.name}</p>
        //                                     <p>{value.students.country}</p>
        //                                     <hr />
        //                                 </div>
        //                             </div>)
        //                         }) : ""}
        //                     </div>
        //                 </div>
        //             </>)
        //         }) : ""}
        //     </div>

        //     <div className="bg-light mt-2 ">
        //         <span>shortList</span>
        //         <hr />
        //         <div className="row ml-2"
        //             onDragOver={(e) => onDragOver(e)}
        //             onDrop={(e) => onDrop(e, "shortList")}
        //         >
        //             {choiceData ? choiceData.map((value) => {
        //                 return (<div key={value.studentId}
        //                     onDragStart={(e) => onDragStart(e, value)}

        //                     draggable
        //                     className="col-3 card big"
        //                     style={{
        //                         width: 200,
        //                         height: 300,
        //                         margin: 30
        //                     }}
        //                 >
        //                     <div >
        //                         <img src={value ? value.students.profileImage : "./images/news.png"} alt="" className="rounded-circle w-50 ml-5 mt-2 h-5" with="10" />
        //                     </div>
        //                     <div className="text-center">
        //                         <h4>{`${value.students.firstName} ${value.students.lastName}`}</h4>
        //                         <p>{ }</p>
        //                         <p>{value.students.country}</p>
        //                         <hr />
        //                     </div>
        //                 </div>)
        //             }) : ""}
        //         </div>
        //     </div>
        // </div>
    )
}

export default Draganddrop

