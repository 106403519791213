export class Common {
  static getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  static UserId = () => {
    return localStorage.getItem("userid");
  };
  static AdminUserId = () => {
    return localStorage.getItem("adminuserid");
  };
  static ValidLogin = () => {
    const userid = localStorage.getItem("userid");
    if (!userid) window.location = "/home";
  };
  static ValidAdminLogin = () => {
    const userid = localStorage.getItem("adminuserid");
    if (!userid) window.location = "/home";
  };

  static LoginId = () => {
    return localStorage.getItem("loginid");
  };
  static ValidId = (id, userId) => {
    return this.UserId() === userId;
  }

  static AdminToken = () => {
    return localStorage.getItem("admintoken");
  };
  static Token = () => {
    return localStorage.getItem("token");
  };
  static Email = () => {
    return localStorage.getItem("email");
  };
  static AdminEmail = () => {
    return localStorage.getItem("adminemail");
  };
  static UserName = () => {
    return localStorage.getItem("username");
  };
  static UserType = () => {
    return localStorage.getItem("usertype");
  };
  static AdminUserType = () => {
    return localStorage.getItem("adminusertype");
  };
  static UserImage = () => {
    return localStorage.getItem("profileimage");
  };

}
export default Common;
