import React, { useState, useEffect, useRef } from "react";
import Common from "../../../Utility/Common";
import DefaultModel from "../../../Model/College/CollegeModel";
import service from "../../../Services/UtilityService";
import ServiceAPI from "../../../Utility/ServiceList";
import CollegeNav from "./CollegeNav";
import environment from '../../../environment';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useLoader from "../../Auth/Loader"
import AdminHeader from '../../Common/AdminHeader'
import Avtar from '../../Auth/Avtar'
import $ from "jquery";
import { useHistory } from "react-router-dom";
toast.configure()

const CollegeProfile = (props) => {
    Common.ValidLogin();
    const formData = new FormData();
    const history = useHistory();
    const id1 = props.id;
    const id12 = props.id12;
    const show = props.show == true ? true : ''
    const apiKey = "AIzaSyAVLTGiMQzxgYBYnviizjkO9g - HRLIEMH4";
    const mapApiJs = 'https://maps.googleapis.com/maps/api/js';
    const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';
    const searchInput = useRef('');
    const [model, setModel] = useState(DefaultModel);
    const [filename, setFileName] = useState('')
    const [imgurl, setImgurl] = useState("./images/user.svg");
    const [addres, setAddres] = useState({})
    const [cimg, setCimg] = useState("");
    const [border, setBorder] = useState("");
    const [loader, showLoader, hideLoader] = useLoader();
    const [showimage, setShowImage] = useState(false);
    const [showimage1, setShowImage1] = useState(false);
    const [count, setCount] = useState(0)
    const services = new service();
    const [showavtar, setShowAvtar] = useState(true)
    useEffect(() => {

        if ((id1 || id12) && props.show === true) {
            props.setShow(false)
            if (id12) {
                GetById(id12);
            }
            else {
                GetById(id1);
            }

        }
        else if (props.match || !props) {
            const id = props.location.search.split("=")[1];

            if (id) { GetById(id); }
        }
    }, [id1, show]);
    const ChangeHandler = (e) => {
        let value = {};
        if (e.target.type === "checkbox") {
            value[e.target.id] = e.target.checked;
        } else if (e.target.type === "select-one") {
            value[e.target.id] = e.target.options[e.target.selectedIndex].value;
        } else {
            value[e.target.id] = e.target.value;
        }
        setModel({ ...model, ...value });
        setBorder({ ...border, [e.target.id]: "" })
    };

    const UploadImage1 = async (e, img) => {
        if (validImage(e)) {
            $('.modal1').removeClass('modal2');
            setImgurl()
            setShowImage(true)
            var s = {
                bitcode: e.split(',')[0],
                userId: model.userId,
                role: "college",
                typeDir: "logo",
                extension: `${img.target.files[0].type.split("/")[1]}`
            }
            setCount(1)
            setFileName(img.target.files[0].name)
            var data = await services.Create(ServiceAPI.EncodedImageUplaodApi, s);
            var url = environment.imageUrl + data.result;
            setImgurl(url);
            setShowImage(false)
            let value = { logo: url };
            await setModel({ ...model, ...value });
        }
    }


    const UploadCoverImage = async (e) => {
        if (validImage(e)) {
            setCimg()
            setShowImage1(true)
            formData.append('file', e.target.files[0]);
            formData.append('imageType', '1');
            var data = await services.UploadImages(ServiceAPI.ImageUplaodApi, "coverImg", formData);
            var url = environment.imageUrl + data.result;
            setCimg(url);
            setShowImage1(false)
            let img = { coverImage: url }
            await setModel({ ...model, ...img });
        }
    }

    const validImage = (e) => {
        let file = e.target.files[0];
        const fileExtension = file.name.split(".").at(-1);
        const allowedFileTypes = ["jpg", "png", "jpeg"];
        if (!allowedFileTypes.includes(fileExtension)) {
            const newName = "No file chosen";
            const input = e.currentTarget;
            const previousFile = input.files[0];
            const newFile = new File([previousFile], newName);
            const dT = new DataTransfer();
            dT.items.add(newFile);
            input.files = dT.files;
            toast.error("File does not support Upload jpg,png,jpeg files", { theme: "colored", toastId: 'error3', autoClose: 3000 });
            return false;
        }
        return true;
    }

    const Create = async (e) => {
        e.preventDefault();
        var response = null;
        if (valid()) {
            let value = { userId: Common.UserId() };
            setModel({ ...model, ...value });
            showLoader();
            if (model.id === null) {
                response = await services.Create(ServiceAPI.CollegeCreateApi, model);
            } else {
                response = await services.Update(ServiceAPI.CollegeUpdateApi, model);
            }

            if (response && response.message === "Success") {
                localStorage.setItem("username", `${model.name}`);
                localStorage.setItem("profileImage", model.logo)
                if (props.id) {
                    props.GetcollegeProfiles(Common.LoginId());
                    $(`#${props.modalid}`).click();
                    if (count === 1) {
                        hideLoader();
                        setTimeout(
                            function () {
                                window.location = `/collegesprofile?id=${Common.LoginId()}`;
                                hideLoader();
                            }, 2000);
                        toast.success('Successfully Update', { theme: "colored", autoClose: 2000, toastId: "id1" })
                        setTimeout(
                            function () {
                                window.location = `/collegesprofile?id=${Common.LoginId()}`;
                                hideLoader();
                            }, 2000);
                    }
                    else {
                        $(`#${props.modalid}`).click();
                        toast.success('Successfully Update', { theme: "colored", autoClose: 2000, toastId: "id1" })

                        setTimeout(
                            function () {
                                $(`#${props.modalid}`).click();
                                hideLoader();
                                $('.modal-backdrop').removeClass('show ');
                                $('.modal').removeClass('show');
                                $('body').toggleClass('p-0');
                                $('body').removeClass('modal-open');
                                $('nav.navbar.navbar-expand-sm').addClass('p-0');
                                history.push(`/collegesprofile?id=${Common.LoginId()}`)
                            }, 2000);

                    }

                }
                else if (props.id2) {
                    hideLoader();
                    $(`#${props.modalid}`).click();
                    props.GetcollegeProfiles(Common.LoginId());
                    toast.success('Successfully Update', { theme: "colored", autoClose: 2000 })
                }
                else if (props.id12) {
                    $(`#${props.modalid}`).click();
                    props.GetcollegeProfiles(Common.LoginId());
                }

                else {
                    if (count == 1) {
                        hideLoader();
                        setTimeout(
                            function () {
                                hideLoader();
                                window.location = `/collegeprofile?id=${Common.LoginId()}`;
                            }, 2000);
                        toast.success('Successfully Update', { theme: "colored", autoClose: 2000, toastId: "id1" })
                        setTimeout(
                            function () {
                                hideLoader();
                                window.location = `/collegeprofile?id=${Common.LoginId()}`;
                            }, 2000);
                    }
                    else {
                        toast.success('Successfully Update', { theme: "colored", autoClose: 2000, toastId: "id1" })

                        setTimeout(
                            function () {
                                hideLoader();
                                $('.modal-backdrop').removeClass('show ');
                                $('.modal').removeClass('show');
                                $('body').toggleClass('p-0');
                                $('body').removeClass('modal-open');
                                $('nav.navbar.navbar-expand-sm').addClass('p-0');
                                history.push(`/collegeprofile?id=${Common.LoginId()}`)
                            }, 2000);

                    }
                }

            } else {
                hideLoader();
            }

        }
    };
    const GetById = async (id) => {
        if (!show) {
            $('.modal-backdrop').removeClass('show ');
            $('.modal').removeClass('show');
            $('body').toggleClass('p-0');
            $('body').removeClass('modal-open');
        }

        showLoader()
        var res = await services.GetStudentDataById(ServiceAPI.CollegeGetApi, Common.LoginId(), {
            "studentId": null,
            "companyId": null,
            'collegeId': Common.LoginId()
        });
        hideLoader()
        const data = await res.result;
        setModel(data);
        localStorage.setItem("profileImage", data.logo)
        localStorage.setItem("clprofile", JSON.stringify(data))
        setImgurl(data.logo);
        setCimg(data.coverImage);

    };
    const valid = () => {
        let Error = [];
        let firstName = '';
        let lastName = '';
        let name = "";
        let email = "";
        let logo = "";
        let coverImage = "";
        let contact = "";
        let website = "";
        let address = "";
        if (!Boolean(model.firstName.trim())) {
            Error.push("Administrator FirstName is required.");
            firstName = "borderred"
        }
        if (!Boolean(model.lastName.trim())) {
            Error.push("Administrator LastName is required.");
            lastName = "borderred"
        }
        if (!Boolean(model.name.trim())) {
            Error.push("College Name is required.");
            name = "borderred"
        }
        if (!Boolean(model.email.trim())) {
            Error.push("Email is required.");
            email = "borderred"
        }
        if (!Boolean(model.logo)) {
            Error.push("Logo is required.");
            logo = "borderred"
        }
        if (model.isDefaultLogo == true) {
            if (!Boolean(model.coverImage)) {
                Error.push("Leading Image is required.");
                coverImage = "borderred"
            }
        }

        if (!Boolean(model.contact.trim())) {
            Error.push("Contact is required.");
            contact = "borderred"
        }
        if (!Boolean(model.website.trim())) {
            Error.push("Website is required.");
            website = "borderred"
        }
        if (!Boolean(model.address.trim())) {
            Error.push("Address is required.");
            address = "borderred"
        }
        if (firstName || lastName || name || email || logo || coverImage || contact || website || address) {
            setBorder({ firstName, lastName, name, email, logo, coverImage, contact, website, address, });
        }
        if (Error.length === 0) {
            Error = [];
            return true;
        } else if (Error.length > 2) {
            toast.error('Please fill the required fields', { theme: "colored", toastId: 'error1' });
            return false;
        } else if (Error.length <= 2) {
            Error.forEach((element, index) => {
                toast.error(element, { theme: "colored", toastId: `${[index]}` });
            });
            return false;
        }
    };
    const loadAsyncScript = async (src) => {
        return new Promise(resolve => {
            const script = document.createElement("script");
            Object.assign(script, {
                type: "text/javascript",
                async: true,
                src
            })
            script.addEventListener("load", () => resolve(script));
            document.head.appendChild(script);
        })
    }
    // init gmap script
    const initMapScript = async () => {
        // if script already loaded
        if (window.google) {
            return await Promise.resolve();
        }
        const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
        return await loadAsyncScript(src);
    }
    const extractAddress = (place) => {
        const address = {
            city: "",
            state: "",
            country: "",
            pincode: "",
            address: "",
            plain() {
                const city = city ? city + ", " : "";
                const pincode = pincode ? pincode + ", " : "";
                const state = state ? state + ", " : "";
                const address = address ? address + ", " : "";
                const country = country ? country + ", " : "";
                return city + pincode + state + country + address;
            }
        }

        if (!Array.isArray(place?.address_components)) {
            return address;
        }

        place.address_components.forEach(component => {
            const types = component.types;
            const value = component.long_name;

            if (types.includes("locality")) {
                address.city = value;
            }

            if (types.includes("administrative_area_level_1")) {
                address.state = value;
            }

            if (types.includes("postal_code")) {
                address.pincode = value;
            }

            if (types.includes("country")) {
                address.country = value;
            }
            if (place) {
                var adr = place.formatted_address.split(",")
                address.address = place.formatted_address.split(",").length > 4 ? `${adr[0]}${adr[1]}` : adr[0];
            }
        });
        return address;
    }

    const onChangeAddress = async (autocomplete) => {
        const place = await autocomplete.getPlace();
        var values = extractAddress(place)
        setModel({ ...JSON.parse(localStorage.getItem("clprofile")), ...values });
        setAddres(extractAddress(place));
    }

    const initAutocomplete = () => {
        if (!searchInput.current) {
            return;
        }
        else {
            const autocomplete = new window.google.maps.places.Autocomplete(searchInput.current);
            autocomplete.setFields(["address_component", "geometry", "formatted_address"]);
            autocomplete.addListener("place_changed", () => onChangeAddress(autocomplete));
        }
    }
    useEffect(() => {
        initMapScript().then(() => initAutocomplete())
    }, [searchInput.current]);
    const closeModal = () => {

        setShowAvtar(false)
        setTimeout(() => {
            setShowAvtar(true)
            $("#exampleMediaAdd").removeClass("modal2")
        }, 10);
    }
    const customModal = () => {
        $("#exampleMediaAdd").addClass("modal2")
    }
    return (
        <>
            {props.id || props.id2 ? "" : <AdminHeader heading="" subheading="Manage Profile" />}
            <form onSubmit={Create} >
                <div className="theme-container-admin container-fluid">
                    {props.id || props.id2 ? "" : <CollegeNav profile="active"></CollegeNav>}
                    <div className="row">
                        <div className="col-md-6 mx-auto detail-box form-sec pr-1">
                            <div className="row m-0 align-items-center">
                                <div className="col-md-2 pr-0">
                                    {imgurl ? <img src={imgurl} className="profile-login sm" />
                                        : showimage ? <span className="img mt-3">
                                            <div className="admin-img-preview mt-3">
                                                <div className="lds-roller sm"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                            </div>
                                        </span> : <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/user.svg`} className="ad-img img-fluid" />}
                                </div>
                                <div className="col-md-10" id="mod" >
                                    {/* <div className="col-md-10"> */}
                                    <div className="label">
                                        Choose logo<span className="required">*</span>
                                    </div>
                                    <div
                                        id="logo"
                                        className="xu-input xpading">
                                        <a className="btn btn-outline choose-file" id="logo" onClick={() => customModal()}>Choose file</a>
                                        <span className="file-name">{filename ? filename : "No file chosen"}</span>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="label">Administrator First Name<span className="required">*</span></div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="firstName"
                                        className={border.firstName ? border.firstName : "xu-input"}
                                        placeholder="FirstName"
                                        value={model.firstName}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Administrator Last Name<span className="required">*</span></div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="lastName"
                                        className={border.lastName ? border.lastName : "xu-input"}
                                        placeholder="Last Name"
                                        value={model.lastName}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">College Name<span className="required">*</span></div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="name"
                                        className={border.name ? border.name : "xu-input"}
                                        placeholder="College Name"
                                        value={model.name}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>

                                <div className="col-md-6">
                                    <div className="label">Contact Number<span className="required">*</span></div>
                                    <input
                                        autoComplete="none"
                                        type="number"
                                        pattern="/[0-9]{10}/"
                                        maxLength={15}
                                        id="contact"
                                        className={border.contact ? border.contact : "xu-input"}
                                        placeholder="Contact"
                                        value={model.contact}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Email<span className="required">*</span></div>
                                    <input
                                        autoComplete="none"
                                        type="email"
                                        id="email"
                                        className={border.email ? border.email : "xu-input"}
                                        placeholder="Email"
                                        value={model.email}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Website<span className="required">*</span></div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="website"
                                        className={border.website ? border.website : "xu-input"}
                                        placeholder="Website"
                                        value={model.website}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Instagram Link</div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="instagramUrl"
                                        className="xu-input"
                                        placeholder="URL"
                                        value={model.instagramUrl}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Facebook Link</div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="facebookUrl"
                                        className="xu-input"
                                        placeholder="URL"
                                        value={model.facebookUrl}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Twitter Link</div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="twitterUrl"
                                        className="xu-input"
                                        placeholder="URL"
                                        value={model.twitterUrl}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>

                            </div>{" "}
                        </div>{" "}
                        <div className="col-md-6 mx-auto detail-box form-sec pl-1 profile-box">
                            <div className="row m-0 align-items-center">
                                <div className="col-md-2">
                                    {cimg ? <img src={cimg} className="ad-img img-fluid" />
                                        : showimage1 ? <span className="img mt-3">

                                            <div className="admin-img-preview mt-3">
                                                <div className="lds-roller sm"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                            </div>
                                        </span> : <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/default-img.jpg`} className="ad-img img-fluid" />}

                                </div>
                                <div className="col-md-10">
                                    <div className="label">Leading Image <span className="">(JPG or PNG)</span></div>
                                    <input
                                        type="file"
                                        id="coverImage"
                                        className="xu-input xpading"
                                        placeholder="Image"
                                        accept="image/jpeg, image/png,  image/jpg"
                                        onChange={(e) => UploadCoverImage(e)}
                                    ></input>
                                </div>

                                <div className="col-md-12">
                                    <div className="label">Address<span className="required">*</span></div>
                                    <input
                                        type="text"
                                        id="address"
                                        className={border.address ? border.address : "xu-input"}
                                        placeholder="Address"
                                        value={model.address}
                                        ref={searchInput}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>

                                <div className="col-md-6">
                                    <div className="label">Postal Code</div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="pincode"
                                        className="xu-input"
                                        placeholder="Pincode"
                                        value={model.pincode}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">City</div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="city"
                                        className="xu-input"
                                        placeholder="City"
                                        value={model.city}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">State/Province</div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="state"
                                        className="xu-input"
                                        placeholder="State"
                                        value={model.state}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Country</div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="country"
                                        className="xu-input"
                                        placeholder="Country"
                                        value={model.country}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-12">
                                    <div className="label">show Leading Image</div>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            id="isDefaultLogo"
                                            checked={model.isDefaultLogo}
                                            onChange={(e) => ChangeHandler(e)}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>\
                                <div className="col-md-12 sub">
                                    <button
                                        className="btn btn-outline a-btn mt-0 float-right"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="modal modal1 bg-modal"

                    id={`exampleMediaAdd`}
                    tabIndex="-1"

                    aria-labelledby="exampleMediaAdd "
                >
                    <div className="modal-dialog modal-dialog-centered " >
                        <div className="modal-content">
                            <div className="modal-header flex">
                                {/* <div className="modal-header "> */}
                                <div className="preview-title">PROFILE IMAGE </div>
                                <button type="button" className="close modalclose-btn" data-dismiss-modal="modal2" onClick={() => closeModal()}>&times;</button>
                                {/* </div> */}
                            </div>
                            <div className="modal-body">
                                {showavtar ? <Avtar UploadImage={UploadImage1} /> : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            {loader}
        </>
    );


}
export default CollegeProfile;
