import { Link } from 'react-router-dom'
import Common from '../../../Utility/Common'

const StudentNav = (props) => {

   return (
      <>
         <div className="scroll-tabs" scroll="true">

            <ul className="nav nav-tabs admin-nav" id="pills-tab" role="tablist">

               <li className="active">
                  <Link className={"nav-link " + props.profile} to={`/StudentProfile?id=${Common.LoginId()}`} >About Me</Link>
               </li>

               <li className="">
                  <Link className={"nav-link " + props.education} to="/StudentEducationList"  >Education</Link>
               </li>
               <li className="">
                  <Link className={"nav-link " + props.jobhistory} to="/StudentJobHistoryList"  >Experience</Link>
               </li>
               <li className="">
                  <Link className={"nav-link " + props.portfolio} to="/StudentPortFolioList" >Portfolio</Link>
               </li>
               <li className="">
                  <Link className={"nav-link " + props.banner} to="/studentbannerlist" >Banner</Link>
               </li>
               <li className="">
                  <Link className={"nav-link " + props.achievement} to="/StudentAchievementList"  >Achievement</Link>
               </li>

               <li className="">
                  <Link className={"nav-link " + props.changepassword} to="/studentchangepassword"   >Change Password</Link>
               </li>
            </ul>
         </div>

      </>

   )

}

export default StudentNav