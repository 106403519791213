import React, { useState, useEffect } from "react";
import Common from "../../../Utility/Common";
import DefaultModel from "../../../Model/College/CollegeCourseModel";
import service from "../../../Services/UtilityService";
import ServiceAPI from "../../../Utility/ServiceList";
import CollegeNav from "./CollegeNav";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useLoader from "../../Auth/Loader"
import AdminHeader from '../../Common/AdminHeader'
import Multiselect from 'multiselect-react-dropdown';
import $ from "jquery";
import { useHistory } from "react-router-dom";

toast.configure()

const CollegeCourse = (props) => {
  const history = useHistory()
  Common.ValidLogin();
  const formData = new FormData();
  localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
  const [model, setModel] = useState(DefaultModel);
  const [industry, setIndustry] = useState([]);
  const [course, setCourse] = useState([]);
  const [qualificationType, setQualificationType] = useState([]);
  const services = new service();
  const id1 = props.id;
  const show = props.show == true ? true : ''
  const [loader, showLoader, hideLoader] = useLoader();
  const [imgurl, setImgurl] = useState('');
  const [border, setBorder] = useState("");
  const [studyType, setStudyType] = useState([]);
  const [pageModel, setPageModel] = useState({
    "pageNum": 1,
    "pageSize": 50,
    "isActive": true
  });

  useEffect(() => {

    if (props.id2 === "0" && props.show === true) {
      props.setShow4(false)
      StudyTypeGetAll();
      BindIndustry();
      QualificationGetAll();
      StudyTypeGetAll();
    }
    else if (props.match || !props) {
      StudyTypeGetAll();
      BindIndustry();
      QualificationGetAll();
      StudyTypeGetAll();

      const id = props.location.search.split("=")[1]
      if (id) { GetById(id); }
    }

  }, [id1, show]);

  const QualificationGetAll = async () => {
    var res = await services.PostGetAll(ServiceAPI.QualificationGetAllApi, pageModel);
    const data = await res.result.map((item) => {
      return {
        name: item.name,
        value: item.name,
        id: item.id
      }
    });;
    setQualificationType(data)
  }
  const GetById = async (id) => {
    $('.modal-backdrop').removeClass('show ');
    $('.modal').removeClass('show');
    $('body').toggleClass('p-0');
    $('body').removeClass('modal-open');
    showLoader()
    setModel(DefaultModel);
    var res = await services.GetById(ServiceAPI.CollegeCourseGetApi, id);
    hideLoader()
    if (res && res.message === "Success") {
      const banner = await res.result;
      await setModel(banner);
    }
  };
  const StudyTypeGetAll = async () => {
    showLoader()
    var res = await services.PostGetAll(ServiceAPI.StudyTypeGetAllApi, {
      "pageNum": 1,
      "pageSize": 50,
      "isActive": true
    });
    hideLoader()
    const data = await res.result;
    setStudyType(data)
  }
  const BindIndustry = async () => {
    var res = await services.PostGetAll(ServiceAPI.IndustryGetAllApi, pageModel);
    const data = await res.result.filter((data) => data.active !== false);
    await setIndustry(data);
  }
  const ChangeHandler = (e) => {

    let value = {};
    if (e.target.type === "checkbox") {
      value[e.target.id] = e.target.checked;
    }
    else if (e.target.type === "select-one") {
      value[e.target.id] = e.target.options[e.target.selectedIndex].value;
      if (e.target.id === 'industryId') {
        if (e.target.options[e.target.selectedIndex].value != 0) {
        }
      }
    }
    else {
      value[e.target.id] = e.target.value;
    }
    setModel({ ...model, ...value });
    setBorder({ ...border, [e.target.id]: "" })
  };
  const AddValue = async (e) => {
    let value = { studyTypeNames: [...model.studyTypeNames, { id: e.slice(-1)[0].id, name: e.slice(-1)[0].name }] };
    await setModel({ ...model, ...value });
  }
  const RemoveValue = async (e) => {
    let value = { studyTypeNames: e }
    await setModel({ ...model, ...value });
  }
  const Create = async (e) => {
    e.preventDefault();
    var response = null;
    if (valid()) {
      showLoader();
      if (model.id === null) {
        response = await services.Create(ServiceAPI.CollegeCourseCreateApi, model);
      } else {
        response = await services.Update(ServiceAPI.CollegeCourseUpdateApi, { ...model, "course": '', "industry": "" });
      }
      if (response && response.message === "Success") {
        if (props.id) {
          hideLoader();
          props.GetcollegeCourses(Common.LoginId());
          toast.success('Successful', { theme: "colored", autoClose: 1000, toastId: 'success1' })
          setTimeout(() => {
            $('.modal-backdrop').removeClass('show ');
            $('.modal').removeClass('show');
            $('body').toggleClass('p-0');
            $('body').removeClass('modal-open');
            $('nav.navbar.navbar-expand-sm').addClass('p-0');
          }, 1000)
        }
        else if (props.id2) {
          hideLoader();
          $(`#${props.modalid}`).click();
          props.GetcollegeCourses(Common.LoginId());
          toast.success('Successful', { theme: "colored", autoClose: 2000, toastId: 'success2' })
          setModel(DefaultModel);
          setImgurl("");
          setCourse([])
          setIndustry([]);
          setTimeout(() => {
            BindIndustry();
            $(`#${props.modalid}`).click();
            $('.modal-backdrop').removeClass('show ');
            $('.modal').removeClass('show');
            $('body').toggleClass('p-0');
            $('body').removeClass('modal-open');
            $('nav.navbar.navbar-expand-sm').addClass('p-0');
          }, 2000)
        }
        else {
          toast.success('Successful', { theme: "colored", autoClose: 2000, toastId: 'success3' })
          setTimeout(() => {
            history.push("/collegecourselist")
            setModel(DefaultModel);
          }, 1000);

        }
      }
      else {
        hideLoader()
      }
    }
  };

  const valid = () => {
    let Error = [];
    let industry = "";
    let type = "";
    let collegeCourseName = "";
    let duration = "";
    let nfqlLevel = "";
    let imageUrl = "";
    let studyTypeNames = "";

    if (!Boolean(model.industryId) || model.industryId === "0") {
      Error.push("Valid Industry required");
      industry = "borderred";
    }
    if (!Boolean(model.collegeCourseName)) {
      Error.push("Valid Course required");
      collegeCourseName = "borderred";
    }
    if (!Boolean(model.type) || model.type === "0") {
      Error.push("Valid Type required");
      type = "borderred";
    }
    if (!Boolean(model.duration) || model.duration === "0") {
      Error.push("Valid Duration required");
      duration = "borderred";
    }
    if (!Boolean(model.nfqlLevel) || model.nfqlLevel === "0") {
      Error.push("Valid NFQL Level required");
      nfqlLevel = "borderred";
    }
    if (model.studyTypeNames.length == 0) {
      Error.push("study Type is required.");
      studyTypeNames = "borderred"
    }

    if (industry || collegeCourseName || type || duration || nfqlLevel || imageUrl || studyTypeNames) {
      setBorder({ industry, collegeCourseName, type, duration, nfqlLevel, imageUrl, studyTypeNames });
    }
    if (Error.length === 0) {
      Error = [];
      return true;
    } else if (Error.length > 2) {
      toast.error('Please fill the required fields', { theme: "colored", toastId: 'error1' });
      return false;
    } else if (Error.length <= 2) {
      Error.forEach((element, index) => {
        toast.error(element, { theme: "colored", toastId: `${[index]}` });
      });
      return false;
    }
  };
  const back = () => {
    history.push("/collegecourselist");
  }
  const DeleteCourse = async (id) => {
    const res = await services.Delete(ServiceAPI.CollegeCourseDeleteApi, id)
    if (res.message == "Success") {
      if (props.id) {
        props.GetcollegeCourses(Common.LoginId());
        toast.success('Successful Deleted...', { theme: "colored", autoClose: 2000, toastId: 'success1' })
        setTimeout(() => {
          hideLoader();
          $('.modal-backdrop').removeClass('show ');
          $('.modal').removeClass('show');
          $('body').toggleClass('p-0');
          $('body').removeClass('modal-open');
          $('nav.navbar.navbar-expand-sm').addClass('p-0');

        }, 2000)
      }
    }
  }
  return (
    <>
      {props.id || props.id2 ? "" : <AdminHeader heading="" subheading="Manage Course" />}
      <form onSubmit={Create} autoComplete="off">
        <div className="theme-container-admin container-fluid">
          {props.id || props.id2 ? "" : <CollegeNav course="active"></CollegeNav>}
          <div className="row">
            <div className="  mx-auto detail-box form-sec">
              <div className="row m-0 align-items-center">

                <div className="col-md-6">
                  <div className="label">Industry<span className="required">*</span></div>
                  <select className={border.industry ? border.industry : "xu-input"} id="industryId" value={model.industryId} onChange={(e) => ChangeHandler(e)}>
                    <option value="0">Select</option>
                    {
                      industry.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="col-md-6">
                  <div className="label">Course<span className="required">*</span></div>
                  <input
                    type="text"
                    id="collegeCourseName"
                    className={border.collegeCourseCame ? border.collegeCourseName : "xu-input"}
                    placeholder="Course name"
                    value={model.collegeCourseName}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                </div>
                <div className="col-md-6">
                  <div className="label">Qualification<span className="required">*</span></div>
                  <select className={border.type ? border.type : "xu-input"} id="type" value={model.type} onChange={(e) => ChangeHandler(e)}>
                    <option value="0">Select</option>
                    {
                      qualificationType.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="col-md-6">
                  <div className="label">Duration<span className="required">*</span></div>
                  <select className={border.duration ? border.duration : "xu-input"} id="duration" value={model.duration} onChange={(e) => ChangeHandler(e)}>
                    <option value="0">Select</option>
                    {
                      [1, 2, 3, 4].map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-6">
                  <div className="label">NQF Level<span className="required">*</span></div>
                  <select className={border.nfqlLevel ? border.nfqlLevel : "xu-input"} id="nfqlLevel" value={model.nfqlLevel} onChange={(e) => ChangeHandler(e)}>
                    <option value="0">Select</option>
                    {
                      ["NQF 5", "NQF 6", "NQF 7", "NQF 8", "NQF 9", "NQF 10"].map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-6">
                  <div className="label">Website URL</div>
                  <input
                    type="text"
                    id="url"
                    className="xu-input"
                    placeholder="Website URL"
                    value={model.url}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                </div>
                <div className="col-md-6">
                  <div className="label">Study Type<span className="required">*</span></div>
                  <Multiselect
                    className="xu-input"
                    // className={border.Course ? border.Course : "xu-input"}
                    id="studyTypeNames"
                    onSelect={(e) => AddValue(e)}
                    selectedValues={model.studyTypeNames}
                    options={studyType}
                    displayValue="name"
                    onRemove={(e) => RemoveValue(e)}
                  />
                </div>
                <div className="col-md-6">
                  <div className="label">Active</div>
                  <label className="switch">
                    <input
                      className=""
                      type="checkbox"
                      id="active"
                      checked={model.active}
                      onChange={(e) => ChangeHandler(e)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                {props.id ? < div className=" delete">
                  <a
                    className="fa fa-trash delete"
                    onClick={() => DeleteCourse(model.id)}
                  >

                  </a>
                </div> : ""}
                <div className="col-md-12">
                  <button
                    className="btn btn-outline a-btn mt-0 float-right auto"
                    type="submit"

                  >
                    Submit
                  </button>
                </div>
              </div>{" "}
            </div>{" "}
          </div>
          {props.id || props.id2 ? "" : <div className='back-icon' onClick={back}></div>}
        </div>
      </form>
      {loader}
    </>
  );

}
export default CollegeCourse