import React, { useState, useEffect } from "react";
import ServiceAPI from "../../Utility/ServiceList";
import service from '../../Services/UtilityService';
import AlphabetList from 'react-alphabet-list';
import { Tag } from "antd";
import "antd/dist/antd.css";
import $ from "jquery";
import useLoader from '../Auth/Loader'
// import Multiselect from "multiselect-react-dropdown";
import Multiselect from "../Auth/multiselect-dropdown";
import Select from 'react-select'
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
toast.configure()
const StudentList = () => {
  const history = useHistory();
  const style = {
    paddingRight: "0px",
    color: "black"
  };

  const profile = (id, userId) => {
    localStorage.setItem("suserid", userId)
    history.push('studentsprofile?id=' + id)
  }
  const [loader, showLoader, hideLoader] = useLoader();
  var activetest = false; var activetest1 = false; var activetest2 = false; var activetest3 = false; var activetest4 = false; var activetest5 = false; var activetest6 = false
  const [studentlist, setStudentList] = useState([]);
  const [skillsetlist, setSkillSetList] = useState();
  const [alldata, setAllData] = useState([]);
  const [industryData, setIndustryData] = useState([]);
  const [clear, setClear] = useState(true)
  const [industrylist, setIndustryList] = useState()
  const [qualificationlist, setQualificationList] = useState()
  const [NQFLData, setNQFLData] = useState([]);
  const [achiever, setAchiever] = useState(false)
  const [coutnrylist, setCountryList] = useState([]);
  const [statelist, setStateList] = useState([]);
  const [citylist, setCityList] = useState([]);
  const [count, setCount] = useState()
  const [count1, setCount1] = useState()
  const [count2, setCount2] = useState()
  const [count3, setCount3] = useState()
  const [count5, setCount5] = useState()
  const [showcount, setShowCount] = useState(false);
  const [suggestionData, setSuggestionData] = useState([]);
  const [suggestionvalue, setSuggestionValue] = useState()
  const [locationData, setLocationData] = useState(
    {
      "country": "",
      "state": "",
      "city": ""
    }
  );
  const [filtermodel, setFilterModel] = useState(
    {
      "country": "", "city": "", "state": "",
      "industry": [], "graduateYear": [], "nqfl": [],
      "qualification": [], "studyType": [], "sizeOfEmployees": 0,
      "topAchiever": false, "isHiringActive": false,
      "skillsets": [], "searchValue": ""
    }
  );
  var services = new service();
  localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
  useEffect(() => {
    GetAll();
    GetIndustry();
    getLocation();
    GetQualification();
    GetTollkit();
  },
    []);
  const fetchStudentData = (item) => {
    const url = alldata.map((data) => {
      if (data.id === item.split(",")[1]) {
        if (data.educations.length !== 0) {
          return `${data.coverImage} --,${data.isPursuing} --,${data.courseDuration} --,${data.pursuingYearNum} --,${data?.collegeNavigation?.logo} --,${data.educations[0].studyProgram ? `${data.educations[0].studyProgram}` : "null"} --,${data.profileImage} --,${data.designation} --,${data.lastCourseYear} --,${data.educations[0].institute} --,${data.listImage} --,${data.educations[0].industry ? data.educations[0].industry.name : "null"}`
        }
        return `${data?.coverImage} --,${data?.isPursuing} --,${data?.courseDuration} --,${data?.pursuingYearNum} --,${data?.collegeNavigation?.logo} --,null --,${data?.profileImage} --,${data?.designation ? data?.designation : "null"} --,${data?.lastCourseYear} --,null --,${data?.listImage} --,null`
      }
    }
    )
    // console.log("urlo", url.join('').split(' --,'))
    return url.join('').split(' --,')
  }
  const GetTollkit = async () => {

    var res = await services.PostGetAll(ServiceAPI.ToolKitGetAllApi, {
      "pageNum": 1,
      "pageSize": 100,
      "isActive": true,
      "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
    });

    const data = await res.result.map((item) => {
      return {
        name: item.name,
        value: item.name,
        id: item.id
      }
    });
    data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    setSkillSetList(data);

  }
  const GetQualification = async () => {
    var res = await services.PostAnonymous(ServiceAPI.QualificationGetAllApi, {
      "pageNum": 1,
      "pageSize": 100,
      "isActive": true,
      "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
    });
    const industryData = await res.result.map((item) => {
      return {
        name: item.name,
        value: item.name,
        id: item.id
      }
    });
    industryData.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    setQualificationList(industryData)
  };
  const GetIndustry = async () => {
    var res = await services.PostAnonymous(ServiceAPI.IndustryGetAllApi, {
      "pageNum": 1,
      "pageSize": 100,
      "isActive": true
    });
    const industryData = await res.result.map((item) => {
      return {
        name: item.name,
        value: item.name,
        id: item.id
      }
    });
    industryData.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    setIndustryList(industryData)
  };
  async function GetAll() {
    $('.modal-backdrop').removeClass('show ');
    $('.modal').removeClass('show');
    $('body').toggleClass('p-0');
    $('body').removeClass('modal-open');
    showLoader()
    var res = await services.Create(ServiceAPI.StudentFilterApi, filtermodel);
    hideLoader()
    const data1 = await res.result;
    setAllData(data1);
    setStudentList(data1.map((data) => `${data.firstName + ' ' + data.lastName},${data.id},${data.userId}`));
  }

  const getLocation = async () => {
    var res = await services.GetAll(ServiceAPI.getLocationCountryApi);
    const data1 = await res.result.map((item) => {
      return { value: item, label: item, type: "country" }
    });
    setCountryList([{ value: 'select', label: 'select', type: "country" }, ...data1])
  }

  const GetState = async (state) => {
    var res = await services.GetLocationById(ServiceAPI.getLocationStateApi, state, "country");
    const data1 = await res.result.map((item) => {
      return { value: item, label: item, type: "state" }
    });
    setStateList([{ value: 'select', label: 'select', type: "state" }, ...data1])
  }

  const GetCity = async (city) => {
    var res = await services.GetLocationById(ServiceAPI.getLocationCityApi, city, "state");
    const data1 = await res.result.map((item) => {
      return { value: item, label: item, type: "city" }
    });
    setCityList([{ value: 'select', label: 'select', type: "city" }, ...data1])
  }

  const filterData = async (filtermodel) => {
    setFilterModel({ ...filtermodel })
    var res = await services.Create(ServiceAPI.StudentFilterApi, filtermodel);
    if (res.message == "Success") {
      const data1 = await res.result;
      setAllData(data1);
      setStudentList([]);
      setStudentList(data1.map((data) => `${data.firstName + ' ' + data.lastName},${data.id},${data.userId}`));
    }
    else {
      setStudentList([])
    }
  }

  const AddValue = async (e) => {
    if (e.type === "country") {
      const value = e.value
      if (value !== "select...") {
        setStateList([])
        setCityList([])
        GetState(value)
        setFilterModel({ ...filtermodel, "country": value })
        setLocationData({ ...locationData, "country": value });
        filterData({ ...filtermodel, "country": value })
      }
      else {
        setFilterModel({ ...filtermodel, ...locationData, "state": "" })
        setLocationData({ ...locationData, "state": '' });
        setStateList([])
        setCityList([])
        filterData({ ...filtermodel, ...locationData, "country": '', "state": '', "city": '' })
      }
    }
    else if (e.type === "state") {
      const value = e.value
      if (value !== "select...") {
        setCityList([])
        GetCity(value);
        setFilterModel({ ...filtermodel, ...filtermodel.location, "state": value })
        setLocationData({ ...locationData, "state": value });
        filterData({ ...filtermodel, "state": value, })
      }
      else {
        setFilterModel({ ...filtermodel, ...locationData, "state": '' })
        setLocationData({ ...locationData, "state": '' });
        setCityList([])
        filterData({ ...filtermodel, "state": '', "city": '' })
      }
    }
    else if (e.type === "city") {
      const value = e.value
      if (value !== "select...") {
        setFilterModel({ ...filtermodel, ...filtermodel.location, "city": value })
        setLocationData({ ...locationData, "city": value });
        filterData({ ...filtermodel, ...filtermodel.location, "city": value })
      }
      else {
        setFilterModel({ ...filtermodel, ...filtermodel.location, "city": '' })
        setLocationData({ ...locationData, "city": "" });
        filterData({ ...filtermodel, ...filtermodel.location, "city": '' })
      }
    }
    else if (e[0].type === "NQFL") {
      const value = e.map((item, index) => item.name)
      setNQFLData(value);
    }
    else {
      const value = e.map((item, index) => item.name)
      const valueId = e.map((item, index) => item.id)
      filterData({ ...filtermodel, "industry": valueId })
      setIndustryData(value);
    }
  }

  const setDropdown = () => {
    if (activetest === false) {
      $(".dropdown1-content").addClass("active");
      $(".alpha-list").addClass('filter-expand');
      $(".cards-list").addClass('filter-expand');
      $(".dropdown1 .btn-outline").addClass("active"); $(".qual-menu").removeClass("show");
      $("#dropdownMenu4").removeClass("active"); $(".styd-menu").removeClass("show");
      $("#dropdownMenu5").removeClass("active"); $(".top-menu").removeClass("show")
      $("#dropdownMenu6").removeClass("active"); $(".skill-menu").removeClass("show")
      $("#dropdownMenu7").removeClass("active");
      $(".ind-menu").removeClass("show"); $("#dropdownMenu3").removeClass("active")
      $("#dropdownMenu2").removeClass("active"); $(".location-menu").removeClass("show")
      activetest = true;
    }
    else {
      $(".dropdown1-content").removeClass("active")
      $(".alpha-list").removeClass('filter-expand')
      $(".cards-list").removeClass('filter-expand')
      $(".dropdown1 .btn-outline").removeClass("active")
      if (count || count1 || count2 || count3 || count5 || statelist.length !== 0 || achiever == true) {
        $(".dropdown1 .btn-outline").addClass("active")

      }
      activetest = false;
    }
  }
  const setDropdown1 = () => {
    if (activetest1 == false) {
      $(".ind-menu").addClass("show")
      $("#dropdownMenu2").addClass("active")
      $(".location-menu").removeClass("show")
      $("#dropdownMenu3").removeClass("active")
      $(".qual-menu").removeClass("show")
      $("#dropdownMenu4").removeClass("active")
      $(".top-menu").removeClass("show")
      $("#dropdownMenu6").removeClass("active")
      $(".styd-menu").removeClass("show")
      $("#dropdownMenu5").removeClass("active")
      $(".skill-menu").removeClass("show")
      $("#dropdownMenu7").removeClass("active")
      activetest1 = true;
    }
    else {
      $(".ind-menu").removeClass("show")
      $("#dropdownMenu2").removeClass("active")
      activetest1 = false;
    }
  }
  const setDropdown2 = () => {
    if (activetest2 == false) {
      $(".location-menu").addClass("show")
      $("#dropdownMenu3").addClass("active")
      $(".ind-menu").removeClass("show")
      $("#dropdownMenu2").removeClass("active")
      $(".qual-menu").removeClass("show")
      $("#dropdownMenu4").removeClass("active")
      $(".top-menu").removeClass("show")
      $("#dropdownMenu6").removeClass("active")
      $(".styd-menu").removeClass("show")
      $("#dropdownMenu5").removeClass("active");
      $(".skill-menu").removeClass("show")
      $("#dropdownMenu7").removeClass("active")
      activetest2 = true;
    }
    else {
      $(".location-menu").removeClass("show")
      $("#dropdownMenu3").removeClass("active")
      activetest2 = false;
    }
  }
  const setDropdown3 = () => {
    if (activetest3 === false) {
      $(".qual-menu").addClass("show")
      $("#dropdownMenu4").addClass("active")
      $(".location-menu").removeClass("show")
      $("#dropdownMenu3").removeClass("active")
      $(".ind-menu").removeClass("show")
      $("#dropdownMenu2").removeClass("active")
      $(".top-menu").removeClass("show")
      $("#dropdownMenu6").removeClass("active")
      $(".styd-menu").removeClass("show")
      $("#dropdownMenu5").removeClass("active");
      $(".skill-menu").removeClass("show")
      $("#dropdownMenu7").removeClass("active")
      activetest3 = true;
    }
    else {
      $(".qual-menu").removeClass("show")
      $("#dropdownMenu4").removeClass("active")
      activetest3 = false;
    }
  }
  const setDropdown4 = () => {
    if (activetest5 == false) {
      $(".styd-menu").addClass("show")
      $("#dropdownMenu5").addClass("active")
      $(".top-menu").removeClass("show")
      $("#dropdownMenu6").removeClass("active")
      $(".qual-menu").removeClass("show")
      $("#dropdownMenu4").removeClass("active")
      $(".location-menu").removeClass("show")
      $("#dropdownMenu3").removeClass("active")
      $(".ind-menu").removeClass("show")
      $("#dropdownMenu2").removeClass("active");
      $(".skill-menu").removeClass("show")
      $("#dropdownMenu7").removeClass("active")
      activetest5 = true;
    }
    else {
      $(".styd-menu").removeClass("show")
      $("#dropdownMenu5").removeClass("active")
      activetest5 = false;
    }
  }
  const setDropdown5 = () => {
    if (activetest4 == false) {
      $(".top-menu").addClass("show")
      $("#dropdownMenu6").addClass("active")
      $(".qual-menu").removeClass("show")
      $("#dropdownMenu4").removeClass("active")
      $(".location-menu").removeClass("show")
      $("#dropdownMenu3").removeClass("active")
      $(".ind-menu").removeClass("show")
      $("#dropdownMenu2").removeClass("active")
      $(".styd-menu").removeClass("show")
      $("#dropdownMenu5").removeClass("active")
      $(".skill-menu").removeClass("show")
      $("#dropdownMenu7").removeClass("active")
      activetest4 = true;
    }
    else {
      $(".top-menu").removeClass("show")
      $("#dropdownMenu6").removeClass("active")
      activetest4 = false;
    }
  }
  const setDropdown6 = () => {
    if (activetest6 == false) {
      $(".skill-menu").addClass("show")
      $("#dropdownMenu7").addClass("active")
      $(".qual-menu").removeClass("show")
      $("#dropdownMenu4").removeClass("active")
      $(".location-menu").removeClass("show")
      $("#dropdownMenu3").removeClass("active")
      $(".ind-menu").removeClass("show")
      $("#dropdownMenu2").removeClass("active")
      $(".styd-menu").removeClass("show")
      $("#dropdownMenu5").removeClass("active")
      $(".top-menu").removeClass("show")
      $("#dropdownMenu6").removeClass("active")
      activetest6 = true;
    }
    else {
      $(".skill-menu").removeClass("show")
      $("#dropdownMenu7").removeClass("active")
      activetest6 = false;
    }
  }
  const setFilter = () => {
    $(".dropdown1-content").removeClass("active")
    $(".alpha-list").removeClass('filter-expand')
    $(".cards-list").removeClass('filter-expand')
    $(".dropdown1 .btn-outline").removeClass("active")
    if (count || count1 || count2 || count3 || count5 || statelist.length !== 0 || achiever == true) {
      $(".dropdown1 .btn-outline").addClass("active")

    }
    activetest = false;
    $(".loc-menu").removeClass("show")
    $("#dropdownMenu2").removeClass("active")
    $("#dropdownMenu3").removeClass("active")
    $("#dropdownMenu4").removeClass("active")
    $("#dropdownMenu5").removeClass("active")
    $("#dropdownMenu6").removeClass("active")
    $("#dropdownMenu7").removeClass("active")
  }
  const dateChange = (dates) => {
    if (dates.split(" ")[0].indexOf('/') < 0) {
      return dates.split(" ")[0].split("-")[2]
    }
    else {
      return dates.split(" ")[0].split("/")[2]
    }
  }
  const setIndustry = (params) => {
    if (industrylist.length !== 0) {
      const s = [...params.map((item) => industrylist.filter((items) => item == items.name)).map((item) => {
        return item[0].id
      })]
      filterData({ ...filtermodel, "industry": s })
      setCount(params.length)
    }
  }

  const setSkillSetData = (params) => {
    if (industrylist.length !== 0) {
      const s = [...params.map((item) => skillsetlist.filter((items) => item == items.name)).map((item) => {
        return item[0].id
      })]
      filterData({ ...filtermodel, "skillsets": s })
      setCount5(params.length)
    }
  }
  const setQualification = (params) => {
    const s = [...params.map((item) => qualificationlist.filter((items) => item == items.name)).map((item) => {
      return item[0].id
    })]
    filterData({ ...filtermodel, "qualification": s })
    setCount1(params.length)
  }
  const result = (params) => {
    const s = params.map((itme) => parseInt(itme))
    filterData({ ...filtermodel, "graduateYear": s })
    setCount2(params.length)
  }
  const setTopAchiever = (params) => {
    if (achiever == false) {

      filterData({ ...filtermodel, "topAchiever": true })
      setAchiever(true)
    }
    else {
      filterData({ ...filtermodel, "topAchiever": false })
      setAchiever(false)
    }
  }
  const resetData = (type) => {
    if (type == "location") {
      setFilterModel({ ...filtermodel, ...locationData, "state": "", "country": '' })
      setShowCount(true)
      setCountryList([])
      setStateList([])
      setCityList([])
      filterData({ ...filtermodel, "country": '', "state": '', "city": '' })
      setTimeout(() => {
        setShowCount(false)
      }, 100);
      getLocation()
    }
  }
  const clearall = () => {
    filterData({
      ...filtermodel,
      "country": "", "city": "", "state": "",
      "industry": [], "graduateYear": [], "nqfl": [],
      "qualification": [], "studyType": [],
      "sizeOfEmployeesMin": 0, "sizeOfEmployeesMax": 0,
      "topAchiever": false, "isHiringActive": false,
      "skillsets": []
    })
    GetIndustry(); getLocation(); GetQualification();
    resetData("location");
    setClear(false);
    setAchiever(false)
    setStudentList([])
    filterData({
      ...filtermodel,
      "country": "", "city": "",
      "state": "", "industry": [], "graduateYear": [],
      "nqfl": [], "qualification": [], "studyType": [],
      "sizeOfEmployeesMin": 0, "sizeOfEmployeesMax": 0,
      "topAchiever": false, "isHiringActive": false,
      "skillsets": []
    })

    setCount(); setCount1(); setCount2(); setCount3(); setCount5()
    setTimeout(() => {
      setClear(true)
    }, 100);

  }
  const ApplySearch = async (searchValue) => {
    setSuggestionData([])
    setSuggestionValue(searchValue)
    setFilterModel({ ...filtermodel, "searchValue": searchValue ? searchValue : "" })
    var res = await services.Create(ServiceAPI.StudentFilterApi, {
      ...filtermodel, "searchValue": searchValue ? searchValue : ""
    });
    const data1 = await res.result;
    setAllData(data1);
    setStudentList(data1.map((data) => `${data.firstName + ' ' + data.lastName},${data.id},${data.userId}`));
  }

  const closeSearch = () => {
    if ($(".globsr").hasClass("active")) {
      if ($("#globsearch").val()) {
        $("#new-users-list").removeClass("show")
      }
      else {
        $(".global").removeClass("active")
        $("#globsearch").removeClass("active")
      }
    }
  }
  return (
    <>
      <section className="section " onClick={() => closeSearch()}>
        <div className="category-section container-fluid stu">
          <div className="row">
            <div className="col-md-12">
              <div className="heading-row pt-0">
                <div className="filter">
                  <div className="dropdown1 text-left">
                    <button className="btn btn-outline" onClick={() => setDropdown()}>filter by
                      {/* <i className="fa fa-caret-down"></i> */}
                      <span><img src="../../images/icon-chevron.svg" alt="" /></span>
                    </button>
                    <div className="dropdown1-content bg-white container-fluid">
                      <div className="row">
                        <div className="column ">
                          <div className="filter-title dropdown">
                            <button className="loc-btn" type="button" id="dropdownMenu2" onClick={() => setDropdown1()} >
                              Industry
                              <span>
                                <span><img src="../../images/icon-chevron.svg" alt="" /></span>
                                {count ?
                                  <span className="count">
                                    {count}
                                  </span>
                                  : ""}
                              </span>
                            </button>
                            <div className="dropdown-menu loc-menu ind-menu" aria-labelledby="dropdownMenu2">
                              {industrylist ? clear ? <Multiselect options={industrylist} onSelectOptions={setIndustry} type={"industry"} /> : "" : ""}
                              <div className="absolute-btn">
                                <button className="reset btn btn-outline mr-1" style={{ zIndex: 0 }} onClick={() => setFilter()}>ok</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="column">
                          <div className="filter-title dropdown">
                            <button className="loc-btn" type="button" id="dropdownMenu3" onClick={() => setDropdown2()} >
                              Location
                              <span>
                                <span><img src="../../images/icon-chevron.svg" /></span>
                                {statelist.length !== 0 ? <span className="count check">
                                  <img src="../../images/check-img-white.svg"></img>
                                </span> : ""}
                              </span>
                            </button>
                            <div className="dropdown-menu loc-menu location-menu" aria-labelledby="dropdownMenu3">

                              {showcount ? "" : <><div className="lable"> country : </div> <Select options={coutnrylist} onChange={(e) => AddValue(e)} placeholder={"select"} isSearchable="true"
                                className="filter-title" /></>}
                              {statelist.length !== 0 ?
                                <><div className="lable"> State/Province: </div><Select options={statelist ? statelist : []} placeholder={"select"} onChange={(e) => AddValue(e)} className="filter-title" /></>
                                : ""}
                              {citylist.length !== 0 ? <>< div className="lable">  city    :</div> <Select options={citylist ? citylist : []} onChange={(e) => AddValue(e)} placeholder={"select"} className="filter-title" /> </> : ""}
                              <button className="reset btn btn-outline mr-1" style={{ zIndex: 0 }} onClick={() => setFilter()}>ok</button>
                              <button className="reset btn btn-outline mr-1" style={{ zIndex: 0 }} onClick={() => resetData("location")}>Reset</button>
                            </div>

                          </div>
                        </div>
                        <div className="column">
                          <div className="filter-title dropdown">
                            <button className="loc-btn" type="button" id="dropdownMenu4" onClick={() => setDropdown3()} >
                              Qualification
                              <span>
                                <span><img src="../../images/icon-chevron.svg" /></span>
                                {count1 ?
                                  <span className="count">
                                    {count1}
                                  </span>
                                  : ""}
                              </span>
                            </button>
                            <div className="dropdown-menu loc-menu qual-menu" aria-labelledby="dropdownMenu4">
                              {qualificationlist ? clear ?
                                <Multiselect options={qualificationlist} onSelectOptions={setQualification} type={1} />
                                : "" : ''}
                              <div className="absolute-btn">
                                <button className="reset btn btn-outline mr-1" style={{ zIndex: 0 }} onClick={() => setFilter()}>ok</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="column">
                          <div className="filter-title dropdown">
                            <button className="loc-btn" type="button" id="dropdownMenu5" onClick={() => setDropdown4()} >
                              Year of study
                              <span>
                                <span><img src="../../images/icon-chevron.svg" /></span>
                                {count2 ?
                                  <span className="count">
                                    {count2}
                                  </span>
                                  : ""}
                              </span>
                            </button>
                            <div className="dropdown-menu loc-menu styd-menu" aria-labelledby="dropdownMenu5">
                              {clear ?
                                <Multiselect options={[
                                  { name: "1 year", value: "1 year" }, { name: "2 year", value: "2 year" }, { name: "3 year", value: "3 year" },
                                  { name: "4 year", value: "4 year" }, { name: "5 year", value: "5 year" }
                                ]} onSelectOptions={result} />
                                : ""}
                              <div className="absolute-btn">
                                <button className="reset btn btn-outline mr-1" style={{ zIndex: 0 }} onClick={() => setFilter()}>ok</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="column">
                          <div className="filter-title dropdown">
                            <button className="loc-btn" type="button" id="dropdownMenu6" onClick={() => setDropdown5()} >
                              Top Achiever
                              <span>
                                <span><img src="../../images/icon-chevron.svg" /></span>
                                {achiever ? <span className="count check">
                                  <img src="../../images/check-img-white.svg"></img>
                                </span> : ""}
                              </span>
                            </button>
                            <div className="dropdown-menu loc-menu top-menu p-10" aria-labelledby="dropdownMenu6">
                              <label className="container mb-0">
                                <span>Top Achiever</span>
                                <input
                                  type="checkbox"
                                  id="Remember"
                                  onChange={() => setTopAchiever()}
                                  checked={clear ? achiever : false}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <button className="reset btn btn-outline mr-1" style={{ zIndex: 0 }} onClick={() => setFilter()}>ok</button>
                            </div>
                          </div>
                        </div>
                        <div className="column ">
                          <div className="filter-title dropdown">
                            <button className="loc-btn" type="button" id="dropdownMenu7" onClick={() => setDropdown6()} >
                              Toolkit
                              <span>
                                <span><img src="../../images/icon-chevron.svg" /></span>
                                {count5 ?
                                  <span className="count">
                                    {count5}
                                  </span>
                                  : ""}
                              </span>
                            </button>
                            <div className="dropdown-menu loc-menu skill-menu" aria-labelledby="dropdownMenu7">
                              {skillsetlist ? clear ? <Multiselect options={skillsetlist} onSelectOptions={setSkillSetData} type={"skillset"} /> : "" : ""}
                              <div className="absolute-btn">
                                <button className="reset btn btn-outline mr-1" style={{ zIndex: 0 }} onClick={() => setFilter()}>ok</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="reset-btn">
                          {count || count1 || count2 || count3 || count5 || statelist.length !== 0 || achiever == true ? <button className="reset btn btn-outline" onClick={() => clearall()}>Reset</button> : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sign-heading" onClick={() => setFilter()} style={{
                  width: "100%",
                  textAlign: "right"
                }}>
                  Candidate
                </div>
              </div>
            </div>

          </div>

          <div className="row">
            <div className="cards-list" onClick={() => setFilter()} >
              {(count || count1 || count2 || count3 || count5 || statelist.length !== 0 || achiever == true) && studentlist.length == 0 ? <div className=" showdata">no data found!</div> : ""}
              {<AlphabetList
                className="alpha-list bg-light"
                style={style}
                data={studentlist}
                generateFn={(item, index) => {
                  return <Tag key={index} style={{ backgroundColor: "light-grey", border: "none" }} >
                    <div >
                      <div className="img-container" style={{ postion: "relative" }}>
                        {fetchStudentData(item)[0] !== "null" ? <img src={fetchStudentData(item)[0]} alt="" className="image" /> : ""}
                        <div className="name line"> {`${item.split(",")[0]} `}
                        </div>
                        <div className="middle text-center">
                          <div className="front-text" onClick={() => profile(item.split(",")[1], item.split(",")[2])} >
                            <div className="title">
                              {item.split(",")[0]}
                              <div className="text-capitalize" style={{ lineHeight: "0.8" }}>
                                <small>{fetchStudentData(item)[11] !== 'null' ? fetchStudentData(item)[11] : ''}</small>
                              </div>
                            </div>

                            <div className="sub-title text-capitalize">
                            </div>
                            <hr />
                            <div className="sub-head">
                              {fetchStudentData(item)[5] !== 'null' ? fetchStudentData(item)[5] : ""}
                              <br />
                              {fetchStudentData(item)[9] !== 'null' ? fetchStudentData(item)[9] : ""}

                            </div>
                            <div>
                              <img src={fetchStudentData(item)[4] ? fetchStudentData(item)[4] : "./images/bhc card+hover.svg"} alt="Avatar" className=" rounded-circle" />
                            </div>
                            {(fetchStudentData(item)[1] === 'false') ? fetchStudentData(item)[2] == 0 ? "" : <div className="sub-head cn-btn"> <img src="../../images/graduation-cap.svg" className="graduated-icon"></img> graduated <span className="year">({fetchStudentData(item)[8] ? dateChange(fetchStudentData(item)[8]) : ""})</span> </div> :
                              <div className="sub-head cn-btn grey p-0">{(fetchStudentData(item)[1] === 'false') ? fetchStudentData(item)[2] === 0 ? "" : "graduated" : fetchStudentData(item)[2] === 0 ? "" :
                                fetchStudentData(item)[3] && parseInt(fetchStudentData(item)[3]) === 1 ?
                                  <div className={(parseInt(fetchStudentData(item)[2]) - parseInt(fetchStudentData(item)[3]) === parseInt(fetchStudentData(item)[3])) ? "year-progress one-ff" : "year-progress one-fr"} > <div className="zindex">
                                    {fetchStudentData(item)[3]}/{fetchStudentData(item)[2]} year
                                  </div></div>
                                  : parseInt(fetchStudentData(item)[3]) === 2 ?
                                    <div className={(parseInt(fetchStudentData(item)[2]) - parseInt(fetchStudentData(item)[3]) === parseInt(fetchStudentData(item)[3])) ? "year-progress one-ff" : "year-progress one-h"} > <div className="zindex">
                                      {fetchStudentData(item)[3]}/{fetchStudentData(item)[2]} year</div></div> :
                                    fetchStudentData(item)[3] && parseInt(fetchStudentData(item)[3]) === 3 ?
                                      <div className={(parseInt(fetchStudentData(item)[2]) - parseInt(fetchStudentData(item)[3]) === parseInt(fetchStudentData(item)[3])) ? "year-progress one-ff" : "year-progress one-t"} ><div className="zindex">
                                        {fetchStudentData(item)[3]}/{fetchStudentData(item)[2]} year</div></div> : parseInt(fetchStudentData(item)[3]) === parseInt(fetchStudentData(item)[3]) ?
                                        <div className={"year-progress one-tt"} > <div className="zindex">
                                          {fetchStudentData(item)[3]}/{fetchStudentData(item)[2]} year</div></div>
                                        : ""}
                              </div>}
                          </div>
                          <button className="readmore-btn" onClick={() => profile(item.split(",")[1], item.split(",")[2])}>See more</button>
                        </div>
                      </div>
                    </div>
                  </Tag>
                }} />

              }
            </div>
          </div>
        </div>
        {loader}
      </section>
    </>

  )

}
export default StudentList;