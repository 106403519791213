import Common from "../../Utility/Common";
const BannerModel = (object) => ({
  id: null,
  name: "",
  imageUrl: "",
  title: "",
  titleLink: "",
  active: true,
  userId: Common.UserId(),
  errormsg: "",
});

export default BannerModel;