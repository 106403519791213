import Common from "../../Utility/Common";
const StudentBannerModel = () => ({
    id: null,
    name: "",
    imageUrl: "",
    title: "",
    titleLink: "",
    active: true,
    userId: Common.UserId(),
    errormsg: "",
    studentId: Common.LoginId()
});
export default StudentBannerModel