import Common from "../../Utility/Common";
const UpdateProfileModel = () => ({
    id: null,
    firstName: '',
    lastName: '',
    email: '',
    contact: '',
    website: '',
    logo: '',
    address: '',
    country: '',
    state: '',
    city: '',
    role: '',
    isActive: false,
    studentLanguage: [""],
    topAchievers: false
});

export default UpdateProfileModel;