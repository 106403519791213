import React, { useEffect } from "react";
import { useTable, useSortBy } from "react-table";
import InfiniteScroll from "react-infinite-scroll-component";


function TableList({ columns, data, update, hasMore, newClass }) {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy }
    } = useTable(
        {
            columns,
            data
        },
        useSortBy
    );

    useEffect(() => {
    }, [sortBy]);
    return (

        <div className={newClass ? `${newClass} ad-table` : "ad-table"} id="scrollableDiv">
            <InfiniteScroll
                dataLength={rows.length}
                next={update}
                hasMore={hasMore}
                loader={data.length <= 10 ? '' : <div className="load-text">Loading ...</div>}
                scrollableTarget="scrollableDiv"
                style={{ overflow: 'initial', }}
            >
                <table {...getTableProps()} className="col-md-12 table">
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        {...column.getHeaderProps()}
                                        style={{
                                        }}
                                    >
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                style={{
                                                }}
                                            >
                                                <div className="descr">  {cell.render('Cell')}</div>
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </InfiniteScroll>
        </div>
    );
}

export default TableList