import Common from "../../Utility/Common";
const CourseModel = () => ({
    id: null,
    industryId: null,
    name: '',
    qualification: '',
    qualificationId:'',
    courseDescription: '',
    active: true,
    userId: Common.UserId()

});

export default CourseModel;
