import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Common from "../Utility/Common";
import $ from "jquery";
import environment from "../environment";
import { useHistory } from "react-router-dom";
import service from "../Services/UtilityService";
import ServiceAPI from "../Utility/ServiceList";
import InfiniteScroll from "react-infinite-scroll-component";
import useLoader from './Auth/Loader'
const Nav = () => {
  var path = window.location.pathname.toLowerCase()
  const history = useHistory(); const [onAply, setOnAply] = useState(false);
  const services = new service();
  const [loader, showLoader, hideLoader] = useLoader();
  const [settingUrl, setSettingUrl] = useState('');
  const [profileUrl, setProfileUrl] = useState('');
  const [searchvalue, setSearchValue] = useState('')
  const [suggestionData, setSuggestionData] = useState([]);
  var usertype = Common.UserType();
  const twitterLink = localStorage.getItem("twitterLink");
  const instagramLink = localStorage.getItem("instagramLink");
  const facebookLink = localStorage.getItem("facebookLink");
  const adminlastName = localStorage.getItem("adminlastName");
  var p = localStorage.getItem("ProfileImage")
  useEffect(() => {
    $("#btnMenuSlider,#CloseMenu,#awardsMenu,#aboutMenu,#privacyMenu,#termsMenu,#eventsMenu,#getInMenu,#supportMenu").on("click", function () {
      $("#mySidebar").toggleClass("active");
      $("body").toggleClass("overflow-hidden");
    }
    );
    $("#globsearch").on("click", () => {
      if ($("input").hasClass("globsr")) {
        if ($("globsr").hasClass("active")) {
          $("#globsearch").removeClass("active")
          $(".global").removeClass("active")
        }
        else {
          $(".global").addClass("active")
          $("#globsearch").addClass("active")
        }
      }
    })
    var usertype = Common.UserType();

    if (usertype === 'college') {
      setSettingUrl("/collegedeshboard");
      setProfileUrl(`/collegeprofile?id=${Common.LoginId()}`);

    }
    else if (usertype === 'company') {
      setSettingUrl("/companydashboard");
      setProfileUrl(`/companyprofile?id=${Common.LoginId()}`);

    }
    else if (usertype === 'candidate') {
      setSettingUrl("/studentdashboard");
      setProfileUrl(`/StudentProfile?id=${Common.LoginId()}`);

    } else if (usertype === 'admin') {
      setSettingUrl("/studentdashboard");
      setProfileUrl("/industrylist");
    }
  }, []);
  const closeSearch = () => {
    if ($(".globsr").hasClass("active")) {
      if ($("#globsearch").val()) {
        $("#new-users-list").removeClass("show")
        setSuggestionData([])
        setSearchValue()
        $("#globsearch").removeClass("active")
      }
      else {
        $(".global").removeClass("active")
        $("#globsearch").removeClass("active")
      }
    }
  }
  const GlobalSearchSuggestion = async (searchvalue) => {
    if (searchvalue.trim()) {
      setSearchValue(searchvalue.trim())
    }
    else {
      setSearchValue('')
    }
    if (searchvalue && searchvalue.trim().length >= 3) {
      showLoader()
      var res = await services.GetByValue(ServiceAPI.GetGlobalSuggestionApi, searchvalue && searchvalue.length >= 3 ? searchvalue : '');
      setSuggestionData(res)
      setOnAply(false)
      hideLoader()
    }
    else {
      setSuggestionData([])
    }
  }
  const ApplySearch = async (searchid, searchValue, searchtype, type) => {
    setOnAply(true)
    setSearchValue(searchValue)
    if (searchtype === 'College') {
      setSuggestionData([])
      history.push(`/collegesprofile?id=${searchid}`);
    }
    else if (searchtype === 'Company') {
      setSuggestionData([])
      history.push(`/companysprofile?id=${searchid}`)
    }
    else if (searchtype === 'Candidate' || searchtype === 'Student') {
      setSuggestionData([])
      history.push(`/studentsprofile?id=${searchid}`)
    }
    else if (searchtype === 'admin') {
      history.push("/admindashboard")
    }

  }
  const ClearSearch = () => {

    $(".globsr").removeClass("active")
    $(".global").removeClass("active")
    setSuggestionData([])
    setSearchValue('')
  }
  const loginProfile = (type) => {
    if (usertype === 'college') {
      history.push(`/collegesprofile?id=${Common.LoginId()}`);
    }
    else if (usertype === 'company') {
      history.push(`/companysprofile?id=${Common.LoginId()}`)
    }
    else if (usertype === 'candidate') {
      history.push(`/studentsprofile?id=${Common.LoginId()}`)
    }
    else if (usertype === 'admin') {
      history.push("/admindashboard")
    }
  }
  const Logout = () => {
    localStorage.removeItem("userid");
    localStorage.removeItem("username");
    localStorage.removeItem("loginid");
    localStorage.removeItem("email");
    localStorage.removeItem("usertype");
    localStorage.removeItem("profileImage");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    window.location = "/home";
  };
  const AdminLogout = () => {
    localStorage.removeItem("adminuserid");
    localStorage.removeItem("adminusername");
    localStorage.removeItem("adminloginid");
    localStorage.removeItem("adminemail");
    localStorage.removeItem("adminusertype");
    localStorage.removeItem("adminprofileimage");
    localStorage.removeItem("adminfirstName");
    localStorage.removeItem("adminlastName");
    window.location = "/adminlogin";

  };
  const sidbar = () => {
    $("#mySidebar").removeClass("active");
    $("body").removeClass("overflow-hidden");
  }
  return (
    <>
      <nav className="navbar navbar-expand sticky-top xylus-navbar">
        {path === '/admindashboard' || path === '/attribute' || path === '/attributelist' || path === '/advertisement' || path === '/advertisementlist' || path === '/adminlogin'
          || path === '/toolkitlist' || path === '/industry' || path === '/skillsetlist' || path === '/staticpageslist' || path === '/industrylist' || path === '/course' || path === '/courselist' || path === '/toolkit' || path === '/invitationmail'
          || path === '/banner' || path === '/bannerlist' || path === '/users' || path === '/adminchangepassword' || path.split('/').splice(-2, 1).toString() === 'banner'
          || path.split('/').splice(-2, 1).toString() === 'industry' || path.split('/').splice(-2, 1).toString() === 'attribute' || path.split('/').splice(-2, 1).toString() === 'toolkit'
          || path.split('/').splice(-2, 1).toString() === 'advertisement' || path.split('/').splice(-2, 1).toString() === 'course' || path.split('/').splice(-2, 1).toString() === 'updateprofile' ?
          //if user is admin
          <div className="theme-container w-100 navbar">
            <Link className="navbar-brand" to="/admindashboard">
              <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}/images/logo.png`} />
            </Link>
            <div className="navigation">


              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto">
                  {Common.AdminUserId() ? (
                    <>
                      <li className="nav-item dropdown profile">
                        <div className=" dropdown-toggle " href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <img src={localStorage.getItem("adminprofileImage") ? localStorage.getItem("adminprofileImage") : `${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/user.svg`} className="profile-img " alt="" />
                        </div>
                        <div className="dropdown-menu profile-options " aria-labelledby="dropdownMenuLink">
                          <div className="display-inline pl-0 pr-0">
                            <div className="dropdown-item link-item"  > Admin
                            </div>
                          </div>
                          <div className="divider"></div>
                          <Link className="dropdown-item link-item" to="/adminlogin" onClick={() => AdminLogout()}  > Sign out </Link>
                        </div>
                      </li>
                    </>
                  ) : (
                    <>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div> :

          //if not admin
          <div className="theme-container w-100 navbar">
            <div >
              <Link className="navbar-brand" to="/home">
                <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}/images/logo.png`} />
              </Link>
            </div>
            <div onClick={() => closeSearch()}> </div>
            <div className="navigation">
              <div className="side-menu d-flex">
                <div className="search-box-pannel global" >
                  <input type="search" id="globsearch" className="globsr" value={searchvalue} placeholder="Search" onChange={(e) => GlobalSearchSuggestion(e.target.value)} autoComplete="off" />
                  {suggestionData && searchvalue ? <span type="button"
                    className="modalclose"
                    onClick={() => ClearSearch()}
                  >
                    <span aria-hidden="true"> x </span>
                  </span> : ""}
                  {suggestionData && suggestionData.length && suggestionData.length > 0 && searchvalue ?
                    <div className="notification-menu show search-dropbox over" id='new-users-list' style={{ color: "black" }}>
                      {suggestionData ? suggestionData.map((data) => {
                        return (<>
                          <div className="inner-item" onClick={() => ApplySearch(data.id, data.name, data.type, "select")}>
                            <img className='new-user-img' src={data.url ? data.url : '../images/user.svg'} alt="" />
                            <div><div>{data.name}</div>
                              <div className="type">{data.type}</div>
                            </div>
                          </div>
                          {/*  */}
                        </>)
                      }) : ""}</div>
                    : suggestionData.length === 0 && searchvalue.length > 2 && onAply === false ? <div className="notification-menu  show search-dropbox ss" id='new-users-list' style={{ color: "black" }}>
                      <div className="inner-item text-center">
                        <span>No Data Found !</span>
                      </div>
                    </div> : ""}
                </div>
                <div className="home-icon" onClick={() => closeSearch()}>
                  <div className="" href="#" onClick={() => closeSearch()}>
                    <Link to="/home"><img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}/images/clarity_home-solid.png`} /></Link>
                  </div>
                </div>
                <button className="openbtn"><img id="btnMenuSlider" className="openbtn" src="/images/menu.svg" alt="" /></button>
              </div>

              {environment.isServer == "true" ? <div className="pl-1 b-right">{usertype === "college" ? <Link to="collegeShortList">

                <button
                  className="btn sign-btn btn-outline s-btn mt-0"
                  type="button"
                > ShortList
                </button>
              </Link> : ""}
                {usertype === "candidate" ? <Link to="studentShortList">

                  <button
                    className="btn sign-btn btn-outline s-btn mt-0"
                    type="button"
                  > ShortList
                  </button>
                </Link> : ""}
                {usertype === "company" ? <Link to="companyShortList">

                  <button
                    className="btn sign-btn btn-outline s-btn mt-0"
                    type="button"
                  > ShortList
                  </button>
                </Link> : ""}
              </div> : Common.UserType() ? <button
                className="btn sign-btn btn-outline s-btn mt-0"
                type="button"
              > ShortList
              </button> : ""}
              <div className="" id="navbarSupportedContent" onClick={() => closeSearch()}>
                <ul className="navbar-nav ml-auto">
                  {Common.UserId() ? (
                    <>
                      <li className="nav-item dropdown profile">
                        <div className=" dropdown-toggle " href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <img src={localStorage.getItem("profileImage") ? localStorage.getItem("profileImage") : `${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/user.svg`} className="profile-img " alt="" />
                        </div>
                        <div className="dropdown-menu profile-options " aria-labelledby="dropdownMenuLink">
                          <div className="display-inline pl-0 pr-0">
                            <div className="dropdown-item link-item no-hov"  > {Common.UserName()}
                              <span className="dropdown-item d-block p-0"  >{Common.UserType()}</span>
                            </div>
                          </div>
                          <div className="divider"></div>
                          <div className="dropdown-item link-item" onClick={loginProfile}  >Profile</div>
                          <div className="divider"></div>
                          <Link className="dropdown-item link-item" to={profileUrl}  >Edit Xylus Profile</Link>
                          <div className="divider"></div>
                          <Link className="dropdown-item link-item" to="/home" onClick={() => Logout()}  > Sign out </Link>
                        </div>
                      </li>
                    </>
                  ) : (
                    <>
                      <div className="row">
                        <li className="nav-item b-right d-none" >
                          <Link to={environment.isServer == "true" ? "/Xyfund" : ""} className="btn btn-outline ml-2 d-md-block d-none">
                            XyFund
                          </Link>
                          <Link to={environment.isServer == "true" ? "/Xyfund" : ""} className="btn btn-outline ml-2 d-block d-md-none xyfund-btn">
                            <img src="../../images/xyfunding-img.png" className="xyfund"></img>
                          </Link>
                        </li>
                        <li className="nav-item pr-0 d-none d-md-block">
                          <Link className="btn dark d-none d-md-block" to="Login">
                            Sign in
                          </Link>
                        </li>
                        <li className="nav-item pr-3 d-none d-md-block">
                          <Link className="btn dark d-none d-md-block" to="SignUp">
                            Sign up
                          </Link>
                        </li>
                        <li className="nav-item dropdown login-dropdown d-block d-md-none">
                          <div className=" dropdown-toggle " href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img src="../../images/login.png" className="xyfund d-block d-md-none"></img>
                          </div>
                          <div className="dropdown-menu login-options " aria-labelledby="dropdownMenuLink">
                            <Link className="btn btn-outline dark d-block d-md-none" to="Login">
                              Sign in
                            </Link>
                            <Link className="btn btn-outline dark d-block d-md-none" to="SignUp">
                              Sign up
                            </Link>
                          </div>
                        </li>
                      </div>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>}
      </nav>
      <div id="mySidebar" className="sidebar">
        <div className="sidebar-header">
          <div className="closebtn" id="CloseMenu">×</div>
          <div className="tab-heading">
            More Stuff...
          </div>
        </div>
        <div className="list-items">
          <Link to="aboutus" className="item " id="aboutMenu" onClick={sidbar()}>
            <div >
              <img src="/images/about-icon.png" alt="" />
              about us
            </div>
          </Link>
          <Link to="event" className="item d-none" id="eventsMenu" onClick={sidbar()}>
            <div>
              <img src="/images/event-schedule-icon.png" alt="" />
              events
            </div>
          </Link>
          <Link to="awards" className="item d-none" id="awardsMenu" onClick={sidbar()}>
            <div>
              <img src="/images/award-icon.png" alt="" />
              Awards
            </div>
          </Link>
          <Link to="support" className="item d-none" id="supportMenu" onClick={sidbar()}>
            <div>
              <img src="/images/support-icon.png" alt="" />
              Support
            </div>
          </Link>
          <Link to="terms" className="item" id="termsMenu" onClick={sidbar()}>
            <div>
              <img src="/images/terms-icon.png" alt="" />
              Terms Of Use
            </div>
          </Link>

          <Link to="/privacy" className="item" id="privacyMenu" onClick={sidbar()}>
            <div>
              <img src="/images/document-view.svg" alt="" />
              Privacy Policy
            </div>
          </Link>
          <Link to="getintouch" className="item" id="getInMenu" onClick={sidbar()}>
            <div >
              <img src="/images/get-in-icon.png" alt="" />
              Get In Touch
            </div>
          </Link>


        </div>
        <div className="side-footer-icons">
          {Common.UserType() ?
            <div className="social-icons">
              {twitterLink ? <a href={twitterLink ? twitterLink : ""} target='_blank' rel="noreferrer"><img src="../images/twitter.svg" /></a> : ""}
              {instagramLink ? <a href={instagramLink ? instagramLink : ""} target='_blank' rel="noreferrer"><img src="../images/instagram.svg" /></a> : ""}
              {facebookLink ? <a href={facebookLink ? facebookLink : ""} target='_blank' rel="noreferrer"><img src="../images/facebook.svg" /></a> : ""}
            </div> : ""}
        </div>
      </div>
      <div className="overlay"></div>
      {loader}
    </>
  );
};

export default Nav;
