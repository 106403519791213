import Common from "../../Utility/Common";
const newDate = new Date();
const CollegeEventModel = () => ({
        id: null,
        collegeId: Common.LoginId(),
        title: '',
        description: '',
        imageUrl: '',
        fromDate: newDate,
        toDate: newDate,
        caption: '',
        location: '',
        active: true,
        userId: Common.UserId(),
        location: "",
        caption: "",
        college: ''

})
export default CollegeEventModel;