import Common from "../../Utility/Common";
const CollegeLocationModel = () => ({
    id: null,
    collegeId: Common.LoginId(),
    name: '',
    address: '',
    active: true,
    userId: Common.UserId()

})
export default CollegeLocationModel;

