import React, { useState, useEffect, useMemo } from "react";
import service from "../../../Services/UtilityService";
import ServiceAPI from "../../../Utility/ServiceList";
import AdminNav from "./CollegeNav";
import AdminHeader from '../../Common/AdminHeader'
import Common from "../../../Utility/Common";
import TableList from "../../../Utility/TableList";
import ShortList from "./ShortList";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import $ from "jquery";
import useLoader from '../../Auth/Loader'
let value = 1;
const ShortListCategory = () => {
    const [itemlist, setItemList] = useState([]);
    const [loader, showLoader, hideLoader] = useLoader();
    const [hasMore, setHasMore] = useState(true);
    const [model, setModel] = useState();
    const [pageModel, setPageModel] = useState({
        "pageNum": 1,
        "pageSize": 3
    });
    var services = new service();
    const data = itemlist;

    const columns = useMemo(
        () => [
            {
                Header: 'Category Name',
                accessor: 'name',
            }, {
                Header: "Status",
                accessor: "active",
                Cell: (props) => {
                    return (
                        <label className="switch">
                            <input
                                disabled
                                type="checkbox"
                                defaultChecked={props.value}
                            />
                            <span className="slider round"></span>
                        </label>
                    )
                },
            }, {
                Header: "Edit",
                accessor: "edit",
                Cell: ({ row }) => {
                    const itemID = data.map((item, index) => item.id);
                    const itemName = data.map((item, index) => item.name);
                    return (
                        <label>
                            <a
                                className="fa fa-pencil edit"
                                aria-hidden="true"
                                data-toggle="modal" data-target={`${"#exampleModal"}${row.index}`}
                            />
                            <div className="modal fade xy-form"
                                id={`${"exampleModal"}${row.index}`}
                                tabIndex="-1"
                                role="dialog"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true">

                                <div className="modal-dialog modal-dialog-centered " >

                                    <div className="modal-content"  >
                                        <div className="modal-header form-mdlheadr mb-3">
                                            <h4 class="modal-title mdl-heading text-capitalize">
                                                Edit
                                            </h4>
                                            <button type="button"
                                                className="close modalclose-btn"
                                                data-dismiss="modal">
                                                x
                                            </button>
                                        </div>
                                        <ShortList id={itemID[row.index]} GetAll={GetAll} setPageModel={setPageModel} />
                                    </div>
                                </div>
                            </div>
                        </label>
                    );
                },
            }, {
                Header: "Delete",
                accessor: "delete",
                Cell: ({ row }) => {
                    const itemID = data.map((item, index) => item.id);
                    return (

                        <label>
                            <a
                                onClick={() => Delete(itemID[row.index], row.index)}
                                className="fa fa-trash delete"
                                aria-hidden="true"
                            />
                        </label>

                    );
                },
            }

        ], [data])

    useEffect(() => {
        GetAll();
    }, []);

    useEffect(() => {

    }, [pageModel]);

    async function GetAll(id, pageModels) {
        $('.modal-backdrop').removeClass('show ');
        $('.modal').removeClass('show');
        $('body').toggleClass('p-0');
        $('body').removeClass('modal-open');
        showLoader()
        setPageModel(pageModels ? pageModels : pageModel)
        var res = await services.GetDataById(ServiceAPI.CollegeCategoryGetAllApi, Common.LoginId(), pageModels ? pageModels : pageModel);
        hideLoader()
        const model = await res.metaData;
        setModel(model.totalCount);
        setHasMore(true)
        const data = await res.result;
        if (id == false || id == Common.LoginId()) {
            setItemList([...data]);
            setPageModel({
                "pageNum": pageModels.pageNum + 1,
                "pageSize": 10,
                "isActive": false
            })
        }
        else if (id == true) {
            setItemList([...itemlist, ...data]);
            setPageModel({
                "pageNum": pageModel.pageNum + 1,
                "pageSize": 10,
                "isActive": false
            })
        }
        else {
            setItemList([...itemlist, ...data]);
            setPageModel({
                "pageNum": pageModel.pageNum + 1,
                "pageSize": 10,
                "isActive": false
            })
        }

    }

    const Delete = (id, index) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="xy-form">
                        <div className="mdl-heading">Delete Record</div>
                        <div className="desc">Are you sure to delete it?</div>
                        <button className="btn btn-outline s-btn pr" onClick={onClose}>No</button>
                        <button className="btn btn-outline s-btn pr"
                            onClick={async () => {
                                let item = itemlist.filter((data) => data.id != id);
                                await services.Delete(ServiceAPI.CollegeCategoryDeleteApi, id).then(async () => {
                                    var res = await services.GetDataById(ServiceAPI.CollegeCategoryGetAllApi, Common.LoginId(), {
                                        "pageNum": parseInt((item.length / 10) + 1),
                                        "pageSize": 10,
                                        "isActive": false
                                    });
                                    const model = await res.metaData;
                                    const data = await res.result;
                                    if (model.totalPages > parseInt((index / 10) + 1)) {
                                        if (itemlist.length === 10 * model.currentPage && data.length > 9) {
                                            setItemList([...item, data[data.length - 1]]);
                                        } else {
                                            setItemList(item)
                                        }
                                    } else {
                                        setItemList(item)
                                    }
                                })
                                onClose();
                            }}
                        >
                            Yes
                        </button>
                    </div>
                );
            }
        });
    };


    const fetchMoreData = () => {
        if (model > itemlist.length) {
            GetAll()
        } else {
            setHasMore(false)
        }
    };
    const newdata = useMemo(() => itemlist, [itemlist]);
    return (
        <>
            <AdminHeader heading="" subheading="ShortList Category" />
            <form>
                <div className="theme-container-admin min-h">
                    <AdminNav shortlistcategory="active"></AdminNav>
                    <div className="table-sec">
                        <div className="add-option">
                            <div className="title"></div>
                            <div className="icon" data-toggle="modal" data-target='#exampleModal'>
                                <i className="fa fa-plus" aria-hidden="true"></i>
                            </div>
                            <div className="modal fade xy-form"
                                id="exampleModal"
                                tabIndex="-1"
                                role="dialog"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true">

                                <div className="modal-dialog  modal-dialog-centered" >
                                    {/* <div className="modal-content" width="750px" height="400px" > */}
                                    <div className="modal-content"  >
                                        <div className="modal-header form-mdlheadr mb-3">
                                            <h4 class="modal-title mdl-heading text-capitalize">
                                                ShortList category
                                            </h4>
                                            <button type="button"
                                                className="close modalclose-btn"
                                                data-dismiss="modal">
                                                x
                                            </button>
                                        </div>
                                        <ShortList GetAll={GetAll} setPageModel={setPageModel} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <TableList
                            data={newdata}
                            columns={columns}
                            update={fetchMoreData}
                            hasMore={hasMore}
                        />
                    </div>
                </div>
            </form>
            {loader}
        </>
    );

}
export default ShortListCategory