import React, { useState, useEffect } from "react";
import StudentNav from "./StudentNav";
import { useHistory } from "react-router-dom";
const StudentDashboard = () => {
  const history = useHistory();
  const redirectlist = (link) => {
    history.push(link)
  }
  return (
    <>
      <form>
        <div className="theme-container-admin">
          <StudentNav ></StudentNav>
          <div className="table-sec">
            <div className="add-option">
              <div className="title"></div>
              <a className="icon" onClick={() => redirectlist("/studentbanner")}>
                <i className="fa fa-plus" aria-hidden="true"></i>
              </a>
            </div>
            <div className="ad-table table-responsive">
              <h1> Student Dashboard</h1>
            </div>
          </div>
        </div>
      </form>
    </>

  )
}
export default StudentDashboard;