import React, { useState, useEffect, useRef } from "react";
import Common from "../../../Utility/Common";
import DefaultModel from "../../../Model/Student/StudentPortfolioModel";
import service from "../../../Services/UtilityService";
import ServiceAPI from "../../../Utility/ServiceList";
import FormHeader from "../../Common/FormHeader";
import StudentNav from "./StudentNav";
import environment from '../../../environment';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useLoader from "../../Auth/Loader"
import AdminHeader from '../../Common/AdminHeader'
import $ from "jquery";
import { useHistory } from "react-router-dom";
import ModalHeader from "react-bootstrap/esm/ModalHeader";

toast.configure()
var vidurl = ''
const StudentPortFolio = (props) => {
  const history = useHistory();
  localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
  Common.ValidLogin();
  const formData = new FormData();
  const id1 = props.id;
  const show = props.show === true ? true : ''
  const [loader, showLoader, hideLoader] = useLoader();
  const [model, setModel] = useState(DefaultModel);
  const [imgurl, setImgurl] = useState("");
  const [border, setBorder] = useState("xu-input");
  const [showimage, setShowImage] = useState(false); const [showvideo, setShowVideo] = useState(false)
  const [videourl, setVideoUrl] = useState()
  const [isvideo, setIsVideo] = useState(false)

  const services = new service();

  useEffect(() => {
    if (id1 && props.show === true) {
      props.setShow(false)
      GetById(id1);
    }
    else if (props.match || !props) {
      const id = props.location.search.split("=")[1]
      if (id) { GetById(id); }
    }
  }, [id1, show]);

  const GetById = async (id) => {
    if (!show) {
      $('.modal-backdrop').removeClass('show ');
      $('.modal').removeClass('show');
      $('body').toggleClass('p-0');
      $('body').removeClass('modal-open');
    }
    showLoader()
    setModel(DefaultModel);
    var res = await services.GetById(ServiceAPI.StudentPortFolioGetApi, id);
    hideLoader()
    const data = await res.result;
    setModel(data ? data : "");
    setIsVideo(data.images && data.images.length > 0 && data.images[0].isVideo === true ? true : false)

    setVideoUrl(data.images && data.images.length > 0 && data.images[0].isVideo === true ? data.images[0].imageUrl : '')
    setImgurl(data.images && data.images.length > 0 && data.images[0].isVideo === false ? data.images.map((item) => item.imageUrl) : '');
  };
  const ChangeHandler = (e) => {
    let value = {};
    if (e.target.id === "videourl") {
      setVideoUrl(e.target.value)
      setIsVideo(true)
      // value["images"] = [{ studentPortfolioId: Common.LoginId(), imageUrl: e.target.value, id: null, userId: Common.UserId(), active: true, isVideo: true }];
    }

    if (e.target.type === "checkbox") {
      value[e.target.id] = e.target.checked;
    } else if (e.target.type === "textarea") {
      const inputText = e.target.value;

      // Count words, excluding symbols
      const words = inputText
        .trim()
        .match(/\b[a-zA-Z]+\b/g) || [];

      // Allow only if the word count is within limits
      if (words.length <= 300) {
        value[e.target.id] = e.target.value;
      } else {
        toast.error("Maximum word limit of 300 reached.", { theme: "colored", autoClose: 2000 });
      }
    } else {
      value[e.target.id] = e.target.value;
    }
    setModel({ ...model, ...value });
    setBorder({ ...border, [e.target.id]: "" })
  };


  const UploadImage = async (e) => {
    if (validImage(e)) {
      if (e.target.files[0]) {
        if (imgurl.length < 4) {
          setShowImage(true)
        }
        setIsVideo(false)
        formData.append('file', e.target.files[0]);
        formData.append('imageType', '1');
        var data = await services.UploadImages(ServiceAPI.ImageUplaodApi, "Portfolio", formData);
        var url = environment.imageUrl + data.result;
        if (model.images.length < 4) {
          let value = { images: [...model.images, { studentPortfolioId: Common.LoginId(), imageUrl: url, id: null, userId: Common.UserId(), active: true, isVideo: false }] };
          setImgurl((prev) => [...prev, url]);
          setShowImage(false)
          await setModel({ ...model, ...value });
          setBorder({ ...border, [e.target.id]: "" })
        } else {
          toast.error("can not upload more then 4 images", { theme: "colored", autoClose: 2000 })
        }
      }
    }
  }

  const validImage = (e) => {
    let file = e.target.files[0];
    const fileExtension = file.name.split(".").at(-1);
    const allowedFileTypes = ["jpg", "png", "jpeg"];
    if (!allowedFileTypes.includes(fileExtension)) {
      const newName = "No file chosen";
      const input = e.currentTarget;
      const previousFile = input.files[0];
      const newFile = new File([previousFile], newName);
      const dT = new DataTransfer();
      dT.items.add(newFile);
      input.files = dT.files;
      toast.error("File does not support Upload jpg,png,jpeg files", { theme: "colored", toastId: 'error3', autoClose: 3000 });
      return false;
    }
    return true;
  }

  const customModal = (type) => {
    if (type === "images") toast.error("please first remove video Link", { theme: "colored", autoClose: 2000 })
    else {
      if (model.images.length > 0) {
        toast.error("please first remove images", { theme: "colored", autoClose: 2000 })
      }
    }
  }

  function deleteFile(e, type) {
    if (type === "video") {
      model.images = model.images.filter((url, index) => index !== e);
      setShowVideo(false)
      setVideoUrl()
      setIsVideo(false)
      setModel({ ...model, images: [...model.images] });
    }
    else {
      const value = imgurl.filter((url, index) => index !== e);
      model.images = model.images.filter((url, index) => index !== e);
      setImgurl(value);
      setVideoUrl()
      setModel({ ...model, images: [...model.images] });
    }

  }

  const Create = async (e) => {
    e.preventDefault();
    var response = null;
    if (valid()) {
      let value = { userId: Common.UserId() };
      setModel({ ...model, ...value });
      showLoader();
      if (model.id === null) {
        response = await services.Create(ServiceAPI.StudentPortFolioCreateApi, model);
      } else {
        response = await services.Update(ServiceAPI.StudentPortFolioUpdateApi, model);
      }
      if (response && response.message === "Success") {
        if (props.id) {
          hideLoader();
          $(`#${props.modalid}`).click();
          props.GetStudentPortfolio();
          toast.success('Successful', { theme: "colored", autoClose: 2000 })
          $(`#${props.modalid}`).click();
          setTimeout(() => {
            props.GetStudentPortfolio();
            hideLoader();
            $('.modal-backdrop').removeClass('show ');
            $('.modal').removeClass('show');
            $('body').toggleClass('p-0');
            $('body').removeClass('modal-open');
            $('nav.navbar.navbar-expand-sm').addClass('p-0');
          }, 2000)
        }
        else if (props.id2) {
          hideLoader();
          $(`#${props.modalid}`).click();
          props.GetStudentPortfolio();
          toast.success('Successfully Added', { theme: "colored", autoClose: 2000 })
          setModel(DefaultModel);
          $(`#${props.modalid}`).click();
          setImgurl('');
          setTimeout(() => {
            $('.modal-backdrop').removeClass('show ');
            $('.modal').removeClass('show');
            $('body').toggleClass('p-0');
            $('body').removeClass('modal-open');
            $('nav.navbar.navbar-expand-sm').addClass('p-0');
          }, 2000)
        }
        else {
          toast.success('Successfully Added', { theme: "colored", autoClose: 2000, toastId: 'success' })
          setTimeout(() => {
            history.push("/studentportfoliolist")
          }, 2000);
        }
      } else {
        hideLoader();
      }
    }
  };

  const valid = () => {
    let Error = [];
    let title = "";
    let images = "";
    let titleLink = "";
    // let viedolink = ''
    function isValidWebUrl(url) {
      let regEx = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gm;
      return regEx.test(url);
    }
    if (!Boolean(model.title)) {
      Error.push("Valid Title required")
      title = "borderred";
    }
    if (model.title.length > 100) {
      title = "borderred";
      Error.push("Valid Length required for Title")
    }
    if (!Boolean(model.images.length)) {
      images = "borderred";
      Error.push("Valid Image required");
    }
    // if (!Boolean(model.images.length)) {
    //   if (!videourl) {
    //     images = "borderred";
    //     viedolink = "borderred"
    //     Error.push("Valid Image or youtube link required")
    //   }
    // }
    // if (isvideo === true && videourl)
    //   if (!isValidWebUrl(videourl)) {
    //     titleLink = "borderred";
    //     Error.push("Valid video link required")
    //   }

    if (title || images || titleLink) {
      setBorder({ title, images, titleLink });
    }
    if (Error.length === 0) {
      Error = [];
      return true;
    } else if (Error.length > 2) {
      return false;
    } else if (Error.length <= 2) {
      Error.forEach((element, index) => {
        toast.error(element, { theme: "colored", toastId: `${[index]}` });
      });
      return false;
    }
  };
  const back = () => {
    history.push("/studentportfoliolist");
  }
  const DeletePortfolio = async (id) => {
    showLoader()
    const res = await services.Delete(ServiceAPI.StudentPortFolioDeleteApi, id);
    hideLoader()
    if (res.message === "Success") {
      if (props.id) {
        hideLoader();
        props.GetStudentPortfolio();
        toast.success('Successful Deleted...', { theme: "colored", autoClose: 2000 })
        setTimeout(() => {
          hideLoader();
          $('.modal-backdrop').removeClass('show ');
          $('.modal').removeClass('show');
          $('body').toggleClass('p-0');
          $('body').removeClass('modal-open');
          $('nav.navbar.navbar-expand-sm').addClass('p-0');
        }, 2000)
      }
    }
  }
  return (
    <>
      {props.id || props.id2 ? "" : <AdminHeader heading="" subheading="Manage Portfolio" />}
      <form onSubmit={Create} autoComplete="off">
        <div className="theme-container-admin container-fluid">
          {props.id || props.id2 ? "" : <StudentNav portfolio="active"></StudentNav>}
          <div className="row">
            <div className="mx-auto detail-box form-sec">
              <div className="row m-0 align-items-center">
                <div className="col-md-12">
                  <div className="label">Title<span className="required">*</span></div>
                  <input
                    type="text"
                    id="title"
                    className={border.title ? border.title : "xu-input"}
                    placeholder="Title"
                    value={model.title}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                </div>
                {/* {isvideo === true && model.images.length !== 0 ?
                  <div className="col-md-9">
                    <div className="label">image<span className="required">*</span></div>
                    <div
                      className="xu-input xpading"
                    >
                      <div className="btn btn-outline choose-file" id="logo" onClick={() => customModal("images")}>Choose file</div>
                      <span className="file-name">  {"No file chosen"} </span>
                    </div></div> : */}
                <div className="col-md-9">
                  <div className="label">Image<span className="required">*</span><span className="">(JPG or PNG)</span></div>
                  <input
                    multiple
                    type="file"
                    id="images"
                    className={border.images ? `${border.images} xpading` : "xu-input xpading"}
                    placeholder="Image"
                    accept="image/jpeg, image/png,  image/jpg"
                    onChange={(e) => UploadImage(e)}
                  ></input>
                </div>
                {/* } */}
                <div className="col-md-3 img-preview">
                  {imgurl ? imgurl.map((url, index) => {
                    return (
                      <span key={url + index} className="img-preview-block">
                        <img src={url} alt="" className="multi-img" />
                        <button type="button" className="close modalclose-btn ab" onClick={() => deleteFile(index)}>
                          x
                        </button>
                      </span>
                    );
                  })
                    : showimage === true ? `${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/default-img.jpg` : imgurl ? <img src={imgurl ? `${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/default-img.jpg` : ""} className="ad-img img-fluid" /> : ""}
                  {showimage && imgurl.length < 5 ? <span className="img">
                    <div className="admin-img-preview">
                      <div className="lds-roller sm"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    </div>
                  </span> : ""}
                </div>
                {/* {model.images.length === 0 || isvideo === true ? */}
                <div className="col-md-12">
                  <div className="label">youtube video link
                    {/* <span className="required">*</span> */}
                  </div>
                  <input
                    type="text"
                    id="videourl"
                    // className={border.images ? `${border.images} xpading` : "xu-input xpading"}
                    className="xu-input xpading"
                    placeholder="https://youtu.be/..."
                    value={model && isvideo === true ? videourl : ""}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                </div>
                {/* :
                  <div className="col-md-12">
                    <div className="label">youtube video link
                      <span className="required">*</span>
                    </div>
                    <div
                      onClick={() => customModal()}
                      className="xu-input xpading"
                    >
                    </div></div>
                } */}

                <div className="col-md-12">
                  <div className="label">Portfolio Link </div>
                  <input
                    type="text"
                    id="titleLink"
                    className={border.titleLink ? border.titleLink : "xu-input"}
                    placeholder="Portfolio Link"
                    value={model.titleLink}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                </div>
                <div className="col-md-12">
                  <div className="label"> description{`(${model.description ? model.description.trim().match(/\b[a-zA-Z]+\b/g)?.length : 0}/300 words)`}</div>
                  <textarea
                    // maxLength={300}
                    minLength={0}
                    type="text"
                    id="description"
                    className="xu-textarea"
                    placeholder="description"
                    value={model.description}
                    onChange={(e) => ChangeHandler(e)}
                    rows="5"
                  ></textarea>
                </div>
                <div className="col-md-12">
                  <div className="label">Active</div>
                  <label className="switch">
                    <input
                      className=""
                      type="checkbox"
                      id="active"
                      checked={model.active}
                      onChange={(e) => ChangeHandler(e)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                {props.id ? <div className=" delete">
                  <a
                    className="fa fa-trash delete"
                    onClick={() => DeletePortfolio(model.id)}
                  >
                  </a>
                </div> : ""}
                <div className="col-md-12 spacing" >
                  <button
                    className="btn btn-outline a-btn mt-0 float-right"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
                {props.id ? <div className=" delete">
                  <a
                    className="fa fa-trash delete"
                    onClick={() => DeletePortfolio(model.id)}
                  >
                  </a>
                </div> : ""}
              </div>{" "}
            </div>{" "}
          </div>
          {props.id || props.id2 ? "" : <div className='back-icon' onClick={back}></div>}
        </div>
      </form>
      {loader}

    </>
  );
};

export default StudentPortFolio;
