import Common from "../../Utility/Common";
const StudentModel = () => ({
        id: Common.LoginId(),
        firstName: '',
        lastName: '',
        description: '',
        address: '',
        country: '',
        state: '',
        city: '',
        pincode: '',
        dob: '',
        gender: '',
        studentLanguage: '',
        profileImage: '',
        coverImage: "",
        mobileNo: '',
        email: '',
        active: true,
        userId: Common.UserId(),
        resume: '',
        toolKits: '',
        attributes: '',
        educations: '',
        twitterUrl: '',
        facebookUrl: '',
        instagramUrl: '',
        collegeId: '',
        Logo:'',
});
export default StudentModel;
