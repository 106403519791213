import React, { useState, useEffect, useMemo } from "react";
import service from "../../../Services/UtilityService";
import ServiceAPI from "../../../Utility/ServiceList";
import TableView from "../../../Utility/TableView";
import AdminNav from "./CollegeNav";
import AdminHeader from '../../Common/AdminHeader';
import Common from "../../../Utility/Common";
import TableList from "../../../Utility/TableList"
import $ from "jquery";
import useLoader from '../../Auth/Loader'

const CollegeLocationList = () => {
  const [itemlist, setItemList] = useState([]);
  const [loader, showLoader, hideLoader] = useLoader();
  var services = new service();
  const data = itemlist;
  localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      }, {
        Header: 'Address',
        accessor: 'address',
      }, {
        Header: "Active",
        accessor: "active",
        Cell: (props) => {
          return (
            <label className="switch">
              <input
                disabled
                type="checkbox"
                defaultChecked={props.value}
              />
              <span className="slider round"></span>
            </label>
          )
        },
      },
      {
        Header: "Edit",
        accessor: "edit",
        Cell: ({ row }) => {
          const itemID = data.map((item, index) => item.id);
          return (
            <label>
              <a
                onClick={() => editfunction(itemID[row.index])}
                className="fa fa-pencil edit"
                aria-hidden="true"
              />
            </label>
          );
        },
      }, {
        Header: "Delete",
        accessor: "delete",
        Cell: ({ row }) => {
          const itemID = data.map((item, index) => item.id);
          return (

            <label>
              <a
                onClick={() => Delete(itemID[row.index])}
                className="fa fa-trash delete"
                aria-hidden="true"
              />
            </label>

          );
        },
      }

    ], [data])

  useEffect(() => {
    GetAll();
  }, []);

  async function GetAll() {
    $('.modal-backdrop').removeClass('show ');
    $('.modal').removeClass('show');
    $('body').toggleClass('p-0');
    $('body').removeClass('modal-open');
    showLoader()
    var res = await services.GetById(ServiceAPI.CollegeLocationGetAllApi, Common.LoginId());
    hideLoader()
    const data = await res.result;
    await setItemList(data);
  }
  const editfunction = (id) => {
    window.location = "/collegelocation?id=" + id;

  };
  const Delete = async (id) => {
    if (window.confirm("Are you sure you want to delete record ?")) {
      await services.Delete(ServiceAPI.CollegeLocationDeleteApi, id);
      await GetAll();
    }
  };
  return (
    <>
      <form>
        <div className="theme-container-admin">
          <AdminNav location="active"></AdminNav>
          <AdminHeader heading="College" subheading="Location List" />
          <div className="table-sec">
            <div className="add-option">
              <div className="title"></div>
              <a className="icon" href="/collegelocation">
                <i className="fa fa-plus" aria-hidden="true"></i>
              </a>
            </div>
            <div className="ad-table table-responsive">
              <TableList
                data={itemlist}
                columns={columns}
              />
            </div>
          </div>
        </div>
      </form>
      {loader}
    </>
  );
}

export default CollegeLocationList;