import React from 'react'
import baseService from './BaseService'
import ServiceAPI from '../Utility/ServiceList'
import MessageService from '../Utility/MessageService'
import { toast } from "react-toastify";
class RegistrationService {
    constructor() {
        this.service = new baseService();
    }
    async registration(data) {

        return new Promise((resolve, reject) => {
            this.service.post(ServiceAPI.registrationApi, false, data).then(resp => {
                if (resp.succeeded !== undefined) {
                    resolve("success");
                } else {
                    toast.error(resp.ErrorMessage, { theme: "colored", autoClose: 3000, toastId: 'error1' })
                    resolve(resp.ErrorMessage);
                }

            })
        })
    }

    async confirmEmail(userid, code, action) {
        return new Promise((resolve, reject) => {
            this.service.post(ServiceAPI.confirmAccountApi + '?userId=' + userid + '&code=' + code + '&action=' + action, false).then(resp => {
                if (resp.succeeded !== undefined) {
                    resolve(resp);
                } else {
                    alert(resp.ErrorMessage);
                }
            })
        })
    }


    async ChangeAdminPassword(model) {
        this.service.post(ServiceAPI.changePasswordApi, true, model).then(resp => {
            if (resp.succeeded !== undefined) {
                toast.success(MessageService.AccountChange, { theme: "colored", autoClose: 3000 });
                localStorage.removeItem("adminuserid");
                localStorage.removeItem("admintoken", resp.data.jwToken);
                localStorage.removeItem("adminusername");
                localStorage.removeItem("adminloginid");
                localStorage.removeItem("adminemail");
                localStorage.removeItem("adminusertype");
                localStorage.removeItem("adminprofileimage");
                localStorage.removeItem("adminfirstName");
                localStorage.removeItem("adminlastName");
                window.location = "/adminlogin";
            } else {
                toast.error(resp.ErrorMessage, { theme: "colored", autoClose: 3000 });

            }

        })
    }
    async ChangePassword(model) {
        this.service.post(ServiceAPI.changePasswordApi, true, model).then(resp => {
            if (resp.succeeded !== undefined) {
                toast.success(MessageService.AccountChange, { theme: "colored", autoClose: 3000 });
                localStorage.removeItem("userid");
                localStorage.removeItem("username");
                localStorage.removeItem("reftoken", resp.data.refreshToken);
                localStorage.removeItem("token", resp.data.jwToken);
                localStorage.removeItem("loginid");
                localStorage.removeItem("email");
                localStorage.removeItem("usertype");
                localStorage.removeItem("profileImage");
                localStorage.removeItem("firstName");
                localStorage.removeItem("lastName");
                window.location = '/login'
            } else {
                toast.error(resp.ErrorMessage, { theme: "colored", autoClose: 3000 });

            }

        })
    }


}
export default RegistrationService