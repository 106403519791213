import React, { useState, useEffect, useRef } from "react";
import Common from "../../../Utility/Common";
import DefaultModel from "../../../Model/Company/CompanyModel";
import service from "../../../Services/UtilityService";
import ServiceAPI from "../../../Utility/ServiceList";
import FormHeader from "../../Common/FormHeader";
import CompanyNav from "./CompanyNav";
import environment from '../../../environment';
import { toast, useToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useLoader from "../../Auth/Loader"
import AdminHeader from '../../Common/AdminHeader'
import Avtar from '../../Auth/Avtar'
import $ from 'jquery'

import "../../../../style.css";
import { useHistory } from "react-router-dom";

toast.configure()
const CompanyProfile = (props) => {
    Common.ValidLogin();
    const history = useHistory();
    const formData = new FormData();
    const apiKey = "AIzaSyAVLTGiMQzxgYBYnviizjkO9g - HRLIEMH4";
    const mapApiJs = 'https://maps.googleapis.com/maps/api/js';
    const searchInput = useRef('');
    const [addres, setAddres] = useState({})
    const id1 = props.id;
    const show = props.show == true ? true : ''
    const [loader, showLoader, hideLoader] = useLoader();
    const [model, setModel] = useState(DefaultModel);
    const [imgurl, setImgurl] = useState("./images/user.svg");
    const [cimg, setCimg] = useState("");
    const [border, setBorder] = useState("xu-input");
    const services = new service();
    const [showimage, setShowImage] = useState(false);
    const [showimage1, setShowImage1] = useState(false);
    const [industry, setIndustry] = useState([]);
    const [filename, setFileName] = useState('')
    const [showavtar, setShowAvtar] = useState(true)
    const [count, setCount] = useState(0)
    const [pageModel, setPageModel] = useState({
        "pageNum": 1,
        "pageSize": 50,
        "isActive": true
    });
    useEffect(() => {
        if (id1 && props.show === true) {
            props.setShow1(false)
            GetById(id1);
            BindIndustry()
        }
        else if (props.match || !props) {
            BindIndustry()
            const id = props.location.search.split("=")[1];
            if (id) { GetById(id); }
        }
    }, [id1, show]);
    const BindIndustry = async () => {
        var res = await services.PostGetAll(ServiceAPI.IndustryGetAllApi, pageModel);
        const data = await res.result
        data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
        await setIndustry(data);
    }
    const ChangeHandler = (e) => {
        let value = {};
        if (e.target.type === "checkbox") {
            value[e.target.id] = e.target.checked;
        } else if (e.target.type === "select-one") {
            value[e.target.id] = e.target.options[e.target.selectedIndex].value;
        } else {
            value[e.target.id] = e.target.value;
        }
        setModel({ ...model, ...value });
        setBorder({ ...border, [e.target.id]: "" })
    };

    const UploadImage1 = async (e, img) => {
        $('.modal1').removeClass('modal2');
        setImgurl()
        setShowImage(true)
        var s = {
            bitcode: e.split(',')[0],
            userId: model.userId,
            role: "company",
            typeDir: "logo",
            extension: `${img.target.files[0].type.split("/")[1]}`
        }
        setCount(1)
        var data = await services.Create(ServiceAPI.EncodedImageUplaodApi, s);
        var url = environment.imageUrl + data.result;
        setImgurl(url);
        setShowImage(false)
        setFileName(img.target.files[0].name)
        let value = { logo: url };
        await setModel({ ...model, ...value });
    }
    const UploadCoverImage = async (e) => {
        if (validImage(e)) {
            setCimg()
            setShowImage1(true)
            formData.append('file', e.target.files[0]);
            formData.append('imageType', '1');
            var data = await services.UploadImages(ServiceAPI.ImageUplaodApi, "coverimg", formData);
            var url = environment.imageUrl + data.result;
            setCimg(url);
            setShowImage1(false)
            let img = { coverImage: url }
            await setModel({ ...model, ...img });
        }
    }

    const validImage = (e) => {
        let file = e.target.files[0];
        const fileExtension = file.name.split(".").at(-1);
        const allowedFileTypes = ["jpg", "png", "jpeg"];
        if (!allowedFileTypes.includes(fileExtension)) {
            const newName = "No file chosen";
            const input = e.currentTarget;
            const previousFile = input.files[0];
            const newFile = new File([previousFile], newName);
            const dT = new DataTransfer();
            dT.items.add(newFile);
            input.files = dT.files;
            toast.error("File does not support Upload jpg,png,jpeg files", { theme: "colored", toastId: 'error3', autoClose: 3000 });
            return false;
        }
        return true;
    }

    const Create = async (e) => {
        e.preventDefault();
        var response = null;

        if (valid()) {
            let value = { userId: Common.UserId() };
            setModel({ ...model, ...value });
            showLoader();
            if (model.id === null) {
                response = await services.Create(ServiceAPI.CompanyCreateApi, model);
            } else {
                response = await services.Update(ServiceAPI.CompanyUpdateApi, model);
            }
            if (response && response.message === "Success") {
                localStorage.setItem("username", `${model.firstName} ${model.lastName}`);
                localStorage.setItem("profileImage", model.logo)
                if (props.id) {
                    hideLoader();
                    $(`#${props.modalid}`).click();
                    props.GetCompanyProfiles(Common.LoginId());
                    toast.success('Successful', { theme: "colored", autoClose: 2000, toastId: "id1" })

                    if (count == 1) {
                        hideLoader();
                        setTimeout(
                            function () {
                                window.location = `/companysprofile?id=${Common.LoginId()}`;
                            }, 2000);
                        toast.success('Successfully Update', { theme: "colored", autoClose: 2000, toastId: "id1" })
                        setTimeout(
                            function () {
                                window.location = `/companysprofile?id=${Common.LoginId()}`;
                            }, 2000);
                    }
                    else {
                        setTimeout(
                            function () {

                                toast.success('Successfully Update', { theme: "colored", autoClose: 2000, toastId: "id1" })
                                $('.modal-backdrop').removeClass('show ');
                                $('.modal').removeClass('show');
                                $('body').toggleClass('p-0');
                                $('body').removeClass('modal-open');
                                $('nav.navbar.navbar-expand-sm').addClass('p-0');
                                history.push(`/companysprofile?id=${Common.LoginId()}`)

                            }, 2000);
                    }
                }
                else if (props.id2) {
                    hideLoader();
                    $(`#${props.modalid}`).click();
                    props.GetCompanyProfiles(Common.LoginId());
                    toast.success('Successful', { theme: "colored", autoClose: 2000, toastId: "id1" })
                }
                else {
                    hideLoader();
                    GetById(Common.LoginId())
                    if (count === 1) {
                        setTimeout(
                            function () {

                                window.location = `/companyprofile?id=${Common.LoginId()}`;
                            }, 2000);
                        toast.success('Successfully Update', { theme: "colored", autoClose: 2000, toastId: "id1" })
                        setTimeout(
                            function () {
                                window.location = `/companyprofile?id=${Common.LoginId()}`;
                            }, 2000);
                    }
                    else {
                        setTimeout(
                            function () {
                                toast.success('Successfully Update', { theme: "colored", autoClose: 2000, toastId: "id1" })
                                history.push(`/companyprofile?id=${Common.LoginId()}`)
                            }, 2000);
                    }
                }
            } else {
                hideLoader();
            }
        }
    };

    const GetById = async (id) => {
        if (!show) {
            $('.modal-backdrop').removeClass('show ');
            $('.modal').removeClass('show');
            $('body').toggleClass('p-0');
            $('body').removeClass('modal-open');
        }

        showLoader()
        var res = await services.GetStudentDataById(ServiceAPI.CompanyGetApi, id, {
            "studentId": null,
            "companyId": id,
            'collegeId': null
        });
        hideLoader()
        const data = await res.result;
        setModel(data);
        localStorage.setItem("cpprofile", JSON.stringify(data))
        localStorage.setItem("profileImage", data.logo)
        setImgurl(data.logo);
        setCimg(data.coverImage);
    };
    const valid = () => {

        let Error = [];
        let firstName = '';
        let lastName = '';
        let name = '';
        let email = "";
        let logoImage = "";
        let coverImage = "";
        let website = '';
        let address = "";
        let industry = '';

        if (!Boolean(model.firstName.trim())) {
            Error.push("FirstName is required.");
            firstName = "borderred"
        }
        if (!Boolean(model.lastName.trim())) {
            Error.push("LastName is required.");
            lastName = "borderred"
        }
        if (!Boolean(model.name.trim())) {
            Error.push("Company Name is required.");
            name = "borderred"
        }
        if (!Boolean(model.email)) {
            Error.push("Email is required.");
            email = "borderred"
        }
        if (!Boolean(model.logo.trim())) {
            Error.push("logo is required.");
            logoImage = "borderred"
        }
        if (model.isDefaultLogo == true) {
            if (!Boolean(model.coverImage)) {
                Error.push("Leading Image is required.");
                coverImage = "borderred"
            }
        }

        if (!Boolean(model.website.trim())) {
            Error.push("Website is required.");
            website = "borderred"
        }
        if (!Boolean(model.address.trim())) {
            Error.push("address is required.");
            address = "borderred"
        }
        if (!Boolean(model.industryId) || model.industryId === "0") {
            Error.push("Industry is required.");
            industry = "borderred";
        }

        if (firstName || lastName || name || email || logoImage || coverImage || website || address || industry) {
            setBorder({
                firstName, lastName, name, email, logoImage, coverImage, website, address, industry
            });
            // console.log(border)
        }
        if (Error.length === 0) {
            Error = [];
            return true;
        } else if (Error.length > 2) {
            toast.error('Please fill the required fields', { theme: "colored" });
            return false;
        } else if (Error.length <= 2) {
            Error.forEach((element, index) => {
                toast.error(element, { theme: "colored", toastId: `${[index]}` });
            });
            return false;
        }
    };
    const loadAsyncScript = async (src) => {
        return new Promise(resolve => {
            const script = document.createElement("script");
            Object.assign(script, {
                type: "text/javascript",
                async: true,
                src
            })
            script.addEventListener("load", () => resolve(script));
            document.head.appendChild(script);
        })
    }
    // init gmap script
    const initMapScript = async () => {
        // if script already loaded
        if (window.google) {
            return await Promise.resolve();
        }
        const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
        return await loadAsyncScript(src);
    }
    const extractAddress = (place) => {
        const address = {
            city: "",
            state: "",
            country: "",
            pincode: "",
            address: "",
            plain() {
                const city = city ? city + ", " : "";
                const pincode = pincode ? pincode + ", " : "";
                const state = state ? state + ", " : "";
                const address = address ? address + ", " : "";
                const country = country ? country + ", " : "";
                return city + pincode + state + country + address;
            }
        }

        if (!Array.isArray(place?.address_components)) {
            return address;
        }

        place.address_components.forEach(component => {
            const types = component.types;
            const value = component.long_name;

            if (types.includes("locality")) {
                address.city = value;
            }

            if (types.includes("administrative_area_level_1")) {
                address.state = value;
            }

            if (types.includes("postal_code")) {
                address.pincode = value;
            }

            if (types.includes("country")) {
                address.country = value;
            }
            if (place) {
                var adr = place.formatted_address.split(",")
                address.address = place.formatted_address.split(",").length > 4 ? `${adr[0]}${adr[1]}` : adr[0];
            }
        });
        return address;
    }
    const onChangeAddress = async (autocomplete) => {
        const place = await autocomplete.getPlace();
        var values = extractAddress(place)
        setModel({ ...JSON.parse(localStorage.getItem("cpprofile")), ...values });
        setAddres(extractAddress(place));
    }
    // init autocomplete
    const initAutocomplete = () => {
        if (!searchInput.current) {
            return;
        }
        else {
            const autocomplete = new window.google.maps.places.Autocomplete(searchInput.current);
            autocomplete.setFields(["address_component", "geometry", "formatted_address"]);
            autocomplete.addListener("place_changed", () => onChangeAddress(autocomplete));
        }
    }
    useEffect(() => {
        initMapScript().then(() => initAutocomplete())
    }, [searchInput.current]);

    const closeModal = (type) => {
        setShowAvtar(false)
        setTimeout(() => {
            setShowAvtar(true)
            $("#exampleMediaAdd").removeClass("modal2")
        }, 10);
    }
    const customModal = () => {
        $("#exampleMediaAdd").addClass("modal2")
    }
    return (
        <>
            {props.id || props.id2 ? "" : <AdminHeader heading="" subheading="Manage Profile" />}
            <form onSubmit={Create}>
                <div className="theme-container-admin min-h">
                    {props.id || props.id2 ? "" : <CompanyNav profile="active"></CompanyNav>}
                    <div className="row">
                        <div className=" col-md-6 mx-auto detail-box form-sec profile-box">
                            <div className="row m-0 align-items-center">
                                <div className="col-md-2">
                                    {imgurl ? <img src={imgurl} className="profile-login sm" />
                                        : showimage ? <span className="img mt-3">
                                            <div className="admin-img-preview mt-3">
                                                <div className="lds-roller sm"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                            </div>
                                        </span> : <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/user.svg`} className="ad-img img-fluid" />}
                                </div>

                                <div className="col-md-10" id="mod" >
                                    <div className="label">
                                        Choose logo<span className="required">*</span>
                                    </div>
                                    <div
                                        id="logo"
                                        className="xu-input xpading"
                                    >
                                        <a className="btn btn-outline choose-file" id="logo" onClick={() => customModal()}>Choose file</a>
                                        <span className="file-name"> {filename ? filename : "No file chosen"}</span>
                                    </div>
                                </div>
                                <div className="modal modal1 bg-modal"

                                    id={`exampleMediaAdd`}
                                    tabIndex="-1"
                                    aria-labelledby="exampleModalLabel"
                                >
                                    <div className="modal-dialog modal-dialog-centered " >
                                        <div className="modal-content">
                                            <div className="modal-header flex">
                                                <div className="preview-title">PROFILE IMAGE </div>
                                                <button type="button" className="modalclose-btn" data-dismiss-modal="modal2" onClick={() => closeModal()}>&times;</button>
                                            </div>
                                            <div className="modal-body">
                                                {showavtar ? <Avtar UploadImage={UploadImage1} /> : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">First Name<span className="required">*</span></div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="firstName"
                                        className={border.firstName ? border.firstName : "xu-input"}
                                        placeholder="First Name"
                                        value={model.firstName}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Last Name<span className="required">*</span></div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="lastName"
                                        className={border.lastName ? border.lastName : "xu-input"}
                                        placeholder="Last Name"
                                        value={model.lastName}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Company Name<span className="required">*</span></div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="name"
                                        className={border.name ? border.name : "xu-input"}
                                        placeholder="Company Name"
                                        value={model.name}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Email<span className="required">*</span></div>
                                    <input
                                        autoComplete="none"
                                        type="email"
                                        id="email"
                                        className={border.email ? border.email : "xu-input"}
                                        placeholder="Email"
                                        value={model.email}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Website<span className="required">*</span></div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="website"
                                        className={border.website ? border.website : "xu-input"}
                                        placeholder="Website"
                                        value={model.website}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Instagram Link</div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="instagramUrl"
                                        className="xu-input"
                                        placeholder="Url"
                                        value={model.instagramUrl}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Facebook Link</div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="facebookUrl"
                                        className="xu-input"
                                        placeholder="Url"
                                        value={model.facebookUrl}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Twitter Link</div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="twitterUrl"
                                        className="xu-input"
                                        placeholder="Url"
                                        value={model.twitterUrl}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Industry<span className="required">*</span></div>
                                    <select className={border.industry ? border.industry : "xu-input"} id="industryId" value={model.industryId} onChange={(e) => ChangeHandler(e)}>
                                        <option value="0">Select</option>
                                        {
                                            industry.map((item) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>{" "}
                        </div>{" "}
                        <div className="col-md-6 mx-auto detail-box form-sec profile-box pl-1">
                            <div className="row m-0 align-items-center">
                                <div className="col-md-2">
                                    {cimg ? <img src={cimg} className="ad-img img-fluid" />
                                        : showimage1 ? <span className="img mt-3">

                                            <div className="admin-img-preview mt-3">
                                                <div className="lds-roller sm"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                            </div>
                                        </span> : <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/default-img.jpg`} className="ad-img img-fluid" />}
                                </div>
                                <div className="col-md-10"  >

                                    <div className="label">Leading Image <span className="">(JPG or PNG)</span></div>
                                    <input
                                        type="file"
                                        id="coverImage"
                                        className="xu-input xpading"
                                        placeholder="Image"
                                        accept="image/jpeg, image/png,  image/jpg"
                                        onChange={(e) => UploadCoverImage(e)}
                                    ></input>
                                </div>


                                <div className="col-md-12">
                                    <div className="label">Address<span className="required">*</span></div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="address"
                                        className={border.address ? border.address : "xu-input"}
                                        placeholder={"Address"}
                                        value={model.address}

                                        ref={searchInput}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>

                                <div className="col-md-6">
                                    <div className="label">Postal code</div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="pincode"
                                        className="xu-input"
                                        placeholder="Pincode"
                                        value={model.pincode}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">City</div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="city"
                                        className="xu-input"
                                        placeholder="City"
                                        value={model.city}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">State/Province</div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="state"
                                        className="xu-input"
                                        placeholder="State/Province"
                                        value={model.state}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Country</div>
                                    <input
                                        autoComplete="none"
                                        type="text"
                                        id="country"
                                        className="xu-input"
                                        placeholder="Country"
                                        value={model.country}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">show Leading Image</div>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            id="isDefaultLogo"
                                            checked={model.isDefaultLogo}
                                            onChange={(e) => ChangeHandler(e)}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="col-md-12 spacing">
                                    <button
                                        className="btn btn-outline a-btn mt-0 float-right"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            {loader}
        </>
    );


}
export default CompanyProfile;