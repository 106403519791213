import React, { useState, useEffect } from "react";
import Common from "../../../Utility/Common";
import DefaultModel from "../../../Model/Company/CompanyJobModel";
import service from "../../../Services/UtilityService";
import ServiceAPI from "../../../Utility/ServiceList"
import CompanyNav from "./CompanyNav";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Multiselect from 'multiselect-react-dropdown';
import useLoader from "../../Auth/Loader"
import AdminHeader from '../../Common/AdminHeader'
import $ from "jquery";
import { useHistory } from "react-router-dom";
import Texteditor from './Texteditor'
toast.configure()

const CompanyJob = (props) => {
  const history = useHistory();
  localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
  Common.ValidLogin();
  const formData = new FormData();
  const id1 = props.id;
  const show = props.show == true ? true : ''
  const [model, setModel] = useState(DefaultModel);
  const [jobcategorylist, setJobCategoryList] = useState();
  const [attribute, setAttribute] = useState([]);
  const [qualificationlist, setQualificationList] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [toolkit, setToolkit] = useState([]);
  const [jobId, setJobId] = useState('');
  const [border, setBorder] = useState("");
  const [pageModel, setPageModel] = useState({
    "pageNum": 1,
    "pageSize": 100,
    "isActive": true
  });
  const [loader, showLoader, hideLoader] = useLoader();
  const services = new service();

  useEffect(() => {
    if (id1 && props.show === true) {
      props.setShow2(false)
      GetById(id1);
      BindAttribute();
      BindIndustry();
      BindToolkit();
      GetQualification();
      GetJobCategory();
    }
    else if (props.id2 === "0" && props.show === true) {
      props.setShow2(false)
      BindAttribute();
      BindIndustry();
      BindToolkit();
      GetQualification();
      GetJobCategory();
    }
    else if (props.match || !props) {
      BindAttribute();
      BindIndustry();
      BindToolkit();
      GetQualification();
      GetJobCategory();
      const id = props.location.search.split("=")[1]
      if (id) { GetById(id); }
    }

  }, [id1, show]);

  const GetJobCategory = async () => {
    var res = await services.Create(ServiceAPI.CompanyJobCategoryApi, {
      "pageNum": 1,
      "pageSize": 100,
      "isActive": true,
      "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
    });
    const data = res.result
    data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    setJobCategoryList(data);
  }
  const GetById = async (id) => {
    if (!show) {
      $('.modal-backdrop').removeClass('show ');
      $('.modal').removeClass('show');
      $('body').toggleClass('p-0');
      $('body').removeClass('modal-open');
    }
    showLoader()
    setModel(DefaultModel);
    var res = await services.GetById(ServiceAPI.CompanyJobGetApi, id);
    hideLoader()
    const job = await res.result;
    setJobId(job.id);
    await setModel(job);
  };
  const GetQualification = async () => {
    var res = await services.PostAnonymous(ServiceAPI.QualificationGetAllApi, {
      "pageNum": 1,
      "pageSize": 100,
      "isActive": true,
      "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
    });
    const qualificationData = await res.result
    qualificationData.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    setQualificationList(qualificationData)

  };
  const BindAttribute = async () => {
    var res = await services.PostGetAll(ServiceAPI.AttributeGetAllApi, pageModel);
    const data = await res.result;
    data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    await setAttribute(data);

  }

  const BindIndustry = async () => {
    var res = await services.PostGetAll(ServiceAPI.IndustryGetAllApi, pageModel);
    const data = await res.result;
    data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    await setIndustry(data);

  }
  const BindToolkit = async () => {
    var res = await services.PostGetAll(ServiceAPI.ToolKitGetAllApi, pageModel);
    const data = await res.result;
    data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    await setToolkit(data);
  }
  const DesEdit = (value) => {
    const wordCount = value.trim().split(/\s+/).filter(Boolean).length;
    if (wordCount <= 500) {
      setModel({ ...model, description: value });
    }
  };
  const ChangeHandler = (e) => {
    let value = {};
    if (e.target.type === "checkbox") {
      value[e.target.id] = e.target.checked;
    } else if (e.target.type === "select-one") {
      ;
      value[e.target.id] = e.target.options[e.target.selectedIndex].value;
      if (e.target.id === 'industryId') {
        if (e.target.options[e.target.selectedIndex].value != 0) {
        }
      }
    } else {
      value[e.target.id] = e.target.value;
    }
    setModel({ ...model, ...value });
    setBorder({ ...border, [e.target.id]: "" })
  };
  const AddValue = async (e, field) => {

    if (field === "companyJobAttributes") {
      let value = { companyJobAttributes: [...model.companyJobAttributes, { companyJobId: jobId ? jobId : null, attributeId: e.slice(-1)[0].id, name: e.slice(-1)[0].name, attribute: null, id: null, userId: Common.UserId(), active: true }] };
      await setModel({ ...model, ...value });
    } else if (field === "companyJobCourses") {
      let value = { companyJobCourses: [...model.companyJobCourses, { companyJobId: jobId ? jobId : null, courseId: e.slice(-1)[0].id, name: e.slice(-1)[0].name, course: null, id: null, userId: Common.UserId(), active: true }] };
      await setModel({ ...model, ...value });
    } else if (field === "companyJobToolkits") {
      let value = { companyJobToolkits: [...model.companyJobToolkits, { companyJobId: jobId ? jobId : null, toolkitId: e.slice(-1)[0].id, name: e.slice(-1)[0].name, toolkit: null, id: null, userId: Common.UserId(), active: true }] };
      await setModel({ ...model, ...value });
    }
    else if (field === "jobCategory") {
      let value = { jobCategory: [...model.jobCategory, { companyJobId: null, JobCategoryId: e.slice(-1)[0].id, name: e.slice(-1)[0].name }] };
      await setModel({ ...model, ...value });
    }
  }
  const RemoveValue = async (e, field) => {
    if (field === "companyJobAttributes") {
      let value = { companyJobAttributes: e }
      await setModel({ ...model, ...value });

    } else if (field === "companyJobCourses") {
      let value = { companyJobCourses: e }
      await setModel({ ...model, ...value });

    } else if (field === "companyJobToolkits") {
      let value = { companyJobToolkits: e }
      await setModel({ ...model, ...value });

    }
    else if (field === "jobCategory") {
      let value = { jobCategory: e }
      await setModel({ ...model, ...value });
    }
  }

  const Create = async (e) => {
    e.preventDefault();
    var response = null;
    if (valid()) {
      let value = { userId: Common.UserId() };
      setModel({ ...model, ...value });
      showLoader();
      if (model.id === null) {
        response = await services.Create(ServiceAPI.CompanyJobCreateApi, model);
      } else {
        response = await services.Update(ServiceAPI.CompanyJobUpdateApi, { ...model, "industry": "" });
      }
      if (response && response.message === "Success") {
        if (props.id) {
          hideLoader();
          props.GetcompanyJobs(); $(`#${props.modalid}`).click();
          toast.success('Successful', { theme: "colored", autoClose: 2000 })

          setTimeout(() => {
            $(`#${props.modalid}`).click();
            hideLoader();
            $('.modal-backdrop').removeClass('show ');
            $('.modal').removeClass('show');
            $('body').toggleClass('p-0');
            $('body').removeClass('modal-open');
            $('nav.navbar.navbar-expand-sm').addClass('p-0');
          }, 2000)
        }
        else if (props.id2) {
          hideLoader();
          $(`#${props.modalid}`).click();
          props.GetcompanyJobs();
          toast.success('Successful', { theme: "colored", autoClose: 2000 })
          setModel(DefaultModel);
          setTimeout(() => {
            $(`#${props.modalid}`).click();
            $('.modal-backdrop').removeClass('show ');
            $('.modal').removeClass('show');
            $('body').toggleClass('p-0');
            $('body').removeClass('modal-open');
            $('nav.navbar.navbar-expand-sm').addClass('p-0');
          }, 2000)
        }
        else {
          toast.success('Successful', { theme: "colored", autoClose: 2000 })
          setTimeout(() => {
            history.push("/companyjoblist");
          }, 2000);

        }
      } else {
        hideLoader();
      }
    }
  };

  const valid = () => {
    let Error = [];
    let title = "";
    let description = "";
    let city = "";
    let state = "";
    let country = "";
    let Attributes = "";
    let Toolkits = "";
    let Course = "";
    let Industry = "";
    let companyURL = ''

    if (!Boolean(model.title)) {
      Error.push("Valid Title required");
      title = "borderred";
    }
    if (model.title.length > 50) {
      Error.push("Valid Length required for Title");
      title = "borderred";
    }
    if (!Boolean(model.companyJobAttributes.length)) {
      Error.push("Valid Attributes required");
      Attributes = "borderred";
    }
    if (!Boolean(model.companyJobToolkits.length)) {
      Error.push("Valid Attributes required");
      Toolkits = "borderred";
    }

    if (!Boolean(model.companyJobCourses.length)) {
      Error.push("Valid Qualification required");
      Course = "borderred";
    }
    if (!Boolean(model.industryId) || model.industryId === "0") {
      Error.push("Valid Industry required");
      Industry = "borderred";
    }

    if (!Boolean(model.city)) {
      Error.push("Valid City required");
      city = "borderred";
    }
    if (!Boolean(model.state)) {
      Error.push("Valid state required");
      state = "borderred";
    }
    if (!Boolean(model.country)) {
      Error.push("Valid country required");
      country = "borderred";
    }
    const regex = /^((http(s?)?):\/\/)([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g
    if (!regex.test(model.companyURL)) {
      Error.push("Enter valid url \nfor example https://www.example.com");
      companyURL = "borderred";
    }
    if (!Boolean(model.description)) {
      Error.push("Valid Description required");
      description = "borderred";
    }

    if (title || Attributes || Toolkits || Course || Industry || description || city || state || country || companyURL) {
      setBorder({ title, Attributes, Toolkits, Course, Industry, description, city, state, country, companyURL });
    }
    if (Error.length === 0) {
      Error = [];
      return true;
    } else if (Error.length > 2) {
      toast.error('Please fill the required fields', { theme: "colored", toastId: 'error1' });
      return false;
    } else if (Error.length <= 2) {
      Error.forEach((element, index) => {
        toast.error(element, { theme: "colored", toastId: `${[index]}` });
      });
      return false;
    }
  };
  const back = () => {
    history.push("/companyjoblist");
  }
  const DeleteJob = async (id) => {
    const res = await services.Delete(ServiceAPI.CompanyJobDeleteApi, id)
    if (res.message == "Success") {
      if (props.id) {
        props.GetcompanyJobs();
        toast.success('Successful Deleted...', { theme: "colored", autoClose: 2000, toastId: 'success1' })
        setTimeout(() => {
          hideLoader();
          $('.modal-backdrop').removeClass('show ');
          $('.modal').removeClass('show');
          $('body').toggleClass('p-0');
          $('body').removeClass('modal-open');
          $('nav.navbar.navbar-expand-sm').addClass('p-0');

        }, 2000)
      }
    }
  }
  return (
    <>
      {props.id || props.id2 ? "" : <AdminHeader heading="" subheading="Manage Job" />}
      <form onSubmit={Create} autoComplete="off">
        <div className="theme-container-admin container-fluid">
          {props.id || props.id2 ? "" : <CompanyNav job="active"></CompanyNav>}
          <div className="row">
            <div className="mx-auto detail-box form-sec">
              <div className="row m-0 align-items-center">
                <div className="col-md-12">
                  <div className="label">Title<span className="required">*</span></div>
                  <input
                    type="text"
                    id="title"
                    className={border.title ? border.title : "xu-input"}
                    placeholder="Title"
                    value={model.title}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                </div>
                <div className="col-md-6">
                  <div className="label">Attributes<span className="required">*</span></div>
                  <Multiselect
                    id="companyJobAttributes"
                    onSelect={(e) => AddValue(e, "companyJobAttributes")}
                    selectedValues={model.companyJobAttributes}
                    options={attribute}
                    displayValue="name"
                    onRemove={(e) => RemoveValue(e, "companyJobAttributes")}
                  />
                </div>

                <div className="col-md-6">
                  <div className="label">Toolkit<span className="required">*</span></div>
                  <Multiselect
                    className="xu-input"
                    id="companyJobToolkits"
                    onSelect={(e) => AddValue(e, "companyJobToolkits")}
                    selectedValues={model.companyJobToolkits}
                    options={toolkit}
                    displayValue="name"
                    onRemove={(e) => RemoveValue(e, "companyJobToolkits")}
                  />
                </div>
                <div className="col-md-6">
                  <div className="label">Industry<span className="required">*</span></div>
                  <select className={border.Industry ? border.Industry : "xu-input"} id="industryId" value={model.industryId} onChange={(e) => ChangeHandler(e)}>
                    <option value="0">Select</option>
                    {
                      industry.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-6">
                  <div className="label">Qualification<span className="required">*</span></div>
                  <Multiselect
                    className="xu-input"
                    id="companyJobCourses"
                    onSelect={(e) => AddValue(e, "companyJobCourses")}
                    selectedValues={model.companyJobCourses}
                    options={qualificationlist}
                    displayValue="name"
                    onRemove={(e) => RemoveValue(e, "companyJobCourses")}
                  />
                </div>
                <div className="col-md-6">
                  <div className="label">JobCategories<span className="required">*</span></div>
                  <Multiselect
                    className="xu-input"
                    id="jobCategory"
                    onSelect={(e) => AddValue(e, "jobCategory")}
                    selectedValues={model.jobCategory}
                    options={jobcategorylist}
                    displayValue="name"
                    onRemove={(e) => RemoveValue(e, "jobCategory")}
                  />
                </div>
                <div className="col-md-6">
                  <div className="label">City<span className="required">*</span></div>
                  <input
                    autoComplete="none"
                    type="text"
                    id="city"
                    className={border.city ? border.city : "xu-input"}
                    placeholder="city"
                    value={model.city}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                </div>
                <div className="col-md-6">
                  <div className="label">State/Province<span className="required">*</span></div>
                  <input
                    autoComplete="none"
                    type="text"
                    id="state"
                    className={border.state ? border.state : "xu-input"}
                    placeholder="State/Province"
                    value={model.state}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                </div>
                <div className="col-md-6">
                  <div className="label">Country<span className="required">*</span></div>
                  <input
                    autoComplete="none"
                    type="text"
                    id="country"
                    className={border.country ? border.country : "xu-input"}
                    placeholder="country"
                    value={model.country}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                </div>
                <div className="col-md-12">
                  <div className="label">Job Url<span className="required">*</span></div>
                  <input
                    type="text"
                    id="companyURL"
                    className={border.title ? border.title : "xu-input"}
                    placeholder="https://www.example.com"
                    value={model.companyURL}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                </div>
                <div className="col-md-12">
                  <div className="label">Description<span className="required">*</span>{`(${model.description ? model.description.trim().split(/\s+/).filter(Boolean).length : 0}/500)`}</div>
                  <Texteditor data={`${model.description}`} ChangeHandler={DesEdit} />
                </div>

                <div className="col-md-2  ">
                  <div className="label">Active</div>
                  <label className="switch">
                    <input
                      className=""
                      type="checkbox"
                      id="active"
                      checked={model.active}
                      onChange={(e) => ChangeHandler(e)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                {props.id ? <div className=" delete">
                  <a
                    className="fa fa-trash delete"
                    onClick={() => DeleteJob(model.id)}
                  >
                  </a>
                </div> : ""}
                <div className="col-md-10">
                  <button
                    className="btn btn-outline a-btn mt-0 float-right"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>{" "}
            </div>{" "}
          </div>
          {props.id || props.id2 ? "" : <div className='back-icon' onClick={back}></div>}
        </div>
      </form>
      {loader}
    </>
  );
};

export default CompanyJob;
