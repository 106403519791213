const Terms = () => {
  // console.log("ss", window.location.href.split('/')[3].toString())
  return (
    <div>
      <section className="section min-height position-relative tabs-heading">
        <div className="row m-0 justify-content-center">
          <div className="col-md-4">
            <img
              src="./images/new-privacy-policy.svg"
              alt=""
              className="img-fluid privcy-img"
            />
          </div>
          <div className="col-md-6">
            <div className="stat-sec ">
              {/* <div className="banner">
                        <img src="./images/terms.png" alt="" className="img img-fluid" />
                    </div> */}

              {/* <!-- list nav --> */}
              <ul
                className="nav nav-pills justify-content-center admin-nav"
                id="pills-tab"
                role="tablist"
              >
                {/* <!-- profile --> */}

                <li className="nav-item" role="presentation">
                  <a className={window.location.href.split('/')[3].toString() == "terms" ? "nav-link active " : "nav-link"} id="terms-of-use" data-toggle="pill" href="#terms-block" role="tab" aria-controls="terms-block" aria-selected="true">Terms Of Use</a>
                </li>
                {/* <!-- password tab --> */}

                <li className="nav-item" role="presentation">
                  <a
                    className={
                      window.location.href.split("/")[3].toString() ==
                        "privacy"
                        ? "nav-link active "
                        : "nav-link"
                    }
                    id="privacy-policy"
                    data-toggle="pill"
                    href="#privacy-block"
                    role="tab"
                    aria-controls="privacy-block"
                    aria-selected="false"
                  >
                    Privacy policy
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className={
                      window.location.href.split("/")[3].toString() == "paia"
                        ? "nav-link active "
                        : "nav-link"
                    }
                    id="paia"
                    data-toggle="pill"
                    href="#paia-block"
                    role="tab"
                    aria-controls="paia"
                    aria-selected="false"
                  >
                    PAIA Form
                  </a>
                </li>
              </ul>

              <div className="tab-content " id="tnc">
                {/* <!-- profile --> */}

                <div
                  className={
                    window.location.href.split("/")[3].toString() == "terms"
                      ? "tab-pane fade active show"
                      : `tab-pane fade `
                  }
                  id="terms-block"
                  role="tabpanel"
                  aria-labelledby="terms-of-use"
                >
                  <div className="block">
                    <div className="terms-text">
                      <ul>
                        <li>
                          <b>Introduction and Legal Terms</b>
                        </li>
                        <ul>
                          <li>
                            By accessing or using our website, www.xylus.com
                            or any related platform or application
                            (collectively, “<b>the Platform</b>”), or any of
                            our services, you agree that you have read,
                            understood, and agree to be bound to the terms and
                            conditions contained herein (<b>“Terms”</b>). All
                            rights in and to the content of the Platform
                            always remain expressly reserved by Xylus.
                          </li>
                          <li>
                            These Terms explain the conditions applicable to
                            how you will use the Platform. Please read these
                            Terms carefully before using the Platform. We will
                            assume you have read and understood these Terms if
                            you continue to access or make use of our
                            Platform.
                          </li>
                          <li>
                            Please pay specific attention to the{" "}
                            <b>BOLD paragraphs</b> of these Terms. These
                            paragraphs limit our risk or liability, constitute
                            an assumption of risk or liability by you, impose
                            an obligation on you to indemnify us or is an
                            acknowledgement of any fact by you.
                          </li>
                          <li>
                            The terms "user", “you" and “your” are used
                            interchangeably in these Terms and accordingly
                            refer to anyone using the Platform for any reason
                            whatsoever. Accordingly, the terms “us”, “our” or
                            “we” refers to Xylus or our possession.
                          </li>
                        </ul>
                      </ul>
                      <ul>
                        <li>
                          <p>
                            <b>Our Services </b>
                          </p>
                        </li>
                        <ul>
                          <li>
                            <p>
                              {" "}
                              <u>What Our Platform Offers</u>{" "}
                            </p>
                          </li>
                          <p>
                            <ul>
                              <li>
                                Xylus is a centralised Platform that bridges
                                the gap between candidates, companies, and
                                colleges through our various services on the
                                Platform including:
                              </li>
                              <li>
                                A directory service for candidates seeking
                                creative qualifications from colleges;
                              </li>
                              <li>
                                A directory service for companies recruiting
                                for potential interns or employees with
                                creative backgrounds and qualifications;
                              </li>
                              <li>
                                Offering students and graduates the
                                opportunity to visually display their resume,
                                skills and creative portfolios to market
                                themselves to companies recruiting for entry
                                level creative roles;
                              </li>
                              <li>
                                Providing job and internship boards
                                specialising in entry level creative roles for
                                candidates;
                              </li>
                              <li>
                                Offering space for companies and colleges to
                                advertise events and post relevant news/media
                                information for other users; and
                              </li>
                              <li>
                                General advertising space for brands and
                                companies to market their offerings on the
                                Platform.
                              </li>
                              <li>
                                (collectively, <b>“Services”</b>).{" "}
                              </li>
                            </ul>
                          </p>
                          <li>
                            <p>
                              <b>Service Disclaimer</b>
                            </p>
                            <ul className="pl-4">
                              <li>
                                We are not associated to any company or
                                college who joins the Platform. Candidates
                                understand and agree that Xylus is only a
                                facilitating party and any liability stemming
                                from the relationship between the candidate
                                and a company or college lies exclusively with
                                these users, and not with Xylus.
                              </li>
                              <li>
                                We do not and cannot guarantee that any
                                candidate will receive placement or
                                registration with a college or job/position
                                with the company for which they have applied
                                at all. We expressly disclaim any liability or
                                responsibility for the outcome of any
                                application by means of the Services.
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </ul>
                      <ul>
                        <li>
                          <p>
                            <b>Registering a Profile with the Platform</b>
                          </p>
                        </li>
                        <ul>
                          <li>
                            <p>
                              <u>Creating a Profile:</u> To join the Platform
                              we require you to create a profile with us. We
                              ask you to provide us with all the requested
                              information in the on-boarding process.
                            </p>
                          </li>
                          <li>
                            <p>
                              <u>Vetting of Information:</u> For colleges and
                              companies to be eligible to join the Platform,
                              we request you to provide us with copies of
                              certain documents. We use these documents to
                              verify the information provided during
                              registration to ensure you are recognised in
                              your sector and not offering fraudulent
                              services.
                            </p>
                          </li>
                          <li>
                            <p>
                              <u>Accurate Information:</u> When signing up to
                              our Platform you agree to provide accurate,
                              current, and complete information and to update
                              this information as and when it changes. To
                              protect your privacy and security, we take
                              reasonable steps to verify your identity by
                              requiring your password together with your email
                              address to grant you access to your profile and
                              information. Please update your information
                              yourself through your profile or contact us to
                              view or change your personal information
                              provided.
                            </p>
                          </li>
                          <li>
                            <p>
                              <u>Warranty:</u> By sharing your personal
                              information with us, you warrant that the person
                              using the Platform is you or you have the
                              authority to act on behalf of a juristic entity.
                              You are responsible for the information you
                              provide, and all the actions taken on the
                              Platform. Please see our Privacy Policy for more
                              details on how we use and processes personal
                              information.
                            </p>
                          </li>
                          <li>
                            <p>
                              <u>Unlawful Access: </u>Please let us know if
                              you have reason to believe that your profile is
                              no longer secure (for example, in the event of a
                              loss, theft or unauthorized disclosure or use of
                              your username, password, or any payment card
                              information) to avoid possible liability for any
                              unauthorized charges to your account.
                            </p>
                          </li>
                        </ul>
                      </ul>

                      <ul>
                        <li>
                          <p>
                            <b>Payment Terms</b>
                          </p>
                        </li>
                        <ul>
                          <li>
                            <p>
                              <u>Free Use: </u>You are welcome to browse our
                              Platform for free without needing to create a
                              profile. Free access means that you will have
                              limited access to features and functions of the
                              Platform.
                            </p>
                          </li>
                          <li>
                            <p>
                              <u>Subscription:</u> When joining our Platform,
                              you must subscribe to any one of our available
                              packages. All packages are billed from the day
                              you first subscribe, and you will be rebilled on
                              the same day of each following month or year
                              thereafter. You can cancel your subscription at
                              any time before the next billing date and will
                              continue to have access to your subscription
                              until the next billing date, after which you
                              will not be billed again, and your subscription
                              will end.
                            </p>
                          </li>
                          <li>
                            <p>
                              <u>Subscription Fees:</u> Our subscription fees
                              are available on the Platform and are subject to
                              change at any time in our reasonable discretion.
                              You will be given 30 days prior notice where
                              there is a change in the subscription fee
                              charged.
                            </p>
                          </li>
                          <li>
                            <p>
                              <u>Corporate Users:</u> If you are using our
                              Services on behalf of your company (in the case
                              of colleges and companies), please contact us
                              directly for a quote on the cost of our
                              Services.
                            </p>
                          </li>
                          <li>
                            <p>
                              Invoices and Statements: You will receive an
                              invoice for any payments made which are also
                              available in your profile.
                            </p>
                          </li>
                          <li>
                            <p>
                              <u>Limitation of Use:</u> Your right to use our
                              Platform is subject to any limits we or your
                              credit card issuer may establish. If payment
                              cannot be charged to your payment card or a
                              charge is returned for any reason, including
                              chargeback, we reserve the right to either
                              suspend or terminate your account and access to
                              the Platform.
                            </p>
                          </li>
                        </ul>
                      </ul>

                      <ul>
                        <li>
                          <p>
                            <b>Responsibilities and Warranties</b>
                          </p>
                        </li>
                        <ul>
                          <li>
                            <p>
                              <u>Platform Warranties </u>
                            </p>
                          </li>
                          <li>
                            <p>
                              by using the Platform and/or the Services, you
                              warrant that -
                            </p>
                          </li>
                          <ul>
                            <li>
                              you have read and agreed to these Terms and will
                              use the Platform and Services in accordance with
                              them;
                            </li>
                            <li>
                              you have not made any misrepresentations and the
                              information provided in the registration process
                              about you, your company and/or your status is
                              true, accurate and complete in every aspect;
                            </li>
                            <li>
                              you are above the age of 18 (eighteen) years old
                              and have the legal capacity to understand, agree
                              with and be bound with these Terms;
                            </li>
                            <li>
                              if you are under the age of 18, you warrant that
                              you have the consent of your parent or guardian
                              to access the Platform and use the Services and
                              your parent/guardian warrants that they shall be
                              responsible for your actions and expenses on the
                              Platform;
                            </li>
                            <li>
                              you lawfully possess and submit all information
                              to Xylus for the use of the Platform and the
                              Services and hereby indemnify Xylus against any
                              third-party claims that may arise due to the
                              processing of the information shared by you with
                              Xylus;
                            </li>
                            <li>
                              where you use our Services on behalf of your
                              company or organisation, you warrant that you
                              have the authority and consent to sign-up to our
                              Platform and share personal information with us
                              and indemnify Xylus against any third-party
                              claims that may arise in respect the sharing of
                              personal information;
                            </li>
                            <li>
                              you will not post, upload, replicate or transmit
                              any abusive content on the Platform that is or
                              could reasonably be considered to be
                              threatening, harassing, defamatory, abusive,
                              racist, sexist, discriminatory, in breach of
                              confidence, in breach of privacy or restrict any
                              user in any way from properly using the
                              Platform;
                            </li>
                            <li>
                              you will not share any confidential information
                              on the Platform including employment or other
                              personal information of a user not made publicly
                              available in any profile;
                            </li>
                            <li>
                              you will not send any unsolicited electronic
                              messages or use any software, routine or device
                              to interfere or attempt to interfere
                              electronically or manually with the operation or
                              functionality of the Platform including but not
                              limited to uploading or making available files
                              containing corrupt data or viruses via whatever
                              means or deface, alter or interfere with the
                              front end ‘look and feel’ of the Platform or the
                              underlying software code;
                            </li>
                            <li>
                              you will not infringe the intellectual property
                              or other rights of any third party or the
                              Platform or transmit content that you do not own
                              or do not have the right to publish or
                              distribute;
                            </li>
                            <li>
                              you will not use the Platform for any commercial
                              purpose other than as expressly provided for by
                              Xylus herein;
                            </li>
                            <li>
                              you will not use the Platform to breach any
                              applicable law or regulation or perform or
                              encourage any illegal activity including
                              (without limitation) promoting or facilitating
                              money laundering or financial crimes; and/or
                            </li>
                            <li>
                              <p>
                                you will not facilitate or assist any third
                                party to do any of the above,
                              </p>
                            </li>
                          </ul>
                          <li>
                            <p>
                              {" "}
                              failing which, such action will automatically
                              and immediately be deemed to be a material
                              breach of these Terms, allowing Xylus to
                              manifest all of our rights in the case of
                              breach, including but not limited to denying you
                              access to the Platform/Services, reporting your
                              actions to an applicable authority or
                              instituting legal proceedings against you.
                            </p>
                          </li>
                          <li>
                            <p>
                              <u>Connected Devices:</u> The Platform is only
                              available on compatible devices connected to the
                              internet. It is your responsibility to obtain
                              these devices and any connectivity necessary to
                              use the Platform. We do not guarantee that the
                              Platform, or any portion thereof, will function
                              on any particular hardware or device.
                            </p>
                          </li>
                          <li>
                            <p>
                              <b>
                                <u>Access to Platform in Breach:</u> Without
                                prejudice to any of our other rights (whether
                                at law or otherwise), we reserve the right to
                                deny you access to the Platform where we
                                believe (in our sole reasonable discretion)
                                that you are in breach of any of these Terms.
                              </b>
                            </p>
                          </li>
                        </ul>
                      </ul>

                      <ul>
                        <li>
                          <p>
                            <b>Messages and Advertising</b>
                          </p>
                        </li>
                        <ul>
                          <li>
                            <p>Data Messages between You and Xylus</p>
                            <ul>
                              <li>
                                Data messages, including email messages, you
                                send to us will be considered as received only
                                when we acknowledge or responded to these
                                messages.
                              </li>
                              <li>
                                Data messages we send to you will be regarded
                                as received when the data message enters your
                                email sever inbox and is capable of being
                                retrieved and processed by you.
                              </li>
                              <li>
                                We reserve the right not to respond to any
                                email or other data message that contains
                                obscene, threatening, defamatory or otherwise
                                illegal, unlawful, or inappropriate content,
                                and to take appropriate action against the
                                sender of such email or data message if
                                necessary.
                              </li>
                              <li>
                                Messages sent over the internet cannot be
                                guaranteed to be completely secure as they can
                                be intercepted, lost, or corrupted. We are
                                therefore not responsible for the accuracy or
                                safety of any message sent by email or over
                                the internet.
                              </li>
                            </ul>
                          </li>
                          <li>
                            <p>
                              <u>Hyperlinks, Deep Links, Framing</u>
                            </p>
                            <ul>
                              <li>
                                The Platform may include links to other
                                websites ("<b>other sites</b>"). We do not own
                                or endorse these other sites and are not
                                responsible for the information, material,
                                products, or services contained on or
                                accessible through these other sites. Any
                                hyperlinks do not imply any endorsement,
                                agreement on or support of the content or
                                products of these other sites.
                              </li>
                              <li>
                                We do not own the content on any other site
                                which may be shown on the Platform. Should the
                                owner of any content showcased on the Platform
                                want the content to be removed, please contact
                                us to request the removal of such content.
                              </li>
                              <li>
                                <p>
                                  Your access and use of the other sites
                                  remain solely at your own risk and on the
                                  terms set by the operator of any other site.
                                </p>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </ul>
                      <ul>
                        <li>
                          <p>
                            <b>Intellectual Property</b>
                          </p>
                        </li>
                        <ul>
                          <li>
                            <p>
                              <u>Platform IP: </u>All website layout, website
                              content, material, illustrations, information,
                              data, software, icons, text, graphics, layouts,
                              images, sound clips, advertisements, video
                              clips, user interface design and layout, trade
                              names, logos, trademarks, designs, copyright
                              and/or service marks, together with the
                              underlying software code, (“the intellectual
                              property”) are owned (or co-owned or licenced,
                              as the case may be) by Xylus, our shareholders,
                              associates and/or partners, whether directly or
                              indirectly, and as such, are protected from
                              infringement by domestic and international
                              legislation and treaties.
                            </p>
                          </li>
                          <li>
                            <p>
                              <u>User submitted IP:</u> All rights to any
                              intellectual property you provide to us will
                              remain with you, but for which you have provided
                              us with a non-exclusive, non-transferable
                              licence to use such intellectual property to
                              provide you with our Services including the use
                              of our Platform.
                            </p>
                          </li>
                          <li>
                            <p>
                              <u>No Modification of IP: </u>Subject to the
                              rights afforded to you in these Terms, all other
                              rights to all intellectual property on the
                              Platform are expressly reserved.{" "}
                              <b>
                                You may not copy, download, print, modify,
                                alter, publish, broadcast, distribute, sell,
                                or transfer any intellectual property,
                                editorial content, graphics or other material
                                or the underlying software code whether in
                                whole or in part, without our written consent
                                first being granted, which consent may be
                                refused at our discretion. No modification of
                                any intellectual property or graphics is
                                permitted. Should you breach these provisions,
                                we and/or the rightful intellectual property
                                rights owner may launch legal proceedings
                                against you for a breach of contract,
                                resulting in a claim of damages against you.
                              </b>
                            </p>
                          </li>
                          <li>
                            <p>
                              <u>Updates:</u> We reserve the right to make
                              improvements or changes to the intellectual
                              property, information, videos, graphics, and
                              other materials on the Platform/Services, or to
                              suspend or terminate the Platform, at any time
                              without notice; provided that any transactions
                              or functions already concluded, will not be
                              affected by such suspension or termination (as
                              the case may be).
                            </p>
                          </li>
                          <li>
                            <p>
                              <u>Third Party IP:</u> Where any intellectual
                              property has been licensed to us or belongs to
                              any third party all rights of use will also be
                              subject to any terms and conditions which that
                              licensor or third party imposes from time to
                              time, and you agree to comply with such
                              third-party terms and conditions.
                            </p>
                          </li>
                          <li>
                            <p>
                              <b>User License:</b> Subject to adherence to the
                              Terms, we grant to you a personal,
                              non-exclusive, non-assignable and
                              non-transferable license to use and display all
                              content and information on any machine which you
                              are the primary user. However, nothing contained
                              on the Platform or in these Terms should be
                              construed as granting any licence or right to
                              use any intellectual property without our prior
                              written permission.
                            </p>
                          </li>
                        </ul>
                      </ul>
                      <ul>
                        <li>
                          <p>
                            <b>Indemnities and Disclaimers</b>
                          </p>
                        </li>
                        <ul>
                          <li>
                            <p>
                              <u>Disclaimers</u>
                            </p>
                          </li>
                          <ul>
                            <li>
                              <p>
                                The Platform and Services, including
                                intellectual property appearing therein, are
                                provided "as is" and "as available". We make
                                no representations or warranties, express or
                                implied, including but not limited to
                                warranties as to the accuracy, correctness, or
                                suitability of either the Platform or the
                                Services.
                              </p>
                            </li>
                            <li>
                              <p>
                                All content, information, and/or opinions of
                                users made available on the Platform in
                                relation to any of the Services are those of
                                the authors and not Xylus. While we make every
                                reasonable effort to present such information
                                accurately and reliably on the Platform we do
                                not endorse, approve, or certify such
                                information, nor guarantee the accuracy or
                                completeness of such information on the
                                Platform.
                              </p>
                            </li>
                            <li>
                              <p>
                                <b>
                                  Xylus, its shareholders, directors,
                                  employees, and partners, accept no liability
                                  whatsoever for any loss, whether direct or
                                  indirect, consequential, or arising from
                                  information made available on (or by means
                                  of) the Platform and/or transactions or
                                  actions resulting therefrom or from the
                                  Services offered.
                                </b>
                              </p>
                            </li>
                            <li>
                              <p>
                                <b>
                                  Xylus, its shareholders, directors,
                                  employees, partners, and affiliates, accept
                                  no liability whatsoever for any costs,
                                  expenses, fines, or damages, including but
                                  not limited to direct or indirect loss or
                                  damages, including any economic loss,
                                  consequential loss, loss of profits or any
                                  form of punitive damages, resulting from the
                                  facilitation and offering of the Services,
                                  and access to, or use of, the Platform in
                                  any manner.
                                </b>
                              </p>
                            </li>
                            <li>
                              <p>
                                We take reasonable security measures to ensure
                                the safety and integrity of the Platform and
                                to exclude viruses, unlawful monitoring and/or
                                access from the Platform offline. However, we
                                do not warrant or represent that your access
                                to the Platform will be uninterrupted or
                                error-free or that any information, data,
                                content, software, or other material
                                accessible through the Platform will be free
                                of bugs, viruses, worms, trojan horses or
                                other harmful components. Your access to and
                                use of the Platform remains solely at your own
                                risk, and you should take your own precautions
                                accordingly.
                              </p>
                            </li>
                            {/* <li><p></p></li> */}
                          </ul>
                          <ul>
                            <li>
                              <p>
                                <u>Indemnities</u>
                              </p>
                            </li>
                            <ul>
                              <li>
                                <p>
                                  <b>
                                    You indemnify and hold harmless Xylus, its
                                    shareholders, employees, and partners from
                                    any demand, action or application or other
                                    proceedings, including for attorneys’ fees
                                    and related costs such as tracing fees,
                                    made by any third party, and arising out
                                    of or in connection with your use of the
                                    Platform and/or Services offered or
                                    transactions concluded through the
                                    Platform in any way.
                                  </b>
                                </p>
                              </li>
                              <li>
                                <p>
                                  <b>
                                    You agree to indemnify, defend, and hold
                                    Xylus, its shareholders, employees and
                                    partners harmless from any direct or
                                    indirect liability, loss, claim and
                                    expense (including reasonable legal fees)
                                    related to the user’s breach of these
                                    Terms.
                                  </b>
                                </p>
                              </li>
                            </ul>
                          </ul>
                          <ul>
                            <li>
                              This clause will survive termination of these
                              Terms.
                            </li>
                          </ul>
                        </ul>
                      </ul>
                      <ul>
                        <li>
                          <p>
                            <b>Dispute Resolution </b>
                          </p>
                        </li>
                        <ul>
                          <li>
                            <p>
                              <b>Disputes between you and Xylus</b>
                            </p>
                          </li>
                          <ul>
                            <li>
                              <p>
                                <u>Negotiation</u> - Should any dispute,
                                disagreement or claim arise between you and
                                Xylus concerning the use of the Platform or
                                the Services, the parties shall endeavour to
                                resolve the dispute amicably, by negotiation,
                                and with the best interests of both parties in
                                mind.
                              </p>
                            </li>

                            <li>
                              <p>
                                <u>Mediation</u> - Should these parties fail
                                to resolve such dispute in the aforesaid
                                manner or within such further period as the
                                parties may agree to in their negotiation, the
                                parties will approach an independent industry
                                expert who shall mediate the discussions
                                between them to find a mutually beneficial
                                solution.
                              </p>
                            </li>

                            <li>
                              <p>
                                <b>Arbitration</b> - If the dispute is still
                                not resolved after such mediation, the parties
                                will commence and be party to binding and
                                confidential arbitration in terms of the
                                expedited rules of the Arbitration Foundation
                                of Southern Africa (“AFSA”), with an
                                arbitrator selected by Xylus.
                              </p>
                            </li>

                            <li>
                              <p>
                                <u>Jurisdiction</u> - Notwithstanding the
                                above, both parties' consent to the
                                jurisdiction of an appropriate South African
                                court. Either party may also always use the
                                dispute resolution services of any applicable
                                legislative tribunal or ombud, as provided for
                                in applicable legislation.
                              </p>
                            </li>

                            <li>
                              <p>
                                <u>No publication</u> - The parties both agree
                                that in no circumstance will either party
                                publicise the dispute on any social media or
                                other public platforms. The parties understand
                                that any publicity of this nature can cause
                                serious damage to the other party, which
                                damage may result in a financial claim against
                                the infringing party.
                              </p>
                            </li>
                          </ul>
                          <li>
                            <p>
                              <b>Disputes between Users</b>
                            </p>
                          </li>
                          <ul>
                            <li>
                              <p>
                                <u>Private Dispute: </u>Should a dispute arise
                                between users and their private transaction,
                                said dispute is between these parties
                                exclusively. Xylus is not responsible for
                                fulfilling any function in any way or engaging
                                in the dispute in any way.
                              </p>
                            </li>
                            <li>
                              <p>
                                <u>Beneficial Resolution: </u>The users agree
                                that they will resolve their dispute in a
                                manner that is mutually agreeable and/or as
                                prescribed by any relevant agreement concluded
                                between them, and for the attempted benefit of
                                both parties.
                              </p>
                            </li>
                            <li>
                              <p>
                                <u>Notification: </u>Notwithstanding the
                                above, these parties must inform Xylus of the
                                dispute for Xylus to log the issues
                                experienced, and to try assist both parties in
                                whatever way it deems fit but is under no
                                obligation to do so.
                              </p>
                            </li>
                          </ul>
                        </ul>
                      </ul>
                      <ul>
                        <li>
                          <p>
                            <b>Termination of Use </b>
                          </p>
                        </li>
                        <ul>
                          <li>
                            <p>
                              <b>
                                IN ADDITION TO OUR OTHER RIGHTS HEREIN, WE
                                RESERVE THE RIGHT TO RESTRICT AND/OR TERMINATE
                                YOUR USE OF OUR PLATFORM IF YOU BREACH ANY OF
                                THESE TERMS, OR{" "}
                                <u>
                                  {" "}
                                  FOR ANY OTHER REASON IN OUR SOLE DISCRETION{" "}
                                </u>
                                PROVIDED THAT WE GIVE REASONABLE NOTICE TO
                                YOU.
                              </b>
                            </p>
                          </li>
                          <li>
                            <p>
                              If you wish to terminate your agreement with us
                              and these Terms, you may do so by ending your
                              subscription and/or use of our Platform. Such
                              termination will however not have any effect on
                              the continued and comprehensive functioning or
                              legitimacy of any lawful rights which we may
                              have at the time of said termination.
                            </p>
                          </li>
                          <li>
                            <p>
                              In the event of termination of your agreement
                              with these Terms we will remove you from the
                              Platform and delete your profile.
                            </p>
                          </li>
                        </ul>
                      </ul>
                      <ul>
                        <li>
                          <p>
                            <b>Notices and Service Address</b>
                          </p>
                        </li>
                        <ul>
                          <li>
                            <p>
                              Each of the parties chooses their service
                              address for the purposes of the giving of any
                              notice, the serving of any process and for any
                              other purposes arising from these Terms as
                              being:
                            </p>
                            <ul>
                              <li>
                                <p>
                                  in the case of Xylus, at
                                  <a href="mailto:admin@xylus.com "> admin@xylus.com </a>
                                  or
                                </p>
                              </li>
                              <li>
                                <p>
                                  in the case of the user, at the e-mail,
                                  cellphone number, and/or address provided
                                  when registering with us.
                                </p>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <p>
                              Each of the parties will be entitled from time
                              to time, by written notice to the other to vary
                              its service address to any other address which
                              is not a post office box or poste restante,
                              provided that the change will become effective
                              only 14 (fourteen) days after service of the
                              notice in question.
                            </p>
                          </li>
                          <li>
                            <p>
                              Notwithstanding the above, any notice given in
                              writing in English, and actually received by the
                              party to whom the notice is addressed, will be
                              deemed to have been properly given and received,
                              notwithstanding that such notice has not been
                              given in accordance with this clause.
                            </p>
                          </li>
                        </ul>
                      </ul>

                      <ul>
                        <li>
                          <p>
                            <b>Company Information</b>
                          </p>
                        </li>
                        <ul>
                          <li>
                            <b className="me-5">Site owner:</b> Xylus (Pty)
                            Ltd
                          </li>
                          <li>
                            <b className="me-5">Legal status: </b> Private
                            Company
                          </li>
                          <li>
                            <b className="me-5">Registration number: </b>{" "}
                            2019/524679/07
                          </li>
                          <li>
                            <b className="me-5">Description of business:</b>
                            Software Provider
                          </li>
                          <li>
                            <b className="me-5">Platform address:</b>{" "}
                            www.xylus.com
                          </li>
                          <li>
                            <b className="me-5">Email address:</b>{" "}
                            admin@xylus.com
                          </li>
                          <li>
                            <b className="me-5">Telephone number: </b>{" "}
                            (+27) 067 470 9654
                          </li>
                          <li>
                            <b className="me-5">Registered address: </b> 3
                            Northmoor Avenue, Umhlanga, Durban, 4319
                          </li>
                          <li>
                            <b className="me-5">Postal address: </b> PO Box
                            991, Umhlanga, Durban, 4320
                          </li>
                        </ul>
                      </ul>
                      <ul>
                        <li>
                          <p>
                            <b>General </b>
                          </p>
                        </li>
                        <ul>
                          <li>
                            <u>Relationship Between the Parties</u> - The
                            relationship of the parties, inter se, shall be
                            governed by these Terms and nothing contained
                            herein shall be deemed to constitute a
                            partnership, joint venture, employer/employee
                            agreement, agency agreement, or the like between
                            them. No party shall by the reasons of the actions
                            of the other party incur any personal liability as
                            co-partner to any third party. Xylus only provides
                            software as a service, any formal engagement
                            between users facilitated by the Platform is
                            between them privately, and for which Xylus holds
                            no responsibility.
                          </li>
                          <li>
                            <u>Force Majeure</u> - If either party is
                            prevented, whether in whole or in part, or delayed
                            from performing any of its duties, functions or
                            obligations under these Terms, whether timeously
                            or at all, due to an event out of their control
                            (which for the purposes hereof shall mean war,
                            political riots, civil commotions, electrical
                            load-shedding or surges, legal prohibitions or
                            restrictions, epidemics, pandemics, governmental
                            lockdowns, fire, floods or other similar natural
                            disasters), then such failure shall not constitute
                            a breach under these Terms, and the obligation to
                            perform shall be suspended to the extent and
                            during the continuance of such prevention provided
                            that the parties shall use their reasonable
                            commercial endeavours to minimise any delay
                            occasioned
                          </li>
                          <li>
                            <u>Entire Agreement</u> - This document contains
                            the entire agreement between the parties in
                            relation to the subject matter hereof. Save as
                            contemplated in clause 13.3 above, no alteration,
                            cancellation, variation of, or addition hereto
                            will be of any force or effect unless reduced to
                            writing and signed by all the parties to these
                            Terms or their duly authorised representatives.
                          </li>
                          <li>
                            <u>No Indulgence</u> - No indulgence, leniency or
                            extension of time granted by Xylus shall
                            constitute a waiver of any of Xylus’ rights under
                            these Terms and, accordingly, Xylus shall not be
                            precluded as a consequence of having granted such
                            indulgence, from exercising any rights against the
                            client which may have arisen in the past or which
                            might arise in the future.
                          </li>
                          <li>
                            <u>Importation of Words</u> - Words importing the
                            singular will include the plural and vice versa.
                            Words importing one gender will include the other
                            genders, and words importing persons will include
                            partnerships, trusts, and bodies corporate, and
                            vice versa.
                          </li>
                          <li>
                            <u>Headings as Reference </u>- The headings to the
                            paragraphs in these Terms are inserted for
                            reference purposes only and will not affect the
                            interpretation of any of the provisions to which
                            they relate.
                          </li>
                          <li>
                            <u>Governing Law</u> - Your access and/or use of
                            the Platform and/or the Services, any downloaded
                            material from it and the operation of these Terms
                            (including any transaction concluded pursuant
                            thereto) shall be governed by and construed in
                            accordance with the laws of the Republic of South
                            Africa.
                          </li>
                          <li>
                            <u>Failure to Pay</u> - In the event of a client
                            failing to pay any amount timeously or breaching
                            these Terms, the client shall be liable for all
                            legal costs (on the scale as between attorney and
                            client) (including collection commission) which
                            may be incurred by Xylus in relation to the
                            payment failure or breach.
                          </li>
                          <li>
                            <u>Severability </u>- Each sentence, paragraph,
                            term, clause and provision of these Terms and any
                            portion thereof shall be considered severable and
                            if for any reason, any such sentence, paragraph,
                            term, clause or provision is held to be invalid,
                            contrary to, or in conflict with any applicable
                            present or future law or regulation or in terms of
                            a final, binding judgment issued by any court, it
                            shall to that extent be deemed not to form part
                            hereof and shall not impair the operation of, or
                            have any effect upon such other sentence,
                            paragraph, term, clause or provision hereof as may
                            otherwise remain valid or intelligible, which
                            shall continue to be given full force and effect
                            and bind the parties hereto.
                          </li>
                          <li>
                            <u>Prohibited Provision </u>- No term or condition
                            of these Terms is intended to breach any
                            peremptory provisions of any consumer protection
                            legislation and any regulations thereto
                            ("Prohibited Provision"). Any breach of any such
                            Prohibited Provision shall be governed by the
                            provisions of clause 13.10.
                          </li>
                        </ul>
                      </ul>

                      {/* <button className="readmore-btn">Read more</button> */}
                    </div>
                  </div>
                </div>
                {/* <!-- password tab --> */}
                <div
                  className={
                    window.location.href.split("/")[3].toString() == "privacy"
                      ? "tab-pane fade active show"
                      : `tab-pane fade `
                  }
                  id="privacy-block"
                  role="tabpanel"
                  aria-labelledby="privacy-policy"
                >
                  <div className="block">
                    <div className="terms-text">
                      <p>
                        At Xylus (Pty) Ltd (2019/524679/07) <b>(“Xylus”)</b>{" "}
                        we adhere to the highest standards of protecting your
                        personal information when we process it by virtue of
                        your use of our Services and Platform,{" "}
                        <a href="www.xylus.com">www.xylus.com </a>and any
                        related platforms (collectively, “<b>the Platform</b>
                        ”), or by providing us with your personal information
                        in any other way. As such, we have created this
                        privacy policy for you to read and to understand how
                        we safeguard your personal information and respect
                        your privacy (“
                        <b>Privacy Policy</b>”).{" "}
                      </p>
                      <p>
                        Please note that Xylus is a private company duly
                        registered and operating in accordance with the laws
                        of the Republic of South Africa. Not all terms are
                        necessarily defined in order or may be defined in our
                        Terms and Conditions of Use (“<b>Terms</b>”).
                      </p>
                      Please ensure that you read all the provisions below,
                      and our policies and guidelines which may apply from
                      time to time, to understand all of your, and our, rights
                      and duties.
                      <ul>
                        <li>
                          {" "}
                          <b> Important Information and Who We Are</b>
                        </li>
                        <ul>
                          <li>
                            {" "}
                            <u> Purpose of this Privacy Policy</u>
                          </li>
                          <ul>
                            <li>
                              This Privacy Policy aims to give you information
                              on how we collect and process your personal
                              information through any form of your engagement
                              with us. This Privacy Policy complies with, and
                              facilitates the obligations required from, the
                              South African Protection of Personal Information
                              Act, No. 4 of 2013 (“<b>POPI</b>”), as amended.{" "}
                            </li>
                            <li>
                              It is important that you read this Privacy
                              Policy together with any other privacy policy or
                              fair processing notice we may provide on
                              specific occasions when we are collecting or
                              processing personal information about you, so
                              that you are fully aware of how and why we are
                              using your personal information. This Privacy
                              Policy supplements the other notices and is not
                              intended to override them.
                            </li>
                          </ul>
                        </ul>
                      </ul>
                      <p>
                        Due to the nature of our operations we may process
                        certain special categories of personal information. We
                        process this information to provide our services and
                        to ensure our platform users receive the best possible
                        service.{" "}
                        <b>
                          {" "}
                          By accepting this Privacy Policy, you, as a
                          parent/guardian expressly and informedly consent to
                          our processing of the personal information of your
                          minor child where we will always store and process
                          any minor personal information at a higher standard
                          than conventional personal information.
                        </b>
                      </p>
                      <ul>
                        <li>
                          {" "}
                          <u> Responsible Party and Operator</u>
                        </li>
                        <ul>
                          <li>
                            We are the “Responsible Party” and is responsible
                            for your personal information when we decide its
                            processing operations. In certain instances we may
                            operate as an “Operator” of personal information
                            on behalf of a third party.
                          </li>
                          <li>
                            <p>
                              We have appointed an information officer at
                              Xylus who is responsible for overseeing
                              questions in relation to this Privacy Policy. If
                              you have any questions about this Privacy
                              Policy, including any requests to exercise your
                              legal rights, please contact our information
                              officer using the details set out below.
                            </p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            {" "}
                            <u> Our Contact Details</u>
                          </li>
                          <ul>
                            <li>Our full details are:</li>
                            <ul>
                              <li>
                                {" "}
                                <b> Full name:</b> Xylus (Pty) Ltd
                              </li>
                              <li>
                                {" "}
                                <b> Information Officer:</b> Ciaran Ramdeo
                              </li>
                              <li>
                                {" "}
                                <b> Email address:</b>  admin@xylus.com
                              </li>
                              <li>
                                <b> Postal address:</b> PO Box 991, Umhlanga,
                                Durban, 4320
                              </li>
                              <li>
                                <p>
                                  {" "}
                                  <b> Telephone number:</b> (+27) 067 470 9654
                                </p>
                              </li>
                            </ul>
                            <li>
                              You have the right to make a complaint at any
                              time to the South African regulator’s office (
                              <u>
                                {" "}
                                Information Regulator’s Office of South Africa
                              </u>
                              ). We would, however, appreciate the chance to
                              deal with your concerns before you approach any
                              such regulator, so please contact us in the
                              first instance.
                            </li>
                          </ul>
                          <li>
                            {" "}
                            <u> Changes to this Privacy Policy</u>
                          </li>
                          <ul>
                            <li>
                              This Privacy Policy was last updated on 30
                              January 2024 and previous versions are archived
                              and can be provided on request.
                            </li>
                            <li>
                              It is important that the personal information we
                              hold about you is accurate and current. Please
                              keep us informed if your personal information
                              changes during your relationship with us.
                            </li>
                            <li>
                              This Privacy Policy is subject to change without
                              notice and is updated or amended from time to
                              time and will be effective once we upload the
                              amended version to the Platform. Your continued
                              access or use of our Services constitutes your
                              acceptance of this Privacy Policy, as amended.
                              It is your responsibility to read this document
                              periodically to ensure you are aware of any
                              changes.
                            </li>
                          </ul>
                          <li>
                            <u> Third-Party Links on Platform </u>
                            <div className="ml-4">
                              The Platform may include links to third-party
                              platforms, plug-ins, and applications. Clicking
                              on those links or enabling those connections may
                              allow third parties to collect or share
                              information about you. We do not control these
                              third-party platforms and are not responsible
                              for their privacy statements or terms. When you
                              leave our Platform, or engage with such third
                              parties, we encourage you to read the distinct
                              privacy policy of every third-party you engage
                              with.
                            </div>
                          </li>
                        </ul>
                      </ul>
                      <ul>
                        <li>
                          {" "}
                          <b> What We Collect About You</b>
                        </li>
                        <ul>
                          <li>
                            Personal information, or personally identifiable
                            information, means any information about an
                            individual, both natural and juristic entities
                            (i.e., people and companies), from which that
                            entity can be identified. It does not include
                            information where the identity has been removed
                            (anonymous data).
                          </li>
                          <li>
                            We may collect, use, store, and transfer (“
                            <b> process </b>”) different kinds of personal
                            information about you which we have grouped
                            together as follows:
                            <ul>
                              <li>
                                <p>
                                  <b> Identity Data</b> including full name,
                                  age, or the information about your company
                                  such as company name, address and/or company
                                  registration details;
                                </p>
                              </li>
                              <li>
                                <p>
                                  <b> Contact Data</b> including email
                                  address, physical/registered addresses, and
                                  contact phone numbers;
                                </p>
                              </li>
                              <li>
                                <p>
                                  <b> Minor Data </b> including full name,
                                  identity number, email address,
                                  physical/registered addresses, contact phone
                                  numbers and all associated data as listed
                                  below;{" "}
                                </p>
                              </li>
                              <li>
                                <p>
                                  <b> Profile Data </b> including all the data
                                  and information available in your Xylus
                                  profile such as your employment history,
                                  educational qualifications and payment
                                  history;{" "}
                                </p>
                              </li>
                              <li>
                                <p>
                                  <b> Social Media Data </b> including all the
                                  data available on your publicly available
                                  social media profile;{" "}
                                </p>
                              </li>
                              <li>
                                <p>
                                  <b> Financial Data</b> including a salary
                                  information, bank account details and
                                  third-party payment provider information and
                                  payment card details (which we do not
                                  process but is processed by a payment
                                  service provider on our instruction);
                                </p>
                              </li>
                              <li>
                                <p>
                                  <b> Transaction Data</b> including details
                                  about payments to and from you, contracts,
                                  contractual terms, contract fees, signups,
                                  subscriptions, invoices and other details of
                                  products and services you have obtained from
                                  us, or provide to us;
                                </p>
                              </li>
                              <li>
                                <p>
                                  <b> Technical</b> Data including internet
                                  protocol address/es, login data, browser
                                  type and version, time zone setting and
                                  location, cookies, browser plug-in types and
                                  versions, operating system and platform and
                                  other technology on the devices you use to
                                  access the Platform;
                                </p>
                              </li>
                              <li>
                                <p>
                                  <b> Usage Data</b> including information
                                  about how you use our company, Platform,
                                  surveys, and Services; and
                                </p>
                              </li>
                              <li>
                                <p>
                                  <b> Marketing and Communications Data</b>
                                  including your preferences in receiving
                                  notices and marketing from us and our third
                                  parties and your communication preferences
                                  as well as details of which communications
                                  were sent to you and how they were sent.
                                </p>
                              </li>
                              <li>
                                <p>
                                  <b> Support Data</b> including all messages
                                  you send to us via email and through the
                                  Platform.
                                </p>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </ul>
                      <p>
                        We also collect, use, and share{" "}
                        <b> Aggregated Data</b> such as statistical or
                        demographic data for any purpose. Aggregated Data may
                        be derived from your personal information but is not
                        considered personal information in law as this data
                        does <b> not</b> directly or indirectly reveal your
                        identity. For example, we may aggregate your Usage
                        Data to calculate the percentage of users accessing a
                        specific Platform feature. However, if we combine or
                        connect Aggregated Data with your personal information
                        so that it can directly or indirectly identify you, we
                        treat the combined data as personal information which
                        will be used in accordance with this Privacy Policy.
                      </p>
                      <p>
                        Where we need to collect personal information by law,
                        or under the terms of a contract we have with you and
                        you fail to provide that data when requested, we may
                        not be able to perform in terms of the contract we
                        have or are trying to enter into with you (for
                        example, to provide you with services or allow you to
                        provide us with your services). In this case, we may
                        have to cancel Platform-access or Services you have
                        with us, but we will notify you if this is the case at
                        the time.
                      </p>
                      <ul>
                        <li>
                          <b> How Is Your Personal Information Collected </b>{" "}
                        </li>
                        <ul>
                          <li>
                            We use different methods to collect personal
                            information from and about you, including through:
                            <ul>
                              <li>
                                <b> Direct interactions:</b> You may give us
                                your personal information by signing up to our
                                website or by corresponding with us through
                                the Platform, by phone, email or otherwise.
                                This includes personal information you provide
                                when you:
                              </li>
                              <ul>
                                <li>use our Services;</li>
                                <li>use our Platform;</li>
                                <li>contract with us;</li>
                                <li>
                                  provide any services to us as a service
                                  provider or independent contractor on
                                  contract with us;
                                </li>
                                <li>
                                  request information to be sent to you;
                                </li>
                                <li>give us some feedback.</li>
                              </ul>
                            </ul>
                            <ul>
                              <li>
                                <b> Automated technologies </b> or
                                interactions:As you interact with our
                                Platform, we may automatically collect
                                Technical Data and Usage Data about your
                                equipment, browsing actions and patterns. We
                                may collect this personal information by using
                                cookies, server logs and other similar
                                technologies. We may also receive Technical
                                Data about you if you visit other Platforms
                                employing our cookies.
                              </li>
                            </ul>
                            <ul>
                              <li>
                                <b> Third parties: </b> We may receive
                                personal information about you from various
                                third parties such as:
                              </li>
                              <ul>
                                <li>analytics providers; </li>
                                <li>tertiary education institutions; </li>
                                <li>marketing platforms; and</li>
                                <li>search information providers. </li>
                              </ul>
                            </ul>
                          </li>
                        </ul>
                      </ul>
                      <ul>
                        <li>
                          <b> How We Use Your Personal Information </b>
                        </li>
                        <ul>
                          <li>
                            We will only use your personal information when
                            the law allows us to and for legitimate reasons,
                            which you hereby expressly understand and consent
                            to. Most commonly, we will use your personal
                            information in the following circumstances:
                            <ul>
                              <li>
                                where we have your <b> express consent </b> to
                                do so;
                              </li>
                              <li>
                                where we need to consult with you or{" "}
                                <b> perform on the Services contract</b> we
                                are about to enter into or have entered into
                                with you;
                              </li>
                              <li>
                                where it is necessary for our{" "}
                                <b> legitimate business interests </b> (or
                                those of a third party) and your interests and
                                fundamental rights do not override those
                                interests; and/or
                              </li>
                              <li>
                                where we need to comply with a{" "}
                                <b> legal or regulatory obligation.</b>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </ul>
                      <ul>
                        <li>
                          <b>
                            {" "}
                            Purposes For Which We Will Use Your Personal
                            Information
                          </b>
                        </li>
                        <ul>
                          <li>
                            We have set out below, in a table format, a
                            description of
                            <ul>
                              <li>
                                the purpose for which we will process your
                                personal information,
                              </li>
                              <li>the type of data we process; and</li>
                              <li>the lawful basis of processing</li>
                            </ul>
                          </li>
                          <li>
                            Note that we may process your personal information
                            for more than one lawful ground depending on the
                            specific purpose for which we are using your
                            personal information.
                          </li>
                        </ul>
                        <div className="table-responsive">
                          <table className="privacy-table">
                            <tr>
                              <th>PURPOSE / ACTIVITY</th>
                              <th>TYPE OF DATA</th>
                              <th>LAWFUL BASIS AND BUSINESS INTEREST</th>
                            </tr>
                            <tr>
                              <td>
                                To engage with you after you have contacted us
                                via the Platform or otherwise
                              </td>
                              <td>
                                <ul>
                                  <li>Identity</li>
                                  <li>Contact</li>
                                  <li>Minor</li>
                                  <ul>
                                    <li>Financial</li>
                                  </ul>
                                  <li>Technical</li>
                                  <li>Usage</li>
                                  <li>Marketing and Communications</li>
                                  <li>Support</li>
                                </ul>
                              </td>
                              <td>
                                <ul>
                                  <li>Express consent</li>
                                  <li>Performance of a contract with you</li>
                                  <li>
                                    Necessary for our legitimate interests (keep
                                    records updated, study how users use our
                                    services, develop services, grow)
                                  </li>
                                </ul>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                To allow you to use our Services and to provide
                                you with our Services as contracted
                              </td>
                              <td>
                                <ul>
                                  <li>Identity</li>
                                  <li>Contact</li>
                                  <li>Minor</li>
                                  <li>Profile</li>
                                  <li>Social Media</li>
                                  <li>Financial</li>
                                  <li>Usage</li>
                                  <li>Transaction</li>
                                  <li>Support</li>
                                </ul>
                              </td>
                              <td>
                                <ul>
                                  <li>Performance of a contract with you</li>
                                  <li>Express consent </li>
                                  <li>
                                    Necessary to comply with a legal obligation
                                  </li>
                                  <li>
                                    Necessary for our legitimate interests
                                    (provide you with Services you contracted to
                                    acquire, keep records updated, study how
                                    users use Services)
                                  </li>
                                </ul>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                To contract with you as a service provider to
                                Xylus
                              </td>
                              <td>
                                <ul>
                                  <li>Identity</li>
                                  <li>Contact</li>
                                  <li>Financial</li>
                                  <li>Transaction</li>
                                </ul>
                              </td>
                              <td>
                                <ul>
                                  <li>Performance of a contract with you</li>
                                  <li>Express consent </li>
                                  <li>
                                    Necessary to comply with a legal obligation
                                  </li>
                                  <li>
                                    Necessary for our legitimate interests
                                    (provide us with your services, keep our
                                    records updated, and study how we may use
                                    third-party services)
                                  </li>
                                </ul>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                To provide it to our authorised service
                                providers who need your personal information to
                                provide their services to you{" "}
                              </td>
                              <td>
                                <ul>
                                  <li>Identity</li>
                                  <li>Contact</li>
                                  <li>Minor</li>
                                  <li>Financial</li>
                                  <li>Transaction</li>
                                  <li>Support</li>
                                </ul>
                              </td>
                              <td>
                                <ul>
                                  <li>Performance of a contract with you</li>
                                  <li>
                                    Necessary for our legitimate interests
                                    (provide you with Services you contracted
                                    from the third-party, develop our services,
                                    and grow)
                                  </li>
                                  <li>Express consent </li>
                                </ul>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                To process and service your payment for any
                                services rendered by Xylus or its service
                                providers.
                                <p></p>
                                To manage payments, fees, and charges
                              </td>
                              <td>
                                <ul>
                                  <li>Identity</li>
                                  <li>Contact</li>
                                  <li>Minor</li>
                                  <li>Financial</li>
                                  <li>Transaction</li>
                                </ul>
                              </td>
                              <td>
                                <ul>
                                  <li>Performance of a contract with you</li>
                                  <li>
                                    Necessary for our legitimate interests (make
                                    or receive necessary payments)
                                  </li>
                                  <li>Express consent </li>
                                </ul>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                To manage our relationship with you which may
                                include notifying you about changes to our
                                Terms, Privacy Policy, or Services
                              </td>
                              <td>
                                <ul>
                                  <li>Identity</li>
                                  <li>Contact</li>
                                  <li>Minor</li>
                                  <li>Social Media</li>
                                  <li>Marketing and Communications</li>
                                  <li>Support</li>
                                </ul>
                              </td>
                              <td>
                                <ul>
                                  <li>Performance of a contract with you</li>
                                  <li>
                                    Necessary to comply with a legal obligation
                                  </li>
                                  <li>
                                    Necessary for our legitimate interests{" "}
                                  </li>
                                  <li>Express consent </li>
                                </ul>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                To administer and protect our company, Platform
                                and services (including troubleshooting, data
                                analysis, testing, system maintenance, support,
                                reporting and hosting of data)
                              </td>
                              <td>
                                <ul>
                                  <li>Identity</li>
                                  <li>Contact</li>
                                  <li>Minor</li>
                                  <li>Technical</li>
                                  <li>Usage</li>
                                  <li>Support</li>
                                </ul>
                              </td>
                              <td>
                                <ul>
                                  <li>
                                    Necessary for our legitimate interests
                                    (running our organisation, provision of
                                    administration and IT, network security, to
                                    prevent fraud and organisation restructuring
                                    exercise)
                                  </li>
                                  <li>
                                    Necessary to comply with a legal obligation
                                  </li>
                                  <li>Express consent </li>
                                </ul>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                To use data analytics to improve our Platform,
                                Services, and client relationships and
                                experiences
                              </td>
                              <td>
                                <ul>
                                  <li>Technical</li>
                                  <li>Usage</li>
                                  <li>Marketing and Communications</li>
                                  <li>Support</li>
                                </ul>
                              </td>
                              <td>
                                <ul>
                                  <li>
                                    Necessary for our legitimate interests
                                    (define types of users, keep Platform
                                    updated, develop our organisation and inform
                                    our marketing strategy)
                                  </li>
                                  <li>Express consent </li>
                                </ul>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                To provide you with direct marketing, make
                                suggestions about services that may be of
                                interest
                              </td>
                              <td>
                                <ul>
                                  <li>Identity</li>
                                  <li>Contact</li>
                                  <li>Minor</li>
                                  <li>Social Media</li>
                                  <li>Technical</li>
                                  <li>Usage</li>
                                  <li>Marketing and Communications</li>
                                  <li>Support</li>
                                </ul>
                              </td>
                              <td>
                                <ul>
                                  <li>
                                    Necessary for our legitimate interests
                                    (develop our services and grow)
                                  </li>
                                  <li>Express consent </li>
                                </ul>
                              </td>
                            </tr>
                          </table>
                        </div>
                        <ul>
                          <ul>
                            <li>
                              <u>

                                Marketing
                              </u>
                              <ul>
                                <li>

                                  We strive to provide you with choices regarding certain personal information uses, particularly around marketing and advertising. To manifest your rights attached to any marketing sent to you as an existing user, please use the in-built prompts provided on those communications, or contact us.
                                </li>
                                <li>

                                  You will receive marketing communications from us if you have requested our Services, requested information from us, or provided us with your details in any other circumstance and, in each case, have not opted-out of receiving that marketing.
                                </li>
                                <li>
                                  You can ask us to stop sending you marketing messages at any time by using the built-in prompts or contacting us and requesting us to cease or change your marketing preferences. Where you opt-out of receiving these marketing messages, this opt-out will not apply to other personal information of yours which we process for another lawful basis or necessary communications we send when you use our Services.

                                </li>

                              </ul>
                            </li>
                            <li>
                              <u>

                                Third-Party Marketing
                              </u>
                              <div className="ms-4">

                                Whilst we may use your personal information within our company, we will get your express opt-in consent before we share your personal information publicly with any entity outside of Xylus for marketing.
                              </div>
                            </li>
                            <li>
                              <u>

                                Change of Purpose
                              </u>
                              <ul>
                                <li>

                                  We will only use your personal information for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us.
                                </li>
                                <li>

                                  If we need to use your personal information for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.
                                </li>
                                <li>

                                  Please note that we may process your personal information without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </ul>
                        <ul>
                          <li>
                            <b>
                              Disclosures Of Your Personal Information

                            </b>
                            <ul>
                              <li>
                                We may have to share your personal information with the parties set out below for the purposes set out in the table above.

                                <ul>
                                  <li>

                                    Internal Third Parties as set out in the <b>
                                      Glossary</b>;
                                  </li>
                                  <li>

                                    External Third Parties as set out in the <b>
                                      Glossary;
                                    </b>
                                  </li>
                                  <li>

                                    Third parties to whom we may choose to sell, transfer, or merge parts of our company or our assets. Alternatively, we may seek to acquire other organisations or merge with them. If a change happens to our company, we may continue to use your personal information in the same way as set out in this Privacy Policy.
                                  </li>
                                </ul>
                              </li>
                              <li>

                                We require all third parties to respect the security of your personal information and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal information for their own purposes and only permit them to process your personal information in accordance with our instructions and standards.
                              </li>
                            </ul>
                          </li>
                          <li>

                            <b>
                              Cookies
                            </b>
                            <ul>
                              <li>

                                The Platform may make use of “cookies” to automatically collect information and data through the standard operation of the Internet servers. “Cookies” are small text files a platform can use (and which we may use) to recognise repeat users, facilitate the user’s on-going access to and use of a platform and allow a platform to track usage behaviour and compile aggregate data that will allow the platform operator to improve the functionality of the platform and its content, and to display more focused advertising to a user by way of third party tools.
                              </li>
                              <li>

                                The type of information collected by cookies is not used to personally identify you. If you do not want information collected using cookies, there is a simple procedure in most browsers that allows you to deny or accept the cookie feature. Please note that cookies may be necessary to provide you with certain features available on our Platform and thus if you disable the cookies on your browser you may not be able to use those features, and your access to our Platform will therefore be limited. If you do not disable “cookies”, you are deemed to consent to our use of any personal information collected using those cookies, subject to the provisions of this Privacy Policy and our other policies or terms.
                              </li>
                            </ul>
                          </li>
                          <li>
                            <b>

                              International Transfers
                            </b>
                            <ul>
                              <li>

                                We may share and process your personal information outside of South Africa for the purpose of cloud storage or to engage with third parties such as website developers.
                              </li>
                              <li>

                                Whenever we may transfer your personal information out of South Africa, we will ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented:
                                <ul>
                                  <li>

                                    We will only transfer your personal information to countries that have appropriate data protection legislation in place similar to that of South Africa; and/or
                                  </li>
                                  <li>
                                    Where we use service providers, we may use specific contracts/clauses which ensure personal information is processed and secured lawfully.

                                  </li>
                                </ul>
                              </li>
                              <li>
                                Please contact us if you want further information regarding transfer of personal information out of South Africa.

                              </li>
                            </ul>
                          </li>
                          <li>
                            <b>

                              Data Security
                            </b>
                            <ul>
                              <li>

                                We have put in place appropriate security measures to prevent your personal information from being accidentally lost, used, or accessed in an unauthorised way, altered, or disclosed by [insert a short sentence on your security measures]. We also limit access to your personal information to those employees, agents, contractors and other third parties who have a legitimate need to know. They will only process your personal information on our instruction and are subject to a duty of confidentiality.
                              </li>
                              <li>

                                We have put in place procedures to deal with any suspected personal information breach and will notify you and the Information Regulator of a breach where we are legally required to do so.
                              </li>

                            </ul>
                          </li>
                          <li>
                            <b>

                              Data Retention
                            </b>
                            <ul>
                              <li>

                                We will only retain your personal information for as long as necessary to fulfil the purpose we collected it for including any legal, accounting, or reporting requirements.
                              </li>
                              <li>

                                To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorised use or disclosure of your personal information, the purpose for which we process your personal information, any other South African applicable law requiring us to retain the personal information and whether we can achieve those purposes through other means, and the applicable legal requirements.
                              </li>
                              <li>

                                We may also anonymise your personal information (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.
                              </li>
                            </ul>
                          </li>
                          <li>
                            <b>

                              Your Legal Rights
                            </b>
                            <ul>
                              <li>

                                You have rights in relation to your personal information where we are the relevant “Responsible Party” over such personal information. Please contact us to find out more about, or manifest, these rights:
                                <ul>
                                  <li>

                                    request access to your personal information;
                                  </li>
                                  <li>

                                    request correction of your personal information;
                                  </li>
                                  <li>
                                    request erasure of your personal information;

                                  </li>
                                  <li>

                                    object to the processing of your personal information;
                                  </li>
                                  <li>

                                    request a restriction of processing your personal information;
                                  </li>
                                  <li>

                                    request transfer of your personal information; and/or
                                  </li>
                                  <li>

                                    right to withdraw consent.
                                  </li>
                                </ul>
                              </li>
                              <li>

                                You will not have to pay a fee to access your personal information (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive, or excessive. Alternatively, we may refuse to comply with your request in these circumstances.
                              </li>
                              <li>
                                We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal information (or to exercise any of your other rights). This is a security measure to ensure that personal information is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.

                              </li>
                              <li>
                                We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.

                              </li>
                              <li>

                                Users with citizenships from jurisdictions other than South Africa, please note that we comply with South African data protection laws when processing your personal information as we are a South African entity. Should foreign law be applicable to your use of the Services and/or the Platform in any way, including how we may process your personal information, please contact us and we will gladly engage with you on your rights.
                              </li>
                            </ul>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <b>
                              Glossary
                            </b>
                            <ul>
                              <li>
                                <u>

                                  Lawful Basis
                                </u>
                                <ul>
                                  <li>

                                    <b>
                                      Legitimate Interest </b>means the interest of our organisation in conducting and managing our organisation to enable us to give you the best service and the most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal information for our legitimate interests. We do not use your personal information for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us.
                                  </li>
                                  <li>

                                    <b>
                                      Performance of Contract</b> means processing your personal information where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.
                                  </li>
                                  <li>

                                    <b>Comply with a legal or regulatory obligation </b>means processing your personal information where it is necessary for compliance with a legal or regulatory obligation that we are subject to.
                                  </li>
                                  <li>

                                    <b>Express consent </b>means the confirmed express consent you have provided to our processing of your personal information by actively accepting this Privacy Policy.
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <u> Third Parties</u>
                                <ul>
                                  <li>
                                    <b>
                                      Internal Third Parties </b>means other entities or parties in the Xylus group acting as joint responsible parties or operators.
                                  </li>
                                  <li>

                                    <b>
                                      External Third Parties </b>means:
                                    <ul>
                                      <li>

                                        Authorised third-party service providers under contract with Xylus who need your personal information in order to contact and transact with you pursuant to your use of the Services;

                                      </li>
                                      <li>

                                        service providers acting as operators;
                                      </li>
                                      <li>
                                        South African or other national governments and/or their respective authorities pursuant to our adherence with anti-corruption and crime-fighting legislation; and/or

                                      </li>
                                      <li>

                                        professional advisers acting as operators or joint responsible parties including lawyers, bankers, auditors and insurers based in South Africa who provide consultancy, banking, legal, insurance and accounting services as required.
                                      </li>
                                    </ul>
                                  </li>
                                </ul>

                              </li>
                              <li>
                                <u>

                                  Your Legal Rights
                                </u>

                                You have the right to:
                                <ul>
                                  <li>
                                    <b>
                                      Request access </b>to your personal information (commonly known as a “<b>
                                      data subject access request</b>”). This enables you to receive a copy of the personal information we hold about you and to check that we are lawfully processing it.

                                  </li>
                                  <li>
                                    <b>
                                      Request correction</b> of the personal information that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.

                                  </li>
                                  <li>


                                    <b>
                                      Request erasure</b> of your personal information. This enables you to ask us to delete or remove personal information where there is no valid reason for us continuing to process it. You also have the right to ask us to delete or remove your personal information where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal information to comply with local law. <b>
                                      Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be communicated to you, if applicable, at the time of your request.
                                    </b>
                                  </li>
                                  <li>

                                    <b>
                                      Object to processing</b> of your personal information where we are relying on a legitimate interest (or those of a third party) and there is something about your situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal information for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.

                                  </li>
                                  <li>
                                    <b>
                                      Request restriction of processing</b> of your personal information. This enables you to ask us to suspend the processing of your personal information in the following scenarios:
                                    <ul>
                                      <li>
                                        if you want us to establish the data’s accuracy;

                                      </li>
                                      <li>
                                        where our use of the data is unlawful but you do not want us to erase it;

                                      </li>
                                      <li>

                                        where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or
                                        you have objected to our use of your data, but we need to verify whether we have overriding legitimate grounds to use it.
                                      </li>
                                    </ul>
                                  </li>
                                  <li>

                                    <b>
                                      Request the transfer</b> of your personal information to you or to a third party. We will provide to you, or a third party you have chosen, your personal information in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform on a contract with you.
                                  </li>
                                  <li>

                                    <b>
                                      Withdraw consent at any time</b> where we are relying on consent to process your personal information. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain Platform access or Services to you. We will advise you if this is the case at the time you withdraw your consent. <b>
                                      Please take note that regardless of your right to withdraw consent under POPI, other South African legislation applies and may require that we continue to process your data to comply with anti-corruption, crime-fighting and/or other national legislation, which you expressly understand and agree to.
                                    </b>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </ul>


                      {/* <button className="readmore-btn">Read more</button> */}

                    </div>
                  </div>

                </div>
                {/**Paia form */}
                <div className={window.location.href.split('/')[3].toString() == "paia" ? "tab-pane fade active show" : `tab-pane fade `} id="paia-block" role="tabpanel" aria-labelledby="terms-of-use">
                  <div className="block" >
                    <div className="paia-block" >
                      <ul className="forms-list">
                        <li className="list-item">
                          <div className="form-name">

                            <span className="icon">
                              <img src="./images/fluent-form1.svg"></img>
                            </span>
                            <a href="/pdf/PAIA-Guide-English.pdf" download={"PAIA-Guide-English.pdf"} >PAIA-Guide-English.pdf</a>
                          </div>
                          <span className="icon">
                            <a href="/pdf/PAIA-Guide-English.pdf" download={"PAIA-Guide-English.pdf"} > <img src="./images/cloud-download.svg"></img></a>
                          </span>
                        </li>
                        <li className="list-item">
                          <div className="form-name">
                            <span className="icon">
                              <img src="./images/fluent-form1.svg"></img>
                            </span>
                            <a href="/pdf/PAIAForm2.pdf" download={"PAIAForm2.pdf"} >PAIA-Form-2.pdf</a>
                          </div>
                          <span className="icon">
                            <a href="/pdf/PAIAForm2.pdf" download={"PAIAForm2.pdf"} > <img src="./images/cloud-download.svg"></img></a>
                          </span>
                        </li>
                        <li className="list-item">
                          <div className="form-name">

                            <span className="icon">
                              <img src="./images/fluent-form1.svg"></img>
                            </span>
                            <a href="/pdf/PAIAForm3.pdf" download={"PAIAForm3.pdf"} >PAIA-Form-3.pdf</a>
                          </div>
                          <span className="icon">
                            <a href="/pdf/PAIAForm3.pdf" download={"PAIAForm3.pdf"} > <img src="./images/cloud-download.svg"></img></a>
                          </span>
                        </li>
                        <li className="list-item">
                          <div className="form-name">

                            <span className="icon">
                              <img src="./images/fluent-form1.svg"></img>
                            </span>
                            <a href="/pdf/PAIA-Guide-isiZulu.pdf" download={"PAIA-Guide-isiZulu.pdf"} >PAIA-Guide-isiZulu.pdf</a>
                          </div>
                          <span className="icon">
                            <a href="/pdf/PAIA-Guide-isiZulu.pdf" download={"PAIA-Guide-isiZulu.pdf"} > <img src="./images/cloud-download.svg"></img></a>
                          </span>
                        </li>
                        <li className="list-item">
                          <div className="form-name">

                            <span className="icon">
                              <img src="./images/fluent-form1.svg"></img>
                            </span>
                            <a href="/pdf/PAIAForm5.pdf" download={"PAIAForm5.pdf"} >PAIA-Form-5.pdf</a>
                          </div>
                          <span className="icon">
                            <a href="/pdf/PAIAForm5.pdf" download={"PAIAForm5.pdf"} > <img src="./images/cloud-download.svg"></img></a>
                          </span>
                        </li>

                      </ul>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="footer terms d-none">
          <div className="footer-img">
            {/* <!-- <img src="./images/logo.png"> --> */}
          </div>
          <div className="foot-content d-flex">
            <a className="foot-link gray br" href="#">
              <img src="./images/designscape.png" />
            </a>
            <a className="foot-link br" href="#">
              <img src="./images/tolko.png" />{" "}
            </a>
            <a className="foot-link br" href="#">
              <img src="./images/inscape1.png" />
            </a>
            <a className="foot-link" href="#">
              <img src="./images/DUT-Logo_new.png" />
            </a>
          </div>
        </footer>
      </section>
    </div>
  );
};
export default Terms;
