import { environment } from "../environment";
import Common from '../Utility/Common'
const headers = {
    "Content-Type": "application/json",
}
function joinURL(baseURL, url) {
    return `${baseURL}/${url}`;
}

class AdminBaseService {
    constructor() {
        this.domain = environment.baseURL;
    }
    async login(res) {
        var path = window.location.pathname.toLowerCase()
        if (path == '/adminglogin') {

            localStorage.setItem("adminusertype", "admin")
        }
        if (res.status == 401 && localStorage.getItem("adminusertype") == "admin") {
            localStorage.removeItem("adminuserid");
            localStorage.removeItem("adminusername");
            localStorage.removeItem("adminloginid");
            localStorage.removeItem("adminemail");
            localStorage.removeItem("adminusertype");
            localStorage.removeItem("adminprofileimage");
            localStorage.removeItem("adminfirstName");
            localStorage.removeItem("adminlastName");
            window.location = "/adminlogin";
        }
        else if (res.status == 401) {
            localStorage.removeItem("userid");
            localStorage.removeItem("username");
            localStorage.removeItem("loginid");
            localStorage.removeItem("email");
            localStorage.removeItem("usertype");
            localStorage.removeItem("profileImage");
            localStorage.removeItem("firstName");
            localStorage.removeItem("lastName");
            window.location = '/login'
        }

    }
    async refreshtoken(jtoken) {
        var jtoken = jtoken
        var reftoken = localStorage.getItem("adminreftoken")
        var tokens = {
            "jwToken": jtoken,
            "refreshToken": reftoken
        }
        var option = {
            headers,
            "body": JSON.stringify({ ...tokens }),
            "method": "POST"
        }
        const url = joinURL(this.domain, 'Account/refresh');
        if (jtoken && reftoken) {
            await fetch(url, option)
                .then((response) => {
                    return response.json();
                }).then((res) => {
                    if (res.data) {
                        if (localStorage.getItem("adminusertype") == "admin") {
                            localStorage.setItem("admintoken", res.data.jwToken);
                        }
                        else {
                            localStorage.setItem("admintoken", res.data.jwToken);
                        }
                        localStorage.setItem("adminreftoken", res.data.refreshToken);

                    }

                });
        }
    }
    async request(url, method = 'POST', auth = true, data = null, filetype = null) {
        var today = new Date()
        var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        url = joinURL(this.domain, url);
        if (auth) {
            headers["authorization"] = `Bearer ${localStorage.getItem("admintoken")}`;
        }
        if (filetype === 'image') {
            headers = [];
        }
        const options = {
            headers,
            method,
        }
        if (data) {
            options.body = JSON.stringify({ ...data });
        }
        return await fetch(url, options);
    }
    async requestuser(url, method = 'POST', auth = true, data = null, filetype = null) {
        url = joinURL(this.domain, url);
        if (auth) {
            headers["authorization"] = `Bearer ${localStorage.getItem("admintoken")}`;
        }
        if (filetype === 'image') {
            headers = [];
        }
        const options = {
            headers,
            method,
        }
        if (data) {
            options.body = JSON.stringify({ ...data });
        }
        return await fetch(url, options);
    }
    async requestFile(url, method = 'POST', auth = true, data = null, filetype = null) {
        url = joinURL(this.domain, url);
        if (auth) {
            headers["authorization"] = `Bearer ${localStorage.getItem("admintoken")}`;
        }
        const options = {
            method,
        }
        if (data) {
            options.body = data;
        }
        return await fetch(url, options);
    }
    async get(url, auth, id) {
        const method = 'GET';
        if (id) {
            url = `${url}/${id}`;
        }
        return await this.request(url, method, auth).then(res => {
            var resp = res.json()
            this.login(res)
            return resp
        });
    }

    async getUser(url, id, auth) {
        const method = 'GET';
        if (id) {
            url = `${url}/id?Id=${id}`;
        }
        return await this.request(url, method, auth).then(res => {
            var resp = res.json()
            this.login(res)
            return resp
        });
    }

    async getusers(url, auth, data) {
        const method = 'POST';
        return await this.requestuser(url, method, auth, data).then(res => {
            var resp = res.json()
            this.login(res)
            return resp
        });
    }
    async post(url, auth, data) {
        const method = 'POST';
        return await this.request(url, method, auth, data).then(res => {
            var resp = res.json()
            this.login(res)
            return resp
        });
    }
    async delete(url, auth, id) {
        const method = 'DELETE';
        if (id) {
            url = `${url}/${id}`;
        }
        return await this.request(url, method, auth).then(res => {
            var resp = res.json()
            this.login(res)
            return resp
        });
    }

    async deleteUser(url, id, auth) {
        const method = 'DELETE';
        if (id) {
            url = `${url}/id?Id=${id}`;
        }
        return await this.request(url, method, auth).then(res => {
            var resp = res.json()
            this.login(res)
            return resp
        });
    }

    async put(url, auth, data) {
        const method = 'PUT';
        return await this.request(url, method, auth, data).then(res => {
            var resp = res.json()
            this.login(res)
            return resp
        });
    }
    async upload(url, auth, data) {
        const method = 'POST';
        return await this.requestFile(url, method, auth, data, 'image').then(res => {
            var resp = res.json()
            this.login(res)
            return resp
        });
    }

}
export default AdminBaseService
