import { Component } from "react";
import baseService from "./AdminBaseService";
import { toast } from "react-toastify";
import Common from "../Utility/Common";
class AdminUtilityService extends Component {
  constructor(props) {
    super(props);
    this.service = new baseService();
  }
  Create(url, data) {
    return new Promise((resolve, reject) => {
      var res = this.service
        .post(url, true, data).then((resp) => {
          if (resp.message === 'Success') {
            resolve(resp);
          } else {

            if (resp.message === "Error" || resp.errorMessage) {
              toast.error(resp.errorMessage, { theme: "colored", autoClose: 3000, toastId: 'error1' })
            } else {
              toast.error(resp.errorMessage, { theme: "colored", autoClose: 3000, toastId: 'error2', })
            }
            resolve(resp);
          }
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }
  UpdateStaticPages(url, data, id) {

    return new Promise((resolve, reject) => {
      var res = this.service
        .put(url + '/' + id, true, data).then((resp) => {
          if (resp.message === 'Success') {
            resolve(resp);
          } else {
            if (resp.message === undefined) {
              toast.error(resp.errorMessage, { theme: "colored", autoClose: 3000, toastId: 'error3' })
            } else {
              toast.error(resp.errorMessage, { theme: "colored", autoClose: 3000, toastId: 'error4' })
            }
            resolve(resp);
          }
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }
  Update(url, data) {

    return new Promise((resolve, reject) => {
      var res = this.service
        .put(url + '/' + data.id, true, data).then((resp) => {
          if (resp.message === 'Success') {
            resolve(resp);
          } else {
            if (resp.message === undefined) {
              toast.error(resp.errorMessage, { theme: "colored", autoClose: 3000, toastId: 'error3' })
            } else {
              toast.error(resp.errorMessage, { theme: "colored", autoClose: 3000, toastId: 'error4' })
            }
            resolve(resp);
          }
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }
  GetAll(url, auth = true) {
    return new Promise((resolve, reject) => {
      var res = this.service
        .get(url, auth)
        .catch(function (error) {
          reject(error);
        });
      resolve(res);
    });
  }
  GetAllUsers(url, data, auth = true) {
    return new Promise((resolve, reject) => {
      var res = this.service
        .getusers(url, auth, data)
        .catch(function (error) {
          reject(error);
        });
      resolve(res);
    });
  }
  PostGetAll(url, data) {
    return new Promise((resolve, reject) => {
      var res = this.service
        .post(url, true, data).then((resp) => {
          if (resp.message === 'Success') {
            resolve(resp);
          } else {
            reject(resp.ErrorMessage);
          }
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }
  GetById(url, id) {
    return new Promise((resolve, reject) => {
      var res = this.service
        .get(url + '/' + id, true)
        .catch(function (error) {
          reject(error);
        });

      resolve(res);
    });
  }

  GetUserById(url, id) {
    return new Promise((resolve, reject) => {
      var res = this.service
        .getUser(url, id, true)
        .catch(function (error) {
          reject(error);
        });

      resolve(res);
    });
  }

  GetDataById(url, id, data) {
    return new Promise((resolve, reject) => {
      var res = this.service
        .post(url + '?id=' + id, true, data)
        .catch(function (error) {

          reject(error);
        });

      resolve(res);
    });
  }
  Delete(url, id) {
    return new Promise((resolve, reject) => {
      var res = this.service
        .delete(url + '/' + id, true)
        .catch(function (error) {
          reject(error);
        });
      resolve(res);
    });
  }
  DeleteUser(url, id) {
    return new Promise((resolve, reject) => {
      var res = this.service
        .deleteUser(url, id, true)
        .catch(function (error) {
          reject(error);
        });
      resolve(res);
    });
  }
  UploadImages(url, type, data) {
    return new Promise((resolve, reject) => {
      var res = this.service
        .upload(url + `?userId=${Common.AdminUserId()}` + `&role=${Common.AdminUserType()}` + `&typeDir=${type}`, false, data).then((resp) => {
          if (resp.message === 'Success') {
            resolve(resp);
          } else {
            reject(resp.ErrorMessage);
          }
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  Upload(url, data) {
    return new Promise((resolve, reject) => {
      var res = this.service
        .upload(url, false, data).then((resp) => {
          if (resp.message === 'Success') {
            resolve(resp);
          } else {
            reject(resp.ErrorMessage);
          }
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }
}
export default AdminUtilityService;