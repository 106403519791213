import moment from "moment";

const TableView = (props) => {
  const checked = "checked";
  // console.log("props", props)
  // console.log("data", props.data)
  // console.log("coll", props.data.map((item, index) => item.id))
  return (
    <>
      <table className="table admin-table">
        <thead>
          <tr key="-1">
            {props.collist.map((item, index) => {
              return <th key={index}>{item.name}</th>;
            })}
            {props.edit ? <th>Edit</th> : ""}
            {props.delete ? <th>Delete</th> : ""}
          </tr>
        </thead>
        <tbody>
          {props.data.map((item, index) => {
            return (
              <tr key={item.id}>
                {props.collist.map((item1, index1) => {
                  return (
                    <td key={index1}>
                      {item1.type === "checkbox" ? (
                        <input
                          type="checkbox"
                          defaultChecked={item[item1.col]}
                        ></input>
                      ) : item1.type === "image" ? (
                        <img src={item[item1.col]} width="50px" height="50px" />

                      ) : item1.type === "date" ? (
                        <div>
                          {moment(item[item1.col]).format("DD-MM-YYYY")}
                        </div>
                      ) : (
                        item[item1.col]
                      )}
                    </td>
                  );
                })}
                <td className="edit-option">
                  <label>
                    <i
                      itemID={item.id}
                      onClick={props.editfunction(this)}
                      className="fa fa-pencil edit"
                      aria-hidden="true"
                    ></i>
                  </label>
                </td>
                <td className="edit-option">
                  <label>
                    <i
                      itemID={item.id}
                      onClick={props.deletefunction(this)}
                      className="fa fa-trash delete"
                      aria-hidden="true"
                    ></i>
                  </label>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
export default TableView;
