import "./App.css";

import Nav from "./App/Component/Nav";
import NavRoute from "./App/Component/NavRoute"
import { BrowserRouter as Router } from "react-router-dom";

function App(props) {
  return (
    <div className="App">
      <Router>
        <Nav />
        <NavRoute />
      </Router>
    </div>
  );
}

export default App;
