import Common from "../../Utility/Common";

const CompanyAboutUsModel = () => ({
    id: null,
    heading: "",
    description: "",
    image: "",
    website: "",
    noOfOfEmployees: "",
    headquerters: "",
    active: true,
    servicesOffered: "",
    userId: Common.UserId(),
    founded: "",
    companyId: Common.LoginId(),
    remoteWorking:false,
    travelOpportunities:false,
    learningOpportunities:false
});
export default CompanyAboutUsModel
