import React, { useState, useEffect } from "react";
import service from "../../../Services/UtilityService";
import ServiceAPI from "../../../Utility/ServiceList";
import Common from "../../../Utility/Common";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import $ from "jquery";
import { useHistory } from "react-router-dom";
import useLoader from '../../Auth/Loader'
toast.configure()

const DefaultModel = () => ({
    id: null,
    categoryName: "",
    active: true,
    userId: Common.UserId(),
    companyId: Common.LoginId()
});

function Checkfit(props) {
    localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
    const history = useHistory();
    const [model, setModel] = useState(DefaultModel);
    const [loader, showLoader, hideLoader] = useLoader();
    const [border, setBorder] = useState("");
    const id = props.id;
    const id1 = props.id1
    const services = new service();

    useEffect(() => {
        if (id) {
            GetById(id);
        }
        else if (id1) {
            GetById(id1);
        }
    }, []);
    const GetById = async (id) => {
        $('.modal-backdrop').removeClass('show ');
        $('.modal').removeClass('show');
        $('body').toggleClass('p-0');
        $('body').removeClass('modal-open');
        setModel(DefaultModel);
        var res = await services.GetById(ServiceAPI.CompanyCheckfitGetApi, id);
        const model = await res.result;
        setModel(model);
    }

    const ChangeHandler = (e) => {
        let value = {};
        if (e.target.type === "checkbox") {
            value[e.target.id] = e.target.checked;
        } else {
            value[e.target.id] = e.target.value;
        }
        setModel({ ...model, ...value });
        setBorder({ ...border, [e.target.id]: "" })
    }
    const Create = async (e) => {
        e.preventDefault();
        var response = null;
        if (valid()) {
            showLoader()
            let value = { userId: Common.UserId() };
            setModel({ ...model, ...value });
            if (model.id === null) {
                response = await services.Create(ServiceAPI.CompanyCheckfitCreateApi, model);
                hideLoader()
            } else {
                response = await services.Update(ServiceAPI.CompanyCheckfitUpdateApi, model);
                hideLoader()
            }

            if (response && response.message === "Success") {
                if (props.id1) {
                    props.GetShortListData();
                    toast.success('Successfully Update', { theme: "colored", autoClose: 2000 })
                    setTimeout(() => {
                        hideLoader()
                        $('.modal-backdrop').removeClass('show ');
                        $('.modal').removeClass('show');
                        $('body').toggleClass('p-0');
                        $('body').removeClass('modal-open');
                        $('nav.navbar.navbar-expand-sm').addClass('p-0');
                    }, 2000)
                }
                else if (props.id2) {
                    props.GetShortListData();
                    setModel(DefaultModel);
                    toast.success('Successfully Added', { theme: "colored", autoClose: 2000 })
                    setTimeout(() => {
                        hideLoader()
                        $('.modal-backdrop').removeClass('show ');
                        $('.modal').removeClass('show');
                        $('body').toggleClass('p-0');
                        $('body').removeClass('modal-open');
                        $('nav.navbar.navbar-expand-sm').addClass('p-0');
                    }, 2000)
                }
                else {
                    props.GetAll()
                    setTimeout(() => {
                        hideLoader()
                        $('.modal-backdrop').removeClass('show ');
                        $('.modal').removeClass('show');
                        $('body').toggleClass('p-0');
                        $('body').removeClass('modal-open');
                        $('nav.navbar.navbar-expand-sm').addClass('p-0');
                    }, 1000)
                    history.push("/companycheckfitlist");
                    setModel(DefaultModel);
                }
            }

        }
    };
    const valid = () => {
        let categoryName = "";
        if (!Boolean(model.categoryName)) {
            toast.error("Valid Category Name required", { theme: "colored", toastId: 'error1' });
            categoryName = "borderred";
        }

        if (categoryName) {
            setBorder({ categoryName });
            return false;
        }
        return true;
    };

    return (
        <>
            <div className="">
                <div className="mx-auto form-sec">
                    <div className="row align-items-center justify-content-center mt-3">
                        <div className="col-11">
                            <div className="label">Category Name</div>
                            <input
                                autoComplete="off"
                                type="text"
                                id="categoryName"
                                className={border.categoryName ? border.categoryName : "xu-input"}
                                placeholder="category Name"
                                value={model.categoryName}
                                onChange={(e) => ChangeHandler(e)}
                            ></input>
                        </div>
                        <div className="col-11">
                            <div style={{ textAlign: "center" }}>
                                <button type="button" className="btn btn-outline a-btn  mb-3 float-right" onClick={Create}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loader}
        </>
    )
}

export default Checkfit
