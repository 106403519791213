import Common from "../../Utility/Common";
const CompanyBannerModel = () => ({
        id: null,
        title: "",
        description: "",
        active: true,
        city: "",
        state: "",
        country: "",
        companyJobAttributes: "",
        companyJobCourses: "",
        companyJobToolkits: "",
        userId: Common.UserId(),
        industry: '',
        companyId: Common.LoginId(),
        industryId: null,
        jobCategory: '',
        companyURL: ""

});
export default CompanyBannerModel

