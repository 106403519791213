import Common from "../../Utility/Common";
const AttributeModel = () => ({
    id: null,
    name: '',
    image: '',
    active: true,
    userId: Common.UserId()


});
export default AttributeModel;