import React, { useState, useEffect } from "react";
import CompanyNav from "./CompanyNav";
const CompanyDashboard =()=>{
    return (
    <>
    <form>
              <div className="theme-container-admin">
              <CompanyNav banner="active"></CompanyNav>                     
                <div className="table-sec">
                  <div className="add-option">
                    <div className="title"></div>
                    <a className="icon" href="/studentbanner">
                      <i className="fa fa-plus" aria-hidden="true"></i>
                    </a>
                  </div>
                  <div className="ad-table table-responsive">
                   <h1> Company Dashboard</h1>
                  </div>
                </div>
              </div>
            </form>
    </>
    
    )
    }
    export default CompanyDashboard;