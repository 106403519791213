import React, { Component } from 'react'
import Carousel from 'react-elastic-carousel';

export default class User extends Component {

    constructor() {
        super();
        this.state = { data: null };
    }
    componentDidMount() {
        fetch('https://localhost:44356/api/v1/Banner/getall', {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }

        }).then((result) => {
            result.json().then((resp) => {
                this.setState({ items: resp.result });

            })
        })

    }

    render() {
        return (
            <div>
                <h1>Banner</h1>
                <Carousel>
                    {
                        this.state.items ?
                            this.state.items.map((item) =>
                                <img src={item.imageUrl} />

                            )
                            : null

                    }
                </Carousel>
            </div>
        )
    }
}
