import React, { useState, useEffect } from "react";
import service from "../../Services/UtilityService";
import ServiceAPI from "../../Utility/ServiceList";
import ValidationService from "../../Services/ValidationService";
import { toast } from "react-toastify";
import useLoader from "../Auth/Loader";
const Xyfund = () => {
    var services = new service();
    const [error, setError] = useState(false)
    const [loader, showLoader, hideLoader] = useLoader();
    const [contactModel, setContactModel] = useState({
        "name": "",
        "emailAddress": "",
        "message": "",

    });
    const contactHandle = async (e) => {
        e.preventDefault()
        if (valid()) {
            showLoader()
            var res = await services.Create(ServiceAPI.GetInTouchApi, contactModel);
            // console.log(res, "compContact");
            if (res.message === "Success") {
                hideLoader()
                setContactModel(
                    {
                        "name": "",
                        "emailAddress": "",
                        "message": "",

                    }
                )
                toast.success('Email Sent Successfully ', { theme: "colored", autoClose: 3000, toastId: 'Success1' })

            }
        }
        // const data = res.result;
        // await setCompanyAboutUsData(data)
    };
    const valid = () => {
        function isValidEmail(url) {
            let regEx = /^\S+@\S +\.\S + $ /
            return regEx.test(url);
        }
        var arrError = [];
        if (!ValidationService.validationNotEmpty(contactModel.name)) {
            toast.error("Please enter Name", { theme: "colored", autoClose: 3000, toastId: 'error1' });
        }
        if (!ValidationService.validationNotEmpty(contactModel.emailAddress)) {
            toast.error("Please enter Email", { theme: "colored", autoClose: 3000, toastId: 'error1' });
        }
        if (!ValidationService.validationEmail(contactModel.emailAddress)) {
            arrError.push("Valid Email required");
            toast.error("Valid Email required", { theme: "colored", autoClose: 3000, toastId: 'error1' });
        }
        if (!ValidationService.validationNotEmpty(contactModel.message)) {
            toast.error("Please enter message", { theme: "colored", autoClose: 3000, toastId: 'error1' });
        }
        if (arrError.length) {
            setError(arrError);
            return false;
        }
        return true;
    };
    const ChangeHandler = (e) => {
        e.preventDefault();
        let value = {};
        value[e.target.id] = e.target.value;
        setContactModel({ ...contactModel, ...value });
    }
    return <>
        <section className="section fund-sec">
            <div className="stat-sec min-height ">
                <div className="fund">
                    <div className="">
                        <img src="../images/bank.jpg" alt="" className="img" />
                        <div className="sign-heading">
                            Contribute to   <span style={{ color: "#FF5A00" }}>Xyfund</span>
                        </div>
                        <div className="sub-title">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu velit vitae metus feugiat feugiat id id ipsum. Pellentesque euismod sem in dapibus euismod. Praesent quam leo, tempus sed mattis id, dictum vel dolor. Nullam est orci, dictum egestas diam id, aliquet scelerisque turpis.
                        </div>
                        {/* <div> <a className="btn home-btn btn-outline big-btn" href="#" >Colleges</a></div> */}

                        {/* <div className="scroll-down">
                            <a href="#about"><img src="./images/Icon-material-mouse.svg" alt="" /></a> <br />
                            <a href="#about">  scroll down</a>
                        </div> */}
                    </div>
                </div>
            </div>
            <section className="bg-light">
                <div className="side-section container-fluid mtn">
                    <div className="row justify-content-between pannel-row">
                        <div className="col-md-3">
                            <div className=" title">
                                Define
                            </div>
                            <div className="detail">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu velit vitae metus feugiat feugiat id id ipsum. Pellentesque euismod sem in dapibus euismod. Praesent quam leo, tempus sed mattis id, dictum vel dolor. Nullam est orci, dictum egestas diam id, aliquet scelerisque turpis.
                            </div>
                            <div> <img className="img" src="../images/focus.png"></img> </div>
                        </div>
                        <div className="col-md-3">
                            <div className=" title">
                                Strategy
                            </div>
                            <div className="detail">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu velit vitae metus feugiat feugiat id id ipsum. Pellentesque euismod sem in dapibus euismod. Praesent quam leo, tempus sed mattis id, dictum vel dolor. Nullam est orci, dictum egestas diam id, aliquet scelerisque turpis.
                            </div>
                            <div> <img className="img" src="../images/target1.png"></img> </div>
                        </div>
                        <div className="col-md-3">
                            <div className=" title">
                                Build
                            </div>
                            <div className="detail">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu velit vitae metus feugiat feugiat id id ipsum. Pellentesque euismod sem in dapibus euismod. Praesent quam leo, tempus sed mattis id, dictum vel dolor. Nullam est orci, dictum egestas diam id, aliquet scelerisque turpis.
                            </div>
                            <div> <img className="img" src="../images/build.png"></img> </div>
                        </div>

                    </div>

                </div>

                <div className="side-section container-fluid no-bg">
                    <div className="row pannel-row">
                        <div className="col-md-4">
                            <div className=" title">
                                Funds & SPV's made simple
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="detail mt-0">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu velit vitae metus feugiat feugiat id id ipsum. Pellentesque euismod sem in dapibus euismod. Praesent quam leo, tempus sed mattis id, dictum vel dolor. Nullam est orci, dictum egestas diam id, aliquet scelerisque turpis.
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu velit vitae metus feugiat feugiat id id ipsum. Pellentesque euismod sem in dapibus euismod. Praesent quam leo, tempus sed mattis id, dictum vel dolor. Nullam est orci, dictum egestas diam id, aliquet scelerisque turpis.
                            </div>
                            <div className="detail row">
                                <div className="col-md-6">
                                    <span>
                                        <img src="../images/arrow-right.png" className="img sm"></img>

                                    </span>
                                    <span className="detail">
                                        Consectetur Adipiscing
                                    </span>
                                </div>

                                <div className="col-md-6">
                                    <span>
                                        <img src="../images/arrow-right.png" className="img sm"></img>

                                    </span>
                                    <span className="detail">
                                        Consectetur Adipiscing
                                    </span>
                                </div>

                                <div className="col-md-6">
                                    <span>
                                        <img src="../images/arrow-right.png" className="img sm"></img>

                                    </span>
                                    <span className="detail">
                                        Consectetur Adipiscing
                                    </span>
                                </div>

                                <div className="col-md-6">
                                    <span>
                                        <img src="../images/arrow-right.png" className="img sm"></img>

                                    </span>
                                    <span className="detail">
                                        Consectetur Adipiscing
                                    </span>
                                </div>

                                <div className="col-md-6">
                                    <span>
                                        <img src="../images/arrow-right.png" className="img sm"></img>

                                    </span>
                                    <span className="detail">
                                        Consectetur Adipiscing
                                    </span>
                                </div>

                                <div className="col-md-6">
                                    <span>
                                        <img src="../images/arrow-right.png" className="img sm"></img>

                                    </span>
                                    <span className="detail">
                                        Consectetur Adipiscing
                                    </span>
                                </div>
                                <div className="col-md-6">
                                    <span>
                                        <img src="../images/arrow-right.png" className="img sm"></img>

                                    </span>
                                    <span className="detail">
                                        Consectetur Adipiscing
                                    </span>
                                </div>

                                <div className="col-md-6">
                                    <span>
                                        <img src="../images/arrow-right.png" className="img sm"></img>

                                    </span>
                                    <span className="detail">
                                        Consectetur Adipiscing
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="side-section container-fluid">

                    <div className=" title line">
                        Configurable investment entities
                    </div>
                    <div className="row pannel-row">
                        <div className="col-md-5">
                            <div className=" title">
                                Structure your fund
                            </div>
                            <div className="detail">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu velit vitae metus feugiat feugiat id id ipsum.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu velit vitae metus feugiat feugiat id id ipsum.
                            </div>
                        </div>
                        <div className="offset-md-1 col-md-5">
                            <div className=" title">
                                Strategy
                            </div>
                            <div className="detail">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu velit vitae metus feugiat feugiat id id ipsum.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu velit vitae metus feugiat feugiat id id ipsum.
                            </div>
                        </div>


                    </div>

                </div>

                <div className="side-section container-fluid no-bg">
                    <div className="row ">

                        <div className="contact-sec ">
                            <div className="title line center">
                                get in touch
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-md-6">
                                    <div className="contacts">
                                        <img src="./images/contact-illu.png" alt="" className="img" />
                                        <div className="extra-info ">
                                            <div className="sub-title">
                                                <img src="./images/call-icon.png" alt="" />
                                                <div className="contact-details">
                                                    <span className="font-increase"> contact number</span><br /> (+27) 067 470 9654
                                                </div>
                                            </div>
                                            <div className="sub-title ">
                                                <img src="./images/mail-icon.png " alt=" " />
                                                <div className="contact-details ">
                                                    <span className="font-increase">Email</span><br />  admin@xylus.com  </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 ">
                                    <div className="bg-lines ">
                                        <div className="login-box type-box ">
                                            {/* <!-- <div className="sign-heading ">
                        location
                      </div> --> */}
                                            <div className="form-sec">
                                                <form action="" autoComplete="off">

                                                    <div>
                                                        <div className="label">
                                                            name
                                                        </div>
                                                        <input type="text" id="name" value={contactModel.name} onChange={(e) => ChangeHandler(e)} className="xu-input" />
                                                    </div>
                                                    <div>
                                                        <div className="label">
                                                            email
                                                        </div>
                                                        <input type="text" className="xu-input" id="emailAddress" value={contactModel.emailAddress} onChange={(e) => ChangeHandler(e)} />
                                                    </div>
                                                    <div>
                                                        <div className="label">
                                                            Message
                                                        </div>
                                                        <textarea type="text" className="xu-textarea" value={contactModel.message} id="message" onChange={(e) => ChangeHandler(e)}></textarea>
                                                    </div>
                                                    <button className="btn sign-btn btn-outline big-btn mt-0" onClick={(e) => contactHandle(e)}>Send Message</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </section>
        </section>
        {loader}
    </>
}

export default Xyfund;