import React, { useEffect } from "react";
import service from "../../Services/RegistrationService";
import MessageService from '../../Utility/MessageService'

const Confirm = (props) => {
  let params = (new URL(document.location)).searchParams;
  let userid = params.get('userId');
  let code = params.get('code');
  let action = params.get('action');

  var services = new service();
  useEffect(() => {
    updateStatus(userid, code, action);
  }, []);
  async function updateStatus(userid, code, action) {
    var res = await services.confirmEmail(userid, code, action);
    if (res.succeeded !== undefined) {
      alert(res.message);
    }
    window.location = '/login';
  }
  return ("");
}
export default Confirm;