import Common from "../../Utility/Common";
const CompanyBannerModel = () => ({
    id: null,
    name: "",
    imageUrl: "",
    title: "",
    titleLink: "",
    active: true,
    userId: Common.UserId(),
    errormsg: "",
    companyId: Common.LoginId()
});
export default CompanyBannerModel