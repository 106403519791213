import React, { useState, useEffect } from "react";
import service from "../../Services/UtilityService";
import ServiceAPI from "../../Utility/ServiceList";
import environment from "../../environment";
import { FaPencilAlt } from "react-icons/fa";
import moment from "moment"; import $ from "jquery";
import StudentPortFolio from "./Admin/StudentPortFolio";
import StudentProfiles from "./Admin/StudentProfile";
import StudentAchievement from "./Admin/StudentAchievement";
import StudentWorkExperience from "./Admin/StudentJobHistory";
import StudentEducations from "./Admin/StudentEducation";
import Common from "../../Utility/Common";
import { toast } from "react-toastify";
import ImageGallaries from './Admin/ImageGallaries';
import "react-image-gallery/styles/css/image-gallery.css";
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import MyNetwork from "../Company/MyNetwork";
import useLoader from "../Auth/Loader"
import { useHistory, Link } from "react-router-dom";
var showpercentage = false
const StudentsProfile = () => {
    var services = new service();
    const history = useHistory();
    const [banner, setBanner] = useState([]);
    const [timeouts, setTimeouts] = useState(false);
    const [show, setShow] = useState(false); const [show1, setShow1] = useState(false); const [show2, setShow2] = useState(false); const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [checkouts, setCheckOuts] = useState(false);
    const [studentData, setStudentData] = useState('');
    const [StudentEducation, setStudentEducation] = useState([]);
    const [loader, showLoader, hideLoader] = useLoader();
    const [isShortlist, setIsShortlist] = useState(false)
    const [checkfitvalue, setCheckFitValue] = useState(-1)
    const [pageModel, setPageModel] = useState({
        "pageNum": 1,
        "pageSize": 5
    });
    let params = (new URL(document.location)).searchParams;

    let id = params.get('id');

    const [ShortListModel, setShortListModel] = useState({
        "id": "00000000-0000-0000-0000-000000000000",
        "userId": Common.UserId(),
        "active": true,
        "studentId": id,
        "collegeId": Common.LoginId(),
        "categoryId": '00000000-0000-0000-0000-000000000000'
    });
    const [CompamyShortListModel, setCompamyShortListModel] = useState({
        "id": "00000000-0000-0000-0000-000000000000",
        "userId": Common.UserId(),
        "active": true,
        "studentId": id,
        "companyId": Common.LoginId(),
        "categoryId": null,
        "companyCheckfitId": null
    });
    const [datamodel, setDataModel] = useState(
        {
            "studentId": id,
            "companyId": null,
            'collegeId': null
        }
    )
    const verify = Common.ValidId(id, localStorage.getItem("suserid"));
    localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
    useEffect(() => {
        GetProfiles(id);
        CloseVideo()
        headNav();
    }, []);
    const GetProfiles = async () => {
        $('.modal-backdrop').removeClass('show ');
        $('.modal').removeClass('show');
        $('body').toggleClass('p-0');
        $('body').removeClass('modal-open');
        const x = localStorage.getItem('usertype')
        const s = localStorage.getItem('usertype') === 'company' || localStorage.getItem('usertype') === 'college' ? Common.LoginId() : null
        if (x === "company") {
            showLoader()
            var res1 = await services.GetStudentDataById(ServiceAPI.StudentGetApi, id, { ...datamodel, companyId: s });
            const data1 = res1.result;
            if (data1) {
                localStorage.setItem("suserid", data1.userId)
                setStudentData(data1 ? data1 : "");
                setIsShortlist(data1.isShortlist)
                hideLoader()
            }
        }
        else if (x === "college") {
            var res1 = await services.GetStudentDataById(ServiceAPI.StudentGetApi, id, { ...datamodel, collegeId: s });
            const data1 = res1.result;
            localStorage.setItem("suserid", data1.userId)
            setStudentData(data1 ? data1 : "");
            setIsShortlist(data1.isShortlist)
        }
        else {
            showLoader()
            var res1 = await services.GetStudentDataById(ServiceAPI.StudentGetApi, id, {
                "studentId": id,
                "companyId": null,
                'collegeId': null
            });
            const data1 = await res1.result;
            localStorage.setItem("suserid", data1.userId)
            await setStudentData(data1 ? data1 : "");
            await setIsShortlist(data1.isShortlist)
            setTimeout(() => {
                hideLoader()
            }, 1000);
        }
        setTimeout(() => {
            setTimeouts(true)
        }, 10);

    };
    const GetResume = async (type) => {
        if (type === "NotAccess") {
            toast.error("You don't have access to download this file ! ", { theme: "colored", autoClose: 2000 })
        }
        else {
            if (localStorage.getItem("usertype")) {
                let params = (new URL(document.location)).searchParams;
                let id1 = params.get('id');
                showLoader()
                var res1 = await services.GetResumeById(ServiceAPI.StudentGetResumeApi, id1);
                hideLoader()
                const url = await res1.result;
                if (res1.message === "Success") {
                    setTimeout(() => {
                        downloadBrochure(url)
                    }, 10)
                }
                else {
                    toast.error(res1.errorMessage, { theme: "colored", autoClose: 2000 })
                }
            }
            else {
                toast.error("Login Required! ", { theme: "colored", autoClose: 2000 })
            }
        }

    };
    const downloadBrochure = async (resume) => {
        // http://192.168.29.112:81/PDF/AshishSharma_resume1488.pdf
        fetch(`${resume}`, {
            method: 'GET'
        }).then(resp => resp.blob())
            .then(blob => {
                // the filename you wan
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = "Resume";
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
    }
    const GetCheckFit = async () => {
        showLoader()
        var res = await services.Create(ServiceAPI.StudentCheckFitApi, {
            "studentId": id,
            "companyId": Common.LoginId()
        });
        hideLoader()
        const data = res.result
        if (data === 0) {
            toast.error(`Not Compatible`, { theme: "colored", autoClose: 2000 })

        }
        else if (data === false) {
            toast.error(`Not Compatible`, { theme: "colored", autoClose: 2000 })
        }
        else {
            showpercentage = true
            toast.success(`${data}% Compatible`, { theme: "colored", autoClose: 2000 })
            setCheckFitValue(data === false ? 0 : data)
            setTimeout(() => {
                setCheckOuts(true)
            }, 3000);
        }
    };

    const UnShortList = async () => {
        showpercentage = false
        const x = localStorage.getItem('usertype')
        const s = localStorage.getItem('usertype') === 'company' || localStorage.getItem('usertype') === 'college' ? Common.LoginId() : null
        if (x === "company") {
            showLoader()
            var res = await services.Create(ServiceAPI.CompanyShortListremoveApi, { ...datamodel, companyId: s });
            hideLoader()
            if (res.message === "Success") {
                setIsShortlist(false)
                toast.success("Successfully Removed", { theme: "colored", autoClose: 2000 })
            }
        }
        else if (x === "college") {
            showLoader()
            // eslint-disable-next-line no-redeclare
            var res = await services.Create(ServiceAPI.CompanyShortListremoveApi, { ...datamodel, collegeId: s });
            hideLoader()
            if (res.message === "Success") {
                setIsShortlist(false)
                toast.success("Successfully Removed", { theme: "colored", autoClose: 2000 })
            }
        }
    }
    const PostShortList = async () => {
        let x = Common.UserType()
        if (x === "company") {
            showLoader()
            var res = await services.Create(ServiceAPI.CompanyShortListPostApi, CompamyShortListModel);
            if (res.message === "Success") {
                hideLoader()
                setIsShortlist(true)
                toast.success("Successfully Shortlisted", { theme: "colored", autoClose: 2000 })
            }
            else {
                toast.error(res.ErrorMessage, { theme: "colored", autoClose: 2000 })
                hideLoader()
            }
        }
        else if (x === "college") {
            showLoader()
            var res = await services.Create(ServiceAPI.CollegeShortListPostApi, ShortListModel);
            if (res.message === "Success") {
                hideLoader()
                setIsShortlist(true)
                toast.success("Successfully Shortlisted", { theme: "colored", autoClose: 2000 })
            }
            else {
                hideLoader()
                toast.error(res.ErrorMessage, { theme: "colored", autoClose: 2000 })
            }
        }
    };
    const headNav = () => {
        $(window).scroll(function (e) {
            var scrollDistance = $(window).scrollTop() + 150;
            if (scrollDistance) {
                // var menuc = $("#btn-nav").position().top;
                $(".profile-tab").each(function (i) {
                    var divPoc = $(this).position().top;
                    if (divPoc <= scrollDistance) {
                        $("#btn-nav ul li.active").removeClass('active');
                        $("#btn-nav ul li a.active ").removeClass('active');
                        $("#btn-nav ul li").eq(i).addClass('active');
                        $("#btn-nav ul li a").eq(i).addClass('active');
                    }
                });
            }
        });
        $(".nav-tabs .navitem").on("click", function () {
            var sectionId = '#' + $(this).attr('id').replace('lnk', '');
            // console.log(sectionId + '---' + $(sectionId).position().top + '---' + $(window).scrollTop());
            // $(window).scrollTop($(sectionId).position().top - 80);
            if (window.matchMedia('(max-width: 768px)').matches) {
                $(window).scrollTop($(sectionId).position().top - 100);
            }
            else {
                $(window).scrollTop($(sectionId).position().top - 80);
            }
        });
    }
    const redirectTolist = () => {
        history.push("/studentbannerlist");
    }
    const closeSearch = () => {
        if ($(".globsr").hasClass("active")) {
            if ($("#globsearch").val()) {
                $("#new-users-list").removeClass("show")

            }
            else {
                $(".global").removeClass("active")
                $("#globsearch").removeClass("active")
            }
        }
    }
    const CloseVideo = (index, src) => {
        // to pause the video
        $("#videoModal iframe").attr("src", $(`${"#videopopup"}${index} iframe`).attr("src", src));
        $('.close').click(function () {
            $('iframe').attr('src', $('iframe').attr('src'));
        });

    }
    const EditPortfolio = () => {
        setShow(true)
    }
    const Editeducation = () => {
        setShow1(true)
    }
    const EditWork = () => {
        setShow2(true)
    }
    const EditAchivement = () => {
        setShow3(true)
    }
    const EditBanner = () => {
        setShow4(true)
    }
    return (<>

        <section className="section ">
            <div className="profile">
                <div className="btn-row">
                    <div className="w-100">
                        <nav className="w-100" onClick={() => closeSearch()}>

                            <div className="scroll-tabs wrapped-tab" scroll="true" id="btn-nav">
                                <ul className="nav nav-tabs" id="pills-tab" role="tablist">
                                    <li id="lnkhome" className="navitem active">
                                        <a className="btn dark s-btn active"> Home</a>
                                    </li>
                                    <li id="lnkabout" className="navitem">
                                        <a className="btn dark s-btn"> about</a>
                                    </li>
                                    <li id="lnkportfolio" className="navitem">
                                        <a className="btn dark s-btn">portfolio</a>
                                    </li>
                                    <li id="lnkresumes" className="navitem">
                                        <a className="btn dark s-btn">resume</a>
                                    </li>
                                    <li className="d-md-none d-block">
                                        <div className="visit">
                                            {environment.isServer === "true" ? Common.UserType() === "company" ? studentData.isShortlist ? <a className="btn btn-outline s-btn" onClick={() => UnShortList()}><div className="px-2 ">Shortlist</div> <img className="sl-img" src="images/cross.png" /></a> : <a className="btn btn-outline s-btn" onClick={() => PostShortList()}><div className="px-2 ">Shortlist</div><img className="sl-img" src="images/file-check.png" /></a> : "" : ""}

                                            {environment.isServer === "true" ? Common.UserType() === "college" ? studentData.isShortlist ? <a className="btn btn-outline s-btn" onClick={() => UnShortList()}><div className="px-2 ">Shortlist</div> <img className="sl-img" src="images/cross.png" /></a> : <a className="btn btn-outline s-btn" onClick={() => PostShortList()}><div className="px-2 ">Shortlist</div><img className="sl-img" src="images/file-check.png" /></a> : "" : ""}
                                            {environment.isServer === "true" ? Common.UserType() === "company" && isShortlist === true ? <a className="btn btn-outline s-btn" onClick={() => GetCheckFit()}><img src="images/checkfit.png" onClick={() => GetCheckFit()} />checkfit</a> : "" : ""}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                    <div className="visit d-none d-md-block">
                        {environment.isServer === "true" ? Common.UserType() === "company" ? isShortlist ? <a className="btn btn-outline s-btn" onClick={() => UnShortList()}><div className="px-2 ">Shortlist</div> <img className="sl-img" src="images/cross.png" /></a> : <a className="btn btn-outline s-btn" onClick={() => PostShortList()}> <div className="px-2 ">Shortlist</div><img className="sl-img" src="images/file-check.png" /></a> : "" : ""}
                        {environment.isServer === "true" ? Common.UserType() === "college" ? isShortlist ? <a className="btn btn-outline s-btn" onClick={() => UnShortList()}><div className="px-2 ">Shortlist</div> <img className="sl-img" src="images/cross.png" /></a> : <a className="btn btn-outline s-btn" onClick={() => PostShortList()}><div className="px-2 ">Shortlist</div><img className="sl-img" src="images/file-check.png" /> </a> : "" : ""}
                        {environment.isServer === "true" ? Common.UserType() === "company" && isShortlist === true ? <a className="btn btn-outline s-btn" onClick={() => GetCheckFit()}><img src="images/checkfit.png" onClick={() => GetCheckFit()} />checkfit</a> : "" : ""}
                    </div>
                </div>
                <section className="profile-tab landing-section-home-page profile-carousel carousel slide" id="home" data-ride="carousel">
                    {/* Indicators  */}
                    <ul className="carousel-indicators carousel-dots landing-dots">
                        {banner && banner.length > 1
                            ? banner.map((items, indexs) => <li data-target="#home" key={indexs + items} data-slide-to={`${indexs}`} className={indexs === 0 ? 'active' : ""}></li>) : ""}
                    </ul>
                    <div className="caraousal-head ">
                        <div className={studentData.topAchiever === true ? verify ? "display-inline bg-white rounded center  pr-70" : "display-inline bg-white rounded unset pr-50" : verify ? "display-inline bg-white rounded unset pr-30" : "display-inline bg-white rounded unset "}>
                            {timeouts ? <div className="imge">
                                <img src={studentData ? studentData.profileImage : ""} alt="" className="img-fluid rounded-circle " />
                            </div> : ""}
                            <div className="edit-top">
                                <div className="pedit2 add-option " > {verify ? <FaPencilAlt onClick={() => EditBanner()} className="icon" data-toggle="modal" data-target="#exampleProfileModal" /> : ""}</div>
                                {studentData.topAchiever === true ? <div className="top-flag">Top </div> : ""}</div>
                            {timeouts ? <div className={studentData.topAchiever === true ? "headings " : "headings"}>
                                <div className="title">
                                    {studentData ? `${studentData.firstName} ${studentData.lastName}` : ""}
                                </div>
                                <div className="sub-title">
                                    {(studentData !== null && studentData.designation !== null) ? `${studentData.designation}` : ""}
                                </div>
                                <div className="sub-head">
                                    {(studentData !== null && studentData.country !== null) ? <img src="images/location-icon.png" /> : ""} {(studentData !== null && studentData.country !== null) ? `${studentData.country}` : ""}
                                </div>
                                <div className=" ">
                                    {environment.isServer === "true" ? Common.UserType() === "company" && checkfitvalue >= 0 ? <> <span style={{ color: "#ff5a00" }} >{checkfitvalue >= 0 && checkouts === false ? `${checkfitvalue}% Compatible` : ""}</span> </> : "" : ""}
                                </div>
                            </div> : ""}
                        </div>
                    </div>
                    <a className="scroll-down" href="#about">
                        <a href="#about"><img src="./images/scroll-down.svg" alt="" /></a> <br />
                        <a href="#about">  scroll down</a>
                    </a>
                    <div className="checkfit-percentage " data-toggle="tooltip" data-placement="right" >
                        {environment.isServer === "true" ? Common.UserType() === "company" && checkfitvalue >= 0 && showpercentage === true ? <>
                            <span className="percent-value">{`${checkfitvalue} %`}</span>
                            <CircularProgressbarWithChildren value={checkfitvalue} strokeWidth={5} styles={buildStyles({
                                pathColor: "#FF5A00",
                            })}>
                                <img src={studentData ? studentData.profileImage : ""} alt=" " className="rounded-circle" />
                            </CircularProgressbarWithChildren>
                        </> : "" : ""}
                    </div>
                    <div className="artwork carousel-icon ">
                        {studentData.twitterUrl ? <a href={studentData ? studentData.twitterUrl : ""} className="mx-2" target="_blank"><img src="images/twitter.svg" alt="fb icon" /></a> : ""}
                        {studentData.facebookUrl ? <a href={studentData ? studentData.facebookUrl : ""} className="mx-2" target="_blank"><img src="images/facebook.svg" alt="fb icon" /></a> : ""}
                        {studentData.instagramUrl ? <a href={studentData ? studentData.instagramUrl : ""} className="mx-2" target="_blank"><img src="images/instagram.svg" alt="fb icon" /></a> : ""}

                    </div>
                    <div className="edit add-option landing-img-edit"  >
                        {verify ? <FaPencilAlt onClick={() => redirectTolist()} className="icon " /> : ""}</div>
                    <div className="carousel-inner landing-carousel">
                        {studentData.banners ? studentData.banners.map((bimage, index) => {
                            return (
                                <div data-interval={3000} className={index === 0 ? `carousel-item active` : "carousel-item"} key={index}>
                                    <div className="landing-carousal-item caraousal-slide-1">

                                        {bimage.titleLink ? <a className="caraousal-image" href={bimage.titleLink ? bimage.titleLink : ""} target={bimage.titleLink ? "_blank" : ""} >
                                            <img src={bimage ? bimage.imageUrl : "images/bannerbg.jpg"} />
                                        </a> :
                                            <div className="caraousal-image"   >
                                                <img src={bimage ? bimage.imageUrl : "images/bannerbg.jpg"} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        }) : ""}
                    </div>

                    {studentData.banners && studentData.banners.length > 1 ? <a className="carousel-control-prev control" href="#home" data-slide="prev">
                        <img src="images/chevron.svg" className="prev icon"></img>
                    </a> : ""}
                    {studentData.banners && studentData.banners.length > 1 ? <a className="carousel-control-next control" href="#home" data-slide="next">
                        <img src="images/chevron.svg" className="next icon"></img>
                    </a> : ""}

                </section>
                <div className="modal fade xy-form"
                    id="exampleProfileModal"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog modal-xl modal-dialog-centered" >
                        <div className="modal-content">
                            <div className="modal-header form-mdlheadr">
                                <h4 className="modal-title mdl-heading">EDIT</h4>
                                <button type="button" className="close modalclose-btn" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                {verify ? <StudentProfiles id={studentData.id} GetstudentProfiles={GetProfiles} show={show4} setShow={setShow} modalid={`exampleProfileModal`} /> : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* courses    */}
        <div className=" stat-sec profile-sub-sec " id="about">
            <div className="profile-tab att-tool content theme-container light">
                <div className="title line">
                    About
                </div>
                <div className="container-fluid">
                    <div className="row att-tool-row">
                        <div className="pedit2 add-option"  > {verify ? <FaPencilAlt onClick={() => EditBanner()} className="icon" data-toggle="modal" data-target="#exampleProfileModal" /> : ""}</div>

                        <div className="col-sm-3 ">
                            <div className="user-profile">
                                {timeouts ? <img src={studentData ? studentData.profileImage : `${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/user.svg`} className="rounded-circle fit-img" alt="" /> : ""}
                                <div className="title mt-20">{studentData ? `${studentData.firstName} ${studentData.lastName}` : ""}</div>
                                {checkouts && showpercentage === true ? <span className="p-text">{`${checkfitvalue}% Compatible`}</span> : ""}
                                <div className="user-profession">
                                    {(studentData !== null && studentData.designation !== null) ? `${studentData.designation}` : ""}
                                </div>
                                <div className="user-location">
                                    {studentData ? studentData.country ? <img src="images/location-icon.png" /> : "" : ""}{studentData ? `${studentData.country}` : ""}
                                </div>
                                <div className="education">
                                    <div className="study d-flex ">
                                        {studentData && studentData.educations ? studentData.educations[0] ? studentData.educations[0].qualifications.name ? <img src="images/qualification.png" /> : "" : "" : ""}
                                        <div className="max-width">{studentData && studentData.educations ? studentData.educations[0] ? studentData.educations[0].qualifications.name : "" : ""}</div>
                                    </div>
                                    <div className="study d-flex">
                                        {(studentData && studentData.courseDuration !== 0) ?
                                            studentData.isPursuing === false ? <> <img src="images/education-icon.png" className="graduated-icon" alt="" /> {`Graduated (${studentData.lastCourseYear.indexOf("-") > 0 ? studentData.lastCourseYear.split(" ")[0].split('-')[2] : moment(studentData.lastCourseYear).format("YYYY")})`}</>
                                                : <><img src="images/education-icon.png" className="graduated-icon" alt="" /> {`${studentData.pursuingYearNum}/${studentData.courseDuration} Year`}</>
                                            : ""}
                                    </div>
                                    {/* <div className="study" style={{paddingLeft:"38px"}}>{studentData && studentData.educations ? studentData.educations[0] ? studentData.educations[0].educationType : "" : ""}</div>   */}
                                    <div className="study d-flex">
                                        {studentData && studentData.educations ? studentData.educations[0] ? studentData.educations[0].studyProgram ? <img src="images/education-icon.png" alt="" /> : "" : "" : ""}
                                        <div className="max-width">{studentData && studentData.educations ? studentData.educations[0] ? studentData.educations[0].studyProgram : "" : ""}</div>
                                    </div>
                                    <div className="college d-flex">
                                        {studentData ? studentData?.collegeNavigation?.logo ? <img src={studentData ? studentData?.collegeNavigation.logo : ""} alt="" /> : "" : ""}
                                        <div className="max-width">{studentData?.collegeNavigation ? studentData?.collegeNavigation.name : ""}</div>
                                    </div>
                                    <div className="study d-flex">
                                        {studentData ? studentData.studentLanguage && studentData.studentLanguage.length ? <img src="images/language.png" alt="" /> : "" : ""}
                                        <div className="languages">
                                            {studentData.studentLanguage ? studentData.studentLanguage.sort((a, b) => a.languageNavigation.languageName.toLowerCase().localeCompare(b.languageNavigation.languageName.toLowerCase())).map((data, index) => {
                                                return (
                                                    <div className="pr-1">{data.languageNavigation.languageName}{studentData.studentLanguage.length > 1 && studentData.studentLanguage.length - 1 > index ? ' | ' : " "} </div>
                                                )
                                            }) : " "}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5  col-sm-8 toolkit ">
                            {studentData && studentData.description ? <> <div className="title bordered ">
                                about
                            </div>
                                <div className="about-details">
                                    {studentData && studentData.description}
                                </div> </> : ""}
                            {/* <!-- heading --> */}
                            <div className="title bordered pt-3">
                                Toolkit
                            </div>
                            {/* <!-- progree-bar --> */}
                            <div className="display-inline start p-0 skillset-block">
                                {/* <!-- ai -->/ */}
                                {/* <div className="progress mt-0">
                                    <img src="./images/bg-img1.png " alt=" " />
                                    <div className="overlay "></div>
                                    <div className="left "></div>
                                    <div className="right "></div>
                                </div> */}
                                {studentData ? studentData.toolKits.map((value, index) => {
                                    return (
                                        <div className="skillset">
                                            <div className="progress-circle" data-toggle="tooltip" data-placement="right">
                                                <span className="percent-value">{value.percentage ? `${value.percentage}%` : ""}</span>
                                                <CircularProgressbarWithChildren value={value.percentage} strokeWidth={7} styles={buildStyles({
                                                    pathColor: "#FF5A00",
                                                })}>
                                                    <img src={value.toolKit ? value.toolKit.image : "./images/revit.svg"} alt=" " className="rounded-circle img" />
                                                </CircularProgressbarWithChildren>
                                            </div>
                                            <div className="detail">{value.name}</div>
                                        </div>
                                    )
                                }) : ""}
                            </div>
                            {studentData && studentData.skillSets && studentData.skillSets.length > 0 ? <div className="title bordered pt-3">
                                SkillSet
                            </div> : ""}
                            <div className="display-inline start p-0 skillset-block">
                                {/* <!-- ai -->/ */}
                                {/* <div className="progress mt-0">
                                    <img src="./images/bg-img1.png " alt=" " />
                                    <div className="overlay "></div>
                                    <div className="left "></div>
                                    <div className="right "></div>
                                </div> */}

                                {studentData ? studentData.skillSets.map((value, index) => {
                                    return (
                                        <div className="skillset">
                                            <div className="progress-circle" data-toggle="tooltip" data-placement="right">
                                                <span className="percent-value">{value.percentage ? `${value.percentage}%` : ""}</span>
                                                <CircularProgressbarWithChildren value={value.percentage} strokeWidth={7} styles={buildStyles({
                                                    pathColor: "#FF5A00",
                                                })}>
                                                    <img src={value.skillSet ? value.skillSet.image : "./images/revit.svg"} alt=" " className="rounded-circle img" />
                                                </CircularProgressbarWithChildren>
                                            </div>
                                            <div className="detail">{value.name}</div>
                                        </div>
                                    )
                                }) : ""}
                            </div>
                        </div>
                        <div className="col-xl-4 col-sm-12 col attributes ">
                            {/* <!-- heading --> */}
                            <div className="row pr-4 space-even">
                                <div className="col-xl-12 col-sm-3 p-0">
                                    {/* <!-- cv --> */}
                                    <div className="stat-sec profile-sub-sec bg-2 ">
                                        <div className="cv-sec theme-container ">
                                            <div className="display-inline center ">
                                                <div className="cv">
                                                    <img src="./images/cv.png " className="img-fluid " alt=" " />
                                                    <div className="cv-download">find my C.V here
                                                        {/* <Link className="btn btn-theme btn-outline big-btn " to={Resume ? "C:\Users\Public\Documents\movie.pdf" :"C:\Users\Public\Documents\movie.pdf"} target="_blank" download onClick={() => ResumeDownload()}>Download</Link> */}
                                                        {environment.isServer === "true" ?
                                                            verify === true ? <div className="btn btn-theme btn-outline big-btn " onClick={() => GetResume()}>
                                                                <img src="images/download-icon.png" alt="" />  download</div>
                                                                : Common.UserType() === "company" || Common.UserType() === "college" ?
                                                                    <div className="btn btn-theme btn-outline big-btn " onClick={() => GetResume()}>
                                                                        <img src="images/download-icon.png" alt="" />  download</div>
                                                                    : Common.UserType() ? <div className="btn btn-theme btn-outline big-btn" onClick={() => GetResume("NotAccess")} >
                                                                        <img src="images/download-icon.png" alt="" />  download
                                                                    </div> : <div className="btn btn-theme btn-outline big-btn" onClick={() => GetResume()}>
                                                                        <img src="images/download-icon.png" alt="" />  download
                                                                    </div>
                                                            : <div className="btn btn-theme btn-outline big-btn">
                                                                <img src="images/download-icon.png" alt="" />  download
                                                            </div>}
                                                        {/* <PDFDownloadLink
                                                            document={<PdfDocument />}
                                                            fileName="resume.pdf"
                                                        >
                                                            {({ blob, url, loading, error }) =>
                                                                loading ? "Loading document..." : "Download"
                                                            }
                                                        </PDFDownloadLink> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-sm-8 ">
                                    <div className="title bordered">
                                        attributes
                                    </div>
                                    <div className="row">
                                        {studentData && studentData.attributes ? studentData.attributes.map((Atr, index) => {
                                            return (
                                                <div className="col-xl-6 col-md-4 col-6" key={index}>
                                                    <div className="sub-title "><img src={Atr ? Atr.attribute.image : "./images/success.png "} alt=" " />{Atr.name}</div>
                                                </div>)
                                        }) : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* portfoio */}
            <div className="profile-tab portfolio-sec content theme-container" id="portfolio">
                <div className="d-flex justify-content-between">
                    <div className="title line">
                        portfolio
                    </div>
                    <div className="float-right"> {verify ? <div className="add-option"><span></span><div className="icon" data-toggle="modal" data-target="#exampleModaladd">
                        <i className="fa fa-plus" aria-hidden="true"></i>
                    </div></div> : ""}</div>
                </div>
                <div className="container-fluid pr-0">
                    <div className="portfolio" >
                        {studentData.portfolios ? studentData.portfolios.map((portFolio1, index) => {
                            return (<section className="card-column" key={index} >
                                <div className="img-container"  >
                                    {verify ? <div className=" add-option edit2"><FaPencilAlt onClick={() => EditPortfolio()} className="icon" data-toggle="modal" data-target={`${"#exampleModal112"}${index}`} /> </div> : ""}
                                    {portFolio1 && portFolio1.images.length > 0 && portFolio1.images[0].isVideo === true ?
                                        <img src={portFolio1.images && portFolio1.images.length > 0 ? portFolio1.images[0].videoThumb : "images/thumbnail.png"} alt="Avatar" /> :
                                        <img src={(portFolio1 && (portFolio1.images.length !== 0)) ? ((portFolio1.images.length !== 0) && portFolio1.images[0].imageUrl) : "./images/couse.svg"} alt="Avatar" className="image" />
                                    }
                                    <div className="middle" >
                                        <div className="front-text">
                                            <div className="title">
                                                {portFolio1 ? portFolio1.title : ""}
                                                <hr />
                                            </div>
                                            <div className="sub-head">
                                                {portFolio1 ? portFolio1.description : ""}
                                            </div>
                                        </div>
                                        <div data-toggle="modal" data-target={portFolio1 && (portFolio1.images && portFolio1.images.length > 0 && portFolio1.images[0].isVideo && portFolio1.images[0].isVideo === true) ? `${"#videopopup"}${index}` : `${"#exampleModal2"}${index}`} className="seemore-btn">See more</div>
                                        {portFolio1 && portFolio1.titleLink ? <a href={portFolio1 ? portFolio1.titleLink ? portFolio1.titleLink : "" : ""} target="_blank" className="readmore-btn">Web link </a> : ""}
                                    </div>
                                </div>
                            </section>)
                        }) : ""}
                        {studentData.portfolios ? studentData.portfolios.map((portFolio1, index) => {
                            return (<>
                                {portFolio1 && (portFolio1.images.length > 0 && portFolio1.images[0].isVideo === true) ?
                                    <div className="modal fade xy-form portfolio-mode video-modal"
                                        id={`${"videopopup"}${index}`}
                                        tabIndex="-1"
                                        role="dialog"
                                        aria-labelledby="exampleModalLabel"
                                        aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered ">
                                            <div className="modal-content portfolio-modal">
                                                <button type="button" className="modalclose-btn button close" onClick={() => CloseVideo(index, `https://www.youtube.com/embed/${portFolio1.images[0].videoKey}`)} data-dismiss="modal">&times;</button>
                                                <iframe src={portFolio1.images[0].videoThumb ? `https://www.youtube.com/embed/${portFolio1.images[0].videoKey}` : ""} className="yt_player_iframe" style={{ height: "99vh", width: "99vw" }} id="videoId" allowfullscreen="true" frameBorder="0"></iframe>
                                            </div>
                                        </div>
                                    </div>
                                    : ""}
                                <div className="modal fade xy-form portfolio-mode"
                                    id={`${"exampleModal2"}${index}`}
                                    tabIndex="-1"
                                    role="dialog"
                                    aria-labelledby="exampleModalLabel"
                                    aria-hidden="true">
                                    <div className="modal-dialog modal-lg justify-content-center modal-dialog-centered modal-dialog-scrollable">
                                        <div className="modal-content bg-light portfolio-modal">
                                            <div className="modal-header d-flex">
                                                <div className="front-text">
                                                    <div className="title">
                                                        {portFolio1 ? portFolio1.title : ""}
                                                    </div>
                                                </div>
                                                <button type="button" className="modalclose-btn button position-relative m-0" data-dismiss="modal" style={{ top: '0' }}>&times;</button>
                                            </div>
                                            <ImageGallaries images={portFolio1.images} className="img-container" />
                                            <div className="modal-body">
                                                <div className="front-text mt-2">
                                                    <div className="portFolio-sub-head text-center ">
                                                        {portFolio1 ? portFolio1.description : ""}
                                                    </div>
                                                </div> </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade xy-form"
                                    id={`${"exampleModal112"}${index}`}
                                    tabIndex="-1"
                                    role="dialog"
                                    aria-labelledby="exampleModalLabel"
                                    aria-hidden="true">
                                    <div className="modal-dialog modal-lg modal-dialog-centered" >
                                        <div className="modal-content">
                                            <div className="modal-header form-mdlheadr">
                                                <h4 className="modal-title mdl-heading">EDIT</h4>
                                                <button type="button" id={'exampleModal112' + index} className="close modalclose-btn" data-dismiss="modal">&times;</button>
                                            </div>
                                            <div className="modal-body" id={`${"exampleModal112"}${index}`}>
                                                {verify ? <StudentPortFolio id={portFolio1.id} GetStudentPortfolio={GetProfiles} setShow={setShow} show={show} modalid={`exampleModal112${index}`} /> : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div></>)
                        }) : ""}
                        <div className="modal fade xy-form"
                            id="exampleModaladd"
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header form-mdlheadr">
                                        <h4 className="modal-title mdl-heading">ADD</h4>
                                        <button type="button" className="close modalclose-btn" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div className="modal-body">
                                        {verify ? <StudentPortFolio id2="0" GetStudentPortfolio={GetProfiles} setShow={setShow} show={show} modalid={`exampleModaladd`} /> : ""}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </div >
        {/* <!-- education section --> */}
        <section section className="profile-tab education-section" id="resumes" >
            <div className="container-fluid  p-0">
                <div className="row">
                    <div className="col-xl-10">
                        <div className="education">
                            <div className="title line">
                                Education
                            </div>
                            <div className="float-right">{verify ? <div onClick={() => Editeducation()} className="add-option"><span></span><div className="icon" data-toggle="modal" data-target="#exampleEducationAdd">
                                <i className="fa fa-plus" aria-hidden="true"></i>
                            </div></div> : ""}
                            </div>

                            <hr />
                            <div className="edu-div" id="edu">
                                {studentData.educations ? studentData.educations.map((education, index) => {
                                    return (
                                        <div className="timeline" key={index}>
                                            {verify ? <div className=" add-option edit2"><FaPencilAlt onClick={() => Editeducation()} className="icon" data-toggle="modal" data-target={`#editEducationAdd${index}`} /> </div> : ""}
                                            <div className="tml-date">{moment(education.startDate).format("YYYY")} - {moment(education.endDate).format("YYYY")}</div>
                                            <div className="tml-title">{education.institute}</div>
                                            <div className="tml-detail">
                                                <div className="sub-title">{education.qualifications.name}</div>
                                                <div className="sub-title mb-0 mt-0 sm">{education.studyProgram} </div>
                                                <div className="title mt-0">{education.percentage > 0 ? education.percentage : ""} </div>
                                            </div>
                                            <div className="modal fade xy-form"
                                                id={`editEducationAdd${index}`}
                                                tabIndex="-1"
                                                role="dialog"
                                                aria-labelledby="exampleModalLabel"
                                                aria-hidden="true">
                                                <div className="modal-dialog form-w modal-dialog-centered" >
                                                    <div className="modal-content">
                                                        <div className="modal-header form-mdlheadr">
                                                            <h4 className="modal-title mdl-heading">EDIT</h4>
                                                            <button type="button" id={'editEducationAdd' + index} className="close modalclose-btn" data-dismiss="modal">&times;</button>
                                                        </div>
                                                        <div className="modal-body">
                                                            {verify ? <StudentEducations id={education.id} GetStudentEducations={GetProfiles} show={show1} setShow1={setShow1} modalid={`editEducationAdd${index}`} /> : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)
                                }) : ""}
                            </div>

                            <div className="modal fade xy-form"
                                id="exampleEducationAdd"
                                tabIndex="-1"
                                role="dialog"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true">
                                <div className="modal-dialog form-w modal-dialog-centered" >
                                    <div className="modal-content">
                                        <div className="modal-header form-mdlheadr">
                                            <h4 className="modal-title mdl-heading">EDIT</h4>
                                            <button type="button" className="close modalclose-btn" data-dismiss="modal">&times;</button>
                                        </div>
                                        <div className="modal-body">
                                            {verify ? <StudentEducations id2="0" GetStudentEducations={GetProfiles} show={show1} setShow1={setShow1} modalid={`exampleEducationAdd`} /> : ""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="work-experience">
                            <div className="title line">
                                WORK EXPERIENCE
                            </div>
                            <div className="float-right">{verify ? <div onClick={() => EditWork()} className="add-option"><span></span><div className="icon" data-toggle="modal" data-target="#exampleWorkExperienceAdd">
                                <i className="fa fa-plus" aria-hidden="true"></i>
                            </div></div> : ""}

                            </div>
                            <hr />
                            <div className="edu-div" id="edu">
                                {studentData.jobHistorys ? studentData.jobHistorys.map((jobHistory, index) => {
                                    return (
                                        <div className="timeline" key={index}>
                                            {verify ? <div className=" add-option edit2"><FaPencilAlt onClick={() => EditWork()} className="icon" data-toggle="modal" data-target={`#EditExperience${index}`} /> </div> : ""}
                                            <div className="tml-date">{moment(jobHistory.fromDate).format("YYYY")} - {jobHistory.isPresent ? "Present" : moment(jobHistory.toDate).format("YYYY")}</div>
                                            <div className="tml-title">{jobHistory.companyName}</div>
                                            <div className="tml-detail">
                                                <div className="sub-title">{jobHistory.position}</div>
                                                <div className="sub-title-dtl">
                                                    {jobHistory.responsibility.length >= 50 ? jobHistory.responsibility.slice(0, 50) : jobHistory.responsibility}
                                                    {jobHistory.responsibility && jobHistory.responsibility.length >= 50 ? <button data-toggle="modal" data-target={`#exampleMediaPopup${index}`} id="readmore-btn">...read more </button> : ""}
                                                </div>
                                            </div>
                                            <div className="modal fade  apply-modal "
                                                key={index + 1}
                                                id={`exampleMediaPopup${index}`}
                                                tabIndex="-1"
                                                role="dialog"
                                                aria-labelledby="exampleModalLabel"
                                                aria-hidden="true">
                                                <div className="modal-dialog modal-lg modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <button type="button" className="close modalclose-btn" data-dismiss="modal">&times;</button>
                                                        <div className="modal-body custom-padding">
                                                            <div className="container-fluid pr-0">
                                                                <div className=" mt-4 mr-0 ">
                                                                    <div>
                                                                        <div className="title mt-2 text-uppercase">{jobHistory.companyName}</div>
                                                                        <h6 className=" sub-title mt-0 mb-3">Duration : {moment(jobHistory.fromDate).format("YYYY")} - {moment(jobHistory.toDate).format("YYYY")} </h6>
                                                                    </div>

                                                                    <div className="">
                                                                        <div className="sub-title mb-2">{jobHistory.position}</div>
                                                                        <div className="modalbody-content">

                                                                            <p className="detail"> {jobHistory.responsibility ? jobHistory.responsibility : ""}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal fade xy-form"
                                                id={`EditExperience${index}`}
                                                tabIndex="-1"
                                                role="dialog"
                                                aria-labelledby="exampleModalLabel"
                                                aria-hidden="true">
                                                <div className="modal-dialog form-w modal-dialog-centered">
                                                    <div className="modal-content ">
                                                        <div className="modal-header form-mdlheadr">
                                                            <h4 className="modal-title mdl-heading">EDIT</h4>
                                                            <button type="button" id={'EditExperience' + index} className="close modalclose-btn" data-dismiss="modal">&times;</button>
                                                        </div>
                                                        <div className="modal-body">
                                                            {verify ? <StudentWorkExperience id={jobHistory.id} GetStudentWorkExperience={GetProfiles} show={show2} setShow2={setShow2} modalid={`EditExperience${index}`} /> : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)
                                }) : ""}
                            </div>
                            <div className="modal fade xy-form"
                                id="exampleWorkExperienceAdd"
                                tabIndex="-1"
                                role="dialog"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true">
                                <div className="modal-dialog form-w modal-dialog-centered">
                                    <div className="modal-content ">
                                        <div className="modal-header form-mdlheadr">
                                            <h4 className="modal-title mdl-heading">EDIT</h4>
                                            <button type="button" id={'exampleWorkExperienceAdd'} className="close modalclose-btn" data-dismiss="modal">&times;</button>
                                        </div>
                                        <div className="modal-body">
                                            {verify ? <StudentWorkExperience id2="0" GetStudentWorkExperience={GetProfiles} show={show2} setShow2={setShow2} modalid={'exampleWorkExperienceAdd'} /> : ""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2">
                        <div className="achievement">
                            {verify ? <div className="add-option float-right">
                                <span></span>
                                <div className="icon" onClick={() => EditAchivement()} data-toggle="modal" data-target="#exampleModalAchievement">
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                </div>
                            </div>
                                : ""}
                            <div className="title line ach">
                                ACHIEVEMENTS
                            </div>
                            <div className="overflow-cards" >
                                {studentData.achievements ? studentData.achievements.map((awards, index) => {
                                    return (
                                        <div className="flex" key={index + 112}>
                                            <div className="ac-card" key={index}>
                                                <div className="pedit2 add-option"  > {verify ? <FaPencilAlt onClick={() => EditAchivement()} className="icon" data-toggle="modal" data-target={`${"#editModalAchievement"}${index}`} /> : ""}</div>
                                                <div className="ac-icons">
                                                    <img src={awards.image ? awards.image : "/images/awards-trophy.png "} alt="icon" className="img-fluid" />
                                                </div>
                                                <div className="ac-card-heading">
                                                    {awards ? awards.title : " UX/UX WORKSHOP"}
                                                </div>
                                                <div className="ac-card-date">
                                                    {awards && awards.fromDate}
                                                </div>
                                                <div className="ac-card-content">
                                                    {awards.description ? awards.description.length >= 100 ? awards.description.slice(0, 100) : awards.description : ""} {awards.description.length >= 100 ? <button data-toggle="modal" data-target={`#exampleawardsPopup${index}`} id="readmore-btn">...read more </button> : ""}

                                                </div>

                                            </div>
                                            <div className="modal fade  apply-modal "
                                                key={index + awards + 1}
                                                id={`exampleawardsPopup${index}`}
                                                tabIndex="-1"
                                                role="dialog"
                                                aria-labelledby="exampleModalLabel"
                                                aria-hidden="true">
                                                <div className="modal-dialog modal-lg modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <button type="button" className="close modalclose-btn pedit2" data-dismiss="modal">&times;</button>

                                                        <div className="modal-header">
                                                            <div className="modal-title mdl-heading mt-2">{awards.title}</div>

                                                            <h6 className="mt-2 j-type-name">Date: {moment(awards.date).format("DD")} {moment(awards.date).format("MMM")} {moment(awards.date).format("YYYY")} </h6>
                                                        </div>
                                                        <div className="modal-body custom-padding">
                                                            <div className="container-fluid pr-0">
                                                                <div className="row mt-0 mr-0 justify-content-between">
                                                                    <div className="col-sm-8">
                                                                        <div className="modalbody-content">
                                                                            <p className="desc"> {awards.description ? awards.description : ""}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-4  ">
                                                                        <img src={awards ? awards.image : "./images/news-img.png"} alt="" className="img-fluid fix-h" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal fade xy-form"
                                                id={`${"editModalAchievement"}${index}`}
                                                tabIndex="-1"
                                                role="dialog"
                                                aria-labelledby="exampleModalLabel"
                                                aria-hidden="true">
                                                <div className="modal-dialog  modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header form-mdlheadr">
                                                            <h4 className="modal-title mdl-heading">EDIT</h4>
                                                            <button type="button" id={'editModalAchievement' + index} className="close modalclose-btn" data-dismiss="modal">&times;</button>
                                                        </div>
                                                        <div className="modal-body">
                                                            {verify ? <StudentAchievement id={awards.id} GetStudentAchievement={GetProfiles} setShow3={setShow3} show={show3} modalid={`editModalAchievement${index}`} /> : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : ""}
                            </div>
                        </div>

                        <div className="modal fade xy-form"
                            id="exampleModalAchievement"
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div className="modal-dialog  modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header form-mdlheadr">
                                        <h4 className="modal-title mdl-heading">EDIT</h4>
                                        <button type="button" id={'exampleModalAchievement'} className="close modalclose-btn" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div className="modal-body">
                                        {verify ? <StudentAchievement id2="0" setShow3={setShow3} GetStudentAchievement={GetProfiles} show={show3} modalid={`exampleModalAchievement`} /> : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section >
        {loader}
    </>
    )
}
export default StudentsProfile;