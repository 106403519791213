import React, { useState, useEffect } from "react";
import Common from "../../Utility/Common";
import DefaultModel from "../../Model/Admin/CourseModel";
import service from "../../Services/AdminUtilityService";
import ServiceAPI from "../../Utility/ServiceList";
import FormHeader from "./FormHeader";
import AdminHeader from './AdminHeader'
import AdminNav from "./AdminNav";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useLoader from '../Auth/Loader';
import { useHistory } from "react-router-dom";

toast.configure()

const Course = (props) => {
  const history = useHistory();
  Common.ValidAdminLogin();
  // const id = props.match.params.id;
  const id = props.location.search.split("=")[1]
  const [model, setModel] = useState(DefaultModel);
  const [industry, setIndustry] = useState([]);
  const services = new service();
  const [qualificationType, setQualificationType] = useState([]);
  const [loader, showLoader, hideLoader] = useLoader();
  const [border, setBorder] = useState("");
  const [pageModel, setPageModel] = useState({
    "pageNum": 1,
    "pageSize": 50,
    "isActive": true
  });

  useEffect(() => {
    if (id) {
      GetById(id);
    }
    BindIndustry();
    QualificationGetAll();
  }, []);

  const QualificationGetAll = async () => {
    var res = await services.PostGetAll(ServiceAPI.QualificationGetAllApi, pageModel);
    const data = await res.result;
    setQualificationType(data)
  }
  const BindIndustry = async () => {
    var res = await services.PostGetAll(ServiceAPI.IndustryGetAllApi, pageModel);
    //const data = await res.result.filter((data) => data.active !== false);
    const data = await res.result;
    await setIndustry(data);
  }

  const ChangeHandler = (e) => {
    let value = {};
    if (e.target.type === "checkbox") {
      value[e.target.id] = e.target.checked;
    }
    else if (e.target.type === "select-one") {
      value[e.target.id] = e.target.options[e.target.selectedIndex].value;
    }
    else {
      value[e.target.id] = e.target.value;
    }
    setModel({ ...model, ...value });
    setBorder({ ...border, [e.target.id]: "" })
  };
  const Create = async (e) => {
    e.preventDefault();
    var response = null;
    if (valid()) {
      showLoader();
      if (model.id === null) {
        response = await services.Create(ServiceAPI.CourseCreateApi, model);
      } else {
        response = await services.Update(ServiceAPI.CourseUpdateApi, { ...model, "qualifications": "", "industrys": "" });
      }
      if (response && response.message === "Success") {
        if (model.id === null) {
          toast.success('Course Added Successfully', { theme: "colored", autoClose: 2000 })
        } else {
          toast.success('Course Updated Successfully', { theme: "colored", autoClose: 2000 })
        }
        setTimeout(() => {
          history.push("/courselist");
          setModel(DefaultModel);
        }, 1000);

      } else {
        hideLoader();
      }
    }
  };

  const GetById = async (id) => {
    showLoader()
    var res = await services.GetById(ServiceAPI.CourseGetApi, id);
    hideLoader()
    const banner = await res.result;
    setModel(banner);
  };

  const valid = () => {
    let industryId = "";
    let name = "";
    let qualificationId = "";
    // function isValidName(url) {
    //   let regEx = /^[a-zA-Z0-9_ ]*$/
    //   return regEx.test(url);
    // }
    if (!Boolean(model.name)) {
      toast.error("Valid name required", { theme: "colored", toastId: 'error1' });
      name = "borderred";
    }
    if (!Boolean(model.industryId) || model.industryId === "0") {
      toast.error("Industry is required.", { theme: "colored", toastId: 'error2' });
      industryId = "borderred";
    }
    if (!Boolean(model.qualificationId) || model.qualificationId === "0") {
      toast.error("Valid Qualification required.", { theme: "colored", toastId: 'error3' });
      qualificationId = "borderred";
    }

    if (industryId || name || qualificationId) {
      setBorder({ industryId, name, qualificationId });
      return false;
    }
    return true;
  };
  const back = () => {
    history.push("/courselist");
  }
  return (
    <>
      <AdminHeader heading="" subheading="Manage Course" />
      <form onSubmit={Create} autoComplete="off">
        <div className="theme-container-admin master-admin">
          <AdminNav course="active" scroll="scroll-tabs-scroll"></AdminNav>
          <div className="row">
            <div className="col-md-6 mx-auto detail-box form-sec">
              <div className="row m-0 align-items-center">

                <div className="col-md-12">
                  <div className="label">Industry<span className="required">*</span></div>
                  <select className={border.industryId ? border.industryId : "xu-input"} id="industryId" value={model.industryId} onChange={(e) => ChangeHandler(e)}>
                    <option value="0">Select</option>
                    {
                      industry.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="col-md-12">
                  <div className="label">Qualification<span className="required">*</span></div>
                  <select className={border.qualificationId ? border.qualificationId : "xu-input"} id="qualificationId" value={model.qualificationId} onChange={(e) => ChangeHandler(e)}>
                    <option value="0">Select</option>
                    {
                      qualificationType.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="col-md-12">
                  <div className="label">Name<span className="required">*</span></div>
                  <input
                    type="text"
                    id="name"
                    className={border.name ? border.name : "xu-input"}
                    placeholder="Name"
                    value={model.name}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                </div>

                {/* <div className="col-md-12">
                  <div className="label">Image</div>
                  <input
                    type="text"
                    id="image"
                    className="xu-input"
                    placeholder="Image"
                    value={model.image}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                </div> */}

                <div className="col-md-12">
                  <div className="label">Active</div>
                  <label className="switch">
                    <input
                      className=""
                      type="checkbox"
                      id="active"
                      checked={model.active}
                      onChange={(e) => ChangeHandler(e)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="col-md-12">
                  <button
                    className="btn btn-outline s-btn pr"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='back-icon' onClick={back}></div>
        </div>
        <div></div>
      </form>
      {loader}
    </>
  );
};
export default Course;
