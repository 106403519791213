import React, { useState } from 'react'
import { setDefaultLocale } from 'react-datepicker'
import Texteditor from './Texteditor'
const UpdateStaticPages = () => {
    const [list, setList] = useState([1, 3, 4, 5, 6,])
    const [data, setData] = useState()
    const editfunction = (e) => {
        setData("<div  className='text' onClick={(e) => editfunction('text')}> vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati corrupti quos dolores et quas molestias excepturi sint occaecat</div >")
    }
    return (
        <section className="section">
            <div className="stat-sec ">
                <div className=" event-sec ">
                    <div className="event-cards card-sec content theme-container">
                        <div className="title line " >events</div>
                        <div className="column-row column-v3">
                            <div className="card-e" data-toggle="modal" data-target="#register" >
                                <div className="card-img">
                                    <img onClick={(e) => editfunction("text")} src="./images/event1.png" alt="" />
                                </div>
                                <div className="card-text">
                                    <div className="display-inline">
                                        <div className="sub-title">lorem ipsum</div>
                                        <div className="date sub-title">2021-08-17</div>
                                    </div>
                                    <div id="text" className="text" onClick={(e) => editfunction("text")}>
                                        vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati corrupti quos dolores et quas molestias excepturi sint occaecati
                                    </div>
                                </div>
                            </div>
                            <div className="modal fade xy-form"
                                id="register"
                                tabIndex="10"
                                role="dialog"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered modal-lg">
                                    <div className="modal-content" >
                                        <div className="modal-header form-mdlheadr">
                                            <h4 className="modal-title mdl-heading">Edit</h4>
                                            <button type="button" className="close modalclose-btn " data-dismiss="modal">&times;</button>
                                        </div>
                                        <div className="modal-body">
                                            <Texteditor data={data} />
                                            <button className='btn btn-outline float-right mt-2 m-1'>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-e">
                                <div className="card-img">
                                    <img src="./images/event2.png" alt="" />
                                </div>
                                <div className="card-text">
                                    <div className="display-inline">
                                        <div className="sub-title">lorem ipsum</div>
                                        <div className="date sub-title">2021-08-17</div>
                                    </div>
                                    <div className="text">
                                        vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati corrupti quos dolores et quas molestias excepturi sint occaecati
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default UpdateStaticPages