//import React from 'react'
import baseService from './BaseService'
import AdminBaseService from './AdminBaseService'
import ServiceAPI from '../Utility/ServiceList'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { environment } from "../environment";


toast.configure()
const headers = {
    "Content-Type": "application/json",
    /// "Accept":"application/json"  
}
function joinURL(baseURL, url) {
    return `${baseURL}/${url}`;
}
class LoginService {
    constructor() {
        this.service = new baseService();
        this.adminservice = new AdminBaseService();
        this.domain = environment.baseURL;

    }
    async login(data) {
        console.log('LoginService->' + data);
        return new Promise((resolve, reject) => {
            this.service.post(ServiceAPI.loginApi, false, data).then(resp => {
                if (resp.succeeded !== undefined) {
                    localStorage.setItem("reftoken", resp.data.refreshToken);
                    localStorage.setItem("token", resp.data.jwToken);
                    localStorage.setItem("userid", resp.data.id);
                    localStorage.setItem("username", resp.data.userName);
                    localStorage.setItem("loginid", resp.data.loginId);
                    localStorage.setItem("email", resp.data.email);
                    localStorage.setItem("usertype", resp.data.userType);
                    localStorage.setItem("profileImage", resp.data.profileImage);
                    localStorage.setItem("firstName", resp.data.firstName);
                    localStorage.setItem("lastName", resp.data.lastName); localStorage.setItem("instagramLink", resp.data.instagramLink);
                    localStorage.setItem("facebookLink", resp.data.facebookLink);
                    localStorage.setItem("twitterLink", resp.data.twitterLink);
                    var today = new Date()
                    var time = today.getHours() + ':' + today.getMinutes();
                    localStorage.setItem("time", time)
                    resolve("success");

                    localStorage.setItem("currentTalkjsUser", JSON.stringify(resp.data));
                    // return 'success';
                } else {
                    toast.error(resp.ErrorMessage, { theme: "colored", toastId: 'error1' });
                }
                resolve(resp);
            })
        });
    }
    async Adminlogin(data) {
        return new Promise((resolve, reject) => {
            this.adminservice.post(ServiceAPI.loginApi, false, data).then(resp => {
                if (resp.succeeded !== undefined) {
                    localStorage.setItem("admintoken", resp.data.jwToken);
                    localStorage.setItem("adminuserid", resp.data.id);
                    localStorage.setItem("adminusername", resp.data.userName);
                    localStorage.setItem("adminloginid", resp.data.loginId);
                    localStorage.setItem("adminemail", resp.data.email);
                    localStorage.setItem("adminusertype", resp.data.userType);
                    localStorage.setItem("adminprofileimage", resp.data.profileImage);
                    localStorage.setItem("adminfirstName", resp.data.firstName);
                    localStorage.setItem("adminlastName", resp.data.lastName);
                    resolve("success");
                    //for chat
                    localStorage.setItem("admincurrentTalkjsUser", JSON.stringify(resp.data));
                    // return 'success';
                } else {
                    toast.error(resp.ErrorMessage, { theme: "colored", toastId: 'error1' });
                }
                resolve(resp);
            })
        });
    }


}

export default LoginService