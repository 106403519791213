import React, { useEffect, useState } from 'react'
import ReactQuill from "react-quill";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "react-quill/dist/quill.snow.css";
import "../../../../style.css";

function Texteditor(props) {
  const [state, setState] = useState({ value: '' });
  useEffect(() => {
    var values = props.data

    setState({ value: values })
  }, [props.data])

  function handleValue(value) {
    const parts = value.split(' ');
    if (parts.length <= 500) {
      return value;
    }
    return parts.slice(0, 500).join(' ');
  }

  const handleChange = (value) => {

    const wordCount = value.trim().split(/\s+/).filter(Boolean).length;

    if (wordCount > 500) {
      toast.error("Maximum word limit of 500 reached.", {
        theme: "colored",
        autoClose: 2000,
      });
      setState({ value: handleValue(value) })
    } else {

      setState({ value });
      props.ChangeHandler(value === "<p><br></p>" ? "" : value, "des");
    }
  };

  return (
    <div className="text-editor job-editor" >
      <ReactQuill
        theme="snow"
        value={state.value}
        onChange={(value) => handleChange(value)}
        placeholder={"description"}
      />
    </div>
  )
}

export default Texteditor