import React, { useState, useEffect, useMemo } from "react";
import service from "../../../Services/UtilityService";
import ServiceAPI from "../../../Utility/ServiceList";
import TableView from "../../../Utility/TableView";
import AdminNav from "./CompanyNav";
import AdminHeader from '../../Common/AdminHeader'
import Common from "../../../Utility/Common";
import TableList from "../../../Utility/TableList"
import { ImCross } from "react-icons/im";
import { toast } from "react-toastify";
import useLoader from "../../Auth/Loader"
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import $ from 'jquery'

const CompanyAppliedEvents = (props) => {
    const id = props.location.search.split("=")[1]
    localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
    const [itemlist, setItemList] = useState([]);
    const [model, setModel] = useState();
    const [hasMore, setHasMore] = useState(true);
    const [loader, showLoader, hideLoader] = useLoader();
    const [pageModel, setPageModel] = useState({
        "pageNum": 1,
        "pageSize": 10,
        "isActive": true,
        "id": id
    });
    var services = new service();

    const data = itemlist;
    const Delete = (id, index) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="xy-form">
                        <div className="mdl-heading">Delete Record</div>
                        <div className="desc">Are you sure to delete it?</div>
                        <button className="btn btn-outline s-btn pr" onClick={onClose}>No</button>
                        <button className="btn btn-outline s-btn pr"
                            onClick={async () => {
                                let item = itemlist.filter((data) => data.id != id);
                                await services.Delete(ServiceAPI.DeleteCompanyEventRegistrationApi, id).then(async (res) => {
                                    if (res.message == "Success", { autoclose: 2000, }) {
                                        toast.success("Deleted Successfully", { theme: "colored", autoClose: 2000, toastId: 'success1' })
                                    }
                                    var res = await services.Create(ServiceAPI.GetCompanyEventRegistrationListApi, pageModel);
                                    const model = await res.metaData;
                                    const data = await res.result;
                                    if (model.totalPages > parseInt((index / 10) + 1)) {
                                        if (itemlist.length === 10 * model.currentPage && data.length > 9) {
                                            setItemList([...item, data[data.length - 1]]);
                                        } else {
                                            setItemList(item)
                                        }
                                    } else {
                                        setItemList(item)
                                    }
                                })
                                onClose();
                            }}
                        >
                            Yes
                        </button>
                    </div>
                );
            }
        });
    };
    const columns = useMemo(
        () => [
            {
                Header: 'First Name',
                accessor: 'firstName',
            }, {
                Header: 'Last Name',
                accessor: 'lastName',
            },
            {
                Header: 'Contact',
                accessor: 'contact',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: "Delete",
                accessor: "delete",
                Cell: ({ row }) => {
                    const itemID = data.map((item, index) => item.id);
                    return (

                        <label>
                            <a
                                onClick={() => Delete(itemID[row.index], row.index)}
                                className="fa fa-trash delete"
                                aria-hidden="true"
                            />
                        </label>

                    );
                },
            }

        ], [data])

    const fetchMoreData = () => {
        if (model > itemlist.length) {
            GetAll()
        } else {
            setHasMore(false)
        }
    };
    async function GetAll() {
        $('.modal-backdrop').removeClass('show ');
        $('.modal').removeClass('show');
        $('body').toggleClass('p-0');
        $('body').removeClass('modal-open');
        showLoader()
        var res = await services.Create(ServiceAPI.GetCompanyEventRegistrationListApi, pageModel);
        hideLoader()
        const model = await res.metaData;
        setModel(model.totalCount);
        const data = await res.result;
        setItemList([...itemlist, ...data]);
        setPageModel({
            "pageNum": pageModel.pageNum + 1,
            "pageSize": 10,
            "isActive": false,
            "id": id
        })
    }
    useEffect(() => {
        GetAll();
    }, []);

    useEffect(() => {
    }, [pageModel]);

    const newdata = useMemo(() => itemlist, [itemlist]);
    return (
        <>
            <AdminHeader heading="" subheading="Applied Event" />
            <form>
                <div className="theme-container-admin min-h">
                    <AdminNav appliedevent="active"></AdminNav>
                    <div className="table-sec">
                        <div className="add-option">
                            <div className="title"></div>

                        </div>
                        <TableList
                            data={newdata}
                            columns={columns}
                            update={fetchMoreData}
                            hasMore={hasMore}
                            newClass={"events-mng-table"}
                        />
                    </div>
                </div>
            </form>
            {loader}
        </>
    )
}

export default CompanyAppliedEvents