import FormHeader from "./FormHeader";
import AdminNav from "./AdminNav";
import AdminHeader from "./AdminHeader";
import environment from '../../environment'
const AdminDashBoard = () => {

  const value = localStorage.getItem("adminusername");
  return (
    <>
      <AdminHeader heading="" subheading="Dashboard" />
      <div className={value == 'admin' ? "theme-container-admin min-h master-admin" : "theme-container-admin min-h"} >
        <AdminNav dashboard="active"></AdminNav>
        {/* <FormHeader heading="Admin" subheading="DashBoard" back="" /> */}
        <div className="text-center mt-3 sign-heading ad">Welcome To {value == 'admin' ? 'Admin' : value}</div>
      </div>
    </>
  )

}

export default AdminDashBoard;