import React, { useState, useEffect, useMemo } from "react";
import service from "../../../Services/UtilityService";
import ServiceAPI from "../../../Utility/ServiceList";
import AdminNav from "./StudentNav";
import AdminHeader from '../../Common/AdminHeader'
import Common from "../../../Utility/Common";
import TableList from "../../../Utility/TableList"
import useLoader from "../../Auth/Loader"
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useHistory } from "react-router-dom";
import $ from "jquery";

const StudentPortFolioList = () => {
  const history = useHistory();
  localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
  const [itemlist, setItemList] = useState([]);
  const [model, setModel] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [pageModel, setPageModel] = useState({
    "pageNum": 1,
    "pageSize": 10
  });
  const [loader, showLoader, hideLoader] = useLoader();
  var services = new service();
  const data = itemlist;

  const columns = useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',
      }, {
        Header: "Status",
        accessor: "active",
        Cell: ({ row }) => {
          const val = data.map((item, index) => item.active);
          return (
            <label className="switch">
              <input
                id="active"
                checked={val[row.index]}
                type="checkbox"
                onChange={(e) => ChangeHandler(e, row.index)}
              />
              <span className="slider round"></span>
            </label>
          )
        },
      }, {
        Header: "Edit",
        accessor: "edit",
        Cell: ({ row }) => {
          const itemID = data.map((item, index) => item.id);
          return (
            <label>
              <a
                onClick={() => editfunction(itemID[row.index])}
                className="fa fa-pencil edit"
                aria-hidden="true"
              />
            </label>
          );
        },
      }, {
        Header: "Delete",
        accessor: "delete",
        Cell: ({ row }) => {
          const itemID = data.map((item, index) => item.id);
          return (

            <label>
              <a
                onClick={() => Delete(itemID[row.index], row.index)}
                className="fa fa-trash delete"
                aria-hidden="true"
              />
            </label>

          );
        },
      }

    ], [data])

  const ChangeHandler = async (e, index) => {
    let newArr = itemlist.map((item, i) => {
      if (index == i) {
        return { ...item, active: e.target.checked };
      } else {
        return item;
      }
    });
    setItemList(newArr)
    var response = null;
    response = await services.Update(ServiceAPI.StudentPortFolioUpdateApi, newArr[index]);
  };

  useEffect(() => {
    GetAll();
  }, []);

  useEffect(() => {
  }, [pageModel]);

  async function GetAll() {
    $('.modal-backdrop').removeClass('show ');
    $('.modal').removeClass('show');
    $('body').toggleClass('p-0');
    $('body').removeClass('modal-open');
    showLoader()
    var res = await services.GetDataById(ServiceAPI.StudentPortFolioGetAllApi, Common.LoginId(), pageModel);
    hideLoader()
    const model = await res.metaData;
    setModel(model.totalCount);
    const data = await res.result;
    setItemList([...itemlist, ...data]);
    setPageModel({
      "pageNum": pageModel.pageNum + 1,
      "pageSize": 10,
      "isActive": false
    })
  }

  const editfunction = (id) => {
    history.push("/studentportfolio?id=" + id);
  };
  const Delete = (id, index) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="xy-form">
            <div className="mdl-heading">Delete Record</div>
            <div className="desc">Are you sure to delete it?</div>
            <button className="btn btn-outline s-btn pr" onClick={onClose}>No</button>
            <button className="btn btn-outline s-btn pr"
              onClick={async () => {
                let item = itemlist.filter((data) => data.id != id);
                await services.Delete(ServiceAPI.StudentPortFolioDeleteApi, id).then(async () => {
                  var res = await services.GetDataById(ServiceAPI.StudentPortFolioGetAllApi, Common.LoginId(), {
                    "pageNum": parseInt((item.length / 10) + 1),
                    "pageSize": 10,
                    "isActive": false
                  });
                  const model = await res.metaData;
                  const data = await res.result;
                  if (model.totalPages > parseInt((index / 10) + 1)) {
                    if (itemlist.length === 10 * model.currentPage && data.length > 9) {
                      setItemList([...item, data[data.length - 1]]);
                    } else {
                      setItemList(item)
                    }
                  } else {
                    setItemList(item)
                  }
                })
                onClose();
              }}
            >
              Yes
            </button>
          </div>
        );
      }
    });
  };


  const fetchMoreData = () => {
    if (model > itemlist.length) {
      GetAll()
    } else {
      setHasMore(false)
    }
  };
  const newdata = useMemo(() => itemlist, [itemlist]);
  const redirectlist = (link) => {
    history.push(link)
  }
  return (
    <>
      <AdminHeader heading="" subheading="Portfolio List" />
      <form>
        <div className="theme-container-admin min-h">
          <AdminNav portfolio="active"></AdminNav>
          <div className="table-sec">
            <div className="add-option">
              <div className="title"></div>
              <a className="icon" onClick={() => redirectlist("/studentportfolio")}>
                <i className="fa fa-plus" aria-hidden="true"></i>
              </a>
            </div>
            <TableList
              data={newdata}
              columns={columns}
              update={fetchMoreData}
              hasMore={hasMore}
            />
          </div>
        </div>
      </form>
      {loader}
    </>
  );

}
export default StudentPortFolioList