import React, { useState, useEffect } from "react";
import ServiceAPI from "../../Utility/ServiceList";
import service from '../../Services/UtilityService';
import AlphabetList from 'react-alphabet-list';
import { Tag } from "antd";
import $ from "jquery";
import "antd/dist/antd.css";

import { toast } from "react-toastify";
import Multiselect from "../Auth/multiselect-dropdown";
import Select from 'react-select'
import useLoader from '../Auth/Loader'
import { useHistory } from "react-router-dom";

toast.configure();
const CompanyList = () => {
  const history = useHistory();
  const style = {
    paddingRight: "0px",
    color: "black"
  };
  const profile = (id, userId) => {
    localStorage.setItem("cuserid", userId, id)
    history.push('companysprofile?id=' + id)
  }
  var activetest = false; var activetest1 = false; var activetest2 = false; var activetest3 = false; var activetest4 = false; var activetest5 = false
  const [companylist, setCompanyList] = useState([]);
  const [alldata, setAllData] = useState([]);
  const [coutnrylist, setCountryList] = useState([]);
  const [industrylist, setIndustryList] = useState();
  const [loader, showLoader, hideLoader] = useLoader();
  const [achiever, setAchiever] = useState(false)
  const [statelist, setStateList] = useState([]);
  const [citylist, setCityList] = useState([]);
  const [clear, setClear] = useState(true)
  const [count, setCount] = useState()
  const [count1, setCount1] = useState()
  const [count2, setCount2] = useState()
  const [count3, setCount3] = useState()
  const [count4, setCount4] = useState()
  const [showcount, setShowCount] = useState(false);
  const [suggestionData, setSuggestionData] = useState([]);
  const [suggestionvalue, setSuggestionValue] = useState()
  const [size, setSize] = useState({
    isShow: true,
    "sizeOfEmployeesMin": 0,
    "sizeOfEmployeesMax": 0,
  })
  const [locationData, setLocationData] = useState(
    {
      "country": "",
      "state": "",
      "city": ""
    }
  );
  const [filtermodel, setFilterModel] = useState(
    {
      "country": "",
      "city": "",
      "state": "",
      "industry": [],
      "graduateYear": [],
      "nqfl": [],
      "qualification": [],
      "studyType": [],
      "sizeOfEmployeesMin": 0,
      "sizeOfEmployeesMax": "",
      "topAchiever": false,
      "isHiringActive": false, "searchValue": ""
    }
  );
  var services = new service();
  localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
  useEffect(() => {
    if (activetest == false) {
      $("#search-input").on("click", () => {
        $(".dropdown1-content").removeClass("active")
        $(".alpha-list").removeClass('filter-expand')
        $(".cards-list").removeClass('filter-expand')
        $(".dropdown1 .btn-outline").removeClass("active")
        activetest = false;
      })
    }
    GetAll();
    GetIndustry();
    getLocation();
  }, []);
  const fetchCompanyData = (item) => {
    const url = alldata.map((data) => {
      if (data.id === item.split(",")[1]) {
        return [data.logo, data.coverImage, data.employee, data.city, data.industry, data.isDefaultLogo, data.country]
      }
    }
    )
    return url.join('').split(',')
  }
  async function GetAll() {
    $('.modal-backdrop').removeClass('show ');
    $('.modal').removeClass('show');
    $('body').toggleClass('p-0');
    $('body').removeClass('modal-open');
    showLoader()
    var res = await services.Create(ServiceAPI.CompanyFilterApi, filtermodel);
    hideLoader()
    const data1 = await res.result;
    setTimeout(() => {
      setAllData(data1);
      setCompanyList(data1?.map?.((data) => `${data.name},${data.id},${data.userId}`))
    }, 10);
  }
  const GetIndustry = async () => {
    var res = await services.PostAnonymous(ServiceAPI.IndustryGetAllApi, {
      "pageNum": 1,
      "pageSize": 100,
      "isActive": true
    });
    const industryData = await res.result.map((item) => {
      return {
        name: item.name,
        value: item.name,
        id: item.id
      }
    });
    industryData.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    setIndustryList(industryData)
  };

  const getLocation = async () => {
    var res = await services.GetAll(ServiceAPI.getLocationCountryApi);
    const data1 = await res.result.map((item) => {
      return { value: item, label: item, type: "country" }
    });
    setCountryList([{ value: 'select', label: 'select', type: "country" }, ...data1])
  }
  const GetState = async (state) => {
    var res = await services.GetLocationById(ServiceAPI.getLocationStateApi, state, "country");
    const data1 = await res.result.map((item) => {
      return { value: item, label: item, type: "state" }
    });
    setStateList([{ value: 'select', label: 'select', type: "state" }, ...data1])

  }
  const GetCity = async (city) => {
    var res = await services.GetLocationById(ServiceAPI.getLocationCityApi, city, "state");
    const data1 = await res.result.map((item) => {
      return { value: item, label: item, type: "city" }
    });
    setCityList([{ value: 'select', label: 'select', type: "city" }, ...data1])

  }
  const filterData = async (filtermodels) => {
    showLoader()
    setFilterModel({ ...filtermodels })
    var res = await services.Create(ServiceAPI.CompanyFilterApi, filtermodels);
    hideLoader()
    if (res.message == "Success") {
      const data1 = await res.result;
      setAllData(data1);
      setTimeout(() => {

        setCompanyList([])
        setCompanyList(data1.map((data) => `${data.name},${data.id},${data.userId}`));
      }, 10);
    }
    else {
      setCompanyList([])
    }

  }

  const AddValue = async (e, type) => {
    if (e.type === "country") {
      const value = e.value
      if (value !== "select...") {
        setStateList([])
        setCityList([])
        GetState(value)
        setFilterModel({ ...filtermodel, "country": value })
        setLocationData({ ...locationData, "country": value });
        filterData({ ...filtermodel, "country": value })
      }
      else {
        setFilterModel({ ...filtermodel, ...locationData, "state": "" })
        setLocationData({ ...locationData, "state": '' });
        setStateList([])
        setCityList([])
        filterData({ ...filtermodel, ...locationData, "country": '', "state": '', "city": '' })
      }
    }
    else if (e.type === "state") {
      const value = e.value
      if (value !== "select...") {
        setCityList([])
        GetCity(value);
        setFilterModel({ ...filtermodel, ...filtermodel.location, "state": value })
        setLocationData({ ...locationData, "state": value });
        filterData({ ...filtermodel, "state": value, })
      }
      else {
        setFilterModel({ ...filtermodel, ...locationData, "state": '' })
        setLocationData({ ...locationData, "state": '' });
        setCityList([])
        filterData({ ...filtermodel, "state": '', "city": '' })
      }
    }
    else if (e.type === "city") {
      const value = e.value
      if (value !== "select...") {
        setFilterModel({ ...filtermodel, ...filtermodel.location, "city": value })
        setLocationData({ ...locationData, "city": value });
        filterData({ ...filtermodel, ...filtermodel.location, "city": value })
      }
      else {
        setFilterModel({ ...filtermodel, ...filtermodel.location, "city": '' })
        setLocationData({ ...locationData, "city": "" });
        filterData({ ...filtermodel, ...filtermodel.location, "city": '' })
      }
    }
    else if (type == "size1") {
      filterData({ ...filtermodel, "sizeOfEmployees": e.target.value ? parseInt(e.target.value) : 0 })
    }

  }

  const setDropdown = () => {
    if (activetest == false) {
      setSuggestionData([])
      if ($(".dropdown1-content").hasClass("active")) {
        $(".dropdown1-content").removeClass("active")
        $(".alpha-list").removeClass('filter-expand')
        $(".cards-list").removeClass('filter-expand')
        $(".dropdown1 .btn-outline").removeClass("active")
        if (count || count1 || count2 || count3 || statelist.length !== 0 || size.sizeOfEmployeesMax || achiever == true) {
          $(".dropdown1 .btn-outline").addClass("active")
        }

      }
      else {
        $(".dropdown1-content").addClass("active")
        $(".dropdown1 .btn-outline").addClass("active")
        $(".alpha-list").addClass('filter-expand')
        $(".cards-list").addClass('filter-expand')
        activetest = true;
      }
    }
    else {
      $(".dropdown1-content").removeClass("active")
      $(".alpha-list").removeClass('filter-expand')
      $(".cards-list").removeClass('filter-expand')
      $(".dropdown1 .btn-outline").removeClass("active")
      if (count || count1 || count2 || count3 || statelist.length !== 0 || size.sizeOfEmployeesMax || achiever == true) {
        $(".dropdown1 .btn-outline").addClass("active")
      }
      activetest = false;
    }
  }
  const setDropdown1 = () => {
    if (activetest1 == false) {
      $(".ind-menu").addClass("show")
      $("#dropdownMenu2").addClass("active")
      $(".location-menu").removeClass("show")
      $("#dropdownMenu3").removeClass("active")
      $(".qual-menu").removeClass("show")
      $("#dropdownMenu4").removeClass("active")
      $(".top-menu").removeClass("show")
      $("#dropdownMenu5").removeClass("active")
      $(".cat-menu").removeClass("show")
      $("#dropdownMenu6").removeClass("active")
      activetest1 = true; activetest2 = false; activetest3 = false; activetest4 = false; activetest5 = false
    }
    else {
      $(".ind-menu").removeClass("show")
      $("#dropdownMenu2").removeClass("active")
      activetest1 = false;
    }
  }
  const setDropdown2 = () => {
    if (activetest2 == false) {
      $(".location-menu").addClass("show")
      $("#dropdownMenu3").addClass("active")
      $(".ind-menu").removeClass("show")
      $("#dropdownMenu2").removeClass("active")
      $(".qual-menu").removeClass("show")
      $("#dropdownMenu4").removeClass("active")
      $(".top-menu").removeClass("show")
      $("#dropdownMenu5").removeClass("active")
      $(".cat-menu").removeClass("show")
      $("#dropdownMenu6").removeClass("active")
      activetest2 = true; activetest1 = false; activetest3 = false; activetest4 = false; activetest5 = false
    }
    else {
      $(".location-menu").removeClass("show")
      $("#dropdownMenu3").removeClass("active")
      activetest2 = false;
    }
  }
  const setDropdown3 = () => {
    if (activetest3 == false) {
      $(".qual-menu").addClass("show")
      $("#dropdownMenu4").addClass("active")
      $(".location-menu").removeClass("show")
      $("#dropdownMenu3").removeClass("active")
      $(".ind-menu").removeClass("show")
      $("#dropdownMenu2").removeClass("active")
      $(".top-menu").removeClass("show")
      $("#dropdownMenu5").removeClass("active")
      $(".cat-menu").removeClass("show")
      $("#dropdownMenu6").removeClass("active")
      activetest3 = true; activetest1 = false; activetest2 = false; activetest4 = false; activetest5 = false
    }
    else {
      $(".qual-menu").removeClass("show")
      $("#dropdownMenu4").removeClass("active")
      activetest3 = false;
    }
  }
  const setDropdown4 = () => {
    if (activetest4 == false) {
      $(".top-menu").addClass("show")
      $("#dropdownMenu5").addClass("active")
      $(".qual-menu").removeClass("show")
      $("#dropdownMenu4").removeClass("active")
      $(".location-menu").removeClass("show")
      $("#dropdownMenu3").removeClass("active")
      $(".ind-menu").removeClass("show")
      $("#dropdownMenu2").removeClass("active")
      $(".cat-menu").removeClass("show")
      $("#dropdownMenu6").removeClass("active")
      activetest4 = true; activetest1 = false; activetest2 = false; activetest3 = false; activetest5 = false
    }
    else {
      $(".top-menu").removeClass("show")
      $("#dropdownMenu5").removeClass("active")
      activetest4 = false;
    }
  }

  const setFilter = () => {
    $(".dropdown1-content").removeClass("active")
    $(".alpha-list").removeClass('filter-expand')
    $(".cards-list").removeClass('filter-expand')
    $(".dropdown1 .btn-outline").removeClass("active")
    if (count || count1 || count2 || count3 || statelist.length !== 0 || size.sizeOfEmployeesMax || achiever == true) {
      $(".dropdown1 .btn-outline").addClass("active")
    }
    $(".loc-menu").removeClass("show")
    $("#dropdownMenu2").removeClass("active")
    $("#dropdownMenu3").removeClass("active")
    $("#dropdownMenu4").removeClass("active")
    $("#dropdownMenu5").removeClass("active")
  }
  const setIndustry = (params) => {
    const s = [...params.map((item) => industrylist.filter((items) => item == items.name)).map((item) => {
      return item[0].id
    })]
    filterData({ ...filtermodel, "industry": s })
    setCount(params.length)
  }



  const setTopAchiever = () => {
    if (achiever == false) {
      filterData({ ...filtermodel, "isHiringActive": true })
      setAchiever(true)
    }
    else {
      filterData({ ...filtermodel, "isHiringActive": false })
      setAchiever(false)
    }
  }
  const resetLocation = (type) => {
    setFilterModel({ ...filtermodel, ...locationData, "state": '', "city": '' })
    setShowCount(true)
    setCountryList([])
    setStateList([])
    setCityList([])
    filterData({ ...filtermodel, "country": '', "state": '', "city": '' })
    setTimeout(() => {
      setShowCount(false)
    }, 100);
    getLocation()
  }
  const apply = (params) => {

    if (`${params.value.toString()}` == "100+") {
      setSize({
        ...size, sizeOfEmployeesMin: "0",
        sizeOfEmployeesMax: params.value,

      })
      filterData({
        ...filtermodel,
        sizeOfEmployeesMin: "0",
        sizeOfEmployeesMax: params.value
      })
    }
    else {

      setSize({
        ...size, sizeOfEmployeesMin: "0",
        sizeOfEmployeesMax: params.value,
      })
      filterData({
        ...filtermodel,
        sizeOfEmployeesMin: "0",
        sizeOfEmployeesMax: params.value
      })
    }
  }
  const resetEmployee = (type) => {
    setSize({ ...size, "sizeOfEmployeesMin": 0, "sizeOfEmployeesMax": '', isShow: false })
    setTimeout(() => {
      setSize({ ...size, "sizeOfEmployeesMin": 0, "sizeOfEmployeesMax": '', isShow: true })
    }, 10);
    filterData({ ...filtermodel, "sizeOfEmployeesMin": 0, "sizeOfEmployeesMax": '', })
  }

  const clearall = () => {
    setIndustryList();
    setClear(false); setAchiever(false);
    filterData({
      ...filtermodel,
      "country": "", "city": "", "state": "", "industry": [], "graduateYear": [], "nqfl": [], "qualification": [],
      "studyType": [], "topAchiever": false, "isHiringActive": false, "sizeOfEmployeesMin": 0, "sizeOfEmployeesMax": ''
    })
    GetIndustry(); getLocation();
    resetLocation(); setSize({ ...size, isShow: false, "sizeOfEmployeesMin": 0, "sizeOfEmployeesMax": '' })

    filterData({
      ...filtermodel,
      "country": "", "city": "", "state": "", "industry": [], "graduateYear": [], "nqfl": [], "qualification": [],
      "studyType": [], "topAchiever": false, "isHiringActive": false, "sizeOfEmployeesMin": 0, "sizeOfEmployeesMax": ''
    })
    setCount(); setCount1(); setCount2(); setCount3(); setCount4()
    setTimeout(() => {
      setClear(true)
      setSize({ ...size, isShow: true, "sizeOfEmployeesMin": 0, "sizeOfEmployeesMax": '' })
    }, 100);
  }
  const ApplySearch = async (searchValue, type) => {
    if (type) {
      setSuggestionData([])
    }
    showLoader()
    setSuggestionValue(searchValue)
    setFilterModel({ ...filtermodel, "searchValue": searchValue && searchValue.length >= 3 ? searchValue : "" })

    var res = await services.Create(ServiceAPI.CompanyFilterApi, {
      ...filtermodel, "searchValue": searchValue && searchValue.length >= 3 ? searchValue : ""
    });
    hideLoader()
    if (res.message == "Success") {
      const data1 = await res.result;
      setAllData(data1);
      setTimeout(() => {
        setCompanyList([])
        setCompanyList(data1.map((data) => `${data.name},${data.id},${data.userId}`));
      }, 10);
      // console.log("fliterdata1", data1)
    }
    else {
      setCompanyList([])
    }
  }
  const getSuggestionData = async (e) => {
    $(".dropdown1-content").removeClass("active")
    $(".alpha-list").removeClass('filter-expand')
    $(".cards-list").removeClass('filter-expand')
    $(".dropdown1 .btn-outline").removeClass("active")
    if (count || count1 || count2 || count3 || statelist.length !== 0 || size.sizeOfEmployeesMax || achiever == true) {
      $(".dropdown1 .btn-outline").addClass("active")
    }
    if (e.target.value.trim()) {
      setSuggestionValue(e.target.value)
      if (e.target.value.trim().length >= 3) {
        var res = await services.GetByValue(ServiceAPI.CompanyFilterGetSuggestionApi, e.target.value);
        setSuggestionData(res)
        ApplySearch(e.target.value)
      }
      else {
        setSuggestionValue(e.target.value)
        ApplySearch(e.target.value)
      }
    }
    else {
      setSuggestionData([])
      ApplySearch()
    }

  }

  const ClearSearch = () => {
    setSuggestionData([])
    ApplySearch()
  }
  const closeSearch = () => {
    if ($(".globsr").hasClass("active")) {
      if ($("#globsearch").val()) {
        $("#new-users-list").removeClass("show")
      }
      else {
        $(".global").removeClass("active")
        $("#globsearch").removeClass("active")
      }
    }
  }
  return (
    <section className="section " onClick={() => closeSearch()}>
      <div className="category-section container-fluid stu">
        <div className="row">
          <div className="col-md-12">
            <div className="heading-row" >
              <div className="filter">
                <div className="dropdown1 text-left" >
                  <div className="d-flex" >

                    <button className="btn btn-outline" onClick={() => setDropdown()}>filter by
                      <span><img src="../../images/icon-chevron.svg" /></span>
                    </button>
                    <div className="search-box-pannel" >

                      <input type="text" autoComplete="off" placeholder="Search" id="search-input"
                        className={" search-box filter-box"} onChange={(e) => getSuggestionData(e)} value={suggestionvalue ? suggestionvalue : ""} />
                      {suggestionData && suggestionvalue ? <span type="button"
                        className="modalclose"
                        onClick={() => ClearSearch()}
                      >
                        <span aria-hidden="true"> x </span>
                      </span> : ""}
                      {suggestionData && suggestionvalue ?
                        <div className="drop-box" >
                          {suggestionData && suggestionvalue ? suggestionData.map((data) => {
                            return (<div className="inner-item" onClick={() => ApplySearch(data, "select")}>{data}</div>)
                          }) : ""}</div> : ""}
                    </div>
                  </div>
                  <div className="dropdown1-content bg-white container-fluid">
                    <div className="row">
                      <div className="column ">
                        <div className="filter-title dropdown">
                          <button className="loc-btn" type="button" id="dropdownMenu2" onClick={() => setDropdown1()} >
                            Industry <span><span><img src="../../images/icon-chevron.svg" /></span>
                              {count ?
                                <span className="count">
                                  {count}
                                </span>
                                : ""}
                            </span>
                          </button>
                          <div class="dropdown-menu loc-menu ind-menu" aria-labelledby="dropdownMenu2">
                            {industrylist ? clear ? <Multiselect options={industrylist} onSelectOptions={setIndustry} type={"industry"} /> : "" : ""}
                            <div className="absolute-btn">
                              <button className="reset btn btn-outline mr-1" style={{ zIndex: 0 }} onClick={() => setFilter()}>ok</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="column">
                        <div className="filter-title dropdown">
                          <button className="loc-btn" type="button" id="dropdownMenu3" onClick={() => setDropdown2()} >
                            Location<span> <span><img src="../../images/icon-chevron.svg" /></span>
                              {statelist.length !== 0 ? <span className="count check">
                                <img src="../../images/check-img-white.svg"></img>
                              </span> : ""}
                            </span>
                          </button>
                          <div className="dropdown-menu loc-menu location-menu" aria-labelledby="dropdownMenu3">
                            {showcount ? "" : <><div className="lable"> country : </div> <Select options={coutnrylist} onChange={(e) => AddValue(e)} placeholder={"select"} isSearchable="true"
                              className="filter-title" /></>}
                            {statelist.length !== 0 ?
                              <><div className="lable">  State/Province: </div><Select options={statelist ? statelist : []} placeholder={"select"} onChange={(e) => AddValue(e)} className="filter-title" /></>
                              : ""}
                            {citylist.length !== 0 ? <>< div className="lable">  city    :</div> <Select options={citylist ? citylist : []} placeholder={"select"} onChange={(e) => AddValue(e)} className="filter-title" /> </> : ""}

                            <button className="reset btn btn-outline mr-1" style={{ zIndex: 0 }} onClick={() => setFilter()}>ok</button>
                            <button className="reset btn btn-outline mr-1" style={{ zIndex: 0 }} onClick={() => resetLocation("location")}>Reset</button>
                          </div>
                        </div>
                      </div>
                      <div className="column">
                        <div className="filter-title dropdown">

                          <button className="loc-btn" type="button" id="dropdownMenu4" onClick={() => setDropdown3()} >
                            No. of Employees
                            <span style={{ display: 'flex', flexDirection: 'row-reverse' }}> <span><img src="../../images/icon-chevron.svg" /></span>
                              {size.sizeOfEmployeesMax ? <span className="count check">
                                <img src="../../images/check-img-white.svg"></img>
                              </span> : ""}
                            </span>
                          </button>
                          <div class="dropdown-menu loc-menu qual-menu p-10" aria-labelledby="dropdownMenu4">

                            {size.isShow ? <div>{<Select className="filter-title" options={[{ label: "0-10", value: "0-10" }, { label: "10-50", value: "10-50" }, { label: "50-100", value: "50-100" }, { label: "100+", value: "100+" }]} onChange={(e) => apply(e)} placeholder={"select"} isSearchable={false}
                            />}</div> : ""}
                            <button className="reset btn btn-outline mr-1" style={{ zIndex: 0 }} onClick={() => setFilter()}>ok</button>
                            <button className="reset btn btn-outline mr-1" style={{ zIndex: 0 }} onClick={() => resetEmployee("reset")}>Reset</button>

                          </div>
                        </div>
                      </div>
                      <div className="column">
                        <div className="filter-title dropdown">
                          <button className="loc-btn" type="button" id="dropdownMenu5" onClick={() => setDropdown4()} >
                            Hiring
                            <span> <span><img src="../../images/icon-chevron.svg" /></span>
                              {achiever ? <span className="count check">
                                <img src="../../images/check-img-white.svg"></img>
                              </span> : ""}
                            </span>
                          </button>
                          <div class="dropdown-menu loc-menu top-menu p-10" aria-labelledby="dropdownMenu5">
                            <label className="container mb-0">
                              <span>Hiring</span>
                              <input
                                type="checkbox"
                                id="Remember"
                                onChange={() => setTopAchiever()}
                                checked={achiever}
                              />
                              <span className="checkmark"></span>
                            </label>
                            <button className="reset btn btn-outline mr-1" style={{ zIndex: 0 }} onClick={() => setFilter()}>ok</button>
                          </div>

                        </div>
                      </div>

                      <div className="reset-btn">
                        {count || count1 || count2 || count3 || statelist.length !== 0 || size.sizeOfEmployeesMax || achiever == true ? <button className="reset btn btn-outline" onClick={() => clearall()}>Reset</button> : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sign-heading" onClick={() => setFilter()} style={{
                width: "100%",
                textAlign: "right"
              }}>
                Companies
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className=" cards-list " onClick={() => setFilter()} >
            {(count || count1 || count2 || count3 || statelist?.length !== 0 || size.sizeOfEmployeesMax || achiever) && companylist?.length == 0 ? <div className=" showdata">no data found</div> : ""}
            {companylist && <AlphabetList
              className="alpha-list bg-light"
              style={style}
              data={companylist}
              generateFn={(item, index) => {
                { console.log(parseInt(fetchCompanyData(item)[2]), "list", fetchCompanyData(item)[2]) }
                return <Tag key={index} style={{ backgroundColor: "light-grey", border: "none" }} >

                  <div>
                    <div className="img-container" style={{ postion: "relative" }}>
                      {fetchCompanyData(item)[5] == "false" ? <img src={fetchCompanyData(item)[0] ? fetchCompanyData(item)[0] ? fetchCompanyData(item)[0] : "" : ""} className="image clg" /> : <img src={fetchCompanyData(item)[1] ? fetchCompanyData(item)[1] : ""} className="image" />}
                      <div className="name line">
                        {item ? item.split(",")[0].toUpperCase() : ""}
                      </div>
                      <div className="middle" onClick={() => profile(item.split(",")[1], item.split(",")[2])}>
                        <div className="front-text">
                          <div className="title">
                            {item ? item.split(",")[0] : ""}
                            <hr />
                          </div>
                          <div className="sub-title">
                            {fetchCompanyData(item)[4] ? fetchCompanyData(item)[4] : ""}
                          </div>
                          <div className="sub-head">
                            {fetchCompanyData(item) ? fetchCompanyData(item)[2] && parseInt(fetchCompanyData(item)[2]) !== 0 ? fetchCompanyData(item)[2] == 100 ? `${fetchCompanyData(item)[2]}+ emplyees` : `${fetchCompanyData(item)[2]} emplyees` : fetchCompanyData(item)[2] && fetchCompanyData(item)[2].indexOf("-") ? `${fetchCompanyData(item)[2]} emplyees` : "" : ""}
                          </div>
                          <div className="sub-head">
                            {fetchCompanyData(item) ? `${fetchCompanyData(item)[3]}` : " "}
                            {fetchCompanyData(item) && fetchCompanyData(item)[6] ? `, ${fetchCompanyData(item)[6]}` : " "}
                          </div>
                        </div>
                        <button className="readmore-btn">See more</button>
                      </div>
                    </div>
                  </div>
                </Tag>
              }} />}
          </div>
        </div>
        {loader}
      </div>
    </section>

  )

}
export default CompanyList;