import React, { useState, useEffect } from "react";
import Common from "../../../Utility/Common";
import DefaultModel from "../../../Model/Company/CompanyAboutUsModel";
import service from "../../../Services/UtilityService";
import ServiceAPI from "../../../Utility/ServiceList";
import FormHeader from "../../Common/FormHeader";
import CompanyNav from "./CompanyNav";
import environment from '../../../environment';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useLoader from "../../Auth/Loader"
import $ from "jquery";
import AdminHeader from '../../Common/AdminHeader'
import { useHistory } from "react-router-dom";

toast.configure()


const CompanyAboutUs = (props) => {
    const history = useHistory();
    localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
    const [model, setModel] = useState(DefaultModel);
    const [imgurl, setImgurl] = useState('');
    const [showimage, setShowImage] = useState(false);
    const [aboutUsId, setAboutUsId] = useState('');
    const services = new service();
    const year = new Date().getFullYear();
    const [border, setBorder] = useState("");
    const [loader, showLoader, hideLoader] = useLoader();
    const id1 = Common.LoginId();
    Common.ValidLogin();
    const formData = new FormData();
    useEffect(() => {
        if (id1) {
            GetById(id1);
        }
        else if (props.match || !props) {
            const id = props.match.params.id;
            if (id) { GetById(id); }
        }
    }, [id1]);

    const GetById = async (id) => {
        $('.modal-backdrop').removeClass('show ');
        $('.modal').removeClass('show');
        $('body').toggleClass('p-0');
        $('body').removeClass('modal-open');
        showLoader()
        setModel(DefaultModel);
        var res = await services.GetById(ServiceAPI.CompanyGetAboutUs, id);
        hideLoader()
        const data = await res.result;
        setModel(data);
        setImgurl(data.image)
        setAboutUsId(data.id);

    };

    const ChangeHandler = (e) => {
        let value = {};
        if (e.target.type === "checkbox") {
            value[e.target.id] = e.target.checked;
        } else if (e.target.type === "textarea") {
            const inputText = e.target.value;

            // Count words, excluding symbols
            const words = inputText
                .trim()
                .match(/\b[a-zA-Z]+\b/g) || [];

            // Allow only if the word count is within limits
            if (words.length <= 500) {
                value[e.target.id] = e.target.value;
            } else {
                toast.error("Maximum word limit of 500 reached.", { theme: "colored", autoClose: 2000 });
            }
        }
        else {
            value[e.target.id] = e.target.value;
        }
        setModel({ ...model, ...value });
        setBorder({ ...border, [e.target.id]: "" })
    };
    const UploadImage = async (e) => {
        if (validImage(e)) {
            setImgurl()
            setShowImage(true)
            formData.append('file', e.target.files[0]);
            formData.append('imageType', '1');
            var data = await services.UploadImages(ServiceAPI.ImageUplaodApi, "about", formData);
            var url = environment.imageUrl + data.result;
            setImgurl(url);
            setShowImage(false)
            let value = { image: url };
            await setModel({ ...model, ...value });
        }
    }

    const validImage = (e) => {
        let file = e.target.files[0];
        const fileExtension = file.name.split(".").at(-1);
        const allowedFileTypes = ["jpg", "png", "jpeg"];
        if (!allowedFileTypes.includes(fileExtension)) {
            const newName = "No file chosen";
            const input = e.currentTarget;
            const previousFile = input.files[0];
            const newFile = new File([previousFile], newName);
            const dT = new DataTransfer();
            dT.items.add(newFile);
            input.files = dT.files;
            toast.error("File does not support Upload jpg,png,jpeg files", { theme: "colored", toastId: 'error3', autoClose: 3000 });
            return false;
        }
        return true;
    }
    const Create = async (e) => {
        e.preventDefault();
        var response = null;

        if (valid()) {
            let value = { userId: Common.UserId() };
            setModel({ ...model, ...value });
            showLoader();
            if (model.id === null) {
                response = await services.Create(ServiceAPI.CompanyAboutUsCreateApi, model);
            } else {
                response = await services.Update(ServiceAPI.CompanyAboutUsUpdateApi, model);
            }
            if (response && response.message === "Success") {
                if (props.id) {
                    props.GetCompanyAboutUsData();
                    props.GetCompanyJobs();
                    $(`#${props.modalid}`).click();
                    toast.success('Successful', { theme: "colored", autoClose: 2000, toastId: 'success1' })
                    setTimeout(() => {
                        hideLoader();
                        $(`#${props.modalid}`).click();
                        $('.modal-backdrop').removeClass('show ');
                        $('.modal').removeClass('show');
                        $('body').toggleClass('p-0');
                        $('body').removeClass('modal-open');
                        $('nav.navbar.navbar-expand-sm').addClass('p-0');
                    }, 2000)
                }
                else if (props.id2) {
                    $(`#${props.modalid}`).click();
                    props.GetCompanyAboutUsData();
                    toast.success('Successful', { theme: "colored", autoClose: 2000, toastId: 'success2' })
                }
                else {
                    hideLoader();
                    setTimeout(
                        function () {
                            history.push("/companyaboutus");
                        }, 2000);
                    toast.success('Successful', { theme: "colored", autoClose: 2000, toastId: 'success3' })
                }

            } else {
                hideLoader();
            }
        }
    };

    const valid = () => {
        let Error = [];
        let heading = "";
        let image = "";
        let description = "";
        let website = "";
        let noOfOfEmployees = "";
        let servicesOffered = '';
        let headquerters = "";
        let founded = "";

        if (!Boolean(model.heading)) {
            Error.push("Valid heading required");
            heading = "borderred";
        }
        if (model.heading.length > 100) {
            Error.push("Valid Length required for heading");
            heading = "borderred";
        }
        if (!Boolean(model.description)) {
            Error.push("Valid Description required");
            description = "borderred";
        }
        if (!Boolean(model.website)) {
            Error.push("Valid Website URL required");
            website = "borderred";
        }
        if (!Boolean(model.noOfOfEmployees) || model.noOfOfEmployees === "0") {
            Error.push("Valid no Of Employees required");
            noOfOfEmployees = "borderred";
        }
        if (!Boolean(model.servicesOffered)) {
            Error.push("Valid services Offered required");
            servicesOffered = "borderred";
        }
        if (!Boolean(model.headquerters)) {
            Error.push("Valid headquerters required");
            headquerters = "borderred";
        }
        if (!Boolean(model.founded) || model.founded === "0") {
            Error.push("Valid founded required");
            founded = "borderred";
        }

        if (heading || image || description || website || noOfOfEmployees || servicesOffered || headquerters || founded) {
            setBorder({ heading, image, description, website, noOfOfEmployees, servicesOffered, headquerters, founded });
        }
        if (Error.length === 0) {
            Error = [];
            return true;
        } else if (Error.length > 2) {
            toast.error('Please fill the required fields', { theme: "colored", toastId: 'error1' });
            return false;
        } else if (Error.length <= 2) {
            Error.forEach((element, index) => {
                toast.error(element, { theme: "colored", toastId: `${[index]}` });
            });
            return false;
        }
    };

    return (
        <>
            {props.id || props.id2 ? "" : <AdminHeader heading="" subheading="Manage About Us" />}
            <form onSubmit={Create} autoComplete="off">
                <div className="theme-container-admin min-h">
                    {props.id || props.id2 ? "" : <CompanyNav aboutus="active"></CompanyNav>}
                    <div className="row">
                        <div className="  mx-auto detail-box form-sec">
                            <div className="row m-0 align-items-center">
                                <div className="col-md-12">
                                    <div className="label">Heading<span className="required">*</span></div>
                                    <input
                                        type="text"
                                        id="heading"
                                        className={border.heading ? border.heading : "xu-input"}
                                        placeholder="Heading"
                                        maxLength={140}
                                        value={model.heading}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-12">
                                    <div className="label">Description<span className="required">*</span>{`(${model.description ? model.description.trim().match(/\b[a-zA-Z]+\b/g)?.length : 0}/500)`}</div>
                                    <textarea
                                        // maxLength={500}
                                        type="text"
                                        rows="3"
                                        id="description"
                                        className={border.description ? border.description : "xu-textarea mb-0"}
                                        placeholder="Description"
                                        value={model.description}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></textarea>
                                </div>
                                <div className="col-md-6">
                                    <div className="row m-0">
                                        <div className="col-md-10 pl-0">
                                            <div className="label xy-label-float">Choose Image <span className="">(JPG or PNG)</span></div>
                                            <input
                                                type="file"
                                                id="image"
                                                className="xu-input xpading"
                                                placeholder="Image"
                                                accept="image/jpeg, image/png,  image/jpg"
                                                onChange={(e) => UploadImage(e)}
                                            ></input>
                                        </div>
                                        <div className="col-md-2 pr-0">
                                            {imgurl ? <img src={imgurl} className="ad-img img-fluid" />
                                                : showimage ? <span className="img ">
                                                    <div className="lds-roller sm"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>

                                                </span> : <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/default-img.jpg`} className="ad-img img-fluid" />}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Website URL<span className="required">*</span></div>
                                    <input
                                        type="text"
                                        id="website"
                                        className={border.website ? border.website : "xu-input"}
                                        placeholder="Website URL"
                                        value={model.website}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Number Of Employees<span className="required">*</span></div>
                                    <select className={border.noOfOfEmployees ? border.noOfOfEmployees : "xu-input"} id="noOfOfEmployees" value={model.noOfOfEmployees} onChange={(e) => ChangeHandler(e)}>
                                        <option value="0">Select</option>
                                        {
                                            ["0-10", "10-50", "50-100", "100+"].map((item) => (
                                                <option key={item} value={item}>
                                                    {item}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Services Offered<span className="required">*</span></div>
                                    <input
                                        type="text"
                                        id="servicesOffered"
                                        className={border.servicesOffered ? border.servicesOffered : "xu-input"}
                                        placeholder="ServicesOffered"
                                        value={model.servicesOffered}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Headquarters<span className="required">*</span></div>
                                    <input
                                        type="text"
                                        id="headquerters"
                                        className={border.headquerters ? border.headquerters : "xu-input"}
                                        placeholder="Headquerters"
                                        value={model.headquerters}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Founded<span className="required">*</span></div>
                                    <select className={border.founded ? border.founded : "xu-input"} value={model.founded} id="founded" onChange={(e) => ChangeHandler(e)}>
                                        <option value="0">Select</option>
                                        {
                                            Array.from(new Array(100), (v, i) =>
                                                <option key={i} value={year - i}>{year - i}</option>
                                            )}
                                    </select>
                                </div>
                                <div className="col-md-12 d-flex admin-ticks" >
                                    <div className='display-inline pl-0 unset'>
                                        <label className="check-container">
                                            <input type="checkbox" id="remoteWorking" checked={model.remoteWorking} onChange={(e) => ChangeHandler(e)} />
                                            <span className="ccheckmark"></span>
                                        </label>
                                        <div className='label mt-0 pl-1 text-dark '>
                                            Remote working
                                        </div>

                                    </div>
                                    <div className='display-inline ml-2 pl-0 unset'>
                                        <label className="check-container">
                                            <input type="checkbox" id="travelOpportunities" checked={model.travelOpportunities} onChange={(e) => ChangeHandler(e)} />
                                            <span className="ccheckmark"></span>
                                        </label>
                                        <div className='label mt-0 pl-1 text-dark '>
                                            Travel Opportunities
                                        </div>

                                    </div>
                                    <div className='display-inline ml-2 pl-0 unset'>
                                        <label className="check-container">
                                            <input type="checkbox" id="learningOpportunities" checked={model.learningOpportunities} onChange={(e) => ChangeHandler(e)} />
                                            <span className="ccheckmark"></span>
                                        </label>
                                        <div className='label mt-0 pl-1 text-dark '>
                                            Learning Opportunities
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-12 spacing pb-0">
                                    <button
                                        className="btn btn-outline a-btn mt-0 float-right"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            {loader}
        </>
    )
}

export default CompanyAboutUs
