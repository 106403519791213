import React, { useState } from "react";

function Loader() {
    const [loading, setLoading] = useState(false);

    return [
        loading ? <div className="fp-container">
            {/* <img src='./images/Infinity2.svg' className="fp-loader" alt="loading" /> */}
            {/* <div className="fp-loader"></div> */}
            {/* infinite */}
            {/* <div class="spinner"></div> */}
            <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div> : null,
        () => setLoading(true), //Show loader
        () => setLoading(false) //Hide Loader
    ];
}

export default Loader
