
import React, { Component, useState } from 'react';
import Talk from "talkjs";
import "./network.css"
import { Link } from "react-router-dom";



class MyNetwork extends Component {
    constructor(props) {

        super(props);
        let currentUser;

        var data = this.props.studentData;

        if (!this.props.studentData) {
            console.log('data is available')
            data = JSON.parse(localStorage.getItem('studentData'));

        } else {
            localStorage.setItem('studentData', JSON.stringify(data));
            console.log('please update');
        }
        const currentTalkjsUser = localStorage.getItem('currentTalkjsUser');

        if (currentTalkjsUser) {
            const newcurrentUser = JSON.parse(currentTalkjsUser)
            currentUser = {
                id: newcurrentUser.loginId,
                name: newcurrentUser.userName,
                email: newcurrentUser.email,
                photoUrl: newcurrentUser.profileImage,
                role: "Member",
                welcomeMessage: "Hey there! Love to chat :-)"
            }
        }
        this.state = {
            currentUser,
            data
        }

        const user = {
            id: data.id,
            name: data.firstName,
            email: data.email,
            photoUrl: data.profileImage ? data.profileImag : "./images/news.png",
            role: "Member",
            welcomeMessage: "Hey there! Love to chat :-)"
        }


        /* Session initialization code */
        Talk.ready
            .then(() => {
                /* Create the two users that will participate in the conversation */
                const me = new Talk.User(currentUser);
                console.log("me", me)
                const other = new Talk.User(user)
                console.log("other", other)

                /* Create a talk session if this does not exist. Remember to replace tthe APP ID with the one on your dashboard */
                if (!window.talkSession) {
                    window.talkSession = new Talk.Session({
                        appId: "tWsjp2h9",
                        me: me
                    });
                }

                /* Get a conversation ID or create one */
                const conversationId = Talk.oneOnOneId(me, other);
                console.log(me, other, "me")
                const conversation = window.talkSession.getOrCreateConversation(conversationId);

                /* Set participants of the conversations */
                conversation.setParticipant(me);
                conversation.setParticipant(other);
                var popup = window.talkSession.createPopup();
                popup.select(conversation);
                popup.mount({ show: false });
            })
            .catch(e => console.error(e));
    }
    render() {
        return (
            <>

                <div className="users">

                    <div className="users-container">
                        <div className="chatbox-container" ref={c => this.container = c}>
                            <div id="talkjs-container" style={{ height: "300px" }}><i></i></div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default MyNetwork;


