import React, { useState, useEffect } from "react";
import DefaultModel from "../../../Model/College/ShortListCategoryModel";
import service from "../../../Services/UtilityService";
import ServiceAPI from "../../../Utility/ServiceList";
import Common from "../../../Utility/Common";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import $ from "jquery";
import { useHistory } from "react-router-dom";
import useLoader from '../../Auth/Loader'
toast.configure()

function ShortList(props) {
    const history = useHistory();
    const [model, setModel] = useState(DefaultModel);
    const [loader, showLoader, hideLoader] = useLoader();
    const [border, setBorder] = useState("");
    const id = props.id;
    const id1 = props.id1;
    const services = new service();
    useEffect(() => {

        if (id1) {
            GetById(id1);
        }
        else if (id) {
            GetById(id);
        }
    }, []);
    const GetById = async (id) => {
        $('.modal-backdrop').removeClass('show ');
        $('.modal').removeClass('show');
        $('body').toggleClass('p-0');
        $('body').removeClass('modal-open');
        showLoader()
        setModel(DefaultModel);
        var res = await services.GetById(ServiceAPI.CollegeCategoryGetApi, id);
        hideLoader()
        const model = await res.result;
        setModel(model);
    }

    const ChangeHandler = (e) => {
        let value = {};
        if (e.target.type === "checkbox") {
            value[e.target.id] = e.target.checked;
        } else {
            value[e.target.id] = e.target.value;
        }
        setModel({ ...model, ...value });
        setBorder({ ...border, [e.target.id]: "" })
    }

    const Create = async (e) => {
        e.preventDefault();
        var response = null;
        if (valid()) {
            showLoader()
            let value = { userId: Common.UserId() };
            setModel({ ...model, ...value });
            if (model.id === null) {

                response = await services.Create(ServiceAPI.CollegeCategoryCreateApi, model);
                hideLoader()
            } else {
                response = await services.Update(ServiceAPI.CollegeCategoryUpdateApi, model);
                hideLoader()
            }
            if (response && response.message === "Success") {
                if (props.id1) {
                    props.GetShortListData();
                    toast.success('Successfully Update', { theme: "colored", autoClose: 2000 })
                    setTimeout(() => {
                        $('.modal-backdrop').removeClass('show ');
                        $('.modal').removeClass('show');
                        $('body').toggleClass('p-0');
                        $('body').removeClass('modal-open');
                        $('nav.navbar.navbar-expand-sm').addClass('p-0');
                    }, 2000)
                }
                else if (props.id2) {
                    props.GetCompanyProfiles(Common.LoginId());
                    toast.success('Successful', { theme: "colored", autoClose: 2000 })
                }
                else {
                    props.GetAll(model.id == null ? false : Common.LoginId(), {
                        "pageNum": 1,
                        "pageSize": 10
                    });
                    toast.success('Successful', { theme: "colored", autoClose: 2000 })
                    history.push("/collegeshortlistcategory");
                    setTimeout(() => {
                        $('.modal-backdrop').removeClass('show ');
                        $('.modal').removeClass('show');
                        $('body').toggleClass('p-0');
                        $('body').removeClass('modal-open');
                        $('nav.navbar.navbar-expand-sm').addClass('p-0');
                    }, 2000)
                    setModel(DefaultModel);
                }
            }
            else {
                toast.error(response.ErrorMessage, { theme: "colored", autoClose: 2000 })
            }

        }
    };

    const valid = () => {
        let name = "";
        if (!Boolean(model.name)) {
            toast.error("Valid Category Name required", { theme: "colored", toastId: 'error1' });
            name = "borderred";
        }
        if (model.name.length > 50) {
            toast.error("Valid Length required for Title", { theme: "colored", toastId: 'error1' });
            name = "borderred";
        }
        if (name) {
            setBorder({ name });
            return false;
        }
        return true;
    };
    return (
        <>

            <div className="">
                <div className=" mx-auto form-sec mt-3">
                    <div className="row  align-items-center justify-content-center">

                        <div className="col-md-11">
                            <div className="label">Category Name</div>
                            <input
                                autoComplete="off"
                                type="text"
                                id="name"
                                className={border.name ? border.name : "xu-input"}
                                placeholder="Category Name"
                                value={model.name}
                                onChange={(e) => ChangeHandler(e)}
                            ></input>
                        </div>
                        <div className="col-md-11">
                            <div className="label">Active</div>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    id="active"
                                    checked={model.active}
                                    onChange={(e) => ChangeHandler(e)}
                                />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <div className="col-md-11">
                            <div style={{ textAlign: "center" }}>
                                <button type="button" className="btn btn-outline a-btn  mb-3 float-right" onClick={Create}>Submit</button>
                            </div>
                        </div>
                    </div>{" "}
                </div>{" "}

            </div>
            {loader}

        </>
    )
}

export default ShortList
