import Common from "../../Utility/Common";
const newDate = new Date();
const StudentEducationModel = () => ({
        id: null,
        courseId: null,
        course: '',
        industryId: null,
        industry: '',
        student: '',
        institute: '',
        startDate: newDate,
        endDate: newDate,
        percentage: 0,
        active: true,
        userId: Common.UserId(),
        errormsg: "",
        studentId: Common.LoginId(),
        educationType: '',
        qualificationId: '',
        isPursuing: false,
        pursuingYear: 1,
        duration: 0,
        studyProgram: ''
})
export default StudentEducationModel;