import Common from "../../Utility/Common";
const newDate = new Date();
const CompanyMediaModel = () => ({
        id: null,
        companyId: Common.LoginId(),
        title: '',
        description: '',
        image: '',
        date: newDate,
        active: true,
        userId: Common.UserId(),
})
export default CompanyMediaModel