import React, { useState, useEffect } from 'react'
import ServiceAPI from "../../Utility/ServiceList";
import service from "../../Services/UtilityService";
import Common from "../../Utility/Common"
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import useLoader from '../Auth/Loader'
import $ from "jquery";

const StudentShortList = () => {
    const history = useHistory();
    var services = new service();
    localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
    useEffect(() => {
        GetStudentShortList();
    }, []);
    const [loader, showLoader, hideLoader] = useLoader();
    const [companyShortListData, setCompanyShortListData] = useState([]);
    const [collegeShortListData, setCollegeShortListData] = useState([])
    const GetStudentShortList = async () => {
        $('.modal-backdrop').removeClass('show ');
        $('.modal').removeClass('show');
        $('body').toggleClass('p-0');
        $('body').removeClass('modal-open');
        showLoader()
        var res = await services.GetById(ServiceAPI.StudentShortListGetAllApi, Common.LoginId());
        hideLoader()
        const data = await res.result;
        setCollegeShortListData(data.filter((data) => data.companyId == "00000000-0000-0000-0000-000000000000"))
        setCompanyShortListData(data.filter((data) => data.collegeId == "00000000-0000-0000-0000-000000000000"))

    };
    const deletestudent = async (id) => {
        var res = await services.Delete(ServiceAPI.StudentShortListDeleteApi, id);
        if (res.message == "Success") {
            GetStudentShortList()
            toast.success("Successfully Removed", { theme: "colored", autoClose: 2000 })
        }

    };
    const profile = (userId, id) => {
        history.push('collegesprofile?id=' + id)
    }
    const profile1 = (userId, id) => {
        history.push('companysprofile?id=' + id)
    }
    return (
        <div className="checkfit">
            <div className='btn-row '>
                <div className="title ">ShortList</div>
            </div>
            <div className='shortlist-pannel scrollbar pt-3' id='scrollbar'>
                <div>
                    {collegeShortListData ? <div className="bg-light container-fluid">
                        <div className='sub-title text-capitalize'>College</div>
                        <hr />
                        <div className="cards bg-light m-h">
                            {collegeShortListData.map((data, index) => {
                                return (
                                    <div key={index}>
                                        <div className="img-container" style={{ postion: "relative" }}>
                                            <img src={data.college.coverImage ? data.college.coverImage : data.college.logo ? data.college.logo : ""} alt="Avatar" className="image" />
                                            <div className="name">
                                                {data.college.name}

                                            </div>
                                            <div className="middle" >

                                                <a
                                                    onClick={() => deletestudent(data.id)}
                                                    className="fa fa-trash delete"
                                                    aria-hidden="true"
                                                />

                                                <a onClick={() => profile(data.userId, data.collegeId)}>
                                                    <div >
                                                        <div className="front-text">
                                                            <div className="title">
                                                                {data.college.name.toUpperCase()}
                                                                <hr />
                                                            </div>

                                                            <div className="sub-head">
                                                                {data.college.courseTypes ? `${data.college.courseTypes} | ` : ""}
                                                                {data.college.nqfRange && data.college.nqfRange !== "null" ? `${data.college.nqfRange} | ` : data.college.courses && data.college.courses.length !== 0 ? `${data.college.courses[0].nfqlLevel} | ` : ""} {data.college.city ? `${data.college.city} ` : ""}
                                                            </div>
                                                            <div className="sub-head" >
                                                                {data.college.courses && data.college.courses.length !== 0 ? data.college.courses.length == 1 ? `${data.college.courses.length} course ` : `${data.college.courses.length} courses ` : ""}
                                                            </div>
                                                            <div className="sub-head small" >
                                                                {data.college.studyTypes ? data.college.studyTypes !== "null" ? data.college.studyTypes.replace(",null", "").replace(",", " | ").replace(",", " | ") : "" : ""}
                                                            </div>
                                                        </div>
                                                        <button className="readmore-btn" onClick={() => profile(data.userId, data.collegeId)}>See more</button>
                                                    </div>

                                                </a>

                                            </div>
                                        </div>
                                    </div>
                                )
                            })}</div>
                    </div> : ""}
                </div>
                <div>
                    {companyShortListData ? <div className="bg-light container-fluid">
                        <div className='sub-title text-capitalize'>Company</div>
                        <hr />
                        <div className="cards m-h">
                            {companyShortListData.map((data, index) => {
                                return (
                                    <div key={index}>
                                        <div className="img-container" style={{ postion: "relative" }}>
                                            <img src={data.company.coverImage ? data.company.coverImage : data.company.logo ? data.company.logo : ""} alt="Avatar" className="image" />
                                            <div className="name">
                                                {data.company.name}
                                            </div>
                                            <div className="middle">
                                                <label>
                                                    <a
                                                        onClick={() => deletestudent(data.id)}
                                                        className="fa fa-trash delete"
                                                        aria-hidden="true"
                                                    />
                                                </label>

                                                <a onClick={() => profile1(data.userId, data.companyId)}>
                                                    <div className="front-text">
                                                        <div className="title">
                                                            {data.company.name}
                                                            <hr />
                                                        </div>
                                                        <div className="sub-title">
                                                            {data.company.industry ? data.company.industry : ""}
                                                        </div>
                                                        <div className="sub-head">
                                                            {data.company.employee && parseInt(data.company.employee) !== 0 ? data.company.employee == 100 ? `${data.company.employee}+ emplyees` : `${data.company.employee} emplyees` : ""}
                                                        </div>
                                                        <div className="sub-head">
                                                            {data.company.city ? `${data.company.city}, ` : " "}
                                                            {data.company.country ? `${data.company.country}` : " "}
                                                        </div>
                                                    </div>
                                                    <button className="readmore-btn">Read more</button>
                                                </a>

                                            </div>
                                        </div>
                                    </div>
                                )
                            })}</div>
                    </div> : ""}
                </div>
            </div>{loader}
        </div>
    )
}

export default StudentShortList;
