import React, { useState, useEffect } from "react";
import service from "../../Services/UtilityService";
import environment from "../../environment";
import ServiceAPI from "../../Utility/ServiceList";
import { FaPencilAlt } from "react-icons/fa";
import CollegeCourses from "./Admin/CollegeCourse";
import CollegeNews from "./Admin/CollegeNews";
import CollegeEvents from "./Admin/CollegeEvent";
import CollegeAboutus from "./Admin/CollegeAboutUs";
import CollegeProfiles from "./Admin/CollegeProfile";
import CollegeBanners from "./Admin/CollegeBanner";
import Common from "../../Utility/Common";
import ValidationService from "../../Services/ValidationService";
import { toast } from "react-toastify";
import { ImCross } from "react-icons/im";
import $ from "jquery";
import useLoader from "../Auth/Loader"
import moment from "moment";
import { useHistory } from "react-router-dom";

const CollegeProfile = () => {
    var services = new service();
    localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
    const history = useHistory();
    let params = (new URL(document.location)).searchParams;
    let id = params.get('id');
    const [show, setShow] = useState(false); const [show1, setShow1] = useState(false); const [show2, setShow2] = useState(false); const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [timeouts, setTimeouts] = useState(false)
    const [isShortlist, setIsShortlist] = useState(false)
    const [banner, setBanner] = useState([]);
    const [border, setBorder] = useState("");
    const [error, setError] = useState(false)
    const [loader, showLoader, hideLoader] = useLoader();
    const [collegeData, setCollegeData] = useState('');
    const [collegeEventData, setCollegeEventData] = useState([]);
    const [collegeNewsData, setCollegeNewsData] = useState('');
    const [collegeCoursesData, setCollegeCoursesData] = useState();
    const [collegeAboutUsData, setCollegeAboutUsData] = useState([]);
    const [isReadMore, setIsReadMore] = useState(false);
    const [contactModel, setContactModel] = useState({
        "id": null,
        "senderName": "",
        "senderEmail": "",
        "message": "",
        "collegeId": id
    });
    const [eventModel, setEventModel] = useState({
        "id": "00000000-0000-0000-0000-000000000000",
        "collegeEventId": "",
        "firstName": "",
        "lastName": "",
        "email": "",
        "contact": ""
    })
    const [ShortListModel, setShortListModel] = useState({
        "id": '',
        "userId": `${Common.UserId()}`,
        "active": true,
        "studentId": Common.LoginId(),
        "companyId": "00000000-0000-0000-0000-000000000000",
        "collegeId": id,
        "college": null,
        "company": null
    });
    const [pageModel, setPageModel] = useState({
        "pageNum": 1,
        "pageSize": 5
    });
    const [datamodel, setDataModel] = useState(
        {
            "studentId": localStorage.getItem('usertype') == 'candidate' ? Common.LoginId() : null,
            "companyId": null,
            'collegeId': null
        }
    )
    useEffect(() => {
        GetProfiles(id);
        GetBanner(id);
        GetNews(id);
        GetEvent(id);
        GetCourses(id);
        GetAboutUs(id);
        headNav();
    }, [id]);
    const GetEvent = async (id) => {
        var res = await services.GetDataById(ServiceAPI.CollegeEventGetAllApi, id, {
            "pageNum": 1,
            "pageSize": 5
        });
        const data = res.result ? res.result.filter((items) => items.active !== false) : res.result
        await setCollegeEventData(data);
    };
    const eventChange = (e) => {
        const value = {}
        value[e.target.name] = e.target.value
        setEventModel({ ...eventModel, ...value })
    }
    const AttendEvent = async (id, type) => {
        if (type == "type") {
            toast.error('Invalid Action', { theme: "colored", autoClose: 2000, toastId: 'success1' })
            setTimeout(() => {
                hideLoader();
                $('.modal-backdrop').removeClass('show ');
                $('.modal').removeClass('show');
                $('body').toggleClass('p-0');
                $('body').removeClass('modal-open');
                $('nav.navbar.navbar-expand-sm').addClass('p-0');
            }, 2000)
        } else {
            if (validation("college")) {
                showLoader();
                var res = await services.Create(ServiceAPI.collegeEventResisterApi, { ...eventModel, "collegeEventId": id });
                hideLoader();

                if (res && res.message === "Success") {
                    setEventModel({
                        "id": "00000000-0000-0000-0000-000000000000",
                        "collegeEventId": "",
                        "firstName": "",
                        "lastName": "",
                        "email": "",
                        "contact": ""
                    })
                    toast.success('Applied Successfully', { theme: "colored", autoClose: 2000, toastId: 'success1' })
                    setTimeout(() => {
                        setEventModel([])
                        hideLoader();
                        $('.modal-backdrop').removeClass('show ');
                        $('.modal').removeClass('show');
                        $('body').toggleClass('p-0');
                        $('body').removeClass('modal-open');
                        $('nav.navbar.navbar-expand-sm').addClass('p-0');
                    }, 2000)
                }
            }
        }
    }
    const GetNews = async (type) => {
        // setCollegeNewsData([])
        let params = (new URL(document.location)).searchParams;
        let id = params.get('id');
        var res1 = await services.GetDataById(ServiceAPI.CollegeNewsGetAllApi, id, {
            "pageNum": 1,
            "pageSize": 100
        });
        const data1 = res1.result.filter((items) => items.active !== false);;
        await setCollegeNewsData(data1);
        if (type == "delete") {
            $("#newselem").find("div:first").addClass("active")
            $("#dotselem").find("li:first").addClass("active")
        }
    };

    const GetCourses = async () => {

        var res = await services.GetDataById(ServiceAPI.CollegeCoursGetAllbyIndustryApi, id, {
            "pageNum": 1,
            "pageSize": 50
        });
        // var res = await services.GetDataById(ServiceAPI.CollegeCourseGetAllApi, id, {
        //     "pageNum": 1,
        //     "pageSize": 50
        // });
        const data = res.result.filter((items) => items.active !== false)
        await setCollegeCoursesData(data);
    };
    const GetProfiles = async (id) => {
        $('.modal-backdrop').removeClass('show ');
        $('.modal').removeClass('show');
        $('body').toggleClass('p-0');
        $('body').removeClass('modal-open');
        const s = localStorage.getItem('usertype') == 'candidate' ? Common.LoginId() : null
        const x = localStorage.getItem('usertype')
        if (x == "candidate") {
            showLoader()
            var res1 = await services.GetStudentDataById(ServiceAPI.CollegeGetApi, id, { ...datamodel, studentId: s, collegeId: id });
            hideLoader()
            const data1 = res1.result;
            localStorage.setItem("puserid", data1.userId)
            await setCollegeData(data1)
            setIsShortlist(data1.isShortlist)
        }
        else {
            showLoader()
            var res1 = await services.GetStudentDataById(ServiceAPI.CollegeGetApi, id, { ...datamodel, collegeId: id });
            hideLoader()
            const data1 = res1.result;
            localStorage.setItem("puserid", data1.userId)
            await setCollegeData(data1)
            setIsShortlist(data1.isShortlist)
        }

        setTimeout(() => {
            hideLoader()
            setTimeouts(true)
        }, 10);


    };
    const GetBanner = async (type) => {
        let params = (new URL(document.location)).searchParams;
        let id = params.get('id');
        var res = await services.GetDataById(ServiceAPI.CollegeBannerGetAllApi, id, pageModel);
        const data = res.result.filter((items) => items.active !== false);
        await setBanner(data)
        if (type == "delete") {
            $("#bannerelem").find("div:first").addClass("active")
            $("#bannerdots").find("li:first").addClass("active")
        }
    };
    const GetAboutUs = async () => {
        var res = await services.GetById(ServiceAPI.CollegeGetAboutUs, id);
        const data = res.result;
        await setCollegeAboutUsData(data)
    };
    const UnShortList = async () => {
        const x = localStorage.getItem('usertype')
        const s = localStorage.getItem('usertype') == 'candidate' ? Common.LoginId() : null
        if (x === "candidate") {
            showLoader()
            var res = await services.Create(ServiceAPI.CollegeShortlistRemoveApi, { ...datamodel, studentId: s, collegeId: id });
            hideLoader()
            if (res.message === "Success") {
                setIsShortlist(false)
                toast.success("Successfully Removed", { theme: "colored", autoClose: 2000 })
            }
        }

    }
    const PostShortList = async () => {
        showLoader()
        var res = await services.Create(ServiceAPI.StudentShortListPostApi, ShortListModel);
        hideLoader()

        if (res.message === "Success") {
            toast.success("Successfully Shortlisted", { theme: "colored", autoClose: 2000 })

            setIsShortlist(true)
        }
        else {
            setIsShortlist(false)
            toast.error(res.ErrorMessage, { theme: "colored", autoClose: 2000 })

        }
    };
    const contactHandle = async (e, type) => {

        if (type == "type") {
            toast.error('Invalid Action', { theme: "colored", autoClose: 2000, toastId: 'success1' })

        } else {
            e.preventDefault()
            if (valid()) {
                setBorder("")
                showLoader();
                var res = await services.Create(ServiceAPI.CollegeContactGetAllApi, contactModel);
                hideLoader();

                if (res && res.message === "Success") {
                    toast.success('Email Sent Successfully', { theme: "colored", autoClose: 2000, toastId: 'success1' })
                    setContactModel({
                        "id": null,
                        "senderName": "",
                        "senderEmail": "",
                        "message": "",
                        "collegeId": id
                    })

                }
                else {
                    toast.error(res.errorMessage, { theme: "colored", autoClose: 2000, toastId: 'success1' })
                }
            }
        }
    };
    const validation = () => {
        let firstName = ''
        let lastName = ''
        let email = ''
        let contact = ''
        if (!ValidationService.validationNotEmpty(eventModel.firstName)) {
            toast.error("Please enter firstName ", { theme: "colored", autoClose: 2000, toastId: 'success1' });
            firstName = "borderred";
        }
        if (!ValidationService.validationNotEmpty(eventModel.lastName)) {
            toast.error("Please enter lastName ", { theme: "colored", autoClose: 2000, toastId: 'success1' });
            lastName = "borderred";
        }
        if (!ValidationService.validationEmail(eventModel.email)) {
            toast.error("Please enter valid email ", { theme: "colored", autoClose: 2000, toastId: 'success1' });
            email = "borderred";
        }
        if (!ValidationService.validationNotEmpty(eventModel.contact)) {
            toast.error("Please enter Contact no. ", { theme: "colored", autoClose: 2000, toastId: 'success1' });
            contact = "borderred";
        }

        if (firstName || lastName || email || contact) {
            setBorder({ firstName, lastName, email, contact });
            return false;
        }
        return true;
    }
    const valid = () => {
        let senderName = ""
        let senderEmail = ''
        let message = ''
        var arrError = [];
        if (!ValidationService.validationNotEmpty(contactModel.senderName)) {
            toast.error("Please enter Name", { theme: "colored", autoClose: 3000, toastId: 'error1' });
            senderName = "borderred"
        }
        if (!ValidationService.validationNotEmpty(contactModel.senderEmail)) {
            toast.error("Please enter Email", { theme: "colored", autoClose: 3000, toastId: 'error1' });
            senderEmail = 'borderred'
        }
        if (!ValidationService.validationEmail(contactModel.senderEmail)) {
            arrError.push("Valid Email required");
            toast.error("Valid Email required", { theme: "colored", autoClose: 3000, toastId: 'error1' });
        }
        if (!ValidationService.validationNotEmpty(contactModel.message)) {
            toast.error("Please enter message", { theme: "colored", autoClose: 3000, toastId: 'error1' });
            message = "borderred"
        }
        if (senderName || senderEmail || message) {
            setBorder({ senderEmail, senderName, message })
        }
        if (arrError.length) {
            setError(arrError);
            return false;
        }
        return true;
    };
    const ChangeHandler = (e) => {
        e.preventDefault();
        let value = {};
        value[e.target.id] = e.target.value;
        setContactModel({ ...contactModel, ...value });
        setBorder({ ...border, [e.target.id]: "" })
    }
    // connsole.log(collegeAboutUsData, "abouts");

    const headNav = () => {
        $(window).scroll(function (e) {
            var scrollDistance = $(window).scrollTop() + 150;
            if (scrollDistance) {
                // var menuc = $("#btn-nav").position().top;

                $(".profile-tab").each(function (i) {
                    var divPoc = $(this).position().top;
                    if (divPoc <= scrollDistance) {

                        $("#btn-nav ul li.active").removeClass('active');
                        $("#btn-nav ul li a.active ").removeClass('active');
                        $("#btn-nav ul li").eq(i).addClass('active');
                        $("#btn-nav ul li a").eq(i).addClass('active');
                    }
                });

            }

        });
        $(".nav-tabs .navitem").on("click", function () {

            var sectionId = '#' + $(this).attr('id').replace('lnk', '');

            if (window.matchMedia('(max-width: 768px)').matches) {
                $(window).scrollTop($(sectionId).position().top - 100);
            }
            else {
                $(window).scrollTop($(sectionId).position().top - 80);

            }
        });

    }
    const verify = Common.ValidId(id, localStorage.getItem("puserid"));
    const redirect = () => {
        history.push("/collegecourselist")
    }
    const rediectLink = (link) => {
        if (link) {
            window.open(link, '_blank', 'noopener,noreferrer');
        }
    }
    const redirectTolist = () => {
        history.push("/collegebannerlist");
    }
    const closeSearch = () => {
        if ($(".globsr").hasClass("active")) {
            if ($("#globsearch").val()) {
                // alert($("#globsearch").val())
                $("#new-users-list").removeClass("show")
            }
            else {
                $(".global").removeClass("active")
                $("#globsearch").removeClass("active")
            }
        }
    }
    const EditProfile = () => {
        setShow(true)
    }
    const EditNews = () => {
        setShow2(true)
    }

    const EditEvent = () => {
        setShow3(true)
    }
    const EditCourse = () => {
        setShow4(true)
    }
    return (
        <>
            <section className="section ">
                <div className="profile">
                    <div className="btn-row">
                        <div className="w-100">
                            <nav className="w-100" onClick={() => closeSearch()}>
                                {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#btn-nav" aria-controls="btn-nav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button> */}
                                <div className="scroll-tabs wrapped-tab" scroll="true" id="btn-nav">
                                    <ul className="nav nav-tabs " id="pills-tab" role="tablist">
                                        <li id="lnkhome" className=" navitem  " >
                                            <a className="btn dark s-btn active"> Home</a>
                                        </li>
                                        <li id="lnkabout" className="navitem">
                                            <a className="btn dark s-btn"> About</a>
                                        </li>
                                        <li id="lnkcourse" className="navitem">
                                            <a className="btn dark s-btn">courses </a>
                                        </li>
                                        <li id="lnknews" className="navitem">
                                            <a className="btn dark s-btn">news & events </a>
                                        </li>
                                        <li id="lnkcontacts" className="navitem">
                                            <a className="btn dark s-btn">contact </a>
                                        </li>
                                        <li className="d-md-none d-block">
                                            <div className="visit ">
                                                <a className="btn dark s-btn" href={collegeData && collegeData.website ? collegeData.website.includes("http") ? collegeData.website : `http://${collegeData.website}` : ""} target={collegeData && collegeData.website ? "_blank" : ""}>visit site</a>
                                                {environment.isServer == "true" ? Common.UserType() == "candidate" ? <a className="btn dark s-btn" target="_blank" onClick={() => PostShortList()}><div className="px-3 ">Shortlist</div> <img className="sl-img ms-3" src="images/file-check.svg" /></a> : "" : ""}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        <div className="visit d-md-block d-none">
                            <a className="btn btn-outline s-btn" href={collegeData && collegeData.website ? collegeData.website.includes("http") ? collegeData.website : `http://${collegeData.website}` : ""} target={collegeData && collegeData.website ? "_blank" : ""}>visit site</a>
                            {environment.isServer == "true" ? Common.UserType() == "candidate" ? isShortlist ? <a className="btn btn-outline s-btn" onClick={() => UnShortList()}><div className="px-2 ">Shortlist</div> <img className="sl-img" src="images/cross.png" /></a> : <a className="btn btn-outline s-btn" onClick={() => PostShortList()}><div className="px-3 ">Shortlist</div> <img className="sl-img ms-3" src="images/file-check.svg" /></a> : "" : ""}
                            {/* {environment.isServer == "true" ? Common.UserType() == "candidate" ? <a className="btn btn-outline s-btn" target="_blank" onClick={() => PostShortList()}><img class="sl-img" src="images/file-check.svg" />shortlist</a> : "" : ""} */}
                        </div>

                    </div>
                    {/* <div class="scroll-tabs scroll-tabs-bg" scroll="true">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="active"><a href="#one" data-toggle="tab">Category One</a></li>
                            <li><a href="#two" data-toggle="tab">Category Two</a></li>
                            <li><a href="#three" data-toggle="tab">Category Three</a></li>
                            <li><a href="#four" data-toggle="tab">Category Four</a></li>
                            <li><a href="#five" data-toggle="tab">Category Five</a></li>
                            <li><a href="#six" data-toggle="tab">Category Six</a></li>
                            <li><a href="#seven" data-toggle="tab">Category Seven</a></li>
                            <li><a href="#eight" data-toggle="tab">Category Eight</a></li>
                            <li><a href="#nine" data-toggle="tab">Category Nine</a></li>
                            <li><a href="#ten" data-toggle="tab">Category Ten</a></li>
                        </ul>
                    </div> */}

                    <section className="profile-tab landing-section-home-page profile-carousel carousel slide" id="home" data-ride="carousel" data-touch="false" data-pause="hover">
                        {/* Indicators  */}
                        <ul className="carousel-indicators carousel-dots landing-dots" id="bannerdots">
                            {banner && banner.length > 1
                                ? banner.map((items, indexs) => <li data-target="#home" key={indexs + items} data-slide-to={`${indexs}`} className={indexs === 0 ? 'active' : ""}></li>) : ""}
                        </ul>
                        <div className="caraousal-head">
                            <div className="display-inline bg-white rounded align-items-center start pr-30">

                                {timeouts ? <div className="imge">
                                    <img src={collegeData ? collegeData.logo : ""} alt="" className="img-fluid rounded-circle " width="70" height="80" />
                                </div> : ""}
                                <div className="edit-top">
                                    <div className="pedit2 add-option" > {verify ? <FaPencilAlt className="icon" data-toggle="modal" data-target="#exampleProfileModal" /> : ""}</div>
                                </div>
                                <div className="headings">
                                    <div className="edit-top">
                                        <div className="pedit2 add-option " > {verify ? <FaPencilAlt onClick={() => EditProfile()} className="icon" data-toggle="modal" data-target="#exampleProfileModal" /> : ""}</div>

                                    </div>
                                    {timeouts ? <><div className="title">
                                        {collegeData ? collegeData.name : ""}
                                    </div>
                                        <div className="sub-head">
                                            {(collegeData !== null && collegeData.country !== null) ? <img src="images/location-icon.png" /> : ""} {(collegeData !== null && collegeData.country !== null) ? `${collegeData.country}` : ""}
                                        </div></> : ""}
                                </div>
                            </div>
                        </div>

                        <a className="scroll-down" href="#about">
                            <a href="#about"><img src="./images/scroll-down.svg" alt="" id="#feed-sec" /></a> <br />
                            {/* <a href="#about"><img src="./images/Icon-material-mouse.svg" alt="" /></a> <br /> */}
                            <a href="#about">  scroll down</a>
                        </a>
                        <div className="artwork carousel-icon ">
                            {collegeData && collegeData.twitterUrl ? <a href={collegeData ? collegeData.twitterUrl : ""} className="mx-2" target="_blank"><img src="images/twitter.svg" alt="fb icon" /></a> : ""}
                            {collegeData && collegeData.facebookUrl ? <a href={collegeData ? collegeData.facebookUrl : ""} className="mx-2" target="_blank"><img src="images/facebook.svg" alt="fb icon" /></a> : ""}
                            {collegeData && collegeData.instagramUrl ? <a href={collegeData ? collegeData.instagramUrl : ""} className="mx-2" target="_blank"><img src="images/instagram.svg" alt="fb icon" /></a> : ""}
                        </div>
                        <div className="edit add-option landing-img-edit" >
                            {verify ? <FaPencilAlt onClick={() => redirectTolist()} className="icon " /> : ""}</div>
                        <div className="carousel-inner landing-carousel" id="bannerelem">
                            {banner ? banner.map((bimage, index) => {
                                return (
                                    <div data-interval={3000} className={index == 0 ? `carousel-item active` : "carousel-item"} key={index}>
                                        <div className="landing-carousal-item caraousal-slide-1">

                                            {bimage.titleLink ? <a className="caraousal-image" href={bimage.titleLink ? bimage.titleLink : ""} target={bimage.titleLink ? "_blank" : ""} >
                                                <img src={bimage ? bimage.imageUrl : "images/bannerbg.png"} />
                                            </a> : <img src={bimage ? bimage.imageUrl : "images/bannerbg.png"} className="caraousal-image" />}
                                        </div>
                                    </div>
                                )
                            }) : ""}
                        </div>
                        {/* Left and right controls */}
                        {banner && banner.length > 1 ? <a className="carousel-control-prev control" href="#home" data-slide="prev">
                            {/* <span className="carousel-control-prev-icon"></span> */}
                            <img src="images/chevron.svg" className="prev icon"></img>
                        </a> : ""}
                        {banner && banner.length > 1 ? <a className="carousel-control-next control" href="#home" data-slide="next">
                            {/* <span className="carousel-control-next-icon"></span> */}
                            <img src="images/chevron.svg" className="next icon"></img>

                        </a> : ""}


                    </section>

                    <div className="modal fade xy-form"
                        id="exampleProfileModal"
                        tabIndex="10"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true">

                        <div className="modal-dialog modal-lg modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header form-mdlheadr">
                                    <h4 className="modal-title mdl-heading">Edit</h4>
                                    <button type="button" className="close modalclose-btn" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    {verify ? <CollegeProfiles id={collegeData.id} GetcollegeProfiles={GetProfiles} show={show} setShow={setShow} modalid={`exampleProfileModal`} /> : ""}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="profile-tab feed-section container-fluid" id="about">
                        {/* <!-- about-us --> */}
                        <div className="stat-sec profile-sub-sec  ">
                            <div className="about-sec content theme-container">
                                <div className="title line">
                                    About Us
                                </div>

                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="about-card feed-card">
                                            <div className="img-box">
                                                <img src={(collegeAboutUsData && collegeAboutUsData.image) ? collegeAboutUsData.image : "./images/post-1.svg"} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="title">
                                            {collegeAboutUsData ? collegeAboutUsData.heading : ""}
                                            <div className="float-right add-option" > {verify ? <FaPencilAlt className="icon" data-toggle="modal" data-target="#exampleModaladd" /> : ""}</div>
                                        </div>

                                        <div className="list">
                                            <div className="list-item">
                                                <img src="./images/about.png" alt="" className="icon" />
                                                <div className="headings">
                                                    <div className="sub-head">About Us</div>
                                                    <div className="text">
                                                        {collegeAboutUsData ? collegeAboutUsData.description : ""}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="list-item">
                                                <img src="./images/planting.png" alt="" className="icon" />
                                                <div className="headings">
                                                    <div className="sub-head">Founded</div>
                                                    <div className="text">

                                                        {collegeAboutUsData ? collegeAboutUsData.founded : ""}

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="list-item">
                                                <img src="./images/__TEMP__SVG__.png" alt="" className="icon" />
                                                <div className="headings">
                                                    <div className="sub-head">Website</div>
                                                    <div className="text">
                                                        {collegeAboutUsData ? collegeAboutUsData.website : ""}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="list-item">
                                                <img src="./images/headquater.png" alt="" className="icon" />
                                                <div className="headings">
                                                    <div className="sub-head">Headquarters</div>
                                                    <div className="text">
                                                        {collegeAboutUsData ? collegeAboutUsData.headquerters : ""}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="list-item">
                                                <img src="./images/users3.png" alt="" className="icon" />
                                                <div className="headings">
                                                    <div className="sub-head">Number Of Students</div>
                                                    <div className="text">
                                                        {collegeAboutUsData ? collegeAboutUsData.noOfStudents : ""}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="list-item">
                                                <img src="./images/qualification.png" alt="" className="icon" />
                                                <div className="headings">
                                                    <div className="sub-head">Qualification Offered</div>
                                                    <div className="text">
                                                        {collegeAboutUsData ? collegeAboutUsData.qualificationsOffered : ""}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="list-item">
                                                {collegeAboutUsData.inPersonTeaching ? <div className="display-inline headings">
                                                    <img src="./images/teaching.png" alt="" className="icon mt-1 mb-2" />
                                                    <div className="sub-head">In-person teaching</div>
                                                </div> : ""}
                                            </div>
                                            <div className="list-item">

                                                {collegeAboutUsData.onlineTeaching ? <div className="display-inline headings">
                                                    <img src="./images/online-teaching.png" alt="" className="icon mt-0 mb-2" />
                                                    <div className="sub-head">Online teaching</div>
                                                </div> : ""}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade xy-form"
                                    id="exampleModaladd"
                                    tabIndex="-1"
                                    role="dialog"
                                    aria-labelledby="exampleModalLabel"
                                    aria-hidden="true">

                                    <div className="modal-dialog modal-lg modal-dialog-centered" >
                                        <div className="modal-content">
                                            <div className="modal-header form-mdlheadr">
                                                <h4 className="modal-title mdl-heading">Edit</h4>
                                                <button type="button" className="close modalclose-btn" data-dismiss="modal">&times;</button>
                                            </div>
                                            <div className="modal-body">
                                                {verify ? <CollegeAboutus id={collegeAboutUsData.collegeId} id12={collegeData.id} GetcollegeProfiles={GetProfiles} GetcollegeAboutus={GetAboutUs} modalid={`exampleModaladd`} /> : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- courses --> */}
                        <div className="profile-tab stat-sec content profile-sub-sec" id="course">
                            <div className="courses-sec content theme-container" >
                                <div className="d-flex justify-content-between">
                                    <div className="title line">
                                        Courses
                                    </div>
                                    <div className="float-right"> {verify ? <div onClick={() => EditCourse()} className="add-option"><span></span><a className="icon" data-toggle="modal" data-target="#exampleCourseModaladd">
                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                    </a></div> : ""}</div>
                                </div>
                                <div className="container-fluid overflow-cards scrollbar" id="scrollbar">
                                    <div className="row">
                                        {collegeCoursesData ?
                                            collegeCoursesData.map((course, index) => {
                                                return (<div className="card-column col-xl-3 col-lg-4 col-md-6 col-12 " key={index}>
                                                    <div className="img-container">
                                                        <img src={course ? course.collegeCourses[0].industry.imageUrl : "./images/course-1.png"} alt="Avatar" className="image" /><span className="title2">{course ? course.industryName : ""}</span>
                                                        <div className="middle">
                                                            <div className="front-text">
                                                                <div className="title2">
                                                                    {course.collegeCourses[0].industry.name}
                                                                </div>
                                                                <div className="max-height-card">

                                                                    {course.collegeCourses ? course.collegeCourses.map((coursedata) => {
                                                                        return (<div onClick={() => rediectLink(coursedata.url)}  >
                                                                            <div className="sub-head ">
                                                                                {coursedata.collegeCourseName ? `${coursedata.collegeCourseName}` : ""}
                                                                                {coursedata.nfqlLevel ? ` | ${coursedata.nfqlLevel}` : ""} | {coursedata.duration ? coursedata.duration == 1 ? `${coursedata.duration} year` : `${coursedata.duration} years` : ""}
                                                                                <br />
                                                                            </div>
                                                                        </div>)
                                                                    }) : ""}
                                                                </div>

                                                            </div>
                                                            <button className="readmore-btn"> {verify ? <div className="add-option"><FaPencilAlt className="icon" onClick={() => redirect()} /></div> : ""}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                )
                                            }) : ""}
                                    </div>
                                </div>

                            </div>
                            <div className="modal fade xy-form"
                                id="exampleCourseModaladd"
                                tabIndex="-1"
                                role="dialog"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true">

                                <div className="modal-dialog modal-lg modal-dialog-centered" >
                                    <div className="modal-content">
                                        <div className="modal-header form-mdlheadr">
                                            <h4 className="modal-title mdl-heading">Edit</h4>
                                            <button type="button" id={'exampleCourseModaladd'} className="close modalclose-btn" data-dismiss="modal">&times;</button>
                                        </div>
                                        <div className="modal-body" >
                                            {verify ? <CollegeCourses id2="0" GetcollegeCourses={GetCourses} show={show4} setShow4={setShow} modalid={`exampleCourseModaladd`} /> : ""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- news-events --> */}
                        <div className="profile-tab stat-sec profile-sub-sec  " id="news">
                            <div className="news-sec content theme-container">
                                <div className="title line">
                                    news and events
                                </div>
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="news-card">
                                            <div className="tab-heading  add-btn">

                                                {verify ? <div className="add-option "><span></span><a className="icon" data-toggle="modal" data-target="#exampleNewsAdd">
                                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                                </a></div> : ""}
                                            </div>
                                            <div className="line tab-heading">
                                                news
                                            </div>
                                            <div id="carouselExampleIndicators" className="carousel  slide" data-ride="carousel">
                                                <div >
                                                    <ol className="carousel-indicators carousel-dots" id="dotselem">
                                                        {collegeNewsData
                                                            ? collegeNewsData.map((items, indexs) => <li data-target="#carouselExampleIndicators" key={indexs + items} data-slide-to={`${indexs}`} className={indexs === 0 ? 'active' : ""}></li>) : ""}
                                                    </ol>
                                                </div>
                                                <div className="carousel-inner" id="newselem">

                                                    {collegeNewsData
                                                        ? collegeNewsData.map((item, index) => {
                                                            return (
                                                                <div key={index + item} className={index === 0 ? 'carousel-item active' : "carousel-item"} >
                                                                    <div>
                                                                        {verify ? <div className=" edit2 add-option"><FaPencilAlt onClick={() => EditNews()} className="icon" data-toggle="modal" data-target={`#exampleMediaAdd${index}`} /></div> : ""}
                                                                        <div className="img-block">
                                                                            <img src={item ? item.imageUrl : "./images/news-img.png"} alt="" />
                                                                        </div>
                                                                        <div className="tab-heading pb-0 mb-1 mt-3" style={{ cursor: "pointer" }} data-toggle="modal" data-target={`#exampleMediaPopup${index}`}>
                                                                            {collegeNewsData ? item.title : "free plans are coming this week!"}
                                                                        </div>
                                                                        <div className="detail" style={{ cursor: "pointer" }} data-toggle="modal" data-target={`#exampleMediaPopup${index}`}>
                                                                            {item.description ? isReadMore == false ? item.description.slice(0, 210) : item.description : ""}{item.description && item.description.length ? <button data-toggle="modal" data-target={`#exampleMediaPopup${index}`} id="readmore-btn">...read more </button> : ""}
                                                                        </div>

                                                                        {/* {isReadMore == true ? <button onClick={() => setIsReadMore(false)} id="readmore-btn" >Show Less</button> : ""} */}
                                                                    </div>

                                                                </div>
                                                            );
                                                        })
                                                        : ""}
                                                </div>
                                                {collegeNewsData ? collegeNewsData.map((item, index) => {
                                                    return (<>
                                                        <div className="modal fade  apply-modal "
                                                            key={index + item + 1}
                                                            id={`exampleMediaPopup${index}`}
                                                            tabIndex="-1"
                                                            role="dialog"
                                                            aria-labelledby="exampleModalLabel"
                                                            aria-hidden="true">
                                                            <div class="modal-dialog modal-lg modal-dialog-centered">
                                                                <div class="modal-content">
                                                                    <button type="button" class="close modalclose-btn pedit2" data-dismiss="modal">&times;</button>

                                                                    <div class="modal-header">
                                                                        <div class="modal-title mdl-heading mt-2">{item.title}</div>
                                                                        <h6 class="mt-2 j-type-name">Date: {moment(item.date).format("DD")} {moment(item.date).format("MMM")} {moment(item.date).format("YYYY")}</h6>

                                                                        {/* <div class="user-location">
                                                                                        <img src="images/location-icon.png" />{`${item.city},${item.state}`}
                                                                                    </div> */}

                                                                    </div>
                                                                    <div class="modal-body custom-padding">
                                                                        <div class="container-fluid pr-0">
                                                                            <div class="row mt-0 mr-0 justify-content-between">
                                                                                <div class="col-sm-8">
                                                                                    <div class="modalbody-content">
                                                                                        {/* <h6>Date:{moment(item.date).format("DD/MM/YYYY")} </h6> */}
                                                                                        <p class="desc"> {item.description ? item.description : ""}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-4">
                                                                                    <img src={collegeNewsData ? item.imageUrl : "./images/news.png"} alt="" className="img-fluid fix-h" />
                                                                                    {/* <div class="job-description">
                                                                                                <div class="j-type-name">JOB TYPE</div>
                                                                                                <div class="j-type">Full Time</div>
                                                                                            </div> */}

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="modal fade xy-form"
                                                            key={index + 1}
                                                            id={`exampleMediaAdd${index}`}
                                                            tabIndex="-1"
                                                            role="dialog"
                                                            aria-labelledby="exampleModalLabel"
                                                            aria-hidden="true">

                                                            <div className="modal-dialog form-w modal-dialog-centered">
                                                                <div className="modal-content">
                                                                    <div className="modal-header form-mdlheadr">
                                                                        <h4 className="modal-title mdl-heading">Edit</h4>
                                                                        <button type="button" id={'exampleMediaAdd' + index} className="close modalclose-btn" data-dismiss="modal">&times;</button>
                                                                    </div>
                                                                    <div className="modal-body ">
                                                                        {verify ? <CollegeNews id={item.id} GetCollegeNews={GetNews} show={show2} setShow2={setShow2} modalid={`exampleMediaAdd${index}`} /> : ""}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>)
                                                }) : ""}
                                                <div className="modal fade xy-form"
                                                    id="exampleNewsAdd"
                                                    tabIndex="-1"
                                                    role="dialog"
                                                    aria-labelledby="exampleModalLabel"
                                                    aria-hidden="true">

                                                    <div className="modal-dialog form-w modal-dialog-centered" >
                                                        <div className="modal-content">
                                                            <div className="modal-header form-mdlheadr">
                                                                <h4 className="modal-title mdl-heading">Add News</h4>
                                                                <button type="button" className="close modalclose-btn" data-dismiss="modal">&times;</button>
                                                            </div>
                                                            <div className="modal-body">
                                                                {verify ? <CollegeNews id2="0" GetCollegeNews={GetNews} show={show2} setShow2={setShow2} modalid={`exampleNewsAdd`} /> : ""}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Left and right controls  */}
                                                {/* <a className="carousel-control-prev" href="#demo" data-slide="prev">
                                                    <span className="carousel-control-prev-icon"></span>
                                                </a>
                                                <a className="carousel-control-next" href="#demo" data-slide="next">
                                                    <span className="carousel-control-next-icon"></span>
                                                </a> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="news-card event-card scrollbar" id="scrollbar">
                                            <div className="tab-heading add-btn">

                                                {verify ? <div className="add-option add-btn"><span></span><a className="icon" data-toggle="modal" data-target="#exampleModaladdEvents">
                                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                                </a></div> : ""}
                                            </div>
                                            <div className="line tab-heading ">
                                                events
                                            </div>

                                            {collegeEventData ? collegeEventData.map((event, index) => {
                                                return (<div key={index}>
                                                    <div className="display-inline event start"  >
                                                        <div className="img-block" data-toggle="modal" data-target={`${"#exampleModalImage"}${index}`}>
                                                            <div>{moment(event.fromDate).format("M") == moment(event.toDate).format("M") ?
                                                                moment(event.fromDate).format("DD") == moment(event.toDate).format("DD") ?
                                                                    ` ${moment(event.fromDate).format("DD")} ${moment(event.fromDate).format("MMM")} ${moment(event.fromDate).format("YYYY")}` :
                                                                    ` ${moment(event.fromDate).format("DD")} - ${moment(event.toDate).format("DD")} ${moment(event.toDate).format("MMM")} ${moment(event.fromDate).format("YYYY")} ` :
                                                                ` ${moment(event.fromDate).format("DD")} ${moment(event.fromDate).format("MMM")} ${moment(event.fromDate).format("YYYY")} - ${moment(event.toDate).format("DD")} ${moment(event.toDate).format("MMM")} ${moment(event.toDate).format("YYYY")} `}</div>
                                                        </div>
                                                        <div className="headings">
                                                            <div className="tab-heading pb-0 mb-0" ><span data-toggle="modal" data-target={`${"#exampleModalImage"}${index}`}>{event.title}</span>{verify ? <div className="edit2 add-option "><FaPencilAlt onClick={() => EditEvent()} className=" icon" data-toggle="modal" data-target={`${"#exampleModalEvent"}${index}`} /></div> : ""}</div>
                                                            <div className="detail" data-toggle="modal" data-target={`${"#exampleModalImage"}${index}`}>
                                                                {event.description.length >= 70 ? `${event.description.slice(0, 70)}` : event.description} {event.description && event.description.length ? <button data-toggle="modal" data-target={`${"#exampleModalImage"}${index}`} id="readmore-btn">...read more </button> : ""}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="divider"></div>
                                                    <div className="modal fade xy-form"
                                                        id={`${"exampleModalEvent"}${index}`}
                                                        tabIndex="-1"
                                                        role="dialog"
                                                        aria-labelledby="exampleModalLabel"
                                                        aria-hidden="true">

                                                        <div className="modal-dialog form-w modal-dialog-centered" >
                                                            <div className="modal-content">
                                                                <div className="modal-header form-mdlheadr">
                                                                    <h4 className="modal-title mdl-heading">Edit</h4>
                                                                    <button type="button" id={'exampleModalEvent' + index} className="close modalclose-btn" data-dismiss="modal">&times;</button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    {verify ? <CollegeEvents id={event.id} GetCollegeEvent={GetEvent} show={show3} setShow3={setShow3} modalid={`${"exampleModalEvent"}${index}`} /> : ""}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="modal fade apply-modal"
                                                        id={`${"exampleModalImage"}${index}`}
                                                        tabIndex="-1"
                                                        role="dialog"
                                                        aria-labelledby="exampleModalLabel"
                                                        aria-hidden="true"
                                                    >
                                                        <div className="modal-dialog modal-lg modal-dialog-centered">
                                                            <div className="modal-content">
                                                                <button type="button" className="close modalclose-btn pedit2" data-dismiss="modal">&times;</button>
                                                                <div className="modal-header">
                                                                    <div className="bg-img-box">
                                                                        <img src={event ? event.imageUrl : ""} className="img-fluid bg-img" />
                                                                    </div>
                                                                    <div className="icon">
                                                                        <img style={{ borderRadius: "100px" }} src={collegeData && collegeData.logo ? collegeData.logo : ""} className="img m" />
                                                                    </div>
                                                                    <div className="modal-title mdl-heading mt-4">{event.title}</div>
                                                                    {/* <div className="user-location"> */}
                                                                    {/* <img src="images/location-icon.png" />{`${event.city},${event.state}`} */}
                                                                    {/* </div> */}
                                                                    <div className="j-type-name d-flex justify-content-center mt-3">
                                                                        Event Date :
                                                                        <div className="j-type pl-1"> {moment(event.fromDate).format("M") == moment(event.toDate).format("M") ?
                                                                            moment(event.fromDate).format("DD") == moment(event.toDate).format("DD") ?
                                                                                ` ${moment(event.fromDate).format("DD")} ${moment(event.fromDate).format("MMM")} ${moment(event.fromDate).format("YYYY")}` :
                                                                                ` ${moment(event.fromDate).format("DD")} - ${moment(event.toDate).format("DD")} ${moment(event.toDate).format("MMM")} ${moment(event.fromDate).format("YYYY")} ` :
                                                                            ` ${moment(event.fromDate).format("DD")} ${moment(event.fromDate).format("MMM")} ${moment(event.fromDate).format("YYYY")} - ${moment(event.toDate).format("DD")} ${moment(event.toDate).format("MMM")} ${moment(event.toDate).format("YYYY")} `}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="modal-body custom-padding ">
                                                                    <div className="container-fluid pr-0">
                                                                        <div className="row justify-content-between">
                                                                            <div className="col-md-8">

                                                                                <p className="detail">  {event.description ? `${event.description}` : ""}
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-md-4 job-details">
                                                                                <div className="sub-head text-left">
                                                                                    Fill Your Details
                                                                                </div>
                                                                                <form autoComplete="off">
                                                                                    <div className="row xy-input-grp">
                                                                                        <div className="col-md-6">
                                                                                            {verify ? <input type="text" className="form-control xu-input" onChange={(e) => eventChange(e)} name="firstName" value={eventModel.firstName} placeholder="First Name" disabled /> : <input type="text" className="form-control xu-input" onChange={(e) => eventChange(e)} name="firstName" value={eventModel.firstName} placeholder="First Name" />}
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            {verify ? <input type="text" className="form-control xu-input" onChange={(e) => eventChange(e)} name="lastName" value={eventModel.lastName} placeholder="Last Name" disabled /> : <input type="text" className="form-control xu-input" onChange={(e) => eventChange(e)} name="lastName" value={eventModel.lastName} placeholder="Last Name" />}
                                                                                        </div>
                                                                                        <div className="col-md-12">
                                                                                            {verify ? <input type="email" className="form-control xu-input" onChange={(e) => eventChange(e)} name="email" value={eventModel.email} placeholder="email" disabled /> : <input type="email" className="form-control xu-input" onChange={(e) => eventChange(e)} name="email" value={eventModel.email} placeholder="email" />}
                                                                                        </div>
                                                                                        <div className="col-md-12">
                                                                                            {verify ? <input type="number" className="form-control xu-input" onChange={(e) => eventChange(e)} name="contact" value={eventModel.contact} placeholder="+91-9383903823" disabled /> : <input type="number" className="form-control xu-input" onChange={(e) => eventChange(e)} name="contact" value={eventModel.contact} placeholder="+91-9383903823" />}
                                                                                        </div>
                                                                                    </div>
                                                                                    <a className="btn btn-outline a-btn mx-auto d-block" onClick={() => verify ? AttendEvent(event.id, "type") : AttendEvent(event.id)}>Attend</a>
                                                                                </form>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>


                                                    </div>


                                                </div>

                                                )
                                            }) : ""}
                                        </div>
                                        <div className="modal fade xy-form"
                                            id="exampleModaladdEvents"
                                            tabIndex="-1"
                                            role="dialog"
                                            aria-labelledby="exampleModalLabel"
                                            aria-hidden="true">

                                            <div className="modal-dialog modal-dialog-centered" >
                                                <div className="modal-content">

                                                    <div className="modal-header form-mdlheadr">
                                                        <h4 className="modal-title mdl-heading">Edit</h4>
                                                        <button type="button" id={'exampleModaladdEvents'} className="close modalclose-btn" data-dismiss="modal">&times;</button>
                                                    </div>
                                                    <div className="modal-body">
                                                        {verify ? <CollegeEvents id2="0" GetCollegeEvent={GetEvent} show={show3} setShow3={setShow3} modalid={`exampleModaladdEvents`} /> : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <!-- contact --> */}
                        <section className="section  bg-sec " >
                            <div className="profile-tab" id="contacts">
                                <div className="contact-sec ">
                                    <div className="title line">
                                        contact
                                    </div>
                                    <div className="row justify-content-center align-items-center">
                                        <div className="col-md-6">
                                            <div className="contacts">
                                                <img src="./images/contact-illu.png" alt="" className="img" />
                                                <div className="extra-info ">
                                                    <div className="sub-title">
                                                        <img src="./images/call-icon.png" alt="" />
                                                        <div className="contact-details">
                                                            <span > contact number</span><br /><a style={{ color: "black" }} href={`tel:+${collegeData.contact}`}>+{collegeData.contact}</a>
                                                        </div>
                                                    </div>
                                                    <div className="sub-title">
                                                        <img src="./images/mail-icon.png" alt="" />
                                                        <div className="contact-details"> <span>Email </span> <br /> <a style={{ color: "black" }} href={`mailto:${collegeData.email}`}
                                                            title={collegeData.email} target="_blank"> {collegeData.email}</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="bg-lines">
                                                <div className="login-box type-box">
                                                    {/* <!-- <div className="sign-heading">
                                                        location
                                                    </div> --> */}
                                                    <div className="form-sec">
                                                        <form action="" autoComplete="off">
                                                            <div>
                                                                <div className="label">
                                                                    name
                                                                </div>
                                                                {verify ? <input type="text" id="senderName" value={contactModel.senderName} onChange={(e) => ChangeHandler(e)} className={border.senderName ? `${border.senderName}` : "xu-input"} disabled /> : <input type="text" id="senderName" value={contactModel.senderName} onChange={(e) => ChangeHandler(e)} className={border.senderName ? `${border.senderName}` : "xu-input"} />}
                                                            </div>
                                                            <div>
                                                                <div className="label">
                                                                    email
                                                                </div>
                                                                {verify ? <input type="text" className={border.senderEmail ? `${border.senderEmail}` : "xu-input"} id="senderEmail" value={contactModel.senderEmail} onChange={(e) => ChangeHandler(e)} disabled /> : <input type="text" className={border.senderEmail ? `${border.senderEmail}` : "xu-input"} id="senderEmail" value={contactModel.senderEmail} onChange={(e) => ChangeHandler(e)} />}
                                                            </div>
                                                            <div>
                                                                <div className="label">
                                                                    Message
                                                                </div>
                                                                {verify ? <textarea type="text" className={border.message ? `${border.message}` : "xu-textarea"} value={contactModel.message} id="message" onChange={(e) => ChangeHandler(e)} disabled></textarea> : <textarea type="text" className={border.message ? `${border.message}` : "xu-textarea"} value={contactModel.message} id="message" onChange={(e) => ChangeHandler(e)}></textarea>}
                                                            </div>

                                                        </form>
                                                        {verify ? <button className="btn sign-btn btn-outline big-btn mt-0" onClick={(e) => contactHandle(e, "type")}>Send Message</button> : <button className="btn sign-btn btn-outline big-btn mt-0" onClick={(e) => contactHandle(e)}>Send Message</button>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </section>
            {loader}
        </>

    )
}
export default CollegeProfile