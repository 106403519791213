import Common from "../../Utility/Common";
const StudentAchivementModel = () => ({
        id: null,
        studentId: Common.LoginId(),
        title: '',
        description: '',
        image: null,
        active: true,
        year: '',
        userId: Common.UserId(),

})
export default StudentAchivementModel
