import React, { useState, useEffect, useMemo } from "react";
import service from "../../../Services/UtilityService";
import ServiceAPI from "../../../Utility/ServiceList";
import TableView from "../../../Utility/TableView";
import AdminNav from "./StudentNav";
import AdminHeader from '../../Common/AdminHeader';
import Common from "../../../Utility/Common";
import TableList from "../../../Utility/TableList";
import moment from "moment";
import useLoader from "../../Auth/Loader"
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useHistory } from "react-router-dom";
import $ from "jquery";

const StudentEducationList = () => {
  const history = useHistory();
  localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
  const [itemlist, setItemList] = useState([]);
  const [model, setModel] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [loader, showLoader, hideLoader] = useLoader();
  const [pageModel, setPageModel] = useState({
    "pageNum": 1,
    "pageSize": 10
  });
  var services = new service();
  const data = itemlist;
  const columns = useMemo(
    () => [
      {
        Header: 'Institution',
        accessor: 'institute',
      }, {
        Header: 'Industry',
        accessor: d => d.industry ? d.industry.name : '',
      }, {
        Header: 'Qualification',
        accessor: d => d.qualifications ? d.qualifications.name : '',
      }, {
        Header: 'Course Name',
        accessor: 'studyProgram',
      }, {
        Header: 'Start Date',
        accessor: 'startDate',
        Cell: (props) => {
          return (
            <div>
              {moment(props.value).format("DD-MM-YYYY")}
            </div>
          )
        },
      }, {
        Header: 'End Date',
        accessor: d => d.isPursuing == false ? d.endDate : 'isPursuing',
        Cell: (props) => {
          if (props.value == 'isPursuing') {
            return (
              <div>
                Pursuing
              </div>
            )
          } else {
            return (
              <div>
                {moment(props.value).format("DD-MM-YYYY")}
              </div>
            )
          }
        },
      }, {
        Header: "Edit",
        accessor: "edit",
        Cell: ({ row }) => {
          const itemID = data.map((item, index) => item.id);
          return (
            <label>
              <a
                onClick={() => editfunction(itemID[row.index])}
                className="fa fa-pencil edit"
                aria-hidden="true"
              />
            </label>
          );
        },
      }, {
        Header: "Delete",
        accessor: "delete",
        Cell: ({ row }) => {
          const itemID = data.map((item, index) => item.id);
          return (

            <label>
              <a
                onClick={() => Delete(itemID[row.index], row.index)}
                className="fa fa-trash delete"
                aria-hidden="true"
              />
            </label>

          );
        },
      }

    ], [data])

  useEffect(() => {
    GetAll();
  }, []);

  useEffect(() => {
  }, [pageModel]);

  async function GetAll() {
    $('.modal-backdrop').removeClass('show ');
    $('.modal').removeClass('show');
    $('body').toggleClass('p-0');
    $('body').removeClass('modal-open');
    showLoader()
    var res = await services.GetDataById(ServiceAPI.StudentEducationGetAllApi, Common.LoginId(), pageModel);
    hideLoader()
    const model = await res.metaData;
    setModel(model.totalCount);
    const data = await res.result;
    setItemList([...itemlist, ...data]);
    setPageModel({
      "pageNum": pageModel.pageNum + 1,
      "pageSize": 10,
      "isActive": false
    })
  }

  const editfunction = (id) => {
    history.push("/studenteducation?id=" + id);
  };

  const Delete = (id, index) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="xy-form">
            <div className="mdl-heading">Delete Record</div>
            <div className="desc">Are you sure to delete it?</div>
            <button className="btn btn-outline s-btn pr" onClick={onClose}>No</button>
            <button className="btn btn-outline s-btn pr"
              onClick={async () => {
                let item = itemlist.filter((data) => data.id != id);
                await services.Delete(ServiceAPI.StudentEducationDeleteApi, id).then(async () => {
                  var res = await services.GetDataById(ServiceAPI.StudentEducationGetAllApi, Common.LoginId(), {
                    "pageNum": parseInt((item.length / 10) + 1),
                    "pageSize": 10,
                    "isActive": false
                  });
                  const model = await res.metaData;
                  const data = await res.result;
                  if (model.totalPages > parseInt((index / 10) + 1)) {
                    if (itemlist.length === 10 * model.currentPage && data.length > 9) {
                      setItemList([...item, data[data.length - 1]]);
                    } else {
                      setItemList(item)
                    }
                  } else {
                    setItemList(item)
                  }
                })
                onClose();
              }}
            >
              Yes
            </button>
          </div>
        );
      }
    });
  };

  const fetchMoreData = () => {
    if (model > itemlist.length) {
      GetAll()
    } else {
      setHasMore(false)
    }
  };
  const newdata = useMemo(() => itemlist, [itemlist]);
  const redirectlist = (link) => {
    history.push(link)
  }
  return (
    <>
      <AdminHeader heading="" subheading="Education List" />
      <form>
        <div className="theme-container-admin min-h">
          <AdminNav education="active"></AdminNav>
          <div className="table-sec">
            <div className="add-option">
              <div className="title"></div>
              <a className="icon" onClick={() => redirectlist("/studenteducation")}>
                <i className="fa fa-plus" aria-hidden="true"></i>
              </a>
            </div>
            <TableList
              data={newdata}
              columns={columns}
              update={fetchMoreData}
              hasMore={hasMore}
            />
          </div>
        </div>
      </form>
      {loader}
    </>
  );

}
export default StudentEducationList
