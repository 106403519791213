import $ from 'jquery'
const AdminHeader = (props) => {
  const closeSearch = () => {
    if ($(".globsr").hasClass("active")) {
      if ($("#globsearch").val()) {
        // alert($("#globsearch").val())
        $("#new-users-list").removeClass("show")
      }
      else {
        $(".global").removeClass("active")
        $("#globsearch").removeClass("active")
      }
    }
  }
  return (
    <div className="container-fluid" onClick={() => closeSearch()}>

      <div className="row justify-content-between title-row align-items-center">
        <div className="title">
          {props.heading}
          <div className="sub-title">{props.subheading}</div>
        </div>
      </div>
    </div>
  )
}
export default AdminHeader;