import React, { useState, useEffect } from "react";
import ServiceAPI from "../Utility/ServiceList";
import service from '../Services/UtilityService';
import AlphabetList from 'react-alphabet-list';
import { Tag } from "antd";
import { toast } from "react-toastify";
import $ from "jquery";
import Common from "../Utility/Common";
import "antd/dist/antd.css";
import Multiselect from "./Auth/multiselect-dropdown";
import Select from 'react-select'
import useLoader from "./Auth/Loader"
import { useHistory } from "react-router-dom";

export const JobsAndIntership = () => {
    const history = useHistory();
    const style = {
        paddingRight: "0px",
        color: "black"
    };
    var activetest = false; var activetest1 = false; var activetest2 = false; var activetest3 = false; var activetest4 = false; var activetest5 = false; var activetest6 = false
    const [jobslist, setJobsList] = useState([]); const [jobcategorylist, setJobCategoryList] = useState();
    const [alldata, setAllData] = useState([]);
    const [industrylist, setIndustryList] = useState();
    const [remote, setRemote] = useState(false);
    const [coutnrylist, setCountryList] = useState([])
    const [statelist, setStateList] = useState([])
    const [citylist, setCityList] = useState([])
    const [qualificationlist, setQualificationList] = useState()
    const [count, setCount] = useState();
    const [count1, setCount1] = useState();
    const [count2, setCount2] = useState();
    const [count3, setCount3] = useState();
    const [count4, setCount4] = useState()
    const [showcount, setShowCount] = useState(false);
    const [show, setShow] = useState(true);
    const [loader, showLoader, hideLoader] = useLoader();
    const [clear, setClear] = useState(true)
    const [locationData, setLocationData] = useState([]);
    const [filtermodel, setFilterModel] = useState(
        {
            "parameter": {
                "pageNum": 1,
                "pageSize": 1000
            },
            "filterModel": {
                "country": "", "city": "", "state": "", "industry": [],
                "graduateYear": [], "nqfl": [], "qualification": [],
                "studyType": [], "sizeOfEmployeesMin": 0, "sizeOfEmployeesMax": 0,
                "topAchiever": false, "isHiringActive": false,
                "JobCategories": [], "IsRemote": false
            }
        }
    );
    var services = new service();
    useEffect(() => {
        GetIndustry();
        GetQualification();
        getLocation();
        GetAll();
        GetJobCategory();
    }, []);
    const fetchData = (item) => {
        const url = alldata.map((data) => {
            if (data.id === item.split(",")[1]) {
                const sa = data.company && data.company.companyAboutUs && data.company.companyAboutUs.remoteWorking ? data.categoryTypes && data.jobCategory.length - 2 > 0 ? data.jobCategory.length - 2 : "" : data.categoryTypes && data.jobCategory.length - 3 > 0 ? data.jobCategory.length - 3 : ""
                return [`${data.title} --`, `${data.city} --`, `${data.state} --`, `${data.description} --`, `${data.company.logo} --`, `${data.toolkitTypes} --`, `${data.categoryTypes ? data.categoryTypes.split(',').join('|') : ""} --`, `${sa} --`, `${data.company && data.company.companyAboutUs && data.company.companyAboutUs.remoteWorking ? data.company.companyAboutUs.remoteWorking : false}  --`, `${data.companyURL}`]
            }
        }
        )
        return url.join('').split('--,')
    }
    const GetIndustry = async () => {
        var res = await services.PostAnonymous(ServiceAPI.IndustryGetAllApi, {
            "pageNum": 1,
            "pageSize": 100,
            "isActive": true
        });
        const industryData = await res.result.map((item) => {
            return {
                name: item.name,
                value: item.name,
                id: item.id
            }
        });
        industryData.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
        setIndustryList(industryData)
    };
    const GetQualification = async () => {
        var res = await services.PostAnonymous(ServiceAPI.QualificationGetAllApi, {
            "pageNum": 1,
            "pageSize": 100,
            "isActive": true,
            "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
        });
        const industryData = await res.result.map((item) => {
            return {
                name: item.name,
                value: item.name,
                id: item.id
            }
        });
        industryData.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

        setQualificationList(industryData)
    };
    const getLocation = async () => {
        var res = await services.GetAll(ServiceAPI.getLocationCountryApi);
        const data1 = await res.result.map((item) => {
            return { value: item, label: item, type: "country" }
        });
        setCountryList([{ value: 'select', label: 'select', type: "country" }, ...data1])
    }
    const GetState = async (state) => {
        var res = await services.GetLocationById(ServiceAPI.getLocationStateApi, state, "country");
        const data1 = await res.result.map((item) => {
            return { value: item, label: item, type: "state" }
        });
        setStateList([{ value: 'select', label: 'select', type: "state" }, ...data1])
    }
    const GetCity = async (city) => {
        var res = await services.GetLocationById(ServiceAPI.getLocationCityApi, city, "state");
        const data1 = await res.result.map((item) => {
            return { value: item, label: item, type: "city" }
        });
        setCityList([{ value: 'select', label: 'select', type: "city" }, ...data1])
    }
    const ApplyJobs = async (id) => {
        showLoader();
        var res = await services.Create(ServiceAPI.StudentApplyJobApi, {
            "id": "",
            "userId": `${Common.UserId()}`,
            "active": true,
            "studentJobApplyJobId": `${id}`,
            "studentJobApplyStudentId": `${Common.LoginId()}`
        });
        hideLoader();
        if (res && res.message === "Success") {
            $('.modal-backdrop').removeClass('show ');
            $('.modal').removeClass('show');
            $('body').toggleClass('p-0');
            $('body').removeClass('modal-open');
            toast.success('Applied Successfully !', { theme: "colored", autoClose: 2000, toastId: 'success1' })
        } else {
            $('.modal-backdrop').removeClass('show ');
            $('.modal').removeClass('show');
            $('body').toggleClass('p-0');
            $('body').removeClass('modal-open');
        }

    }
    async function GetAll() {
        $('.modal-backdrop').removeClass('show ');
        $('.modal').removeClass('show');
        $('body').toggleClass('p-0');
        $('body').removeClass('modal-open');
        showLoader()
        var res = await services.Create(ServiceAPI.JobAndIntershipFilterApi, filtermodel);
        hideLoader()
        const data1 = await res.result;
        setTimeout(() => {
            setAllData(data1);
            setJobsList(data1.map((data) => `${data.title},${data.id},${data.userId},${data.companyId}`));
        }, 10);
    }
    const filterData = async (filtermodel) => {
        setFilterModel({ ...filtermodel })
        showLoader()
        var res = await services.Create(ServiceAPI.JobAndIntershipFilterApi, filtermodel);
        hideLoader()
        if (res.message == "Success") {
            const data1 = await res.result;
            setTimeout(() => {

                setAllData(data1);
                setJobsList([]);
                setJobsList(data1.map((data) => `${data.title},${data.id},${data.userId},${data.companyId}`));;
            }, 10);
        }
        else {
            setJobsList([]);
        }

    }
    const AddValue = async (e, type) => {
        if (e.type === "country") {
            const value = e.value
            if (value !== "select") {
                GetState(value)
                setFilterModel({ ...filtermodel, "filterModel": { ...filtermodel.filterModel, "country": value } })
                setLocationData({ ...locationData, "country": value });
                filterData({ ...filtermodel, "filterModel": { ...filtermodel.filterModel, "country": value } })
            }
            else {
                setFilterModel({ ...filtermodel, "filterModel": { ...filtermodel.filterModel, "country": '', "state": "", "city": '' } })
                setLocationData({ ...locationData, "state": '' });
                setStateList([])
                setCityList([])
                filterData({ ...filtermodel, "filterModel": { ...filtermodel.filterModel, "country": '', "state": '', "city": '' } })
            }

        }
        else if (e.type === "state") {
            const value = e.value
            if (value !== "select") {
                GetCity(value);
                setFilterModel({ ...filtermodel, "filterModel": { ...filtermodel.filterModel, ...filtermodel.location, "state": value } })
                setLocationData({ ...locationData, "state": value });
                filterData({ ...filtermodel, "filterModel": { ...filtermodel.filterModel, "state": value } })
            }
            else {
                setFilterModel({ ...filtermodel, "filterModel": { ...filtermodel.filterModel, ...locationData, "state": '' } })
                setLocationData({ ...locationData, "state": '' });
                setCityList([])
                filterData({ ...filtermodel, "filterModel": { ...filtermodel.filterModel, "state": '', "city": '', "countrt": '' } })
            }
        }
        else if (e.type === "city") {
            const value = e.value
            if (value !== "select") {
                setFilterModel({ ...filtermodel, "filterModel": { ...filtermodel.filterModel, "city": value } })
                setLocationData({ ...locationData, "city": value });
                filterData({ ...filtermodel, "filterModel": { ...filtermodel.filterModel, "city": value } })
            }
            else {
                setFilterModel({ ...filtermodel, "filterModel": { ...filtermodel.filterModel, "city": '' } })
                setLocationData({ ...locationData, "city": "" });
                filterData({ ...filtermodel, "filterModel": { ...filtermodel.filterModel, "city": '' } })
            }
        }
    }


    const setQualification = (params) => {
        const s = [...params.map((item) => qualificationlist.filter((items) => item == items.name)).map((item) => {
            return item[0].id
        })]
        filterData({ ...filtermodel, "filterModel": { ...filtermodel.filterModel, "qualification": s } })
        setCount1(params.length)

    }
    const Setindustry = (params) => {
        const s = [...params.map((item) => industrylist.filter((items) => item == items.name)).map((item) => {
            return item[0].id
        })]
        filterData({ ...filtermodel, "filterModel": { ...filtermodel.filterModel, "industry": s } })
        setCount(params.length)
    }
    const GetJobCategory = async () => {
        var res = await services.Create(ServiceAPI.CompanyJobCategoryApi, {
            "pageNum": 1,
            "pageSize": 100,
            "isActive": true,
            "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
        });
        const data = res.result.map((item) => {
            return {
                name: item.name,
                value: item.name,
                id: item.id
            }
        });
        data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
        setJobCategoryList(data);
    }
    const resetData = (type) => {
        if (type == "location") {
            setFilterModel({ ...filtermodel, "filterModel": { ...filtermodel.filterModel, ...locationData, "state": "", "country": '' } })
            setLocationData({ ...locationData, "state": '', "country": '' });
            setShowCount(true)
            setCountryList([])
            setStateList([])
            setCityList([])
            filterData({ ...filtermodel, "filterModel": { ...filtermodel.filterModel, "country": '', "state": '', "city": '' } })
            setTimeout(() => {
                setShowCount(false)
            }, 100);
            getLocation()
        }
    }
    const setDropdown = () => {
        if (activetest == false) {
            $(".dropdown1-content").addClass("active")
            $(".alpha-list").addClass('filter-expand')
            $(".cards-list").addClass('filter-expand')
            $(".dropdown1 .btn-outline").addClass("active")
            activetest = true;
        }
        else {
            $(".dropdown1-content").removeClass("active")
            $(".alpha-list").removeClass('filter-expand')
            $(".cards-list").removeClass('filter-expand')
            $(".dropdown1 .btn-outline").removeClass("active")
            if (count || count1 || count2 || count3 || count4 || remote || statelist.length !== 0) {
                $(".dropdown1 .btn-outline").addClass("active")

            }
            activetest = false;
        }
    }
    const setDropdown1 = () => {
        if (activetest1 == false) {
            $(".ind-menu").addClass("show")
            $("#dropdownMenu2").addClass("active")
            $(".location-menu").removeClass("show")
            $("#dropdownMenu3").removeClass("active")
            $(".qual-menu").removeClass("show")
            $("#dropdownMenu4").removeClass("active")
            $(".top-menu").removeClass("show")
            $("#dropdownMenu6").removeClass("active")
            $(".styd-menu").removeClass("show")
            $("#dropdownMenu5").removeClass("active")
            $(".rem-menu").removeClass("show")
            $("#dropdownMenu7").removeClass("active")
            $(".cat-menu").removeClass("show")
            $("#dropdownMenu6").removeClass("active")
            activetest1 = true; activetest2 = false; activetest3 = false; activetest4 = false; activetest5 = false; activetest6 = false
        }
        else {
            $(".ind-menu").removeClass("show")
            $("#dropdownMenu2").removeClass("active")
            activetest1 = false;
        }
    }
    const setFilter = () => {
        $(".dropdown1-content").removeClass("active")
        $(".alpha-list").removeClass('filter-expand')
        $(".cards-list").removeClass('filter-expand')
        $(".dropdown1 .btn-outline").removeClass("active")
        if (count || count1 || count2 || count3 || count4 || remote || statelist.length !== 0) {
            $(".dropdown1 .btn-outline").addClass("active")

        }
        activetest = false;
        $(".loc-menu").removeClass("show")
        $("#dropdownMenu2").removeClass("active")
        $("#dropdownMenu3").removeClass("active")
        $("#dropdownMenu4").removeClass("active")

    }
    const setDropdown2 = () => {
        if (activetest2 == false) {
            $(".location-menu").addClass("show")
            $("#dropdownMenu3").addClass("active")
            $(".ind-menu").removeClass("show")
            $("#dropdownMenu2").removeClass("active")
            $(".qual-menu").removeClass("show")
            $("#dropdownMenu4").removeClass("active")
            $(".rem-menu").removeClass("show")
            $("#dropdownMenu7").removeClass("active")
            $(".cat-menu").removeClass("show")
            $("#dropdownMenu6").removeClass("active")
            activetest2 = true; activetest1 = false; activetest3 = false; activetest4 = false; activetest5 = false; activetest6 = false
        }
        else {
            $(".location-menu").removeClass("show")
            $("#dropdownMenu3").removeClass("active")
            activetest2 = false;
        }
    }
    const setDropdown3 = () => {
        if (activetest3 == false) {
            $(".qual-menu").addClass("show")
            $("#dropdownMenu4").addClass("active")
            $(".location-menu").removeClass("show")
            $("#dropdownMenu3").removeClass("active")
            $(".ind-menu").removeClass("show")
            $("#dropdownMenu2").removeClass("active")
            $(".top-menu").removeClass("show")
            $(".rem-menu").removeClass("show")
            $("#dropdownMenu7").removeClass("active")
            $(".cat-menu").removeClass("show")
            $("#dropdownMenu6").removeClass("active")
            activetest1 = false; activetest2 = false; activetest3 = true; activetest4 = false; activetest5 = false; activetest6 = false
        }
        else {
            $(".qual-menu").removeClass("show")
            $("#dropdownMenu4").removeClass("active")
            activetest3 = false;
        }
    }
    const setDropdown5 = () => {
        if (activetest5 == false) {
            $(".cat-menu").addClass("show")
            $("#dropdownMenu6").addClass("active")
            $(".qual-menu").removeClass("show")
            $("#dropdownMenu4").removeClass("active")
            $(".location-menu").removeClass("show")
            $("#dropdownMenu3").removeClass("active")
            $(".ind-menu").removeClass("show")
            $("#dropdownMenu2").removeClass("active")
            $(".top-menu").removeClass("show")
            $("#dropdownMenu5").removeClass("active")
            $(".rem-menu").removeClass("show")
            $("#dropdownMenu7").removeClass("active")
            activetest1 = false; activetest2 = false; activetest3 = false; activetest4 = false; activetest5 = true; activetest6 = false
        }
        else {
            $(".cat-menu").removeClass("show")
            $("#dropdownMenu6").removeClass("active")
            activetest5 = false;
        }
    }
    const setDropdown6 = () => {
        if (activetest6 == false) {
            $(".rem-menu").addClass("show")
            $("#dropdownMenu7").addClass("active")
            $(".qual-menu").removeClass("show")
            $("#dropdownMenu4").removeClass("active")
            $(".location-menu").removeClass("show")
            $("#dropdownMenu3").removeClass("active")
            $(".ind-menu").removeClass("show")
            $("#dropdownMenu2").removeClass("active")
            $(".top-menu").removeClass("show")
            $("#dropdownMenu5").removeClass("active")
            $(".cat-menu").removeClass("show")
            $("#dropdownMenu6").removeClass("active")
            activetest6 = true; activetest1 = false; activetest2 = false; activetest3 = false; activetest4 = false; activetest5 = false
        }
        else {
            $(".rem-menu").removeClass("show")
            $("#dropdownMenu7").removeClass("active")
            activetest6 = false;
        }
    }
    const clearall = () => {
        filterData({
            "parameter": {
                "pageNum": 1,
                "pageSize": 1000
            },
            "filterModel": {
                "country": "", "city": "", "state": "", "industry": [],
                "graduateYear": [], "nqfl": [], "qualification": [],
                "studyType": [], "sizeOfEmployeesMin": 0, "sizeOfEmployeesMax": 0,
                "topAchiever": false, "isHiringActive": false, "JobCategories": [], "IsRemote": false
            }
        })
        GetIndustry(); getLocation(); GetQualification();
        resetData("location");
        setClear(false); setRemote(false)
        filterData({
            "parameter": {
                "pageNum": 1,
                "pageSize": 1000
            },
            "filterModel": {
                "country": "", "city": "", "state": "", "industry": [],
                "graduateYear": [], "nqfl": [], "qualification": [],
                "studyType": [], "sizeOfEmployeesMin": 0, "sizeOfEmployeesMax": 0,
                "topAchiever": false, "isHiringActive": false, "JobCategories": [], "IsRemote": false
            }
        })
        setCount(); setCount1(); setCount2(); setCount3(); setCount4()
        setTimeout(() => {
            setClear(true)
        }, 100);

    }
    const setCaegorylist = (params) => {

        const s = [...params.map((item) => jobcategorylist.filter((items) => item == items.name)).map((item) => {
            return item[0].id
        })]

        filterData({ ...filtermodel, "filterModel": { ...filtermodel.filterModel, "JobCategories": s } })
        setCount4(params.length)
    }
    const redirect = (id, userId) => {

        $('.modal-backdrop').removeClass('show ');
        $('.modal').removeClass('show');
        $('body').toggleClass('p-0');
        $('body').removeClass('modal-open');
        localStorage.setItem("cuserid", userId, id)
        localStorage.setItem("applyjob", "true")
        history.push('invitation')
    }
    const showjobType = (string, typ, rem) => {

        if (typ) {
            if (string && string.split("|").length > 1) {
                var s = string.split("|").map((data, index) => {
                    return (<>
                        {rem == "true" && index <= 0 ? <div class="j-type mr-1">{rem == "true" ? "Remote | " : ""}</div> : ""}
                        <div class="j-type mr-1">{data}{string.split("|").length > 1 && string.split("|").length - 1 > index ? ' | ' : ""}</div>
                    </>)
                })
                return s
            }
            else {
                return (<>
                    {rem == "true" ? <div class="j-type mr-1">{rem == "true" ? "Remote" : ""}</div> : ""}
                    <div class="j-type ">{string}</div>
                </>
                )
            }
        }
        else {
            if (rem == "true" && string && string.split("|").length > 1) {
                var s = string.split("|").slice(0, 2).map((data, index) => {
                    return (<><span key={index + data} className={index == 0 ? "tag badge badge-pill mr-1" : "hide"}>{index == 0 ? "Remote" : ""}</span>
                        <span key={index + data} className={index <= 1 ? "tag badge badge-pill mr-1" : "hide"}>{index <= 1 ? data : ""}</span>
                    </>)
                })
                return s
            }
            else if (rem == "false" && string && string.split("|").length > 1) {
                var s = string.split("|").map((data, index) => {
                    return (<>
                        <span key={index + data} className={index <= 2 ? "tag badge badge-pill mr-1" : "hide"}>{index <= 2 ? data : ""}</span>
                    </>)
                })
                return s
            }
            else {
                if (rem == "true" && string) {
                    return (<>
                        <span className={"tag badge badge-pill mr-1"}>{"Remote"}</span>
                        <div className={string ? string.match(/[a-z]/i) ? "tag badge badge-pill " : "" : ""}>{string ? string : ''}</div>
                    </>
                    )
                }
                else {
                    return (<div className={string ? string.match(/[a-z]/i) ? "tag badge badge-pill " : "" : ""}>{string ? string : ''}</div>)
                }

            }
        }

    }
    const showjobToolkit = (string) => {

        if (string && string.split("|").length > 1) {
            var s = string.split("|").map((data, index) => {
                return (<>
                    <div class="j-type mr-1">{data}{string.split("|").length > 1 && string.split("|").length - 1 > index ? ' | ' : ""}</div>
                </>)
            })
            return s
        }
        else {
            return (<>

                <div class="j-type ">{string}</div>
            </>
            )
        }

    }
    const setTopAchiever = () => {
        if (remote == false) {
            filterData({ ...filtermodel, "filterModel": { ...filtermodel.filterModel, "IsRemote": true } })
            setRemote(true)
        }
        else {
            filterData({ ...filtermodel, "filterModel": { ...filtermodel.filterModel, "IsRemote": false } })
            setRemote(false)
        }
    }
    const closeSearch = () => {
        if ($(".globsr").hasClass("active")) {
            if ($("#globsearch").val()) {
                $("#new-users-list").removeClass("show")
            }
            else {
                $("#globsearch").removeClass("active")
            }
        }
    }
    return (<>
        <section className="section " onClick={() => closeSearch()}>
            <div className="category-section container-fluid stu">
                <div className="row">
                    <div className="col-md-12">
                        <div className="heading-row pt-0">
                            <div className="filter">
                                <div className="dropdown1 text-left">
                                    <button className="btn btn-outline" onClick={() => setDropdown()}>filter by
                                        <span><img src="../../images/icon-chevron.svg" /></span>
                                    </button>

                                    <div className="dropdown1-content bg-white container-fluid">
                                        <div className="row">
                                            <div className="column ">
                                                <div className="filter-title dropdown">
                                                    <button className="loc-btn" type="button" id="dropdownMenu2" onClick={() => setDropdown1()} >
                                                        Industry
                                                        <span> <span><img src="../../images/icon-chevron.svg" /></span>
                                                            {count ? show ?
                                                                <span className="count">
                                                                    {count}
                                                                </span> : ""
                                                                : ""}
                                                        </span>
                                                    </button>
                                                    <div className="dropdown-menu loc-menu ind-menu" aria-labelledby="dropdownMenu2">
                                                        {industrylist ? clear ? <Multiselect options={industrylist} onSelectOptions={Setindustry} type={"industry"} /> : "" : ""}
                                                        <div className="absolute-btn">
                                                            <button className="reset btn btn-outline mr-1" style={{ zIndex: 0 }} onClick={() => setFilter()}>ok</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="column">
                                                <div className="filter-title dropdown">
                                                    <button className="loc-btn" type="button" id="dropdownMenu3" onClick={() => setDropdown2()} >
                                                        Location <span>
                                                            <span>    <img src="../../images/icon-chevron.svg" /></span>
                                                            {statelist.length !== 0 ? <span className="count check">
                                                                <img src="../../images/check-img-white.svg"></img>
                                                            </span> : ""}</span>
                                                    </button>
                                                    <div className="dropdown-menu loc-menu location-menu" aria-labelledby="dropdownMenu3">
                                                        {showcount ? "" : <><div className="lable"> country : </div> <Select options={coutnrylist} placeholder={"select"} onChange={(e) => AddValue(e)} isSearchable="true"
                                                            className="filter-title" /></>}
                                                        {statelist.length !== 0 ?
                                                            <><div className="lable"> State/Province: </div><Select options={statelist ? statelist : []} placeholder={"select"} onChange={(e) => AddValue(e)} className="filter-title" /></>
                                                            : ""}
                                                        {citylist.length !== 0 ? <>< div className="lable">  city :</div> <Select options={citylist ? citylist : []} onChange={(e) => AddValue(e)} placeholder={"select"} className="filter-title" /> </> : ""}

                                                        <button className="reset btn btn-outline mr-1" style={{ zIndex: 0 }} onClick={() => setFilter()}>ok</button>
                                                        <button className="reset btn btn-outline mr-1" style={{ zIndex: 0 }} onClick={() => resetData("location")}>Reset</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="column">
                                                <div className="filter-title dropdown">
                                                    <button className="loc-btn" type="button" id="dropdownMenu4" onClick={() => setDropdown3()}>
                                                        Qualification<span>  <span><img src="../../images/icon-chevron.svg" /></span> {count1 ?
                                                            <span className="count">
                                                                {count1}
                                                            </span>
                                                            : ""}</span>
                                                    </button>
                                                    <div className="dropdown-menu loc-menu qual-menu" aria-labelledby="dropdownMenu4">
                                                        {qualificationlist ? clear ?
                                                            <Multiselect options={qualificationlist} onSelectOptions={setQualification} />
                                                            : "" : ''}
                                                        <div className="absolute-btn">
                                                            <button className="reset btn btn-outline mr-1" style={{ zIndex: 0 }} onClick={() => setFilter()}>ok</button>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            <div className="column ">
                                                <div className="filter-title dropdown">
                                                    <button className="loc-btn" type="button" id="dropdownMenu6" onClick={() => setDropdown5()} >
                                                        Job Categories
                                                        <span>
                                                            <span><img src="../../images/icon-chevron.svg" /></span>
                                                            {count4 ?
                                                                <span className="count">
                                                                    {count4}
                                                                </span>
                                                                : ""}
                                                        </span>

                                                    </button>
                                                    <div class="dropdown-menu loc-menu cat-menu" aria-labelledby="dropdownMenu6">
                                                        {jobcategorylist ? clear ? <Multiselect options={jobcategorylist} onSelectOptions={setCaegorylist} type={"category"} /> : "" : ""}
                                                        <div className="absolute-btn">
                                                            <button className="reset btn btn-outline mr-1" style={{ zIndex: 0 }} onClick={() => setFilter()}>ok</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="column">
                                                <div className="filter-title dropdown">
                                                    <button className="loc-btn" type="button" id="dropdownMenu7" onClick={() => setDropdown6()} >
                                                        Work Mode
                                                        <span>
                                                            <span>    <img src="../../images/icon-chevron.svg" /></span>
                                                            {remote ? <span className="count check">
                                                                <img src="../../images/check-img-white.svg"></img>
                                                            </span> : ""}</span>
                                                    </button>
                                                    <div class="dropdown-menu loc-menu rem-menu p-10" aria-labelledby="dropdownMenu5">
                                                        <label className="container mb-0">
                                                            <span>Remote</span>
                                                            <input
                                                                type="checkbox"
                                                                id="IsRemote"
                                                                onChange={() => setTopAchiever()}
                                                                checked={remote}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                        <button className="reset btn btn-outline mr-1" style={{ zIndex: 0 }} onClick={() => setFilter()}>ok</button>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="reset-btn">
                                                {count || count1 || count2 || count3 || count4 || remote || statelist.length !== 0 ? <button className="reset btn btn-outline" onClick={() => clearall()}>Reset</button> : ""}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sign-heading" onClick={() => setFilter()} style={{
                                width: "100%",
                                textAlign: "right"
                            }}>
                                JOBS AND INTERNSHIP
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="cards-list profile-tab stat-sec profile-sub-sec card-sec" onClick={() => setFilter()}>
                        {(count || count1 || count2 || count3 || count4 || remote || statelist.length !== 0) && jobslist.length == 0 ? <div className=" showdata">no data found</div> : ""}
                        <div className="column-row column-v3 d-block">
                            {<AlphabetList
                                className="alpha-list bg-light"
                                style={style}
                                data={jobslist}
                                generateFn={(item, index) => {

                                    return <Tag key={index} style={{ backgroundColor: "light-grey", border: "none" }} >
                                        <div className="card-e alpha">
                                            <div className="card-text">
                                                <div className="rounded-circle">
                                                    <img src={fetchData(item)[4] ? fetchData(item)[4] : "/images/tolkyologo.svg"} alt="" className="img-fluid rounded-circle " />
                                                </div>
                                                <div className="sub-title">{fetchData(item)[0]}</div>
                                                <div className="tolko">
                                                    <img src="/images/location-icon.png" />{`${fetchData(item)[1] ? fetchData(item)[1].trim() : ''}, ${fetchData(item)[2]} `}
                                                </div>
                                                <div className="text">
                                                    <div className="detail jobheight"  >
                                                        {
                                                            fetchData(item)[3] && (fetchData(item)[3].match(/<p>/g) || []).length === 1 ? <div dangerouslySetInnerHTML={{ __html: `${fetchData(item) && fetchData(item)[3] ? fetchData(item)[3].slice(0, 100) : fetchData(item)[3]}` }} /> :
                                                                fetchData(item)[3] && (fetchData(item)[3].match(/<p>/g) || []).length === 0 ? <div className="edit-p" dangerouslySetInnerHTML={{ __html: `${fetchData(item) && fetchData(item)[3] ? fetchData(item)[3].slice(0, 100) : fetchData(item)[3]}` }} />
                                                                    : <div className="edit-p editor-description" style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: `${fetchData(item) && fetchData(item)[3] ? fetchData(item)[3].slice(0, 100) : fetchData(item)[3]}` }} />
                                                        }
                                                        {fetchData(item)[3] && fetchData(item)[3] ? <button id="readmore-btn" data-toggle="modal" data-target={`${"#jobsPopupModal12"}${index + item[1]}`}>...read more </button> : ""}
                                                    </div>
                                                </div>
                                                <div className="min-badge-height">
                                                    {showjobType(fetchData(item)[6], '', fetchData(item)[8])}
                                                    {fetchData(item)[7] && parseInt(fetchData(item)[7]) > 0 && <div className="tag badge badge-pill ab-tag" data-toggle="modal" data-target={`${"#jobsPopupModal12"}${index + item[1]}`}><a data-toggle="modal" data-target={`${"#jobsPopupModal12"}${index + item[1]}`}>{fetchData(item)[7] > 0 ? `+${fetchData(item)[7]}` : ''}</a></div>}
                                                </div>
                                                {console.log(fetchData(item))}
                                                {Common.UserType() == "candidate" ? <button className="btn btn-outline big-btn" data-toggle="modal" data-target={`${"#jobsPopupModal12"}${index + item[1]}`} >Apply</button> : <a href={fetchData(item)[9]} target="_blank" className="btn btn-outline big-btn" >apply</a>}
                                            </div>
                                        </div>
                                        <div class="modal fade apply-modal"
                                            key={index + 1}
                                            id={`${"jobsPopupModal12"}${index + item[1]}`}
                                            tabIndex="-1"
                                            role="dialog"
                                            aria-labelledby="exampleModalLabel"
                                            aria-hidden="true"
                                        >
                                            <div class="modal-dialog modal-lg modal-dialog-centered">
                                                <div class="modal-content">
                                                    <button type="button" class="close modalclose-btn pedit2" data-dismiss="modal">&times;</button>
                                                    <div class="modal-header">
                                                        <div class="icon">
                                                            <img src={fetchData(item)[4] ? fetchData(item)[4] : "/images/tolkyologo.svg"} alt="" className="img-fluid rounded-circle m " ></img>
                                                        </div>
                                                        <div class="modal-title mdl-heading">{fetchData(item)[0]}</div>
                                                        <div class="user-location">
                                                            <img src="images/location-icon.png" alt="" />{`${fetchData(item)[1] ? fetchData(item)[1].trim() : ''}, ${fetchData(item)[2]} `}
                                                        </div>
                                                        <a className="btn btn-outline s-btn" href={fetchData(item)[9]} target="_blank" >apply</a>
                                                    </div>
                                                    <div class="modal-body custom-padding">
                                                        <div class="container-fluid pr-0">
                                                            <div class="row mt-0 mr-0 justify-content-between">
                                                                <div class="col-md-8">
                                                                    <div class="modalbody-content">
                                                                        <div class="desc">We're hiring!
                                                                            {
                                                                                fetchData(item)[3] && (fetchData(item)[3].match(/<p>/g) || []).length === 1 ? <div style={{ marginTop: "10px", }} dangerouslySetInnerHTML={{ __html: `${fetchData(item) && fetchData(item)[3] ? fetchData(item)[3].slice(0, 500) : fetchData(item)[3]}` }} /> :
                                                                                    fetchData(item)[3] && (fetchData(item)[3].match(/<p>/g) || []).length === 0 ? <div className="edit-p" dangerouslySetInnerHTML={{ __html: `${fetchData(item) && fetchData(item)[3] ? fetchData(item)[3].slice(0, 500) : fetchData(item)[3]}` }} />
                                                                                        : <div className="edit-p editor-description" dangerouslySetInnerHTML={{ __html: `${fetchData(item) && fetchData(item)[3] ? fetchData(item)[3].slice(0, 500) : fetchData(item)[3]}` }} />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 job-details">

                                                                    <div class="job-description">
                                                                        {fetchData(item)[6] ? <div class="j-type-name">JOB TYPE</div> : ""}
                                                                        <div className="d-flex flex-wrap">{showjobType(fetchData(item)[6], "model", fetchData(item)[8])}</div>
                                                                    </div>
                                                                    <div class="job-description">
                                                                        {fetchData(item)[6] ? <div class="j-type-name">TOOLKIT</div> : ""}
                                                                        <div className="d-flex flex-wrap">{showjobToolkit(fetchData(item)[5])}</div>
                                                                    </div>
                                                                    <div class="job-description">
                                                                        <div class="j-type-name">JOB LOCATION</div>
                                                                        <div class="j-type ">{`${fetchData(item)[1] ? fetchData(item)[1].trim() : ''}, ${fetchData(item)[2]} `}</div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </Tag>
                                }} />}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {loader}
    </>
    )
}
