import Common from "../../Utility/Common";
const newDate = new Date();
const StudentJobHistoryModel = () => ({
        id: null,
        studentId: Common.LoginId(),
        industryId: '',
        companyName: '',
        position: '',
        fromDate: newDate,
        toDate: newDate,
        responsibility: '',
        active: true,
        isPresent: false,
        userId: Common.UserId(),


})
export default StudentJobHistoryModel;
