import React, { useState, useEffect } from "react";
import service from "../../Services/LoginService";
import LoginModel from "../../Model/Auth/AdminLoginModel";
import ValidationService from "../../Services/ValidationService";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Common from "../../Utility/Common";
import useLoader from "./Loader"
toast.configure()

function AdminLogin() {
    if (window.location.pathname.toLowerCase() == '/adminlogin' && localStorage.getItem("adminusertype") == "admin") {
        window.location = "/admindashboard"
    }
    const [active, setActive] = useState(false)
    const [border, setBorder] = useState("")
    const [error, setError] = useState(false)
    const [model, setModel] = useState(LoginModel)
    document.cookie = "usertype=" + 'admin' + ";path=http://localhost:3000/Adminlogin"
    const [loader, showLoader, hideLoader] = useLoader();
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = (e) => {
        e.preventDefault();
        setPasswordShown(!passwordShown);
    };

    useEffect(() => {
        getCookiesData("admin")
    }, []);

    const ChangeHandler = (event) => {
        let value = {};
        value[event.target.id] = event.target.value;

        setModel({ ...model, ...value });
    }

    const login = async (e) => {
        e.preventDefault();
        if (validation()) {
            showLoader();
            const services = new service();
            var res = await services.Adminlogin(model);
            hideLoader();
            if (res === 'success') {
                window.location = '/admindashboard';
            }
        }

    }
    const getCookie = (cname) => {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    const getCookiesData = async (user) => {
        if (getCookie(user) == '') {
            setActive(false)
            setTimeout(() => {
                setModel({
                    Email: '',
                    Password: '',
                    errormsg: null,
                    UserType: user
                })
            }, 0);
        }
        if (getCookie(user) != '' && getCookie(user) != '') {
            setActive(true)
            // document.getElementById("Email").value = s.email;
            // document.getElementById("Password").value = s.password;
            setTimeout(() => {
                setModel({
                    Email: JSON.parse(getCookie(user)).email,
                    Password: JSON.parse(getCookie(user)).password,
                    errormsg: null,
                    UserType: JSON.parse(getCookie(user)).userType
                })
            }, 0);
        }
    }
    const setCookie = () => {
        var email = document.getElementById("Email").value;
        var Password = document.getElementById("Password").value;
        var userType = model.UserType
        var obj = {
            userType: `${userType}`,
            email: `${email}`,
            password: `${Password}`
        }
        if (email.length != 0) {
            document.cookie = `${model.UserType}=` + " "
        }

        if (email.length != 0 && Password.length != 0) {
            if (active == true) {
                setActive(false)
            }
            else {
                document.cookie = `${userType}=` + JSON.stringify(obj)
                setActive(true)
            }
        }
    }
    const validation = () => {
        let error = false;
        let arrError = [];
        let Email = "";
        let Password = "";

        if (!ValidationService.validationEmail(model.Email)) {
            arrError.push("Please enter valid User ID");
            error = true;
            Email = "borderred";
        }
        if (!ValidationService.validationNotEmpty(model.Password)) {
            arrError.push("Please enter Password");
            error = true;
            Password = "borderred";
        }

        if (Email || Password) {
            setBorder({ Email, Password })
        }

        if (arrError.length === 0) {
            arrError = [];
            return true;
        } else if (arrError.length > 2) {
            toast.error('Please fill the required fields', { theme: "colored", toastId: 'error1' });
            return false;
        } else if (arrError.length <= 2) {
            arrError.forEach((element) => {
                toast.error(element, { theme: "colored", toastId: 'error2' });
            });
            return false;
        }
        // if (error) {
        //     alert(
        //         arrError.map(function (error) {
        //             return error + " \n";
        //         })
        //     );

        // }
        return !error;
    }
    return (
        <>
            <section className="section bg-sec" >
                <div className="container-fluid login-sec admin">
                    <div className="sign-box login">
                        <div className="col-md-6">
                            <img src="./images/sign-in-illu.png" className="sign-img" alt="" />
                        </div>
                        {/* <div className="bg">
                                <img src="../images/bg-lines.svg" alt="" />
                            </div> */}
                        <div className="col-md-6">
                            <div className="bg-lines">
                                <div className="login-box">

                                    <div className="sign-heading text-center border-none">admin login</div>
                                    <form action="" className="login-form text-left">
                                        <label htmlFor="" className="label">User ID<span className="required">*</span></label>
                                        <input
                                            autoComplete="off"
                                            type="email"
                                            id="Email"
                                            className={border.Email ? border.Email : "xu-input"}
                                            placeholder="Email"
                                            value={model.Email}
                                            onChange={(e) => ChangeHandler(e)}
                                        ></input>

                                        <label htmlFor="" className="label">Password<span className="required">*</span></label>
                                        <div className="password-input">
                                            <input
                                                autoComplete="off"
                                                type={passwordShown ? "text" : "password"}
                                                id="Password"
                                                className={border.Password ? border.Password : "xu-input password"}
                                                placeholder="Password"
                                                value={model.Password}
                                                onChange={(e) => ChangeHandler(e)}
                                            ></input>
                                            {passwordShown ?
                                                <img src="./images/close-eye.svg" className="icon close" onClick={(e) => togglePassword(e)} />
                                                :
                                                <img src="./images/open-eye.svg" className="icon" onClick={(e) => togglePassword(e)} />
                                            }
                                        </div>
                                        <div className="form-rem">
                                            <label className="container"><span>Remember me</span>
                                                <input
                                                    type="checkbox"
                                                    id="Remember"
                                                    onChange={() => setCookie()}
                                                    checked={active}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                            <a href="AdminForgotPassword" className="text-dark">Forgot Password?</a>
                                        </div>
                                        <button
                                            type="submit"
                                            className="btn sign-btn btn-outline big-btn"
                                            onClick={(e) => login(e)}
                                        >
                                            Login
                                        </button>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            {loader}
        </>
    )
}

export default AdminLogin
