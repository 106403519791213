import React from 'react'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

toast.configure()

function GlobalFilter({ filter, setFilter, getData }) {
    const getFilter = (e) => {
        let value = e.target.value;
        if (getData.length !== 0) {
            setFilter(value);
        } else {
            toast.error("Select User Type", { theme: "colored", toastId: 'error1' });

        }

    }
    return (
        <div className='search-bar'>
            <div className='label'>
                Search : {' '}
            </div>
            <input value={filter || ''}
                onChange={(e) => getFilter(e)} placeholder='Search Here' className='xu-input' />

        </div>
    )
}

export default GlobalFilter
