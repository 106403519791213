import React, { Component } from "react";
import PropTypes from "prop-types";
import "./style.css";

export class Multiselect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: '',
            checked: [],
            dropDownValue: []
        };
        this.checkBox = this.checkBox.bind(this);
    }
    componentWillMount() {
        this.setState({
            dropDownValue: this.props.options,
            type: this.props.type
        });
    }
    removeChip(value) {
        this.checkBox(value, false);
    }
    removeAllChips() {
        this.state.checked.map((data) => {
            this.checkBoxClearAll(data, false);
        });
    }
    checkBoxClearAll(value, condition) {
        let checkedValue = this.state.checked;
        let index = checkedValue.indexOf(value);
        checkedValue.splice(index, this.state.checked.length);
        this.setState(
            {
                checked: checkedValue
            },
            () => {
                this.props.onSelectOptions(this.state.checked);
            }
        );
    }
    checkBox(value, condition) {
        let checkedValue = this.state.checked;
        if (condition) {
            checkedValue.push(value);
        } else {
            let index = checkedValue.indexOf(value);
            checkedValue.splice(index, 1);
        }
        this.setState(
            {
                checked: checkedValue
            },
            () => {
                this.props.onSelectOptions(this.state.checked);
            }
        );
    }
    searchFun(e) {
        if (e.target.value.length !== 0) {
            let enteredValue = e.target.value.toLowerCase();
            let presentValue = this.props.options.filter(function (data) {
                return data.name.toLowerCase().indexOf(enteredValue) > -1;
            });
            this.setState({ dropDownValue: presentValue });
        } else {
            this.setState({ dropDownValue: this.props.options });
        }
    }
    // searchFun(e) {
    //     if (e.target.value.length !== 0) {
    //         let enteredValue = e.target.value;
    //         let presentValue = this.props.options.filter(function (data) {
    //             return data.name.indexOf(enteredValue) > -1;
    //         });
    //         this.setState({ dropDownValue: presentValue });
    //     } else {
    //         this.setState({ dropDownValue: this.props.options });
    //     }
    // }
    returnChip() {
        const chip = this.state.checked
            ? this.state.checked.map((data, index) => (
                <div className="chip-body" key={index}>
                    <p className="chip-text">{data}</p>
                    <button
                        className="chip-close"
                        onClick={(e) => this.removeChip(data)}
                    >
                        &times;
                    </button>
                </div>
            ))
            : [];
        return chip;
    }
    returnState() {
        return this.state.type
    }
    returnList() {

        const list = this.state.dropDownValue
            ? this.state.dropDownValue.map((data, index) => (
                <label className="container" key={index}>
                    {data.name}
                    <input
                        type="checkbox"
                        value={data.value}
                        onChange={(e) => this.checkBox(e.target.value, e.target.checked)}
                        checked={this.state.checked.includes(data.value) ? true : false}
                    />
                    <span className="checkmark"></span>
                </label>
            ))
            : null;
        return list;
    }
    render() {
        return (
            <div>
                {/* {this.returnChip().length !== 0 ? <div className="chip">{this.returnChip()}</div> : ""} */}

                <div className="multiSelect" id={this.props.type}>
                    {/* <div className="chip">
                    {
                        <div className="chip-body">
                            <p onClick={(e) => this.removeAllChips()} className="chip-text">{"Clear All"}</p>
                        </div>
                    }  
                </div>   */}
                    {/* <button className=" btn btn-outline"  >filter by
                    <i className="fa fa-caret-down"></i>
                    <span><img src="../../images/icon-chevron.svg"   /></span>
                </button> */}

                    <input
                        type="text"
                        name="Search"
                        placeholder="Select"
                        className={this.props.type == "language" ? "hide" : "input-box"}
                        onChange={(e) => this.searchFun(e)}
                    />
                    <div className="search-result">
                        <div className="list-result">{this.returnList()}</div>
                    </div>
                    {this.props.type == "language" ? <div className="list-result">{this.returnList()}</div> : ""}
                </div>
                {this.props.type == "language" ? "" : <button className="reset btn btn-outline ml-3 " onClick={(e) => this.removeAllChips()}>Reset</button>}
            </div>
        );
    }
}

Multiselect.defaultProps = {
    options: []
};

/** define proptypes including fields which is required */
Multiselect.prototypes = {
    options: PropTypes.array.isRequired,
    onSelectOptions: PropTypes.func,
    type: PropTypes.isRequired,

};

export default Multiselect;
