import React, { useState, useEffect } from "react";
import service from "../../Services/UtilityService";
import ServiceAPI from "../../Utility/ServiceList";
import environment from "../../environment";
import { FaPencilAlt } from "react-icons/fa";
import Common from "../../Utility/Common";
import CompanyAboutus from "./Admin/CompanyAboutUs";
import CompanyJobs from "./Admin/CompanyJob";
import CompanyEvents from "./Admin/CompanyEvent";
import CompanyProfiles from "./Admin/CompanyProfile";
import CompanyMedia from "./Admin/CompanyMedia";
import ValidationService from "../../Services/ValidationService";
import { toast } from "react-toastify";
import useLoader from "../Auth/Loader"
import $ from "jquery";
import moment from "moment";
import { useHistory, Link } from "react-router-dom";

const CompanyProfile = () => {
    const history = useHistory();
    var services = new service();
    let params = (new URL(document.location)).searchParams;
    let id = params.get('id');
    const [error, setError] = useState(false)
    const [timeouts, setTimeouts] = useState(false)
    const [isShortlist, setIsShortlist] = useState(false)
    const [show1, setShow1] = useState(false); const [show2, setShow2] = useState(false); const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [border, setBorder] = useState("");
    const [loader, showLoader, hideLoader] = useLoader();
    const [banner, setBanner] = useState([]);
    const [companyData, setCompanyData] = useState('');
    const [companyEventData, setCompanyEventData] = useState([]);
    const [companyMediaData, setCompanyMediaData] = useState([]);
    const [companyAboutUsData, setCompanyAboutUsData] = useState([]);
    const [companyJobsData, setCompanyJobsData] = useState([]);
    const [isReadMore, setIsReadMore] = useState(false);
    const [companyEventModel, setCompanyEventModel] = useState({
        "id": "00000000-0000-0000-0000-000000000000",
        "companyEventId": "",
        "firstName": "",
        "lastName": "",
        "email": "",
        "contact": ""
    })
    const [contactModel, setContactModel] = useState({
        "id": null,
        "senderName": "",
        "senderEmail": "",
        "message": "",
        "companyId": id
    });
    const [ShortListModel, setShortListModel] = useState({
        "id": "00000000-0000-0000-0000-000000000000",
        "userId": `${Common.UserId()}`,
        "active": true,
        "studentId": Common.LoginId(),
        "companyId": id,
        "collegeId": "00000000-0000-0000-0000-000000000000",
        "college": null,
        "company": null
    });
    const [pageModel, setPageModel] = useState({
        "pageNum": 1,
        "pageSize": 5
    });
    const [datamodel, setDataModel] = useState(
        {
            "studentId": localStorage.getItem('usertype') == 'candidate' ? Common.LoginId() : null,
            "companyId": null,
            'collegeId': null
        }
    )
    const verify = Common.ValidId(id, localStorage.getItem("cuserid"));
    localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
    useEffect(() => {

        if (localStorage.getItem("applyjob") === "true") {
            $(document).ready(function () {
                $('linkjob').click(function () {
                    alert('button clicked');
                });
                // set time out 5 sec
                setTimeout(function () {
                    $('#linkjob').trigger('click');
                    localStorage.removeItem("applyjob")
                }, 100);
            });
        }

        GetBanner();
        GetCompanyEvent(id);
        GetCompanyMedia(id);
        GetAboutUs();
        GetProfile(id);
        GetCompanyJobs();
        headNav();

    }, []);

    const GetCompanyEvent = async () => {

        var res = await services.GetDataById(ServiceAPI.CompanyEventGetAllApi, id, { 'PageNum': 1, 'PageSize': 10 });
        const data = await res.result.filter((items) => items.active !== false);
        await setCompanyEventData(data);
    };
    const eventChange = (e, type) => {
        const value = {}
        value[e.target.name] = e.target.value
        setCompanyEventModel({ ...companyEventModel, ...value })
    }
    const AttendCompanyEvent = async (id, type) => {

        if (type == "type") {
            toast.error('Invalid Action', { theme: "colored", autoClose: 2000, toastId: 'success1' })
            setTimeout(() => {
                hideLoader();
                $('.modal-backdrop').removeClass('show ');
                $('.modal').removeClass('show');
                $('body').toggleClass('p-0');
                $('body').removeClass('modal-open');
                $('nav.navbar.navbar-expand-sm').addClass('p-0');
            }, 2000)
        } else {
            if (validation()) {
                showLoader();
                var res = await services.Create(ServiceAPI.companyEventResisterApi, { ...companyEventModel, "companyEventId": id });
                if (res && res.message === "Success") {
                    setCompanyEventModel({
                        "id": "00000000-0000-0000-0000-000000000000",
                        "companyEventId": "",
                        "firstName": "",
                        "lastName": "",
                        "email": "",
                        "contact": ""
                    })
                    toast.success('Applied Successfully', { theme: "colored", autoClose: 2000, toastId: 'success1' })
                    setTimeout(() => {
                        hideLoader();
                        $('.modal-backdrop').removeClass('show ');
                        $('.modal').removeClass('show');
                        $('body').toggleClass('p-0');
                        $('body').removeClass('modal-open');
                        $('nav.navbar.navbar-expand-sm').addClass('p-0');
                    }, 2000)
                }
            }
        }
    }
    const GetCompanyMedia = async (type) => {
        let params = (new URL(document.location)).searchParams;
        let id = params.get('id');
        var res = await services.GetDataById(ServiceAPI.CompanyMediaGetAllApi, id, { 'PageNum': 1, 'PageSize': 3 });
        const data = await res.result.filter((items) => items.active !== false);;

        await setCompanyMediaData(data);
        if (type == "delete") {
            $("#mediaelem").find("div:first").addClass("active")
            $("#mediadots").find("li:first").addClass("active")
        }
    };
    const GetProfile = async () => {
        $('.modal-backdrop').removeClass('show ');
        $('.modal').removeClass('show');
        $('body').toggleClass('p-0');
        $('body').removeClass('modal-open');
        window.scrollTo(0, 0);
        const s = localStorage.getItem('usertype') == 'candidate' ? Common.LoginId() : null
        const x = localStorage.getItem('usertype')
        if (x == "candidate") {
            showLoader()
            var res1 = await services.GetStudentDataById(ServiceAPI.CompanyGetApi, id, { ...datamodel, studentId: s, companyId: id });
            hideLoader()
            const data1 = res1.result;
            if (Common.UserType() == "company") {
                localStorage.setItem("profileImage", data1.logo)
            }
            localStorage.setItem("cuserid", data1.userId)
            setCompanyData(data1)
            setIsShortlist(data1?.isShortlist)

        }
        else {
            showLoader()
            var res2 = await services.GetStudentDataById(ServiceAPI.CompanyGetApi, id, { ...datamodel, companyId: id });
            hideLoader()
            const data2 = await res2.result;
            if (Common.UserType() == "company") {
                localStorage.setItem("profileImage", data2.logo)
            }
            if (data2 && data2.userId) {
                localStorage.setItem("cuserid", data2.userId)
            }
            await setCompanyData(data2)
            await setIsShortlist(data2?.isShortlist)
        }


        setTimeout(() => {
            hideLoader()
            setTimeouts(true)
        }, 10);
    };

    const GetBanner = async () => {
        let params = (new URL(document.location)).searchParams;
        let id = params.get('id');
        showLoader()
        var res = await services.GetDataById(ServiceAPI.CompanyBannerGetAllApi, id, pageModel);
        hideLoader()
        const data = res.result.filter((items) => items.active !== false);
        await setBanner(data);
    };
    const GetAboutUs = async () => {
        var res = await services.GetById(ServiceAPI.CompanyGetAboutUs, id);
        const data = res.result;
        await setCompanyAboutUsData(data)
    };
    const GetCompanyJobs = async () => {
        var res = await services.GetDataById(ServiceAPI.CompanyJobGetAllApi, id, { 'PageNum': 1, 'PageSize': 10 });
        const data = res?.result;
        await setCompanyJobsData(data)
    };
    const ApplyJobs = async (id) => {
        if (id == "login") {
            setTimeout(() => {
                hideLoader();
                $('.modal-backdrop').removeClass('show ');
                $('.modal').removeClass('show');
                $('body').toggleClass('p-0');
                $('body').removeClass('modal-open');
                $('nav.navbar.navbar-expand-sm').addClass('p-0');
            }, 2000)
            toast.error('Login required!', { theme: "colored", autoClose: 2000, toastId: 'success1' })
        }
        else {
            showLoader();
            var res = await services.Create(ServiceAPI.StudentApplyJobApi, {
                "id": "",
                "userId": `${Common.UserId()}`,
                "active": true,
                "studentJobApplyJobId": `${id}`,
                "studentJobApplyStudentId": `${Common.LoginId()}`
            });
            hideLoader();
            if (res && res.message === "Success") {
                setTimeout(() => {
                    hideLoader();
                    $('.modal-backdrop').removeClass('show ');
                    $('.modal').removeClass('show');
                    $('body').toggleClass('p-0');
                    $('body').removeClass('modal-open');
                    $('nav.navbar.navbar-expand-sm').addClass('p-0');
                }, 2000)
                toast.success('Applied Successfully', { theme: "colored", autoClose: 2000, toastId: 'success1' })
            }
        }

    }
    const UnShortList = async () => {
        const x = localStorage.getItem('usertype')
        const s = localStorage.getItem('usertype') == 'candidate' ? Common.LoginId() : null
        if (x === "candidate") {
            showLoader()
            var res = await services.Create(ServiceAPI.CompanyShortlistRemoveApi, { ...datamodel, studentId: s, companyId: id });
            hideLoader()
            if (res.message === "Success") {
                setIsShortlist(false)
                toast.success("Successfully Removed", { theme: "colored", autoClose: 2000 })
            }
        }

    }
    const PostShortList = async () => {
        showLoader()
        var res = await services.Create(ServiceAPI.StudentShortListPostApi, ShortListModel);
        hideLoader()
        if (res.message === "Success") {
            toast.success("Successfully Shortlisted", { theme: "colored", autoClose: 2000 })
            setIsShortlist(true)
        }
        else {

            setIsShortlist(false)
            toast.error(res.ErrorMessage, { theme: "colored", autoClose: 2000 })
        }
    };
    const contactHandle = async (e, type) => {
        if (type == "type") {
            toast.error('Invalid Action', { theme: "colored", autoClose: 2000, toastId: 'success1' })

        } else {
            e.preventDefault()
            if (valid()) {
                showLoader();
                var res = await services.Create(ServiceAPI.CompanyContactGetAllApi, contactModel);
                hideLoader();
                if (res && res.message === "Success") {

                    toast.success('Email Sent Successfully', { theme: "colored", autoClose: 2000, toastId: 'success1' })
                }
                else {
                    toast.error(res.errorMessage, { theme: "colored", autoClose: 2000, toastId: 'success1' })
                }
                setContactModel({
                    "id": 0,
                    "senderName": "",
                    "senderEmail": '',
                    "message": "",
                    "companyId": id
                })
            }
        }
    };
    const valid = () => {
        let senderName = ""
        let senderEmail = ''
        let message = ''
        var arrError = [];
        if (!ValidationService.validationNotEmpty(contactModel.senderName)) {
            toast.error("Please enter Name", { theme: "colored", autoClose: 3000, toastId: 'error1' });
            senderName = "borderred"
        }
        if (!ValidationService.validationNotEmpty(contactModel.senderEmail)) {
            toast.error("Please enter Email", { theme: "colored", autoClose: 3000, toastId: 'error1' });
            senderEmail = 'borderred'
        }
        if (!ValidationService.validationEmail(contactModel.senderEmail)) {
            arrError.push("Valid Email required");
            toast.error("Valid Email required", { theme: "colored", autoClose: 3000, toastId: 'error1' });
        }
        if (!ValidationService.validationNotEmpty(contactModel.message)) {
            toast.error("Please enter message", { theme: "colored", autoClose: 3000, toastId: 'error1' });
            message = "borderred"
        }
        if (senderName || senderEmail || message) {
            setBorder({ senderEmail, senderName, message })
        }
        if (arrError.length) {
            setError(arrError);
            return false;
        }
        return true;
    };
    const validation = () => {
        let firstName = ''
        let lastName = ''
        let email = ''
        let contact = ''
        if (!ValidationService.validationNotEmpty(companyEventModel.firstName)) {
            toast.error("Please enter firstName ", { theme: "colored", autoClose: 2000, toastId: 'success1' });
            firstName = "borderred";
        }
        if (!ValidationService.validationNotEmpty(companyEventModel.lastName)) {
            toast.error("Please enter lastName ", { theme: "colored", autoClose: 2000, toastId: 'success1' });
            lastName = "borderred";
        }
        if (!ValidationService.validationEmail(companyEventModel?.email)) {
            toast.error("Please enter valid email ", { theme: "colored", autoClose: 2000, toastId: 'success1' });
            email = "borderred";
        }
        if (!ValidationService.validationNotEmpty(companyEventModel?.contact)) {
            toast.error("Please enter Contact no. ", { theme: "colored", autoClose: 2000, toastId: 'success1' });
            contact = "borderred";
        }

        if (firstName || lastName || email || contact) {
            setBorder({ firstName, lastName, email, contact });
            return false;
        }
        return true;
    }
    const ChangeHandler = (e) => {
        e.preventDefault();
        let value = {};
        value[e.target.id] = e.target.value;
        setContactModel({ ...contactModel, ...value });
    }
    const headNav = () => {
        $(window).on("scroll", function (e) {
            var scrollDistance = $(window).scrollTop() + 150;
            if (scrollDistance) {
                $(".profile-tab").each(function (i) {
                    var divPoc = $(this).position().top;
                    if (divPoc <= scrollDistance) {
                        $("#btn-nav ul li.active").removeClass('active');
                        $("#btn-nav ul li a.active ").removeClass('active');
                        $("#btn-nav ul li").eq(i).addClass('active');
                        $("#btn-nav ul li a").eq(i).addClass('active');
                    }
                });
            }
        });
        $(".nav-tabs .navitem").on("click", function () {
            var sectionId = '#' + $(this).attr('id').replace('lnk', '');
            if (window.matchMedia('(max-width: 768px)').matches) {
                $(window).scrollTop($(sectionId).position().top - 100);
            }
            else {
                $(window).scrollTop($(sectionId).position().top - 80);

            }
        });
    }
    const redirectTolist = () => {
        history.push("/companybannerlist");
    }
    const closeSearch = () => {
        if ($(".globsr").hasClass("active")) {
            if ($("#globsearch").val()) {
                $("#new-users-list").removeClass("show")
            }
            else {
                $(".global").removeClass("active")
                $("#globsearch").removeClass("active")
            }
        }
    }
    const EditProfile = () => {
        setShow1(true)
    }
    const EditMedia = () => {
        setShow3(true)
    }

    const EditEvent = () => {
        setShow4(true)
    }
    const EditJob = () => {
        setShow2(true)
    }
    return (
        <>
            <section className="section ">
                <div className="profile">
                    <div className="btn-row">
                        <div className="w-100">
                            <nav className="w-100" onClick={() => closeSearch()}>
                                <div className="scroll-tabs wrapped-tab" scroll="true" id="btn-nav">
                                    <ul className="nav nav-tabs " id="pills-tab" role="tablist">
                                        <li id="lnkhome" className="navitem active">
                                            <a className="btn dark s-btn active"> Home</a>
                                        </li>
                                        <li id="lnkabout" className="navitem">
                                            <a className="btn dark s-btn"> About</a>
                                        </li>
                                        <li id="lnkjob" className="navitem">
                                            <a className="btn dark s-btn" id="linkjob">Job Listing </a>
                                        </li>
                                        <li id="lnkmedia" className="navitem">
                                            <a className="btn dark s-btn">Media & events </a>
                                        </li>
                                        <li id="lnkcontact" className="navitem">
                                            <a className="btn dark s-btn">contact </a>
                                        </li>
                                        <li className="d-md-none d-block">
                                            <div className="visit">
                                                <a className="btn dark s-btn" href={companyData && companyData.website ? companyData.website : ""} target={companyData && companyData.website ? "_blank" : ""}> visit site</a>
                                                {environment.isServer == "true" ? Common.UserType() == "candidate" ? isShortlist ? <a className="btn dark s-btn" onClick={() => UnShortList()}><div className="px-2 ">Remove</div> <img className="sl-img" src="images/cross.png" /></a> : <a className="btn dark s-btn" onClick={() => PostShortList()}><div className="px-3 ">Shortlist</div> <img className="sl-img ms-3" src="images/file-check.svg" /></a> : "" : ""}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        <div className="visit d-none d-md-block">
                            <a className="btn btn-outline s-btn" href={companyData && companyData.website ? companyData.website : ""} target={companyData && companyData.website ? "_blank" : ""}> visit site</a>
                            {environment.isServer == "true" ? Common.UserType() == "candidate" ? isShortlist ? <a className="btn btn-outline s-btn" onClick={() => UnShortList()}><div className="px-2 ">Shortlist</div> <img className="sl-img" src="images/cross.png" /></a> : <a className="btn btn-outline s-btn" onClick={() => PostShortList()}><div className="px-3 ">Shortlist</div> <img className="sl-img ms-3" src="images/file-check.svg" /></a> : "" : ""}
                        </div>
                    </div>
                    <section className="profile-tab landing-section-home-page profile-carousel carousel slide" id="home" data-ride="carousel">
                        {/* Indicators  */}
                        <ul className="carousel-indicators carousel-dots landing-dots">
                            {banner && banner.length > 1
                                ? banner.map((items, indexs) => <li data-target="#home" key={indexs + items} data-slide-to={`${indexs}`} className={indexs === 0 ? 'active' : ""}></li>) : ""}
                        </ul>
                        <div className="caraousal-head">
                            <div className="display-inline bg-white rounded align-items-center start pr-30">
                                {timeouts ? <div className="imge">
                                    <img src={companyData ? companyData.logo : ""} alt="" className="img-fluid rounded-circle " />
                                </div> : ""}
                                {timeouts ? <div className="headings">
                                    <div className="edit-top">
                                        <div className="pedit2 add-option" > {verify ? <FaPencilAlt onClick={() => EditProfile()} className="icon" data-toggle="modal" data-target="#exampleProfileModal" /> : ""}</div>
                                    </div>
                                    <div className="title">
                                        {companyData ? companyData.name : ""}
                                    </div>
                                    <div className="sub-head">
                                        {(companyData !== null && companyData.country !== null) ? <img src="images/location-icon.png" /> : ""} {(companyData !== null && companyData.country !== null) ? ` ${companyData.country}` : ""}
                                    </div>
                                </div> : ""}
                            </div>
                        </div>
                        <a className="scroll-down" href="#about">
                            <a href="#about"><img src="./images/scroll-down.svg" alt="" /></a> <br />
                            <a href="#about">  scroll down</a>
                        </a>
                        <div className="artwork carousel-icon">
                            {companyData && companyData.twitterUrl ? <a href={companyData ? companyData.twitterUrl : ""} className="mx-2" target="_blank"><img src="images/twitter.svg" alt="fb icon" /></a> : ""}
                            {companyData && companyData.facebookUrl ? <a href={companyData ? companyData.facebookUrl : ""} className="mx-2" target="_blank"><img src="images/facebook.svg" alt="fb icon" /></a> : ""}
                            {companyData && companyData.instagramUrl ? <a href={companyData ? companyData.instagramUrl : ""} className="mx-2" target="_blank"><img src="images/instagram.svg" alt="fb icon" /></a> : ""}
                        </div>
                        <div className="edit add-option landing-img-edit" >
                            {verify ? <FaPencilAlt onClick={() => redirectTolist()} className="icon " /> : ""}</div>
                        <div className="carousel-inner landing-carousel">
                            {banner ? banner.map((bimage, index) => {
                                return (
                                    <div data-interval={3000} className={index == 0 ? `carousel-item active` : "carousel-item"} key={index}>
                                        <div className="landing-carousal-item caraousal-slide-1">
                                            {bimage.titleLink ? <a className="caraousal-image" href={bimage.titleLink ? bimage.titleLink : ""} target={bimage.titleLink ? "_blank" : ""} >
                                                <img src={bimage ? bimage.imageUrl : "images/bannerbg.png"} />
                                            </a> : <img src={bimage ? bimage.imageUrl : "images/bannerbg.png"} className="caraousal-image" />}
                                        </div>
                                    </div>
                                )
                            }) : ""}

                        </div>
                        {banner && banner.length > 1 ? <a className="carousel-control-prev control" href="#home" data-slide="prev">
                            <img src="images/chevron.svg" className="prev icon"></img>
                        </a> : ""}
                        {banner && banner.length > 1 ? <a className="carousel-control-next control" href="#home" data-slide="next">
                            <img src="images/chevron.svg" className="next icon"></img>

                        </a> : ""}
                    </section>
                    <div className="modal fade xy-form"
                        id="exampleProfileModal"
                        tabIndex="10"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true">

                        <div className="modal-dialog modal-lg modal-dialog-centered ">
                            <div className="modal-content">
                                <div className="modal-header form-mdlheadr">
                                    <h4 className="modal-title mdl-heading">Edit </h4>
                                    <button type="button" className="close modalclose-btn" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    {verify ? <CompanyProfiles id={companyData ? companyData.id : 0} GetCompanyProfiles={GetProfile} show={show1} setShow1={setShow1} modalid={`exampleProfileModal`} /> : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="feed-section container-fluid" id="about">
                        {/* <!-- about-us --> */}
                        <div className="profile-tab stat-sec profile-sub-sec  ">
                            <div className="about-sec content theme-container">
                                <div className="title line">
                                    About Us
                                </div>
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="about-card feed-card">
                                            <div className="img-box">
                                                <img src={(companyAboutUsData && companyAboutUsData.image) ? companyAboutUsData.image : "./images/post-1.svg"} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="title">
                                            {companyAboutUsData ? companyAboutUsData.heading : " we are carriers of future learning"}
                                            <div className="float-right add-option" > {verify ? <FaPencilAlt className="icon" data-toggle="modal" data-target="#exampleAboutUsModal" /> : ""}</div>

                                        </div>
                                        <div className="list">
                                            <div className="list-item">
                                                <img src="./images/about.png" alt="" className="icon" />
                                                <div className="headings">
                                                    <div className="sub-head">About Us</div>
                                                    <div className="text">
                                                        {companyAboutUsData ? companyAboutUsData.description : "This is where college will describe its about in 150 characters or less.Write up should be clean and concice."}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="list-item">
                                                <img src="./images/planting.png" alt="" className="icon" />
                                                <div className="headings">
                                                    <div className="sub-head">Founded</div>
                                                    <div className="text">
                                                        {companyAboutUsData ? companyAboutUsData.founded : ""}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="list-item">
                                                <img src="./images/web.png" alt="" className="icon" />
                                                <div className="headings">
                                                    <div className="sub-head">Website</div>
                                                    <div className="text">
                                                        {companyAboutUsData ? companyAboutUsData.website : " www.bhc.com"}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="list-item">
                                                <img src="./images/headquater.png" alt="" className="icon" />
                                                <div className="headings">
                                                    <div className="sub-head">Headquarters</div>
                                                    <div className="text">
                                                        {companyAboutUsData ? companyAboutUsData.headquerters : "Cape Town, South Africa"}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="list-item">
                                                <img src="./images/users3.png" alt="" className="icon" />
                                                <div className="headings">
                                                    <div className="sub-head">Number Of Employees</div>
                                                    <div className="text">
                                                        {companyAboutUsData ? companyAboutUsData.noOfOfEmployees : ""}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="list-item">
                                                <img src="./images/qualification.png" alt="" className="icon" />
                                                <div className="headings">
                                                    <div className="sub-head">Services</div>
                                                    <div className="text">
                                                        {companyAboutUsData ? companyAboutUsData.servicesOffered : ""}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="list-item">
                                                {companyAboutUsData.remoteWorking ? <div className="display-inline headings">
                                                    <img src="./images/wfh.png" alt="" className="icon mb-2 mt-0" />
                                                    <div className="sub-head"> Remote Working</div>
                                                </div> : ""}</div>
                                            <div className="list-item">
                                                {companyAboutUsData.travelOpportunities ? <div className="display-inline headings">
                                                    <img src="./images/airplane-around-earth.png" alt="" className="icon mt-0 mb-2" />
                                                    <div className="sub-head">Travel Opportunities</div>
                                                </div> : ""}
                                            </div>
                                            <div className="list-item">
                                                {companyAboutUsData.learningOpportunities ? <div className="display-inline headings">
                                                    <img src="./images/learning.png" alt="" className="icon mt-0 mb-2" />
                                                    <div className="sub-head"> Learning Opportunities</div>
                                                </div> : ""}
                                            </div>
                                        </div>
                                        <div className="modal fade xy-form"
                                            id="exampleAboutUsModal"
                                            tabIndex="10"
                                            role="dialog"
                                            aria-labelledby="exampleModalLabel"
                                            aria-hidden="true">
                                            <div className="modal-dialog modal-lg modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-header form-mdlheadr">
                                                        <h4 className="modal-title mdl-heading"> Edit About</h4>
                                                        <button type="button" className="close modalclose-btn" data-dismiss="modal">&times;</button>
                                                    </div>
                                                    <div className="modal-body">
                                                        {verify ? <CompanyAboutus id={companyAboutUsData ? companyAboutUsData.id : Common.LoginId()} GetCompanyAboutUsData={GetAboutUs} GetCompanyJobs={GetCompanyJobs} modalid={`exampleAboutUsModal`} /> : ""}                                            </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- jobs --> */}
                        <div className="profile-tab stat-sec profile-sub-sec card-sec" id="job">
                            <div className="content theme-container">
                                <div className="display-inline">
                                    <div className="title line m-0">
                                        Jobs
                                    </div>
                                    <div className="">{verify ? <div onClick={() => EditJob()} className="add-option" data-toggle="modal" data-target="#exampleJobsModaladd1"><span></span><a className="icon" >
                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                    </a></div> : ""}
                                    </div>
                                </div>
                                {/* <!-- </div> --> */}
                                <div className="column-row column-v3 overflow-cards">
                                    {/* <!-- card-1 --> */}
                                    {companyJobsData ? companyJobsData.map((jobs, index) => {
                                        return (< div className="card-e job" key={index}>
                                            <div key={index}>
                                                {verify ? <div className="edit2 add-option"><FaPencilAlt onClick={() => EditJob()} className="icon" data-toggle="modal" data-target={`${"#exampleJobsModal111"}${index}`} /></div> : ""}
                                                <div className="card-text">
                                                    <div className="rounded-circle">
                                                        <img src={companyData ? companyData.logo : ""} alt="" className="img-fluid rounded-circle " style={{

                                                        }} />
                                                    </div>

                                                    <div className="sub-title">{jobs.title}</div>
                                                    <div className="tolko">
                                                        <img src="/images/location-icon.png" />{`${jobs.city}, ${jobs.state}`}
                                                    </div>
                                                    <div className="text">
                                                        <div className="detail jobheight" >
                                                            {(jobs.description.match(/<p>/g) || []).length === 1 ?
                                                                <div className="editor-description" dangerouslySetInnerHTML={{ __html: `${jobs.description && jobs.description.length > 120 ? jobs.description.indexOf("<p>") > 0 ? jobs.description.slice(0, 120) : jobs.description.slice(0, 120) : jobs.description}` }} /> :
                                                                (jobs.description.match(/<p>/g) || []).length === 0 ?
                                                                    <div className="edit-p editor-description" dangerouslySetInnerHTML={{ __html: `${jobs.description && jobs.description.length > 120 ? jobs.description.indexOf("<p>") > 0 ? jobs.description.slice(0, 120) : jobs.description.slice(0, 120) : jobs.description}` }} />
                                                                    : <div className="editor-description" style={{}} dangerouslySetInnerHTML={{ __html: `${jobs.description && jobs.description.length > 120 ? jobs.description.indexOf("<p>") > 0 ? jobs.description.slice(0, 100) : jobs.description.slice(0, 100) : jobs.description}` }} />}
                                                            {jobs.description.length ? <button id="readmore-btn" data-toggle="modal" data-target={`${"#jobsPopupModal"}${index}`}>...read more </button> : ""}
                                                        </div>
                                                    </div>
                                                    <div className="min-badge-height">
                                                        {jobs.company && jobs.company.companyAboutUs && jobs.company.companyAboutUs.remoteWorking ? <div className="tag badge badge-pill"> <a>{jobs.company && jobs.company.companyAboutUs && jobs.company.companyAboutUs.remoteWorking ? `Remote` : ""}</a></div> : ""}
                                                        {jobs.company && jobs.company.companyAboutUs && jobs.company.companyAboutUs.remoteWorking ? jobs.jobCategory && jobs.jobCategory.slice(0, 2).map((data, index) => {
                                                            return (<>
                                                                {index <= 2 ? <div key={index + data} className={index <= 2 ? "tag badge badge-pill" : "hide"}>{index <= 2 ? data.name : ""} </div> : ""}
                                                            </>
                                                            )
                                                        }) :
                                                            jobs.jobCategory && jobs.jobCategory.map((data, index) => {
                                                                return (<>
                                                                    {index <= 3 ? <div key={index + data} className={index <= 3 ? "tag badge badge-pill" : "hide"}>{index <= 3 ? data.name : ""} </div> : ""}
                                                                </>
                                                                )
                                                            })}
                                                        {jobs.company && jobs.company.companyAboutUs && jobs.company.companyAboutUs.remoteWorking && jobs.jobCategory.length - 2 > 0 ?
                                                            <div className="tag badge badge-pill ab-tag" data-toggle="modal" data-target={`${"#jobsPopupModal"}${index}`}> <a>{jobs.jobCategory.length - 2 > 0 && ` +${jobs.jobCategory.length - 2}`}</a></div> :
                                                            jobs && jobs.jobCategory.length - 3 > 0 && <div className="tag badge badge-pill ab-tag" data-toggle="modal" data-target={`${"#jobsPopupModal"}${index}`}> <a>{jobs.jobCategory.length - 3 > 0 && ` +${jobs.jobCategory.length - 3}`}</a></div>}
                                                    </div>
                                                    {Common.UserType() == "candidate" ? <button className="btn btn-outline big-btn" onClick={() => ApplyJobs(jobs.id)} >Apply</button> : <button className="btn btn-outline big-btn" onClick={() => ApplyJobs("login")}>apply</button>}
                                                </div>
                                            </div>

                                            <div className="modal fade apply-modal"
                                                key={index + jobs + 1}
                                                id={`${"jobsPopupModal"}${index}`}
                                                tabIndex="-1"
                                                role="dialog"
                                                aria-labelledby="exampleModalLabel"
                                                aria-hidden="true"
                                            >
                                                <div className="modal-dialog modal-lg modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <button type="button" className="close modalclose-btn pedit2" data-dismiss="modal">&times;</button>

                                                        <div className="modal-header">
                                                            <div className="bg-img-box">
                                                                <img src="https://images.unsplash.com/photo-1707740901903-fa6d1f367b19?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" className="img-fluid bg-img" />

                                                            </div>
                                                            <div className="icon">
                                                                <img src={companyData ? companyData.logo : ""} alt="" className="img-fluid rounded-circle m " ></img>
                                                            </div>
                                                            <div className="modal-title mdl-heading">{jobs.title}</div>
                                                            <div className="user-location">
                                                                <img src="images/location-icon.png" />{`${jobs.city}, ${jobs.state}`}
                                                            </div>
                                                            {Common.UserType() == "candidate" ? <button className="btn btn-outline s-btn" onClick={() => ApplyJobs(jobs.id)} >Apply</button> : <button className="btn btn-outline s-btn" onClick={() => ApplyJobs("login")}>apply</button>}
                                                        </div>
                                                        <div className="modal-body custom-padding">
                                                            <div className="container-fluid pr-0">
                                                                <div className="row mt-0 mr-0 justify-content-between">
                                                                    <div className="col-sm-8">
                                                                        <div className="modalbody-content">
                                                                            <p className="desc">We're hiring! <br />
                                                                                {(jobs.description.match(/<p>/g) || []).length === 1 ?
                                                                                    <div style={{ marginTop: "10px" }} dangerouslySetInnerHTML={{ __html: `${jobs.description && jobs.description.length > 120 ? jobs.description.indexOf("<p>") > 0 ? jobs.description.slice(0, 500) : jobs.description.slice(0, 500) : jobs.description}` }} /> :
                                                                                    (jobs.description.match(/<p>/g) || []).length === 0 ? <div dangerouslySetInnerHTML={{ __html: `${jobs.description && jobs.description.length > 120 ? jobs.description.indexOf("<p>") > 0 ? jobs.description.slice(3, 120) : jobs.description.slice(0, 500) : jobs.description}` }} />
                                                                                        : <div className="editor-description" dangerouslySetInnerHTML={{ __html: `${jobs.description && jobs.description.length > 120 ? jobs.description.indexOf("<p>") > 0 ? jobs.description.slice(3, 120) : jobs.description.slice(0, 500) : jobs.description}` }} />}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-4 job-details">

                                                                        <div className="job-description">
                                                                            {jobs && jobs.jobCategory.length > 0 ? <div className="j-type-name">JOB TYPE</div> : ""}
                                                                            <div className="d-flex flex-wrap">
                                                                                {jobs.company && jobs.company.companyAboutUs && jobs.company.companyAboutUs.remoteWorking ? <div className="j-type mr-1"> <a>{jobs.company && jobs.company.companyAboutUs && jobs.company.companyAboutUs.remoteWorking ? `Remote | ` : ""}</a></div> : ""}
                                                                                {jobs && jobs.jobCategory ? jobs.jobCategory.map((data, index) => {
                                                                                    return (
                                                                                        <div className="j-type mr-1" key={index}>{data.name}{jobs.jobCategory.length > 1 && jobs.jobCategory.length - 1 > index ? ' | ' : ""} </div>
                                                                                    )
                                                                                }) : ""}
                                                                            </div>
                                                                        </div>

                                                                        <div className="job-description">
                                                                            {jobs && jobs.companyJobToolkits.length > 0 ? <div className="j-type-name">TOOLKIT</div> : ""}
                                                                            <div className="d-flex flex-wrap">
                                                                                {jobs && jobs.companyJobToolkits ? jobs.companyJobToolkits.map((data, index) => {
                                                                                    return (
                                                                                        <div className="j-type mr-1" key={index}>{data.name}{jobs.companyJobToolkits.length > 1 && jobs.companyJobToolkits.length - 1 > index ? ' | ' : ""} </div>
                                                                                    )
                                                                                }) : ""}
                                                                            </div>
                                                                        </div>
                                                                        <div className="job-description">
                                                                            <div className="j-type-name">JOB LOCATION</div>
                                                                            <div className="j-type">{`${jobs.city}, ${jobs.state}`}</div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>)
                                    }) : ""}
                                </div>
                                {companyJobsData ? companyJobsData.map((jobs, index) => {
                                    return (<div className="modal fade xy-form"
                                        key={index + jobs + 1}
                                        id={`${"exampleJobsModal111"}${index}`}
                                        tabIndex="-1"
                                        role="dialog"
                                        aria-labelledby="exampleModalLabel"
                                        aria-hidden="true">
                                        <div className="modal-dialog  modal-dialog-centered modal-xl sm"  >
                                            <div className="modal-content">
                                                <div className="modal-header form-mdlheadr">
                                                    <h4 className="modal-title mdl-heading">Edit</h4>
                                                    <button type="button" className="close modalclose-btn" data-dismiss="modal">&times;</button>
                                                </div>
                                                <div className="modal-body">
                                                    {verify ? <CompanyJobs id={jobs.id} GetcompanyJobs={GetCompanyJobs} show={show2} setShow2={setShow2} modalid={`${"exampleJobsModal111"}${index}`} /> : ""}
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                                }) : ""}
                                {/* jobs Add */}
                                <div className="modal fade xy-form"

                                    id="exampleJobsModaladd1"
                                    tabIndex="-1"
                                    role="dialog"
                                    aria-labelledby="exampleModalLabel"
                                    aria-hidden="true">
                                    <div className="modal-dialog form-w modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header form-mdlheadr">
                                                <h4 className="modal-title mdl-heading">Edit</h4>
                                                <button type="button" className="close modalclose-btn" data-dismiss="modal">&times;</button>
                                            </div>
                                            <div className="modal-body">
                                                {verify ? <CompanyJobs id2='0' GetcompanyJobs={GetCompanyJobs} show={show2} setShow2={setShow2} modalid={`exampleJobsModaladd1`} /> : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* <!-- media --> */}
                        <div className="profile-tab stat-sec profile-sub-sec  " id="media">
                            <div className="news-sec content theme-container">
                                <div className="title line">
                                    media and events
                                </div>

                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="news-card">
                                            <div className="tab-heading  add-btn">
                                                {verify ? <div onClick={() => EditMedia()} className="add-option "><span></span><a className="icon" data-toggle="modal" data-target="#exampleCompanyMediaAdd1">
                                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                                </a></div> : ""}
                                            </div>
                                            <div className="line tab-heading">
                                                Media
                                            </div>
                                            <div id="carouselExampleIndicators" className="carousel  slide" data-ride="carousel">
                                                {/* Indicators  */}
                                                <ul className="carousel-indicators carousel-dots" id="mediadots">
                                                    {companyMediaData
                                                        ? companyMediaData.map((item, index) => {
                                                            return (
                                                                <li key={index} data-target="#carouselExampleIndicators" data-slide-to={`${index}`} className={index == 0 ? "active" : ""}></li>
                                                            )
                                                        }) : ""}
                                                </ul>
                                                {/* The slideshow */}
                                                <div className="carousel-inner" id="mediaelem">
                                                    {companyMediaData
                                                        ? companyMediaData.map((item, index) => {
                                                            return (<div key={index + item} className={index == 0 ? `carousel-item active` : companyMediaData.length == 1 ? "carousel-item active" : "carousel-item"}>
                                                                <div  >
                                                                    {verify ? <div className=" edit2 add-option"><FaPencilAlt onClick={() => EditMedia()} className="icon" data-toggle="modal" data-target={`#exampleMediaAdd${index}`} /></div> : ""}
                                                                    <div className="img-block">
                                                                        <img src={item ? item.imageUrl : "./images/news-img.png"} alt="" />
                                                                    </div>
                                                                    <div className="tab-heading pb-0 mb-1 mt-3" style={{ cursor: "pointer" }} data-toggle="modal" data-target={`#exampleMediaPopup${index}`}>
                                                                        {companyMediaData ? item.title : "free plans are coming this week!"}
                                                                    </div>
                                                                    <div className="detail" style={{ cursor: "pointer" }} data-toggle="modal" data-target={`#exampleMediaPopup${index}`}>
                                                                        {item.description ? isReadMore == false ? item.description.slice(0, 210) : item.description : ""} {item.description && item.description.length ? <button data-toggle="modal" data-target={`#exampleMediaPopup${index}`} id="readmore-btn">...read more </button> : ""}
                                                                    </div>
                                                                </div>
                                                            </div>)
                                                        }) : ""}
                                                </div>
                                                {companyMediaData
                                                    ? companyMediaData.map((item, index) => {
                                                        return (<><div className="modal fade  apply-modal "
                                                            key={index + item + 1}
                                                            id={`exampleMediaPopup${index}`}
                                                            tabIndex="-1"
                                                            role="dialog"
                                                            aria-labelledby="exampleModalLabel"
                                                            aria-hidden="true">
                                                            <div className="modal-dialog modal-lg modal-dialog-centered">
                                                                <div className="modal-content">
                                                                    <button type="button" className="close modalclose-btn pedit2" data-dismiss="modal">&times;</button>

                                                                    <div className="modal-header">
                                                                        <div className="modal-title mdl-heading mt-2">{item.title}</div>
                                                                        <h6 className="mt-2 j-type-name">Date: {moment(item.date).format("DD")} {moment(item.date).format("MMM")} {moment(item.date).format("YYYY")} </h6>
                                                                    </div>
                                                                    <div className="modal-body custom-padding">
                                                                        <div className="container-fluid pr-0">
                                                                            <div className="row mt-0 mr-0 justify-content-between">
                                                                                <div className="col-sm-8">
                                                                                    <div className="modalbody-content">
                                                                                        <p className="desc"> {item.description ? item.description : ""}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-4  ">
                                                                                    <img src={item ? item.imageUrl : "./images/news-img.png"} alt="" className="img-fluid fix-h" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                            <div className="modal fade xy-form"
                                                                key={index + item + 1}
                                                                id={`exampleMediaAdd${index}`}
                                                                tabIndex="-1"
                                                                role="dialog"
                                                                aria-labelledby="exampleModalLabel"
                                                                aria-hidden="true">
                                                                <div className="modal-dialog  form-w modal-dialog-centered">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header form-mdlheadr">
                                                                            <h4 className="modal-title mdl-heading">Edit</h4>
                                                                            <button type="button" className="close modalclose-btn" data-dismiss="modal">&times;</button>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            {verify ? <CompanyMedia id={item.id} GetCompanyMedia={GetCompanyMedia} show={show3} setShow3={setShow3} modalid={`exampleMediaAdd${index}`} /> : ""}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div></>
                                                        )
                                                    }) : ""}
                                            </div>
                                        </div>
                                        <div className="modal fade xy-form"
                                            id="exampleCompanyMediaAdd1"
                                            tabIndex="-1"
                                            role="dialog"
                                            aria-labelledby="exampleModalLabel"
                                            aria-hidden="true">
                                            <div className="modal-dialog form-w modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-header form-mdlheadr">
                                                        <h4 className="modal-title mdl-heading">Edit</h4>
                                                        <button type="button" className="close modalclose-btn" data-dismiss="modal">&times;</button>
                                                    </div>
                                                    <div className="modal-body">
                                                        {verify ? <CompanyMedia id2="0" GetCompanyMedia={GetCompanyMedia} show={show3} setShow3={setShow3} modalid={`exampleCompanyMediaAdd1`} /> : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="news-card event-card scrollbar" id="scrollbar">
                                            <div className="tab-heading line">
                                                events
                                                {verify ? <div onClick={() => EditEvent()} className="edit2  add-option "><span></span><a className="icon" data-toggle="modal" data-target="#exampleModaladdEvent">
                                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                                </a></div> : ""}
                                            </div>
                                            {companyEventData ? companyEventData.map((event, index) => {
                                                return (<section key={index + event + 1}>
                                                    <div className="display-inline event start pl-0 pr-0">
                                                        <div className="img-block" data-toggle="modal" data-target={`${"#exampleModalImage"}${index}`}>
                                                            <div>{moment(event.fromDate).format("M") == moment(event.toDate).format("M") ?
                                                                moment(event.fromDate).format("DD") == moment(event.toDate).format("DD") ?
                                                                    `  ${moment(event.fromDate).format("DD")} ${moment(event.fromDate).format("MMM")} ${moment(event.fromDate).format("YYYY")}` :
                                                                    `  ${moment(event.fromDate).format("DD")} - ${moment(event.toDate).format("DD")} ${moment(event.toDate).format("MMM")} ${moment(event.fromDate).format("YYYY")} ` :
                                                                `  ${moment(event.fromDate).format("DD")} ${moment(event.fromDate).format("MMM")} ${moment(event.fromDate).format("YYYY")} - ${moment(event.toDate).format("DD")} ${moment(event.toDate).format("MMM")} ${moment(event.toDate).format("YYYY")} `}</div>
                                                        </div>
                                                        <div className="headings ">
                                                            <div className="tab-heading pb-0 mb-0" data-toggle="modal" data-target={`${"#exampleModalImage"}${index}`}>{event && event.title} </div>
                                                            {verify ? <div className=" edit2 add-option"><FaPencilAlt onClick={() => EditEvent()} className=" icon" data-toggle="modal" data-target={`${"#exampleModalEvent"}${index}`} /></div> : ""}
                                                            <div className="detail" data-toggle="modal" data-target={`${"#exampleModalImage"}${index}`}>
                                                                {event.description.length >= 70 ? `${event.description.slice(0, 70)}` : event.description}
                                                            </div>
                                                            {event.description && event.description.length ? <button data-toggle="modal" className="float-right" data-target={`${"#exampleModalImage"}${index}`} id="readmore-btn">...read more </button> : ""}
                                                        </div>
                                                    </div>
                                                    <div className="divider"></div>
                                                    <div className="modal fade xy-form"

                                                        id={`${"exampleModalEvent"}${index}`}
                                                        tabIndex="-1"
                                                        role="dialog"
                                                        aria-labelledby="exampleModalLabel"
                                                        aria-hidden="true">
                                                        <div className="modal-dialog form-w modal-dialog-centered" >
                                                            <div className="modal-content">
                                                                <div className="modal-header form-mdlheadr">
                                                                    <h4 className="modal-title mdl-heading">Edit</h4>
                                                                    <button type="button" className="close modalclose-btn" data-dismiss="modal">&times;</button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    {verify ? <CompanyEvents id={event.id} GetCompanyEvent={GetCompanyEvent} show={show4} setShow4={setShow4} modalid={`${"exampleModalEvent"}${index}`} /> : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal fade apply-modal"
                                                        id={`${"exampleModalImage"}${index}`}
                                                        tabIndex="-1"
                                                        role="dialog"
                                                        aria-labelledby="exampleModalLabel"
                                                        aria-hidden="true"
                                                    >
                                                        <div className="modal-dialog modal-lg modal-dialog-centered">
                                                            <div className="modal-content">
                                                                <button type="button" className="close modalclose-btn pedit2" data-dismiss="modal">&times;</button>
                                                                <div className="modal-header">
                                                                    <div className="bg-img-box">
                                                                        <img src={event ? event.imageUrl : ""} className="img-fluid bg-img" />
                                                                    </div>
                                                                    <div className="icon">
                                                                        <img style={{ borderRadius: "100px" }} src={companyData && companyData.logo ? companyData.logo : ""} className="img m" />
                                                                    </div>
                                                                    <div className="modal-title mdl-heading mt-4">{event.title}</div>
                                                                    <div className="j-type-name d-flex justify-content-center mt-3">
                                                                        Event Date :
                                                                        <div className="j-type pl-1"> {moment(event.fromDate).format("M") == moment(event.toDate).format("M") ?
                                                                            moment(event.fromDate).format("DD") == moment(event.toDate).format("DD") ?
                                                                                `  ${moment(event.fromDate).format("DD")} ${moment(event.fromDate).format("MMM")} ${moment(event.fromDate).format("YYYY")}` :
                                                                                `  ${moment(event.fromDate).format("DD")} - ${moment(event.toDate).format("DD")} ${moment(event.toDate).format("MMM")} ${moment(event.fromDate).format("YYYY")} ` :
                                                                            `  ${moment(event.fromDate).format("DD")} ${moment(event.fromDate).format("MMM")} ${moment(event.fromDate).format("YYYY")} - ${moment(event.toDate).format("DD")} ${moment(event.toDate).format("MMM")} ${moment(event.toDate).format("YYYY")} `}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="modal-body custom-padding">
                                                                    <div className="container-fluid pr-0">
                                                                        <div className="row justify-content-between">
                                                                            <div className="col-md-8">
                                                                                <p className="detail">  {event.description ? `${event.description}` : ""}
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-md-4 job-details">
                                                                                <div className="sub-head text-left">
                                                                                    Fill Your Details
                                                                                </div>
                                                                                <form autoComplete="off">
                                                                                    <div className="row xy-input-grp">
                                                                                        <div className="col-md-6">
                                                                                            {verify ? <input type="text" className="form-control xu-input" onChange={(e) => eventChange(e)} name="firstName" value={companyEventModel.firstName ? companyEventModel.firstName : ""} placeholder="First Name" disabled /> : <input type="text" className="form-control xu-input" onChange={(e) => eventChange(e)} name="firstName" value={companyEventModel.firstName ? companyEventModel.firstName : ""} placeholder="First Name" />}
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            {verify ? <input type="text" className="form-control xu-input" onChange={(e) => eventChange(e)} name="lastName" value={companyEventModel.lastName ? companyEventModel.lastName : ""} placeholder="Last Name" disabled /> : <input type="text" className="form-control xu-input" onChange={(e) => eventChange(e)} name="lastName" value={companyEventModel.lastName ? companyEventModel.lastName : ""} placeholder="Last Name" />}
                                                                                        </div>
                                                                                        <div className="col-md-12">
                                                                                            {verify ? <input type="email" className="form-control xu-input" onChange={(e) => eventChange(e)} name="email" value={companyEventModel?.email ? companyEventModel?.email : ""} placeholder="email" disabled /> : <input type="email" className="form-control xu-input" onChange={(e) => eventChange(e)} name="email" value={companyEventModel?.email ? companyEventModel?.email : ""} placeholder="email" />}
                                                                                        </div>
                                                                                        <div className="col-md-12">
                                                                                            {verify ? <input type="number" className="form-control xu-input" onChange={(e) => eventChange(e)} name="contact" value={companyEventModel?.contact ? companyEventModel?.contact : ""} placeholder="+91-9383903823" disabled /> : <input type="number" className="form-control xu-input" onChange={(e) => eventChange(e)} name="contact" value={companyEventModel?.contact ? companyEventModel?.contact : ""} placeholder="+91-9383903823" />}
                                                                                        </div>
                                                                                    </div>
                                                                                    <a className="btn btn-outline a-btn mx-auto d-block" onClick={() => verify ? AttendCompanyEvent(event.id, "type") : AttendCompanyEvent(event.id)}>Attend</a>
                                                                                </form>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal fade "
                                                        id={`${"exampleModalImage"}${index}`}
                                                        tabIndex="-1"
                                                        role="dialog"
                                                        aria-labelledby="exampleModalLabel"
                                                        aria-hidden="true">
                                                        <div className="modal-dialog modal-lg modal-dialog-centered " >

                                                            <div className="modal-content">
                                                                <div className="modal-body">
                                                                    <div className="modal-header form-mdlheadr">
                                                                        <h4 className="modal-title mdl-heading">Edit</h4>
                                                                        <button type="button" className="close modalclose-btn" data-dismiss="modal">&times;</button>
                                                                    </div>
                                                                    <div className="headings">
                                                                        <div>Date: {moment(event.fromDate).format("M") == moment(event.toDate).format("M") ?
                                                                            moment(event.fromDate).format("DD") == moment(event.toDate).format("DD") ?
                                                                                `${moment(event.fromDate).format("DD")} ${moment(event.fromDate).format("MMMM ")} ${moment(event.fromDate).format("YYYY")}` :
                                                                                `${moment(event.fromDate).format("DD")} - ${moment(event.toDate).format("DD")} ${moment(event.fromDate).format("MMMM ")} ${moment(event.fromDate).format("YYYY")}` :
                                                                            `${moment(event.fromDate).format("DD/MM/YYYY")} - ${moment(event.toDate).format("DD/MM/YYYY")}`}</div>
                                                                        <div className="detail">
                                                                            {event.description ? `${event.description.slice(0, 100)}...` : ""}
                                                                        </div>
                                                                    </div>
                                                                    <img src={event ? event.imageUrl : ""} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>)
                                            }) : ""}
                                        </div>
                                        <div className="modal fade xy-form"
                                            id="exampleModaladdEvent"
                                            tabIndex="-1"
                                            role="dialog"
                                            aria-labelledby="exampleModalLabel"
                                            aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered" >
                                                <div className="modal-content">
                                                    <div className="modal-header form-mdlheadr">
                                                        <h4 className="modal-title mdl-heading">Edit</h4>
                                                        <button type="button" className="close modalclose-btn" data-dismiss="modal">&times;</button>
                                                    </div>
                                                    <div className="modal-body">
                                                        {verify ? <CompanyEvents id2='0' GetCompanyEvent={GetCompanyEvent} show={show4} setShow4={setShow4} modalid={`exampleModaladdEvent`} /> : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- contact --> */}
                        <section className="profile-tab section  bg-sec " id="contact">
                            <div className="stat-sec">
                                <div className="contact-sec ">
                                    <div className="title line">
                                        contact
                                    </div>
                                    <div className="row justify-content-center align-items-center">
                                        <div className="col-md-6">
                                            <div className="contacts">
                                                <img src="./images/contact-illu.png" alt="" className="img" />
                                                <div className="extra-info ">
                                                    <div className="sub-title">
                                                        <img src="./images/call-icon.png" alt="" />
                                                        <div className="contact-details">
                                                            <span> contact number</span><br /> <a style={{ color: "black" }} href={`tel:+${companyData?.contact}`}>+{companyData?.contact}</a>
                                                        </div>
                                                    </div>
                                                    <div className="sub-title">
                                                        <img src="./images/mail-icon.png" alt="" />
                                                        <div className="contact-details"> <span>Email</span><br /> <a style={{ color: "black" }} href={`mailto:${companyData?.email}`}
                                                            title={companyData?.email} target="_blank"> {companyData?.email}</a> </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="bg-lines">
                                                <div className="login-box type-box">
                                                    <div className="form-sec">
                                                        <form action="" autoComplete="off">
                                                            <div>
                                                                <div className="label">
                                                                    name
                                                                </div>
                                                                {verify ? <input type="text" id="senderName" value={contactModel.senderName} onChange={(e) => ChangeHandler(e)} className={border.senderName ? `${border.senderName}` : "xu-input"} disabled /> : <input type="text" id="senderName" value={contactModel.senderName} onChange={(e) => ChangeHandler(e)} className={border.senderName ? `${border.senderName}` : "xu-input"} />}
                                                            </div>
                                                            <div>
                                                                <div className="label">
                                                                    email
                                                                </div>
                                                                {verify ? <input type="text" className={border.senderEmail ? `${border.senderEmail}` : "xu-input"} id="senderEmail" value={contactModel.senderEmail} onChange={(e) => ChangeHandler(e)} disabled /> : <input type="text" className={border.senderEmail ? `${border.senderEmail}` : "xu-input"} id="senderEmail" value={contactModel.senderEmail} onChange={(e) => ChangeHandler(e)} />}
                                                            </div>
                                                            <div>
                                                                <div className="label">
                                                                    Message
                                                                </div>
                                                                {verify ? <textarea type="text" className={border.message ? `${border.message}` : "xu-textarea"} value={contactModel.message} id="message" onChange={(e) => ChangeHandler(e)} disabled></textarea> : <textarea type="text" className={border.message ? `${border.message}` : "xu-textarea"} value={contactModel.message} id="message" onChange={(e) => ChangeHandler(e)}></textarea>}
                                                            </div>

                                                        </form>
                                                        {verify ? <button className="btn sign-btn btn-outline big-btn mt-0" onClick={(e) => contactHandle(e, "type")}>Send Message</button> : <button className="btn sign-btn btn-outline big-btn mt-0" onClick={(e) => contactHandle(e)}>Send Message</button>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </section>
                    </div>
                </div>
            </section >
            {loader}
        </>
    )
}
export default CompanyProfile;