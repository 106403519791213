import Common from "../../Utility/Common";

const CollegeAboutUsModel = () => ({
    id: null,
    heading: "",
    description: "",
    image: "",
    website: "",
    noOfStudents: "",
    qualificationsOffered: "",
    headquerters: "",
    active: true,
    userId: Common.UserId(),
    founded: "",
    collegeId: Common.LoginId(),
    inPersonTeaching:false,
    onlineTeaching:false
});
export default CollegeAboutUsModel
