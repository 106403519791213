import React, { useState, useEffect, useRef } from "react";
import Common from "../../../Utility/Common";
import DefaultModel from "../../../Model/Company/CompanyLocationModel";
import service from "../../../Services/UtilityService";
import ServiceAPI from "../../../Utility/ServiceList";
import FormHeader from "../../Common/FormHeader";
import CompanyNav from "./CompanyNav";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useLoader from "../../Auth/Loader"
import AdminHeader from '../../Common/AdminHeader'
import { useHistory } from "react-router-dom";
import $ from 'jquery'

toast.configure()

const CompanyLocation = (props) => {
  const history = useHistory();
  localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
  Common.ValidLogin();
  const apiKey = "AIzaSyAVLTGiMQzxgYBYnviizjkO9g - HRLIEMH4";
  const mapApiJs = 'https://maps.googleapis.com/maps/api/js';
  const searchInput = useRef('');
  const id = props.match.params.id;
  const [model, setModel] = useState(DefaultModel);
  const [border, setBorder] = useState("");
  const services = new service();
  const [loader, showLoader, hideLoader] = useLoader();

  useEffect(() => {
    if (id) {
      GetById(id);
    }
  }, [id]);
  useEffect(() => {
    initMapScript().then(() => initAutocomplete())
  }, [searchInput.current]);
  const loadAsyncScript = async (src) => {
    return new Promise(resolve => {
      const script = document.createElement("script");
      Object.assign(script, {
        type: "text/javascript",
        async: true,
        src
      })
      script.addEventListener("load", () => resolve(script));
      document.head.appendChild(script);
    })
  }
  // init gmap script
  const initMapScript = async () => {
    if (window.google) {
      return await Promise.resolve();
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return await loadAsyncScript(src);
  }
  const extractAddress = (place) => {
    const address = {
      city: "",
      state: "",
      country: "",
      postalCode: "",
      address: "",
      plain() {
        const city = city ? city + ", " : "";
        const postalCode = postalCode ? postalCode + ", " : "";
        const state = state ? state + ", " : "";
        const address = address ? address + ", " : "";
        const country = country ? country + ", " : "";
        return city + postalCode + state + country + address;
      }
    }

    if (!Array.isArray(place?.address_components)) {
      return address;
    }

    place.address_components.forEach(component => {
      const types = component.types;
      const value = component.long_name;

      if (types.includes("locality")) {
        address.city = value;
      }

      if (types.includes("administrative_area_level_1")) {
        address.state = value;
      }

      if (types.includes("postal_code")) {
        address.postalCode = value;
      }

      if (types.includes("country")) {
        address.country = value;
      }
      if (place) {
        address.address = place.formatted_address.split(",")[0];
      }
    });
    return address;
  }
  const onChangeAddress = async (autocomplete) => {
    const place = await autocomplete.getPlace();
    var values = extractAddress(place)
    setTimeout(() => {
      setModel({ ...JSON.parse(localStorage.getItem("location")), ...values });
    }, 1000);
  }

  // init autocomplete
  const initAutocomplete = () => {
    if (!searchInput.current) {
      return;
    }
    else {
      const autocomplete = new window.google.maps.places.Autocomplete(searchInput.current);
      autocomplete.setFields(["address_component", "geometry", "formatted_address"]);
      autocomplete.addListener("place_changed", () => onChangeAddress(autocomplete));
    }
  }
  const ChangeHandler = (e) => {
    let value = {};
    if (e.target.type === "checkbox") {
      value[e.target.id] = e.target.checked;
    } else {
      value[e.target.id] = e.target.value;
    }
    setModel({ ...model, ...value });
    setBorder({ ...border, [e.target.id]: "" })
    if (e.target.id == "address") {
      localStorage.setItem("location", JSON.stringify(model))
    }
  };
  const Create = async (e) => {
    e.preventDefault();
    var response = null;

    if (valid()) {
      let value = { userId: Common.UserId() };
      setModel({ ...model, ...value });
      showLoader();
      if (model.id === null) {
        response = await services.Create(ServiceAPI.CompanyLocationCreateApi, model);
      } else {
        response = await services.Update(ServiceAPI.CompanyLocationUpdateApi, model);
      }
      if (response && response.message === "Success") {
        localStorage.removeItem("location")
        toast.success("Successful", { theme: "colored" });
        setTimeout(() => {
          history.push("/companylocationlist");
        }, 2000);
      } else {
        hideLoader();
      }
    }
  };
  const GetById = async (id) => {
    $('.modal-backdrop').removeClass('show ');
    $('.modal').removeClass('show');
    $('body').toggleClass('p-0');
    $('body').removeClass('modal-open');
    showLoader()
    setModel(DefaultModel);
    var res = await services.GetById(ServiceAPI.CompanyLocationGetApi, id);
    hideLoader()
    const location = await res.result;
    setModel(location);
  }
  const valid = () => {
    let Error = [];
    let name = "";
    let address = "";
    let postalCode = "";
    let city = "";
    let state = "";
    let country = "";

    if (!Boolean(model.name)) {
      Error.push("Valid Name required");
      name = "borderred";
    }
    if (model.name.length > 500) {
      Error.push("Valid Length required for name");
      name = "borderred";
    }
    if (!Boolean(model.address)) {
      Error.push("Valid address required");
      address = "borderred";
    }
    if (!Boolean(model.postalCode)) {
      Error.push("Valid PostalCode required");
      postalCode = "borderred";
    }
    if (!Boolean(model.city)) {
      Error.push("Valid City required");
      city = "borderred";
    }
    if (!Boolean(model.state)) {
      Error.push("Valid state required");
      state = "borderred";
    }
    if (!Boolean(model.country)) {
      Error.push("Valid country required");
      country = "borderred";
    }

    if (name || address || postalCode || city || state || country) {
      setBorder({ name, address, postalCode, city, state, country });
    }
    if (Error.length === 0) {
      Error = [];
      return true;
    } else if (Error.length > 2) {
      toast.error('Please fill the required fields', { theme: "colored", toastId: 'error1' });
      return false;
    } else if (Error.length <= 2) {
      Error.forEach((element, index) => {
        toast.error(element, { theme: "colored", toastId: `${[index]}` });
      });
      return false;
    }
  };
  const back = () => {
    history.push("/companylocationlist");
  }
  return (
    <>
      <AdminHeader heading="" subheading="Manage Location" />
      <form onSubmit={Create} >
        <div className="theme-container-admin container-fluid">
          <CompanyNav location="active"></CompanyNav>
          <div className="row">
            <div className="col-md-6 mx-auto detail-box form-sec">
              <div className="row m-0 align-items-center">

                <div className="col-md-12">
                  <div className="label">Name<span className="required">*</span></div>
                  <input
                    autoComplete="none"
                    type="text"
                    id="name"
                    className={border.name ? border.name : "xu-input"}
                    placeholder="Name"
                    value={model.name}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                </div>
                <div className="col-md-12">
                  <div className="label">Address<span className="required">*</span></div>
                  <input
                    autoComplete="none"
                    type="text"
                    id="address"
                    className={border.address ? border.address : "xu-input"}
                    placeholder="Address"
                    ref={searchInput}
                    value={model.address}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                </div>
                <div className="col-md-6">
                  <div className="label">PostalCode<span className="required">*</span></div>
                  <input
                    autoComplete="none"
                    type="text"
                    id="postalCode"
                    className={border.postalCode ? border.postalCode : "xu-input"}
                    placeholder="PostalCode"
                    value={model.postalCode}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                </div>
                <div className="col-md-6">
                  <div className="label">City<span className="required">*</span></div>
                  <input
                    autoComplete="none"
                    type="text"
                    id="city"
                    className={border.city ? border.city : "xu-input"}
                    placeholder="city"
                    value={model.city}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                </div>
                <div className="col-md-6">
                  <div className="label">State/Province<span className="required">*</span></div>
                  <input
                    autoComplete="none"
                    type="text"
                    id="state"
                    className={border.state ? border.state : "xu-input"}
                    placeholder="State/Province"
                    value={model.state}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                </div>
                <div className="col-md-6">
                  <div className="label">Country<span className="required">*</span></div>
                  <input
                    autoComplete="none"
                    type="text"
                    id="country"
                    className={border.country ? border.country : "xu-input"}
                    placeholder="country"
                    value={model.country}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                </div>
                <div className="col-md-12">
                  <div className="label">Active</div>
                  <label className="switch">
                    <input
                      className=""
                      type="checkbox"
                      id="active"
                      checked={model.active}
                      onChange={(e) => ChangeHandler(e)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="col-md-12 spacing">
                  <button
                    className="btn btn-outline a-btn mt-0 float-right"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>{" "}
            </div>{" "}
          </div>
          {props.id || props.id2 ? "" : <div className='back-icon' onClick={back}></div>}
        </div>
      </form>
      {loader}
    </>
  );
}

export default CompanyLocation;