import Common from "../../Utility/Common";
const CompanyModel = () => ({
        firstName: '',
        lastName: '',
        name: '',
        description: '',
        address: '',
        country: '',
        state: '',
        city: '',
        pincode: '',
        employee: 0,
        coverImage: "",
        logo: '',
        website: '',
        contact: '',
        email: '',
        twitterUrl: '',
        facebookUrl: '',
        instagramUrl: '',
        active: true,
        isDefaultLogo: false,
        industry: "",
        industryId: '',
        userId: Common.UserId(),
        id: Common.LoginId()

});
export default CompanyModel;