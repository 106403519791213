import React, { useState, useEffect } from "react";
import Common from "../../../Utility/Common";
import DefaultModel from "../../../Model/Auth/ChangePassword";
import service from "../../../Services/RegistrationService";
import FormHeader from "../../Common/FormHeader";
import CompanyNav from "./CompanyNav";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useLoader from "../../Auth/Loader"
import AdminHeader from '../../Common/AdminHeader'

toast.configure()

const ChnagePassword = () => {
    Common.ValidLogin();
    localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
    const [model, setModel] = useState(DefaultModel);
    const [border, setBorder] = useState("");
    const services = new service();
    const [loader, showLoader, hideLoader] = useLoader();
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordShown2, setPasswordShown2] = useState(false);
    const [passwordShown3, setPasswordShown3] = useState(false);

    const ChangeHandler = (e) => {
        let value = {};
        value[e.target.id] = e.target.value;
        setModel({ ...model, ...value, email: Common.Email() });
        setBorder({ ...border, [e.target.id]: "" })
    };
    const togglePassword = (e) => {
        e.preventDefault();
        setPasswordShown(!passwordShown);
    };
    const togglePassword2 = (e) => {
        e.preventDefault();
        setPasswordShown2(!passwordShown2);
    };
    const togglePassword3 = (e) => {
        e.preventDefault();
        setPasswordShown3(!passwordShown3);
    };


    const Create = async (e) => {
        e.preventDefault();
        var response = null;
        if (valid()) {
            showLoader();
            response = await services.ChangePassword(model);
            hideLoader();
            if (response && response.message === "Success") {
                toast.success('Password Changed Successfully.', { theme: "colored", autoClose: 3000 });
            }
            setModel(DefaultModel);
        }
    };

    const valid = () => {
        let currentPassword = "";
        let password = "";
        let confirmPassword = "";

        function validationPassword(s) {
            return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(s);
        }

        if (!Boolean(model.currentPassword)) {
            toast.error("Valid Current Password required", { theme: "colored", toastId: 'error1' });
            currentPassword = "borderred";
        }
        if (!Boolean(model.password)) {
            toast.error("Valid Password required", { theme: "colored", toastId: 'error2' });
            password = "borderred";
        }
        if (!Boolean(model.confirmPassword)) {
            toast.error("Valid Confirm Password required", { theme: "colored", toastId: 'error3' });
            confirmPassword = "borderred";
        }
        if (!validationPassword(model.password)) {
            toast.error("Passwords must have at least one uppercase letter, one lowercase letter, one number and one special character", { theme: "colored", toastId: 'error2' });
            password = "borderred"
        }

        if (model.confirmPassword !== model.password) {
            toast.error("Password not match", { theme: "colored", toastId: 'error3' });
            confirmPassword = "borderred";
        }
        if (model.password.length < 8) {
            toast.error(" Password length should be grater then 8 character.", { theme: "colored", toastId: 'error2' });
            password = "borderred";
        }
        if (currentPassword || password || confirmPassword) {
            setBorder({ currentPassword, password, confirmPassword });
            return false;
        }
        return true;
    };
    return (
        <>
            <AdminHeader heading="" subheading="Manage Password" />
            <form onSubmit={Create} autoComplete="off">
                <div className="theme-container-admin min-h">
                    <CompanyNav changepassword="active"></CompanyNav>
                    <div className="row">
                        <div className="col-md-6 mx-auto detail-box form-sec">
                            <div className="row m-0 align-items-center">
                                <div className="col-md-12">
                                    <div className="label">Current Password<span className="required">*</span></div>
                                    <div className="password-input">
                                        <input
                                            autoComplete="new-password"
                                            type={passwordShown ? "text" : "password"}
                                            id="currentPassword"
                                            className={border.currentPassword ? border.currentPassword : "xu-input"}
                                            placeholder="Current Password"
                                            value={model.currentPassword}
                                            onChange={(e) => ChangeHandler(e)}
                                        ></input>
                                        {passwordShown ?
                                            <img src="./images/close-eye.svg" className="icon close" onClick={(e) => togglePassword(e)} />
                                            :
                                            <img src="./images/open-eye.svg" className="icon" onClick={(e) => togglePassword(e)} />
                                        }
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="label">New Password<span className="required">*</span></div>
                                    <div className="password-input">
                                        <input
                                            autoComplete="new-password"
                                            type={passwordShown2 ? "text" : "password"}
                                            id="password"
                                            className={border.password ? border.password : "xu-input"}
                                            placeholder="New Password"
                                            value={model.password}
                                            onChange={(e) => ChangeHandler(e)}
                                        ></input>
                                        {passwordShown2 ?
                                            <img src="./images/close-eye.svg" className="icon close" onClick={(e) => togglePassword2(e)} />
                                            :
                                            <img src="./images/open-eye.svg" className="icon" onClick={(e) => togglePassword2(e)} />
                                        }
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="label">Confirm Password<span className="required">*</span></div>
                                    <div className="password-input">
                                        <input
                                            autoComplete="new-password"
                                            type={passwordShown3 ? "text" : "password"}
                                            id="confirmPassword"
                                            className={border.confirmPassword ? border.confirmPassword : "xu-input"}
                                            placeholder="Confirm Password"
                                            value={model.confirmPassword}
                                            onChange={(e) => ChangeHandler(e)}
                                        ></input>
                                        {passwordShown3 ?
                                            <img src="./images/close-eye.svg" className="icon close" onClick={(e) => togglePassword3(e)} />
                                            :
                                            <img src="./images/open-eye.svg" className="icon" onClick={(e) => togglePassword3(e)} />
                                        }
                                    </div>
                                </div>
                                <div className="col-md-12 spacing">
                                    <button
                                        className="btn btn-outline a-btn mt-0 float-right"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>{" "}
                        </div>{" "}
                    </div>
                </div>
            </form>
            {loader}
        </>
    );
}
export default ChnagePassword