import Common from "../../Utility/Common";
const newDate = new Date();
const CompanyNewsModel = () => ({
        id: null,
        companyId: Common.LoginId(),
        title: '',
        description: '',
        image: '',
        fromDate: newDate,
        toDate: newDate,
        caption: '',
        location: '',
        active: true,
        userId: Common.UserId()


})
export default CompanyNewsModel;