import React, { useEffect, useState } from 'react'
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./QuillToolbar";
import "react-quill/dist/quill.snow.css";
import "../../../style.css";

function Texteditor(props) {
  const [state, setState] = useState({ value: "" });
  useEffect(() => {
    var values = props.data
    setState({ value: values })

    {/* <div dangerouslySetInnerHTML={{ __html: state.value }}></div> */ }

  }, [props.data])
  // if(props.data){
  //   var values = props.data
  //   setState({ value: values })
  // }
  const handleChange = value => {
    setState({ value });

  };
  return (
    <div className="text-editor" >
      {/* <div dangerouslySetInnerHTML={{ __html: state.value }}></div> */}
      <EditorToolbar />
      {props.data ? <ReactQuill
        theme="snow"
        value={state.value}
        onChange={handleChange}
        placeholder={"Write something awesome..."}
        modules={modules}
        formats={formats}
      /> : ""}
    </div>
  )
}

export default Texteditor