export class ServiceAPI {
     static version = 'v1';
     static registrationApi = 'Account/register';
     static refreshTokenApi = + 'Account/refresh';
     static loginApi = 'Account/authenticate';
     static getusersApi = 'Account/getusers';
     static updateusersApi = 'Account/User-Activation';
     static confirmEmailApi = 'Account/confirm-email';
     static confirmAccountApi = 'Account/confim-account';
     static changePasswordApi = 'Account/change-password';
     static resetPasswordApi = 'Account/reset-password';
     static forgotPasswordApi = 'Account/forgot-password';
     static UserExcelApi = this.version + '/ExcelSheet';
     static GetInTouchApi = this.version + '/GetInTouch';

     static NotificationCountApi = "Account/Counter"
     static updateNotificationApi = "Account/UserNotification"
     static GetAllNotificationApi = "Account/GetAllNotification"
     static GetLanguageApi = this.version + "/Student/Languages"
     static ImageUplaodApi = `${this.version}` + '/upload';
     static EncodedImageUplaodApi = `${this.version}` + '/upload/EncodedImage';

     static JobAndIntershipFilterApi = this.version + '/CompanyJob/GetJobsAndInternshiplist';

     static bannerGetAllApi = this.version + '/AdminBanner/GetAll';
     static bannerCreateApi = `${this.version}` + '/AdminBanner';
     static bannerGetApi = `${this.version}` + '/AdminBanner/GetById';
     static bannerUpdateApi = `${this.version}` + '/AdminBanner';
     static bannerDeleteApi = `${this.version}` + '/AdminBanner';


     static IndustryGetAllApi = this.version + '/Industry/GetAll';
     static IndustryCreateApi = `${this.version}` + '/Industry';
     static IndustryGetApi = `${this.version}` + '/Industry/GetById';
     static IndustryUpdateApi = `${this.version}` + '/Industry';
     static IndustryDeleteApi = `${this.version}` + '/Industry';


     static QualificationGetAllApi = this.version + '/Qualification/GetAll';
     static QualificationCreateApi = `${this.version}` + '/Qualification';
     static QualificationGetApi = `${this.version}` + '/Qualification/GetById';
     static QualificationUpdateApi = `${this.version}` + '/Qualification';
     static QualificationDeleteApi = `${this.version}` + '/Qualification';


     static AttributeGetAllApi = this.version + '/Attribute/GetAll';
     static AttributeCreateApi = `${this.version}` + '/Attribute';
     static AttributeGetApi = `${this.version}` + '/Attribute/GetById';
     static AttributeUpdateApi = `${this.version}` + '/Attribute';
     static AttributeDeleteApi = `${this.version}` + '/Attribute';

     static GetAllStaticDataApi = this.version + '/StaticPage/GetAll';
     static GetStaticDataByIDApi = this.version + '/StaticData/GetById';
     static GetStaticDataUpdateApi = this.version + '/StaticPage';
     static AdminAdvertisementGetAllApi = this.version + '/AdminAdvertisement/GetAll';
     static AdminAdvertisementCreateApi = `${this.version}` + '/AdminAdvertisement';
     static AdminAdvertisementGetApi = `${this.version}` + '/AdminAdvertisement/GetById';
     static AdminAdvertisementUpdateApi = `${this.version}` + '/AdminAdvertisement';
     static AdminAdvertisementDeleteApi = `${this.version}` + '/AdminAdvertisement';

     static CompanyContactGetAllApi = this.version + '/CompanyContactMail';
     static CollegeContactGetAllApi = this.version + '/CollegeContactMail';

     static CourseGetAllApi = this.version + '/Course/GetAll';
     static CourseCreateApi = `${this.version}` + '/Course';
     static CourseGetApi = `${this.version}` + '/Course/GetById';
     static CourseUpdateApi = `${this.version}` + '/Course';
     static CourseDeleteApi = `${this.version}` + '/Course';
     static CourseByIndustryApi = `${this.version}` + '/Course/GetCourseByIndustry';

     static ToolKitGetAllApi = this.version + '/ToolKit/GetAll';
     static ToolKitCreateApi = `${this.version}` + '/ToolKit';
     static ToolKitGetApi = `${this.version}` + '/ToolKit/GetById';
     static ToolKitUpdateApi = `${this.version}` + '/ToolKit';
     static ToolKitDeleteApi = `${this.version}` + '/ToolKit';

     static SkillSetGetAllApi = this.version + '/SkillSet/GetAll';
     static SkillSetCreateApi = `${this.version}` + '/SkillSet';
     static SkillSetGetApi = `${this.version}` + '/SkillSet/GetById';
     static SkillSetUpdateApi = `${this.version}` + '/SkillSet';
     static SkillSetDeleteApi = `${this.version}` + '/SkillSet';

     static InvitationCreateApi = `${this.version}` + '/Invitation';

     static AdminUserDeleteApi = `${this.version}` + '/AdminUser';
     static AdminUserGetApi = `${this.version}` + '/AdminUser/GetById';
     static AdminUserUpdateApi = `${this.version}` + '/AdminUser';


     static GetAllFeedApi = `${this.version}` + '/Feed/GetFeed';
     static collegeEventResisterApi = `${this.version}` + '/CollegeEventRegister';
     static companyEventResisterApi = `${this.version}` + '/CompanyEventRegister';

     //--------------------------------College----------------------------
     static CollegeFilterGetSuggestionApi = `${this.version}` + '/CollegeFilter/GetSuggestion';
     static CollegeFilterApi = `${this.version}` + '/CollegeFilter';
     static DeleteEventRegistrationApi = `${this.version}` + '/CollegeEvent/DeleteEventRegistration';
     static GetCollegeEventRegistrationListApi = `${this.version}` + '/CollegeEvent/GetAllEventRegistration';
     static CollegeFilterGetAllApi = `${this.version}` + '/College/GetFilterData';

     static CollegeBannerGetAllApi = `${this.version}` + '/CollegeBanner/GetAll';
     static CollegeBannerCreateApi = `${this.version}` + '/CollegeBanner';
     static CollegeBannerGetApi = `${this.version}` + '/CollegeBanner/GetById';
     static CollegeBannerUpdateApi = `${this.version}` + '/CollegeBanner';
     static CollegeBannerDeleteApi = `${this.version}` + '/CollegeBanner';

     static CollegeGetAllApi = `${this.version}` + '/College/getall';
     static CollegeCreateApi = `${this.version}` + '/College';
     static CollegeGetApi = `${this.version}` + '/College/GetById';
     static CollegeUpdateApi = `${this.version}` + '/College';
     static CollegeDeleteApi = `${this.version}` + '/College';

     static CollegeCoursGetAllbyIndustryApi = `${this.version}` + `/CollegeCourse/GetAllbyIndustry`;
     static CollegeCourseGetAllApi = `${this.version}` + '/CollegeCourse/GetAll';
     static CollegeCourseCreateApi = `${this.version}` + '/CollegeCourse';
     static CollegeCourseGetApi = `${this.version}` + '/CollegeCourse/GetById';
     static CollegeCourseUpdateApi = `${this.version}` + '/CollegeCourse';
     static CollegeCourseDeleteApi = `${this.version}` + '/CollegeCourse';

     static CollegeEventGetAllApi = `${this.version}` + '/CollegeEvent/GetAll';
     static CollegeEventCreateApi = `${this.version}` + '/CollegeEvent';
     static CollegeEventGetApi = `${this.version}` + '/CollegeEvent/GetById';
     static CollegeEventUpdateApi = `${this.version}` + '/CollegeEvent';
     static CollegeEventDeleteApi = `${this.version}` + '/CollegeEvent';

     static CollegeNewsGetAllApi = `${this.version}` + '/CollegeNews/GetAll';
     static CollegeNewsCreateApi = `${this.version}` + '/CollegeNews';
     static CollegeNewsGetApi = `${this.version}` + '/CollegeNews/GetById';
     static CollegeNewsUpdateApi = `${this.version}` + '/CollegeNews';
     static CollegeNewsDeleteApi = `${this.version}` + '/CollegeNews';

     static CollegeLocationGetAllApi = `${this.version}` + '/CollegeLocation/GetByCollegeId';
     static CollegeLocationCreateApi = `${this.version}` + '/CollegeLocation';
     static CollegeLocationGetApi = `${this.version}` + '/CollegeLocation/GetById';
     static CollegeLocationUpdateApi = `${this.version}` + '/CollegeLocation';
     static CollegeLocationDeleteApi = `${this.version}` + '/CollegeLocation';

     static CollegeGetAboutUs = `${this.version}` + '/CollegeAboutUs/GetByCollegeId';
     static CollegeAboutUsCreateApi = `${this.version}` + '/CollegeAboutUs';
     static CollegeAboutUsGetApi = `${this.version}` + '/CollegeAboutUs/GetById';
     static CollegeAboutUsUpdateApi = `${this.version}` + '/CollegeAboutUs';
     static CollegeAboutUsDeleteApi = `${this.version}` + '/CollegeAboutUs';
     //--------------------------------CollegeShortList----------------------------
     static CollegeShortlistRemoveApi = `${this.version}` + '/College/RemoveShortlist';
     static CollegeShortListDeleteApi = `${this.version}` + '/CollegeShortList';
     static CollegeShortListPostApi = `${this.version}` + '/CollegeShortList';
     static CollegeCategoryGetAllApi = `${this.version}` + '/CollegeCategory/GetAll';
     static CollegeShortListGetAllApi = `${this.version}` + '/CollegeShortList/GetAll';
     static CollegeShortListPutApi = `${this.version}` + '/CollegeShortList';

     //--------------------------------CompanyShortList-----------------------------
     static CompanyshortlistResetApi = `${this.version}` + '/CompanyShortList/Reset';
     static CompanyJobCategoryApi = `${this.version}` + '/JobCategory/GetAll';
     static CompanyShortListremoveApi = `${this.version}` + '/Student/RemoveShortlist';
     static CompanyShortlistRemoveApi = `${this.version}` + '/Company/RemoveShortlist';
     static CompanyShortListDeleteApi = `${this.version}` + '/CompanyShortList';
     static CompanyShortListPostApi = `${this.version}` + '/CompanyShortList';
     static CompanyShortListPutApi = `${this.version}` + '/CompanyShortList';
     static CompanyShortListGetAllApi = `${this.version}` + '/CompanyShortList/GetAll';
     //--------------------------------CompanycheckFit-----------------------------
     static CompanyCheckfitGetAllApi = `${this.version}` + '/CompanyCheckfit/GetAll';
     static CompanyCheckfitCreateApi = `${this.version}` + '/Checkfit';
     static CompanyCheckfitGetApi = `${this.version}` + '/Checkfit/GetById';
     static CompanyCheckfitUpdateApi = `${this.version}` + '/Checkfit';
     static CompanyCheckfitDeleteApi = `${this.version}` + '/Checkfit';

     static CollegeCategoryGetAllApi = `${this.version}` + '/CollegeCategory/GetAll';
     static CollegeCategoryCreateApi = `${this.version}` + '/CollegeCategory';
     static CollegeCategoryGetApi = `${this.version}` + '/CollegeCategory/GetById';
     static CollegeCategoryUpdateApi = `${this.version}` + '/CollegeCategory';
     static CollegeCategoryDeleteApi = `${this.version}` + '/CollegeCategory';

     //--------------------------------Company----------------------------
     static CompanyFilterGetSuggestionApi = `${this.version}` + '/CompanyFilter/GetSuggestion';
     static DeleteCompanyEventRegistrationApi = `${this.version}` + '/CompanyEvent/DeleteCompanyRegistration'
     static GetCompanyDeleteAppliedJobsApi = `${this.version}` + '/Company/DeleteStudentJobApply'
     static GetCompanyAppliedJobsListApi = `${this.version}` + '/Company/GetAllStudentJobApply'
     static GetCompanyEventRegistrationListApi = `${this.version}` + '/CompanyEvent/GetAllCompanyRegistration'
     static CompanyFilterGetAllApi = `${this.version}` + '/Company/GetFilterData';
     static CompanyFilterApi = `${this.version}` + '/CompanyFilter';

     static CompanyBannerGetAllApi = `${this.version}` + '/CompanyBanner/GetAll';
     static CompanyBannerCreateApi = `${this.version}` + '/CompanyBanner';
     static CompanyBannerGetApi = `${this.version}` + '/CompanyBanner/GetById';
     static CompanyBannerUpdateApi = `${this.version}` + '/CompanyBanner';
     static CompanyBannerDeleteApi = `${this.version}` + '/CompanyBanner';

     static CompanyGetAllApi = `${this.version}` + '/Company/getall';
     static CompanyCreateApi = `${this.version}` + '/Company';
     static CompanyGetApi = `${this.version}` + '/Company/GetById';
     static CompanyUpdateApi = `${this.version}` + '/Company';
     static CompanyDeleteApi = `${this.version}` + '/Company';

     static CompanyEventGetAllApi = `${this.version}` + '/CompanyEvent/GetAll';
     static CompanyEventCreateApi = `${this.version}` + '/CompanyEvent';
     static CompanyEventGetApi = `${this.version}` + '/CompanyEvent/GetById';
     static CompanyEventUpdateApi = `${this.version}` + '/CompanyEvent';
     static CompanyEventDeleteApi = `${this.version}` + '/CompanyEvent';

     static CompanyNewsGetAllApi = `${this.version}` + '/CollegeNews/GetAll';

     static CompanyLocationGetAllApi = `${this.version}` + '/CompanyLocation/GetAll';
     static CompanyLocationCreateApi = `${this.version}` + '/CompanyLocation';
     static CompanyLocationGetApi = `${this.version}` + '/CompanyLocation/GetById';
     static CompanyLocationUpdateApi = `${this.version}` + '/CompanyLocation';
     static CompanyLocationDeleteApi = `${this.version}` + '/CompanyLocation';

     static CompanyMediaGetAllApi = `${this.version}` + '/CompanyMedia/GetAll';
     static CompanyMediaCreateApi = `${this.version}` + '/CompanyMedia';
     static CompanyMediaGetApi = `${this.version}` + '/CompanyMedia/GetById';
     static CompanyMediaUpdateApi = `${this.version}` + '/CompanyMedia';
     static CompanyMediaDeleteApi = `${this.version}` + '/CompanyMedia';

     static CompanyJobsGetAllApi = `${this.version}` + '/CompanyJob/GetAllJobs';
     static CompanyJobGetAllApi = `${this.version}` + '/CompanyJob/GetAllCompanyJob';
     static CompanyJobCreateApi = `${this.version}` + '/CompanyJob';
     static CompanyJobGetApi = `${this.version}` + '/CompanyJob/GetById';
     static CompanyJobUpdateApi = `${this.version}` + '/CompanyJob';
     static CompanyJobDeleteApi = `${this.version}` + '/CompanyJob';

     static CompanyGetAboutUs = `${this.version}` + '/CompanyAboutUs/GetByCompanyId';
     static CompanyAboutUsCreateApi = `${this.version}` + '/CompanyAboutUs';
     static CompanyAboutUsGetApi = `${this.version}` + '/CompanyAboutUs/GetById';
     static CompanyAboutUsUpdateApi = `${this.version}` + '/CompanyAboutUs';
     static CompanyAboutUsDeleteApi = `${this.version}` + '/CompanyAboutUs';

     static CompanyCheckfitGetAllApi = `${this.version}` + '/CompanyCheckfit/GetAll';
     static CompanyCheckfitCreateApi = `${this.version}` + '/CompanyCheckfit';
     static CompanyCheckfitGetApi = `${this.version}` + '/CompanyCheckfit/GetById';
     static CompanyCheckfitUpdateApi = `${this.version}` + '/CompanyCheckfit';
     static CompanyCheckfitDeleteApi = `${this.version}` + '/CompanyCheckfit';
     //----------------------------The-Feed--------------------------------
     static GetAllFeedApi = `${this.version}` + '/Feed/GetFeed';
     static GetGlobalSuggestionApi = `${this.version}` + '/Feed/GetSuggestion';
     //  -------------------------------------studyType---------------------
     static StudyTypeGetAllApi = `${this.version}` + '/StudyType/GetAll';
     static StudyVideoUploadApi = `${this.version}` + '/upload/VideoUpload';
     //--------------------------------StudentShortList-----------------------------

     static StudentShortlistRemoveApi = `${this.version}` + '/Student/RemoveShortlist';
     static StudentCheckFitApi = `${this.version}` + '/Student/StudentCheckFit';
     static StudentShortListDeleteApi = `${this.version}` + '/StudentShortList';
     static StudentGetResumeApi = `${this.version}` + '/StudentResumePdf';
     static StudentShortListPostApi = `${this.version}` + '/StudentShortList';
     static StudentShortListGetAllApi = `${this.version}` + '/StudentShortList/GetAll';

     //--------------------------Student-----------------------------------
     static StudentFilterGetSuggestionApi = `${this.version}` + '/StudentFilter/GetSuggestion';
     static StudentGetAppliedJobsApi = `${this.version}` + '/Student/GetAppliedJobs';
     static StudentFilterApi = `${this.version}` + '/StudentFilter';
     static StudentFilterGetAllApi = `${this.version}` + '/Student/GetFilterData';

     static StudentAchievementGetAllApi = `${this.version}` + '/StudentAchievement/GetAll';
     static StudentAchievementCreateApi = `${this.version}` + '/StudentAchievement';
     static StudentAchievementGetApi = `${this.version}` + '/StudentAchievement/GetById';
     static StudentAchievementUpdateApi = `${this.version}` + '/StudentAchievement';
     static StudentAchievementDeleteApi = `${this.version}` + '/StudentAchievement';


     static StudentBannerGetAllApi = `${this.version}` + '/StudentBanner/GetAll';
     static StudentBannerCreateApi = `${this.version}` + '/StudentBanner';
     static StudentBannerGetApi = `${this.version}` + '/StudentBanner/GetById';
     static StudentBannerUpdateApi = `${this.version}` + '/StudentBanner';
     static StudentBannerDeleteApi = `${this.version}` + '/StudentBanner';


     static StudentEducationGetAllApi = `${this.version}` + '/StudentEducation/GetAll';
     static StudentEducationCreateApi = `${this.version}` + '/StudentEducation';
     static StudentEducationGetApi = `${this.version}` + '/StudentEducation/GetById';
     static StudentEducationUpdateApi = `${this.version}` + '/StudentEducation';
     static StudentEducationDeleteApi = `${this.version}` + '/StudentEducation';

     static StudentApplyJobApi = `${this.version}` + '/Student/ApplyJob';
     static StudentJobHistoryGetAllApi = `${this.version}` + '/StudentJobHistory/GetAll';
     static StudentJobHistoryCreateApi = `${this.version}` + '/StudentJobHistory';
     static StudentJobHistoryGetApi = `${this.version}` + '/StudentJobHistory/GetById';
     static StudentJobHistoryUpdateApi = `${this.version}` + '/StudentJobHistory';
     static StudentJobHistoryDeleteApi = `${this.version}` + '/StudentJobHistory';


     static StudentGetAllApi = `${this.version}` + '/Student/getall';
     static StudentCreateApi = `${this.version}` + '/Student';
     static StudentGetApi = `${this.version}` + '/Student/GetById';
     static StudentUpdateApi = `${this.version}` + '/Student';
     static StudentDeleteApi = `${this.version}` + '/Student';

     static StudentPortFolioGetAllApi = `${this.version}` + '/StudentPortFolio/GetAll';
     static StudentPortFolioCreateApi = `${this.version}` + '/StudentPortFolio';
     static StudentPortFolioGetApi = `${this.version}` + '/StudentPortFolio/GetById';
     static StudentPortFolioUpdateApi = `${this.version}` + '/StudentPortFolio';
     static StudentPortFolioDeleteApi = `${this.version}` + '/StudentPortFolio';

     static QualificationGetAllApi = this.version + '/Qualification/GetAll';
     static QualificationCreateApi = `${this.version}` + '/Qualification';
     static QualificationGetApi = `${this.version}` + '/Qualification/GetById';
     static QualificationUpdateApi = `${this.version}` + '/Qualification';
     static QualificationDeleteApi = `${this.version}` + '/Qualification';
     // ------------------------------------location-------------------------------
     static getLocationCountryApi = `${this.version}` + '/Location/GetCountry';
     static getLocationStateApi = `${this.version}` + '/Location/GetState(country)';
     static getLocationCityApi = `${this.version}` + '/Location/GetCity(state)';

}
export default ServiceAPI