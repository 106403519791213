import React, { useState, useEffect, useMemo } from "react";
import service from "../../Services/AdminUtilityService";
import ServiceAPI from "../../Utility/ServiceList";
import TableView from "../../Utility/TableView";
import AdminNav from "./AdminNav";
import AdminHeader from './AdminHeader';
import TableList from "../../Utility/TableList"
import { ImCross } from "react-icons/im";
import useLoader from '../Auth/Loader';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
import $ from 'jquery'
let value = 1;
const ToolKitList = () => {
  const history = useHistory();
  var notifiy = false
  const [itemlist, setItemList] = useState([]);
  const [model, setModel] = useState();
  const [loader, showLoader, hideLoader] = useLoader();
  const [hasMore, setHasMore] = useState(true);
  const [pageModel, setPageModel] = useState({
    "pageNum": 1,
    "pageSize": 10,
    "isActive": false
  });
  var services = new service();
  const data = itemlist;
  const columns = useMemo(
    () => [
      {
        Header: 'Image',
        accessor: 'image',
        Cell: ({ row }) => {
          const url = data.map((item, index) => item.image);
          return (
            <>
              {url[row.index] ? <div data-toggle="modal" data-target={`${"#exampleModal"}${row.index}`}>
                <img
                  src={url[row.index]}
                  width={60}
                  alt='Avatar'
                  className="rounded-circle toolkit-img"
                />

              </div> : <span className="img" >
                {/* <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/loader.gif`} className="ad-img img-fluid" /> */}
                <div className="admin-img-preview  " style={{ width: "25%" }}>
                  <div className="lds-roller sm"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
              </span>}
              <div className="modal fade admin-modal"
                id={`${"exampleModal"}${row.index}`}
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true">

                <div className="modal-dialog modal-lg modal-dialog-centered toolkit-modal">

                  <div className="modal-content">
                    <button type="button"
                      className="modalclose "
                      data-dismiss="modal"
                      aria-label="Close">
                      <span aria-hidden="true"> <ImCross /> </span>
                    </button>
                    <img src={url[row.index]} className="modal-img toolkit-img" />
                  </div>
                </div>
              </div>
            </>
          )
        }
      }, {
        Header: 'Name',
        accessor: 'name',
      }, {
        Header: "Status",
        accessor: "active",
        Cell: ({ row }) => {
          const val = data.map((item, index) => item.active);
          return (
            <label className="switch">
              <input
                id="active"
                checked={val[row.index]}
                type="checkbox"
                onChange={(e) => ChangeHandler(e, row.index)}
              />
              <span className="slider round"></span>
            </label>
          )
        },
      }, {
        Header: "Edit",
        accessor: "edit",
        Cell: ({ row }) => {
          const itemID = data.map((item, index) => item.id);
          return (
            <label>
              <a
                onClick={() => editfunction(itemID[row.index])}
                className="fa fa-pencil edit"
                aria-hidden="true"
              />
            </label>
          );
        },
      }, {
        Header: "Delete",
        accessor: "delete",
        Cell: ({ row }) => {
          const itemID = data.map((item, index) => item.id);
          return (

            <label>
              <a
                onClick={() => Delete(itemID[row.index], row.index)}
                className="fa fa-trash delete"
                aria-hidden="true"
              />
            </label>

          );
        },
      }

    ], [data])

  const ChangeHandler = async (e, index) => {
    let newArr = itemlist.map((item, i) => {
      if (index == i) {
        return { ...item, active: e.target.checked };
      } else {
        return item;
      }
    });
    setItemList(newArr)
    var response = null;
    response = await services.Update(ServiceAPI.ToolKitUpdateApi, newArr[index]);
  };

  useEffect(() => {
    GetAll();
  }, []);

  useEffect(() => {
  }, [pageModel]);

  async function GetAll() {
    showLoader()
    var res = await services.PostGetAll(ServiceAPI.ToolKitGetAllApi, pageModel);
    hideLoader()
    const model = await res.metaData;
    setModel(model.totalCount);
    const data = await res.result;

    setItemList([...itemlist, ...data]);
    setPageModel({
      "pageNum": pageModel.pageNum + 1,
      "pageSize": 10,
      "isActive": false
    })
  }
  const editfunction = (id) => {
    history.push("/toolkit?id=" + id);
  };

  const Delete = (id, index) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="xy-form">
            <div className="mdl-heading">Delete Record</div>
            <div className="desc">Are you sure to delete it?</div>
            <button className="btn btn-outline s-btn pr" onClick={onClose}>No</button>
            <button className="btn btn-outline s-btn pr"
              onClick={async () => {
                let item = itemlist.filter((data) => data.id != id);
                await services.Delete(ServiceAPI.ToolKitDeleteApi, id).then(async (response) => {
                  if (response.message == "Success") {
                    var res = await services.PostGetAll(ServiceAPI.ToolKitGetAllApi, {
                      "pageNum": parseInt((item.length / 10) + 1),
                      "pageSize": 10,
                      "isActive": false
                    });
                    const model = await res.metaData;
                    // setModel(model.totalCount);
                    const data = await res.result;
                    if (model.totalPages > parseInt((index / 10) + 1)) {
                      if (itemlist.length === 10 * model.currentPage && data.length > 9) {
                        setItemList([...item, data[data.length - 1]]);
                      } else {
                        setItemList(item)
                      }
                    } else {
                      setItemList(item)
                    }
                  } else {
                    toast.error(response.errorMessage, { theme: "colored", autoClose: 3000, toastId: 'error3' })
                  }
                })
                onClose();
              }}
            >
              Yes
            </button>
          </div>
        );
      }
    });
  };

  const fetchMoreData = () => {
    if (model > itemlist.length) {
      GetAll()
    } else {
      setHasMore(false)
    }
  };
  const newdata = useMemo(() => itemlist, [itemlist]);
  const redirectlist = (link) => {
    history.push(link)
  }
  const showNotification = (type) => {
    notifiy = $(".notification-menu").hasClass("show")
    if (notifiy == true) {
      $(".notification-menu").removeClass("show")
      notifiy = false
    }



  }
  return (
    <>
      <AdminHeader heading="" subheading="Manage ToolKit" />
      <form>
        <div className="theme-container-admin min-h master-admin">
          <AdminNav toolkit="active"></AdminNav>

          <div className="table-sec" onClick={() => showNotification()}>
            <div className="add-option">
              <div className="title"></div>
              <a className="icon" onClick={() => redirectlist("/toolkit")}>
                <i className="fa fa-plus" aria-hidden="true"></i>
              </a>
            </div>
            <TableList
              data={newdata}
              columns={columns}
              update={fetchMoreData}
              hasMore={hasMore}
            />
          </div>
        </div>
      </form>
      {loader}
    </>
  );
};

export default ToolKitList;
