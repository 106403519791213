import Common from "../../Utility/Common";
const CollegeCourseModel = () => ({
        id: null,
        industryId: null,
        industry: '',
        courseId: '',
        course: '',
        description: '',
        type: '',
        studyTypeId: '',
        nfqlLevel: '',
        duration: '',
        url: '',
        studyTypeNames: '',
        collegeCourseName:'',
        imageUrl: null,
        active: true,
        userId: Common.UserId(),
        collegeId: Common.LoginId()
})

export default CollegeCourseModel;