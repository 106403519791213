import { Link } from 'react-router-dom'
import Common from '../../../Utility/Common'
import environment from '../../../environment'
const CompanyNav = (props) => {
   return (
      <>
         <div class="scroll-tabs cmp-nav" scroll="true">
            <ul className="nav nav-tabs admin-nav" id="pills-tab" role="tablist">

               <li className="active">
                  <Link className={"nav-link " + props.profile} to={`/companyprofile?id=${Common.LoginId()}`}>Profile</Link>
               </li>
               <li className="">
                  <Link className={"nav-link " + props.banner} to="/companybannerlist" >Banner</Link>
               </li>
               <li className="">
                  <Link className={"nav-link " + props.aboutus} to={"/companyaboutus"} >About Us</Link>
               </li>
               <li className="">
                  <Link className={"nav-link " + props.event} to="/companyeventlist" >Events</Link>
               </li>
               <li className="">
                  <Link className={"nav-link " + props.media} to="/companymedialist" >Media</Link>
               </li>
               <li className="">
                  <Link className={"nav-link " + props.location} to="/companylocationlist" >Locations</Link>
               </li>
               <li className="">
                  <Link className={"nav-link " + props.job} to="/companyjoblist" >Jobs & Internships</Link>
               </li>

               <li className="">
                  <Link className={"nav-link " + props.changepassword} to="/companychangepassword" >Change Password</Link>
               </li>
               {environment.isServer == "true" ? <>
                  <li className="">
                     <Link className={"nav-link " + props.Checkfit} to="/companycheckfitlist" >Checkfit Preferences</Link>
                  </li> </> : ""}


            </ul>
         </div>

      </>

   )

}

export default CompanyNav