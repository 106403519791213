import React from 'react'
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css"
const ImageGallaries = (props) => {
    const Images = [];

    if (props.images.length !== 0) {
        Images.push(...props.images.map(item => ({
            original: `${item.imageUrl}`,
            thumbnail: `${item.imageUrl}`
        })));
    }
    return (
        <div >
            < ImageGallery items={Images} showFullscreenButton={false} additionalClass={"image-container"} showPlayButton={false} showThumbnails={false} />
        </div>
    )
}
export default ImageGallaries;