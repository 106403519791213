import React, { useState } from 'react'
import service from "../../Services/UtilityService";
import ServiceAPI from "../../Utility/ServiceList";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

toast.configure()

function ForgotPassword() {
    const services = new service();
    const [model, setModel] = useState([]);
    const [error, setError] = useState("");

    const ChangeHandler = (e) => {
        let value = {};
        value[e.target.id] = e.target.value;
        setModel({ ...model, ...value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        var response = null;
        if (valid()) {
            response = await services.Create(ServiceAPI.forgotPasswordApi, model);
            if (response && response.message === "Success") {
                toast.success('Email Sent Successfully', { theme: "colored", autoClose: 4000, toastId: 'success1' })
                setModel();
            }
        }
    };
    const valid = () => {
        function isValidEmail(s) {
            return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(s);
        }
        let emailAddress = "";

        if (!isValidEmail(model.email)) {

            toast.error("Valid Email Address required", { theme: "colored", toastId: 'error1', });
            emailAddress = "Enter Valid Email Address";
        }
        if (emailAddress.length !== 0) {

            setError({ emailAddress });
            return false;
        }
        return true;
    };
    const back = () => {
        window.location = "/login";
    }
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="section bg-sec login-h theme-container-admin">
                    <div className='container-fluid login-sec'>
                        <div className='sign-box'>

                            <div className="col-md-6">

                                <div className='bg-lines'>
                                    <div className='login-box'>
                                        <img src='./images/wrong-password.png' className='img-fluid f-pass'></img>
                                        <div class="sign-heading">Forgot Password</div>
                                        <div className="col-md-12">
                                            <div className="label float-left">
                                                Email
                                            </div>
                                            <input
                                                type="text"
                                                id="email"
                                                className="xu-input"
                                                placeholder="Email"
                                                onChange={(e) => ChangeHandler(e)}
                                            ></input>
                                            <button
                                                type="submit"
                                                className="btn sign-btn btn-outline big-btn"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>


                    <div className='back-icon' onClick={back}></div>
                </div>
            </form>
        </>
    )
}

export default ForgotPassword
