import React, { useState, useEffect, useMemo } from "react";
import service from "../../../Services/UtilityService";
import ServiceAPI from "../../../Utility/ServiceList";
import TableView from "../../../Utility/TableView";
import AdminNav from "./StudentNav";
import AdminHeader from '../../Common/AdminHeader';
import TableList from "../../../Utility/TableList";
import moment from "moment";
import useLoader from "../../Auth/Loader"
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from "react-toastify";
import $ from 'jquery'
const StudentAppliedJobsList = (props) => {
    const id = props.location.search.split("=")[1]
    localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
    const [itemlist, setItemList] = useState([]);
    const [model, setModel] = useState();
    const [hasMore, setHasMore] = useState(true);
    const [show, setShow] = useState(false);
    const [loader, showLoader, hideLoader] = useLoader();
    const [pageModel, setPageModel] = useState({
        "pageNum": 1,
        "pageSize": 10,
        "isActive": true,
        "id": id
    });
    var services = new service();

    const data = itemlist;
    const columns = useMemo(
        () => [
            {
                Header: 'Job Title',
                accessor: d => d.companyJobNavigation.title,
            },
            {
                Header: 'Company Name',
                accessor: d => d.companyJobNavigation.company.name,
            },
            {
                Header: 'Applied Date',
                accessor: d => moment(d.createdDate).format("DD-MMM-YYYY"),
            },
            {
                Header: 'Description',
                accessor: d => d.companyJobNavigation.description,
                Cell: ({ row }) => {
                    const itemID = data.map((item, index) => item.companyJobNavigation.description);
                    console.log(itemID[row.index], "des")
                    return (<>
                        {itemID[row.index].length >= 50 ? <div id={`description${row.index}`}>{itemID[row.index].slice(0, 50)} </div> : <div id={`description${row.index}`}>{itemID[row.index]}</div>}
                        {itemID[row.index].length >= 50 ? <a className={`readmore-btn read${row.index} show`} onClick={() => showData(itemID[row.index], `description${row.index}`, row.index)}>Readmore</a> : ""}
                        {<a className={`readmore-btn less${row.index} hide`} onClick={() => showLess(itemID[row.index], `description${row.index}`, row.index)}>showless</a>}
                    </>
                    );
                }
            },

            {
                Header: 'Email',
                accessor: d => d.companyJobNavigation.company.email,
            },

        ], [data])
    const showLess = (data, id, index) => {
        $(`.read${index}`).addClass("show")
        $(`.read${index}`).removeClass("hide")
        $(`.less${index}`).addClass("hide")
        setShow(!show)
        document.getElementById(`${id}`).innerHTML = data.slice(0, 50);
    }
    const showData = (data, id, index) => {
        $(`.read${index}`).addClass("hide")
        $(`.less${index}`).addClass("show")
        $(`.less${index}`).removeClass("hide")
        setShow(!show)
        document.getElementById(`${id}`).innerHTML = data;
    }

    const fetchMoreData = () => {
        if (model > itemlist.length) {
            GetAll()
        } else {
            setHasMore(false)
        }
    };
    async function GetAll() {
        $('.modal-backdrop').removeClass('show ');
        $('.modal').removeClass('show');
        $('body').toggleClass('p-0');
        $('body').removeClass('modal-open');
        showLoader()
        var res = await services.Create(ServiceAPI.StudentGetAppliedJobsApi, pageModel);
        hideLoader()
        const model = await res.metaData;
        setModel(model.totalCount);
        const data = await res.result;
        setItemList([...itemlist, ...data]);
        setPageModel({
            "pageNum": pageModel.pageNum + 1,
            "pageSize": 10,
            "isActive": true,
            "id": id
        })
    }
    useEffect(() => {
        GetAll();
    }, []);

    useEffect(() => {
    }, [pageModel]);

    const newdata = useMemo(() => itemlist, [itemlist]);
    return (
        <>
            <AdminHeader heading="" subheading="Applied Job" />
            <form>
                <div className="theme-container-admin ">
                    <AdminNav joblist="active"></AdminNav>
                    <div className="table-sec">
                        <div className="add-option">
                            <div className="title"></div>
                        </div>
                        <TableList
                            data={newdata}
                            columns={columns}
                            update={fetchMoreData}
                            hasMore={hasMore}
                            newClass={"events-mng-table"}
                        />
                    </div>
                </div>
            </form>
            {loader}
        </>
    )
}

export default StudentAppliedJobsList