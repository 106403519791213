import React, { useState, useEffect, useMemo } from "react";
import service from "../Services/UtilityService";
import ServiceAPI from "../Utility/ServiceList";
import ValidationService from "../Services/ValidationService";
import Common from "../Utility/Common";
import environment from "../environment";
import moment from "moment";
import { toast } from "react-toastify";
import useLoader from "./Auth/Loader"
import InfiniteScroll from "react-infinite-scroll-component";
import Multiselect from "./Auth/multiselect-dropdown"
import Select from 'react-select'
import $ from "jquery";
import { useHistory } from "react-router-dom";
localStorage.removeItem("userType")
var showFeedDatas = []
const Home = () => {

  let countValue = 0
  localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
  const history = useHistory();
  var services = new service();
  const [loader, showLoader, hideLoader] = useLoader();
  const [pageModel, setPageModel] = useState({
    "pageNum": 1,
    "pageSize": 10,
    "isServer": true
  });
  const [eventModel, setEventModel] = useState({
    "id": "00000000-0000-0000-0000-000000000000",
    "collegeEventId": "",
    "firstName": "",
    "lastName": "",
    "email": "",
    "contact": ""
  })
  const [companyEventModel, setCompanyEventModel] = useState({
    "id": "00000000-0000-0000-0000-000000000000",
    "companyEventId": "",
    "firstName": "",
    "lastName": "",
    "email": "",
    "contact": ""
  })
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(false)
  const [active, setActive] = useState(false)
  const [textvalue, setTextValue] = useState('')
  const [showtile, setShowTile] = useState(false);
  const [showtile1, setShowTile1] = useState({ newest: false, oldest: false });
  const [staticdata, SetStaticData] = useState();
  const [showFeedData, SetShowFeedData] = useState([]);
  const [industrylist, setIndustryList] = useState()
  const [show, setShow] = useState(true);
  const [advertisement, setAdvertisement] = useState([])
  const [count, setCount] = useState()
  const [TotalPage, setTotalPage] = useState(0)
  const [hasMore, setHasMore] = useState(true);
  const [coutnrylist, setCountryList] = useState([])
  const [statelist, setStateList] = useState([])
  const [showcount, setShowCount] = useState(false);
  const [active1, setActive1] = useState(false)
  const [active2, setActive2] = useState(false)
  const [citylist, setCityList] = useState([]);
  const [types, setTypes] = useState('all')
  const [filtermodel, setFilterModel] = useState(
    {
      "pagingParameter": {
        "pageNum": 1,
        "pageSize": 4
      },
      "feedFilterModel": {
        "industryIds": [],
        "courseIds": [],
        "locations": [],
        "country": "",
        "city": "",
        "state": "",
        "type": "all",
        "sortOrder": ""
      }
    }
  );
  useEffect(() => {
    $(".scroll-down").on("click", function () {
      var sectionId = '#' + $(this).attr('id').replace('lnk', '');
      if (window.matchMedia('(max-width: 768px)').matches) {
        $(window).scrollTop($(sectionId).position().top);
      }
      else {
        $(window).scrollTop($(sectionId).position().top + 20);
      }
    });
    GetFeed(filtermodel)
    window.scrollTo(0, 0);
    GetBanner();
    GetIndustry();
    getLocation();
    GetAdvertisement()
    textValue();
    GetAll()
  }, [])
  const textValue = (() => {
    setInterval(() => {
      getTextChange()
    }, 1000);
  })
  const getTextChange = (index) => {

    const months = ["Creatives", "Students", "Graduates", "Jobs", "Internships", "Colleges", "Brands", "Companies"];
    if (months.length === countValue) { countValue = 0 }
    const random = Math.floor(Math.random() * months.length);
    setTextValue(months[countValue])
    countValue = countValue + 1
  }
  const GetFeed = async (filtermodel, type) => {
    $('.modal-backdrop').removeClass('show ');
    $('.modal').removeClass('show');
    $('body').toggleClass('p-0');
    $('body').removeClass('modal-open');
    showLoader()
    setFilterModel({ ...filtermodel })
    if (type === "selectall") {
      showFeedDatas.splice(0, showFeedDatas.length)
    }
    var res = await services.Create(ServiceAPI.GetAllFeedApi, filtermodel);
    hideLoader()
    const data = await res.result
    const totalPage = res.metaData.totalCount
    setTotalPage(totalPage)
    if (res.message === "Success") {
      setLoading(false)
      if (data.length !== 0) {
        if (res.metaData.pageNum === 1) {
          showFeedDatas.splice(0, showFeedDatas.length)
          showFeedDatas = data
        }
        else if (filtermodel.feedFilterModel.type === "all" && type != "selectall") {
          showFeedDatas = [...showFeedDatas, ...data]
        } else if (filtermodel.pagingParameter.pageNum > 1 && filtermodel.feedFilterModel.type != "all") {
          showFeedDatas = [...showFeedDatas, ...data]
        }
      }
      else if (filtermodel.pagingParameter.pageNum > res.metaData.totalPages && totalPage === 0) {
        showFeedDatas.splice(0, showFeedDatas.length)
      }
    }
    setLoading(false)
  };
  const update = () => {
    if (TotalPage > showFeedDatas.length) {
      GetFeed({
        ...filtermodel, "pagingParameter": {
          ...filtermodel.pagingParameter, "pageNum": filtermodel.pagingParameter.pageNum + 1
        }
      })
    }
    else {
      setHasMore(false)
    }
  }
  const ApplyJobs = async (id) => {
    showLoader();
    var res = await services.Create(ServiceAPI.StudentApplyJobApi, {
      "id": "",
      "userId": `${Common.UserId()}`,
      "active": true,
      "studentJobApplyJobId": `${id}`,
      "studentJobApplyStudentId": `${Common.LoginId()}`
    });
    hideLoader();

    if (res && res.message === "Success") {
      $('.modal-backdrop').removeClass('show ');
      $('.modal').removeClass('show');
      $('body').toggleClass('p-0');
      $('body').removeClass('modal-open');
      toast.success('Applied Successfully', { theme: "colored", autoClose: 2000, toastId: 'success1' })
    }
  }
  async function GetAdvertisement() {
    showLoader()
    var res = await services.PostGetAll(ServiceAPI.AdminAdvertisementGetAllApi, pageModel);
    hideLoader()
    setAdvertisement(res.result)
  }
  const GetBanner = async () => {
    window.scrollTo(0, 0);
    showLoader()
    var res = await services.PostAnonymous(ServiceAPI.bannerGetAllApi, {
      "pageNum": 1,
      "pageSize": 100
    });
    hideLoader()
    const data = await res.result.filter((data) => data.active !== false);
    setBanner(data)
  };
  const GetIndustry = async () => {
    showLoader()
    var res = await services.PostAnonymous(ServiceAPI.IndustryGetAllApi, {
      "pageNum": 1,
      "pageSize": 100,
      "isActive": true
    });
    hideLoader()
    const industryData = await res.result.map((item) => {
      return {
        name: item.name,
        value: item.name,
        id: item.id
      }
    });
    setIndustryList(industryData)
  };
  const getLocation = async () => {
    var res = await services.GetAll(ServiceAPI.getLocationCountryApi);
    const data1 = await res.result.map((item) => {
      return { value: item, label: item, type: "country" }
    });
    setCountryList([{ value: 'select', label: 'select', type: "country" }, ...data1])
  }
  const GetState = async (state) => {
    var res = await services.GetLocationById(ServiceAPI.getLocationStateApi, state, "country");
    const data1 = await res.result.map((item) => {
      return { value: item, label: item, type: "state" }
    });
    setStateList([{ value: 'select', label: 'select', type: "state" }, ...data1])
  }
  const GetCity = async (city) => {
    var res = await services.GetLocationById(ServiceAPI.getLocationCityApi, city, "state");
    const data1 = await res.result.map((item) => {
      return { value: item, label: item, type: "city" }
    });
    setCityList([{ value: 'select', label: 'select', type: "city" }, ...data1])
  }

  const AddValue = async (e, type) => {
    if (e.type === "country") {
      const value = e.value
      if (value !== "select") {
        GetState(value)
        setHasMore(true)
        setFilterModel({
          ...filtermodel, "pagingParameter": {
            ...filtermodel.pagingParameter, "pageNum": 1
          }, "feedFilterModel": { ...filtermodel.feedFilterModel, "country": value, "type": types }
        })
        GetFeed({
          ...filtermodel, "pagingParameter": {
            ...filtermodel.pagingParameter, "pageNum": 1
          }, "feedFilterModel": { ...filtermodel.feedFilterModel, "country": value, "type": types }
        })
      }
      else {
        setFilterModel({ ...filtermodel, "feedFilterModel": { ...filtermodel.feedFilterModel, "country": '', "state": "", "city": '', "type": types } })
        setStateList([])
        setCityList([])
        GetFeed({
          ...filtermodel, "feedFilterModel": {
            ...filtermodel.feedFilterModel, "country": '', "state": '', "city": '', "type": types
          }
        })
      }
    }
    else if (e.type === "state") {
      const value = e.value
      if (value !== "select...") {
        GetCity(value);
        setHasMore(true)
        setFilterModel({
          ...filtermodel, "pagingParameter": {
            ...filtermodel.pagingParameter, "pageNum": 1
          }, "feedFilterModel": { ...filtermodel.feedFilterModel, "state": value, "type": types }
        })
        GetFeed({ ...filtermodel, "feedFilterModel": { ...filtermodel.feedFilterModel, "state": value, "type": types } })
      }
      else {
        setFilterModel({ ...filtermodel, "feedFilterModel": { ...filtermodel.feedFilterModel, "state": '', "type": types } })

        setCityList([])
        GetFeed({ ...filtermodel, "feedFilterModel": { ...filtermodel.feedFilterModel, "state": '', "city": '', "country": '', "type": types } })

      }
    }
    else if (e.type === "city") {
      const value = e.value
      if (value !== "select...") {
        setFilterModel({
          ...filtermodel, "pagingParameter": {
            ...filtermodel.pagingParameter, "pageNum": 1
          }, "feedFilterModel": { ...filtermodel.feedFilterModel, "state": '', "countrt": '', "city": value, "type": types }
        })
        setHasMore(true)
        GetFeed({
          ...filtermodel, "pagingParameter": {
            ...filtermodel.pagingParameter, "pageNum": 1
          }, "feedFilterModel": { ...filtermodel.feedFilterModel, "city": value, "type": types }
        })
      }
    }
  }
  const eventChange = (e, type) => {
    const value = {}
    value[e.target.name] = e.target.value
    if (type === "college") {
      setEventModel({ ...eventModel, ...value })
    }
    else if (type === "company") {
      setCompanyEventModel({ ...companyEventModel, ...value })
    }
  }
  const AttendCompanyEvent = async (id) => {
    if (validation("company")) {
      showLoader();
      var res = await services.Create(ServiceAPI.companyEventResisterApi, { ...companyEventModel, "companyEventId": id });
      hideLoader();
      if (res && res.message === "Success") {
        setCompanyEventModel({
          "id": "",
          "companyEventId": "",
          "firstName": "",
          "lastName": "",
          "email": "",
          "contact": ""
        })
        setTimeout(() => {
          $('.modal-backdrop').removeClass('show ');
          $('.modal').removeClass('show');
          $('body').toggleClass('p-0');
          $('body').removeClass('modal-open');
          $('nav.navbar.navbar-expand-sm').addClass('p-0');
        }, 2000)
        toast.success('Applied Successfully', { theme: "colored", autoClose: 2000, toastId: 'success1' })
      }

    }
  }
  const AttendEvent = async (id) => {
    if (validation("college")) {
      showLoader();
      var res = await services.Create(ServiceAPI.collegeEventResisterApi, { ...eventModel, "collegeEventId": id });
      hideLoader();
      if (res && res.message === "Success") {
        setEventModel({
          "id": "",
          "collegeEventId": "",
          "firstName": "",
          "lastName": "",
          "email": "",
          "contact": ""
        })
        setTimeout(() => {
          $('.modal-backdrop').removeClass('show ');
          $('.modal').removeClass('show');
          $('body').toggleClass('p-0');
          $('body').removeClass('modal-open');
          $('nav.navbar.navbar-expand-sm').addClass('p-0');
        }, 2000)
        toast.success('Applied Successfully', { theme: "colored", autoClose: 2000, toastId: 'success1' })
      }
    }
  }
  const validation = (type) => {
    let firstName = ''
    let lastName = ''
    let email = ''
    let contact = ''
    if (type === "college") {
      if (!ValidationService.validationNotEmpty(eventModel.firstName)) {
        toast.error("Please enter firstName ", { theme: "colored", autoClose: 2000, toastId: 'success1' });
        firstName = "borderred";
      }
      if (!ValidationService.validationNotEmpty(eventModel.lastName)) {
        toast.error("Please enter lastName ", { theme: "colored", autoClose: 2000, toastId: 'success1' });
        lastName = "borderred";
      }
      if (!ValidationService.validationEmail(eventModel.email)) {
        toast.error("Please enter email ", { theme: "colored", autoClose: 2000, toastId: 'success1' });
        lastName = "borderred";
      }
      if (!ValidationService.validationNotEmpty(eventModel.contact)) {
        toast.error("Please enter Contact no. ", { theme: "colored", autoClose: 2000, toastId: 'success1' });
        contact = "borderred";
      }
    }
    else if (type === "company") {
      if (!ValidationService.validationNotEmpty(companyEventModel.firstName)) {
        toast.error("Please enter firstName ", { theme: "colored", autoClose: 2000, toastId: 'success1' });
        firstName = "borderred";
      }
      if (!ValidationService.validationNotEmpty(companyEventModel.lastName)) {
        toast.error("Please enter lastName ", { theme: "colored", autoClose: 2000, toastId: 'success1' });
        lastName = "borderred";
      }
      if (!ValidationService.validationEmail(companyEventModel.email)) {
        toast.error("Please enter valid email ", { theme: "colored", autoClose: 2000, toastId: 'success1' });
        email = "borderred";
      }
      if (!ValidationService.validationNotEmpty(companyEventModel.contact)) {
        toast.error("Please enter Contact no. ", { theme: "colored", autoClose: 2000, toastId: 'success1' });
        contact = "borderred";
      }
    }

    if (firstName || lastName || email || contact) {

      return false;
    }
    return true;
  }

  const Redirect = (type) => {

    $('.modal-backdrop').removeClass('show ');
    $('.modal').removeClass('show');
    $('body').toggleClass('p-0');
    $('body').removeClass('modal-open');
    if (type === "Candidate" || type === "studentList") {
      history.push("/" + "studentList");
    }
    if (type === "College" || type === "collegeList") {
      history.push("/" + "collegelist");
    }
    if (type === "Company" || type === "companyList") {
      history.push("/" + "companylist");
    }
    if (type === "JobAndInternship") {
      history.push("/JobAndInternship");
    }
  }

  const setDropdown = () => {
    if (active === false) {
      $(".dropdown1-content").addClass("active");
      $(".dropdown1 .btn-outline").addClass("active")
      setActive(true);
    }
    else {
      $(".dropdown1-content").removeClass("active");
      $(".dropdown1 .btn-outline").removeClass("active")
      if (count || statelist.length !== 0) { $(".dropdown1 .btn-outline").addClass("active") }
      setActive(false);
    }
  }
  const setDropdown2 = () => {
    if (active2 === false) {
      $(".location-menu").addClass("show")
      $("#dropdownMenu3").addClass("active")
      $(".ind-menu").removeClass("show")
      $("#dropdownMenu2").removeClass("active")
      setActive2(true); setActive1(false);
    }
    else {
      $(".location-menu").removeClass("show")
      $("#dropdownMenu3").removeClass("active")
      setActive2(false);
    }
  }
  const setDropdown1 = () => {
    if (active1 === false) {
      $(".ind-menu").addClass("show")
      $("#dropdownMenu2").addClass("active")
      $(".location-menu").removeClass("show")
      $("#dropdownMenu3").removeClass("active")
      setActive1(true); setActive2(false);
    }
    else {
      $(".ind-menu").removeClass("show")
      $("#dropdownMenu2").removeClass("active")
      setActive1(false)
    }
  }

  const sortfeed = (e, type) => {

    if (type === "list") {
      setShowTile(false)
    }
    else if (type === "tile") {
      setShowTile(true)
    }
    else if (type === "newest") {
      setHasMore(true)
      setShowTile1({ ...showtile1, newest: e.target.checked, oldest: false })
      setFilterModel({
        ...filtermodel, "pagingParameter": {
          ...filtermodel.pagingParameter, "pageNum": 1
        }, "feedFilterModel": { ...filtermodel.feedFilterModel, "sortOrder": e.target.checked === true ? `${type}` : "" }
      })
      GetFeed({
        ...filtermodel, "pagingParameter": {
          ...filtermodel.pagingParameter, "pageNum": 1
        }, "feedFilterModel": { ...filtermodel.feedFilterModel, "sortOrder": e.target.checked === true ? `${type}` : "" }
      })
    }
    else if (type === "oldest") {
      setHasMore(true)
      setShowTile1({ ...showtile1, oldest: e.target.checked, newest: false })
      setFilterModel({
        ...filtermodel, "pagingParameter": {
          ...filtermodel.pagingParameter, "pageNum": 1
        }, "feedFilterModel": { ...filtermodel.feedFilterModel, "sortOrder": e.target.checked === true ? `${type}` : "" }
      })
      GetFeed({
        ...filtermodel, "pagingParameter": {
          ...filtermodel.pagingParameter, "pageNum": 1
        }, "feedFilterModel": { ...filtermodel.feedFilterModel, "sortOrder": e.target.checked === true ? `${type}` : "" }
      })
    }
  }
  const Setindustry = (params) => {
    SetShowFeedData([])
    const s = [...params.map((item) => industrylist.filter((items) => item === items.name)).map((item) => {
      return item[0].id
    })]
    setHasMore(true)
    GetFeed({
      ...filtermodel, "pagingParameter": {
        ...filtermodel.pagingParameter, "pageNum": 1
      }
      , "feedFilterModel": { ...filtermodel.feedFilterModel, "industryIds": s, "type": types }
    })
    setCount(params.length)
  }
  const resetData = (type) => {
    if (type === "location") {
      SetShowFeedData([])
      setHasMore(true)
      GetFeed({
        ...filtermodel, "pagingParameter": {
          ...filtermodel.pagingParameter, "pageNum": 1
        },
        "feedFilterModel": {
          ...filtermodel.feedFilterModel,
          "country": "",
          "city": "",
          "state": "",
        }
      })
      setShowCount(true)
      setCountryList([])
      setStateList([])
      setCityList([])
      setTimeout(() => {
        setShowCount(false)
      }, 100);
      getLocation()
    }
  }
  const clearall = (type) => {
    SetShowFeedData([])
    setHasMore(true)
    if (type) { }
    else { setTypes("all") }

    GetIndustry(); getLocation(); setCountryList([]); setStateList([])
    setCityList([])
    setShow(false); setShowCount(true)
    setCount();
    setTimeout(() => {
      GetFeed({
        "pagingParameter": {
          "pageNum": 1,
          "pageSize": 4
        },
        "feedFilterModel": {
          "courseIds": [],
          "locations": [],
          "industryIds": [],
          "country": "",
          "city": "",
          "state": "",
          "sortOrder": "",
          "type": "all"
        }
      })
      setShow(true); setShowCount(false)
    }, 100);
  }
  const changeHandel = (e) => {
    setActive(false)
    setCountryList([]); setStateList([])
    setCityList([])
    setShow(false); setShowCount(true); setCount()
    $(".dropdown1-content").removeClass("active");
    $(".dropdown1 .btn-outline").removeClass("active")
    setTypes(e.target ? e.target.value : e);
    showFeedDatas.splice(0, showFeedDatas.length)
    setHasMore(true)
    setShowTile1({ ...showtile1, oldest: false, newest: false })
    setTimeout(() => {
      GetIndustry(); getLocation();
      setShow(true); setShowCount(false)
      GetFeed({
        ...filtermodel, "pagingParameter": {
          ...filtermodel.pagingParameter, "pageNum": 1
        },
        "feedFilterModel": {
          "courseIds": [],
          "locations": [],
          "industryIds": [],
          "country": "",
          "city": "",
          "state": "",
          "sortOrder": "",
          "type": e.target ? e.target.value : e,
        }
      }, "selectall")
    }, 100);
  }
  const setFilter = () => {
    $(".loc-menu").removeClass("show"); $(".ind-menu").removeClass("show")
    $("#dropdownMenu2").removeClass("active"); $("#dropdownMenu3").removeClass("active")
  }
  const setFilter1 = () => {
    if (showtile1.newest || showtile1.oldest) {
    }

  }

  const redirect = (id, userId) => {
    localStorage.setItem("applyjob", "true")
    $('.modal-backdrop').removeClass('show ');
    $('.modal').removeClass('show');
    $('body').toggleClass('p-0');
    $('body').removeClass('modal-open');
    localStorage.setItem("cuserid", userId, id)
    history.push('invitation')
  }

  const redirectToProfile = (type, id, userId) => {
    if (type === "college") {
      $('.modal-backdrop').removeClass('show ');
      $('.modal').removeClass('show');
      $('body').toggleClass('p-0');
      $('body').removeClass('modal-open');
      history.push('collegesprofile?id=' + id)
      localStorage.setItem("puserid", userId)
    }
    else if (type === "candidate") {
      $('.modal-backdrop').removeClass('show ');
      $('.modal').removeClass('show');
      $('body').toggleClass('p-0');
      $('body').removeClass('modal-open');
      history.push('studentsprofile?id=' + id)
      localStorage.setItem("suserid", userId)
    }
    else if (type === "company") {
      $('.modal-backdrop').removeClass('show ');
      $('.modal').removeClass('show');
      $('body').toggleClass('p-0');
      $('body').removeClass('modal-open');
      history.push('companysprofile?id=' + id)
      localStorage.setItem("cuserid", userId)
    }
  }

  const GetAll = async () => {
    showLoader()
    var res = await services.PostGetAll(ServiceAPI.GetAllStaticDataApi, pageModel);
    hideLoader()
    const data = await res.result;
    SetStaticData(...data);
  }
  const closeSearch = () => {
    if ($(".globsr").hasClass("active")) {
      if ($("#globsearch").val()) {
        $("#new-users-list").removeClass("show")
      }
      else {
        $(".global").removeClass("active")
        $("#globsearch").removeClass("active")
      }
    }
  }
  return (
    <section className="section " style={{ overflow: "hidden" }}>
      <section className="landing-section-home-page carousel slide" id="demo" data-ride="carousel" onClick={() => closeSearch()}>
        <div className="caraousal-head landing-head">
          <div className="sign-heading">
            <span style={{ color: "#FF5A00" }}>  FIND  </span>  THE BEST
            <span style={{ color: "#FF5A00" }}> {textvalue ? textvalue : "CREATIVES"}</span>
            {/* <br /> FROM STUDENTS TO STUDIOS. */}
          </div>
          <div className="btn-row mb-20">

            <div> <a className="btn home-btn btn-outline big-btn" onClick={(e) => Redirect('studentList')} >Candidates</a></div>
            <div style={{ marginLeft: "20px" }}> <a className="btn home-btn btn-outline big-btn" onClick={(e) => Redirect('collegeList')} >Colleges</a></div>
          </div>
          <div className="btn-row">
            <div><a className="btn home-btn btn-outline big-btn" onClick={(e) => Redirect('companyList')}>companies</a></div>
            <div style={{ marginLeft: "20px" }}> <a className="btn home-btn btn-outline big-btn" onClick={(e) => Redirect('JobAndInternship')}>jobs & internship</a></div>
          </div>
        </div>
        {/* Indicators  */}
        <ul className="carousel-indicators carousel-dots landing-dots extra">
          {banner
            ? banner.map((items, indexs) => <li data-target="#demo" key={indexs + items} data-slide-to={`${indexs}`} className={indexs === 0 ? 'active' : ""}></li>) : ""}
        </ul>
        {environment.isServer === "true" ? <div className="scroll-down" id="linkwhatwedo">
          <a ><img src="./images/scroll-down.svg" alt="" /></a> <br />
          <a>  scroll down</a>
        </div> : ""}
        <div className="carousel-inner landing-carousel">
          {banner ? banner.map((bimage, index) => {
            return (
              <div data-interval={3000} className={index === 0 ? `carousel-item active` : "carousel-item"} key={index}>
                <div className="landing-carousal-item caraousal-slide-1">
                  <div className="caraousal-image">
                    <img src={bimage ? bimage.imageUrl : "images/bannerbg.jpg"} />
                    <div className="artwork carousel-title">
                      {bimage.titleLink ? <a href={bimage.titleLink ? bimage.titleLink : ""} target="_blank">{bimage.title ? `This Weeks Artwork is By : ` : ""}{bimage.title}</a> : ""}
                    </div>
                  </div>
                </div>
              </div>
            )
          }) : ""}
        </div>
        {banner.length > 1 ? <>
          <a className="carousel-control-prev control" href="#demo" data-slide="prev">
            <img src="images/chevron.svg" className="prev icon prev-2"></img>
          </a>
          <a className="carousel-control-next control" href="#demo" data-slide="next">
            <img src="images/chevron.svg" className="next icon next-2"></img>
          </a>
        </> : ""}
      </section>
      {/* about section */}
      {environment.isServer === "true" ?
        <div className="feed-section container-fluid " id="whatwedo">
          <div className="row">
            <div className="col-md-12 feed-about " >
              <div className="awards-sec center container-fluid">
                <div className="center title line">
                  WHAT WE DO
                </div>
                <div className="row" >

                  <div className="card center" >
                    <img src={staticdata && staticdata.imageUrl1 ? staticdata.imageUrl1 : "./images/companies.png"} className="card-image" alt="..." />
                    <div className="card-body">
                      <h5 className="sub-title mb-1">{staticdata && staticdata.title1}</h5>
                      <p className="card-text detail">{staticdata && staticdata.description1}</p>

                    </div>
                  </div>
                  <div className="card center" >
                    <img src={staticdata && staticdata.imageUrl2 ? staticdata.imageUrl2 : "./images/companies.png"} className="card-image" alt="..." />
                    <div className="card-body">
                      <h5 className="sub-title mb-1">{staticdata && staticdata.title2}</h5>
                      <p className="card-text detail">{staticdata && staticdata.description2}</p>
                    </div>
                  </div>

                  <div className="card center" >
                    <img src={staticdata && staticdata.imageUrl3 ? staticdata.imageUrl3 : "./images/companies.png"} className="card-image" alt="..." />
                    <div className="card-body">
                      <h5 className="sub-title mb-1">{staticdata && staticdata.title3}</h5>
                      <p className="card-text detail">{staticdata && staticdata.description3}</p>
                    </div>
                  </div>

                  <div className="card center" >
                    <img src={staticdata && staticdata.imageUrl4 ? staticdata.imageUrl4 : "./images/companies.png"} className="card-image" alt="..." />
                    <div className="card-body">
                      <h5 className="sub-title mb-1">{staticdata && staticdata.title4}</h5>
                      <p className="card-text detail">{staticdata && staticdata.description4}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="heading-row">
                <div className="sign-heading">
                  the feed
                </div>
                <div className="head-button">
                  <div className={types === "all" ? "btn btn-outline grey s-btn active" : "btn btn-outline grey s-btn"} onClick={() => changeHandel("all")}>all</div>
                  <div className={types === "job" ? "btn btn-outline grey s-btn active" : "btn btn-outline grey s-btn"} onClick={() => changeHandel("job")}>Jobs</div>
                  <div className={types === "Event" ? "btn btn-outline grey s-btn active" : "btn btn-outline grey s-btn"} onClick={() => changeHandel("Event")}>Events</div>
                  <div className={types === "News" ? "btn btn-outline grey s-btn active" : "btn btn-outline grey s-btn"} onClick={() => changeHandel("News")}>News</div>
                </div>
              </div>
              <div className="heading-row mt-0">
                <div className="filter col-6">
                  <div className="dropdown1     ">
                    <button className="btn btn-outline" onClick={() => setDropdown()}>filter by
                      <span><img src="../../images/icon-chevron.svg" alt="" /></span>
                    </button>
                    <div className="dropdown1-content bg-white container-fluid">
                      <div className="row" >
                        {types === "job" || types === "all" ? <div className="column ">
                          <div className="filter-title dropdown">
                            <button className="loc-btn" type="button" id="dropdownMenu2" onClick={() => setDropdown1()} >
                              Industry<span><span><img src="../../images/icon-chevron.svg" alt="" /></span>
                                {count ? show ?
                                  <span className="count">
                                    {count}
                                  </span> : ""
                                  : ""}
                              </span>
                            </button>
                            <div className="dropdown-menu loc-menu ind-menu" aria-labelledby="dropdownMenu2">
                              {industrylist ? show ? <Multiselect options={industrylist} onSelectOptions={Setindustry} type={"industry"} /> : "" : ""}
                              <div className="absolute-btn">
                                <button className="reset btn btn-outline " style={{ zIndex: 0 }} onClick={() => setFilter()}>ok</button>
                              </div>
                            </div>
                          </div>
                        </div> : ""}

                        <div className="column">
                          <div className="filter-title dropdown">
                            <button className="loc-btn" type="button" id="dropdownMenu3" onClick={() => setDropdown2()} >
                              Location <span><span><img src="../../images/icon-chevron.svg" /></span>
                                {statelist.length !== 0 ? <span className="count check">
                                  <img src="../../images/check-img-white.svg"></img>
                                </span> : ""}</span>
                            </button>
                            <div className="dropdown-menu loc-menu location-menu" aria-labelledby="dropdownMenu3">

                              {showcount ? "" : <><div className="lable"> country : </div> <Select options={coutnrylist} onChange={(e) => AddValue(e)} placeholder={"select"} isSearchable="true"
                                className="filter-title" /></>}
                              {statelist.length !== 0 ?
                                <><div className="lable"> State/Province: </div><Select options={statelist ? statelist : []} placeholder={"select"} onChange={(e) => AddValue(e)} className="filter-title" /></>
                                : ""}
                              {citylist.length !== 0 ? <>< div className="lable">  city    :</div> <Select options={citylist ? citylist : []} onChange={(e) => AddValue(e)} placeholder={"select"} className="filter-title" /> </> : ""}
                              <button className="reset btn btn-outline mr-0" style={{ zIndex: 0 }} onClick={() => resetData("location")}>Reset</button>
                              <button className="reset btn btn-outline mr-2" style={{ zIndex: 0 }} onClick={() => setFilter()}>ok</button>
                            </div>
                          </div>
                        </div>
                        <div className="reset-btn">
                          {count || statelist.length !== 0 ? <button className="reset btn btn-outline" style={{ zIndex: 0 }} onClick={() => clearall()}>Clear All</button> : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row float-right">
                    <div className="filter " onClick={() => setFilter1()}>
                      <div className="dropdown sorting sort-menu2 text-left" id="sort-menu2">
                        <button className="btn btn-outline" data-toggle="dropdown" id="sort-menu2" aria-expanded="false">Sort By
                          <span><img src="../../images/icon-chevron.svg" /></span>
                        </button>
                        <div className="dropdown-menu sort-menu2" aria-labelledby="sort-menu2">
                          <div className={showtile1.oldest ? "link-item  " : "link-item"}>
                            <label className="check-container mb-0">
                              <span>Newest first</span>
                              <input
                                type="checkbox"
                                id="Remember"
                                onChange={(e) => sortfeed(e, "newest")}
                                checked={showtile1.newest}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                          <div className={showtile1.oldest ? "link-item  " : "link-item"} >
                            <label className="check-container mb-0">
                              <span >Oldest first</span>
                              <input
                                type="checkbox"
                                id="Remember"
                                onChange={(e) => sortfeed(e, "oldest")}
                                checked={showtile1.oldest}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="filter ml-1" onClick={() => setFilter()}>
                      <div className="dropdown sorting view sort-menu text-left" id="sort-menu">
                        <button className="btn btn-outline" data-toggle="dropdown" aria-expanded="false">view by
                          <span><img src="../../images/icon-chevron.svg" alt="" /></span>
                        </button>
                        <div className="dropdown-menu sort-menu" aria-labelledby="sort-menu">
                          <div className={showtile ? "link-item" : "link-item active"} onClick={() => sortfeed('', "list")}>
                            <img src="../../images/bars.png" onClick={() => sortfeed('', "list")} alt=''></img> <span>List</span>
                          </div>
                          <div className={showtile ? "link-item active" : "link-item"} onClick={() => sortfeed('', "tile")}>
                            <img src="../../images/tiles.png" onClick={() => sortfeed('', "tile")} alt=''></img> <span>Tiles</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-md-8 feed-scroll" onClick={() => setFilter()}  >
              <InfiniteScroll
                dataLength={showFeedDatas ? showFeedDatas.length : []}
                next={update}
                hasMore={hasMore}
                loader={<div className="load-text"></div>}
                id="InfiniteScroll"
                height={370}
                className={showtile ? "tile-cards" : ""}
              >
                {count > 0 && showFeedDatas.length === 0 ? <div className=" showdata">no data found</div> : ""}
                {showFeedDatas ?
                  showFeedDatas.map((feedData, index) => {
                    return (
                      <div className={showtile ? "feed-card tile-view" : "feed-card"} key={index} id="scrollableDiv">
                        <div className="img-box" style={{ cursor: "pointer" }} onClick={() => redirectToProfile(feedData.company ? "company" : feedData.college ? "college" : feedData.student ? "candidate" : "", feedData.company ? feedData.company.id : feedData.college ? feedData.college.id : feedData.student ? feedData.student.id : '', feedData.company ? feedData.company.userId : feedData.college ? feedData.college.userId : feedData.student ? feedData.student.userId : "")} >
                          {feedData.company ? <img onClick={() => redirectToProfile(feedData.company ? "company" : feedData.college ? "college" : feedData.student ? "candidate" : "", feedData.company ? feedData.company.id : feedData.college ? feedData.college.id : feedData.student ? feedData.student.id : '')} src={feedData.type === "Job" ? feedData.company.logo : feedData.type === "Event" ? feedData.imageUrl : feedData.type === "Media" ? feedData.imageUrl : "./images/post-1.svg"} alt="" /> : feedData.college ? <img onClick={() => redirectToProfile(feedData.company ? "company" : feedData.college ? "college" : feedData.student ? "candidate" : "", feedData.company ? feedData.company.id : feedData.college ? feedData.college.id : feedData.student ? feedData.student.id : '')} src={feedData.type === "Event" || feedData.type === "News" ? feedData.imageUrl : "./images/post-1.svg"} alt="" /> : ""}
                          {feedData.role === "Student" ? "" : <div className="overlay " >
                            {(feedData.type === "Job") ? '' : ""}
                            <div className="img-button">
                              {feedData.type === "Job" ? " Vacancy" : feedData.type === "Event" ? "Event" : feedData.type === "News" ? "News" : feedData.type === "Media" && "media"}
                            </div>
                          </div>}
                        </div>
                        <div className="content-box" >
                          <div className="section-1 d-flex">
                            <div className="d-flex">
                              <div className="logo">
                                {feedData.role === "Student" ? "" : <img src={feedData.type === "Job" ? "./images/location-icon.png" : feedData.type === "Event" ? "./images/calendar.png" : feedData.type === "News" ? "./images/newspaper.png" : feedData.type === "Media" && "./images/newspaper.png"} alt="" />}
                              </div>
                              <div className="headlines">
                                <div className="tab-heading">{feedData.title}</div>
                                <div className="d-flex">
                                  <div className="sub-head">
                                    {feedData.role === "Company" ? `${feedData.company.name}` : feedData.role === "College" ? `${feedData.college.name}` : ""}
                                  </div>
                                  {feedData.type === "Event" ?
                                    <div className="event-modal-date sub-head ">{moment(feedData.eventStartDate).format("M") === moment(feedData.eventEndDate).format("M") ?
                                      moment(feedData.eventStartDate).format("DD") === moment(feedData.eventEndDate).format("DD") ?
                                        `,  ${moment(feedData.eventStartDate).format("DD")} ${moment(feedData.eventStartDate).format("MMMM ")} ${moment(feedData.eventStartDate).format("YYYY")}` :
                                        `,   ${moment(feedData.eventStartDate).format("DD")} - ${moment(feedData.eventEndDate).format("DD")} ${moment(feedData.eventStartDate).format("MMMM ")} ${moment(feedData.eventStartDate).format("YYYY")}` :
                                      `,  ${moment(feedData.eventStartDate).format("DD")} ${moment(feedData.eventStartDate).format("MMM")} ${moment(feedData.eventStartDate).format("YYYY")} - ${moment(feedData.eventEndDate).format("DD")} ${moment(feedData.eventEndDate).format("MMM")} ${moment(feedData.eventEndDate).format("YYYY")} `}</div>
                                    : feedData.type === "News" ? <div className="event-modal-date sub-head ">{`,  ${moment(feedData.newsDate).format("DD")} ${moment(feedData.newsDate).format("MMM")} ${moment(feedData.newsDate).format("YYYY")}`}</div> : ""}
                                </div>
                              </div>
                            </div>
                            <div className="df">
                              {(feedData.type === "Job" ?
                                Common.UserType() === "candidate" ?
                                  <button className="btn btn-outline big-btn" data-toggle="modal" data-target={feedData.type === "Job" ? `${"#jobsPopupModal"}${index}` : ''}>Apply</button>
                                  : <a className="btn btn-outline s-btn" href={feedData.companyURL} target="_blank" >Apply</a> : feedData.type === "Event" && <button className="btn btn-outline big-btn" data-toggle="modal" data-target={feedData.type === "Job" ? `${"#jobsPopupModal"}${index}` : feedData.type === "News" ? `#exampleNewsPopup${index}` : feedData.type === "Media" ? `#exampleMediaPopups${index}` : `${"#jobsEventModal"}${index}`}  >Attend </button>
                              )}
                            </div>
                          </div>
                          <div className="section-2">
                            <div className="detail">
                              {(feedData.type === "Job") ?
                                (feedData.description.match(/<p>/g) || []).length === 1 ? <div style={{}} dangerouslySetInnerHTML={{
                                  __html: `${feedData.description ? feedData.description.slice(0, 500) :
                                    feedData.description}`
                                }} >
                                </div>
                                  : (feedData.description.match(/<p>/g) || []).length === 0 ?
                                    <div style={{}} dangerouslySetInnerHTML={{ __html: `${feedData.description ? feedData.description.slice(0, 500) : feedData.description}` }} />
                                    :
                                    <div className="editor-description" style={{}} dangerouslySetInnerHTML={{ __html: `${feedData.description ? feedData.description.slice(0, 500) : feedData.description}` }} />
                                : feedData.description}
                            </div>
                          </div>
                          {feedData.type === "Portfolio" ? "" : feedData.description ? <button id="readmore-btn" data-toggle="modal" data-target={feedData.type === "Job" ? `${"#jobsPopupModal"}${index}` : feedData.type === "News" ? `#exampleNewsPopup${index}` : feedData.type === "Media" ? `#exampleMediaPopups${index}` : `${"#jobsEventModal"}${index}`} >Read more</button> : ""}
                          {/* even popup model */}
                          {feedData.type === "Event" ?
                            <div className="modal fade apply-modal"
                              key={index + 1}
                              id={`${"jobsEventModal"}${index}`}
                              tabIndex="-1"
                              role="dialog"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog modal-lg modal-dialog-centered">
                                <div className="modal-content">
                                  <button type="button" className="close modalclose-btn pedit2" data-dismiss="modal">&times;</button>
                                  <div className="modal-header">
                                    <div className="bg-img-box">
                                      {feedData.company ? <img className="img-fluid fix-h bg-img" src={feedData.type === "Job" ? feedData.company.logo : feedData.type === "Event" ? feedData.imageUrl : "./images/post-1.svg"} alt="" /> : feedData.college ? <img className="img-fluid fix-h bg-img" src={feedData.type === "Event" || feedData.type === "News" ? feedData.imageUrl : "./images/post-1.svg"} alt="" /> : ""}
                                    </div>
                                    <div className="icon">
                                      {feedData.role === "Company" ? <img style={{ borderRadius: "100px" }} src={feedData.type === "Event" ? feedData.company.logo : "./images/post-1.svg"} alt="" /> : feedData.role === "College" ? <img style={{ borderRadius: "100px" }} src={feedData.type === "Event" || feedData.type === "News" ? feedData.college.logo : "./images/post-1.svg"} alt="" /> : ""}
                                    </div>
                                    <div className="modal-title mdl-heading">{feedData.title}</div>
                                    <div className="user-location">
                                      <img src="./images/calendar.png" />{feedData.role === "Company" ? feedData.company.name : feedData.role === "College" ? feedData.college.name : ""}
                                    </div>
                                    <div class="j-type-name">Event Date :
                                      <div className="event-modal-date ml-2 pl-1"> {moment(feedData.eventStartDate).format("M") === moment(feedData.eventEndDate).format("M") ?
                                        moment(feedData.eventStartDate).format("DD") === moment(feedData.eventEndDate).format("DD") ?
                                          ` ${moment(feedData.eventStartDate).format("DD")} ${moment(feedData.eventStartDate).format("MMMM ")} ${moment(feedData.eventStartDate).format("YYYY")}` :
                                          ` ${moment(feedData.eventStartDate).format("DD")} - ${moment(feedData.eventEndDate).format("DD")} ${moment(feedData.eventStartDate).format("MMMM ")} ${moment(feedData.eventStartDate).format("YYYY")}` :
                                        `  ${moment(feedData.eventStartDate).format("DD")} ${moment(feedData.eventStartDate).format("MMM")} ${moment(feedData.eventStartDate).format("YYYY")} - ${moment(feedData.eventEndDate).format("DD")} ${moment(feedData.eventEndDate).format("MMM")} ${moment(feedData.eventEndDate).format("YYYY")} `}</div>
                                    </div>

                                  </div>
                                  <div className="modal-body custom-padding">
                                    <div className="container-fluid pr-0">
                                      <div className="row justify-content-between">
                                        <div className="col-md-8">
                                          <div>
                                            <p className="detail"> {feedData.description}
                                            </p>

                                          </div>
                                        </div>
                                        <div className="col-md-4 job-details">
                                          <div className="sub-head text-left">
                                            Fill Your Details
                                          </div>
                                          <form>
                                            <div className="row xy-input-grp">
                                              <div className="col-md-6">
                                                <input type="text" className="form-control xu-input" onChange={(e) => eventChange(e, feedData.role === "Company" ? "company" : "college")} name="firstName" value={feedData.role === "Company" ? companyEventModel.firstName : eventModel.firstName} placeholder="First Name" />
                                              </div>
                                              <div className="col-md-6">
                                                <input type="text" className="form-control xu-input" onChange={(e) => eventChange(e, feedData.role === "Company" ? "company" : "college")} name="lastName" value={feedData.role === "Company" ? companyEventModel.lastName : eventModel.lastName} placeholder="Last Name" />
                                              </div>
                                              <div className="col-md-12">
                                                <input type="email" className="form-control xu-input" onChange={(e) => eventChange(e, feedData.role === "Company" ? "company" : "college")} name="email" value={feedData.role === "Company" ? companyEventModel.email : eventModel.email} placeholder="email" />
                                              </div>
                                              <div className="col-md-12">
                                                <input type="number" className="form-control xu-input" onChange={(e) => eventChange(e, feedData.role === "Company" ? "company" : "college")} name="contact" value={feedData.role === "Company" ? companyEventModel.contact : eventModel.contact} placeholder="+91-9383903823" />
                                              </div>
                                            </div>
                                            {feedData.role === "Company" ? <a className="btn btn-outline a-btn mx-auto d-block" onClick={() => AttendCompanyEvent(feedData.id)}>Attend</a> : <a className="btn btn-outline a-btn mx-auto d-block" onClick={() => AttendEvent(feedData.id)}>Attend</a>}
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            : ""}

                          {/* news popup model */}
                          {feedData.type === "News" ?
                            <div className="modal fade  apply-modal "
                              key={index + 1}
                              id={`exampleNewsPopup${index}`}
                              tabIndex="-1"
                              role="dialog"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true">
                              <div className="modal-dialog modal-lg modal-dialog-centered">
                                <div className="modal-content">
                                  <button type="button" className="close modalclose-btn pedit2" data-dismiss="modal">&times;</button>
                                  <div className="modal-header">
                                    <div className="modal-title mdl-heading mt-2">{feedData.title}</div>
                                    <h6 className="mt-2">Date: {moment(feedData.newsDate).format("DD")} {moment(feedData.newsDate).format("MMM")} {moment(feedData.newsDate).format("YYYY")}</h6>
                                  </div>
                                  <div className="modal-body custom-padding">
                                    <div className="container-fluid pr-0">
                                      <div className="row  mr-0 justify-content-between">
                                        <div className="col-sm-8">
                                          <div className="modalbody-content">
                                            {/* <h6>Date:{moment(item.date).format("DD/MM/YYYY")} </h6> */}
                                            <p className="desc">
                                              {feedData.description ? feedData.description : ""}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-sm-4">
                                          {feedData.company ? <img src={feedData.type === "Job" ? feedData.company.logo : feedData.type === "Event" ? feedData.imageUrl : "./images/post-1.svg"} alt="" /> : feedData.college ? <img src={feedData.type === "Event" || feedData.type === "News" ? feedData.imageUrl : "./images/post-1.svg"} alt="" /> : ""}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            : ""}

                          {/*job popup model  */}
                          {feedData.type === "Job" ?
                            <div className="modal fade apply-modal"
                              key={index + 1}
                              id={`${"jobsPopupModal"}${index}`}
                              tabIndex="-1"
                              role="dialog"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog modal-lg modal-dialog-centered">
                                <div className="modal-content">
                                  <button type="button" className="close modalclose-btn pedit2" data-dismiss="modal">&times;</button>
                                  <div className="modal-header">
                                    <div className="icon">
                                      {feedData.role === "Company" ? <img style={{ borderRadius: "100px" }} src={feedData.company.logo ? feedData.company.logo : "./images/post-1.svg"} alt="" /> : ""}
                                    </div>
                                    <div className="modal-title mdl-heading">{feedData.title}</div>
                                    <div className="user-location">
                                      <img src="images/location-icon.png" alt="" />{feedData.role === "Company" ? feedData.company.name : feedData.role === "College" ? feedData.college.name : ""}
                                    </div>
                                    <a className="btn btn-outline s-btn" href={feedData.companyURL} target="_blank" >Apply</a>
                                  </div>
                                  <div className="modal-body custom-padding">
                                    <div className="container-fluid pr-0">
                                      <div className="row  mr-0 justify-content-between">
                                        <div className="col-md-8">
                                          <div className="modalbody-content">
                                            <p className="desc">We're hiring! <br />
                                              {(feedData.type === "Job") ?
                                                (feedData.description.match(/<p>/g) || []).length === 1 ? <div style={{ marginTop: "11px" }} dangerouslySetInnerHTML={{
                                                  __html: `${feedData.description ? feedData.description.slice(0, 500) :
                                                    feedData.description}`
                                                }} >
                                                </div>
                                                  : (feedData.description.match(/<p>/g) || []).length === 0 ?
                                                    <div style={{ marginTop: "11px", fontFamily: "helveticaMed" }} dangerouslySetInnerHTML={{ __html: `${feedData.description ? feedData.description.slice(0, 500) : feedData.description}` }} />
                                                    :
                                                    <div className="editor-description" style={{ marginTop: "11px" }} dangerouslySetInnerHTML={{ __html: `${feedData.description ? feedData.description.slice(0, 500) : feedData.description}` }} />
                                                : feedData.description}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-md-4 job-details">
                                          <div className="job-description">
                                            {feedData && feedData.companyJobCategories && feedData.companyJobCategories.length > 0 ? <div className="j-type-name  d-flex">JOB TYPE</div> : ""}
                                            <div className="d-flex flex-wrap"> {feedData && feedData.companyJobCategories ? feedData.companyJobCategories.map((data, index) => {
                                              return (<div key={index} className="j-type pr-1">{data.name} {feedData.companyJobCategories.length > 1 && feedData.companyJobCategories.length - 1 > index ? ' | ' : ""} </div>)
                                            }) : ""}</div>
                                          </div>
                                          <div className="job-description">
                                            {feedData && feedData.companyJobToolkits && feedData.companyJobToolkits.length > 0 ? <div className="j-type-name  d-flex">TOOLKIT</div> : ""}
                                            <div className="d-flex flex-wrap"> {feedData && feedData.companyJobToolkits ? feedData.companyJobToolkits.map((data, index) => {
                                              return (<div key={index} className="j-type pr-1">{data.name} {feedData.companyJobToolkits.length > 1 && feedData.companyJobToolkits.length - 1 > index ? ' | ' : ""} </div>)
                                            }) : ""}</div>
                                          </div>
                                          <div className="job-description">
                                            <div className="j-type-name">JOB LOCATION</div>
                                            <div className="j-type">{`${feedData.jobCity}, ${feedData.jobState}`}</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            : ""}
                        </div>
                      </div>
                    )
                  })
                  : ""}
              </InfiniteScroll>
            </div>
            {/* advertisement section */}
            <div className="col-md-4 adv-sec">
              <section className="  carousel slide" id="demo1" data-ride="carousel">
                {advertisement.length > 1 ? <>
                  <a className="carousel-control-prev control" href="#demo1" data-slide="prev">
                    <img src="images/chevron.svg" className="prev icon prev-2"></img>
                  </a>
                  <a className="carousel-control-next control" href="#demo1" data-slide="next">
                    <img src="images/chevron.svg" className="next icon prev-2"></img>
                  </a></> : ""}
                <div className="carousel-inner">
                  <div className="feed-box feed-card ad-card ">
                    {advertisement ? advertisement.map((bimage, index) => {
                      return (
                        <div data-interval={3000} className={index === 0 ? `carousel-item active` : "carousel-item"} key={index}>
                          <div className=" caraousal-slide-1">
                            <div>
                              <div className=" float-right dots mr-3 ">
                              </div>
                            </div>
                            <div className="img-box">
                              <img src={bimage.image ? bimage.image : "./images/Group 204.png "} alt=" " className="img-fluid" />
                              <div className="overlay">
                              </div>
                              {bimage.url ? <a className="btn btn-outline s-btn" href={bimage.url ? bimage.url : "#"} target="_blank">Click here</a> : <a className="btn btn-outline s-btn" >Click here</a>}
                            </div>
                          </div>
                        </div>
                      )
                    }) : ""}
                  </div>
                </div>
              </section>
            </div>
          </div>
          {loading === true ? <div className="load-text">Loading....</div> : ""}
        </div >
        : ""}
      {loader}
    </section >
  );
};

export default Home;
