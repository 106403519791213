import React, { useState, useEffect } from "react";
import Common from "../../../Utility/Common";
import DefaultModel from "../../../Model/Student/StudentEducationModel";
import service from "../../../Services/UtilityService";
import ServiceAPI from "../../../Utility/ServiceList";
import FormHeader from "../../Common/FormHeader";
import StudentNav from "./StudentNav";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useLoader from "../../Auth/Loader"
import AdminHeader from '../../Common/AdminHeader'
import $ from "jquery";
import { useHistory } from "react-router-dom";

toast.configure()

const StudentEducation = (props) => {
  const history = useHistory();
  Common.ValidLogin();
  const id1 = props.id;
  const show = props.show == true ? true : ''
  const [model, setModel] = useState(DefaultModel);
  const services = new service();
  const [industry, setIndustry] = useState([]);
  const [qualificationType, setQualificationType] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [date, setDate] = useState(new Date());
  const [border, setBorder] = useState("xu-input");
  const [loader, showLoader, hideLoader] = useLoader();
  const [pageModel, setPageModel] = useState({
    "pageNum": 1,
    "pageSize": 100,
    "isActive": true
  });

  useEffect(() => {
    if (id1 && show === true) {
      BindIndustry();
      QualificationGetAll();
      GetById(id1);
      props.setShow1(false)
    }
    if (props.id2 === "0" && show === true) {
      BindIndustry();
      QualificationGetAll();
      props.setShow1(false)
    }
    else if (props.match || !props) {
      BindIndustry();
      QualificationGetAll();
      const id = props.location.search.split("=")[1]
      if (id) { GetById(id); }
    }
  }, [id1, show]);
  const ChangeHandler = (e) => {
    let value = {};
    if (e.target.type === "checkbox") {
      value[e.target.id] = e.target.checked;
    } else if (e.target.type === "select-one") {

      value[e.target.id] = e.target.options[e.target.selectedIndex].value;

    } else {
      value[e.target.id] = e.target.value;
    }
    setModel({ ...model, ...value });
    setBorder({ ...border, [e.target.id]: "" })
  };

  const updateDate = async (e, field) => {
    let value = {};
    value[field] = e;
    if (field === "startDate") {
      setBorder({ ...border, "startDate": "" })
      setStartDate(e);
    }
    else if (field === "endDate") {
      setEndDate(e);
      setBorder({ ...border, "endDate": "" })
    }
    else if (field === "pursuingYear") {
      setDate(e);
      setBorder({ ...border, "pursuingYear": "" })
    }
    setModel({ ...model, ...value });
  }
  const QualificationGetAll = async () => {
    var res = await services.PostGetAll(ServiceAPI.QualificationGetAllApi, pageModel);
    const data = await res.result;
    data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    setQualificationType(data)
  }
  const BindIndustry = async () => {
    var res = await services.PostGetAll(ServiceAPI.IndustryGetAllApi, pageModel);
    const data = await res.result;
    data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    await setIndustry(data);

  }
  const Create = async (e) => {
    e.preventDefault();
    var response = null;
    if (valid()) {
      if (model.isPursuing === true) {
        let value = { endDate: null };
        setModel({ ...model, ...value });
      }
      showLoader();
      if (model.id === null) {
        response = await services.Create(ServiceAPI.StudentEducationCreateApi, model);
      } else {
        response = await services.Update(ServiceAPI.StudentEducationUpdateApi,
          { ...model, "course": '', "industry": "", "qualifications": '' });
      }
      if (response && response.message === "Success") {
        if (props.id) {
          hideLoader();
          $(`#${props.modalid}`).click();
          props.GetStudentEducations();
          toast.success('Successfully Added', { theme: "colored", autoClose: 2000 })
          setModel(DefaultModel);
          $(`#${props.modalid}`).click();
          setTimeout(() => {
            $('.modal-backdrop').removeClass('show ');
            $('.modal').removeClass('show');
            $('body').toggleClass('p-0');
            $('body').removeClass('modal-open');
            $('nav.navbar.navbar-expand-sm').addClass('p-0');
          }, 2000)
        }
        else if (props.id2) {
          hideLoader();
          setModel(DefaultModel);
          props.GetStudentEducations();
          toast.success('Successfully Added', { theme: "colored", autoClose: 2000 })
          setModel(DefaultModel);
          setTimeout(() => {
            setModel(DefaultModel);
            $(`#${props.modalid}`).click();
            $('.modal-backdrop').removeClass('show ');
            $('.modal').removeClass('show');
            $('body').toggleClass('p-0');
            $('body').removeClass('modal-open');
            $('nav.navbar.navbar-expand-sm').addClass('p-0');
          }, 2000)
        }
        else {
          toast.success('Successful', { theme: "colored", autoClose: 2000 })
          setTimeout(() => {
            history.push("/studenteducationlist");
          }, 2000);
        }
      } else {
        hideLoader();
      }
    }
  };
  const GetById = async (id) => {
    if (!show) {
      $('.modal-backdrop').removeClass('show ');
      $('.modal').removeClass('show');
      $('body').removeClass('p-0');
      $('body').removeClass('modal-open');
    }
    showLoader()
    setModel(DefaultModel);
    var res = await services.GetById(ServiceAPI.StudentEducationGetApi, id);
    hideLoader()
    const education = await res.result;
    setModel(res ? education : "");
    setStartDate(res ? new Date(education.startDate) : "");
    setEndDate(res ? new Date(education.endDate) : "");
    setDate(res ? new Date(education.date) : "");
  };
  const valid = () => {

    let Error = [];
    let qualificationId = "";
    let courseId = "";
    let institute = "";
    let pursuingYear = "";
    let startDate = "";
    let endDate = "";
    let duration = "";
    let percentage = '';
    let industryId = "";
    let studyProgram = ''

    if (!Boolean(model.qualificationId) || model.qualificationId === "0") {
      Error.push("Valid Qualification is required.");
      qualificationId = "borderred";
    }
    if (!Boolean(model.industryId) || model.industryId === "0") {
      Error.push("Valid Industry required");
      industryId = "borderred";
    }
    if (!Boolean(model.institute)) {
      Error.push("Institute Name is required");
      institute = "borderred";
    }
    if (!Boolean(model.studyProgram)) {
      Error.push("Course Name is required");
      studyProgram = "borderred";
    }
    if (model.isPursuing === true) {
      if ((model.pursuingYear > model.duration) || (model.pursuingYear <= 0)) {
        Error.push("pursuingYear cannot greater then duration");
        pursuingYear = "borderred"
      }
    }
    if (!Boolean(model.startDate)) {
      Error.push("start Date is required");
      startDate = "borderred";
    }
    if (model.isPursuing === false) {
      if (!Boolean(model.endDate)) {
        Error.push("end Date is required");
        endDate = "borderred";
      }
    }
    if (model.isPursuing === false) {
      if (new Date(model.startDate) > new Date(model.endDate)) {
        Error.push("Start Date Can Not Greater than End Date");
        startDate = "borderred";
      }
    }
    if (model.isPursuing === false) {
      if (new Date(model.endDate) > new Date()) {
        Error.push("End Date Can Not Greater than Current Date");
        endDate = "borderred";
      }
    }
    if (!Boolean(model.duration)) {
      Error.push("Duration is required");
      duration = "borderred";
    }
    if (model.duration == 0) {
      Error.push("Duration Can't Be Zero");
      duration = "borderred";
    }
    if (model.isPursuing === false) {
      if (model.duration != new Date(model.endDate).getFullYear() - new Date(model.startDate).getFullYear()) {
        Error.push("Valid Duration is required");
        duration = "borderred";
      }
    }
    if (qualificationId || courseId || industryId || institute || studyProgram || pursuingYear || startDate || endDate || duration || percentage) {
      setBorder({ qualificationId, courseId, industryId, institute, studyProgram, pursuingYear, startDate, endDate, duration, percentage });
    }
    if (Error.length === 0) {
      Error = [];
      return true;
    } else if (Error.length > 2) {
      toast.error('Please fill the required fields', { theme: "colored" });
      return false;
    } else if (Error.length <= 2) {
      Error.forEach((element, index) => {
        toast.error(element, { theme: "colored", toastId: `${[index]}` });
      });
      return false;
    }
  };
  const back = () => {
    history.push("/studenteducationlist");
  }
  const DeleteEducation = async (id) => {
    showLoader()
    const res = await services.Delete(ServiceAPI.StudentEducationDeleteApi, id);
    hideLoader()
    if (res.message == "Success") {
      if (props.id) {
        hideLoader();
        props.GetStudentEducations();
        toast.success('Successful Deleted...', { theme: "colored", autoClose: 2000 })
        setTimeout(() => {
          hideLoader();
          $('.modal-backdrop').removeClass('show ');
          $('.modal').removeClass('show');
          $('body').toggleClass('p-0');
          $('body').removeClass('modal-open');
          $('nav.navbar.navbar-expand-sm').addClass('p-0');
        }, 2000)
      }
    }
  }
  return (
    <>
      {props.id || props.id2 ? "" : <AdminHeader heading="" subheading="Manage Education" />}
      <form onSubmit={Create} autoComplete="off">
        <div className="theme-container-admin container-fluid">
          {props.id || props.id2 ? "" : <StudentNav education="active"></StudentNav>}
          <div className="row">
            <div className="mx-auto detail-box form-sec">
              <div className="row m-0 align-items-center">
                <div className="col-md-12">
                  <div className="label">Qualification<span className="required">*</span></div>
                  <select className={border.qualificationId ? border.qualificationId : "xu-input"} id="qualificationId" value={model.qualificationId} onChange={(e) => ChangeHandler(e)}>
                    <option value="0">Select</option>
                    {
                      qualificationType ? qualificationType.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      )) : ""}
                  </select>
                </div>
                <div className="col-md-6">
                  <div className="label">Industry<span className="required">*</span></div>
                  <select className={border.industryId ? border.industryId : "xu-input"} id="industryId" value={model.industryId} onChange={(e) => ChangeHandler(e)}>
                    <option value="0">Select</option>
                    {
                      industry.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-6">
                  <div className="label">Course Name<span className="required">*</span></div>
                  <input
                    type="text"
                    id="studyProgram"
                    className={border.studyProgram ? border.studyProgram : "xu-input"}
                    placeholder="Course Name"
                    value={model ? model.studyProgram : ""}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                </div>

                <div className="col-md-6">
                  <div className="label">Institute Name<span className="required">*</span></div>
                  <input
                    type="text"
                    id="institute"
                    className={border.institute ? border.institute : "xu-input"}
                    placeholder="institute"
                    value={model ? model.institute : ""}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                </div>
                <div className="col-md-6">
                  <div className="label">IsPursuing</div>
                  <label className="switch">
                    <input
                      type="checkbox"
                      id="isPursuing"
                      checked={model ? model.isPursuing : ""}
                      onChange={(e) => ChangeHandler(e)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

                <div className="col-md-6">
                  <div className="label">Start Date<span className="required">*</span></div>
                  <DatePicker
                    id="startDate"
                    className={border.startDate ? border.startDate : "xu-input date"}
                    selected={startDate}
                    onChange={(e) => updateDate(e, "startDate")}
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
                {model.isPursuing === false ?
                  <div className="col-md-6">
                    <div className="label">End Date<span className="required">*</span></div>
                    <DatePicker
                      id="endDate"
                      className={border.endDate ? border.endDate : "xu-input date"}
                      selected={endDate}
                      minDate={startDate}
                      onChange={(e) => updateDate(e, "endDate")}
                      dateFormat="dd-MM-yyyy"
                    />
                  </div>
                  : ''}
                <div className="col-md-6">
                  <div className="label">Duration<span className="required">*</span></div>
                  <input
                    className={border.duration ? border.duration : "xu-input"}
                    type="number"
                    id="duration"
                    value={model ? model.duration : ""}
                    onChange={(e) => ChangeHandler(e)}
                  />
                </div>
                <div className="col-md-6">
                  <div className="label">Percentage</div>
                  <select className="xu-input" id="percentage" value={model.percentage} onChange={(e) => ChangeHandler(e)}>
                    <option value="0">Select</option>
                    {
                      ["75-100%", "70-74%", "60-69%", "50-59%", "40-49%", "0-39%"].map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                  </select>
                </div>
                {model.isPursuing === true ?
                  <div className="col-md-6">
                    <div className="label">Pursuing Year</div>
                    <input
                      className="xu-input"
                      type="number"
                      id="pursuingYear"
                      value={model.isPursuing === true ? model.pursuingYear : 0}
                      onChange={(e) => ChangeHandler(e)}
                    />
                  </div>
                  : ''}
                {props.id ? <div className=" delete">
                  <a
                    className="fa fa-trash delete"
                    onClick={() => DeleteEducation(model.id)}
                  >
                  </a>
                </div> : ""}
                <div className="col-md-12 mt-1">
                  <button
                    className="btn btn-outline a-btn mt-0 float-right"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>{" "}
            </div>{" "}
          </div>
          {props.id || props.id2 ? "" : <div className='back-icon' onClick={back}></div>}
        </div>
      </form>
      {loader}
    </>
  );
};

export default StudentEducation;
