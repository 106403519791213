import { Link } from 'react-router-dom'
import $ from "jquery"
import React, { useState, useEffect } from "react";
import service from "../../Services/UtilityService";
import Common from '../../Utility/Common';
import ServiceAPI from "../../Utility/ServiceList";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
const AdminNav = (props) => {
   var services = new service();
   const history = useHistory(); var notifiy = false
   const [pageModel, setPageModel] = useState({
      "pageNum": 1,
      "pageSize": 5,
      "role": "all"
   })
   const [TotalPage, setTotalPage] = useState(0)
   const [loading, setLoading] = useState(false)
   const [hasMore, setHasMore] = useState(true);
   const [count, setCount] = useState()
   const [notificationlist, setNotificationList] = useState([])
   useEffect(() => {
      // $("#notification-menu").on("click", function () {
      //    $("#new-users-list").addClass("show");
      // }
      // );
      GetNotification(pageModel)
      countNotification()
   }, [])
   // GetAllNotificationApi
   // updateNotificationApi
   const updateNotifications = async (notificatioId, roleid, role) => {
      notifiy = false
      $(".notification-menu").removeClass("show")
      setNotificationList([])
      var res = await services.Create(ServiceAPI.updateNotificationApi, { "id": [notificatioId] });

      history.push(`/updateprofile/${notificatioId}`)
      // }
      // else if (role == "Company") {
      //    window.open(`/companysprofile?id=${roleid}`, "_blank")
      // }
      // else {
      //    window.open(`/collegesprofile?id=${roleid}`, "_blank")

      // }

      GetNotification({
         "pageNum": 1,
         "pageSize": 5,
         "role": "all"
      }, "select")
   }
   useEffect(() => {

   }, [pageModel])

   const GetNotification = async (pageModel, type) => {

      if (type == "select") {
         setNotificationList([])
      }
      setPageModel({ ...pageModel })
      var res = await services.Create(ServiceAPI.GetAllNotificationApi, pageModel);
      var s = res.result.filter((data) => data.notified == false)

      const data = await res.result
      const totalPage = res.metaData.totalCount

      setTotalPage(res.metaData.totalCount)
      setNotificationList(s)
      setHasMore(true)
      if (data.length != 0) {
         if (res.metaData.pageNum == 1 || type == "select") {
            setNotificationList([...data]);
         }
         else {
            setNotificationList([...notificationlist, ...data]);
         }
      }
      else if (pageModel.pageNum > res.metaData.totalPages && totalPage == 0) {
         setNotificationList([])
      }
      setLoading(false)
   }

   const countNotification = async () => {
      var res = await services.GetAll(ServiceAPI.NotificationCountApi);
      setCount(res)
      // NotificationCountApi
   }
   const update = () => {
      if (TotalPage > notificationlist.length) {
         GetNotification({
            ...pageModel, "pageNum": pageModel.pageNum + 1
         })
      }
      else {
         setHasMore(false)
      }
   }


   const showNotification = (type) => {
      notifiy = $(".notification-menu").hasClass("show")
      if (notifiy == true) {
         $(".notification-menu").removeClass("show")
         notifiy = false
      }
      else {
         $(".notification-menu").addClass("show")
         notifiy = true
         // alert(1)
      }

      countNotification()
   }
   return (
      <>
         <div>
            <div>
               <div class={props.scroll ? "scroll-tabs scroll-tabs-scroll" : "scroll-tabs"} scroll="true">
                  {/* <ul className="nav nav-pills justify-content-center admin-nav " id="pills-tab" role="tablist"> */}
                  <ul className="nav nav-tabs admin-nav master" id="pills-tab" role="tablist">
                     <li className="" hidden>
                        <Link className={"nav-link " + props.dashboard} to="/dashboard" ></Link>
                     </li>
                     <li className="active">
                        <Link className={"nav-link " + props.banner} to="/bannerlist" >Banner</Link>
                     </li>
                     <li className="">
                        <Link className={"nav-link " + props.industry} to="/industrylist" >Industry</Link>
                     </li>
                     <li className="">
                        <Link className={"nav-link " + props.attribute} to="/attributelist" >Attribute</Link>
                     </li>
                     <li className="">
                        <Link className={"nav-link " + props.toolkit} to="/toolkitlist" >Toolkit</Link>
                     </li>
                     <li className="">
                        <Link className={"nav-link " + props.skillset} to="/skillsetlist" >Skillset</Link>
                     </li>
                     <li className="">
                        <Link className={"nav-link " + props.advertisement} to="/advertisementlist" >Advertisement</Link>
                     </li>
                     <li className="">
                        <Link className={"nav-link " + props.qualification} to="/qualificationlist" >Qualification</Link>
                     </li>

                     <li className="">
                        <Link className={"nav-link " + props.staticpageslist} to="/staticpageslist" >Static Pages</Link>
                     </li>
                     {/* <li className="">
                  <Link className={"nav-link " + props.course} to="/courselist" >Course</Link>
               </li> */}

                     <li className="">
                        <Link className={"nav-link " + props.changepassword} to="/adminchangepassword" >Change Password</Link>
                     </li>

                     <li className="" style={{ display: "flex" }}>
                        <Link className={"nav-link " + props.users} to="/users" >Users</Link>
                        <div className='notifications' >
                           {count > 0 ? <span className="count"> {count}</span> : ""}
                           <div className="dropdown " id="notification-menu">
                              <div   ><i class="fa fa-bell " onClick={() => showNotification()} style={{ cursor: "pointer" }}></i></div>
                           </div>
                        </div>
                     </li>
                     <li className="">
                        <Link className={"nav-link " + props.invitationmail} to="/invitationmail" >Send Request</Link>
                     </li>
                     {/* <li className="">
                  <Link className={"nav-link "} to="/texteditor" >StaticPage</Link>
               </li> */}
                  </ul>
               </div>
               <div className="notification-menu" id='new-users-list'>
                  <InfiniteScroll
                     dataLength={notificationlist ? notificationlist.length : []}
                     next={update}
                     hasMore={hasMore}
                     loader={<div className="load-text"></div>}
                     // scrollableTarget="scrollableDiv"
                     // endMessage={data.length > 10 ?
                     //     <p style={{ textAlign: 'center' }}>
                     //         <b>You have seen it all</b>
                     //     </p> : ''
                     // }
                     // style={{ overflow: "initial", maxHeight: "400px" }}
                     id="InfiniteScroll"
                     height={300}
                  >
                     {notificationlist && notificationlist.map((data) => {
                        return (
                           <div className={data.notified == false ? "inner-item showbg new" : "inner-item"} id={`${data.id}_notify`} onClick={() => updateNotifications(data.id, data.roleId, data.role, `${data.id}_notify`)}>
                              <img className='new-user-img' src={data.logo ? data.logo : '../images/user.svg'} />
                              <a>New Account Created in {data.role} By {data.firstName}</a>
                           </div>
                        )
                     })}
                  </InfiniteScroll>
               </div>
            </div>
         </div>
      </>

   )

}
export default AdminNav