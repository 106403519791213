import { Link } from 'react-router-dom'
import Common from '../../../Utility/Common'
import environment from '../../../environment'
const CollegeNav = (props) => {
   return (
      <>
         <div class="scroll-tabs clg-nav" scroll="true">
            {/* <ul className="nav nav-pills justify-content-center admin-nav " id="pills-tab" role="tablist"> */}
            <ul className="nav nav-tabs admin-nav" id="pills-tab" role="tablist">

               <li className="active">
                  <Link className={"nav-link " + props.profile} to={`/collegeprofile?id=${Common.LoginId()}`} >Profile</Link>
               </li>
               <li className="">
                  <Link className={"nav-link " + props.banner} to="/collegebannerlist" >Banner</Link>
               </li>
               <li className="">
                  <Link className={"nav-link " + props.aboutus} to={"/collegeaboutus"} >About Us</Link>
               </li>
               <li className="">
                  <Link className={"nav-link " + props.course} to="/collegecourselist" >Manage Course</Link>
               </li>
               <li className="">
                  <Link className={"nav-link " + props.news} to="/collegenewslist" >News</Link>
               </li>
               <li className="">
                  <Link className={"nav-link " + props.event} to="/collegeeventlist" >Event</Link>
               </li>
               <li className="">
                  <Link className={"nav-link " + props.changepassword} to="/collegechangepassword" >Change Password</Link>
               </li>
               {environment.isServer == "true" ? <>
               {/* <li className="">
                  <Link className={"nav-link " + props.appliedevent} to={`/GetAllEventRegistration?id=${Common.LoginId()}`} >Applied Event</Link>
               </li> */}
               <li className="">
                  <Link className={"nav-link " + props.shortlistcategory} to="/collegeshortlistcategory" >ShortList Category</Link>
                  </li> </>: ""}
            </ul>
         </div>
      </>
   )
}
export default CollegeNav