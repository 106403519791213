import React, { useState, useEffect } from "react";
import Common from "../../../Utility/Common";
import DefaultModel from "../../../Model/Company/CompanyMediaModel";
import service from "../../../Services/UtilityService";
import ServiceAPI from "../../../Utility/ServiceList";
import FormHeader from "../../Common/FormHeader";
import CompanyNav from "./CompanyNav";
import environment from '../../../environment';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useLoader from "../../Auth/Loader"
import AdminHeader from '../../Common/AdminHeader'
import $ from "jquery";
import { useHistory } from "react-router-dom";

toast.configure()

const CompanyMedia = (props) => {
  const history = useHistory();
  localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
  Common.ValidLogin();
  const formData = new FormData();
  const id1 = props.id;
  const show = props.show == true ? true : ''
  const newDate = new Date();
  const [showimage, setShowImage] = useState(false);
  const [model, setModel] = useState(DefaultModel);
  const [imgurl, setImgurl] = useState('');
  const [date, setDate] = useState(newDate);
  const [border, setBorder] = useState("");
  const [loader, showLoader, hideLoader] = useLoader();
  const services = new service();

  useEffect(() => {
    if (id1 && props.show === true) {
      props.setShow3(false)
      GetById(id1);
    }
    else if (props.match || !props) {
      const id = props.location.search.split("=")[1]
      if (id) { GetById(id); }
    }
  }, [id1, show]);
  const ChangeHandler = (e) => {
    let value = {};
    if (e.target.type === "checkbox") {
      value[e.target.id] = e.target.checked;
    } else if (e.target.type === "textarea") {
      const inputText = e.target.value;

      // Count words, excluding symbols
      const words = inputText
        .trim()
        .match(/\b[a-zA-Z]+\b/g) || [];

      // Allow only if the word count is within limits
      if (words.length <= 500) {
        value[e.target.id] = e.target.value;
      } else {
        toast.error("Maximum word limit of 500 reached.", { theme: "colored", autoClose: 2000 });
      }
    }
    else {
      value[e.target.id] = e.target.value;
    }
    setModel({ ...model, ...value });
    setBorder({ ...border, [e.target.id]: "" })
  };

  const UploadImage = async (e) => {
    if (validImage(e)) {
      setImgurl()
      setShowImage(true)
      formData.append('file', e.target.files[0]);
      formData.append('imageType', '1');
      var data = await services.UploadImages(ServiceAPI.ImageUplaodApi, "media", formData);
      var url = environment.imageUrl + data.result;
      setImgurl(url);
      setShowImage(false)
      let value = { imageUrl: url };
      await setModel({ ...model, ...value });
      setBorder({ ...border, [e.target.id]: "" })
    }
  }

  const validImage = (e) => {
    let file = e.target.files[0];
    const fileExtension = file.name.split(".").at(-1);
    const allowedFileTypes = ["jpg", "png", "jpeg"];
    if (!allowedFileTypes.includes(fileExtension)) {
      const newName = "No file chosen";
      const input = e.currentTarget;
      const previousFile = input.files[0];
      const newFile = new File([previousFile], newName);
      const dT = new DataTransfer();
      dT.items.add(newFile);
      input.files = dT.files;
      toast.error("File does not support Upload jpg,png,jpeg files", { theme: "colored", toastId: 'error3', autoClose: 3000 });
      return false;
    } 
    return true;
  }

  const UpadteDate = async (e, field) => {
    let value = {};
    value[field] = e;
    if (field === 'date')
      setDate(e);

    await setModel({ ...model, ...value });
    setBorder({ ...border, 'date': "" })
  }

  const Create = async (e) => {
    e.preventDefault();
    var response = null;
    if (valid()) {
      let value = { userId: Common.UserId() };
      setModel({ ...model, ...value });
      showLoader();
      if (model.id === null) {
        response = await services.Create(ServiceAPI.CompanyMediaCreateApi, model);
      } else {
        response = await services.Update(ServiceAPI.CompanyMediaUpdateApi, model);
      }
      if (response && response.message === "Success") {
        if (props.id) {
          hideLoader();
          $(`#${props.modalid}`).click();
          props.GetCompanyMedia();
          toast.success('Successful', { theme: "colored", autoClose: 2000 })
          setTimeout(() => {
            $(`#${props.modalid}`).click();
            hideLoader();
            $('.modal-backdrop').removeClass('show ');
            $('.modal').removeClass('show');
            $('body').toggleClass('p-0');
            $('body').removeClass('modal-open');
            $('nav.navbar.navbar-expand-sm').addClass('p-0');
          }, 2000)
        }
        else if (props.id2) {
          hideLoader();
          $(`#${props.modalid}`).click();
          props.GetCompanyMedia();
          toast.success('Successful', { theme: "colored", autoClose: 2000 })
          setModel(DefaultModel);
          setImgurl('');
          setTimeout(() => {
            $(`#${props.modalid}`).click();
            $('.modal-backdrop').removeClass('show ');
            $('.modal').removeClass('show');
            $('body').toggleClass('p-0');
            $('body').removeClass('modal-open');
            $('nav.navbar.navbar-expand-sm').addClass('p-0');
          }, 2000)
        }
        else {
          toast.success('Successful', { theme: "colored", autoClose: 2000 })
          setTimeout(() => {
            history.push("/companymedialist");
            setModel(DefaultModel);
          }, 1000);
        }
      } else {
        hideLoader();
      }
    }
  };

  const GetById = async (id) => {
    if (!show) {
      $('.modal-backdrop').removeClass('show ');
      $('.modal').removeClass('show');
      $('body').toggleClass('p-0');
      $('body').removeClass('modal-open');
    }
    showLoader()
    setModel(DefaultModel);
    var res = await services.GetById(ServiceAPI.CompanyMediaGetApi, id);
    hideLoader()
    const media = await res.result;
    setModel(media);
    setImgurl(media.imageUrl);
    setDate(new Date(media.date));

  };

  const valid = () => {
    let Error = [];
    let title = "";
    let imageUrl = "";
    let description = "";
    let date = "";

    if (!Boolean(model.title)) {
      Error.push("Valid Title required");
      title = "borderred";
    }
    if (model.title.length > 50) {
      Error.push("Valid Length required for Title");
      title = "borderred";
    }
    if (!Boolean(model.imageUrl)) {
      Error.push("Valid Image URL Required");
      imageUrl = "borderred";
    }
    if (!Boolean(model.description)) {
      Error.push("Valid Description required");
      description = "borderred";
    }

    if (!Boolean(model.date)) {
      Error.push("Date required");
      date = "borderred";
    }
    if (new Date(model.date) > new Date()) {
      Error.push("Date Can't In Future");
      date = "borderred";
    }
    if (title || imageUrl || description || date) {
      setBorder({ title, imageUrl, description, date });
    }
    if (Error.length === 0) {
      Error = [];
      return true;
    } else if (Error.length > 2) {
      toast.error('Please fill the required fields', { theme: "colored", toastId: 'error1' });
      return false;
    } else if (Error.length <= 2) {
      Error.forEach((element, index) => {
        toast.error(element, { theme: "colored", toastId: `${[index]}` });
      });
      return false;
    }
  };
  const back = () => {
    history.push("/companymedialist");
  }
  const DeleteMedia = async (id) => {
    const res = await services.Delete(ServiceAPI.CompanyMediaDeleteApi, id)
    if (res.message == "Success") {
      if (props.id) {
        props.GetCompanyMedia("delete");
        toast.success('Successful Deleted...', { theme: "colored", autoClose: 2000, toastId: 'success1' })
        setTimeout(() => {
          hideLoader();
          $('.modal-backdrop').removeClass('show ');
          $('.modal').removeClass('show');
          $('body').toggleClass('p-0');
          $('body').removeClass('modal-open');
          $('nav.navbar.navbar-expand-sm').addClass('p-0');

        }, 2000)
      }
    }
  }
  return (
    <>
      {props.id || props.id2 ? "" : <AdminHeader heading="" subheading="Manage Media" />}
      <form onSubmit={Create} autoComplete="off">
        <div className="theme-container-admin container-fluid">
          {props.id || props.id2 ? "" : <CompanyNav media="active"></CompanyNav>}
          <div className="row">
            <div className="mx-auto detail-box form-sec">
              <div className="row m-0 align-items-center">

                <div className="col-md-12">
                  <div className="label">Title<span className="required">*</span></div>
                  <input
                    type="text"
                    id="title"
                    className={border.title ? border.title : "xu-input"}
                    placeholder="Title"
                    value={model.title}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                </div>
                <div className="col-md-12">
                  <div className="row m-0">
                    <div className="col-md-10 pl-0">
                      <div className="label xy-label-float">Choose Image<span className="required">*</span> <span className="">(JPG or PNG)</span></div>
                      <input
                        type="file"
                        id="imageUrl"
                        className={border.imageUrl ? `${border.imageUrl} xpading` : "xu-input xpading"}
                        placeholder="Image"
                        accept="image/jpeg, image/png,  image/jpg"
                        onChange={(e) => UploadImage(e)}
                      ></input>
                    </div>
                    <div className="col-md-2 pr-0">
                      {imgurl ? <img src={imgurl} className="ad-img img-fluid" />
                        : showimage ? <span className="img ">
                          <div className="admin-img-preview">
                            <div className="lds-roller sm"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                          </div>
                        </span> : <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/default-img.jpg`} className="ad-img img-fluid" />}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="label">Date <span className="required">*</span></div>
                  <DatePicker
                    id="date"
                    className={border.date ? border.date : "xu-input"}
                    selected={date}
                    onChange={(e) => UpadteDate(e, 'date')}
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
                <div className="col-md-12">
                  <div className="label">description<span className="required">*</span>{`(${model.description ? model.description.trim().match(/\b[a-zA-Z]+\b/g)?.length : 0}/500)`}</div>
                  <textarea
                    // maxLength={500}
                    type="text"
                    rows="3"
                    id="description"
                    className={border.description ? border.description : "xu-textarea"}
                    placeholder="description"
                    value={model.description}
                    onChange={(e) => ChangeHandler(e)}
                  ></textarea>
                </div>

                <div className="col-md-12">
                  <div className="label">Active</div>
                  <label className="switch">
                    <input
                      className=""
                      type="checkbox"
                      id="active"
                      checked={model.active}
                      onChange={(e) => ChangeHandler(e)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                {props.id ? <div className=" delete">
                  <a
                    className="fa fa-trash delete"
                    onClick={() => DeleteMedia(model.id)}
                  >

                  </a>
                </div> : ""}
                <div className="col-md-12 ">
                  <button
                    className="btn btn-outline a-btn mt-0 float-right"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>{" "}
            </div>{" "}
          </div>
          {props.id || props.id2 ? "" : <div className='back-icon' onClick={back}></div>}
        </div>
      </form>
      {loader}
    </>
  );

}

export default CompanyMedia;