import Common from "../../Utility/Common";
const CompanyLocationModel = () => ({
    id: null,
    userId: Common.UserId(),
    active: true,
    companyId: Common.LoginId(),
    name: '',
    address: '',
    postalCode: '',
    city: '',
    state: '',
    country: '',

})
export default CompanyLocationModel;
