import React, { useState, useEffect } from 'react';
import service from "../../Services/UtilityService";
import Common from '../../Utility/Common';
import ServiceAPI from "../../Utility/ServiceList";
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ShortList from './Admin/ShortList'
import { FaPencilAlt } from "react-icons/fa"
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import useLoader from '../Auth/Loader'

const Draganddrop = () => {
    const history = useHistory();
    var services = new service();
    const [loader, showLoader, hideLoader] = useLoader();
    const [ShortListData, setShortListData] = useState([])
    const [choiceData, setChoiceData] = useState([])
    localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
    useEffect(() => {
        GetShortListData();
    }, [])
    const deletestudent = async (id) => {
        showLoader()
        var res = await services.Delete(ServiceAPI.CollegeShortListDeleteApi, id);
        hideLoader()
        if (res.message == "Success") {
            GetShortListData()
            toast.success("Successfully Removed", { theme: "colored", autoClose: 2000 })

        }

    };
    const GetShortListData = async (callback) => {
        $('.modal-backdrop').removeClass('show ');
        $('.modal').removeClass('show');
        $('body').toggleClass('p-0');
        $('body').removeClass('modal-open');
        showLoader()
        var res = await services.GetById(ServiceAPI.CollegeShortListGetAllApi, Common.LoginId());
        var res1 = await services.GetDataById(ServiceAPI.CollegeCategoryGetAllApi, Common.LoginId(), {
            "pageNum": 1,
            "pageSize": 6
        });
        hideLoader()
        if (res.result && res1.result) {
            setChoiceData(res.result.filter((value) => value.categoryId == "00000000-0000-0000-0000-000000000000"))
            // var intersection = res1.result.map((value, index) =>res.result.filter((value1) =>(value1.categoryId == value.id)))
            setShortListData(res1.result)
        }
    };
    const onDragStart = (ev, id) => {
        document.getElementById("devent").addEventListener('touchstart', function () {
            console.log('btn touched');
        })
        ev.dataTransfer.setData("id", JSON.stringify(id));
    }
    const onDragOver = (ev) => {
        ev.preventDefault();
    }
    const onDrop = async (ev, cat) => {
        ev.preventDefault()
        let id = JSON.parse(ev.dataTransfer.getData("id"));
        if (cat === "wip") {
            const new_obj = { ...id, categoryId: "00000000-0000-0000-0000-000000000000", students: null }
            await services.Update(ServiceAPI.CollegeShortListPutApi, new_obj);
            GetShortListData()
        }
        else {
            const new_obj = { ...id, categoryId: cat, students: null }
            await services.Update(ServiceAPI.CollegeShortListPutApi, new_obj);
            GetShortListData()
        }
    }
    const profile = (id) => {
        history.push('studentsprofile?id=' + id)
    }
    return (
        <div className="checkfit" key={0}>
            <div className='btn-row '>
                <div className="title">ShortList</div>
            </div>
            <div className='shortlist-pannel scrollbar' id='scrollbar'>
                <div className="container-fluid bg-light mt-2 shortlist">
                    <span className="sub-title">shortList</span>
                    <div className="row m-h"
                        onDragOver={(e) => onDragOver(e)}
                        onDrop={(e) => onDrop(e, "wip")}>
                        <div className="light-gry " style={{ minHeight: "30px" }}>    </div>
                        {choiceData ? choiceData.map((value, index) => {
                            return (<div key={index}
                                onTouchStart={(e) => onDragStart(e, value)}
                                onDragStart={(e) => onDragStart(e, value)}
                                draggable
                                className="col-xl-2 col-md-4 col-sm-6" id="devent">
                                <div className="user-profile shortlist-card">
                                    <label>
                                        <a
                                            onClick={() => deletestudent(value.id)}
                                            className="fa fa-trash delete"
                                            aria-hidden="true"
                                        />
                                    </label>
                                    <a onClick={() => profile(value.studentId, value.userId)}>
                                        <div className="bor-pad">
                                            <img src={value ? value.students.profileImage : "./images/news.png"} className="profile-img" />
                                            <h4 className='title mt-20'>{`${value.students.firstName} ${value.students.lastName}`}</h4>
                                            {/* <p className='user-profession'>{value.name}</p> */}
                                            <div className='user-profession'>{value.students && value.students.educations.length !== 0 && value.students.educations[0].industry ? value.students.educations[0].industry.name : ""}</div>
                                            <div className='user-location'>{value.students.country ? <img src="images/location-icon.png"></img> : ""}{value.students.country}</div>
                                        </div>
                                        {/* progree-bar  */}
                                        <div className="education">
                                            <div className="study">
                                                {value.students.educations.length !== 0 ? value.students.educations[0].studyProgram ? <img src="images/education-icon.png" /> : "" : ""}
                                                {value.students && value.students.educations.length !== 0 ? value.students.educations[0].studyProgram ? value.students.educations[0].studyProgram : "" : ""}
                                            </div>
                                            <div className="college">
                                                {value.students.collegeNavigation ? value.students.collegeNavigation.logo ? <img className="" src={value.students.collegeNavigation ? value.students.collegeNavigation.logo : "images/small-bg-logo.png"} /> : "" : ""}
                                                {value.students.collegeNavigation ? value.students.collegeNavigation.name ? <span className='ml-1'>{value.students.collegeNavigation ? value.students.collegeNavigation.name : "Bhc School Of Design"}</span> : "" : ""}
                                            </div>
                                        </div>
                                        <div className="display-inline center">

                                            {/* revit  */}
                                            {value.students ? value.students.toolKits.map((values, index) => {
                                                return (
                                                    <div key={index} className="progress-circle" data-toggle="tooltip" data-placement="right" >
                                                        <span className="percent-value">{values.percentage ? `${values.percentage}%` : ""}</span>
                                                        <CircularProgressbarWithChildren value={values.percentage} strokeWidth={7} styles={buildStyles({
                                                            pathColor: "#FF5A00",
                                                        })}>
                                                            <img src={values.toolKit ? values.toolKit.image : "./images/revit.svg"} alt=" " className="rounded-circle img" />
                                                        </CircularProgressbarWithChildren>

                                                    </div>
                                                )
                                            }) : ""}
                                        </div>
                                    </a>
                                </div>

                            </div>)
                        }) : ""}
                    </div>
                </div>
                <div>
                    {ShortListData ? ShortListData.map((category, index) => {
                        return (<section key={index}>
                            <div className="shortlist " key={index + category}>
                                <div className='display-inline pb-0' >
                                    <div className=''><span className="sub-title m-0 border-0 pb-1">{category.name}</span></div>
                                    <div className=" add-option float-right mt-0" >  <FaPencilAlt className="icon" data-toggle="modal" data-target={`${"#shortlistModal"}${index}`} /> </div>
                                </div>
                                <div className='divider'></div>
                                <div className="row m-h"
                                    key={index}
                                    onDragOver={(e) => onDragOver(e)}
                                    onDrop={(e) => onDrop(e, category.id)}>
                                    <div className="light-gry " style={{ minHeight: "30px" }}>    </div>
                                    {category ? category.shortLists.map((value, index) => {
                                        return (<div key={value.studentId + index}
                                            onTouchStart={(e) => onDragStart(e, value)}
                                            onDragStart={(e) => onDragStart(e, value)}
                                            draggable
                                            className="col-xl-2 col-md-4 col-sm-6">
                                            <div className="user-profile shortlist-card">
                                                <label>
                                                    <a
                                                        onClick={() => deletestudent(value.id)}
                                                        className="fa fa-trash delete"
                                                        aria-hidden="true"
                                                    />
                                                </label>
                                                <a onClick={() => profile(value.studentId, value.userId)}>
                                                    <div className="bor-pad">
                                                        <img src={value ? value.students.profileImage : "./images/news.png"} className="profile-img" />
                                                        <h4 className='title mt-20'>{`${value.students.firstName} ${value.students.lastName}`}</h4>
                                                        <div className='user-profession'>{value.students && value.students.educations.length !== 0 && value.students.educations[0].industry ? value.students.educations[0].industry.name : ""}</div>
                                                        <div className='user-location '>{value.students.country ? <img src="images/location-icon.png"></img> : ""}{value.students.country}</div>

                                                    </div>
                                                    {/* education */}
                                                    <div className="education">
                                                        <div className="study">
                                                            {value.students.educations.length !== 0 ? value.students.educations[0].studyProgram ? <img src="images/education-icon.png" /> : "" : ""}
                                                            {value.students && value.students.educations.length !== 0 ? value.students.educations[0].studyProgram ? value.students.educations[0].studyProgram : "" : ""}
                                                        </div>
                                                        <div className="college">
                                                            {value.students.collegeNavigation ? value.students.collegeNavigation.logo ? <img className="" src={value.students.collegeNavigation ? value.students.collegeNavigation.logo : "images/small-bg-logo.png"} /> : "" : ""}
                                                            {value.students.collegeNavigation ? value.students.collegeNavigation.name ? <span className='ml-1'>{value.students.collegeNavigation ? value.students.collegeNavigation.name : "Bhc School Of Design"}</span> : "" : ""}
                                                        </div>
                                                    </div>
                                                    {/* progree-bar  */}
                                                    <div className="display-inline center">
                                                        {value.students ? value.students.toolKits.map((values, index) => {
                                                            return (
                                                                <div key={index} className="progress-circle" data-toggle="tooltip" data-placement="right" >
                                                                    <span className="percent-value">{values.percentage ? `${values.percentage}%` : ""}</span>
                                                                    <CircularProgressbarWithChildren value={values.percentage} strokeWidth={7} styles={buildStyles({
                                                                        pathColor: "#FF5A00",
                                                                    })}>
                                                                        <img src={values.toolKit ? values.toolKit.image : "./images/revit.svg"} alt=" " className="rounded-circle img" />
                                                                    </CircularProgressbarWithChildren>

                                                                </div>
                                                            )
                                                        }) : ""}

                                                    </div>
                                                </a>
                                            </div>
                                        </div>)
                                    }) : ""}
                                </div>
                            </div>
                            <div className="modal fade xy-form"
                                id={`${"shortlistModal"}${index}`}
                                tabIndex="-1"
                                role="dialog"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true">
                                <div className="modal-dialog form-w modal-dialog-centered " >
                                    <div className="modal-content">
                                        <div className="modal-header form-mdlheadr">
                                            <h4 className="modal-title mdl-heading"> Edit</h4>
                                            <button type="button" className="close modalclose-btn" data-dismiss="modal">&times;</button>
                                        </div>
                                        <div className="modal-body">
                                            <ShortList id1={category.id} GetShortListData={GetShortListData} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>)
                    }) : ""}
                </div>

            </div>
            {loader}
        </div>
    )
}

export default Draganddrop

