import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

import useLoader from "../Auth/Loader"
import service from "../../Services/UtilityService";
import ServiceAPI from "../../Utility/ServiceList";

const AboutUs = () => {
    const history = useHistory();
    const [loader, showLoader, hideLoader] = useLoader();
    const [staticdata, SetStaticData] = useState();

    useEffect(() => {
        GetAll()
    }, [])

    const GetAll = async () => {
        showLoader()
        var services = new service();
        var res = await services.PostGetAll(ServiceAPI.GetAllStaticDataApi, {
            "pageNum": 1,
            "pageSize": 10, 
            "isServer": true
        });
        hideLoader()
        const data = await res.result;
        SetStaticData(...data);
    }

    const redirect = (type) => {

        if (type == "Candidate" || type == "studentList") {
            history.push("/" + "studentList");
        }
        if (type == "College" || type == "collegeList") {
            history.push("/" + "collegelist");
        }
        if (type == "Company" || type == "companyList") {
            history.push("/" + "companylist");
        }
        if (type == "JobAndInternship") {
            history.push("/JobAndInternship");
        }
    }

    const getRedirectLocation = (name) => {
        switch (name) {
            case "Colleges":
                return "collegeList"
            case "Candidates":
                return "studentList"
            case "Companies":
                return "companyList"
            case "Jobs & Internship":
                return "JobAndInternship"

        }
    }

    return (
        <>
            <section className="section about-section">
                <div className="stat-sec ">
                    <div className="faq-sec feed-about">
                        {/* <div className="banner min-height-2">
                            <img src="./images/support-bg2.png" alt="" className="img" />
                            <div className="sign-heading">
                                know us more
                            </div>
                        </div> */}

                        <div className="awards-sec container-fluid center">

                            <div className="center title line">
                                From Students To Studios
                            </div>
                            <div className="row">

                                <div className="card center">
                                    <img src={staticdata && staticdata.imageUrl1 ? staticdata.imageUrl1 : "./images/college.png"} className="card-image" alt="..." />
                                    <div className="card-body">
                                        <h5 className="sub-title">{staticdata && staticdata.title1}</h5>
                                        <p className="card-text">{staticdata && staticdata.description1}</p>
                                        <div className="btn btn-outline" onClick={() => redirect(getRedirectLocation(staticdata.title1))}>View All</div>
                                    </div>
                                </div>

                                <div className="card center">
                                    <img src={staticdata && staticdata.imageUrl2 ? staticdata.imageUrl2 : "./images/candidate.png"} className="card-image" alt="..." />
                                    <div className="card-body">
                                        <h5 className="sub-title">{staticdata && staticdata.title2}</h5>
                                        <p className="card-text">{staticdata && staticdata.description2}</p>
                                        <div className="btn btn-outline" onClick={() => redirect(getRedirectLocation(staticdata.title2))}>View All</div>
                                    </div>
                                </div>

                                <div className="card center">
                                    <img src={staticdata && staticdata.imageUrl3 ? staticdata.imageUrl3 : "../images/companies.png"} className="card-image" alt="..." />
                                    <div className="card-body">
                                        <h5 className="sub-title">{staticdata && staticdata.title3}</h5>
                                        <p className="card-text">{staticdata && staticdata.description3}</p>
                                        <div className="btn btn-outline" onClick={() => redirect(getRedirectLocation(staticdata.title3))}>View All</div>
                                    </div>
                                </div>

                                <div className="card center">
                                    <img src={staticdata && staticdata.imageUrl4 ? staticdata.imageUrl4 : "../images/job n internship.png"} className="card-image" alt="..." />
                                    <div className="card-body">
                                        <h5 className="sub-title">{staticdata && staticdata.title4}</h5>
                                        <p className="card-text">{staticdata && staticdata.description4}</p>
                                        <div className="btn btn-outline" onClick={() => redirect(getRedirectLocation(staticdata.title4))}>View All</div>
                                    </div>
                                </div>

                            </div >
                        </div >

                    </div >
                </div >
            </section >
            <section className="client-feedback d-none">

                <div className=" center title line">
                    What Client Say
                </div>
                <div id="demo" className="carousel slide" data-ride="carousel">
                    <div className="upper-ul">
                        {/* <!-- Indicators --> */}
                        <ul className="carousel-indicators">
                            <li data-target="#demo" data-slide-to="0" className="active"> <img src="images/1-Copy.png" alt="" /></li>
                            <li data-target="#demo" data-slide-to="1"><img src="images/2.png" alt="" /></li>
                            <li data-target="#demo" data-slide-to="2"><img src="images/3.png" alt="" /></li>
                            <li data-target="#demo" data-slide-to="3"><img src="images/4.png" alt="" /></li>
                            <li data-target="#demo" data-slide-to="4"><img src="images/5.png" alt="" /></li>

                        </ul>
                    </div>

                    <div className="bg">
                        <img src="../images/bg-lines.svg" alt="" />
                    </div>
                    {/* <!-- The slideshow --> */}
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati corrupti quos dolores et quas molestias excepturi sint occaecati
                            <div className="client-name">Marcus Doe</div>
                            <div className="client-profession">UI/UX Developer</div>
                        </div>
                        <div className="carousel-item">
                            vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati corrupti quos dolores et quas molestias excepturi sint occaecati
                            <div className="client-name">Marcus Doe</div>
                            <div className="client-profession">UI/UX Developer</div>
                        </div>
                        <div className="carousel-item">
                            vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati corrupti quos dolores et quas molestias excepturi sint occaecati
                            <div className="client-name">Marcus Doe</div>
                            <div className="client-profession">UI/UX Developer</div>
                        </div>
                        <div className="carousel-item">
                            vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati corrupti quos dolores et quas molestias excepturi sint occaecati
                            <div className="client-name">Marcus Doe</div>
                            <div className="client-profession">UI/UX Developer</div>
                        </div>
                        <div className="carousel-item">
                            vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati corrupti quos dolores et quas molestias excepturi sint occaecati
                            <div className="client-name">Marcus Doe</div>
                            <div className="client-profession">UI/UX Developer</div>
                        </div>
                    </div>

                    {/* <!-- Left and right controls --> */}
                    <a className="carousel-control-prev" href="#demo" data-slide="prev">
                        <span className="carousel-control-prev-icon"></span>
                    </a>
                    <a className="carousel-control-next" href="#demo" data-slide="next">
                        <span className="carousel-control-next-icon"></span>
                    </a>

                </div>
            </section>
            <section className="after-client d-none">
                <div className="container">
                    <div className="client-content">

                        <p className=" client-text ">
                            <span className="text-symbol">"</span>vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati
                            <br /> corrupti quos dolores et quas molestias excepturi sint occaecati</p>
                        <p className="client-name">Genilia Decousta , Xylus</p>
                    </div>
                </div>

            </section>
            <section className="position-relative d-none">
                <div className=" footer ">
                    <div className="container">
                        <div className="footer-content center title line ">
                            We Work With Forward Thinking Brands
                            <br /> Who Understands<br /> The Value Of Creativeness </div>
                        <div className="footer-img ">
                            {/* <!-- <img src="./images/logo.png "> --> */}
                        </div>
                        <div className="foot-content about d-flex ">
                            <a className="foot-link gray" href="# "><img src="./images/DUT-Logo_new.png " /></a>
                            <a className="foot-link gray" href="# "><img src="./images/tolko.png" /> </a>
                            <a className="foot-link gray" href="# "><img src="./images/designscape.png" /></a>
                            <a className="foot-link gray" href="# "><img src="./images/inscape.png " /></a>
                            <a className="foot-link gray" href="# "><img src="./images/DUT-Logo_new.png " /></a>
                            <a className="foot-link gray" href="# "><img src="./images/tolko.png " /> </a>
                        </div>
                    </div>
                </div>
            </section>
            {loader}
        </>
    )
}
export default AboutUs