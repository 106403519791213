import React, { useState, useEffect } from "react";
import Common from "../../../Utility/Common";
import DefaultModel from "../../../Model/College/CollegeLocationModel";
import service from "../../../Services/UtilityService";
import ServiceAPI from "../../../Utility/ServiceList";
import FormHeader from "../../Common/FormHeader";
import CollegeNav from "./CollegeNav";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import $ from "jquery";
import useLoader from '../../Auth/Loader'
toast.configure()

const CollegeLocation = (props) => {
  Common.ValidLogin();
  const id = props.location.search.split("=")[1]
  const [model, setModel] = useState(DefaultModel);
  const [error, setError] = useState("");
  const [loader, showLoader, hideLoader] = useLoader();
  const services = new service();
  localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
  useEffect(() => {
    if (id) {
      GetById(id);
    }
  }, []);

  const ChangeHandler = (e) => {
    let value = {};
    if (e.target.type === "checkbox") {
      value[e.target.id] = e.target.checked;
    } else {
      value[e.target.id] = e.target.value;
    }
    setModel({ ...model, ...value });
  };
  const Create = async (e) => {
    e.preventDefault();
    var response = null;

    if (valid()) {
      let value = { userId: Common.UserId() };
      setModel({ ...model, ...value });
      if (model.id === null) {
        response = await services.Create(ServiceAPI.CollegeLocationCreateApi, model);
      } else {
        response = await services.Update(ServiceAPI.CollegeLocationUpdateApi, model);
      }

      if (response && response.message === "Success") {
        toast.success('Successful', { theme: "colored", autoClose: 2000, toastId: 'success3' })
        setTimeout(() => {
          window.location = "/collegelocationlist";
          setModel(DefaultModel);
        }, 1000);

      }

    }
  };
  const back = () => {
    window.location = "/collegelocationlist";
  }
  const GetById = async (id) => {
    $('.modal-backdrop').removeClass('show ');
    $('.modal').removeClass('show');
    $('body').toggleClass('p-0');
    $('body').removeClass('modal-open');
    showLoader()
    setModel(DefaultModel);
    var res = await services.GetById(ServiceAPI.CollegeLocationGetApi, id);
    hideLoader()
    const location = await res.result;
    setModel(location);
  }
  const valid = () => {
    let nameError = "";
    let addressError = "";

    if (!Boolean(model.name)) {
      toast.error("Valid Name required", { theme: "colored", toastId: 'error1' });
      nameError = "Name is required.";
    }
    if (model.name.length > 50) {
      toast.error("Valid Length required for name", { theme: "colored", toastId: 'error1' });
      nameError = "Enter Valid Length";
    }
    if (!Boolean(model.address)) {
      toast.error("Valid address required", { theme: "colored", toastId: 'error2' });
      addressError = "address is required.";
    }

    if (nameError || addressError) {
      setError({ nameError, addressError });
      return false;
    }
    return true;
  };

  return (
    <>
      <form onSubmit={Create}>
        <div className="theme-container-admin">
          <CollegeNav location="active"></CollegeNav>
          <div className="row">
            <div className="col-md-6 mx-auto detail-box form-sec">
              <div className="row m-0 align-items-center">

                <div className="col-md-12">
                  <div className="label">Name</div>
                  <input
                    type="text"
                    id="name"
                    className="xu-input"
                    placeholder="name"
                    value={model.name}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                  {error.nameError ? <small className="text-danger" >{error.nameError}</small> : null}
                </div>
                <div className="col-md-12">
                  <div className="label">Address</div>
                  <input
                    type="text"
                    id="address"
                    className="xu-input"
                    placeholder="address"
                    value={model.address}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                  {error.addressError ? <small className="text-danger" >{error.addressError}</small> : null}
                </div>
                <div className="col-md-12">
                  <div className="label">Active</div>
                  <label className="switch">
                    <input
                      className=""
                      type="checkbox"
                      id="active"
                      checked={model.active}
                      onChange={(e) => ChangeHandler(e)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="col-md-12 spacing">
                  <button
                    className="btn sign-btn btn-outline s-btn mt-0 float-right"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>{" "}
            </div>{" "}
          </div>
          {props.id || props.id2 ? "" : <div className='back-icon' onClick={back}></div>}
        </div>
      </form>
      {loader}
    </>
  );
}

export default CollegeLocation;