import Common from "../../Utility/Common";
const newDate = new Date();
const CollegeNewsModel = () => ({
    id: null,
    title: '',
    description: '',
    imageUrl: null,
    date: newDate,
    userId: Common.UserId(),
    active: true,
    collegeId: Common.LoginId()
})
export default CollegeNewsModel;