import React, { useState, useEffect } from "react";
import ServiceAPI from "../../Utility/ServiceList";
import service from '../../Services/UtilityService';
import AlphabetList from 'react-alphabet-list';
import { Tag } from "antd";
import $ from "jquery";
import "antd/dist/antd.css";
import Multiselect from "../Auth/multiselect-dropdown";
import Select from 'react-select'
import useLoader from '../Auth/Loader'
import { useHistory } from "react-router-dom";
const CollegeList = () => {

  const style = {
    paddingRight: "0px",
    color: "black"
  };
  const history = useHistory();
  const profile = (id, userId) => {

    localStorage.setItem("puserid", userId)
    history.push('collegesprofile?id=' + id)
  }
  var activetest = false; var activetest1 = false; var activetest2 = false; var activetest3 = false; var activetest4 = false; var activetest5 = false
  localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: 0 }))
  const [loader, showLoader, hideLoader] = useLoader();
  const [collegelist, setCollegeList] = useState([]);
  const [alldata, setAllData] = useState([]);
  const [qualificationlist, setQualificationList] = useState()
  const [studytypelist, setStudyTypeList] = useState();
  const [industrylist, setIndustryList] = useState()
  const [show, setShow] = useState(true);
  const [nqfl, setNQFL] = useState();
  const [coutnrylist, setCountryList] = useState([])
  const [statelist, setStateList] = useState([])
  const [citylist, setCityList] = useState([])
  const [count, setCount] = useState();
  const [count1, setCount1] = useState();
  const [count2, setCount2] = useState();
  const [count3, setCount3] = useState();
  const [showcount, setShowCount] = useState(false);
  const [suggestionData, setSuggestionData] = useState([]);
  const [suggestionvalue, setSuggestionValue] = useState()
  const [locationData, setLocationData] = useState({
    "country": "",
    "state": "",
    "city": ""
  }
  );
  const nqf = [{ value: "NQF 5", name: "NQF 5" }, { value: "NQF 6", name: "NQF 6" }, { value: "NQF 7", name: "NQF 7" }, { value: "NQF 8", name: "NQF 8" }, { value: "NQF 9", name: "NQF 9" }, { value: "NQF 10", name: "NQF 10" }]
  const [filtermodel, setFilterModel] = useState(
    {
      "country": "", "city": "", "state": "", "industry": [], "graduateYear": [],
      "nqfl": [], "qualification": [], "studyType": [], "sizeOfEmployees": 0,
      "topAchiever": false, "isHiringActive": false, "searchValue": ""
    }
  );

  var services = new service();
  useEffect(() => {
    $("#search").on("click", () => {
      activetest = false
      $(".dropdown1-content").removeClass("active")
      $(".alpha-list").removeClass('filter-expand')
      $(".cards-list").removeClass('filter-expand')
      $(".dropdown1 .btn-outline").removeClass("active")
      if (count || count1 || count2 || count3 || statelist.length !== 0 || nqfl) {
        $(".dropdown1 .btn-outline").addClass("active")
      }
    })
    GetAll();
    getLocation();
    GetIndustry();
    GetQualification();
    GetStudyType();
  }, [])

  const GetStudyType = async () => {
    var res = await services.PostAnonymous(ServiceAPI.StudyTypeGetAllApi, {
      "pageNum": 1,
      "pageSize": 100,
      "isActive": true,
      "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
    });
    const industryData = await res.result.map((item) => {
      return {
        name: item.name,
        value: item.name,
        id: item.id
      }
    });
    industryData.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    setStudyTypeList(industryData)
  };
  const GetQualification = async () => {
    var res = await services.PostAnonymous(ServiceAPI.QualificationGetAllApi, {
      "pageNum": 1,
      "pageSize": 100,
      "isActive": true,
      "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
    });
    const industryData = await res.result.map((item) => {
      return {
        name: item.name,
        value: item.name,
        id: item.id
      }
    })
    industryData.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    setQualificationList(industryData)
  };
  const fetchData = (item) => {
    const url = alldata?.map?.((data) => {
      if (data.id === item.split(",")[1]) {
        if (data.courses.length !== 0) {
          return `${data.logo} --,${data.coverImage} --,${data.isDefaultLogo} --,${data.city} --,${data.courses[0].type} --,${data.courses[0].nfqlLevel} --,${data.courses.length} --,${data.name} --,${data.courseTypes} --,${data.isDefaultLogo} --,${data.studyTypes} --,${data.nqfRange}`
        }
        return `${data.logo} --,${data.coverImage} --,${data.isDefaultLogo} --,${data.city}`
      }
    }
    )
    return url.join('').split(' --,')
  }
  async function GetAll(value) {
    $('.modal-backdrop').removeClass('show ');
    $('.modal').removeClass('show');
    $('body').toggleClass('p-0');
    $('body').removeClass('modal-open');
    showLoader()
    setSuggestionValue(value)
    setSuggestionData([])
    var res = await services.Create(ServiceAPI.CollegeFilterApi, {
      "country": "",
      "city": "",
      "state": "",
      "industry": [],
      "graduateYear": [],
      "nqfl": [],
      "qualification": [],
      "studyType": [],
      "sizeOfEmployees": 0,
      "topAchiever": false,
      "isHiringActive": false,
      "searchValue": value ? value : ""
    });
    hideLoader()
    const data1 = await res.result;
    setTimeout(() => {
      setAllData(data1);
      setCollegeList(data1?.map?.((data) => `${data.name},${data.id},${data.userId}`));
    }, 100);
  }
  const GetIndustry = async () => {
    var res = await services.PostAnonymous(ServiceAPI.IndustryGetAllApi, {
      "pageNum": 1,
      "pageSize": 100,
      "isActive": true
    });
    const industryData = await res.result?.map?.((item) => {
      return {
        name: item.name,
        value: item.name,
        id: item.id
      }
    });
    industryData.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    setIndustryList(industryData)
  };
  const getLocation = async () => {
    var res = await services.GetAll(ServiceAPI.getLocationCountryApi);
    const data1 = await res.result?.map?.((item) => {
      return { value: item, label: item, type: "country" }
    });
    setCountryList([{ value: 'select', label: 'select', type: "country" }, ...data1])
  }
  const GetState = async (state) => {
    var res = await services.GetLocationById(ServiceAPI.getLocationStateApi, state, "country");
    const data1 = await res.result?.map?.((item) => {
      return { value: item, label: item, type: "state" }
    });
    setStateList([{ value: 'select', label: 'select', type: "state" }, ...data1])

  }
  const GetCity = async (city) => {
    var res = await services.GetLocationById(ServiceAPI.getLocationCityApi, city, "state");
    const data1 = await res.result?.map?.((item) => {
      return { value: item, label: item, type: "city" }
    });
    setCityList([{ value: 'select', label: 'select', type: "city" }, ...data1])

  }
  const filterData = async (filtermodel) => {
    showLoader()
    setFilterModel({ ...filtermodel })
    var res = await services.Create(ServiceAPI.CollegeFilterApi, filtermodel);
    hideLoader()
    if (res.message == "Success") {
      const data1 = await res.result;
      setAllData(data1);
      setCollegeList([]);
      setCollegeList(data1?.map?.((data) => `${data.name},${data.id},${data.userId}`));
    }
    else {
      setCollegeList([]);
    }

  }
  const AddValue = async (e, type) => {
    if (e.type === "country") {
      const value = e.value
      if (value !== "select") {
        GetState(value)
        setFilterModel({ ...filtermodel, "country": value })
        setLocationData({ ...locationData, "country": value });
        filterData({ ...filtermodel, "country": value })
      }
      else {
        setFilterModel({ ...filtermodel, "country": '', "state": "", "city": '' })
        setLocationData({ ...locationData, "state": '' });
        setStateList([])
        setCityList([])
        filterData({ ...filtermodel, "country": '', "state": '', "city": '' })
      }
    }
    else if (e.type === "state") {
      const value = e.value
      if (value !== "select...") {
        GetCity(value);
        setFilterModel({ ...filtermodel, ...filtermodel.location, "state": value })
        setLocationData({ ...locationData, "state": value });
        filterData({ ...filtermodel, "state": value, })
      }
      else {
        setFilterModel({ ...filtermodel, ...locationData, "state": '' })
        setLocationData({ ...locationData, "state": '' });
        setCityList([])
        filterData({ ...filtermodel, "state": '', "city": '', "countrt": '' })
      }
    }
    else if (e.type === "city") {
      const value = e.value
      if (value !== "select...") {
        setFilterModel({ ...filtermodel, ...filtermodel.location, "city": value })
        setLocationData({ ...locationData, "city": value });
        filterData({ ...filtermodel, ...filtermodel.location, "city": value })
      }
      else {
        setFilterModel({ ...filtermodel, ...filtermodel.location, "city": '' })
        setLocationData({ ...locationData, "city": "" });
        filterData({ ...filtermodel, ...filtermodel.location, "city": '' })
      }
    }
    else if (type == "nqfl") {
      setNQFL(e.target.value)
      filterData({ ...filtermodel, "nqfl": e.target.value ? [e.target.value] : [] })
    }

  }
  const setDropdown = (type) => {
    setSuggestionData([])
    if (activetest == false) {
      if ($(".dropdown1-content").hasClass("active")) {
        $(".dropdown1-content").removeClass("active")
        $(".alpha-list").removeClass('filter-expand')
        $(".cards-list").removeClass('filter-expand')
        $(".dropdown1 .btn-outline").removeClass("active")
        if (count || count1 || count2 || count3 || statelist.length !== 0 || nqfl) {
          $(".dropdown1 .btn-outline").addClass("active")
        }

      }
      else {
        $(".dropdown1-content").addClass("active")
        $(".dropdown1 .btn-outline").addClass("active")
        $(".alpha-list").addClass('filter-expand')
        $(".cards-list").addClass('filter-expand')
        activetest = true;
      }
    }
    else {
      $(".dropdown1-content").removeClass("active")
      $(".alpha-list").removeClass('filter-expand')
      $(".cards-list").removeClass('filter-expand')
      $(".dropdown1 .btn-outline").removeClass("active")
      if (count || count1 || count2 || count3 || statelist.length !== 0 || nqfl) {
        $(".dropdown1 .btn-outline").addClass("active")
      }
      activetest = false;
    }
  }
  const setDropdown1 = () => {
    if (activetest1 == false) {
      $(".ind-menu").addClass("show")
      $("#dropdownMenu2").addClass("active")
      $(".location-menu").removeClass("show")
      $("#dropdownMenu3").removeClass("active")
      $(".qual-menu").removeClass("show")
      $("#dropdownMenu4").removeClass("active")
      $(".top-menu").removeClass("show")
      $("#dropdownMenu6").removeClass("active")
      $(".styd-menu").removeClass("show")
      $("#dropdownMenu5").removeClass("active")
      activetest1 = true; activetest2 = false; activetest3 = false; activetest4 = false; activetest5 = false;

    }
    else {
      $(".ind-menu").removeClass("show")
      $("#dropdownMenu2").removeClass("active")
      activetest1 = false;
    }
  }
  const setDropdown2 = () => {
    if (activetest2 == false) {
      $(".location-menu").addClass("show")
      $("#dropdownMenu3").addClass("active")
      $(".ind-menu").removeClass("show")
      $("#dropdownMenu2").removeClass("active")
      $(".qual-menu").removeClass("show")
      $("#dropdownMenu4").removeClass("active")
      $(".top-menu").removeClass("show")
      $("#dropdownMenu6").removeClass("active")
      $(".styd-menu").removeClass("show")
      $("#dropdownMenu5").removeClass("active")
      activetest2 = true; activetest1 = false; activetest3 = false; activetest4 = false; activetest5 = false;
    }
    else {
      $(".location-menu").removeClass("show")
      $("#dropdownMenu3").removeClass("active")
      activetest2 = false;
    }
  }
  const setDropdown3 = () => {
    if (activetest3 == false) {
      $(".qual-menu").addClass("show")
      $("#dropdownMenu4").addClass("active")
      $(".location-menu").removeClass("show")
      $("#dropdownMenu3").removeClass("active")
      $(".ind-menu").removeClass("show")
      $("#dropdownMenu2").removeClass("active")
      $(".top-menu").removeClass("show")
      $("#dropdownMenu6").removeClass("active")
      $(".styd-menu").removeClass("show")
      $("#dropdownMenu5").removeClass("active")
      activetest3 = true; activetest2 = false; activetest1 = false; activetest4 = false; activetest5 = false;
    }
    else {
      $(".qual-menu").removeClass("show")
      $("#dropdownMenu4").removeClass("active")
      activetest3 = false;
    }
  }
  const setDropdown4 = () => {
    if (activetest5 == false) {
      $(".styd-menu").addClass("show")
      $("#dropdownMenu5").addClass("active")
      $(".top-menu").removeClass("show")
      $("#dropdownMenu6").removeClass("active")
      $(".qual-menu").removeClass("show")
      $("#dropdownMenu4").removeClass("active")
      $(".location-menu").removeClass("show")
      $("#dropdownMenu3").removeClass("active")
      $(".ind-menu").removeClass("show")
      $("#dropdownMenu2").removeClass("active")
      activetest5 = true; activetest2 = false; activetest3 = false; activetest4 = false; activetest1 = false;
    }
    else {
      $(".styd-menu").removeClass("show")
      $("#dropdownMenu5").removeClass("active")
      activetest5 = false;
    }
  }
  const setDropdown5 = () => {
    if (activetest4 == false) {
      $(".top-menu").addClass("show")
      $("#dropdownMenu6").addClass("active")
      $(".qual-menu").removeClass("show")
      $("#dropdownMenu4").removeClass("active")
      $(".location-menu").removeClass("show")
      $("#dropdownMenu3").removeClass("active")
      $(".ind-menu").removeClass("show")
      $("#dropdownMenu2").removeClass("active")
      $(".styd-menu").removeClass("show")
      $("#dropdownMenu5").removeClass("active")
      activetest4 = true; activetest2 = false; activetest3 = false; activetest1 = false; activetest5 = false;
    }
    else {
      $(".top-menu").removeClass("show")
      $("#dropdownMenu6").removeClass("active")
      activetest4 = false;
    }
  }

  const Setindustry = (params) => {
    const s = [...params?.map?.((item) => industrylist.filter((items) => item == items.name))?.map?.((item) => {
      return item[0].id
    })]
    filterData({ ...filtermodel, "industry": s })
    setCount(params.length)
  }
  const setNf = (params) => {
    filterData({ ...filtermodel, "nqfl": params })
    setNQFL(params.length)
  }
  const setstudytypelist = (params) => {
    const s = [...params?.map?.((item) => studytypelist.filter((items) => item == items.name))?.map?.((item) => {
      return item[0].id
    })]
    filterData({ ...filtermodel, "studyType": s })
    setCount2(params.length)
  }
  const setQualification = (params) => {
    const s = [...params?.map?.((item) => qualificationlist.filter((items) => item == items.name))?.map?.((item) => {
      return item[0].id
    })]
    filterData({ ...filtermodel, "qualification": s })
    setCount1(params.length)
  }
  const resetData = (type) => {
    if (type == "location") {
      setFilterModel({ ...filtermodel, ...locationData, "state": "", "country": '' })
      setShowCount(true)
      setCountryList([])
      setStateList([])
      setCityList([])
      filterData({ ...filtermodel, "country": '', "state": '', "city": '' })
      setTimeout(() => {
        setShowCount(false)
      }, 100);
      getLocation()
    }
  }
  const setFilter = () => {
    $(".dropdown1-content").removeClass("active")
    $(".alpha-list").removeClass('filter-expand')
    $(".cards-list").removeClass('filter-expand')
    $(".dropdown1 .btn-outline").removeClass("active")
    if (count || count1 || count2 || count3 || statelist.length !== 0 || nqfl) {
      $(".dropdown1 .btn-outline").addClass("active")
    }
    $(".loc-menu").removeClass("show")
    $("#dropdownMenu2").removeClass("active")
    $("#dropdownMenu3").removeClass("active")
    $("#dropdownMenu4").removeClass("active")
    $("#dropdownMenu5").removeClass("active")
    $("#dropdownMenu6").removeClass("active")
  }
  const clearall = () => {
    filterData({
      "country": "", "city": "", "state": "", "industry": [], "graduateYear": [], "nqfl": [], "qualification": [],
      "studyType": [], "sizeOfEmployees": 0, "topAchiever": false, "isHiringActive": false
    })
    GetIndustry(); getLocation(); GetQualification(); GetStudyType();
    resetData("location"); setNQFL(); setShow(false);
    filterData({
      "country": "", "city": "", "state": "", "industry": [], "graduateYear": [], "nqfl": [], "qualification": [],
      "studyType": [], "sizeOfEmployees": 0, "topAchiever": false, "isHiringActive": false
    })
    setCount(); setCount1(); setCount2(); setCount3();
    setTimeout(() => {
      setShow(true)
    }, 100);

  }
  const ApplySearch = async (searchValue, type) => {
    if (type) {
      setSuggestionData([])
    }
    showLoader()
    setSuggestionValue(searchValue)
    setFilterModel({ ...filtermodel, "searchValue": searchValue && searchValue.length >= 3 ? searchValue : "" })
    var res = await services.Create(ServiceAPI.CollegeFilterApi, {
      ...filtermodel, "searchValue": searchValue && searchValue.length >= 3 ? searchValue : ""
    });
    hideLoader()
    if (res.message == "Success") {
      const data1 = await res.result;
      setAllData(data1);
      setTimeout(() => {
        setCollegeList([])
        setCollegeList(data1?.map?.((data) => `${data.name},${data.id},${data.userId}`));
      }, 10);
    }
    else {
      setCollegeList([])
    }
  }
  const getSuggestionData = async (e) => {

    $(".dropdown1-content").removeClass("active")
    $(".alpha-list").removeClass('filter-expand')
    $(".cards-list").removeClass('filter-expand')
    $(".dropdown1 .btn-outline").removeClass("active")
    if (count || count1 || count2 || count3 || statelist.length !== 0 || nqfl) {
      $(".dropdown1 .btn-outline").addClass("active")
    }
    if (e.target.value.trim()) {
      setSuggestionValue(e.target.value)
      if (e.target.value.trim().length >= 3) {
        var res = await services.GetByValue(ServiceAPI.CollegeFilterGetSuggestionApi, e.target.value);
        setSuggestionData(res)
        ApplySearch(e.target.value)
      }
      else {
        setSuggestionValue(e.target.value)
        ApplySearch(e.target.value)
      }
    }
    else {
      setSuggestionData([])
      ApplySearch()
    }

  }
  const ClearSearch = () => {
    setSuggestionData([])
    ApplySearch()
  }
  const closeSearch = () => {
    if ($(".globsr").hasClass("active")) {
      if ($("#globsearch").val()) {
        $("#new-users-list").removeClass("show")
      }
      else {
        $(".global").removeClass("active")
        $("#globsearch").removeClass("active")
      }
    }
  }
  return (
    <>
      <section className="section " onClick={() => closeSearch()}>
        <div className="category-section container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="heading-row  ">
                <div className="filter">
                  <div className="dropdown1 text-left">
                    <div className="d-flex">
                      <button className=" btn btn-outline" onClick={() => setDropdown()}>filter by
                        {/* <i className="fa fa-caret-down"></i>  */}
                        <span  ><img src="../../images/icon-chevron.svg" /></span>
                      </button>
                      <div className="search-box-pannel" onClick={() => setFilter()} >
                        <input type="text" autoComplete="off" placeholder="Search" id="search"
                          className={" search-box filter-box"} onChange={(e) => getSuggestionData(e)} value={suggestionvalue ? suggestionvalue : ""} />

                        {suggestionData && suggestionvalue ? <span type="button"
                          className="modalclose"
                          onClick={() => ClearSearch()}
                        >
                          <span aria-hidden="true"> x </span>
                        </span> : ""}
                        {suggestionData && suggestionvalue ?
                          <div className="drop-box" >
                            {suggestionData && suggestionvalue ? suggestionData?.map?.((data) => {
                              return (<div className="inner-item" onClick={() => ApplySearch(data, "select")}>{data}</div>)
                            }) : ""}</div> : ""}
                      </div>
                    </div>
                    <div className="dropdown1-content bg-white container-fluid">
                      <div className="row">
                        <div className="column ">
                          <div className="filter-title dropdown">
                            <button className="loc-btn" type="button" id="dropdownMenu2" onClick={() => setDropdown1()} >
                              Industry <span><span><img src="../../images/icon-chevron.svg" /></span>
                                {count ? show ?
                                  <span className="count">
                                    {count}
                                  </span> : ""
                                  : ""}</span>
                            </button>
                            <div className="dropdown-menu loc-menu ind-menu" aria-labelledby="dropdownMenu2">
                              {industrylist ? show ? <Multiselect options={industrylist} onSelectOptions={Setindustry} type={"industry"} /> : "" : ""}
                              <div className="absolute-btn">
                                <button className="reset btn btn-outline mr-1" style={{ zIndex: 0 }} onClick={() => setFilter()}>ok</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="column">
                          <div className="filter-title dropdown">
                            <button className="loc-btn" type="button" id="dropdownMenu3" onClick={() => setDropdown2()} >
                              Location <span><span><img src="../../images/icon-chevron.svg" /></span>
                                {statelist.length !== 0 ? <span className="count check">
                                  <img src="../../images/check-img-white.svg"></img>
                                </span> : ""}</span>
                            </button>
                            <div className="dropdown-menu loc-menu location-menu" aria-labelledby="dropdownMenu3">
                              {showcount ? "" : <><div className="lable"> country : </div> <Select options={coutnrylist} placeholder={"select"} onChange={(e) => AddValue(e)} isSearchable="true"
                                className="filter-title" /></>}
                              {statelist.length !== 0 ?
                                <><div className="lable"> State/Province: </div><Select options={statelist ? statelist : []} placeholder={"select"} onChange={(e) => AddValue(e)} className="filter-title" /></>
                                : ""}
                              {citylist.length !== 0 ? <>< div className="lable">  city :</div> <Select options={citylist ? citylist : []} onChange={(e) => AddValue(e)} placeholder={"select"} className="filter-title" /> </> : ""}
                              <button className="reset btn btn-outline mr-1" style={{ zIndex: 0 }} onClick={() => setFilter()}>ok</button>
                              <button className="reset btn btn-outline mr-1" style={{ zIndex: 0 }} onClick={() => resetData("location")}>Reset</button>
                            </div>
                          </div>
                        </div>
                        <div className="column">
                          <div className="filter-title dropdown">
                            <button className="loc-btn" type="button" id="dropdownMenu4" onClick={() => setDropdown3()}>
                              Qualification <span><span><img src="../../images/icon-chevron.svg" /></span> {count1 ?
                                <span className="count">
                                  {count1}
                                </span>
                                : ""}
                              </span>

                            </button>
                            <div className="dropdown-menu loc-menu qual-menu" aria-labelledby="dropdownMenu4">
                              {qualificationlist ? show ?
                                <Multiselect options={qualificationlist} onSelectOptions={setQualification} />
                                : "" : ''}
                              <div className="absolute-btn">

                                <button className="reset btn btn-outline mr-1" style={{ zIndex: 0 }} onClick={() => setFilter()}>ok</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="column">
                          <div className="filter-title dropdown">
                            <button className="loc-btn" type="button" id="dropdownMenu5" onClick={() => setDropdown4()} >
                              Study Type <span><span><img src="../../images/icon-chevron.svg" /></span> {count2 ?
                                <span className="count">
                                  {count2}
                                </span>
                                : ""}
                              </span>

                            </button>
                            <div className="dropdown-menu loc-menu styd-menu" aria-labelledby="dropdownMenu5">
                              {studytypelist ? show ?
                                <Multiselect options={studytypelist} onSelectOptions={setstudytypelist} />
                                : "" : ""}
                              <div className="absolute-btn">
                                <button className="reset btn btn-outline" style={{ zIndex: 0 }} onClick={() => setFilter()}>ok</button>

                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="column">
                          <div className="filter-title dropdown"> <button className="loc-btn" type="button" id="dropdownMenu6" onClick={() => setDropdown5()} >
                            NQFL
                            <span><span><img src="../../images/icon-chevron.svg" /></span> {nqfl ?
                              <span className="count">
                                {nqfl}
                              </span>
                              : ""}
                            </span>
                          </button>
                            <div className="dropdown-menu loc-menu top-menu" aria-labelledby="dropdownMenu6">
                              {show ? <Multiselect options={nqf} onSelectOptions={setNf} /> : ""}
                              <div className="absolute-btn">

                                <button className="reset btn btn-outline ml-0" style={{ zIndex: 0 }} onClick={() => setFilter()}>ok</button>
                              </div>
                            </div>

                          </div>
                        </div>
                        <div className="reset-btn">
                          {count || count1 || count2 || count3 || statelist.length !== 0 || nqfl ? <button className="reset btn btn-outline" onClick={() => clearall()}>Reset</button> : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div onClick={() => setFilter()} className="sign-heading" style={{
                  width: "100%",
                  textAlign: "right"
                }}>
                  Colleges
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="cards-list" onClick={() => setFilter()}>
              {(count || count1 || count2 || count3 || statelist.length !== 0 || nqfl) && collegelist.length == 0 ? <div className=" showdata">no data found</div> : ""}
              {collegelist && <AlphabetList
                className="alpha-list bg-light"
                style={style}
                data={collegelist}
                generateFn={(item, index) => {
                  return <Tag key={index} style={{ backgroundColor: "light-grey", border: "none" }} >
                    <div>
                      <div className="img-container" style={{ postion: "relative" }}>

                        {fetchData(item)[2] === "false" ? <img src={fetchData(item)[0]} className="image clg" /> : <img src={fetchData(item)[1]} className="image " />}
                        <div className="name">
                          {item.split(",")[0].toUpperCase()}
                        </div>
                        <div className="middle" onClick={() => profile(item.split(",")[1], item.split(",")[1])}>
                          <div >
                            <div className="front-text">
                              <div className="title">
                                {item.split(",")[0] ? item.split(",")[0] : "designer time"}
                                <hr />
                              </div>
                              <div className="sub-head">
                                {fetchData(item)[8] && fetchData(item)[8] != "null" ? `${fetchData(item)[8]} | ` : ""}
                                {fetchData(item)[11] && fetchData(item)[11] !== "null" ? `${fetchData(item)[11]} | ` : fetchData(item)[5] ? `${fetchData(item)[5]} | ` : ""} {fetchData(item)[3] ? `${fetchData(item)[3]} ` : ""}
                              </div>
                              <div className="sub-head" >
                                {fetchData(item)[6] ? fetchData(item)[6] == 1 ? `${fetchData(item)[6]} course ` : `${fetchData(item)[6]} courses ` : ""}
                              </div>
                              <div className="sub-head small" >
                                {fetchData(item)[10] ? fetchData(item)[10] !== "null" ? fetchData(item)[10].replace(",null", "").replace(",", " | ").replace(",", " | ") : "" : ""}
                              </div>
                            </div>
                            <button className="readmore-btn" onClick={() => profile(item.split(",")[1])}>See more</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tag>
                }} />}
            </div>
          </div>
        </div>
        {loader}
      </section >
    </>

  )

}
export default CollegeList;