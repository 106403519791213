const AdminHeader =(props)=>{
    return (
       <div className="container-fluid">
        <div className="row justify-content-between title-row ">
          <div className="title">
            {props.heading}
            <div className="sub-title">{props.subheading}</div>
          </div>
        </div>
       </div>
    )
}
export default AdminHeader;
