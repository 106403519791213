import { environment } from "../environment";
import Common from '../Utility/Common'
const headers = {
    "Content-Type": "application/json",
    /// "Accept":"application/json"  
}
function joinURL(baseURL, url) {
    return `${baseURL}/${url}`;
}

class BaseService {
    constructor() {
        this.domain = environment.baseURL;
    }
    async login(res) {
        localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: localStorage.getItem("refreshcount") ? JSON.parse(localStorage.getItem("refreshcount")).count + 1 : 0 }))
        var path = window.location.pathname.toLowerCase()
        if (path == '/adminglogin') {
            localStorage.setItem("adminusertype", "admin")
        }

        if (res.status === 401 && localStorage.getItem("adminusertype") == "admin") {
            localStorage.removeItem("adminuserid");
            localStorage.removeItem("adminusername");
            localStorage.removeItem("adminloginid");
            localStorage.removeItem("adminemail");
            localStorage.removeItem("adminusertype");
            localStorage.removeItem("adminprofileimage");
            localStorage.removeItem("adminfirstName");
            localStorage.removeItem("adminlastName");
            window.location = "/adminlogin";
        }
        else if (res.status === 401) {
            localStorage.removeItem("userid");
            localStorage.removeItem("username");
            localStorage.removeItem("loginid");
            localStorage.removeItem("email");
            localStorage.removeItem("usertype");
            localStorage.removeItem("profileImage");
            localStorage.removeItem("firstName");
            localStorage.removeItem("lastName"); localStorage.removeItem("refreshcount")
            window.location = '/login'
        }
        if (localStorage.getItem("adminusertype") == "admin") {
            var jtoken = localStorage.getItem("admintoken")
            if (localStorage.getItem("coutner") == 1) {
                this.refreshtoken(jtoken)
            }
        }
        else {
            var jtoken = localStorage.getItem("token")

            const count = JSON.parse(localStorage.getItem("refreshcount"))
            if (count.count === 1 && count.url === window.location.pathname) {

                this.refreshtoken(jtoken)
            }
            localStorage.setItem("refreshcount", JSON.stringify({ url: window.location.pathname, count: JSON.parse(localStorage.getItem("refreshcount")).count + 1 }))
        }
    }
    async refreshtoken(jtoken) {
        var jtoken = jtoken
        var reftoken = localStorage.getItem("reftoken")
        var tokens = {
            "userID": `${localStorage.getItem("userid")}`,
            "jwToken": jtoken,
            "refreshToken": reftoken
        }
        var option = {
            headers,
            "body": JSON.stringify({ ...tokens }),
            "method": "POST"
        }
        const url = joinURL(this.domain, 'Account/refresh');
        if (jtoken && reftoken && (localStorage.getItem("adminusertype") == "admin" || localStorage.getItem("usertype"))) {
            setTimeout(() => {
                fetch(url, option)
                    .then(function (response) {
                        if (response) { return response.json(); }
                    }).then(function (res) {
                        if (res.result) {
                            if (res.jwtTokenChanged === true) {
                                localStorage.setItem("token", res.result.data.jwToken);
                                localStorage.setItem("reftoken", res.result.data.refreshToken);
                            }
                            if (res.refreshTokenExpired === true) {
                                if (localStorage.getItem("adminusertype") == "admin") {
                                    localStorage.removeItem("adminuserid");
                                    localStorage.removeItem("adminusername");
                                    localStorage.removeItem("adminloginid");
                                    localStorage.removeItem("adminemail");
                                    localStorage.removeItem("adminusertype");
                                    localStorage.removeItem("adminprofileimage");
                                    localStorage.removeItem("adminfirstName");
                                    localStorage.removeItem("adminlastName");
                                    window.location = "/adminlogin";
                                }
                                localStorage.removeItem("userid");
                                localStorage.removeItem("username");
                                localStorage.removeItem("loginid");
                                localStorage.removeItem("email");
                                localStorage.removeItem("usertype");
                                localStorage.removeItem("profileImage");
                                localStorage.removeItem("firstName");
                                localStorage.removeItem("lastName");
                                window.location = '/login'
                            }
                        }
                    });
            }, [1000])

        }
    }
    async request(url, method = 'POST', auth = true, data = null, filetype = null) {
        var today = new Date()
        var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        url = joinURL(this.domain, url);
        if (auth) {
            headers["authorization"] = `Bearer ${localStorage.getItem("token")}`;
        }
        if (filetype === 'image') {
            headers = [];
        }
        const options = {
            headers,
            method,
        }
        if (data) {
            options.body = JSON.stringify({ ...data });
        }
        return await fetch(url, options);
    }
    async requestuser(url, method = 'POST', auth = true, data = null, filetype = null) {
        var today = new Date()
        var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        url = joinURL(this.domain, url);
        if (auth) {
            headers["authorization"] = `Bearer ${localStorage.getItem("admintoken")}`;
        }
        if (filetype === 'image') {
            headers = [];
        }
        const options = {
            headers,
            method,
        }
        if (data) {
            options.body = JSON.stringify({ ...data });
        }
        return await fetch(url, options);
    }
    async requestFile(url, method = 'POST', auth = true, data = null, filetype = null) {
        url = joinURL(this.domain, url);
        if (auth) {
            headers["authorization"] = `Bearer ${localStorage.getItem("token")}`;
        }
        const options = {
            method,
        }
        if (data) {
            options.body = data;
        }
        return await fetch(url, options);
    }
    async get(url, auth, id) {
        localStorage.setItem("counter", 1)
        const method = 'GET';
        if (id) {
            url = `${url}/${id}`;
        }
        return await this.request(url, method, auth).then(res => {
            var resp = res.json()
            this.login(res)
            return resp
        });
    }
    async getusers(url, auth, id) {
        localStorage.setItem("counter", 1)
        const method = 'GET';
        if (id) {
            url = `${url}/${id}`;
        }
        return await this.requestuser(url, method, auth).then(res => {
            var resp = res.json()
            this.login(res)
            return resp
        });
    }
    async post(url, auth, data) {
        localStorage.setItem("counter", 1)
        const method = 'POST';
        return await this.request(url, method, auth, data).then(res => {
            var resp = res.json()
            this.login(res)
            return resp
        });
    }
    async delete(url, auth, id) {
        localStorage.setItem("counter", 1)
        const method = 'DELETE';
        if (id) {
            url = `${url}/${id}`;
        }
        return await this.request(url, method, auth).then(res => {
            var resp = res.json()
            this.login(res)
            return resp
        });
    }
    async put(url, auth, data) {
        const method = 'PUT';
        localStorage.setItem("counter", 1)
        return await this.request(url, method, auth, data).then(res => {
            var resp = res.json()
            this.login(res)
            return resp
        });
    }
    async upload(url, auth, data) {
        const method = 'POST';
        localStorage.setItem("counter", 1)
        return await this.requestFile(url, method, auth, data, 'image').then(res => {
            var resp = res.json()
            this.login(res)
            return resp
        });
    }

}
export default BaseService
