import React, { useState, useEffect } from "react";
import Common from "../../Utility/Common";
import DefaultModel from "../../Model/Admin/IndustryModel";
import service from "../../Services/AdminUtilityService";
import ServiceAPI from "../../Utility/ServiceList";
import FormHeader from "./FormHeader";
import AdminNav from "./AdminNav";
import AdminHeader from './AdminHeader'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useLoader from '../Auth/Loader';
import environment from "../../environment";
import { useHistory } from "react-router-dom";

toast.configure()

const Industry = (props) => {
  const history = useHistory();
  Common.ValidAdminLogin();
  const formData = new FormData();
  // const id = props.match.params.id;
  const id = props.location.search.split("=")[1]
  const [model, setModel] = useState(DefaultModel);
  const [error, setError] = useState("");
  const [loader, showLoader, hideLoader] = useLoader();
  const [border, setBorder] = useState("");
  const [imgurl, setImgurl] = useState('');
  const services = new service();
  const [showimage, setShowImage] = useState(false);

  useEffect(() => {
    if (id) {
      GetById(id);
    }
  }, []);

  const ChangeHandler = (e) => {
    let value = {};
    if (e.target.type === "checkbox") {
      value[e.target.id] = e.target.checked;
    } else {
      value[e.target.id] = e.target.value;
    }
    setModel({ ...model, ...value });
    setBorder({ ...border, [e.target.id]: "" })
  };
  const UploadImage = async (e) => {
    if (validImage(e)) {
      setImgurl()
      setShowImage(true)
      formData.append('file', e.target.files[0]);
      formData.append('imageType', '1');
      var data = await services.UploadImages(ServiceAPI.ImageUplaodApi, "Industry", formData);
      var url = environment.imageUrl + data.result;

      setImgurl(url);
      setShowImage(false)
      let value = { imageUrl: url };
      await setModel({ ...model, ...value });
      setBorder({ ...border, [e.target.id]: "" })
    }
  }
  const validImage = (e) => {
    let file = e.target.files[0];
    const fileExtension = file.name.split(".").at(-1);
    const allowedFileTypes = ["jpg", "png", "jpeg"];
    if (!allowedFileTypes.includes(fileExtension)) {
      const newName = "No file chosen";
      const input = e.currentTarget;
      const previousFile = input.files[0];
      const newFile = new File([previousFile], newName);
      const dT = new DataTransfer();
      dT.items.add(newFile);
      input.files = dT.files;
      toast.error("File does not support Upload jpg,png,jpeg files", { theme: "colored", toastId: 'error3', autoClose: 3000 });
      return false;
    }
    return true;
  }
  const Create = async (e) => {
    e.preventDefault();
    var response = null;
    if (valid()) {
      let user = { userId: Common.AdminUserId() };
      setModel({ ...model, ...user });
      showLoader();
      if (model.id === null) {
        response = await services.Create(ServiceAPI.IndustryCreateApi, model);
      } else {
        response = await services.Update(ServiceAPI.IndustryUpdateApi, model);
      }
      if (response && response.message === "Success") {
        if (model.id === null) {
          toast.success('Industry Name added Successfully', { theme: "colored", autoClose: 2000 })
        } else {
          toast.success('Industry Data Updated Successfully', { theme: "colored", autoClose: 2000 })
        }
        setTimeout(() => {
          history.push("/industrylist");
          setModel(DefaultModel);
        }, 1000);

      } else {
        hideLoader();
      }
    }
  };

  const GetById = async (id) => {
    showLoader()
    var res = await services.GetById(ServiceAPI.IndustryGetApi, id);
    hideLoader()
    const banner = await res.result;
    setModel(banner);
    setImgurl(banner.imageUrl);
  };

  const valid = () => {
    let name = '';
    let nameError = "";
    let imageUrl = "";
    function isValidName(url) {
      let regEx = /^[A-Za-z]/
      return regEx.test(url);
    }
    if (!isValidName(model.name)) {
      toast.error("Valid name required", { theme: "colored", toastId: 'error1' });
      nameError = "Enter Valid name";
      name = "borderred"
    }
    if (!Boolean(model.imageUrl)) {
      Error.push("Valid Image required");
      imageUrl = "borderred"
    }
    if (nameError) {
      setError({ nameError });
      setBorder({ name })
      setBorder({ imageUrl })
      return false;
    }
    return true;
  };
  const back = () => {
    history.push("/industrylist");
  }
  return (
    <>
      <AdminHeader heading="" subheading="Manage Industry" />
      <form onSubmit={Create} autoComplete="off">
        <div className="theme-container-admin master-admin">
          <AdminNav industry="active"></AdminNav>
          <div className="row">
            <div className="col-md-6 mx-auto detail-box form-sec">
              <div className="row m-0 align-items-center">
                <div className="col-md-12">
                  <div className="label">Name<span className="required">*</span></div>
                  <input
                    type="text"
                    id="name"
                    className={border.name ? border.name : "xu-input"}
                    placeholder="Name"
                    value={model.name}
                    onChange={(e) => ChangeHandler(e)}
                  ></input>
                </div>
                <div className="col-md-12">
                  <div className="row m-0">
                    <div className="col-md-10 pl-0">
                      <div className="label xy-label-float">Choose Image<span className="required">*</span><span className="">(JPG or PNG)</span></div>
                      <input
                        type="file"
                        id="imageUrl"
                        className={border.imageUrl ? `${border.imageUrl} xpading` : "xu-input xpading"}
                        placeholder="Image"
                        accept="image/jpeg, image/png,  image/jpg"
                        onChange={(e) => UploadImage(e)}
                      ></input>
                    </div>
                    <div className="col-md-2 pr-0">
                      {imgurl ? <img src={imgurl} className="ad-img img-fluid" />
                        : showimage ? <span className="img mt-3">
                          {/* <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/loader.gif`} className="ad-img img-fluid" /> */}
                          <div className="admin-img-preview mt-3">
                            <div className="lds-roller sm"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                          </div>
                        </span> : <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/default-img.jpg`} className="ad-img img-fluid" />}

                      {/* <img src={imgurl ? imgurl : `${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/default-img.jpg`} className="ad-img img-fluid" /> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="label">Active</div>
                  <label className="switch">
                    <input
                      className=""
                      type="checkbox"
                      id="active"
                      checked={model.active}
                      onChange={(e) => ChangeHandler(e)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="col-md-12">
                  <button
                    className="btn  btn-outline s-btn pr"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='back-icon' onClick={back}></div>
        </div>
        <div></div>
      </form>
      {loader}
    </>
  );
};
export default Industry;
