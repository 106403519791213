class ValidationService {


    static validationNotEmpty(s) {
        return !!s;
    }
    static validationDropdown(s) {
        return s !== 'select';
    }
    static validationPassword(s) {
        // /[a-zA-Z]/.test(s) && /[0-9]/.test(s) && /[&._-]/.test(qry)
        return /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{6,16}/.test(s)
        // return /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(s);
    }
    static validationNotCheck(s) {
        if (s)
            return false;
        return true
    }
    static validationEmail(s) { return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(s); }
    static validationUpperLimit(lower, upper, attribute) {

        let value = lower <= upper && upper <= attribute.length
        return value;
    }
    static passwordCompare(p, c) {
        return p === c;
    }



}


export default ValidationService