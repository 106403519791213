import React, { useState, useEffect, useMemo } from "react";
import service from "../../Services/AdminUtilityService";
import ServiceAPI from "../../Utility/ServiceList";
import TableList from "../../Utility/TableList";
import AdminNav from "./AdminNav";
import AdminHeader from './AdminHeader'
import { ImCross } from "react-icons/im";
import useLoader from '../Auth/Loader';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useHistory } from "react-router-dom";
import $ from 'jquery'

let value = 1;
const StaticPagesList = () => {
    const history = useHistory();
    var notifiy = false
    const [itemlist, setItemList] = useState([]);
    const [model, setModel] = useState(10);
    const [hasMore, setHasMore] = useState(true);
    const [pageModel, setPageModel] = useState({
        "pageNum": 1,
        "pageSize": 10,
        "isActive": false
    });
    const [loader, showLoader, hideLoader] = useLoader();

    var services = new service();
    const data = itemlist;
    const columns = useMemo(
        () => [

            {
                Header: 'Page Name',
                accessor: 'pageName',
            },
            {
                Header: "Edit",
                accessor: "edit",
                Cell: ({ row }) => {
                    const itemID = data.map((item, index) => item.id);
                    return (
                        <label>
                            <a
                                onClick={() => editfunction(itemID[row.index])}
                                className="fa fa-pencil edit"
                                aria-hidden="true"
                            />
                        </label>
                    );
                },
            },

        ], [data])

    const ChangeHandler = async (e, index) => {
        let newArr = itemlist.map((item, i) => {
            if (index == i) {
                return { ...item, active: e.target.checked };
            } else {
                return item;
            }
        });
        setItemList(newArr)
        var response = null;
        response = await services.Update(ServiceAPI.bannerUpdateApi, newArr[index]);
    };

    useEffect(() => {
        GetAll();
    }, []);
    useEffect(() => {
    }, [pageModel]);

    const GetAll = async (id) => {
        showLoader();
        var res = await services.PostGetAll(ServiceAPI.GetAllStaticDataApi, pageModel);
        hideLoader()
        const banner = await res.result;
        setItemList([banner[0]]);

    };

    const editfunction = (id) => {
        history.push("/staticpages?id=" + id);
    };


    const fetchMoreData = () => {
        if (model > itemlist.length) {
            GetAll()
        } else {
            setHasMore(false)
        }
    };
    const newdata = useMemo(() => itemlist, [itemlist]);
    const redirectlist = (link) => {
        history.push(link)
    }
    const showNotification = (type) => {
        notifiy = $(".notification-menu").hasClass("show")
        if (notifiy == true) {
            $(".notification-menu").removeClass("show")
            notifiy = false
        }


    }
    return (
        <>
            <AdminHeader heading="" subheading="Manage Banner" />
            <form>
                <div className="theme-container-admin min-h master-admin">
                    <AdminNav banner="active"></AdminNav>
                    <div className="table-sec" onClick={() => showNotification()}>
                        <div className="add-option">
                            <div className="title"></div>
                            <a className="icon" onClick={() => redirectlist("/banner")}>
                                <i className="fa fa-plus" aria-hidden="true"></i>
                            </a>
                        </div>
                        <div >
                            <TableList
                                data={newdata}
                                columns={columns}
                                update={fetchMoreData}
                                hasMore={hasMore}
                                newClass={"events-mng-table"}
                            // model={model}
                            // pageModel={pageModel}
                            // changePageSize={(e) => {
                            //   setPageModel({
                            //     "pageNum": 1,
                            //     "pageSize": e.target.value
                            //   })
                            // }}
                            // NextPageNumber={(e) => {
                            //   e.preventDefault()
                            //   if (value < model.totalPages || model.totalPages > pageModel.pageNum) {
                            //     if (value == model.totalPages) { value = value - 1; }
                            //     setPageModel({
                            //       "pageNum": value + 1,
                            //       "pageSize": pageModel.pageSize
                            //     })
                            //     value = value + 1;
                            //   }
                            // }}
                            // PreviousPageNumber={(e) => {
                            //   e.preventDefault()
                            //   if (value > 1) {
                            //     setPageModel({
                            //       "pageNum": value - 1,
                            //       "pageSize": pageModel.pageSize
                            //     })
                            //     value = value - 1;
                            //   }
                            // }}
                            />
                        </div>
                    </div>
                </div>
            </form>
            {loader}
        </>
    );

}
export default StaticPagesList;