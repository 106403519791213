import React, { useState, useEffect } from "react";
import Common from "../../Utility/Common";
import DefaultModel from "../../Model/Admin/UpdateProfileModel";
import service from "../../Services/AdminUtilityService";
import ServiceAPI from "../../Utility/ServiceList";
import FormHeader from "./FormHeader";
import AdminNav from "./AdminNav";
import AdminHeader from './AdminHeader'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useLoader from '../Auth/Loader';
import Multiselect from 'multiselect-react-dropdown';
import { useHistory } from "react-router-dom";
toast.configure()

function UpdateProfile(props) {
    Common.ValidAdminLogin();
    const history = useHistory();
    const [loader, showLoader, hideLoader] = useLoader();
    const [model, setModel] = useState(DefaultModel);
    const [imgurl, setImgurl] = useState('');
    const [border, setBorder] = useState("");
    const [languagelist, setLanguageList] = useState([])
    const [studentId, setStudentId] = useState('');
    const id = props.match.params.id;

    const services = new service();
    const [showimage, setShowImage] = useState(false);
    useEffect(() => {
        GetLanguage();
        if (id) {
            GetById(id);
        }
    }, [id]);

    const GetById = async (id) => {
        showLoader()
        var res = await services.GetUserById(ServiceAPI.AdminUserGetApi, id);
        hideLoader()
        const User = await res.result;
        setImgurl(User.logo ? User.logo : '');
        setModel(User);
        setStudentId(User.id)
    };

    const ChangeHandler = (e) => {
        let value = {};
        if (e.target.type === "checkbox") {
            value[e.target.id] = e.target.checked;
        } else {
            value[e.target.id] = e.target.value;
        }
        setModel({ ...model, ...value });
        setBorder({ ...border, [e.target.id]: "" })
    };
    const AddValue = async (e, field) => {

        if (field === "studentLanguage") {
            let value = { studentLanguage: [...model.studentLanguage, { studentId: studentId ? studentId : null, languageId: e.slice(-1)[0].id, name: e.slice(-1)[0].name, }] };
            await setModel({ ...model, ...value });
        }
    }
    const RemoveValue = async (e, field) => {
        if (field === "studentLanguage") {
            let value = { studentLanguage: e }
            await setModel({ ...model, ...value });
        }
    }
    const Create = async (e) => {
        e.preventDefault();
        var response = null;
        if (valid()) {
            showLoader()
            response = await services.Update(ServiceAPI.AdminUserUpdateApi, model);
            var val = { email: model.email }
            // await services.Create(ServiceAPI.updateusersApi, val);
            hideLoader()
            if (response && response.message === "Success") {

                toast.success('User Updated Successfully', { theme: "colored", autoClose: 3000 })
                setTimeout(() => {
                    history.push("/users");
                    setModel(DefaultModel);
                }, 3000);
            }
        }
    }

    const valid = () => {
        let Error = [];
        let firstName = "";
        let lastName = "";
        // let address = "";
        let country = "";
        let state = '';
        let city = "";
        let studentLanguage = ''
        if (!Boolean(model.firstName)) {
            Error.push("Valid FirstName required");
            firstName = "borderred";
        }
        if (!Boolean(model.lastName)) {
            Error.push("Valid LastName required");
            lastName = "borderred";
        }
        // if (!Boolean(model.address)) {
        //     Error.push("Valid Address required");
        //     address = "borderred";
        // }
        if (!Boolean(model.country)) {
            Error.push("Valid Country required");
            country = "borderred";
        }
        if (!Boolean(model.state)) {
            Error.push("Valid State/Province required");
            state = "borderred";
        }
        if (!Boolean(model.city)) {
            Error.push("Valid City required");
            city = "borderred";
        }
        if (model.role == "candidate") {
            if (model.studentLanguage.length == 0) {
                Error.push("Please select language");
                studentLanguage = "borderred";
            }
        }

        if (firstName || lastName || country || state || city || studentLanguage) {
            setBorder({
                firstName, lastName, country, state, city, studentLanguage
            });
        }
        if (Error.length === 0) {
            Error = [];
            return true;
        } else if (Error.length > 2) {
            toast.error('Please fill the required fields', { theme: "colored", toastId: 'error1' });
            return false;
        } else if (Error.length <= 2) {
            Error.forEach((element, index) => {
                toast.error(element, { theme: "colored", toastId: `${[index]}` });
            });
            return false;
        }
    };
    const GetLanguage = async () => {
        const resp = await services.GetAll(ServiceAPI.GetLanguageApi);
        const industryData = await resp.result.map((item) => {
            return {
                name: item.languageName,
                value: item.languageName,
                id: item.languageId
            }
        });
        industryData.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
        setLanguageList(industryData);
    }

    const back = () => {
        history.push("/users");
    }
    return (
        <>
            <AdminHeader heading="" subheading="Manage Users" />
            <form onSubmit={Create} autoComplete="off">
                <div className="theme-container-admin min-h master-admin">
                    <AdminNav users="active"></AdminNav>
                    <div className="row">
                        <div className="  mx-auto detail-box form-sec">
                            <div className="row m-0 align-items-center">
                                <div className="col-md-6">
                                    <div className="label">FirstName<span className="required">*</span></div>
                                    <input
                                        type="text"
                                        id="firstName"
                                        className={border.firstName ? border.firstName : "xu-input"}
                                        placeholder="firstName"
                                        value={model.firstName}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">LastName<span className="required">*</span></div>
                                    <input
                                        type="text"
                                        id="lastName"
                                        className={border.lastName ? border.lastName : "xu-input"}
                                        placeholder="LastName"
                                        value={model.lastName}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="row m-0">
                                        <div className="col-md-10 pl-0">
                                            <div className="label xy-label-float">Choose Logo <span className="">(JPG or PNG)</span></div>
                                            <input
                                                disabled
                                                type="file"
                                                id="logo"
                                                className="xu-input xpading"
                                                placeholder="Logo"
                                                accept="image/jpeg, image/png,  image/jpg"
                                            // onChange={(e) => UploadImage(e)}
                                            ></input>
                                        </div>
                                        <div className="col-md-2 pr-0">
                                            <img src={imgurl ? imgurl : `${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/default-img.jpg`} className="ad-img img-fluid" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Email</div>
                                    <input
                                        disabled
                                        type="text"
                                        id="email"
                                        className="xu-input"
                                        placeholder="Email"
                                        value={model.email}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>

                                {model.role === "candidate" ? <div className="col-md-6">
                                    <div className="label"> Languages<span className="required">*</span></div>
                                    <Multiselect
                                        className="xu-input"
                                        id="studentLanguage"
                                        onSelect={(e) => AddValue(e, "studentLanguage")}
                                        selectedValues={model.studentLanguage}
                                        options={languagelist}
                                        displayValue="name"
                                        onRemove={(e) => RemoveValue(e, "studentLanguage")}
                                    />
                                </div> : ""}
                                <div className="col-md-6">
                                    <div className="label">Address
                                        {/* <span className="required">*</span> */}
                                    </div>
                                    <input
                                        type="text"
                                        id="address"
                                        className={border.address ? border.address : "xu-input"}
                                        placeholder="address"
                                        value={model.address}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>

                                <div className="col-md-6">
                                    <div className="label">City<span className="required">*</span></div>
                                    <input
                                        type="text"
                                        id="city"
                                        className={border.city ? border.city : "xu-input"}
                                        placeholder="city"
                                        value={model.city}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">State/Province<span className="required">*</span></div>
                                    <input
                                        type="text"
                                        id="state"
                                        className={border.state ? border.state : "xu-input"}
                                        placeholder="state"
                                        value={model.state}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Country<span className="required">*</span></div>
                                    <input
                                        type="text"
                                        id="country"
                                        className={border.country ? border.country : "xu-input"}
                                        placeholder="country"
                                        value={model.country}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>
                                <div className="col-md-6">
                                    <div className="label">Contact</div>
                                    <input
                                        disabled
                                        type="text"
                                        id="contact"
                                        className="xu-input"
                                        placeholder="contact"
                                        value={model.contact}
                                        onChange={(e) => ChangeHandler(e)}
                                    ></input>
                                </div>

                                {model.role === "candidate" ?
                                    <>
                                        <div className="col-md-6">
                                            <div className="label">Top Achievers</div>
                                            <label className="switch">
                                                <input
                                                    className=""
                                                    type="checkbox"
                                                    id="topAchievers"
                                                    checked={model.topAchievers}
                                                    onChange={(e) => ChangeHandler(e)}
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </div></> :
                                    <div className="col-md-6">
                                        <div className="label">Website</div>
                                        <input
                                            disabled
                                            type="text"
                                            id="website"
                                            className="xu-input"
                                            placeholder="website"
                                            value={model.website}
                                            onChange={(e) => ChangeHandler(e)}
                                        ></input>
                                    </div>}
                                <div className="col-md-6">
                                    <div className="label">Status</div>
                                    <label className="switch">
                                        <input
                                            className=""
                                            type="checkbox"
                                            id="isActive"
                                            checked={model.isActive}
                                            onChange={(e) => ChangeHandler(e)}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="col-md-12 spacing">
                                    <button
                                        className="btn btn-outline a-btn mt-0 float-right"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                            <div className='back-icon' onClick={back}></div>
                        </div>
                    </div>
                </div>
            </form>
            {loader}
        </>
    )
}

export default UpdateProfile