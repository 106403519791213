import React, { useState, useEffect } from "react";
import Common from "../../Utility/Common";
import DefaultModel from "../../Model/Admin/StaticModel";
import service from "../../Services/AdminUtilityService";
import ServiceAPI from "../../Utility/ServiceList";
import FormHeader from "./FormHeader";
import AdminNav from "./AdminNav";
import AdminHeader from './AdminHeader'
import environment from '../../environment'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useLoader from '../Auth/Loader';
import { useHistory } from "react-router-dom";
toast.configure()
const StaticPages = (props) => {
    const history = useHistory();
    Common.ValidAdminLogin();
    const formData = new FormData();
    // const id = props.match.params.id;
    // const id = props.location.search.split("=")[1]
    const [model, setModel] = useState('');
    const [error, setError] = useState("");
    const [imgurl, setImgurl] = useState('');
    const [loader, showLoader, hideLoader] = useLoader();
    const [pageModel, setPageModel] = useState({
        "pageNum": 1,
        "pageSize": 10,
        "isActive": true,
        "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
    });
    const [border, setBorder] = useState("");
    const [showimage, setShowImage] = useState(false);
    const services = new service();

    useEffect(() => {
        GetAll();
    }, []);
    const ChangeHandler = (e) => {

        let value = [...model];
        if (e.target.type === "checkbox") {
            value[e.target.id] = e.target.checked;
        } else if (e.target.type === "textarea") {
            const inputText = e.target.value;
            // Count words, excluding symbols
            const words = inputText
                .trim()
                .match(/\b[a-zA-Z]+\b/g) || [];

            // Allow only if the word count is within limits
            if (words.length <= 200) {
                value[0][e.target.id] = e.target.value;
            } else {
                toast.error("Maximum word limit of 200 reached.", { theme: "colored", autoClose: 2000 });
            }
        } else {
            value[0][e.target.id] = e.target.value;
        }

        setModel([...value]);
        setBorder({ ...border, [e.target.id]: "" })
    };
    const UploadImage = async (e) => {
        let values = [...model]
        if (validImage(e)) {
            setImgurl()
            setShowImage(true)
            formData.append('file', e.target.files[0]);
            formData.append('imageType', '1');
            var data = await services.UploadImages(ServiceAPI.ImageUplaodApi, "banner", formData);
            var url = environment.imageUrl + data.result;
            setImgurl(url);
            setShowImage(false)
            // let value = { url: url };
            values[0][e.target.id] = url
            await setModel([...values]);
            setBorder({ ...border, [e.target.id]: "" })
        }
    }
    const validImage = (e) => {
        let file = e.target.files[0];
        const fileExtension = file.name.split(".").at(-1);
        const allowedFileTypes = ["jpg", "png", "jpeg"];
        if (!allowedFileTypes.includes(fileExtension)) {
            const newName = "No file chosen";
            const input = e.currentTarget;
            const previousFile = input.files[0];
            const newFile = new File([previousFile], newName);
            const dT = new DataTransfer();
            dT.items.add(newFile);
            input.files = dT.files;
            toast.error("File does not support Upload jpg,png,jpeg files", { theme: "colored", toastId: 'error5', autoClose: 3000 });
            return false;
        }
        if (file.size > 10e6) {
            const newName = "No file chosen";
            const input = e.currentTarget;
            const previousFile = input.files[0];
            const newFile = new File([previousFile], newName);
            const dT = new DataTransfer();
            dT.items.add(newFile);
            input.files = dT.files;
            toast.error("Please upload a file smaller than 10 MB", { theme: "colored", toastId: 'error6', autoClose: 3000 });
            return false;
        }
        return true;
    }

    const Create = async (e) => {
        e.preventDefault();
        var response = null;
        if (valid()) {
            showLoader();
            response = await services.UpdateStaticPages(ServiceAPI.GetStaticDataUpdateApi, { ...model[0] }, model[0].id);
            hideLoader();
            if (response && response.message === "Success") {
                toast.success('Successfully Updated', { theme: "colored", autoClose: 2000 })
                setTimeout(() => {
                    history.push("/staticpageslist");
                }, 1000);
            } else {
                hideLoader();
            }
        }
    };

    const GetAll = async (id) => {
        showLoader();
        var res = await services.PostGetAll(ServiceAPI.GetAllStaticDataApi, pageModel);
        hideLoader()
        const banner = await res.result;
        setModel(banner);
        setImgurl(banner.url);
    };
    const valid = () => {

        let Error = [];
        let title1 = "";
        let title2 = "";
        let title3 = "";
        let title4 = "";
        let description1 = ""; let description2 = ""; let description3 = ""; let description4 = "";
        let imageUrl1 = ""; let imageUrl2 = ""; let imageUrl3 = ""; let imageUrl4 = "";
        if (!Boolean(model[0].title1)) {
            Error.push("Valid Name required");
            title1 = "borderred"
        }
        if (model[0].title1.length > 100) {
            Error.push("Valid Length required for name");
            title1 = "borderred"
        }
        if (!Boolean(model[0].imageUrl1)) {
            Error.push("Valid Image required");
            imageUrl1 = "borderred"
        } if (!Boolean(model[0].title2)) {
            Error.push("Valid Name required");
            title2 = "borderred"
        }
        if (model[0].title2.length > 100) {
            Error.push("Valid Length required for name");
            title2 = "borderred"
        }
        if (!Boolean(model[0].imageUrl2)) {
            Error.push("Valid Image required");
            imageUrl2 = "borderred"
        } if (!Boolean(model[0].title3)) {
            Error.push("Valid Name required");
            title3 = "borderred"
        }
        if (model[0].title3.length > 100) {
            Error.push("Valid Length required for name");
            title3 = "borderred"
        }
        if (!Boolean(model[0].imageUrl3)) {
            Error.push("Valid Image required");
            imageUrl3 = "borderred"
        }
        if (model[0].title4.length > 100) {
            Error.push("Valid Length required for name");
            title4 = "borderred"
        }
        if (!Boolean(model[0].imageUrl4)) {
            Error.push("Valid Image required");
            imageUrl4 = "borderred"
        }
        if (description1 || title1 || imageUrl1 || imageUrl2 || imageUrl3 || imageUrl4 || title2 || title3 || title4 || description2 || description3 || description4) {
            setBorder({ description1, title1, imageUrl1, imageUrl2, imageUrl3, imageUrl4, title2, title3, title4, description2, description3, description4 });
        }
        if (Error.length === 0) {
            Error = [];
            return true;
        } else if (Error.length > 2) {
            toast.error('Please fill the required fields', { theme: "colored", toastId: 'error1' });
            return false;
        } else if (Error.length <= 2) {
            Error.forEach((element, index) => {
                toast.error(element, { theme: "colored", toastId: `${[index]}` });
            });
            return false;
        }
    };
    const back = () => {
        history.push("/staticpageslist");
    }
    return (
        <>
            <AdminHeader heading="" subheading="Manage staticpages" />
            <form onSubmit={Create} autoComplete="off">
                <div className="theme-container-admin master-admin">
                    <AdminNav staticpageslist="active"></AdminNav>
                    <div className="title">
                        What we do</div>
                    <div className="container-fluid p-0">

                        <div className="row g-1">

                            <div className="col-lg-4 mx-auto detail-box  ">
                                <div className="row m-0 align-items-center">
                                    <div className="col-md-12">
                                        <div className="label">Title<span className="required">*</span></div>
                                        <input
                                            type="text"
                                            id="title1"
                                            className={border.title1 ? border.title1 : "xu-input"}
                                            placeholder="title1"
                                            value={model && model[0].title1}
                                            onChange={(e) => ChangeHandler(e)}
                                        ></input>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row m-0">
                                            <div className="col-md-10 pl-0">
                                                <div className="label">Image<span className="required">*</span><span className="">(JPG or PNG)</span></div>
                                                <input
                                                    type="file"
                                                    id="imageUrl1"
                                                    className={border.imageUrl1 ? `${border.imageUrl1} xpading` : "xu-input xpading"}
                                                    placeholder="Image"
                                                    accept="image/jpeg, image/png,  image/jpg"
                                                    onChange={(e) => UploadImage(e)}
                                                ></input>
                                            </div>
                                            <div className="col-md-2 pr-0">
                                                {model ? <img src={model[0].imageUrl1 ? model[0].imageUrl1 : `${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/default-img.jpg`} className="ad-img img-fluid" />
                                                    : showimage ? <span className="img mt-3">
                                                        {/* <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/loader.gif`} className="ad-img img-fluid" /> */}
                                                        <div className="admin-img-preview mt-3">
                                                            <div className="lds-roller sm"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                                        </div>
                                                    </span> : <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/default-img.jpg`} className="ad-img img-fluid" />}

                                                {/* <img src={imgurl ? imgurl : "./images/default-img.jpg"} className="ad-img img-fluid" /> */}
                                            </div>
                                            <div className="col-md-12 pl-0">
                                                <div className="label">Description<span className="required">*</span>{`(${model && model[0].description1 ? model[0].description1.trim().match(/\b[a-zA-Z]+\b/g)?.length : 0}/200)`}</div>
                                                <textarea
                                                    type="text"
                                                    id="description1"
                                                    // maxLength={200}
                                                    className={border.title ? border.title : "xu-textarea mb-0"}
                                                    placeholder="description1"
                                                    value={model && model[0].description1}
                                                    onChange={(e) => ChangeHandler(e)}
                                                ></textarea>
                                            </div>


                                        </div>
                                    </div>

                                </div>{" "}
                            </div>{" "}
                            <div className="col-lg-4 mx-auto detail-box   ">
                                <div className="row m-0 align-items-center">
                                    <div className="col-md-12">
                                        <div className="label">Title<span className="required">*</span></div>
                                        <input
                                            type="text"
                                            id="title2"
                                            className={border.title2 ? border.title2 : "xu-input"}
                                            placeholder="title2"
                                            value={model && model[0].title2}
                                            onChange={(e) => ChangeHandler(e)}
                                        ></input>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row m-0">
                                            <div className="col-md-10 pl-0">
                                                <div className="label">Image<span className="required">*</span><span className="">(JPG or PNG)</span></div>
                                                <input
                                                    type="file"
                                                    id="imageUrl2"
                                                    className={border.imageUrl2 ? `${border.imageUrl2} xpading` : "xu-input xpading"}
                                                    placeholder="Image"
                                                    accept="image/jpeg, image/png,  image/jpg"
                                                    onChange={(e) => UploadImage(e)}
                                                ></input>
                                            </div>
                                            <div className="col-md-2 pr-0">
                                                {model ? <img src={model[0].imageUrl2 ? model[0].imageUrl2 : `${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/default-img.jpg`} className="ad-img img-fluid" />
                                                    : showimage ? <span className="img mt-3">
                                                        {/* <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/loader.gif`} className="ad-img img-fluid" /> */}
                                                        <div className="admin-img-preview mt-3">
                                                            <div className="lds-roller sm"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                                        </div>
                                                    </span> : <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/default-img.jpg`} className="ad-img img-fluid" />}
                                                {/* <img src={imgurl ? imgurl : "./images/default-img.jpg"} className="ad-img img-fluid" /> */}
                                            </div>
                                            <div className="col-md-12 pl-0">
                                                <div className="label">Description<span className="required">*</span>{`(${model && model[0].description2 ? model[0].description2.trim().match(/\b[a-zA-Z]+\b/g)?.length : 0}/200)`}</div>
                                                <textarea
                                                    type="text"
                                                    id="description2"
                                                    // maxLength={200}
                                                    className={border.title ? border.title : "xu-textarea mb-0"}
                                                    placeholder="description2"
                                                    value={model && model[0].description2}
                                                    onChange={(e) => ChangeHandler(e)}
                                                ></textarea>
                                            </div>

                                        </div>
                                    </div>
                                </div>{" "}
                            </div>{" "}
                            <div className="col-lg-4  mx-auto detail-box ">
                                <div className="row m-0 align-items-center">
                                    <div className="col-md-12 pl-0">
                                        <div className="label">Title<span className="required">*</span></div>
                                        <input
                                            type="text"
                                            id="title3"
                                            className={border.title3 ? border.title3 : "xu-input"}
                                            placeholder="title"
                                            value={model && model[0].title3}
                                            onChange={(e) => ChangeHandler(e)}
                                        ></input>
                                    </div>
                                    <div className="col-md-12 pl-0">
                                        <div className="row m-0">
                                            <div className="col-md-10 pl-0">
                                                <div className="label">Image<span className="required">*</span><span className="">(JPG or PNG)</span></div>
                                                <input
                                                    type="file"
                                                    id="imageUrl3"
                                                    className={border.imageUrl3 ? `${border.imageUrl3} xpading` : "xu-input xpading"}
                                                    placeholder="Image"
                                                    accept="image/jpeg, image/png,  image/jpg"
                                                    onChange={(e) => UploadImage(e)}
                                                ></input>
                                            </div>
                                            <div className="col-md-2 pr-0">
                                                {model ? <img src={model[0].imageUrl3 ? model[0].imageUrl3 : `${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/default-img.jpg`} className="ad-img img-fluid" />
                                                    : showimage ? <span className="img mt-3">
                                                        {/* <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/loader.gif`} className="ad-img img-fluid" /> */}
                                                        <div className="admin-img-preview mt-3">
                                                            <div className="lds-roller sm"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                                        </div>
                                                    </span> : <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/default-img.jpg`} className="ad-img img-fluid" />}

                                                {/* <img src={imgurl ? imgurl : "./images/default-img.jpg"} className="ad-img img-fluid" /> */}
                                            </div>


                                        </div>
                                    </div>


                                    <div className="col-md-12 pl-0">
                                        <div className="label">Description<span className="required">*</span>{`(${model && model[0].description3 ? model[0].description3.trim().match(/\b[a-zA-Z]+\b/g)?.length : 0}/200)`}</div>
                                        <textarea
                                            type="text"
                                            id="description3"
                                            // maxLength={200}
                                            className={border.title3 ? border.title3 : "xu-textarea mb-0"}
                                            placeholder="description1"
                                            value={model && model[0].description3}
                                            onChange={(e) => ChangeHandler(e)}
                                        ></textarea>
                                    </div>

                                </div>{" "}
                            </div>{" "}
                            <div className="col-lg-4  mx-auto detail-box ">
                                <div className="row m-0 align-items-center">
                                    <div className="col-md-12 pl-0">
                                        <div className="label">Title<span className="required">*</span></div>
                                        <input
                                            type="text"
                                            id="title4"
                                            className={border.title4 ? border.title4 : "xu-input"}
                                            placeholder="title"
                                            value={model && model[0].title4}
                                            onChange={(e) => ChangeHandler(e)}
                                        ></input>
                                    </div>
                                    <div className="col-md-12 pl-0">
                                        <div className="row m-0">
                                            <div className="col-md-10 pl-0">
                                                <div className="label">Image<span className="required">*</span><span className="">(JPG or PNG)</span></div>
                                                <input
                                                    type="file"
                                                    id="imageUrl4"
                                                    className={border.imageUrl4 ? `${border.imageUrl4} xpading` : "xu-input xpading"}
                                                    placeholder="Image"
                                                    accept="image/jpeg, image/png,  image/jpg"
                                                    onChange={(e) => UploadImage(e)}
                                                ></input>
                                            </div>
                                            <div className="col-md-2 pr-0">
                                                {model ? <img src={model[0].imageUrl4 ? model[0].imageUrl4 : `${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/default-img.jpg`} className="ad-img img-fluid" />
                                                    : showimage ? <span className="img mt-3">
                                                        {/* <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/loader.gif`} className="ad-img img-fluid" /> */}
                                                        <div className="admin-img-preview mt-3">
                                                            <div className="lds-roller sm"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                                        </div>
                                                    </span> : <img src={`${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}//images/default-img.jpg`} className="ad-img img-fluid" />}
                                            </div>


                                        </div>
                                    </div>

                                    <div className="col-md-12 pl-0">
                                        <div className="label">Description<span className="required">*</span>{`(${model && model[0].description3 ? model[0].description3.trim().match(/\b[a-zA-Z]+\b/g)?.length : 0}/200)`}</div>
                                        <textarea
                                            type="text"
                                            id="description4"
                                            // maxLength={200}
                                            className={border.description4 ? border.description4 : "xu-textarea mb-0"}
                                            placeholder="description1"
                                            value={model && model[0].description4}
                                            onChange={(e) => ChangeHandler(e)}
                                        ></textarea>
                                    </div>

                                </div>{" "}
                            </div>{" "}
                        </div>
                    </div>
                    <div className="col-md-12 mb-0 mt-2 pr-0">
                        <button
                            className="btn pr btn-outline s-btn"
                            type="submit"
                        >
                            Submit
                        </button>
                    </div>
                    {/* <div className='back-icon' onClick={back}></div> */}
                </div>
            </form>
            {loader}
        </>
    );
};

export default StaticPages;
