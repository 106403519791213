import { Component } from "react";
import baseService from "./BaseService";
import { toast } from "react-toastify";
import Common from "../Utility/Common";
class UtilityService extends Component {
  constructor(props) {
    super(props);
    this.service = new baseService();
  }
  PostAnonymous(url, data) {
    return new Promise((resolve, reject) => {
      var res = this.service
        .post(url, false, data).then((resp) => {
          if (resp.message === 'Success') {
            resolve(resp);
          } else {


            if (resp.message === "Error" || resp.errorMessage) {
              toast.error(resp.errorMessage, { theme: "colored", autoClose: 3000, toastId: 'error1' })
            } else {
              toast.error(resp.errorMessage, { theme: "colored", autoClose: 3000, toastId: 'error2', })
            }
            resolve(resp);
          }
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }
  Creates(url, data) {
    return new Promise((resolve, reject) => {
      var res = this.service
        .post(url, Common.UserType() ? true : false, data).then((resp) => {
          if (resp.message === 'Success') {
            resolve(resp);
          } else {

            if (resp.message === "Error" || resp.errorMessage) {
              toast.error(resp.errorMessage, { theme: "colored", autoClose: 3000, toastId: 'error1' })
            } else {
              toast.error(resp.errorMessage, { theme: "colored", autoClose: 3000, toastId: 'error2', })
            }
            resolve(resp);
          }
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }
  Create(url, data) {
    return new Promise((resolve, reject) => {
      var res = this.service
        .post(url, Common.UserType() ? true : false, data).then((resp) => {
          if (resp.message === 'Success') {
            resolve(resp);
          } else {

            if (resp.message === "Error" || resp.errorMessage) {
              toast.error(resp.errorMessage, { theme: "colored", autoClose: 3000, toastId: 'error1' })
            } else {
              toast.error(resp.errorMessage, { theme: "colored", autoClose: 3000, toastId: 'error2', })
            }
            resolve(resp);
          }
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }
  Update(url, data) {

    return new Promise((resolve, reject) => {
      var res = this.service
        .put(url + '/' + data.id, Common.UserType() ? true : false, data).then((resp) => {
          if (resp.message === 'Success') {
            resolve(resp);
          } else {
            if (resp.message === undefined) {
              toast.error(resp.errorMessage, { theme: "colored", autoClose: 3000, toastId: 'error3' })
            } else {
              toast.error(resp.errorMessage, { theme: "colored", autoClose: 3000, toastId: 'error4' })
            }
            resolve(resp);
          }
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }
  GetAll(url, auth = Common.UserType() ? true : false) {
    return new Promise((resolve, reject) => {
      var res = this.service
        .get(url, auth)
        .catch(function (error) {
          reject(error);
        });
      resolve(res);
    });
  }
  GetAllUsers(url, auth = Common.UserType() ? true : false) {
    return new Promise((resolve, reject) => {
      var res = this.service
        .getusers(url, auth)
        .catch(function (error) {
          reject(error);
        });
      resolve(res);
    });
  }
  PostGetAll(url, data) {
    return new Promise((resolve, reject) => {
      var res = this.service
        .post(url, Common.UserType() ? true : false, data).then((resp) => {
          if (resp.message === 'Success') {
            resolve(resp);
          } else {
            reject(resp.ErrorMessage);
          }
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }
  GetById(url, id) {
    return new Promise((resolve, reject) => {
      var res = this.service
        .get(url + '/' + id, Common.UserType() ? true : false)
        .catch(function (error) {
          reject(error);
        });

      resolve(res);
    });
  }
  GetByValue(url, value) {
    return new Promise((resolve, reject) => {
      var res = this.service
        .get(url + '?value=' + value, Common.UserType() ? true : false)
        .catch(function (error) {
          reject(error);
        });

      resolve(res);
    });
  }
  GetResumeById(url, id) {
    return new Promise((resolve, reject) => {
      var res = this.service
        .get(url + '?id=' + id, Common.UserType() ? true : false)
        .catch(function (error) {
          reject(error);
        });

      resolve(res);
    });
  }
  GetLocationById(url, id, type) {
    return new Promise((resolve, reject) => {
      var res = this.service
        .get(url + '?' + type + '=' + id, Common.UserType() ? true : false)
        .catch(function (error) {
          reject(error);
        });

      resolve(res);
    });
  }
  GetDataById(url, id, data) {
    return new Promise((resolve, reject) => {
      var res = this.service
        .post(url + '?id=' + id, Common.UserType() ? true : false, data)
        .catch(function (error) {

          reject(error);
        });

      resolve(res);
    });
  }
  GetStudentDataById(url, id, data) {
    return new Promise((resolve, reject) => {
      var res = this.service
        .post(url + '/' + id, Common.UserType() ? true : false, data)
        .catch(function (error) {
          reject(error);
        });

      resolve(res);
    });
  }
  Delete(url, id) {
    return new Promise((resolve, reject) => {
      var res = this.service
        .delete(url + '/' + id, true)
        .catch(function (error) {
          reject(error);
        });
      resolve(res);
    });
  }
  Deleteshortlist(url, id) {
    return new Promise((resolve, reject) => {
      var res = this.service
        .delete(url + '?id=' + id, true)
        .catch(function (error) {
          reject(error);
        });
      resolve(res);
    });
  }
  UploadImages(url, type, data) {
    return new Promise((resolve, reject) => {
      var res = this.service
        .upload(url + `?userId=${Common.UserId()}` + `&role=${Common.UserType()}` + `&typeDir=${type}`, false, data).then((resp) => {
          if (resp.message === 'Success') {
            resolve(resp);
          } else {
            reject(resp.ErrorMessage);
          }
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }
  UploadSignupImages(url, type, data, role) {
    return new Promise((resolve, reject) => {
      var res = this.service
        .upload(url + `?role=${role}` + `&typeDir=${type}`, false, data).then((resp) => {
          if (resp.message === 'Success') {
            resolve(resp);
          } else {
            reject(resp.ErrorMessage);
          }
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }
  UploadEncodeImgaes(url, data) {
    return new Promise((resolve, reject) => {

      var res = this.service
        .post(url, false, data).then((resp) => {
          if (resp.message === 'Success') {
            resolve(resp);
          } else {
            reject(resp.ErrorMessage);
          }
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }
  Upload(url, data) {
    return new Promise((resolve, reject) => {

      var res = this.service
        .upload(url, false, data).then((resp) => {
          if (resp.message === 'Success') {
            resolve(resp);
          } else {
            reject(resp.ErrorMessage);
          }
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }
}
export default UtilityService;