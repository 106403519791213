import Common from "../../Utility/Common";
const StudentPortfolioModel = () => ({
        id: null,
        studentId: Common.LoginId(),
        title: '',
        titleLink: '',
        images: '',
        VideoUrl: "",
        description: '',
        active: true,
        userId: Common.UserId()
})
export default StudentPortfolioModel;


