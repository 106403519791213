const registrationValue = (object) => ({
    id: null,
    FirstName: "",
    LastName: "",
    Email: "",
    UserName: "",
    Password: "",
    ConfirmPassword: "",
    UserType: "",
    Website: "",
    MobileNo: "",
    Logo: "",
    Address: "",
    City: "",
    State: "",
    Country: "",
    PinCode: "",
    errormsg: "",
    dob: "",
    gender: "",
    language: '',
    agreeToTerms: false,
    ...object,
});

export default registrationValue;
