import Common from "../../Utility/Common";
const AdvertisementModel = (object) => ({
    id: null,
    name: "",
    image: "",
    url: "",
    active: true,
    userId: Common.UserId(),
    description: "",
});

export default AdvertisementModel;