import { Avatar, Button, Box, Slider } from "@material-ui/core";
import { useState, useRef } from "react";
import AvatarEditor from "react-avatar-editor";
import $ from 'jquery'
import { toast } from "react-toastify";
// import EncodedImage from "../../Model/EncodedImageModel";
// import imgservice from "../../Services/UtilityService";
const Avtar = (props) => {
    var editor = "";

    const [files, setFiles] = useState();
    const [show, setShow] = useState(true);
    const [showimg, setShowImg] = useState('')
    const [picture, setPicture] = useState({
        cropperOpen: false,
        img: null,
        zoom: 2,
        croppedImg:
            "https://upload.wikimedia.org/wikipedia/commons/0/09/Man_Silhouette.png"
    });

    const handleSlider = (event, value) => {
        setPicture({
            ...picture,
            zoom: value
        });
    };

    const handleCancel = () => {
        setPicture({

            cropperOpen: false,
            img: null,
            zoom: 1,
            croppedImg:
                "https://upload.wikimedia.org/wikipedia/commons/0/09/Man_Silhouette.png"
        });
        setShow(false)
        setTimeout(() => {
            setShow(true)
        }, 10);
    };

    const setEditorRef = (ed) => {
        editor = ed;
    };

    const handleSave = (e) => {

        if (setEditorRef) {
            const canvasScaled = editor.getImageScaledToCanvas();
            const croppedImg = canvasScaled.toDataURL().split(",")[1];
            // setEncodedImageModel()
            props.UploadImage(croppedImg, files)
            setShowImg(canvasScaled.toDataURL())
            setPicture({

                cropperOpen: false,
                img: null,
                zoom: 2,
                croppedImg:
                    "https://upload.wikimedia.org/wikipedia/commons/0/09/Man_Silhouette.png"
            })
            setShow(false)
            setTimeout(() => {
                setShow(true)
                setShowImg()
            }, 10);

        }
    };

    const handleFileChange = (img) => {
        if (validImage(img)) {
            let url = URL.createObjectURL(img.target.files[0]);
            setFiles(img)

            setPicture({
                ...picture,
                img: url,
                cropperOpen: true
            });
        }
    };

    const validImage = (e) => {
        let file = e.target.files[0];
        const fileExtension = file.name.split(".").at(-1);
        const allowedFileTypes = ["jpg", "png", "jpeg"];
        if (!allowedFileTypes.includes(fileExtension)) {
            const newName = "No file chosen";
            const input = e.currentTarget;
            const previousFile = input.files[0];
            const newFile = new File([previousFile], newName);
            const dT = new DataTransfer();
            dT.items.add(newFile);
            input.files = dT.files;
            toast.error("File does not support Upload jpg,png,jpeg files", { theme: "colored", toastId: 'error3', autoClose: 3000 });
            return false;
        }
        return true;
    }



    return (
        <div>
            <div  >

                <div className="preview-img-box">
                    <img
                        src={showimg ? showimg : "./images/default-user-img.svg"}
                        className="profile-login sm"
                    />
                    {show ? <input type="file" accept="image/jpeg, image/png,  image/jpg" className="select-img xu-input" onChange={handleFileChange} /> : ""}
                </div>
                {picture.cropperOpen && (
                    <>
                        <div className="preview-box">
                            <AvatarEditor
                                ref={setEditorRef}
                                image={picture.img}
                                width={250}
                                height={250}
                                border={1.5}
                                borderRadius={452}
                                color={[204, 199, 196, 0.6]} // RGBA
                                rotate={0}
                                scale={picture.zoom}
                            />
                            <Slider
                                width="40px"
                                aria-label="raceSlider"
                                value={picture.zoom}
                                min={0.5}
                                max={4}
                                step={0.1}
                                onChange={handleSlider}
                            ></Slider>

                        </div>
                        <div className="preview-footer">
                            <div className="">
                                <button className="btn btn-outline mt-1 mr-1 s-btn" variant="contained" onClick={handleCancel}>
                                    Cancel
                                </button>
                            </div>
                            <div className="">
                                <button onClick={handleSave} className="btn btn-outline mt-1 s-btn" data-dismiss-modal="modal2">Save</button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Avtar;
