import React, { useState, useEffect } from "react";
import service from "../../Services/LoginService";
import LoginModel from "../../Model/Auth/LoginModel";
import ValidationService from "../../Services/ValidationService";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Common from "../../Utility/Common"
import useLoader from './Loader';

toast.configure()
const Login = () => {
    // if (window.location.pathname.toLowerCase() == '/login' && localStorage.getItem("adminusertype") == "admin") {
    //     window.location = "/admindashboard"
    // }
    const [loginModel, setLoginModel] = useState(LoginModel)
    const [error, setError] = useState(false)
    const [active, setActive] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [loader, showLoader, hideLoader] = useLoader();
    const [passwordShown, setPasswordShown] = useState(false);

    if (Common.getCookie("usertype") == "admin") {
        document.cookie = ` usertype=` + "" + ";path=http://localhost:3000/Login"
    }

    useEffect(() => {
        getCookiesData("college");
    }, []);

    // localStorage.setItem("usertype", "college")
    const validation = () => {
        var arrError = [];
        if (!ValidationService.validationNotEmpty(loginModel.Email)) {
            toast.error("Please enter Email", { theme: "colored", autoClose: 3000, toastId: 'error1' });
        }
        if (!ValidationService.validationEmail(loginModel.Email)) {
            toast.error("Please enter valid email", { theme: "colored", autoClose: 3000, toastId: 'error1' });
            arrError.push("Please enter valid email");
        }
        if (!ValidationService.validationNotEmpty(loginModel.Password)) {
            toast.error("Please enter password", { theme: "colored", autoClose: 3000, toastId: 'error3' });
            arrError.push("Please enter password");
        }
        if (arrError.length) {
            setError(arrError);
            return false;
        }
        return true;

    }
    const togglePassword = (e) => {
        e.preventDefault();
        setPasswordShown(!passwordShown);
    };
    const SelectType = async (e) => {
        let value = {};
        if (e.target.id === 'ankCollege') {
            value['UserType'] = 'college';
            getCookiesData("college")
        }
        else if (e.target.id === 'ankCandidate') {
            value['UserType'] = 'candidate';
            getCookiesData("candidate")
        }
        else if (e.target.id === 'ankCompany') {
            value['UserType'] = 'company';
            getCookiesData("company")
        }
        document.cookie = "userType1=" + value['UserType']
        await setLoginModel({ ...loginModel, ...value });

    }
    const login = async (e) => {
        e.preventDefault();
        if (validation()) {
            // if (this.state.error) {
            //     alert(this.state.errorMessage);
            //     return false;
            // }
            showLoader();
            const services = new service();
            var res = await services.login(loginModel);
            hideLoader();
            if (res === 'success') {
                window.location = '/home';
            }
        }
    }
    const ChangeHandler = (e) => {
        e.preventDefault();
        let value = {};
        value[e.target.id] = e.target.value;
        setLoginModel({ ...loginModel, ...value });
    }

    const getCookie = (cname) => {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    const getCookiesData = async (user) => {
        if (getCookie(user) == '') {
            setActive(false)
            setTimeout(() => {
                setLoginModel({
                    Email: '',
                    Password: '',
                    errormsg: null,
                    UserType: user
                })
            }, 0);
        }
        if (getCookie(user) != '' && getCookie(user) != '') {
            setActive(true)
            // document.getElementById("Email").value = s.email;
            // document.getElementById("Password").value = s.password;
            setTimeout(() => {
                setLoginModel({
                    Email: JSON.parse(getCookie(user)).email,
                    Password: JSON.parse(getCookie(user)).password,
                    errormsg: null,
                    UserType: JSON.parse(getCookie(user)).userType
                })
            }, 0);
        }
    }

    const setCookie = () => {
        var email = document.getElementById("Email").value;
        var Password = document.getElementById("Password").value;
        var userType = loginModel.UserType
        var obj = {
            userType: `${userType}`,
            email: `${email}`,
            password: `${Password}`
        }
        if (email.length != 0) {
            document.cookie = `${loginModel.UserType}=` + " " + ";path=http://localhost:3000/Login"
        }

        if (email.length != 0 && Password.length != 0) {
            if (active == true) {
                setActive(false)
            }
            else {
                document.cookie = `${userType}=` + JSON.stringify(obj) + ";path=http://localhost:3000/Login"
                setActive(true)
            }
        }


        // if (email&&Password){
        //     if(!active){
        //         setActive(false)
        //         document.cookie = `${loginModel.UserType}=` + " " + ";path=http://localhost:3000/Login"
        //     }
        //     else{
        //         setActive(false)
        //         document.cookie = `${userType}=` + JSON.stringify(obj) + ";path=http://localhost:3000/Login"
        //     }
        // }
        // if (active == true) {
        //     document.cookie = `${userType}=` + JSON.stringify(obj) + ";path=http://localhost:3000/Login"
        //     setActive(false)
        // }
        // else {
        //     setActive(true) 
        // }
        // if (active==false){
        //     localStorage.removeItem(userType)
        //     document.cookie = `${loginModel.UserType}=` + " " + ";path=http://localhost:3000/Login"

        // }

        // localStorage.setItem("obj", JSON.stringify(obj));

        // document.cookie = "password=" + Password + ";path=http://localhost:3000/Login"
        // document.cookie = "userType=" + userType + ";path=http://localhost:3000/Login"
    }
    return (
        <>
            <section className="section bg-sec login-h">
                <div className="container-fluid login-sec">
                    <div className="sign-box login">
                        <div className="col-md-6">
                            <img src="./images/sign-in-illu.png" className="sign-img" alt="" />
                        </div>
                        {/* <div className="bg">
                                {/* <img src="../images/bg-lines.svg" alt="" /> 
                            </div>*/}
                        <div className="col-md-6">
                            <div className="bg-lines">
                                <div className="login-box">
                                    <div className="sign-heading">
                                        Login
                                    </div>
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link tab-heading active" id="ankCollege" data-toggle="tab" href="#college" role="tab" aria-controls="home" aria-selected="true" onClick={SelectType}  >college</a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link tab-heading" id="ankCandidate" data-toggle="tab" href="#candidate" role="tab" aria-controls="profile" aria-selected="false" onClick={SelectType}  >candidate</a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link tab-heading" id="ankCompany" data-toggle="tab" href="#company" role="tab" aria-controls="contact" aria-selected="false" onClick={SelectType}  >company </a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="college" role="college-tab" aria-labelledby="college-tab">
                                            <div className="form-sec">
                                                <form >
                                                    <div className="label">
                                                        Email ID<span className="required">*</span>
                                                    </div>
                                                    <input
                                                        autoComplete="off"
                                                        type="email"
                                                        id="Email"
                                                        className="xu-input"
                                                        placeholder="Email"
                                                        value={loginModel.Email}
                                                        onChange={(e) => ChangeHandler(e)}
                                                    ></input>
                                                    <div className="label">
                                                        password<span className="required">*</span>
                                                    </div>
                                                    <div className="password-input">
                                                        <input
                                                            autoComplete="off"
                                                            type={passwordShown ? "text" : "password"}
                                                            id="Password"
                                                            className="xu-input"
                                                            placeholder="Password"
                                                            value={loginModel.Password}
                                                            onChange={(e) => ChangeHandler(e)}
                                                        ></input>
                                                        {passwordShown ?
                                                            <img src="./images/close-eye.svg" className="icon close" onClick={(e) => togglePassword(e)} />
                                                            :
                                                            <img src="./images/open-eye.svg" className="icon" onClick={(e) => togglePassword(e)} />
                                                        }
                                                    </div>
                                                    <div className="form-rem">
                                                        <label className="container">
                                                            <span>Remember me</span>
                                                            <input
                                                                type="checkbox"
                                                                id="Remember"
                                                                onChange={() => setCookie()}
                                                                checked={active}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                        <a href="forgotpassword" className="text-dark">Forgot Password?</a>
                                                    </div>
                                                    <button
                                                        type="submit"
                                                        className="btn sign-btn btn-outline big-btn"
                                                        // disabled={error}
                                                        onClick={(e) => login(e)}
                                                    >
                                                        Login
                                                    </button>

                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {loader}
        </>
    )
}

export default Login
