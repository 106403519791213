import Common from "../../Utility/Common";
const CollegeModel = () => ({
    firstName: '',
    lastName: '',
    name: '',
    description: '',
    address: '',
    country: '',
    state: '',
    city: '',
    pincode: '',
    noOfStudent: 0,
    coverImage: "",
    logo: '',
    website: '',
    contact: '',
    twitterUrl: '',
    facebookUrl: '',
    instagramUrl: '',
    email: '',
    active: true,
    isDefaultLogo: true,
    userId: Common.UserId(),
    errormsg: "",
    id: Common.LoginId(),
});
export default CollegeModel;